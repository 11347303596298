import React, { PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { SubpageNav } from './PageLayout.styles'
import SubpageNavItem from './SubpageNavItem'

export function ScopeTabs({
  children,
}: PropsWithChildren<Record<never, never>>): JSX.Element {
  const location = useLocation()

  const getLink = (isInScope: boolean) => {
    return queryString.stringifyUrl(
      {
        url: location.pathname,
        query: { dismissed: [!isInScope] },
      },
      { arrayFormat: 'bracket' },
    )
  }

  const isActive = (isInScope: boolean): boolean => {
    const { dismissed: dismissedParam } = queryString.parse(location.search, {
      arrayFormat: 'bracket',
    }) as {
      dismissed: string[] | null
    }
    const dismissedValue =
      dismissedParam?.[0] &&
      (dismissedParam?.[0] === 'false' || dismissedParam?.[0] === 'true')
        ? dismissedParam[0]
        : 'false'
    const dismissed = JSON.parse(dismissedValue) as boolean
    return dismissed === !isInScope
  }

  return (
    <SubpageNav>
      <SubpageNavItem
        replace
        to={getLink(true)}
        className={isActive(true) ? 'active' : 'inactive'}
        onClick={() => {
          window.scrollTo(0, 0)
        }}
      >
        <span>In Scope</span>
      </SubpageNavItem>
      <SubpageNavItem
        replace
        to={getLink(false)}
        className={isActive(false) ? 'active' : 'inactive'}
        onClick={() => {
          window.scrollTo(0, 0)
        }}
      >
        <span>Out of Scope</span>
      </SubpageNavItem>
      {children}
    </SubpageNav>
  )
}

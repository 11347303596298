import React, { useContext } from 'react'
import { TestResult } from '@trustero/trustero-api-web/lib/evidence/testing_pb'
import { TestingPromiseClient } from '@trustero/trustero-api-web/lib/evidence/testing_grpc_web_pb'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import { useNavigate } from 'react-router-dom'
import { useHardTestInvalidation } from 'src/Utils/swrCacheInvalidation/useInvalidateOnTestResultMutation'
import { ConfirmationContext } from '../../../../Confirmation'
import { useAuthorizedGrpcClient } from '../../../../adapter'

export const useDeleteTestResult = ({
  testId,
  redirectTo,
}: {
  modelId: string
  testId: string
  redirectTo?: string
  timeRange?: TimeRange
}): React.MouseEventHandler<HTMLButtonElement> => {
  const navigate = useNavigate()
  const { setConfirmationState } = useContext(ConfirmationContext)
  const testingClient = useAuthorizedGrpcClient(TestingPromiseClient)
  const invalidateTestResults = useHardTestInvalidation()

  return () => {
    setConfirmationState({
      isShown: true,
      size: 'lg',
      title: 'Are you sure you want to delete the test?',
      body: <p>Note: this cannot be undone.</p>,
      confirmText: 'Delete Test',
      onConfirmCB: async () => {
        const response = await testingClient.deleteTest(
          new TestResult().setId(testId),
        )
        const status = response.toObject()

        if (!status.success) {
          setConfirmationState({
            isShown: true,
            size: 'lg',
            title: 'Error Deleting Test',
            body: <p>{status.message}</p>,
            confirmText: 'OK',
            onConfirmCB: () => null,
          })
          return
        }

        await invalidateTestResults()

        if (redirectTo) {
          navigate(redirectTo, { replace: true })
        }
      },
    })
  }
}

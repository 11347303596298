import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAccount } from 'src/components/async/Account/account.hooks'
import { DirectRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { getRoleConfig, PERMISSIONS, RoleConfig } from 'src/config/roleConfig'
import { useAuth } from 'src/context/authContext'

const checkPath = (denyRoutes: string[], path: string): boolean => {
  for (const route of denyRoutes) {
    if (path.includes(route)) {
      return true
    }
  }
  return false
}

export const useValidateRouteAccess = (): void => {
  const {
    routeConfig: { denyRoutes },
  } = useGetRoleAccessConfig()
  const location = useLocation()

  useEffect(() => {
    const path = location.pathname

    if (checkPath(denyRoutes, path)) {
      window.location.href = DirectRoutes.ACCESS_DENIED
    }
  }, [location.pathname, denyRoutes])
}

export const useGetRoleAccessConfig = (): RoleConfig => {
  const authCtx = useAuth()
  const { data: account } = useAccount()

  return getRoleConfig(authCtx.role, account?.getPurpose())
}

export const useHasRequiredPermissions = (
  requiredPermissions: PERMISSIONS[],
): boolean => {
  const { permissionsConfig } = useGetRoleAccessConfig()

  for (const permission of requiredPermissions) {
    if (!permissionsConfig.includes(permission)) {
      return false
    }
  }

  return true
}

export const useHasEditPermissions = (): boolean => {
  return useHasRequiredPermissions([PERMISSIONS.EDIT])
}

import { CONTROL_STATUS } from '@trustero/trustero-api-web/lib/model/control_pb'

/**
 * Deprecated Statuses post AP-3661
 *  - NO_EXCEPTIONS = 'No Exceptions', // Now 'Accepted'
 *  - EXCEPTIONS = 'Exceptions', // Now 'Ready for Review'
 */
export enum ControlStatus {
  NEEDS_ATTENTION = 'Needs Attention',
  READY_FOR_REVIEW = 'Ready for Review',
  AUDIT_READY = 'Audit Ready',
  ACCEPTED = 'Accepted',
  FAILED_TEST = 'Failed Test',
  NOT_APPLICABLE = 'NA - Not Applicable',
}

export const controlStatusTitle: Record<CONTROL_STATUS, string> = {
  [CONTROL_STATUS.NEEDSATTENTION]: ControlStatus.NEEDS_ATTENTION,
  [CONTROL_STATUS.AUDITREADY]: ControlStatus.AUDIT_READY,
  [CONTROL_STATUS.READYFORREVIEW]: ControlStatus.READY_FOR_REVIEW,
  [CONTROL_STATUS.ACCEPTED]: ControlStatus.ACCEPTED,
  [CONTROL_STATUS.FAILEDTEST]: ControlStatus.FAILED_TEST,
  [CONTROL_STATUS.NOTAPPLICABLE]: ControlStatus.NOT_APPLICABLE,
}

export const controlStatusToTitle: Record<string, CONTROL_STATUS> = {
  [ControlStatus.NEEDS_ATTENTION]: CONTROL_STATUS.NEEDSATTENTION,
  [ControlStatus.AUDIT_READY]: CONTROL_STATUS.AUDITREADY,
  [ControlStatus.READY_FOR_REVIEW]: CONTROL_STATUS.READYFORREVIEW,
  [ControlStatus.ACCEPTED]: CONTROL_STATUS.ACCEPTED,
  [ControlStatus.FAILED_TEST]: CONTROL_STATUS.FAILEDTEST,
  [ControlStatus.NOT_APPLICABLE]: CONTROL_STATUS.NOTAPPLICABLE,
}

export const titleToControlStatus: Record<ControlStatus, CONTROL_STATUS> = {
  [ControlStatus.NEEDS_ATTENTION]: CONTROL_STATUS.NEEDSATTENTION,
  [ControlStatus.AUDIT_READY]: CONTROL_STATUS.AUDITREADY,
  [ControlStatus.READY_FOR_REVIEW]: CONTROL_STATUS.READYFORREVIEW,
  [ControlStatus.ACCEPTED]: CONTROL_STATUS.ACCEPTED,
  [ControlStatus.FAILED_TEST]: CONTROL_STATUS.FAILEDTEST,
  [ControlStatus.NOT_APPLICABLE]: CONTROL_STATUS.NOTAPPLICABLE,
}

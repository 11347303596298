import React from 'react'
import styled from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import { PERMISSIONS } from 'src/config/roleConfig'
import { headerHeight } from '../../components/PageLayout/dimensionUtils'

export const JourneyPageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(1px, auto);
  background-color: white;
  * {
    scroll-margin-top: ${headerHeight + 8}px;
  }
`

export const MainContainer = styled.div`
  margin-top: 36px;
  margin-right: ${themeGet('space.l')}px;
  margin-left: ${themeGet('space.l')}px;

  grid-column: 1/9;
  ${themeGet('textStyles.body.default')};
`

export const JourneySubPageHeader = styled.div.attrs<{
  title: string
}>((props) => {
  return {
    children: (
      <>
        <JourneySubPageTitle>{props.title}</JourneySubPageTitle>
        <JourneySubPageText>{props.children}</JourneySubPageText>
      </>
    ),
  }
})<{
  title: string
}>`
  margin-bottom: 16px;
`

const JourneySubPageTitle = styled.div`
  ${themeGet('textStyles.header.4')};
`

const JourneySubPageText = styled.div`
  ${themeGet('textStyles.body.default')};
  > p {
    padding-top: 8px;
  }
`

export const SideBarContainer = styled.div`
  margin: 24px 0px 0px 0px;
  padding: 32px 32px 32px 32px;
  border: 1px solid ${themeGet('colors.border.neutral.light')};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  line-height: 200%;
  ${themeGet('textStyles.link.1')};

  a {
    font-weight: ${themeGet('fontWeights.regular')};
  }

  img {
    padding-top: 24px;
    height: 300px;
  }
`

export const SideContainer = styled.div`
  grid-column: 9/-1;
  margin-right: 35px;
`

export const HeaderButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  button {
    width: 152px;
    margin-left: 1em;
  }
`

export const SideBarHeader = styled.div`
  ${themeGet('textStyles.header.3')};
  margin-bottom: 12px;
`

export const StyledHelpLinksSection = styled.div`
  display: flex;
  gap: 1em;
  margin-bottom: 24px;
`

export const StyledHelpTextButton = styled(TextButton).attrs({
  requiredPermissions: [PERMISSIONS.READ],
})`
  font-size: 16px;
  font-weight: 500;
`

export const QuickstartContainer = styled(MainContainer)`
  margin-top: 24px;
`

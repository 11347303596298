import styled from 'styled-components/macro'
import { themeGet } from '@styled-system/theme-get'
import { border, color, compose, margin, space, textStyle } from 'styled-system'
import {
  StyledInputError,
  StyledLabel,
} from '../../../Reusable/Inputs/TextInput/styles'

export const EvidenceDetailsContainer = styled.div`
  position: relative;
`

export const StyledLink = styled.a.attrs({
  mb: 'xxxs',
  textStyle: 'body.default',
})`
  ${compose(margin, textStyle)}
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
`

export const Label = styled.label.attrs({
  mb: 'xxxs',
  textStyle: 'body.default',
})`
  ${compose(margin, textStyle)}
  display: flex;
  justify-content: space-between;
`

export const FileInfoContainer = styled.div.attrs({
  p: 's',
  border: '1px solid',
  borderColor: 'border.neutral.light',
  bg: 'white',
  color: 'text.default',
  textStyle: 'form.disabled',
})`
  ${compose(space, border, color, textStyle)}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 240px;

  :hover {
    background-color: ${themeGet('colors.bg.neutralHover')};
    border-color: ${themeGet('colors.border.neutral.base')};
    cursor: pointer;
  }

  :active,
  :focus {
    background-color: white;
    border-color: ${themeGet('colors.border.neutral.dark')};
    outline: none;
  }

  .filename {
    ${themeGet('textStyles.subtitle.1')};
    margin-bottom: ${themeGet('space.xxs')}px;
  }

  .last-modified {
    ${themeGet('textStyles.subtitle.caption')}
  }
`

export const FilesInfoContainer = styled.div.attrs({
  p: 's',
  border: '1px solid',
  borderColor: 'border.neutral.light',
  bg: 'white',
  color: 'text.default',
  textStyle: 'form.disabled',
})`
  ${compose(space, border, color, textStyle)}
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  position: relative;
  height: 240px;
  padding: 24px;
  overflow-y: auto;

  :active,
  :focus {
    background-color: white;
    border-color: ${themeGet('colors.border.neutral.dark')};
    outline: none;
  }
`

export const FilesInfoContainerRow = styled.div.attrs({
  p: 's',
  borderColor: 'border.neutral.light',
  bg: 'white',
  color: 'text.default',
  textStyle: 'form.disabled',
})`
  ${compose(space, color, textStyle)}
  display: flex;
  gap: 8px;
  align-items: center;
  position: relative;
  padding: 0;

  :active,
  :focus {
    background-color: white;
    border-color: ${themeGet('colors.border.neutral.dark')};
    outline: none;
  }

  .filename {
    ${themeGet('textStyles.subtitle.1')};
    margin-bottom: 0;
    margin-top: 2px;
  }

  .last-modified {
    ${themeGet('textStyles.subtitle.caption')}
    margin-top: 2px;
  }
`

export const FilesLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 240px;
`

export const AddEvidenceError = styled(StyledInputError)`
  display: block;
`

export const CaptionLabel = styled(StyledLabel)`
  margin: 0;
`

export const AddEvidenceFormForm = styled.form`
  display: flex;
  flex-direction: column;
`

export const UploadInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const UploadInfo = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${themeGet('colors.text.low')};
  font-size: ${themeGet('textStyles.caption')};
`

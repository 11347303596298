import React, {
  ChangeEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from 'react'
import log from 'loglevel'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { useThrobberContext } from 'src/Throbber'
import { CheckBoxInput } from 'src/components/Reusable/Inputs/CheckBox'
import { showInfoToast } from 'src/Utils/helpers/toast'
import { isGrpcError } from 'src/Utils/isGrpcError'
import { ModalForm, ModalFormId } from '../../../components/ModalForms'
import { DerivedTextInput } from '../../../components/Reusable/Inputs/TextInput'
import { ServiceRolesDropdown } from '../services.components'
import {
  SERVICE_ROLE_LABEL_TO_ROLE,
  ServiceRoleLabels,
} from '../Services.constants'
import { useCreateCustomService } from '../Services.hooks'
import { CreateCustomServiceModalBody } from '../services.styles'

export const CreateCustomServiceModal = (): JSX.Element => {
  const createCustomService = useCreateCustomService()
  const [serviceName, setServiceName] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [serviceRoles, setServiceRoles] = useState<ServiceRoleLabels[]>([])
  const [isValidName, setIsValidName] = useState<boolean>(true)
  const [modalBody, setModalBody] = useState<HTMLDivElement | null>(null)
  const { startThrobber, stopThrobber } = useThrobberContext()
  const show = useIsShowModal(ModalFormId.CREATE_CUSTOM_SERVICE)
  const onHide = () => {
    setServiceName('')
    setServiceRoles([])
    setIsValidName(true)
  }
  const hide = useHideModal({
    modalId: ModalFormId.CREATE_CUSTOM_SERVICE,
    onHide,
  })

  const maxHeight = 400
  useEffect(() => {
    if (!modalBody) return
    const contentHeight = modalBody.offsetHeight || 0
    if (contentHeight >= maxHeight) {
      modalBody.classList.add('scroll')
      modalBody.scrollTo(0, contentHeight)
    } else {
      modalBody.classList.remove('scroll')
    }
  }, [modalBody, serviceRoles])

  const saveCustomService: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    const trimmedServiceName = serviceName.trim()
    const isValid: boolean = trimmedServiceName.length > 0
    setIsValidName(isValid)
    if (!isValid) {
      setErrorMessage('Please enter a name')
      return
    }
    try {
      startThrobber()
      await createCustomService({
        serviceName: trimmedServiceName,
        serviceRoles: serviceRoles.map(
          (role) => SERVICE_ROLE_LABEL_TO_ROLE[role],
        ),
      })
      hide()
    } catch (error) {
      if (isGrpcError(error) && error.code === 6) {
        setErrorMessage('A service with that name already exists')
        setIsValidName(false)
        return
      }
      hide()
      showInfoToast('Sorry, something went wrong. Please try again.')
      log.error('Error when creating custom service', error)
    } finally {
      stopThrobber()
    }
  }

  const changeServiceRoles: ChangeEventHandler<HTMLInputElement> = (e) => {
    const currentRoles = new Set(serviceRoles)
    const serviceRole = e.currentTarget.value
    const isChecked = e.currentTarget.checked
    isChecked
      ? currentRoles.add(serviceRole as ServiceRoleLabels)
      : currentRoles.delete(serviceRole as ServiceRoleLabels)
    const newRoles = Array.from(currentRoles) as ServiceRoleLabels[]
    setServiceRoles(newRoles)
  }

  const changeServiceName: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!isValidName) {
      setIsValidName(true)
    }
    setServiceName(e.currentTarget.value)
  }

  const dropdownRoles = Object.values(ServiceRoleLabels).filter(
    (role) => !serviceRoles.includes(role),
  )

  return (
    <ModalForm
      show={show}
      hide={hide}
      title="Add Custom Service"
      submitText="Add Custom Service"
      formId={ModalFormId.CREATE_CUSTOM_SERVICE}
      showOverflow
    >
      <CreateCustomServiceModalBody ref={(ref) => setModalBody(ref)}>
        <form
          id={ModalFormId.CREATE_CUSTOM_SERVICE}
          onSubmit={saveCustomService}
        >
          <DerivedTextInput
            label="Service Name"
            name="input"
            initVal={serviceName}
            placeholder="What is this service called"
            form={ModalFormId.CREATE_CUSTOM_SERVICE}
            isValid={isValidName}
            errorMessage={errorMessage}
            customOnChange={changeServiceName}
          />
          {serviceRoles.map((role) => (
            <CheckBoxInput
              key={role}
              value={role}
              id={`service-roles-checkboxes-${role}`}
              label={role}
              checked={serviceRoles.includes(role)}
              onChange={changeServiceRoles}
            />
          ))}
          <ServiceRolesDropdown
            dropdownRoles={dropdownRoles}
            onRoleSelected={changeServiceRoles}
          />
        </form>
      </CreateCustomServiceModalBody>
    </ModalForm>
  )
}

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { isWebUri } from 'valid-url'
import styled from 'styled-components/macro'
import { ExternalLink } from 'src/components/Reusable/Text/Link'
import { HUBSPOT } from 'src/Utils/hubspot/hubspot.utils'
import formatDate from '../../../../Utils/formatDate'
import { ReactComponent as TextIcon } from '../../../../components/Icons/assets/evidence-text-icon.svg'
import { ReactComponent as LinkIcon } from '../../../../components/Icons/assets/permalink-icon.svg'
import { ReactComponent as FileUploadIcon } from '../../../../components/Icons/assets/file-upload-icon.svg'
import { TextButton } from '../../../Reusable/Buttons/TextButton'
import { DerivedTextArea, TextInputProps } from '../../../Reusable/Inputs'
import { StyledTextInput } from '../../../Reusable/Inputs/TextInput/styles'
import {
  TypeButton,
  TypeRow,
  TypeSelectorContainer,
} from '../../FileType/TypeSelector.styles'
import { MIME_TYPE } from '../../../../Utils/globalEnums'
import { useFileTypeIcon } from '../../FileType/useFileTypeIcon'
import { TestIds } from '../../../../Utils/testIds'
import {
  AddEvidenceErrorType,
  EvidenceFormData,
  EvidenceType,
} from './AddEvidenceForm'
import {
  CaptionLabel,
  FilesInfoContainer,
  FilesInfoContainerRow,
} from './AddEvidenceForm.styles'
import { acceptedFileFormats, MAX_UPLOAD_MB } from './AddEvidenceForm.constants'

type CaptionTextInputProps = TextInputProps & {
  createCaption: (captionString: string) => void
  isError: (field: string, error: boolean) => void
  captionError: string
}

export const CaptionTextInput = ({
  as,
  createCaption,
  name,
  initVal = '',
  placeholder,
  form,
  label,
  captionError,
  isError,
  ...styleProps
}: CaptionTextInputProps): JSX.Element => {
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (!captionError) {
      return
    }
    setShowError(true)
  }, [captionError])

  const onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const newVal = e.target.value
    createCaption(newVal)
    if (!newVal.length) {
      setShowError(true)
      isError('caption', true)
      return
    }
    isError(AddEvidenceErrorType.CAPTION, false)
    setShowError(false)
  }

  const onKeyDown: React.KeyboardEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    if (e.key !== 'Enter') {
      return
    }
    e.preventDefault()
  }

  const inputProps = {
    as,
    name,
    value: initVal,
    placeholder,
    onChange,
    onKeyDown,
    showError,
  }

  return (
    <>
      {label ? (
        <CaptionLabel form={form} {...styleProps}>
          <p>{label}</p>
          <StyledTextInput {...inputProps} />
        </CaptionLabel>
      ) : (
        <StyledTextInput {...{ ...inputProps, ...styleProps }} />
      )}
    </>
  )
}

type EvidenceFileProps = {
  formData: EvidenceFormData
}

export const EvidenceFile = ({ formData }: EvidenceFileProps): JSX.Element => {
  const { mime, body } = formData
  const FileTypeIcon = useFileTypeIcon({ mime })

  if (!(body instanceof Blob)) return <></>

  return (
    <>
      <FilesInfoContainerRow>
        <FileTypeIcon width="24px" height="24px" />
        <span className="filename">{body.name}</span>
        <span className="last-modified">
          Modified on {formatDate(body.lastModified)}
        </span>
      </FilesInfoContainerRow>
    </>
  )
}

type EvidenceFilesProps = {
  formDataList: EvidenceFormData[]
  type: EvidenceType
}

export const EvidenceFiles = ({
  formDataList,
  type,
}: EvidenceFilesProps): JSX.Element => {
  const noEvidenceFiles = type !== EvidenceType.FILE || formDataList.length < 1

  return (
    <>
      {noEvidenceFiles ? (
        <></>
      ) : (
        <FilesInfoContainer>
          {formDataList.map((formData, i) => (
            <EvidenceFile key={`evidence-file-${i}`} formData={formData} />
          ))}
        </FilesInfoContainer>
      )}
    </>
  )
}

type EvidenceTextProps = {
  formData: EvidenceFormData
  setFormData: Dispatch<SetStateAction<EvidenceFormData>>
  formId: string
  type: EvidenceType
}

export const EvidenceText = ({
  formData,
  setFormData,
  formId,
  type,
}: EvidenceTextProps): JSX.Element => (
  <>
    {(type === EvidenceType.TEXT || type === EvidenceType.LINK) && (
      <DerivedTextArea
        required
        name="body"
        initVal={formData.body as string}
        placeholder={`Add ${type} Content`}
        form={formId}
        setFormData={
          setFormData as Dispatch<SetStateAction<{ [key: string]: string }>>
        }
        isValid={
          type === EvidenceType.LINK ? (value) => !!isWebUri(value) : undefined
        }
        errorMessage={
          type === EvidenceType.LINK
            ? 'Please enter a valid url that begins with ' +
              `"https://" ` +
              'or ' +
              `"http://"`
            : undefined
        }
      />
    )}
  </>
)

type EvidenceTypeSelectorProps = {
  updateEvidence: (mime: MIME_TYPE, type: EvidenceType) => void
  updateEvidenceFiles: (files: EvidenceFormData[], type: EvidenceType) => void
  type: EvidenceType
  isError: (field: AddEvidenceErrorType, error: boolean) => void
  updateUploadSize: (totalSizeInMB: number) => void
}

const StyledTypeButton = styled(TypeButton)`
  margin: 0;
  cursor: pointer;
`

export function EvidenceTypeSelector({
  updateEvidence,
  updateEvidenceFiles,
  type,
  isError,
  updateUploadSize,
}: EvidenceTypeSelectorProps): JSX.Element {
  const showClear = type !== EvidenceType.NULL
  const getFiles: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault()
    const files = e.target.files
    const totalSize = files
      ? Array.from(files).reduce((size, file) => {
          size += file.size
          return size
        }, 0)
      : 0
    const totalSizeInMB = Math.round(totalSize / 1000000)
    updateUploadSize(totalSizeInMB)
    const tooLarge = totalSizeInMB > MAX_UPLOAD_MB

    if (tooLarge) {
      return isError(AddEvidenceErrorType.EVIDENCE, true)
    }

    isError(AddEvidenceErrorType.EVIDENCE, false)
    const newFiles = []
    if (!files) {
      return null
    }
    for (const file of files) {
      let type = file.type
      if (!file.type) {
        const split = file.name.split('.')
        const extension = split[split.length - 1]
        switch (extension) {
          case 'key':
            return (type = MIME_TYPE.APPLE_KEYNOTE)
          case 'numbers':
            return (type = MIME_TYPE.APPLE_NUMBERS)
          case 'pages':
            return (type = MIME_TYPE.APPLE_PAGES)
          default:
            return null
        }
      }
      newFiles.push({
        mime: type,
        body: file,
      })
    }
    return updateEvidenceFiles(newFiles, EvidenceType.FILE)
  }

  return (
    <>
      {showClear ? (
        <TextButton
          onClick={(e) => {
            e.preventDefault()
            updateEvidence(MIME_TYPE.NULL, EvidenceType.NULL)
            return isError(AddEvidenceErrorType.EVIDENCE, false)
          }}
        >
          Clear
        </TextButton>
      ) : (
        <TypeSelectorContainer>
          <span>You can use any of these as Evidence Details.</span>
          <TypeRow>
            <TypeButton
              onClick={(e) => {
                e.preventDefault()
                updateEvidence(MIME_TYPE.TEXT_MARKDOWN, EvidenceType.TEXT)
              }}
            >
              <TextIcon />
              <span>Display Text</span>
            </TypeButton>
            <TypeButton
              onClick={(e) => {
                e.preventDefault()
                updateEvidence(MIME_TYPE.TEXT_URI_LIST, EvidenceType.LINK)
              }}
            >
              <LinkIcon width="48px" height="48px" />
              <span>Paste Link</span>
            </TypeButton>
            <StyledTypeButton
              as="label"
              htmlFor="evidence-file-upload"
              onKeyPress={() => {
                const uploadInput = document.getElementById(
                  'evidence-file-upload',
                )
                uploadInput?.click()
              }}
            >
              <FileUploadIcon />
              <span>Attachment</span>
              <input
                data-testid={TestIds.FILE_INPUT}
                hidden
                type="file"
                id="evidence-file-upload"
                accept={acceptedFileFormats}
                multiple
                onChange={getFiles}
              />
            </StyledTypeButton>
          </TypeRow>
        </TypeSelectorContainer>
      )}
    </>
  )
}

export const AddEvidenceFormDescription = (): JSX.Element => (
  <span>
    Make sure your evidence will work:&nbsp;
    <ExternalLink
      href={HUBSPOT.GOOD_MANUAL_EVIDENCE}
      text="Good Manual Evidence"
    />
  </span>
)

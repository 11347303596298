import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import styled from 'styled-components/macro'
import {
  StyledDropdownMenu,
  StyledDropdownToggle,
} from '../../../components/Reusable/StandardDropdown/StandardDropdown.styles'
import {
  MultiSelectDropdownCheckBox,
  MultiSelectDropdownCheckBoxLabel,
  MultiSelectDropdownItem,
} from '../../../components/Reusable/MultiSelectDropdown'
import { CountChip } from '../../../components/Reusable/Chips/BasicChip'

interface dropdownProps {
  filterSelect: {
    activated: boolean
    deactivated: boolean
  }
  setFilterSelect: React.Dispatch<
    React.SetStateAction<{
      activated: boolean
      deactivated: boolean
    }>
  >
}

const ReceptorFilterDropdownItem = ({
  filterSelect,
  setFilterSelect,
}: dropdownProps): JSX.Element => {
  return (
    <>
      <Dropdown.Item as={MultiSelectDropdownItem}>
        <MultiSelectDropdownCheckBoxLabel>
          <MultiSelectDropdownCheckBox
            data-testid="receptors-filter-active"
            id={'Active'}
            onChange={() =>
              setFilterSelect((prev) => ({
                ...prev,
                activated: !prev.activated,
              }))
            }
            checked={filterSelect.activated}
          />
          Active
        </MultiSelectDropdownCheckBoxLabel>
      </Dropdown.Item>
      <Dropdown.Item as={MultiSelectDropdownItem}>
        <MultiSelectDropdownCheckBoxLabel>
          <MultiSelectDropdownCheckBox
            data-testid="receptors-filter-deactivated"
            checked={filterSelect.deactivated}
            id={'Deactivated'}
            onChange={() =>
              setFilterSelect((prev) => ({
                ...prev,
                deactivated: !prev.deactivated,
              }))
            }
          />
          Deactivated
        </MultiSelectDropdownCheckBoxLabel>
      </Dropdown.Item>
    </>
  )
}

const ReceptorDropdownMenu = ({
  filterSelect,
  setFilterSelect,
}: dropdownProps): JSX.Element => {
  return (
    <Dropdown.Menu as={StyledDropdownMenu}>
      <ReceptorFilterDropdownItem
        filterSelect={filterSelect}
        setFilterSelect={setFilterSelect}
      />
    </Dropdown.Menu>
  )
}

const ReceptorStyledDropdownToggle = styled(StyledDropdownToggle)`
  border: none;
`

export const ReceptorsFilterDropdown = ({
  filterSelect,
  setFilterSelect,
}: dropdownProps): JSX.Element => {
  return (
    <Dropdown id="receptor-filter-dropdown" drop="down" autoClose="outside">
      <Dropdown.Toggle as={ReceptorStyledDropdownToggle}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          Status
          <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
            {Object.values(filterSelect).filter((v) => !!v).length}
          </CountChip>
        </div>
      </Dropdown.Toggle>
      <ReceptorDropdownMenu
        filterSelect={filterSelect}
        setFilterSelect={setFilterSelect}
      />
    </Dropdown>
  )
}

import { CreateCustomServiceRequest } from '@trustero/trustero-api-web/lib/model/model_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { useContext } from 'react'
import log from 'loglevel'
import { callApiAndUpdateContent } from '../../../../adapter/dataModelAdapterUtils'
import {
  useGrpcRevalidateByMethod,
  useGrpcRevalidateByMethodRequest,
} from '../../..'
import AuthContext from '../../../../context/authContext'
import ContentContext from '../../../../context/ContentContext'
import { useAuthorizedGrpcClient } from '../../../../adapter'

type UseAddCustomServiceParams = {
  serviceName: string
  category: string
}

export const useAddCustomService = (): (({
  serviceName,
  category,
}: UseAddCustomServiceParams) => Promise<void>) => {
  const { authCtx, authDispatch } = useContext(AuthContext)
  const { content, contentDispatch } = useContext(ContentContext)
  const modelClient = useAuthorizedGrpcClient(ModelPromiseClient)
  const methodMutator = useGrpcRevalidateByMethod()
  const methodRequestMutator = useGrpcRevalidateByMethodRequest()

  return async ({ serviceName, category }: UseAddCustomServiceParams) => {
    const request = new CreateCustomServiceRequest()
      .setName(serviceName)
      .setCategory(category)
    const addCustomService = () => modelClient.createCustomService(request)
    try {
      await callApiAndUpdateContent(
        addCustomService,
        content,
        contentDispatch,
        authCtx,
        authDispatch,
        methodMutator,
        methodRequestMutator,
      )
    } catch (e) {
      log.error('Error occurred while trying to create custom service', e)
    }
  }
}

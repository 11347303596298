// source: model/policy.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var common_payload_pb = require('../common/payload_pb.js');
goog.object.extend(proto, common_payload_pb);
var common_collections_pb = require('../common/collections_pb.js');
goog.object.extend(proto, common_collections_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var model_framework_pb = require('../model/framework_pb.js');
goog.object.extend(proto, model_framework_pb);
var audit_audit_pb = require('../audit/audit_pb.js');
goog.object.extend(proto, audit_audit_pb);
goog.exportSymbol('proto.model.ApprovePolicyRequest', null, global);
goog.exportSymbol('proto.model.ComplianceFrameworkFilter', null, global);
goog.exportSymbol('proto.model.CreateOrUpdatePolicyRequest', null, global);
goog.exportSymbol('proto.model.DismissRequest', null, global);
goog.exportSymbol('proto.model.GetDocumentRequest', null, global);
goog.exportSymbol('proto.model.GetPoliciesForComplianceFrameworkRequest', null, global);
goog.exportSymbol('proto.model.GetPoliciesInAccountForComplianceFrameworkRequest', null, global);
goog.exportSymbol('proto.model.GetPolicyApprovalHistoryRequest', null, global);
goog.exportSymbol('proto.model.GetPolicyApprovalHistoryResponse', null, global);
goog.exportSymbol('proto.model.GetPolicyAuditsRequest', null, global);
goog.exportSymbol('proto.model.GetPolicyAuditsResponse', null, global);
goog.exportSymbol('proto.model.GetPolicyControlsRequest', null, global);
goog.exportSymbol('proto.model.GetPolicyForComplianceFrameworkRequest', null, global);
goog.exportSymbol('proto.model.GetPolicyFrameworksRequest', null, global);
goog.exportSymbol('proto.model.GetPolicyRequest', null, global);
goog.exportSymbol('proto.model.GetPreparedPoliciesBreakdownRequest', null, global);
goog.exportSymbol('proto.model.GetPreparedPoliciesBreakdownResponse', null, global);
goog.exportSymbol('proto.model.IsCustomizedFilter', null, global);
goog.exportSymbol('proto.model.ListPoliciesRequest', null, global);
goog.exportSymbol('proto.model.PolicyDocument', null, global);
goog.exportSymbol('proto.model.PolicyFrameworkFilter', null, global);
goog.exportSymbol('proto.model.PolicyRecord', null, global);
goog.exportSymbol('proto.model.PolicyRecords', null, global);
goog.exportSymbol('proto.model.PolicySort', null, global);
goog.exportSymbol('proto.model.PolicySort.policy_sort_column', null, global);
goog.exportSymbol('proto.model.PolicySort.policy_sort_order', null, global);
goog.exportSymbol('proto.model.RevertPolicyRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetPolicyAuditsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetPolicyAuditsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetPolicyAuditsRequest.displayName = 'proto.model.GetPolicyAuditsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetPolicyAuditsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetPolicyAuditsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetPolicyAuditsResponse.displayName = 'proto.model.GetPolicyAuditsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.PolicySort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.PolicySort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.PolicySort.displayName = 'proto.model.PolicySort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.PolicyRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.PolicyRecord.repeatedFields_, null);
};
goog.inherits(proto.model.PolicyRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.PolicyRecord.displayName = 'proto.model.PolicyRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.PolicyRecords = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.PolicyRecords.repeatedFields_, null);
};
goog.inherits(proto.model.PolicyRecords, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.PolicyRecords.displayName = 'proto.model.PolicyRecords';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetPolicyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetPolicyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetPolicyRequest.displayName = 'proto.model.GetPolicyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetDocumentRequest.displayName = 'proto.model.GetDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.PolicyDocument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.PolicyDocument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.PolicyDocument.displayName = 'proto.model.PolicyDocument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ApprovePolicyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.ApprovePolicyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ApprovePolicyRequest.displayName = 'proto.model.ApprovePolicyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.RevertPolicyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.RevertPolicyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.RevertPolicyRequest.displayName = 'proto.model.RevertPolicyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetPolicyApprovalHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetPolicyApprovalHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetPolicyApprovalHistoryRequest.displayName = 'proto.model.GetPolicyApprovalHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetPolicyApprovalHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.GetPolicyApprovalHistoryResponse.repeatedFields_, null);
};
goog.inherits(proto.model.GetPolicyApprovalHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetPolicyApprovalHistoryResponse.displayName = 'proto.model.GetPolicyApprovalHistoryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.CreateOrUpdatePolicyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.CreateOrUpdatePolicyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.CreateOrUpdatePolicyRequest.displayName = 'proto.model.CreateOrUpdatePolicyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetPolicyControlsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetPolicyControlsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetPolicyControlsRequest.displayName = 'proto.model.GetPolicyControlsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetPolicyFrameworksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetPolicyFrameworksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetPolicyFrameworksRequest.displayName = 'proto.model.GetPolicyFrameworksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.PolicyFrameworkFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.PolicyFrameworkFilter.repeatedFields_, null);
};
goog.inherits(proto.model.PolicyFrameworkFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.PolicyFrameworkFilter.displayName = 'proto.model.PolicyFrameworkFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ComplianceFrameworkFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.ComplianceFrameworkFilter.repeatedFields_, null);
};
goog.inherits(proto.model.ComplianceFrameworkFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ComplianceFrameworkFilter.displayName = 'proto.model.ComplianceFrameworkFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.IsCustomizedFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.IsCustomizedFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.IsCustomizedFilter.displayName = 'proto.model.IsCustomizedFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ListPoliciesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.ListPoliciesRequest.repeatedFields_, null);
};
goog.inherits(proto.model.ListPoliciesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ListPoliciesRequest.displayName = 'proto.model.ListPoliciesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DismissRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.DismissRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DismissRequest.displayName = 'proto.model.DismissRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetPoliciesForComplianceFrameworkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetPoliciesForComplianceFrameworkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetPoliciesForComplianceFrameworkRequest.displayName = 'proto.model.GetPoliciesForComplianceFrameworkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetPolicyForComplianceFrameworkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetPolicyForComplianceFrameworkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetPolicyForComplianceFrameworkRequest.displayName = 'proto.model.GetPolicyForComplianceFrameworkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetPoliciesInAccountForComplianceFrameworkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetPoliciesInAccountForComplianceFrameworkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetPoliciesInAccountForComplianceFrameworkRequest.displayName = 'proto.model.GetPoliciesInAccountForComplianceFrameworkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetPreparedPoliciesBreakdownRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetPreparedPoliciesBreakdownRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetPreparedPoliciesBreakdownRequest.displayName = 'proto.model.GetPreparedPoliciesBreakdownRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetPreparedPoliciesBreakdownResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetPreparedPoliciesBreakdownResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetPreparedPoliciesBreakdownResponse.displayName = 'proto.model.GetPreparedPoliciesBreakdownResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetPolicyAuditsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetPolicyAuditsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetPolicyAuditsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyAuditsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetPolicyAuditsRequest}
 */
proto.model.GetPolicyAuditsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetPolicyAuditsRequest;
  return proto.model.GetPolicyAuditsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetPolicyAuditsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetPolicyAuditsRequest}
 */
proto.model.GetPolicyAuditsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetPolicyAuditsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetPolicyAuditsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetPolicyAuditsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyAuditsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string policy_id = 1;
 * @return {string}
 */
proto.model.GetPolicyAuditsRequest.prototype.getPolicyId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetPolicyAuditsRequest} returns this
 */
proto.model.GetPolicyAuditsRequest.prototype.setPolicyId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetPolicyAuditsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetPolicyAuditsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetPolicyAuditsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyAuditsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    audits: (f = msg.getAudits()) && audit_audit_pb.AuditRecords.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetPolicyAuditsResponse}
 */
proto.model.GetPolicyAuditsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetPolicyAuditsResponse;
  return proto.model.GetPolicyAuditsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetPolicyAuditsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetPolicyAuditsResponse}
 */
proto.model.GetPolicyAuditsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new audit_audit_pb.AuditRecords;
      reader.readMessage(value,audit_audit_pb.AuditRecords.deserializeBinaryFromReader);
      msg.setAudits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetPolicyAuditsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetPolicyAuditsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetPolicyAuditsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyAuditsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAudits();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      audit_audit_pb.AuditRecords.serializeBinaryToWriter
    );
  }
};


/**
 * optional audit.AuditRecords audits = 1;
 * @return {?proto.audit.AuditRecords}
 */
proto.model.GetPolicyAuditsResponse.prototype.getAudits = function() {
  return /** @type{?proto.audit.AuditRecords} */ (
    jspb.Message.getWrapperField(this, audit_audit_pb.AuditRecords, 1));
};


/**
 * @param {?proto.audit.AuditRecords|undefined} value
 * @return {!proto.model.GetPolicyAuditsResponse} returns this
*/
proto.model.GetPolicyAuditsResponse.prototype.setAudits = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetPolicyAuditsResponse} returns this
 */
proto.model.GetPolicyAuditsResponse.prototype.clearAudits = function() {
  return this.setAudits(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetPolicyAuditsResponse.prototype.hasAudits = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.PolicySort.prototype.toObject = function(opt_includeInstance) {
  return proto.model.PolicySort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.PolicySort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PolicySort.toObject = function(includeInstance, msg) {
  var f, obj = {
    sortOrder: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sortColumn: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.PolicySort}
 */
proto.model.PolicySort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.PolicySort;
  return proto.model.PolicySort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.PolicySort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.PolicySort}
 */
proto.model.PolicySort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.model.PolicySort.policy_sort_order} */ (reader.readEnum());
      msg.setSortOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.model.PolicySort.policy_sort_column} */ (reader.readEnum());
      msg.setSortColumn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.PolicySort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.PolicySort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.PolicySort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PolicySort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSortOrder();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSortColumn();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.model.PolicySort.policy_sort_order = {
  ASC: 0,
  DESC: 1
};

/**
 * @enum {number}
 */
proto.model.PolicySort.policy_sort_column = {
  NAME: 0,
  DEPARTMENT: 1,
  DOCUMENTATION: 2,
  CONTROLS: 3,
  ASSIGNEE: 4
};

/**
 * optional policy_sort_order sort_order = 1;
 * @return {!proto.model.PolicySort.policy_sort_order}
 */
proto.model.PolicySort.prototype.getSortOrder = function() {
  return /** @type {!proto.model.PolicySort.policy_sort_order} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.model.PolicySort.policy_sort_order} value
 * @return {!proto.model.PolicySort} returns this
 */
proto.model.PolicySort.prototype.setSortOrder = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional policy_sort_column sort_column = 2;
 * @return {!proto.model.PolicySort.policy_sort_column}
 */
proto.model.PolicySort.prototype.getSortColumn = function() {
  return /** @type {!proto.model.PolicySort.policy_sort_column} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.model.PolicySort.policy_sort_column} value
 * @return {!proto.model.PolicySort} returns this
 */
proto.model.PolicySort.prototype.setSortColumn = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.PolicyRecord.repeatedFields_ = [11,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.PolicyRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.model.PolicyRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.PolicyRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PolicyRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    ownerEmail: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isDismissed: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    departmentId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    controlIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    frameworkIdsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    complianceFrameworkIdsList: (f = jspb.Message.getRepeatedField(msg, 13)) == null ? undefined : f,
    documentTemplate: jspb.Message.getFieldWithDefault(msg, 14, ""),
    isTemplate: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    isCustom: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.PolicyRecord}
 */
proto.model.PolicyRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.PolicyRecord;
  return proto.model.PolicyRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.PolicyRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.PolicyRecord}
 */
proto.model.PolicyRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerEmail(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDismissed(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDepartmentId(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlIds(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addFrameworkIds(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.addComplianceFrameworkIds(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentTemplate(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTemplate(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustom(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.PolicyRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.PolicyRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.PolicyRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PolicyRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOwnerEmail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsDismissed();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getDepartmentId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getControlIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getFrameworkIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getComplianceFrameworkIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      13,
      f
    );
  }
  f = message.getDocumentTemplate();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getIsTemplate();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getIsCustom();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp created_at = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.PolicyRecord.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.PolicyRecord} returns this
*/
proto.model.PolicyRecord.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.PolicyRecord.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.PolicyRecord.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.PolicyRecord} returns this
*/
proto.model.PolicyRecord.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.PolicyRecord.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.model.PolicyRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_id = 4;
 * @return {string}
 */
proto.model.PolicyRecord.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string model_id = 5;
 * @return {string}
 */
proto.model.PolicyRecord.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.model.PolicyRecord.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string description = 7;
 * @return {string}
 */
proto.model.PolicyRecord.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string owner_email = 8;
 * @return {string}
 */
proto.model.PolicyRecord.prototype.getOwnerEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.setOwnerEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool is_dismissed = 9;
 * @return {boolean}
 */
proto.model.PolicyRecord.prototype.getIsDismissed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.setIsDismissed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string department_id = 10;
 * @return {string}
 */
proto.model.PolicyRecord.prototype.getDepartmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.setDepartmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated string control_ids = 11;
 * @return {!Array<string>}
 */
proto.model.PolicyRecord.prototype.getControlIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.setControlIdsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.addControlIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.clearControlIdsList = function() {
  return this.setControlIdsList([]);
};


/**
 * repeated string framework_ids = 12;
 * @return {!Array<string>}
 */
proto.model.PolicyRecord.prototype.getFrameworkIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.setFrameworkIdsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.addFrameworkIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.clearFrameworkIdsList = function() {
  return this.setFrameworkIdsList([]);
};


/**
 * repeated string compliance_framework_ids = 13;
 * @return {!Array<string>}
 */
proto.model.PolicyRecord.prototype.getComplianceFrameworkIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 13));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.setComplianceFrameworkIdsList = function(value) {
  return jspb.Message.setField(this, 13, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.addComplianceFrameworkIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 13, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.clearComplianceFrameworkIdsList = function() {
  return this.setComplianceFrameworkIdsList([]);
};


/**
 * optional string document_template = 14;
 * @return {string}
 */
proto.model.PolicyRecord.prototype.getDocumentTemplate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.setDocumentTemplate = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional bool is_template = 15;
 * @return {boolean}
 */
proto.model.PolicyRecord.prototype.getIsTemplate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.setIsTemplate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional bool is_custom = 16;
 * @return {boolean}
 */
proto.model.PolicyRecord.prototype.getIsCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.PolicyRecord} returns this
 */
proto.model.PolicyRecord.prototype.setIsCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.PolicyRecords.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.PolicyRecords.prototype.toObject = function(opt_includeInstance) {
  return proto.model.PolicyRecords.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.PolicyRecords} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PolicyRecords.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.model.PolicyRecord.toObject, includeInstance),
    hasAny: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.PolicyRecords}
 */
proto.model.PolicyRecords.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.PolicyRecords;
  return proto.model.PolicyRecords.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.PolicyRecords} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.PolicyRecords}
 */
proto.model.PolicyRecords.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.PolicyRecord;
      reader.readMessage(value,proto.model.PolicyRecord.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasAny(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.PolicyRecords.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.PolicyRecords.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.PolicyRecords} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PolicyRecords.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.model.PolicyRecord.serializeBinaryToWriter
    );
  }
  f = message.getHasAny();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated PolicyRecord items = 1;
 * @return {!Array<!proto.model.PolicyRecord>}
 */
proto.model.PolicyRecords.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.model.PolicyRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.PolicyRecord, 1));
};


/**
 * @param {!Array<!proto.model.PolicyRecord>} value
 * @return {!proto.model.PolicyRecords} returns this
*/
proto.model.PolicyRecords.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.PolicyRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.PolicyRecord}
 */
proto.model.PolicyRecords.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.PolicyRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.PolicyRecords} returns this
 */
proto.model.PolicyRecords.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional bool has_any = 2;
 * @return {boolean}
 */
proto.model.PolicyRecords.prototype.getHasAny = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.PolicyRecords} returns this
 */
proto.model.PolicyRecords.prototype.setHasAny = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetPolicyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetPolicyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetPolicyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetPolicyRequest}
 */
proto.model.GetPolicyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetPolicyRequest;
  return proto.model.GetPolicyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetPolicyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetPolicyRequest}
 */
proto.model.GetPolicyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetPolicyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetPolicyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetPolicyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string model_id = 1;
 * @return {string}
 */
proto.model.GetPolicyRequest.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetPolicyRequest} returns this
 */
proto.model.GetPolicyRequest.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.model.GetPolicyRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetPolicyRequest} returns this
 */
proto.model.GetPolicyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetDocumentRequest}
 */
proto.model.GetDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetDocumentRequest;
  return proto.model.GetDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetDocumentRequest}
 */
proto.model.GetDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.GetDocumentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetDocumentRequest} returns this
 */
proto.model.GetDocumentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.PolicyDocument.prototype.toObject = function(opt_includeInstance) {
  return proto.model.PolicyDocument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.PolicyDocument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PolicyDocument.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isDraft: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    content: (f = msg.getContent()) && common_payload_pb.Payload.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.PolicyDocument}
 */
proto.model.PolicyDocument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.PolicyDocument;
  return proto.model.PolicyDocument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.PolicyDocument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.PolicyDocument}
 */
proto.model.PolicyDocument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDraft(value);
      break;
    case 5:
      var value = new common_payload_pb.Payload;
      reader.readMessage(value,common_payload_pb.Payload.deserializeBinaryFromReader);
      msg.setContent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.PolicyDocument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.PolicyDocument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.PolicyDocument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PolicyDocument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsDraft();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getContent();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_payload_pb.Payload.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp created_at = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.PolicyDocument.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.PolicyDocument} returns this
*/
proto.model.PolicyDocument.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.PolicyDocument} returns this
 */
proto.model.PolicyDocument.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.PolicyDocument.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.model.PolicyDocument.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.model.PolicyDocument} returns this
*/
proto.model.PolicyDocument.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.PolicyDocument} returns this
 */
proto.model.PolicyDocument.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.PolicyDocument.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.model.PolicyDocument.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PolicyDocument} returns this
 */
proto.model.PolicyDocument.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_draft = 4;
 * @return {boolean}
 */
proto.model.PolicyDocument.prototype.getIsDraft = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.PolicyDocument} returns this
 */
proto.model.PolicyDocument.prototype.setIsDraft = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional common.Payload content = 5;
 * @return {?proto.common.Payload}
 */
proto.model.PolicyDocument.prototype.getContent = function() {
  return /** @type{?proto.common.Payload} */ (
    jspb.Message.getWrapperField(this, common_payload_pb.Payload, 5));
};


/**
 * @param {?proto.common.Payload|undefined} value
 * @return {!proto.model.PolicyDocument} returns this
*/
proto.model.PolicyDocument.prototype.setContent = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.PolicyDocument} returns this
 */
proto.model.PolicyDocument.prototype.clearContent = function() {
  return this.setContent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.PolicyDocument.prototype.hasContent = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ApprovePolicyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ApprovePolicyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ApprovePolicyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ApprovePolicyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    approvedByUserEmail: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ApprovePolicyRequest}
 */
proto.model.ApprovePolicyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ApprovePolicyRequest;
  return proto.model.ApprovePolicyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ApprovePolicyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ApprovePolicyRequest}
 */
proto.model.ApprovePolicyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApprovedByUserEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ApprovePolicyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ApprovePolicyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ApprovePolicyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ApprovePolicyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApprovedByUserEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.ApprovePolicyRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ApprovePolicyRequest} returns this
 */
proto.model.ApprovePolicyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string approved_by_user_email = 2;
 * @return {string}
 */
proto.model.ApprovePolicyRequest.prototype.getApprovedByUserEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ApprovePolicyRequest} returns this
 */
proto.model.ApprovePolicyRequest.prototype.setApprovedByUserEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.RevertPolicyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.RevertPolicyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.RevertPolicyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.RevertPolicyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyDocumentId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.RevertPolicyRequest}
 */
proto.model.RevertPolicyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.RevertPolicyRequest;
  return proto.model.RevertPolicyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.RevertPolicyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.RevertPolicyRequest}
 */
proto.model.RevertPolicyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPolicyDocumentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.RevertPolicyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.RevertPolicyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.RevertPolicyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.RevertPolicyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string policy_document_id = 1;
 * @return {string}
 */
proto.model.RevertPolicyRequest.prototype.getPolicyDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.RevertPolicyRequest} returns this
 */
proto.model.RevertPolicyRequest.prototype.setPolicyDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetPolicyApprovalHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetPolicyApprovalHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetPolicyApprovalHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyApprovalHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeBody: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetPolicyApprovalHistoryRequest}
 */
proto.model.GetPolicyApprovalHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetPolicyApprovalHistoryRequest;
  return proto.model.GetPolicyApprovalHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetPolicyApprovalHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetPolicyApprovalHistoryRequest}
 */
proto.model.GetPolicyApprovalHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetPolicyApprovalHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetPolicyApprovalHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetPolicyApprovalHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyApprovalHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeBody();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool include_body = 1;
 * @return {boolean}
 */
proto.model.GetPolicyApprovalHistoryRequest.prototype.getIncludeBody = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.GetPolicyApprovalHistoryRequest} returns this
 */
proto.model.GetPolicyApprovalHistoryRequest.prototype.setIncludeBody = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.GetPolicyApprovalHistoryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetPolicyApprovalHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetPolicyApprovalHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetPolicyApprovalHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyApprovalHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.model.PolicyDocument.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetPolicyApprovalHistoryResponse}
 */
proto.model.GetPolicyApprovalHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetPolicyApprovalHistoryResponse;
  return proto.model.GetPolicyApprovalHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetPolicyApprovalHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetPolicyApprovalHistoryResponse}
 */
proto.model.GetPolicyApprovalHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.PolicyDocument;
      reader.readMessage(value,proto.model.PolicyDocument.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetPolicyApprovalHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetPolicyApprovalHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetPolicyApprovalHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyApprovalHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.model.PolicyDocument.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PolicyDocument items = 1;
 * @return {!Array<!proto.model.PolicyDocument>}
 */
proto.model.GetPolicyApprovalHistoryResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.model.PolicyDocument>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.PolicyDocument, 1));
};


/**
 * @param {!Array<!proto.model.PolicyDocument>} value
 * @return {!proto.model.GetPolicyApprovalHistoryResponse} returns this
*/
proto.model.GetPolicyApprovalHistoryResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.PolicyDocument=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.PolicyDocument}
 */
proto.model.GetPolicyApprovalHistoryResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.PolicyDocument, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.GetPolicyApprovalHistoryResponse} returns this
 */
proto.model.GetPolicyApprovalHistoryResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.CreateOrUpdatePolicyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.CreateOrUpdatePolicyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CreateOrUpdatePolicyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: (f = msg.getName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    description: (f = msg.getDescription()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    ownerEmail: (f = msg.getOwnerEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isDismissed: (f = msg.getIsDismissed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    departmentId: (f = msg.getDepartmentId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    documentTemplate: (f = msg.getDocumentTemplate()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isTemplate: (f = msg.getIsTemplate()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isCustom: (f = msg.getIsCustom()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.CreateOrUpdatePolicyRequest}
 */
proto.model.CreateOrUpdatePolicyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.CreateOrUpdatePolicyRequest;
  return proto.model.CreateOrUpdatePolicyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.CreateOrUpdatePolicyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.CreateOrUpdatePolicyRequest}
 */
proto.model.CreateOrUpdatePolicyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDescription(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOwnerEmail(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsDismissed(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDepartmentId(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDocumentTemplate(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsTemplate(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsCustom(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.CreateOrUpdatePolicyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.CreateOrUpdatePolicyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CreateOrUpdatePolicyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOwnerEmail();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsDismissed();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDepartmentId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getDocumentTemplate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsTemplate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsCustom();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string model_id = 1;
 * @return {string}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue name = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.getName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
*/
proto.model.CreateOrUpdatePolicyRequest.prototype.setName = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.clearName = function() {
  return this.setName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.hasName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue description = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.getDescription = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
*/
proto.model.CreateOrUpdatePolicyRequest.prototype.setDescription = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.clearDescription = function() {
  return this.setDescription(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue owner_email = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.getOwnerEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
*/
proto.model.CreateOrUpdatePolicyRequest.prototype.setOwnerEmail = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.clearOwnerEmail = function() {
  return this.setOwnerEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.hasOwnerEmail = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue is_dismissed = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.getIsDismissed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
*/
proto.model.CreateOrUpdatePolicyRequest.prototype.setIsDismissed = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.clearIsDismissed = function() {
  return this.setIsDismissed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.hasIsDismissed = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue department_id = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.getDepartmentId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
*/
proto.model.CreateOrUpdatePolicyRequest.prototype.setDepartmentId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.clearDepartmentId = function() {
  return this.setDepartmentId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.hasDepartmentId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue document_template = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.getDocumentTemplate = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
*/
proto.model.CreateOrUpdatePolicyRequest.prototype.setDocumentTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.clearDocumentTemplate = function() {
  return this.setDocumentTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.hasDocumentTemplate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue is_template = 8;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.getIsTemplate = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 8));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
*/
proto.model.CreateOrUpdatePolicyRequest.prototype.setIsTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.clearIsTemplate = function() {
  return this.setIsTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.hasIsTemplate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.BoolValue is_custom = 9;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.getIsCustom = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 9));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
*/
proto.model.CreateOrUpdatePolicyRequest.prototype.setIsCustom = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.clearIsCustom = function() {
  return this.setIsCustom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.hasIsCustom = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string id = 10;
 * @return {string}
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CreateOrUpdatePolicyRequest} returns this
 */
proto.model.CreateOrUpdatePolicyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetPolicyControlsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetPolicyControlsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetPolicyControlsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyControlsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inScope: (f = msg.getInScope()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetPolicyControlsRequest}
 */
proto.model.GetPolicyControlsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetPolicyControlsRequest;
  return proto.model.GetPolicyControlsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetPolicyControlsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetPolicyControlsRequest}
 */
proto.model.GetPolicyControlsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setInScope(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetPolicyControlsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetPolicyControlsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetPolicyControlsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyControlsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInScope();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.GetPolicyControlsRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetPolicyControlsRequest} returns this
 */
proto.model.GetPolicyControlsRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.BoolValue in_scope = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.GetPolicyControlsRequest.prototype.getInScope = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.GetPolicyControlsRequest} returns this
*/
proto.model.GetPolicyControlsRequest.prototype.setInScope = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetPolicyControlsRequest} returns this
 */
proto.model.GetPolicyControlsRequest.prototype.clearInScope = function() {
  return this.setInScope(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetPolicyControlsRequest.prototype.hasInScope = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue audit_id = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.GetPolicyControlsRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.GetPolicyControlsRequest} returns this
*/
proto.model.GetPolicyControlsRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetPolicyControlsRequest} returns this
 */
proto.model.GetPolicyControlsRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetPolicyControlsRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetPolicyFrameworksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetPolicyFrameworksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetPolicyFrameworksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyFrameworksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetPolicyFrameworksRequest}
 */
proto.model.GetPolicyFrameworksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetPolicyFrameworksRequest;
  return proto.model.GetPolicyFrameworksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetPolicyFrameworksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetPolicyFrameworksRequest}
 */
proto.model.GetPolicyFrameworksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetPolicyFrameworksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetPolicyFrameworksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetPolicyFrameworksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyFrameworksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.GetPolicyFrameworksRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetPolicyFrameworksRequest} returns this
 */
proto.model.GetPolicyFrameworksRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.PolicyFrameworkFilter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.PolicyFrameworkFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.model.PolicyFrameworkFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.PolicyFrameworkFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PolicyFrameworkFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceFrameworkId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    frameworkIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    includeNoFramework: (f = msg.getIncludeNoFramework()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.PolicyFrameworkFilter}
 */
proto.model.PolicyFrameworkFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.PolicyFrameworkFilter;
  return proto.model.PolicyFrameworkFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.PolicyFrameworkFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.PolicyFrameworkFilter}
 */
proto.model.PolicyFrameworkFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceFrameworkId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFrameworkIds(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeNoFramework(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.PolicyFrameworkFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.PolicyFrameworkFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.PolicyFrameworkFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.PolicyFrameworkFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceFrameworkId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrameworkIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getIncludeNoFramework();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string compliance_framework_id = 1;
 * @return {string}
 */
proto.model.PolicyFrameworkFilter.prototype.getComplianceFrameworkId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.PolicyFrameworkFilter} returns this
 */
proto.model.PolicyFrameworkFilter.prototype.setComplianceFrameworkId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string framework_ids = 2;
 * @return {!Array<string>}
 */
proto.model.PolicyFrameworkFilter.prototype.getFrameworkIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.PolicyFrameworkFilter} returns this
 */
proto.model.PolicyFrameworkFilter.prototype.setFrameworkIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.PolicyFrameworkFilter} returns this
 */
proto.model.PolicyFrameworkFilter.prototype.addFrameworkIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.PolicyFrameworkFilter} returns this
 */
proto.model.PolicyFrameworkFilter.prototype.clearFrameworkIdsList = function() {
  return this.setFrameworkIdsList([]);
};


/**
 * optional google.protobuf.BoolValue include_no_framework = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.PolicyFrameworkFilter.prototype.getIncludeNoFramework = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.PolicyFrameworkFilter} returns this
*/
proto.model.PolicyFrameworkFilter.prototype.setIncludeNoFramework = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.PolicyFrameworkFilter} returns this
 */
proto.model.PolicyFrameworkFilter.prototype.clearIncludeNoFramework = function() {
  return this.setIncludeNoFramework(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.PolicyFrameworkFilter.prototype.hasIncludeNoFramework = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.ComplianceFrameworkFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ComplianceFrameworkFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ComplianceFrameworkFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ComplianceFrameworkFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ComplianceFrameworkFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceFrameworkIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    includeNone: (f = msg.getIncludeNone()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ComplianceFrameworkFilter}
 */
proto.model.ComplianceFrameworkFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ComplianceFrameworkFilter;
  return proto.model.ComplianceFrameworkFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ComplianceFrameworkFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ComplianceFrameworkFilter}
 */
proto.model.ComplianceFrameworkFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addComplianceFrameworkIds(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeNone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ComplianceFrameworkFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ComplianceFrameworkFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ComplianceFrameworkFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ComplianceFrameworkFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceFrameworkIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getIncludeNone();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string compliance_framework_ids = 1;
 * @return {!Array<string>}
 */
proto.model.ComplianceFrameworkFilter.prototype.getComplianceFrameworkIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.ComplianceFrameworkFilter} returns this
 */
proto.model.ComplianceFrameworkFilter.prototype.setComplianceFrameworkIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.ComplianceFrameworkFilter} returns this
 */
proto.model.ComplianceFrameworkFilter.prototype.addComplianceFrameworkIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.ComplianceFrameworkFilter} returns this
 */
proto.model.ComplianceFrameworkFilter.prototype.clearComplianceFrameworkIdsList = function() {
  return this.setComplianceFrameworkIdsList([]);
};


/**
 * optional google.protobuf.BoolValue include_none = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.ComplianceFrameworkFilter.prototype.getIncludeNone = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.ComplianceFrameworkFilter} returns this
*/
proto.model.ComplianceFrameworkFilter.prototype.setIncludeNone = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ComplianceFrameworkFilter} returns this
 */
proto.model.ComplianceFrameworkFilter.prototype.clearIncludeNone = function() {
  return this.setIncludeNone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ComplianceFrameworkFilter.prototype.hasIncludeNone = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.IsCustomizedFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.model.IsCustomizedFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.IsCustomizedFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.IsCustomizedFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    includeNotCustomized: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    includeCustomized: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.IsCustomizedFilter}
 */
proto.model.IsCustomizedFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.IsCustomizedFilter;
  return proto.model.IsCustomizedFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.IsCustomizedFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.IsCustomizedFilter}
 */
proto.model.IsCustomizedFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeNotCustomized(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeCustomized(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.IsCustomizedFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.IsCustomizedFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.IsCustomizedFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.IsCustomizedFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludeNotCustomized();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getIncludeCustomized();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool include_not_customized = 1;
 * @return {boolean}
 */
proto.model.IsCustomizedFilter.prototype.getIncludeNotCustomized = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.IsCustomizedFilter} returns this
 */
proto.model.IsCustomizedFilter.prototype.setIncludeNotCustomized = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool include_customized = 2;
 * @return {boolean}
 */
proto.model.IsCustomizedFilter.prototype.getIncludeCustomized = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.IsCustomizedFilter} returns this
 */
proto.model.IsCustomizedFilter.prototype.setIncludeCustomized = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.ListPoliciesRequest.repeatedFields_ = [2,3,9,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ListPoliciesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ListPoliciesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ListPoliciesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ListPoliciesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    isDismissed: (f = msg.getIsDismissed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    departmentIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    ownersList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    hasDocumentation: (f = msg.getHasDocumentation()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    includeUnassigned: (f = msg.getIncludeUnassigned()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    excludedFromAudit: (f = msg.getExcludedFromAudit()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    auditIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f,
    complianceFrameworkFilter: (f = msg.getComplianceFrameworkFilter()) && proto.model.ComplianceFrameworkFilter.toObject(includeInstance, f),
    policySort: (f = msg.getPolicySort()) && proto.model.PolicySort.toObject(includeInstance, f),
    frameworkFiltersList: jspb.Message.toObjectList(msg.getFrameworkFiltersList(),
    proto.model.PolicyFrameworkFilter.toObject, includeInstance),
    isCustomizedFilter: (f = msg.getIsCustomizedFilter()) && proto.model.IsCustomizedFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ListPoliciesRequest}
 */
proto.model.ListPoliciesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ListPoliciesRequest;
  return proto.model.ListPoliciesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ListPoliciesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ListPoliciesRequest}
 */
proto.model.ListPoliciesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsDismissed(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addDepartmentIds(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addOwners(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHasDocumentation(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeUnassigned(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setExcludedFromAudit(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuditIds(value);
      break;
    case 10:
      var value = new proto.model.ComplianceFrameworkFilter;
      reader.readMessage(value,proto.model.ComplianceFrameworkFilter.deserializeBinaryFromReader);
      msg.setComplianceFrameworkFilter(value);
      break;
    case 11:
      var value = new proto.model.PolicySort;
      reader.readMessage(value,proto.model.PolicySort.deserializeBinaryFromReader);
      msg.setPolicySort(value);
      break;
    case 12:
      var value = new proto.model.PolicyFrameworkFilter;
      reader.readMessage(value,proto.model.PolicyFrameworkFilter.deserializeBinaryFromReader);
      msg.addFrameworkFilters(value);
      break;
    case 13:
      var value = new proto.model.IsCustomizedFilter;
      reader.readMessage(value,proto.model.IsCustomizedFilter.deserializeBinaryFromReader);
      msg.setIsCustomizedFilter(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ListPoliciesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ListPoliciesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ListPoliciesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ListPoliciesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsDismissed();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getDepartmentIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getOwnersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getHasDocumentation();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIncludeUnassigned();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getExcludedFromAudit();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getAuditIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      9,
      f
    );
  }
  f = message.getComplianceFrameworkFilter();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.model.ComplianceFrameworkFilter.serializeBinaryToWriter
    );
  }
  f = message.getPolicySort();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.model.PolicySort.serializeBinaryToWriter
    );
  }
  f = message.getFrameworkFiltersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.model.PolicyFrameworkFilter.serializeBinaryToWriter
    );
  }
  f = message.getIsCustomizedFilter();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.model.IsCustomizedFilter.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.BoolValue is_dismissed = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.ListPoliciesRequest.prototype.getIsDismissed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.ListPoliciesRequest} returns this
*/
proto.model.ListPoliciesRequest.prototype.setIsDismissed = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.clearIsDismissed = function() {
  return this.setIsDismissed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListPoliciesRequest.prototype.hasIsDismissed = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string department_ids = 2;
 * @return {!Array<string>}
 */
proto.model.ListPoliciesRequest.prototype.getDepartmentIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.setDepartmentIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.addDepartmentIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.clearDepartmentIdsList = function() {
  return this.setDepartmentIdsList([]);
};


/**
 * repeated string owners = 3;
 * @return {!Array<string>}
 */
proto.model.ListPoliciesRequest.prototype.getOwnersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.setOwnersList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.addOwners = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.clearOwnersList = function() {
  return this.setOwnersList([]);
};


/**
 * optional google.protobuf.BoolValue has_documentation = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.ListPoliciesRequest.prototype.getHasDocumentation = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.ListPoliciesRequest} returns this
*/
proto.model.ListPoliciesRequest.prototype.setHasDocumentation = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.clearHasDocumentation = function() {
  return this.setHasDocumentation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListPoliciesRequest.prototype.hasHasDocumentation = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.BoolValue include_unassigned = 6;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.ListPoliciesRequest.prototype.getIncludeUnassigned = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 6));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.ListPoliciesRequest} returns this
*/
proto.model.ListPoliciesRequest.prototype.setIncludeUnassigned = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.clearIncludeUnassigned = function() {
  return this.setIncludeUnassigned(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListPoliciesRequest.prototype.hasIncludeUnassigned = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue audit_id = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.ListPoliciesRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.ListPoliciesRequest} returns this
*/
proto.model.ListPoliciesRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListPoliciesRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.BoolValue excluded_from_audit = 8;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.ListPoliciesRequest.prototype.getExcludedFromAudit = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 8));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.ListPoliciesRequest} returns this
*/
proto.model.ListPoliciesRequest.prototype.setExcludedFromAudit = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.clearExcludedFromAudit = function() {
  return this.setExcludedFromAudit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListPoliciesRequest.prototype.hasExcludedFromAudit = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * repeated string audit_ids = 9;
 * @return {!Array<string>}
 */
proto.model.ListPoliciesRequest.prototype.getAuditIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.setAuditIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.addAuditIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.clearAuditIdsList = function() {
  return this.setAuditIdsList([]);
};


/**
 * optional ComplianceFrameworkFilter compliance_framework_filter = 10;
 * @return {?proto.model.ComplianceFrameworkFilter}
 */
proto.model.ListPoliciesRequest.prototype.getComplianceFrameworkFilter = function() {
  return /** @type{?proto.model.ComplianceFrameworkFilter} */ (
    jspb.Message.getWrapperField(this, proto.model.ComplianceFrameworkFilter, 10));
};


/**
 * @param {?proto.model.ComplianceFrameworkFilter|undefined} value
 * @return {!proto.model.ListPoliciesRequest} returns this
*/
proto.model.ListPoliciesRequest.prototype.setComplianceFrameworkFilter = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.clearComplianceFrameworkFilter = function() {
  return this.setComplianceFrameworkFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListPoliciesRequest.prototype.hasComplianceFrameworkFilter = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional PolicySort policy_sort = 11;
 * @return {?proto.model.PolicySort}
 */
proto.model.ListPoliciesRequest.prototype.getPolicySort = function() {
  return /** @type{?proto.model.PolicySort} */ (
    jspb.Message.getWrapperField(this, proto.model.PolicySort, 11));
};


/**
 * @param {?proto.model.PolicySort|undefined} value
 * @return {!proto.model.ListPoliciesRequest} returns this
*/
proto.model.ListPoliciesRequest.prototype.setPolicySort = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.clearPolicySort = function() {
  return this.setPolicySort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListPoliciesRequest.prototype.hasPolicySort = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated PolicyFrameworkFilter framework_filters = 12;
 * @return {!Array<!proto.model.PolicyFrameworkFilter>}
 */
proto.model.ListPoliciesRequest.prototype.getFrameworkFiltersList = function() {
  return /** @type{!Array<!proto.model.PolicyFrameworkFilter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.PolicyFrameworkFilter, 12));
};


/**
 * @param {!Array<!proto.model.PolicyFrameworkFilter>} value
 * @return {!proto.model.ListPoliciesRequest} returns this
*/
proto.model.ListPoliciesRequest.prototype.setFrameworkFiltersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.model.PolicyFrameworkFilter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.PolicyFrameworkFilter}
 */
proto.model.ListPoliciesRequest.prototype.addFrameworkFilters = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.model.PolicyFrameworkFilter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.clearFrameworkFiltersList = function() {
  return this.setFrameworkFiltersList([]);
};


/**
 * optional IsCustomizedFilter is_customized_filter = 13;
 * @return {?proto.model.IsCustomizedFilter}
 */
proto.model.ListPoliciesRequest.prototype.getIsCustomizedFilter = function() {
  return /** @type{?proto.model.IsCustomizedFilter} */ (
    jspb.Message.getWrapperField(this, proto.model.IsCustomizedFilter, 13));
};


/**
 * @param {?proto.model.IsCustomizedFilter|undefined} value
 * @return {!proto.model.ListPoliciesRequest} returns this
*/
proto.model.ListPoliciesRequest.prototype.setIsCustomizedFilter = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListPoliciesRequest} returns this
 */
proto.model.ListPoliciesRequest.prototype.clearIsCustomizedFilter = function() {
  return this.setIsCustomizedFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListPoliciesRequest.prototype.hasIsCustomizedFilter = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DismissRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DismissRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DismissRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DismissRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dismiss: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DismissRequest}
 */
proto.model.DismissRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DismissRequest;
  return proto.model.DismissRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DismissRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DismissRequest}
 */
proto.model.DismissRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDismiss(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DismissRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DismissRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DismissRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DismissRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDismiss();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.DismissRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.DismissRequest} returns this
 */
proto.model.DismissRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool dismiss = 2;
 * @return {boolean}
 */
proto.model.DismissRequest.prototype.getDismiss = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.DismissRequest} returns this
 */
proto.model.DismissRequest.prototype.setDismiss = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetPoliciesForComplianceFrameworkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetPoliciesForComplianceFrameworkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetPoliciesForComplianceFrameworkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPoliciesForComplianceFrameworkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceFrameworkId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetPoliciesForComplianceFrameworkRequest}
 */
proto.model.GetPoliciesForComplianceFrameworkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetPoliciesForComplianceFrameworkRequest;
  return proto.model.GetPoliciesForComplianceFrameworkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetPoliciesForComplianceFrameworkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetPoliciesForComplianceFrameworkRequest}
 */
proto.model.GetPoliciesForComplianceFrameworkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceFrameworkId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetPoliciesForComplianceFrameworkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetPoliciesForComplianceFrameworkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetPoliciesForComplianceFrameworkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPoliciesForComplianceFrameworkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceFrameworkId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string compliance_framework_id = 1;
 * @return {string}
 */
proto.model.GetPoliciesForComplianceFrameworkRequest.prototype.getComplianceFrameworkId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetPoliciesForComplianceFrameworkRequest} returns this
 */
proto.model.GetPoliciesForComplianceFrameworkRequest.prototype.setComplianceFrameworkId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetPolicyForComplianceFrameworkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetPolicyForComplianceFrameworkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetPolicyForComplianceFrameworkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyForComplianceFrameworkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    complianceFrameworkId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetPolicyForComplianceFrameworkRequest}
 */
proto.model.GetPolicyForComplianceFrameworkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetPolicyForComplianceFrameworkRequest;
  return proto.model.GetPolicyForComplianceFrameworkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetPolicyForComplianceFrameworkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetPolicyForComplianceFrameworkRequest}
 */
proto.model.GetPolicyForComplianceFrameworkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceFrameworkId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetPolicyForComplianceFrameworkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetPolicyForComplianceFrameworkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetPolicyForComplianceFrameworkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPolicyForComplianceFrameworkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComplianceFrameworkId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string model_id = 1;
 * @return {string}
 */
proto.model.GetPolicyForComplianceFrameworkRequest.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetPolicyForComplianceFrameworkRequest} returns this
 */
proto.model.GetPolicyForComplianceFrameworkRequest.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string compliance_framework_id = 2;
 * @return {string}
 */
proto.model.GetPolicyForComplianceFrameworkRequest.prototype.getComplianceFrameworkId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetPolicyForComplianceFrameworkRequest} returns this
 */
proto.model.GetPolicyForComplianceFrameworkRequest.prototype.setComplianceFrameworkId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetPoliciesInAccountForComplianceFrameworkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetPoliciesInAccountForComplianceFrameworkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetPoliciesInAccountForComplianceFrameworkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPoliciesInAccountForComplianceFrameworkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceFrameworkId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetPoliciesInAccountForComplianceFrameworkRequest}
 */
proto.model.GetPoliciesInAccountForComplianceFrameworkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetPoliciesInAccountForComplianceFrameworkRequest;
  return proto.model.GetPoliciesInAccountForComplianceFrameworkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetPoliciesInAccountForComplianceFrameworkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetPoliciesInAccountForComplianceFrameworkRequest}
 */
proto.model.GetPoliciesInAccountForComplianceFrameworkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComplianceFrameworkId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetPoliciesInAccountForComplianceFrameworkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetPoliciesInAccountForComplianceFrameworkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetPoliciesInAccountForComplianceFrameworkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPoliciesInAccountForComplianceFrameworkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceFrameworkId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string compliance_framework_id = 1;
 * @return {string}
 */
proto.model.GetPoliciesInAccountForComplianceFrameworkRequest.prototype.getComplianceFrameworkId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetPoliciesInAccountForComplianceFrameworkRequest} returns this
 */
proto.model.GetPoliciesInAccountForComplianceFrameworkRequest.prototype.setComplianceFrameworkId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetPreparedPoliciesBreakdownRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetPreparedPoliciesBreakdownRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetPreparedPoliciesBreakdownRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPreparedPoliciesBreakdownRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    policyIds: (f = msg.getPolicyIds()) && common_collections_pb.StringList.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetPreparedPoliciesBreakdownRequest}
 */
proto.model.GetPreparedPoliciesBreakdownRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetPreparedPoliciesBreakdownRequest;
  return proto.model.GetPreparedPoliciesBreakdownRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetPreparedPoliciesBreakdownRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetPreparedPoliciesBreakdownRequest}
 */
proto.model.GetPreparedPoliciesBreakdownRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_collections_pb.StringList;
      reader.readMessage(value,common_collections_pb.StringList.deserializeBinaryFromReader);
      msg.setPolicyIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetPreparedPoliciesBreakdownRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetPreparedPoliciesBreakdownRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetPreparedPoliciesBreakdownRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPreparedPoliciesBreakdownRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPolicyIds();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_collections_pb.StringList.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.StringList policy_ids = 1;
 * @return {?proto.common.StringList}
 */
proto.model.GetPreparedPoliciesBreakdownRequest.prototype.getPolicyIds = function() {
  return /** @type{?proto.common.StringList} */ (
    jspb.Message.getWrapperField(this, common_collections_pb.StringList, 1));
};


/**
 * @param {?proto.common.StringList|undefined} value
 * @return {!proto.model.GetPreparedPoliciesBreakdownRequest} returns this
*/
proto.model.GetPreparedPoliciesBreakdownRequest.prototype.setPolicyIds = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetPreparedPoliciesBreakdownRequest} returns this
 */
proto.model.GetPreparedPoliciesBreakdownRequest.prototype.clearPolicyIds = function() {
  return this.setPolicyIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetPreparedPoliciesBreakdownRequest.prototype.hasPolicyIds = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetPreparedPoliciesBreakdownResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetPreparedPoliciesBreakdownResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetPreparedPoliciesBreakdownResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPreparedPoliciesBreakdownResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    noControlsCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    noDocumentationCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    preparedCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetPreparedPoliciesBreakdownResponse}
 */
proto.model.GetPreparedPoliciesBreakdownResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetPreparedPoliciesBreakdownResponse;
  return proto.model.GetPreparedPoliciesBreakdownResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetPreparedPoliciesBreakdownResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetPreparedPoliciesBreakdownResponse}
 */
proto.model.GetPreparedPoliciesBreakdownResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNoControlsCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNoDocumentationCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPreparedCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetPreparedPoliciesBreakdownResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetPreparedPoliciesBreakdownResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetPreparedPoliciesBreakdownResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetPreparedPoliciesBreakdownResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNoControlsCount();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNoDocumentationCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPreparedCount();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 no_controls_count = 1;
 * @return {number}
 */
proto.model.GetPreparedPoliciesBreakdownResponse.prototype.getNoControlsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.GetPreparedPoliciesBreakdownResponse} returns this
 */
proto.model.GetPreparedPoliciesBreakdownResponse.prototype.setNoControlsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 no_documentation_count = 2;
 * @return {number}
 */
proto.model.GetPreparedPoliciesBreakdownResponse.prototype.getNoDocumentationCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.GetPreparedPoliciesBreakdownResponse} returns this
 */
proto.model.GetPreparedPoliciesBreakdownResponse.prototype.setNoDocumentationCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 prepared_count = 3;
 * @return {number}
 */
proto.model.GetPreparedPoliciesBreakdownResponse.prototype.getPreparedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.GetPreparedPoliciesBreakdownResponse} returns this
 */
proto.model.GetPreparedPoliciesBreakdownResponse.prototype.setPreparedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.model);

import React, { FormEventHandler } from 'react'
import { faBan, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SelectedCountSpan } from 'src/components/ModalForms/ModalForm.styles'
import { ModalFormId } from '../../ModalForms'
import { TextButton } from '../Buttons/TextButton'
import { Grid } from '../Grid'
import { Spinner } from '../../../Throbber'
import palette from '../../../designSystem/variables/palette'
import { TestIds } from '../../../Utils/testIds'

const StyledTitle = styled.p`
  padding-bottom: 10px;
  font-size: 14px;
  color: ${palette.neutral[900]};
`

const GridContainer = styled.div<{ $noScroll?: boolean }>`
  ${({ $noScroll }) =>
    $noScroll
      ? ``
      : `max-height: 350px;
  overflow-y: auto;`}
  width: 100%;
  margin-top: 25px;
`

const ItemGrid = styled(Grid)`
  width: 100%;
`

const Icon = styled(FontAwesomeIcon)`
  color: ${palette.neutral[600]};
  margin-right: 5px;
`

export type DependentMultiSelectFormProps =
  | {
      showSelectButtons?: false
      handleSelectAll?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
      handleDeselectAll?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
      customSelectButtons?: JSX.Element
    }
  | {
      showSelectButtons: true
      handleSelectAll: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
      handleDeselectAll: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
      customSelectButtons?: JSX.Element
    }

interface CommonMultiSelectFormProps {
  loading: boolean
  formId: ModalFormId
  title?: string
  gridItems: JSX.Element | JSX.Element[]
  showSelectButtons?: boolean
  onSubmit?: FormEventHandler<HTMLFormElement>
  gridTemplateColumnStyling: string
  numberSelectedText?: string
  showEmpty?: boolean
  emptyState?: JSX.Element
  noScroll?: boolean
}

export type MultiSelectFormProps = DependentMultiSelectFormProps &
  CommonMultiSelectFormProps

export const MultiSelectForm = ({
  loading,
  formId,
  title,
  showSelectButtons,
  handleSelectAll,
  handleDeselectAll,
  gridItems,
  onSubmit,
  gridTemplateColumnStyling,
  showEmpty,
  emptyState,
  numberSelectedText,
  customSelectButtons,
  noScroll,
}: MultiSelectFormProps): JSX.Element =>
  loading ? (
    <Spinner color="primary" size="m" data-testid={TestIds.SPINNER} />
  ) : (
    <form id={formId} onSubmit={onSubmit}>
      {!!title && <StyledTitle>{title}</StyledTitle>}
      {showSelectButtons && (
        <>
          <TextButton
            onClick={handleDeselectAll}
            style={{ marginRight: '16px' }}
          >
            <>
              <Icon icon={faBan} />
              Deselect All
            </>
          </TextButton>
          <TextButton onClick={handleSelectAll}>
            <>
              <Icon icon={faPlusCircle} />
              Select All
            </>
          </TextButton>
          {customSelectButtons}
        </>
      )}
      {numberSelectedText && (
        <SelectedCountSpan>{numberSelectedText}</SelectedCountSpan>
      )}
      {showEmpty ? (
        emptyState
      ) : (
        <GridContainer $noScroll={noScroll}>
          <ItemGrid gridTemplateColumns={gridTemplateColumnStyling}>
            {gridItems}
          </ItemGrid>
        </GridContainer>
      )}
    </form>
  )

MultiSelectForm.defaultProps = {
  gridTemplateColumnStyling: 'min-content min-content 1fr',
}

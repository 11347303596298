import React, { Fragment } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { PERMISSIONS } from 'src/config/roleConfig'
import { TextButton } from '../../Buttons/TextButton'
import { FilterParam } from './FilterBar.types'

export const ClearAllFilters = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  /**
   * Current url whose query strings are filtered to include only FilterParams
   */
  const strippedUrl = queryString.pick(
    `${location.pathname}${location.search}`,
    Object.values(FilterParam),
    {
      arrayFormat: 'bracket',
    },
  )
  /** Object of applied FilterParams */
  const filterParams = queryString.parseUrl(strippedUrl, {
    arrayFormat: 'bracket',
  }).query
  /** Have any FilterParams have been applied */
  const hasAppliedFilters = Object.keys(filterParams).length > 0

  if (!hasAppliedFilters) return <Fragment />

  const clearFilters = () => {
    navigate(
      queryString.exclude(
        `${location.pathname}${location.search}`,
        Object.values(FilterParam),
        { arrayFormat: 'bracket' },
      ),
      { replace: true },
    )
  }

  return (
    <TextButton requiredPermissions={[PERMISSIONS.READ]} onClick={clearFilters}>
      Clear All
    </TextButton>
  )
}

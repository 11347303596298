import React from 'react'
import { Range } from 'react-date-range'
import { PERMISSIONS } from 'src/config/roleConfig'
import {
  Tooltip,
  TooltipOverlayType,
  TooltipPositions,
} from '../Tooltip/Tooltip'
import {
  DateFilterContainer,
  DateFilterWrapper,
} from '../../../pages/Controls/ControlsShowPage/ControlsShowItem/ControlsShowItem.styles'
import { InfoButton } from '../../PageLayout/InfoButton'
import { CheckBoxInput } from '../Inputs/CheckBox'
import { TrusteroDateRange } from './TrusteroDateRange'
import { ShowLatestInfoButton } from './ShowLatestDateFilter.styles'

type ShowLatestDateFilterProps = {
  showLatest: boolean
  setShowLatest: React.Dispatch<React.SetStateAction<boolean>>
  showLatestCb?: () => void
  currentAudit?: string
  dateRange: Range
  setDateRange: React.Dispatch<React.SetStateAction<Range>>
  setDateRangeCb?: () => void
  tooltipBody: string
  additionalToolTip?: boolean
  additionalToolTipBody?: string
  hide?: boolean
}

export const ShowLatestDateFilter = ({
  showLatest,
  setShowLatest,
  showLatestCb,
  currentAudit,
  dateRange,
  setDateRange,
  setDateRangeCb,
  tooltipBody,
  additionalToolTip,
  additionalToolTipBody,
  hide = false,
}: ShowLatestDateFilterProps): JSX.Element => {
  if (hide) {
    return <></>
  }

  return (
    <DateFilterWrapper>
      <DateFilterContainer>
        <CheckBoxInput
          data-testid="SHOW_LATEST_CHECKBOX"
          id="Show Latest"
          label="Show Latest"
          checked={showLatest}
          onChange={() => {
            setShowLatest((prev) => !prev)
            showLatestCb?.()
          }}
          requiredPermissions={[PERMISSIONS.READ]}
        />
        <Tooltip
          id="show-latest-tooltip"
          placement={TooltipPositions.top}
          overlayType={TooltipOverlayType.popover}
          tooltipBody={tooltipBody}
        >
          <ShowLatestInfoButton as="div" ml="xxxs" />
        </Tooltip>
        <TrusteroDateRange
          disabled={showLatest || Boolean(currentAudit)}
          range={dateRange}
          rangeSet={(range) => {
            setDateRange(range)
            setDateRangeCb?.()
          }}
        />
        {additionalToolTip && (
          <Tooltip
            id="show-latest-tooltip"
            placement={TooltipPositions.top}
            overlayType={TooltipOverlayType.popover}
            tooltipBody={additionalToolTipBody}
          >
            <InfoButton as="div" ml="xxxs" />
          </Tooltip>
        )}
      </DateFilterContainer>
    </DateFilterWrapper>
  )
}

import React, { ReactElement } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { SubpageNavItemContainer } from './PageLayout.styles'

type SubpageNavItemProps = NavLinkProps
export default function SubpageNavItem({
  to,
  end,
  className,
  children,
  ...rest
}: SubpageNavItemProps): ReactElement {
  return (
    <SubpageNavItemContainer>
      <NavLink end={end} to={to} className={className} {...rest}>
        {children}
      </NavLink>
    </SubpageNavItemContainer>
  )
}

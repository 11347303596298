import React, { PropsWithChildren } from 'react'
import { SideBarContainer, SideBarHeader } from './Help.styles'

export function SideBar({
  title,
  children,
}: PropsWithChildren<{
  title: string
}>): JSX.Element {
  return (
    <SideBarContainer>
      <SideBarHeader>{title}</SideBarHeader>
      {children}
    </SideBarContainer>
  )
}

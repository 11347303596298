import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { useHref } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { AccordionEventKey } from 'react-bootstrap/AccordionContext'
import {
  ListReceptorIds,
  ReceptorComponent,
} from '../../../components/async/model/receptor'
import { HelpAbsoluteRoutes } from '../../Help/HelpRoutes'
import {
  ActivateReceptorForm,
  SelectReceptorForm,
  SelectReceptorFormToggle,
} from '../../../components/ModalForms/Receptor'
import { QuickstartPageHashes } from '../../Help/Quickstart/QuickstartPage'
import { InfoLinkSection } from '../../../components/Reusable/Text/InfoLink.styles'
import { ReactComponent as TuneIcon } from '../../../components/Icons/assets/tune-icon.svg'
import { NoResults } from '../../../components/PageLayout/NoResults'
import { NoReceptorIcons } from '../../../components/Icons/EmptyStateIcons/NoReceptorIcons'
import { FilterBarContainer } from '../../../components/Reusable/IndexPage/FilterBar/FilterBar.styles'
import { ReceptorsFilterDropdown } from './ReceptorsFilter'
import { ReceptorItem } from './ReceptorItem'
import { ReceptorsFilterBarContainer } from './styles'

export const ReceptorsPage = (): JSX.Element => {
  const [selected, setSelected] = useState<AccordionEventKey>()
  const [filterSelect, setFilterSelect] = useState({
    activated: true,
    deactivated: false,
  })

  const infoLink = useHref({
    pathname: `../../${HelpAbsoluteRoutes.QUICKSTART}`,
    hash: QuickstartPageHashes.RECEPTORS,
  })
  return (
    <>
      <ListReceptorIds
        filterSelect={filterSelect}
        setFilterSelect={setFilterSelect}
        child={({ receptorIds, totalReceptors }) => {
          return (
            <>
              <ReceptorsFilterBarContainer>
                <TuneIcon />
                <ReceptorsFilterDropdown
                  filterSelect={filterSelect}
                  setFilterSelect={setFilterSelect}
                />
              </ReceptorsFilterBarContainer>
              <FilterBarContainer mb="l">
                <InfoLinkSection>
                  Receptors automatically gather evidence.{' '}
                  <HashLink to={infoLink}>Learn more</HashLink>
                </InfoLinkSection>
                <SelectReceptorFormToggle />
              </FilterBarContainer>
              <Accordion onSelect={(eventKey) => setSelected(eventKey)}>
                <>
                  {totalReceptors === 0 ? (
                    <NoResults
                      largeTitle
                      icons={<NoReceptorIcons />}
                      title="Connect services you use. Add a receptor."
                      buttons={<SelectReceptorFormToggle />}
                    />
                  ) : (
                    receptorIds.map((receptorId) => (
                      <ReceptorComponent
                        key={receptorId}
                        id={receptorId}
                        child={({ receptor }) => {
                          return (
                            <ReceptorItem
                              receptor={receptor.toObject()}
                              isSelected={selected === receptorId}
                            />
                          )
                        }}
                      />
                    ))
                  )}
                </>
              </Accordion>
            </>
          )
        }}
      />
      <SelectReceptorForm />
      <ActivateReceptorForm />
    </>
  )
}

import React from 'react'
import { ReceptorRecords } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import ContentLoader from 'react-content-loader'
import { BaseProps, OnResponse } from '../../types'
import { useAccountReceptors } from './useReceptors'

type ReceptorListProps<ExternalProps> = BaseProps<
  ExternalProps,
  OnResponse<ExternalProps, ReceptorRecords>
>

export function ReceptorListComponent<ExternalProps>(
  receptorListComponentProps: ReceptorListProps<ExternalProps>,
): JSX.Element {
  const response = useAccountReceptors()

  if (response.isValidating) {
    return receptorListComponentProps.placeholder || <ContentLoader />
  }
  if (response.error || !response.data) {
    return receptorListComponentProps.onError ? (
      receptorListComponentProps.onError({
        error: response.error ?? new Error('not found'),
        props: receptorListComponentProps.props,
      })
    ) : (
      <></>
    )
  }

  return receptorListComponentProps.child({
    props: receptorListComponentProps.props,
    response: response.data,
    mutate: response.mutate,
  })
}

import React from 'react'
import { usePolicy } from '../../../components/async/model'
import { Spinner } from '../../../Throbber'
import { PolicyInfo } from './PolicyInfo'

type Props = {
  modelId: string
}

export const PolicyTitle = (props: Props): JSX.Element => {
  const response = usePolicy(props.modelId)
  if (!props.modelId || response.error) {
    return <PolicyInfo error policyName={'No Policy'} />
  } else if (!response.data) {
    return <Spinner size="s" color="primary" />
  } else {
    const policyName = response.data?.getName()
    return <PolicyInfo policyName={policyName} />
  }
}

import themeGet from '@styled-system/theme-get'
import { Tabs } from 'react-bootstrap'
import styled from 'styled-components/macro'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import { ReactComponent as PermalinkIcon } from '../../../Icons/assets/permalink-icon.svg'

export const StyledEvidenceP = styled.p`
  display: flex;
  align-items: flex-end;
  height: 48px;
`

export const CustomTabs = styled(Tabs)`
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid ${themeGet('colors.border.neutral.light')};
  border-radius: 6px;
  overflow: clip;
  background-color: white;

  .nav-item {
    :not(:last-child) {
      .nav-link:not(.active) {
        border-right: 1px solid ${themeGet('colors.border.neutral.light')};
      }
    }

    .nav-link {
      height: 100%;
      border-radius: 0;

      & {
        svg {
          path,
          polygon {
            fill: ${themeGet('colors.text.icon.default')};
          }
        }
      }

      &.active {
        background-color: ${themeGet('colors.fill.neutral.extraDark')};

        svg {
          path,
          polygon {
            fill: white;
          }
        }
      }
    }
  }
`
export const EvidenceHeader = styled.div`
  padding: 0px 0px 24px;
  overflow-y: auto;
  max-height: 200px;
`

export const StyledPermalinkIcon = styled(PermalinkIcon)`
  width: 24px;
  height: 24px;
`

export const FileDownloadBtn = styled(TextButton)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75em;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const StyledImagePreview = styled.img`
  width: 600px;
  height: auto;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`

export const StyledIframePreview = styled.iframe`
  width: 600px;
  height: auto;
  cursor: pointer;
`

export const StyledPreviewModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  gap: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const StyledPreviewModalImage = styled.img`
  max-width: 90%;
  max-height: 90%;
`

export const StyledPreviewModalIframe = styled.iframe`
  width: 90%;
  height: 90%;
  max-width: 90%;
  max-height: 90%;
`

export const PreviewDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  :hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`

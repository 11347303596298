import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  DocumentRequest,
  ListDocumentRequestsRequest,
} from '@trustero/trustero-api-web/lib/request/request_pb'
import ContentLoader from 'react-content-loader'
import queryString from 'query-string'
import { useModalState } from 'src/Modal/ModalStateContext'
import { useCurrentEvidenceId } from 'src/context/FormContext/CurrentEvidenceContext'
import { showInfoToast } from 'src/Utils/helpers/toast'
import { useDocumentRequests } from '../../components/async/DocumentRequest'
import { ViewEvidenceForm } from '../../components/ModalForms/Evidence'
import { ViewEvidenceQueryParams } from '../../components/ModalForms/Evidence/ViewEvidenceForm/ViewEvidenceForm.utils'
import { ModalFormId } from '../../components/ModalForms'
import { RootPage } from '../../components/Reusable/RootPage'
import { ROOT_ID_PARAM_TYPE } from '../../components/Reusable/RootPage/RootPage.constants'
import { RequestShowPage } from './RequestShowPage'
import { RequestsIndexPage } from './RequestsIndexPage'

export enum RequestsAbsoluteRoutes {
  ROOT = 'requests',
  INDEX = 'requests/index',
  SHOW = 'requests/show',
}

enum RequestsSubRoutes {
  INDEX = 'index',
  SHOW = 'show',
}

export const ProtectRequestShowPage = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const { requestId } = useParams()
  const { openModal } = useModalState()
  const req = new ListDocumentRequestsRequest()
  const { data, isLoading } = useDocumentRequests(req)
  const { evidenceId: currentEvidenceId, setEvidenceId } =
    useCurrentEvidenceId()
  const query = queryString.parse(location.search, {
    arrayFormat: 'bracket',
  })
  const evidenceId = query[ViewEvidenceQueryParams.EVIDENCE_ID] as string
  const showEvidenceModal =
    query[ViewEvidenceQueryParams.MODAL_FORM_ID] === ModalFormId.VIEW_EVIDENCE

  if (showEvidenceModal) {
    setEvidenceId(evidenceId)
    openModal(ModalFormId.VIEW_EVIDENCE)
  }

  if (isLoading || !data) {
    return <ContentLoader data-testid="CONTENT_LOADER" />
  }

  const request = data
    .getItemsList()
    .find((req: DocumentRequest) => req.getId() === requestId)
  if (!request) {
    showInfoToast('This request does not exist')
    // Ensure routing change fires after component returns fragment
    setTimeout(() => navigate(`../${RequestsAbsoluteRoutes.INDEX}`))
    return <></>
  }

  return (
    <>
      <RequestShowPage request={request} />
      {currentEvidenceId && <ViewEvidenceForm titleName="request" />}
    </>
  )
}

export const RequestsRootPage = (): JSX.Element => (
  <RootPage
    idParamType={ROOT_ID_PARAM_TYPE.REQUEST_ID}
    indexRoute={RequestsSubRoutes.INDEX}
    indexPage={<RequestsIndexPage />}
    showRoute={RequestsSubRoutes.SHOW}
    showPage={<ProtectRequestShowPage />}
    notAvailableString="Requests only available within an audit"
    onlyInAudit
  />
)

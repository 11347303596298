export enum GlossaryPageHashes {
  ASSET = 'asset',
  AUDIT_READY = 'audit-ready',
  COMPENSATING_CONTROL = 'compensating-control',
  CONTROLS = 'controls',
  EXCEPTIONS = 'exceptions',
  EVIDENCE = 'evidence',
  FIXIT = 'fixit',
  FRAMEWORKS = 'frameworks',
  IMPACT_OF_RISK = 'impact-of-risk',
  INHERENT_RISK = 'inherent-risk',
  MITIGATING_CONTROL = 'mitigating-control',
  NEEDS_ATTENTION = 'needs-attention',
  NO_EXCEPTIONS = 'no-exceptions',
  POLICIES = 'policies',
  PREDISPOSING_CONDITION = 'predisposing-condition',
  PRO_FORMA_SOC2 = 'pro-forma-soc2',
  RECEPTORS = 'receptors',
  RESIDUAL_RISK = 'residual-risk',
  RISK_RESPONSE = 'risk-response',
  RISK_OWNER = 'risk-owner',
  SCOPE = 'scope',
  SERVICES = 'services',
  TESTS = 'tests',
  THREAT = 'threat',
  VULNERABILITY = 'vulnerability',
}

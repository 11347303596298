import React from 'react'
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'
import { Dropdown } from 'react-bootstrap'
import { FlexColumn, FlexRow } from '../Flex'

export const DropdownContainer = styled.div<{ $hasSelected: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;

  label {
    cursor: pointer;
  }

  .selected-value {
    color: ${({ $hasSelected }) =>
      $hasSelected ? palette.neutral.black : palette.neutral[500]};
  }
  .dropdown-toggle[aria-expanded='true'] {
    .selected-value {
      color: ${palette.neutral.white};
    }
  }
`

export const SelectDropDownSelectedContainer = styled(FlexRow)`
  justify-content: space-between;
  position: relative;
`

export const SelectDropDownSelected = styled.span.attrs({
  className: 'selected-value',
})`
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  word-break: break-word;
  text-overflow: ellipsis;
`

export const SelectMenu = styled(Dropdown.Menu)`
  &.dropdown-menu.show {
    width: 100%;
    max-height: 400px;
    max-width: unset;
  }

  .form-check-input {
    margin-top: 0;
  }
`

export const SelectedIcon = (): JSX.Element => (
  <FontAwesomeIcon
    icon={faCircleCheck}
    size="4x"
    color={`${palette.neutral.white}`}
  />
)

export const MultiSelectIconsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const MultiSelectContainer = styled(FlexRow)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const MultiSelectLabel = styled.label`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  text-align: left;
`

export const MultiSelectOption = styled(FlexColumn)<{
  isSelected: boolean
  isDisabled?: boolean
}>`
  height: 190px;
  padding: 3em;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: ${({ isSelected, isDisabled }) =>
    isDisabled
      ? palette.neutral[200]
      : isSelected
      ? palette.neutral[500]
      : palette.neutral.white};
  color: ${({ isSelected, isDisabled }) =>
    isDisabled
      ? palette.neutral[400]
      : isSelected
      ? palette.neutral.white
      : 'initial'};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

  :not(:last-child) {
    margin-right: 2em;
  }

  :hover {
    background-color: ${({ isSelected, isDisabled }) =>
      isDisabled
        ? palette.neutral[200]
        : isSelected
        ? palette.neutral[800]
        : palette.neutral[50]};
  }
`

export const IconOptionName = styled.div`
  position: relative;
  top: 1.25em;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
`

export const RowLabel = styled.span<{ $isMultiSelect?: boolean }>`
  margin-left: ${({ $isMultiSelect }) => ($isMultiSelect ? '1em' : '0')};
`

import React from 'react'
import styled, { css } from 'styled-components/macro'
import { color } from 'styled-system'
import palette from 'src/designSystem/variables/palette'
import themeGet from '@styled-system/theme-get'
import { ErrorSVG } from '../../Icons/Basic'

export const EditableOnClickWarning = styled.p`
  margin: auto;
  overflow: hidden;
`

export const EditableOnClickWarningContainer = styled.div.attrs({
  color: 'fill.status.error.dark',
})`
  ${color}
  display: flex;
  flex-direction: row;
  margin: auto;
  font-size: 14px;
  gap: 5px;
`

export const ErrorSVGforWarning = styled(ErrorSVG).attrs({
  fill: 'fill.status.error.dark',
})`
  ${color}
  height: 16px;
  min-height: 16px;
  width: 16px;
  min-width: 16px;
  margin: auto;
`

export const EditableOnClickContainer = styled.div<{
  inline?: boolean
  isEditing?: boolean
  isEmpty?: boolean
}>`
  width: 100%;
  ${({ inline }) => {
    if (inline) {
      return css`
        display: flex;
        align-items: center;
        * {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      `
    }
  }}
  ${({ isEditing }) => {
    if (!isEditing) {
      return css`
        :hover,
        :focus {
          background-color: ${themeGet('colors.bg.neutral')};
        }
      `
    }
  }}
${({ isEditing, isEmpty }) => {
    if (!isEditing && isEmpty) {
      return css`
        height: 40px;
      `
    }
  }}
`

export const ButtonGroup = styled.div<{ inline?: boolean }>`
  display: inline-flex;
  ${(props) => {
    if (props.inline) {
      return css`
        align-self: center;
        margin-left: ${themeGet('space.xs')}px;
      `
    }
    return css`
      margin: ${themeGet('space.xs')}px;
    `
  }}

  > * :not(:last-child) {
    margin-right: ${themeGet('space.xs')}px;
  }
`

export const EditOnClickError = styled.div`
  margin: 0 0 8px 8px;
  font-size: 14px;
  color: ${palette.magenta[600]};
`

// styled component p tag that has the value of 'None'
export const EditOnClickNone = styled.div.attrs(() => ({
  children: <p>None</p>,
}))`
  p {
    margin: 0;
    font-size: 14px;
    color: ${palette.neutral[600]};
  }
`

/**
 * @fileoverview gRPC-Web generated client stub for audit
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: audit/audit.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var audit_framework_pb = require('../audit/framework_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.audit = require('./audit_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.audit.AuditClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.audit.AuditPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.GetAuditRequest,
 *   !proto.audit.AuditRecord>}
 */
const methodDescriptor_Audit_GetAudit = new grpc.web.MethodDescriptor(
  '/audit.Audit/GetAudit',
  grpc.web.MethodType.UNARY,
  proto.audit.GetAuditRequest,
  proto.audit.AuditRecord,
  /**
   * @param {!proto.audit.GetAuditRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.AuditRecord.deserializeBinary
);


/**
 * @param {!proto.audit.GetAuditRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.AuditRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.AuditRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.getAudit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/GetAudit',
      request,
      metadata || {},
      methodDescriptor_Audit_GetAudit,
      callback);
};


/**
 * @param {!proto.audit.GetAuditRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.AuditRecord>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.getAudit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/GetAudit',
      request,
      metadata || {},
      methodDescriptor_Audit_GetAudit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.GetAuditsRequest,
 *   !proto.audit.AuditRecords>}
 */
const methodDescriptor_Audit_GetAudits = new grpc.web.MethodDescriptor(
  '/audit.Audit/GetAudits',
  grpc.web.MethodType.UNARY,
  proto.audit.GetAuditsRequest,
  proto.audit.AuditRecords,
  /**
   * @param {!proto.audit.GetAuditsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.AuditRecords.deserializeBinary
);


/**
 * @param {!proto.audit.GetAuditsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.AuditRecords)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.AuditRecords>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.getAudits =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/GetAudits',
      request,
      metadata || {},
      methodDescriptor_Audit_GetAudits,
      callback);
};


/**
 * @param {!proto.audit.GetAuditsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.AuditRecords>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.getAudits =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/GetAudits',
      request,
      metadata || {},
      methodDescriptor_Audit_GetAudits);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.SaveAuditRequest,
 *   !proto.audit.AuditRecord>}
 */
const methodDescriptor_Audit_SaveAudit = new grpc.web.MethodDescriptor(
  '/audit.Audit/SaveAudit',
  grpc.web.MethodType.UNARY,
  proto.audit.SaveAuditRequest,
  proto.audit.AuditRecord,
  /**
   * @param {!proto.audit.SaveAuditRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.AuditRecord.deserializeBinary
);


/**
 * @param {!proto.audit.SaveAuditRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.AuditRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.AuditRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.saveAudit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/SaveAudit',
      request,
      metadata || {},
      methodDescriptor_Audit_SaveAudit,
      callback);
};


/**
 * @param {!proto.audit.SaveAuditRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.AuditRecord>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.saveAudit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/SaveAudit',
      request,
      metadata || {},
      methodDescriptor_Audit_SaveAudit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.DeleteAuditRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Audit_DeleteAudit = new grpc.web.MethodDescriptor(
  '/audit.Audit/DeleteAudit',
  grpc.web.MethodType.UNARY,
  proto.audit.DeleteAuditRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.audit.DeleteAuditRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.audit.DeleteAuditRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.deleteAudit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/DeleteAudit',
      request,
      metadata || {},
      methodDescriptor_Audit_DeleteAudit,
      callback);
};


/**
 * @param {!proto.audit.DeleteAuditRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.deleteAudit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/DeleteAudit',
      request,
      metadata || {},
      methodDescriptor_Audit_DeleteAudit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.AddControlsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Audit_AddControls = new grpc.web.MethodDescriptor(
  '/audit.Audit/AddControls',
  grpc.web.MethodType.UNARY,
  proto.audit.AddControlsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.audit.AddControlsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.audit.AddControlsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.addControls =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/AddControls',
      request,
      metadata || {},
      methodDescriptor_Audit_AddControls,
      callback);
};


/**
 * @param {!proto.audit.AddControlsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.addControls =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/AddControls',
      request,
      metadata || {},
      methodDescriptor_Audit_AddControls);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.RemoveControlsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Audit_RemoveControls = new grpc.web.MethodDescriptor(
  '/audit.Audit/RemoveControls',
  grpc.web.MethodType.UNARY,
  proto.audit.RemoveControlsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.audit.RemoveControlsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.audit.RemoveControlsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.removeControls =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/RemoveControls',
      request,
      metadata || {},
      methodDescriptor_Audit_RemoveControls,
      callback);
};


/**
 * @param {!proto.audit.RemoveControlsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.removeControls =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/RemoveControls',
      request,
      metadata || {},
      methodDescriptor_Audit_RemoveControls);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.RemoveControlsFromAllAuditsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Audit_RemoveControlsFromAllAudits = new grpc.web.MethodDescriptor(
  '/audit.Audit/RemoveControlsFromAllAudits',
  grpc.web.MethodType.UNARY,
  proto.audit.RemoveControlsFromAllAuditsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.audit.RemoveControlsFromAllAuditsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.audit.RemoveControlsFromAllAuditsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.removeControlsFromAllAudits =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/RemoveControlsFromAllAudits',
      request,
      metadata || {},
      methodDescriptor_Audit_RemoveControlsFromAllAudits,
      callback);
};


/**
 * @param {!proto.audit.RemoveControlsFromAllAuditsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.removeControlsFromAllAudits =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/RemoveControlsFromAllAudits',
      request,
      metadata || {},
      methodDescriptor_Audit_RemoveControlsFromAllAudits);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.GetReportRequest,
 *   !proto.audit.GetReportResponse>}
 */
const methodDescriptor_Audit_GetReport = new grpc.web.MethodDescriptor(
  '/audit.Audit/GetReport',
  grpc.web.MethodType.UNARY,
  proto.audit.GetReportRequest,
  proto.audit.GetReportResponse,
  /**
   * @param {!proto.audit.GetReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.GetReportResponse.deserializeBinary
);


/**
 * @param {!proto.audit.GetReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.GetReportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.GetReportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.getReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/GetReport',
      request,
      metadata || {},
      methodDescriptor_Audit_GetReport,
      callback);
};


/**
 * @param {!proto.audit.GetReportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.GetReportResponse>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.getReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/GetReport',
      request,
      metadata || {},
      methodDescriptor_Audit_GetReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.CloseAuditRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Audit_CloseAudit = new grpc.web.MethodDescriptor(
  '/audit.Audit/CloseAudit',
  grpc.web.MethodType.UNARY,
  proto.audit.CloseAuditRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.audit.CloseAuditRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.audit.CloseAuditRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.closeAudit =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/CloseAudit',
      request,
      metadata || {},
      methodDescriptor_Audit_CloseAudit,
      callback);
};


/**
 * @param {!proto.audit.CloseAuditRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.closeAudit =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/CloseAudit',
      request,
      metadata || {},
      methodDescriptor_Audit_CloseAudit);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.SaveReportRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Audit_SaveReport = new grpc.web.MethodDescriptor(
  '/audit.Audit/SaveReport',
  grpc.web.MethodType.UNARY,
  proto.audit.SaveReportRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.audit.SaveReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.audit.SaveReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.saveReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/SaveReport',
      request,
      metadata || {},
      methodDescriptor_Audit_SaveReport,
      callback);
};


/**
 * @param {!proto.audit.SaveReportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.saveReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/SaveReport',
      request,
      metadata || {},
      methodDescriptor_Audit_SaveReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.ComplianceFrameworkOption,
 *   !proto.audit.ComplianceFrameworkOption>}
 */
const methodDescriptor_Audit_CreateUpdateComplianceFrameworkOption = new grpc.web.MethodDescriptor(
  '/audit.Audit/CreateUpdateComplianceFrameworkOption',
  grpc.web.MethodType.UNARY,
  audit_framework_pb.ComplianceFrameworkOption,
  audit_framework_pb.ComplianceFrameworkOption,
  /**
   * @param {!proto.audit.ComplianceFrameworkOption} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  audit_framework_pb.ComplianceFrameworkOption.deserializeBinary
);


/**
 * @param {!proto.audit.ComplianceFrameworkOption} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.ComplianceFrameworkOption)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.ComplianceFrameworkOption>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.createUpdateComplianceFrameworkOption =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/CreateUpdateComplianceFrameworkOption',
      request,
      metadata || {},
      methodDescriptor_Audit_CreateUpdateComplianceFrameworkOption,
      callback);
};


/**
 * @param {!proto.audit.ComplianceFrameworkOption} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.ComplianceFrameworkOption>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.createUpdateComplianceFrameworkOption =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/CreateUpdateComplianceFrameworkOption',
      request,
      metadata || {},
      methodDescriptor_Audit_CreateUpdateComplianceFrameworkOption);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.audit.ComplianceFrameworkOptions>}
 */
const methodDescriptor_Audit_GetComplianceFrameworkOptions = new grpc.web.MethodDescriptor(
  '/audit.Audit/GetComplianceFrameworkOptions',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  audit_framework_pb.ComplianceFrameworkOptions,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  audit_framework_pb.ComplianceFrameworkOptions.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.ComplianceFrameworkOptions)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.ComplianceFrameworkOptions>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.getComplianceFrameworkOptions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/GetComplianceFrameworkOptions',
      request,
      metadata || {},
      methodDescriptor_Audit_GetComplianceFrameworkOptions,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.ComplianceFrameworkOptions>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.getComplianceFrameworkOptions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/GetComplianceFrameworkOptions',
      request,
      metadata || {},
      methodDescriptor_Audit_GetComplianceFrameworkOptions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.ComplianceFramework,
 *   !proto.audit.ComplianceFramework>}
 */
const methodDescriptor_Audit_SaveComplianceFramework = new grpc.web.MethodDescriptor(
  '/audit.Audit/SaveComplianceFramework',
  grpc.web.MethodType.UNARY,
  audit_framework_pb.ComplianceFramework,
  audit_framework_pb.ComplianceFramework,
  /**
   * @param {!proto.audit.ComplianceFramework} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  audit_framework_pb.ComplianceFramework.deserializeBinary
);


/**
 * @param {!proto.audit.ComplianceFramework} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.ComplianceFramework)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.ComplianceFramework>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.saveComplianceFramework =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/SaveComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Audit_SaveComplianceFramework,
      callback);
};


/**
 * @param {!proto.audit.ComplianceFramework} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.ComplianceFramework>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.saveComplianceFramework =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/SaveComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Audit_SaveComplianceFramework);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.GetComplianceFrameworksRequest,
 *   !proto.audit.ComplianceFrameworks>}
 */
const methodDescriptor_Audit_GetComplianceFrameworks = new grpc.web.MethodDescriptor(
  '/audit.Audit/GetComplianceFrameworks',
  grpc.web.MethodType.UNARY,
  proto.audit.GetComplianceFrameworksRequest,
  audit_framework_pb.ComplianceFrameworks,
  /**
   * @param {!proto.audit.GetComplianceFrameworksRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  audit_framework_pb.ComplianceFrameworks.deserializeBinary
);


/**
 * @param {!proto.audit.GetComplianceFrameworksRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.ComplianceFrameworks)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.ComplianceFrameworks>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.getComplianceFrameworks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/GetComplianceFrameworks',
      request,
      metadata || {},
      methodDescriptor_Audit_GetComplianceFrameworks,
      callback);
};


/**
 * @param {!proto.audit.GetComplianceFrameworksRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.ComplianceFrameworks>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.getComplianceFrameworks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/GetComplianceFrameworks',
      request,
      metadata || {},
      methodDescriptor_Audit_GetComplianceFrameworks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.audit.GetHasComplianceFrameworksResponse>}
 */
const methodDescriptor_Audit_GetHasComplianceFrameworks = new grpc.web.MethodDescriptor(
  '/audit.Audit/GetHasComplianceFrameworks',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.audit.GetHasComplianceFrameworksResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.GetHasComplianceFrameworksResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.GetHasComplianceFrameworksResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.GetHasComplianceFrameworksResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.getHasComplianceFrameworks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/GetHasComplianceFrameworks',
      request,
      metadata || {},
      methodDescriptor_Audit_GetHasComplianceFrameworks,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.GetHasComplianceFrameworksResponse>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.getHasComplianceFrameworks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/GetHasComplianceFrameworks',
      request,
      metadata || {},
      methodDescriptor_Audit_GetHasComplianceFrameworks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.audit.GetCriteriaControlMappingsRequest,
 *   !proto.audit.GetCriteriaControlMappingsResponse>}
 */
const methodDescriptor_Audit_GetCriteriaControlMappings = new grpc.web.MethodDescriptor(
  '/audit.Audit/GetCriteriaControlMappings',
  grpc.web.MethodType.UNARY,
  proto.audit.GetCriteriaControlMappingsRequest,
  proto.audit.GetCriteriaControlMappingsResponse,
  /**
   * @param {!proto.audit.GetCriteriaControlMappingsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.audit.GetCriteriaControlMappingsResponse.deserializeBinary
);


/**
 * @param {!proto.audit.GetCriteriaControlMappingsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.audit.GetCriteriaControlMappingsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.audit.GetCriteriaControlMappingsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.audit.AuditClient.prototype.getCriteriaControlMappings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/audit.Audit/GetCriteriaControlMappings',
      request,
      metadata || {},
      methodDescriptor_Audit_GetCriteriaControlMappings,
      callback);
};


/**
 * @param {!proto.audit.GetCriteriaControlMappingsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.audit.GetCriteriaControlMappingsResponse>}
 *     Promise that resolves to the response
 */
proto.audit.AuditPromiseClient.prototype.getCriteriaControlMappings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/audit.Audit/GetCriteriaControlMappings',
      request,
      metadata || {},
      methodDescriptor_Audit_GetCriteriaControlMappings);
};


module.exports = proto.audit;


import React from 'react'
import { ControlsIndexGrid } from 'src/pages/Controls/ControlsIndexPage/ControlsIndexGrid'
import { AutomatedControlsContainer } from './styles'

export const AutomatedControls = ({
  modelIds,
}: {
  modelIds: string[]
}): JSX.Element => {
  return (
    <AutomatedControlsContainer>
      <ControlsIndexGrid controlIds={modelIds} />
    </AutomatedControlsContainer>
  )
}

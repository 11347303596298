import React, { useContext } from 'react'
import { Changelog, InfoPanelContext } from '../../../../InfoPanel'
import { ReactComponent as ChangelogIcon } from '../../../Icons/assets/changelog-icon.svg'
import { Tooltip } from '../../Tooltip'
import { IconButton, IconButtonVariant } from '../IconButton'

export const ChangelogButton = ({
  subjectModelIds,
  subjectId,
}: {
  subjectModelIds?: string[]
  subjectId?: string
}): JSX.Element => {
  const { setInfoPanelState } = useContext(InfoPanelContext)

  return (
    <Tooltip
      id={`changelog-tooltip-${subjectModelIds}`}
      placement="top"
      tooltipBody="Activities"
    >
      <IconButton
        variant={IconButtonVariant.icon}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setInfoPanelState({
            isShown: true,
            render: (
              <Changelog
                subjectmodelid={subjectModelIds}
                subjectId={subjectId}
              />
            ),
          })
        }}
      >
        <ChangelogIcon width="24px" height="24px" />
      </IconButton>
    </Tooltip>
  )
}

import React, { MouseEvent } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import * as R from 'rambda'
import {
  DirectRoutes,
  ReceptorsAbsoluteRoutes,
} from 'src/components/Reusable/RootPage/RootPage.constants'
import { AuditsSVG, ReceptorsSVG } from '../../../components/Icons/Basic'
import { DashboardNavSVG } from '../../../components/Icons/NavIcons'
import {
  JourneyPageGrid,
  JourneySubPageHeader,
  QuickstartContainer,
} from '../Help.styles'
import {
  HelpAccordion,
  HelpAccordionBody,
  HelpAccordionBodyTextSection,
  HelpAccordionFigure,
  HelpAccordionHeader,
  HelpAccordionImage,
  HelpAccordionItem,
  HelpAccordionText,
} from '../HelpAccordion.styles'
import { GlossarySideBar, HelpLinks } from '../Help.components'
import { ScreenShotSrc } from './Screenshots'

export enum QuickstartPageHashes {
  SCOPE = 'scope',
  RECEPTORS = 'receptors',
  AUDIT = 'audit',
  DASHBOARD = 'dashboard',
}

export function QuickstartPage(): JSX.Element {
  const navigate = useNavigate()
  const location = useLocation()
  const hashes = R.tail(R.split('#', location.hash))

  return (
    <JourneyPageGrid>
      <QuickstartContainer>
        <HelpLinks />
        <JourneySubPageHeader title="Welcome!">
          <p>
            Use this app as your system of record for continuous compliance.
          </p>
          <ul>
            <li>Set up automated monitoring</li>
            <li>Organize and store evidence</li>
            <li>
              Get your story straight for whatever compliance project you face
              next
            </li>
          </ul>
        </JourneySubPageHeader>

        <HelpAccordion activeKey={hashes[0]}>
          <HelpAccordionItem
            eventKey={QuickstartPageHashes.RECEPTORS}
            id={QuickstartPageHashes.RECEPTORS}
          >
            <HelpAccordionHeader
              onClick={(e: MouseEvent<HTMLElement>) => {
                e.stopPropagation()
                hashes[0] === QuickstartPageHashes.RECEPTORS
                  ? navigate(location.pathname, { replace: true })
                  : navigate(
                      {
                        pathname: location.pathname,
                        hash: QuickstartPageHashes.RECEPTORS,
                      },
                      { replace: true },
                    )
              }}
            >
              <HelpAccordionText>
                Set Up Receptors to Gather Evidence for You
              </HelpAccordionText>
              <ReceptorsSVG width="40px" height="40px" />
            </HelpAccordionHeader>
            <HelpAccordionBody>
              <HelpAccordionBodyTextSection>
                <p>
                  Set up these integrations to access your critical cloud
                  infrastructure via API.{' '}
                  <Link to={`../../${ReceptorsAbsoluteRoutes.INDEX}`}>
                    <b>Receptors</b>
                  </Link>
                  &nbsp;will gather evidence and post it automatically to
                  appropriate controls.
                </p>
                <br />
                Because receptors link to complex services such as AWS, you may
                need to disable evidence for some sub services, instances, or
                repositories that are not relevant for your auditors.
              </HelpAccordionBodyTextSection>
              <HelpAccordionFigure>
                <HelpAccordionImage
                  src={ScreenShotSrc.Receptors}
                  alt="receptors-screenshot"
                />
              </HelpAccordionFigure>
            </HelpAccordionBody>
          </HelpAccordionItem>

          <HelpAccordionItem
            eventKey={QuickstartPageHashes.AUDIT}
            id={QuickstartPageHashes.AUDIT}
          >
            <HelpAccordionHeader
              onClick={(e: MouseEvent<HTMLElement>) => {
                e.stopPropagation()
                hashes[0] === QuickstartPageHashes.AUDIT
                  ? navigate(location.pathname, { replace: true })
                  : navigate(
                      {
                        pathname: location.pathname,
                        hash: QuickstartPageHashes.AUDIT,
                      },
                      { replace: true },
                    )
              }}
            >
              <HelpAccordionText>Prepare for Each Audit</HelpAccordionText>
              <AuditsSVG width="40px" height="40px" />
            </HelpAccordionHeader>
            <HelpAccordionBody>
              <HelpAccordionBodyTextSection>
                <p>
                  Start work within a single audit, preparing policies and
                  controls to handle your frameworks for one time period. When
                  you’re ready, switch to{' '}
                  <Link to={`../../${DirectRoutes.DASHBOARD}`}>
                    <b>Continuous Compliance</b>
                  </Link>
                  &nbsp;and keep your company orderly across standards and time
                  periods.
                </p>
                <br />
                Your audits will usually only need some of your company’s
                controls, and policies. Other things, such as services, company
                info, departments, and receptors are the same across audits.
              </HelpAccordionBodyTextSection>
              <HelpAccordionFigure>
                <HelpAccordionImage
                  src={ScreenShotSrc.MultiAudit}
                  alt="multi-audit-screenshot"
                />
              </HelpAccordionFigure>
            </HelpAccordionBody>
          </HelpAccordionItem>

          <HelpAccordionItem
            eventKey={QuickstartPageHashes.DASHBOARD}
            id={QuickstartPageHashes.DASHBOARD}
          >
            <HelpAccordionHeader
              onClick={(e: MouseEvent<HTMLElement>) => {
                e.stopPropagation()
                hashes[0] === QuickstartPageHashes.DASHBOARD
                  ? navigate(location.pathname, { replace: true })
                  : navigate(
                      {
                        pathname: location.pathname,
                        hash: QuickstartPageHashes.DASHBOARD,
                      },
                      { replace: true },
                    )
              }}
            >
              <HelpAccordionText>
                Track Your Progress on the Dashboard
              </HelpAccordionText>
              <DashboardNavSVG width="40px" height="40px" />
            </HelpAccordionHeader>
            <HelpAccordionBody>
              <HelpAccordionBodyTextSection>
                <p>
                  Keep tabs on your progress from the{' '}
                  <Link to={`../../${DirectRoutes.DASHBOARD}`}>
                    <b>Dashboard</b>
                  </Link>
                  .
                </p>
                <br />
                <p>
                  From here you can also download and share a pro forma report,
                  a spreadsheet of your controls, or all working papers related
                  to the audit.
                </p>
                <br />
                <p>
                  Use the app to prepare materials that are required for your
                  final audit report.
                </p>
              </HelpAccordionBodyTextSection>
            </HelpAccordionBody>
          </HelpAccordionItem>
        </HelpAccordion>
      </QuickstartContainer>

      <GlossarySideBar />
    </JourneyPageGrid>
  )
}

import React, { createContext, useContext, useMemo, useState } from 'react'
import {
  Control,
  CONTROL_RESPONSIBILITY,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { useDeleteControlModal } from '../../pages/Controls/DeleteControlModal'
import { useRemoveControlFromAuditModal } from '../../pages/Controls/RemoveControlFromAuditModal'

type CurrentControl = {
  updatedResponsibility?: CONTROL_RESPONSIBILITY
  setUpdatedResponsibility: React.Dispatch<
    React.SetStateAction<CONTROL_RESPONSIBILITY | undefined>
  >
  currentControl: Control.AsObject
  setCurrentControl: React.Dispatch<React.SetStateAction<Control.AsObject>>
  removeControl: () => void
  deleteControl: () => void
}

export const CurrentControlContext = createContext<CurrentControl>({
  setUpdatedResponsibility: () => undefined,
  currentControl: new Control().toObject(),
  setCurrentControl: () => new Control().toObject(),
  removeControl: () => null,
  deleteControl: () => null,
})

export const useCurrentControl = (): CurrentControl => {
  const currentContext = useContext(CurrentControlContext)
  const {
    currentControl,
    currentControl: { id: controlId },
  } = currentContext

  const removeControl = useRemoveControlFromAuditModal(controlId)
  const deleteControl = useDeleteControlModal({
    control: currentControl,
  })
  return {
    ...currentContext,
    removeControl,
    deleteControl,
  }
}

export const CurrentControlContextProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [currentControl, setCurrentControl] = useState<Control.AsObject>(
    new Control().toObject(),
  )
  const [updatedResponsibility, setUpdatedResponsibility] = useState<
    CONTROL_RESPONSIBILITY | undefined
  >()

  const controlData: CurrentControl = useMemo(
    () => ({
      currentControl,
      setCurrentControl,
      removeControl: () => null,
      deleteControl: () => null,
      updatedResponsibility,
      setUpdatedResponsibility,
    }),
    [currentControl, updatedResponsibility],
  )
  return (
    <CurrentControlContext.Provider value={controlData}>
      {children}
    </CurrentControlContext.Provider>
  )
}

// For testing
const TEST_CONTROL_0 = new Control().toObject()
export const contextInitialState: CurrentControl = {
  currentControl: TEST_CONTROL_0,
  setCurrentControl: () => new Control().toObject(),
  removeControl: () => null,
  deleteControl: () => null,
  setUpdatedResponsibility: () => undefined,
}

/* eslint-disable no-console */
import React from 'react'
import { useParams } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import {
  CONTROL_STATUS,
  Control,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { PERMISSIONS } from 'src/config/roleConfig'
import { DropdownToggleButton } from '../../../../components/Reusable/Buttons'
import { ReactComponent as VerticalEllipsesIcon } from '../../../../components/Icons/assets/vertical-ellipses-icon.svg'
import { useCurrentControl } from '../../../../context/FormContext'
import { TestIds } from '../../../../Utils/testIds'
import {
  ViewDetails,
  RemoveControl,
  CopyLink,
  InScopeItems,
  DeleteControl,
  ViewActivities,
} from './ControlsListItemMenuComponents'

export const ControlsListItemMenu = ({
  control,
}: {
  control: Control.AsObject
}): JSX.Element => {
  const hasPermission = useHasRequiredPermissions([
    PERMISSIONS.READ,
    PERMISSIONS.EDIT,
  ])
  const { setCurrentControl } = useCurrentControl()
  const { requestId } = useParams()

  const setCurrentControlHandler = () => {
    setCurrentControl(control)
  }

  const isNotApplicable = control.status === CONTROL_STATUS.NOTAPPLICABLE

  console.log('hasPermissions', hasPermission)

  if (!hasPermission) {
    return <></>
  }

  return (
    <Dropdown id={`controls-index-menu-${control.id}`}>
      <Dropdown.Toggle
        as={DropdownToggleButton}
        onClickFunc={setCurrentControlHandler}
        data-testid={TestIds.CONTROL_LIST_ITEM_MENU_TOGGLE}
      >
        <VerticalEllipsesIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {requestId ? (
          <>
            <ViewDetails />
            <RemoveControl {...{ requestId }} />
            <CopyLink />
          </>
        ) : (
          <>
            <ViewDetails />
            <Dropdown.Divider />
            {!isNotApplicable && <InScopeItems />}
            <DeleteControl />
            <Dropdown.Divider />
            <CopyLink />
            <ViewActivities />
          </>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

// source: model/model.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var common_collections_pb = require('../common/collections_pb.js');
goog.object.extend(proto, common_collections_pb);
var common_model_pb = require('../common/model_pb.js');
goog.object.extend(proto, common_model_pb);
var model_control_pb = require('../model/control_pb.js');
goog.object.extend(proto, model_control_pb);
var model_policy_pb = require('../model/policy_pb.js');
goog.object.extend(proto, model_policy_pb);
var model_department_pb = require('../model/department_pb.js');
goog.object.extend(proto, model_department_pb);
var model_framework_pb = require('../model/framework_pb.js');
goog.object.extend(proto, model_framework_pb);
goog.exportSymbol('proto.model.ControlResponsibilityRequest', null, global);
goog.exportSymbol('proto.model.ControlStat', null, global);
goog.exportSymbol('proto.model.ControlStats', null, global);
goog.exportSymbol('proto.model.ControlStatusRequest', null, global);
goog.exportSymbol('proto.model.CreateCustomServiceRequest', null, global);
goog.exportSymbol('proto.model.Dismissed', null, global);
goog.exportSymbol('proto.model.DismissedBatch', null, global);
goog.exportSymbol('proto.model.EvidenceStats', null, global);
goog.exportSymbol('proto.model.GetByModelIDsRequest', null, global);
goog.exportSymbol('proto.model.GetComplianceFrameworkObjectivesRequest', null, global);
goog.exportSymbol('proto.model.GetControlStatsByModelIdRequest', null, global);
goog.exportSymbol('proto.model.GetControlStatsByModelIdResponse', null, global);
goog.exportSymbol('proto.model.GetControlStatsRequest', null, global);
goog.exportSymbol('proto.model.GetModelByOIDRequest', null, global);
goog.exportSymbol('proto.model.GetObjectivesByModelIdRequest', null, global);
goog.exportSymbol('proto.model.HasCustomerData', null, global);
goog.exportSymbol('proto.model.ListModelsRequest', null, global);
goog.exportSymbol('proto.model.ModelIdAndType', null, global);
goog.exportSymbol('proto.model.ModelObjectiveAndType', null, global);
goog.exportSymbol('proto.model.ModelRecord', null, global);
goog.exportSymbol('proto.model.ModelType', null, global);
goog.exportSymbol('proto.model.Owner', null, global);
goog.exportSymbol('proto.model.ProductDelivery', null, global);
goog.exportSymbol('proto.model.RecordCount', null, global);
goog.exportSymbol('proto.model.Records', null, global);
goog.exportSymbol('proto.model.SupportingTool', null, global);
goog.exportSymbol('proto.model.TEMPLATE_TYPE', null, global);
goog.exportSymbol('proto.model.TestResultStats', null, global);
goog.exportSymbol('proto.model.TimeRange', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetByModelIDsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.GetByModelIDsRequest.repeatedFields_, null);
};
goog.inherits(proto.model.GetByModelIDsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetByModelIDsRequest.displayName = 'proto.model.GetByModelIDsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ModelRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.ModelRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ModelRecord.displayName = 'proto.model.ModelRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ModelIdAndType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.ModelIdAndType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ModelIdAndType.displayName = 'proto.model.ModelIdAndType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ModelObjectiveAndType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.ModelObjectiveAndType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ModelObjectiveAndType.displayName = 'proto.model.ModelObjectiveAndType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Records = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.Records.repeatedFields_, null);
};
goog.inherits(proto.model.Records, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Records.displayName = 'proto.model.Records';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.RecordCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.RecordCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.RecordCount.displayName = 'proto.model.RecordCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ModelType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.ModelType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ModelType.displayName = 'proto.model.ModelType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Owner = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.Owner, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Owner.displayName = 'proto.model.Owner';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ControlStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.ControlStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ControlStatusRequest.displayName = 'proto.model.ControlStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ControlResponsibilityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.ControlResponsibilityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ControlResponsibilityRequest.displayName = 'proto.model.ControlResponsibilityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.Dismissed = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.Dismissed, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.Dismissed.displayName = 'proto.model.Dismissed';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.CreateCustomServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.CreateCustomServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.CreateCustomServiceRequest.displayName = 'proto.model.CreateCustomServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.HasCustomerData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.HasCustomerData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.HasCustomerData.displayName = 'proto.model.HasCustomerData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ProductDelivery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.ProductDelivery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ProductDelivery.displayName = 'proto.model.ProductDelivery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.SupportingTool = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.SupportingTool, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.SupportingTool.displayName = 'proto.model.SupportingTool';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.DismissedBatch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.DismissedBatch.repeatedFields_, null);
};
goog.inherits(proto.model.DismissedBatch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.DismissedBatch.displayName = 'proto.model.DismissedBatch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetModelByOIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetModelByOIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetModelByOIDRequest.displayName = 'proto.model.GetModelByOIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.TimeRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.TimeRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.TimeRange.displayName = 'proto.model.TimeRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ControlStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.ControlStats.repeatedFields_, null);
};
goog.inherits(proto.model.ControlStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ControlStats.displayName = 'proto.model.ControlStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ControlStat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.ControlStat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ControlStat.displayName = 'proto.model.ControlStat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetControlStatsByModelIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetControlStatsByModelIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetControlStatsByModelIdRequest.displayName = 'proto.model.GetControlStatsByModelIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.EvidenceStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.EvidenceStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.EvidenceStats.displayName = 'proto.model.EvidenceStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.TestResultStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.TestResultStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.TestResultStats.displayName = 'proto.model.TestResultStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetControlStatsByModelIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetControlStatsByModelIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetControlStatsByModelIdResponse.displayName = 'proto.model.GetControlStatsByModelIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetControlStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.model.GetControlStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetControlStatsRequest.displayName = 'proto.model.GetControlStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.ListModelsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.ListModelsRequest.repeatedFields_, null);
};
goog.inherits(proto.model.ListModelsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.ListModelsRequest.displayName = 'proto.model.ListModelsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetComplianceFrameworkObjectivesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.GetComplianceFrameworkObjectivesRequest.repeatedFields_, null);
};
goog.inherits(proto.model.GetComplianceFrameworkObjectivesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetComplianceFrameworkObjectivesRequest.displayName = 'proto.model.GetComplianceFrameworkObjectivesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.model.GetObjectivesByModelIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.model.GetObjectivesByModelIdRequest.repeatedFields_, null);
};
goog.inherits(proto.model.GetObjectivesByModelIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.model.GetObjectivesByModelIdRequest.displayName = 'proto.model.GetObjectivesByModelIdRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.GetByModelIDsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetByModelIDsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetByModelIDsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetByModelIDsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetByModelIDsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    common_model_pb.Identifier.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetByModelIDsRequest}
 */
proto.model.GetByModelIDsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetByModelIDsRequest;
  return proto.model.GetByModelIDsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetByModelIDsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetByModelIDsRequest}
 */
proto.model.GetByModelIDsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetByModelIDsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetByModelIDsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetByModelIDsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetByModelIDsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
};


/**
 * repeated common.Identifier IDs = 1;
 * @return {!Array<!proto.common.Identifier>}
 */
proto.model.GetByModelIDsRequest.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.common.Identifier>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_model_pb.Identifier, 1));
};


/**
 * @param {!Array<!proto.common.Identifier>} value
 * @return {!proto.model.GetByModelIDsRequest} returns this
*/
proto.model.GetByModelIDsRequest.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Identifier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Identifier}
 */
proto.model.GetByModelIDsRequest.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Identifier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.GetByModelIDsRequest} returns this
 */
proto.model.GetByModelIDsRequest.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ModelRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ModelRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ModelRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ModelRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdat: jspb.Message.getFieldWithDefault(msg, 1, 0),
    updatedat: jspb.Message.getFieldWithDefault(msg, 2, 0),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    modeltype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    modelid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    description: jspb.Message.getFieldWithDefault(msg, 7, ""),
    owneremail: jspb.Message.getFieldWithDefault(msg, 8, ""),
    dismissed: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    status: jspb.Message.getFieldWithDefault(msg, 10, 0),
    responsibility: jspb.Message.getFieldWithDefault(msg, 14, 0),
    hascustomerdata: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    productdelivery: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    supportingtool: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ModelRecord}
 */
proto.model.ModelRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ModelRecord;
  return proto.model.ModelRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ModelRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ModelRecord}
 */
proto.model.ModelRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 5:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setModeltype(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwneremail(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDismissed(value);
      break;
    case 10:
      var value = /** @type {!proto.model.CONTROL_STATUS} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 14:
      var value = /** @type {!proto.model.CONTROL_RESPONSIBILITY} */ (reader.readEnum());
      msg.setResponsibility(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHascustomerdata(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProductdelivery(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupportingtool(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ModelRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ModelRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ModelRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ModelRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUpdatedat();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModeltype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getModelid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOwneremail();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDismissed();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
  f = message.getResponsibility();
  if (f !== 0.0) {
    writer.writeEnum(
      14,
      f
    );
  }
  f = message.getHascustomerdata();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getProductdelivery();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getSupportingtool();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional int64 CreatedAt = 1;
 * @return {number}
 */
proto.model.ModelRecord.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.ModelRecord} returns this
 */
proto.model.ModelRecord.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 UpdatedAt = 2;
 * @return {number}
 */
proto.model.ModelRecord.prototype.getUpdatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.ModelRecord} returns this
 */
proto.model.ModelRecord.prototype.setUpdatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string ID = 3;
 * @return {string}
 */
proto.model.ModelRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ModelRecord} returns this
 */
proto.model.ModelRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string AccountID = 4;
 * @return {string}
 */
proto.model.ModelRecord.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ModelRecord} returns this
 */
proto.model.ModelRecord.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.MODEL_TYPE ModelType = 5;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.model.ModelRecord.prototype.getModeltype = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.model.ModelRecord} returns this
 */
proto.model.ModelRecord.prototype.setModeltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string ModelID = 6;
 * @return {string}
 */
proto.model.ModelRecord.prototype.getModelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ModelRecord} returns this
 */
proto.model.ModelRecord.prototype.setModelid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Description = 7;
 * @return {string}
 */
proto.model.ModelRecord.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ModelRecord} returns this
 */
proto.model.ModelRecord.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string OwnerEmail = 8;
 * @return {string}
 */
proto.model.ModelRecord.prototype.getOwneremail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ModelRecord} returns this
 */
proto.model.ModelRecord.prototype.setOwneremail = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool Dismissed = 9;
 * @return {boolean}
 */
proto.model.ModelRecord.prototype.getDismissed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.ModelRecord} returns this
 */
proto.model.ModelRecord.prototype.setDismissed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional CONTROL_STATUS Status = 10;
 * @return {!proto.model.CONTROL_STATUS}
 */
proto.model.ModelRecord.prototype.getStatus = function() {
  return /** @type {!proto.model.CONTROL_STATUS} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.model.CONTROL_STATUS} value
 * @return {!proto.model.ModelRecord} returns this
 */
proto.model.ModelRecord.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};


/**
 * optional CONTROL_RESPONSIBILITY Responsibility = 14;
 * @return {!proto.model.CONTROL_RESPONSIBILITY}
 */
proto.model.ModelRecord.prototype.getResponsibility = function() {
  return /** @type {!proto.model.CONTROL_RESPONSIBILITY} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {!proto.model.CONTROL_RESPONSIBILITY} value
 * @return {!proto.model.ModelRecord} returns this
 */
proto.model.ModelRecord.prototype.setResponsibility = function(value) {
  return jspb.Message.setProto3EnumField(this, 14, value);
};


/**
 * optional bool HasCustomerData = 11;
 * @return {boolean}
 */
proto.model.ModelRecord.prototype.getHascustomerdata = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.ModelRecord} returns this
 */
proto.model.ModelRecord.prototype.setHascustomerdata = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool ProductDelivery = 12;
 * @return {boolean}
 */
proto.model.ModelRecord.prototype.getProductdelivery = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.ModelRecord} returns this
 */
proto.model.ModelRecord.prototype.setProductdelivery = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool SupportingTool = 13;
 * @return {boolean}
 */
proto.model.ModelRecord.prototype.getSupportingtool = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.ModelRecord} returns this
 */
proto.model.ModelRecord.prototype.setSupportingtool = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ModelIdAndType.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ModelIdAndType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ModelIdAndType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ModelIdAndType.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ModelIdAndType}
 */
proto.model.ModelIdAndType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ModelIdAndType;
  return proto.model.ModelIdAndType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ModelIdAndType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ModelIdAndType}
 */
proto.model.ModelIdAndType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 2:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setModelType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ModelIdAndType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ModelIdAndType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ModelIdAndType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ModelIdAndType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string model_id = 1;
 * @return {string}
 */
proto.model.ModelIdAndType.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ModelIdAndType} returns this
 */
proto.model.ModelIdAndType.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.MODEL_TYPE model_type = 2;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.model.ModelIdAndType.prototype.getModelType = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.model.ModelIdAndType} returns this
 */
proto.model.ModelIdAndType.prototype.setModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ModelObjectiveAndType.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ModelObjectiveAndType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ModelObjectiveAndType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ModelObjectiveAndType.toObject = function(includeInstance, msg) {
  var f, obj = {
    objective: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ModelObjectiveAndType}
 */
proto.model.ModelObjectiveAndType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ModelObjectiveAndType;
  return proto.model.ModelObjectiveAndType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ModelObjectiveAndType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ModelObjectiveAndType}
 */
proto.model.ModelObjectiveAndType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjective(value);
      break;
    case 2:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setModelType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ModelObjectiveAndType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ModelObjectiveAndType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ModelObjectiveAndType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ModelObjectiveAndType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getObjective();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string objective = 1;
 * @return {string}
 */
proto.model.ModelObjectiveAndType.prototype.getObjective = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ModelObjectiveAndType} returns this
 */
proto.model.ModelObjectiveAndType.prototype.setObjective = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.MODEL_TYPE model_type = 2;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.model.ModelObjectiveAndType.prototype.getModelType = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.model.ModelObjectiveAndType} returns this
 */
proto.model.ModelObjectiveAndType.prototype.setModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.Records.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Records.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Records.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Records} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Records.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordsList: jspb.Message.toObjectList(msg.getRecordsList(),
    proto.model.ModelRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Records}
 */
proto.model.Records.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Records;
  return proto.model.Records.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Records} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Records}
 */
proto.model.Records.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.ModelRecord;
      reader.readMessage(value,proto.model.ModelRecord.deserializeBinaryFromReader);
      msg.addRecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Records.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Records.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Records} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Records.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.model.ModelRecord.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ModelRecord records = 1;
 * @return {!Array<!proto.model.ModelRecord>}
 */
proto.model.Records.prototype.getRecordsList = function() {
  return /** @type{!Array<!proto.model.ModelRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.ModelRecord, 1));
};


/**
 * @param {!Array<!proto.model.ModelRecord>} value
 * @return {!proto.model.Records} returns this
*/
proto.model.Records.prototype.setRecordsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.ModelRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.ModelRecord}
 */
proto.model.Records.prototype.addRecords = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.ModelRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.Records} returns this
 */
proto.model.Records.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.RecordCount.prototype.toObject = function(opt_includeInstance) {
  return proto.model.RecordCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.RecordCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.RecordCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    numrecords: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.RecordCount}
 */
proto.model.RecordCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.RecordCount;
  return proto.model.RecordCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.RecordCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.RecordCount}
 */
proto.model.RecordCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumrecords(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.RecordCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.RecordCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.RecordCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.RecordCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumrecords();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 numRecords = 1;
 * @return {number}
 */
proto.model.RecordCount.prototype.getNumrecords = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.RecordCount} returns this
 */
proto.model.RecordCount.prototype.setNumrecords = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ModelType.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ModelType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ModelType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ModelType.toObject = function(includeInstance, msg) {
  var f, obj = {
    modeltype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    alltypes: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ModelType}
 */
proto.model.ModelType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ModelType;
  return proto.model.ModelType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ModelType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ModelType}
 */
proto.model.ModelType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setModeltype(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAlltypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ModelType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ModelType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ModelType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ModelType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModeltype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAlltypes();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional common.MODEL_TYPE ModelType = 1;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.model.ModelType.prototype.getModeltype = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.model.ModelType} returns this
 */
proto.model.ModelType.prototype.setModeltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool AllTypes = 2;
 * @return {boolean}
 */
proto.model.ModelType.prototype.getAlltypes = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.ModelType} returns this
 */
proto.model.ModelType.prototype.setAlltypes = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Owner.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Owner.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Owner} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Owner.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_model_pb.Identifier.toObject(includeInstance, f),
    owneremail: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Owner}
 */
proto.model.Owner.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Owner;
  return proto.model.Owner.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Owner} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Owner}
 */
proto.model.Owner.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwneremail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Owner.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Owner.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Owner} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Owner.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getOwneremail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional common.Identifier ID = 1;
 * @return {?proto.common.Identifier}
 */
proto.model.Owner.prototype.getId = function() {
  return /** @type{?proto.common.Identifier} */ (
    jspb.Message.getWrapperField(this, common_model_pb.Identifier, 1));
};


/**
 * @param {?proto.common.Identifier|undefined} value
 * @return {!proto.model.Owner} returns this
*/
proto.model.Owner.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Owner} returns this
 */
proto.model.Owner.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Owner.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string OwnerEmail = 2;
 * @return {string}
 */
proto.model.Owner.prototype.getOwneremail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Owner} returns this
 */
proto.model.Owner.prototype.setOwneremail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ControlStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ControlStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ControlStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ControlStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_model_pb.Identifier.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ControlStatusRequest}
 */
proto.model.ControlStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ControlStatusRequest;
  return proto.model.ControlStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ControlStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ControlStatusRequest}
 */
proto.model.ControlStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.model.CONTROL_STATUS} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ControlStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ControlStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ControlStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ControlStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional common.Identifier ID = 1;
 * @return {?proto.common.Identifier}
 */
proto.model.ControlStatusRequest.prototype.getId = function() {
  return /** @type{?proto.common.Identifier} */ (
    jspb.Message.getWrapperField(this, common_model_pb.Identifier, 1));
};


/**
 * @param {?proto.common.Identifier|undefined} value
 * @return {!proto.model.ControlStatusRequest} returns this
*/
proto.model.ControlStatusRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ControlStatusRequest} returns this
 */
proto.model.ControlStatusRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ControlStatusRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CONTROL_STATUS status = 2;
 * @return {!proto.model.CONTROL_STATUS}
 */
proto.model.ControlStatusRequest.prototype.getStatus = function() {
  return /** @type {!proto.model.CONTROL_STATUS} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.model.CONTROL_STATUS} value
 * @return {!proto.model.ControlStatusRequest} returns this
 */
proto.model.ControlStatusRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ControlResponsibilityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ControlResponsibilityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ControlResponsibilityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ControlResponsibilityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    responsibility: jspb.Message.getFieldWithDefault(msg, 2, 0),
    responsibilityReason: jspb.Message.getFieldWithDefault(msg, 3, ""),
    vendorId: (f = msg.getVendorId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ControlResponsibilityRequest}
 */
proto.model.ControlResponsibilityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ControlResponsibilityRequest;
  return proto.model.ControlResponsibilityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ControlResponsibilityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ControlResponsibilityRequest}
 */
proto.model.ControlResponsibilityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = /** @type {!proto.model.CONTROL_RESPONSIBILITY} */ (reader.readEnum());
      msg.setResponsibility(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponsibilityReason(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setVendorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ControlResponsibilityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ControlResponsibilityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ControlResponsibilityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ControlResponsibilityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getResponsibility();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getResponsibilityReason();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getVendorId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.model.ControlResponsibilityRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ControlResponsibilityRequest} returns this
 */
proto.model.ControlResponsibilityRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional CONTROL_RESPONSIBILITY responsibility = 2;
 * @return {!proto.model.CONTROL_RESPONSIBILITY}
 */
proto.model.ControlResponsibilityRequest.prototype.getResponsibility = function() {
  return /** @type {!proto.model.CONTROL_RESPONSIBILITY} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.model.CONTROL_RESPONSIBILITY} value
 * @return {!proto.model.ControlResponsibilityRequest} returns this
 */
proto.model.ControlResponsibilityRequest.prototype.setResponsibility = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string responsibility_reason = 3;
 * @return {string}
 */
proto.model.ControlResponsibilityRequest.prototype.getResponsibilityReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.ControlResponsibilityRequest} returns this
 */
proto.model.ControlResponsibilityRequest.prototype.setResponsibilityReason = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.StringValue vendor_id = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.ControlResponsibilityRequest.prototype.getVendorId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.ControlResponsibilityRequest} returns this
*/
proto.model.ControlResponsibilityRequest.prototype.setVendorId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ControlResponsibilityRequest} returns this
 */
proto.model.ControlResponsibilityRequest.prototype.clearVendorId = function() {
  return this.setVendorId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ControlResponsibilityRequest.prototype.hasVendorId = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.Dismissed.prototype.toObject = function(opt_includeInstance) {
  return proto.model.Dismissed.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.Dismissed} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Dismissed.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_model_pb.Identifier.toObject(includeInstance, f),
    dismissed: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    objectId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.Dismissed}
 */
proto.model.Dismissed.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.Dismissed;
  return proto.model.Dismissed.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.Dismissed} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.Dismissed}
 */
proto.model.Dismissed.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDismissed(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setObjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.Dismissed.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.Dismissed.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.Dismissed} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.Dismissed.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getDismissed();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getObjectId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional common.Identifier ID = 1;
 * @return {?proto.common.Identifier}
 */
proto.model.Dismissed.prototype.getId = function() {
  return /** @type{?proto.common.Identifier} */ (
    jspb.Message.getWrapperField(this, common_model_pb.Identifier, 1));
};


/**
 * @param {?proto.common.Identifier|undefined} value
 * @return {!proto.model.Dismissed} returns this
*/
proto.model.Dismissed.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.Dismissed} returns this
 */
proto.model.Dismissed.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.Dismissed.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool Dismissed = 2;
 * @return {boolean}
 */
proto.model.Dismissed.prototype.getDismissed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.Dismissed} returns this
 */
proto.model.Dismissed.prototype.setDismissed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string object_id = 3;
 * @return {string}
 */
proto.model.Dismissed.prototype.getObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.Dismissed} returns this
 */
proto.model.Dismissed.prototype.setObjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.CreateCustomServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.CreateCustomServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.CreateCustomServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CreateCustomServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    category: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.CreateCustomServiceRequest}
 */
proto.model.CreateCustomServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.CreateCustomServiceRequest;
  return proto.model.CreateCustomServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.CreateCustomServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.CreateCustomServiceRequest}
 */
proto.model.CreateCustomServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.CreateCustomServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.CreateCustomServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.CreateCustomServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.CreateCustomServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.model.CreateCustomServiceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CreateCustomServiceRequest} returns this
 */
proto.model.CreateCustomServiceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string category = 2;
 * @return {string}
 */
proto.model.CreateCustomServiceRequest.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.CreateCustomServiceRequest} returns this
 */
proto.model.CreateCustomServiceRequest.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.HasCustomerData.prototype.toObject = function(opt_includeInstance) {
  return proto.model.HasCustomerData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.HasCustomerData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.HasCustomerData.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_model_pb.Identifier.toObject(includeInstance, f),
    hasCustomerData: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.HasCustomerData}
 */
proto.model.HasCustomerData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.HasCustomerData;
  return proto.model.HasCustomerData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.HasCustomerData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.HasCustomerData}
 */
proto.model.HasCustomerData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasCustomerData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.HasCustomerData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.HasCustomerData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.HasCustomerData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.HasCustomerData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getHasCustomerData();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional common.Identifier ID = 1;
 * @return {?proto.common.Identifier}
 */
proto.model.HasCustomerData.prototype.getId = function() {
  return /** @type{?proto.common.Identifier} */ (
    jspb.Message.getWrapperField(this, common_model_pb.Identifier, 1));
};


/**
 * @param {?proto.common.Identifier|undefined} value
 * @return {!proto.model.HasCustomerData} returns this
*/
proto.model.HasCustomerData.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.HasCustomerData} returns this
 */
proto.model.HasCustomerData.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.HasCustomerData.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool has_customer_data = 2;
 * @return {boolean}
 */
proto.model.HasCustomerData.prototype.getHasCustomerData = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.HasCustomerData} returns this
 */
proto.model.HasCustomerData.prototype.setHasCustomerData = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ProductDelivery.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ProductDelivery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ProductDelivery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ProductDelivery.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_model_pb.Identifier.toObject(includeInstance, f),
    productDelivery: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ProductDelivery}
 */
proto.model.ProductDelivery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ProductDelivery;
  return proto.model.ProductDelivery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ProductDelivery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ProductDelivery}
 */
proto.model.ProductDelivery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setProductDelivery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ProductDelivery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ProductDelivery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ProductDelivery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ProductDelivery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getProductDelivery();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional common.Identifier ID = 1;
 * @return {?proto.common.Identifier}
 */
proto.model.ProductDelivery.prototype.getId = function() {
  return /** @type{?proto.common.Identifier} */ (
    jspb.Message.getWrapperField(this, common_model_pb.Identifier, 1));
};


/**
 * @param {?proto.common.Identifier|undefined} value
 * @return {!proto.model.ProductDelivery} returns this
*/
proto.model.ProductDelivery.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ProductDelivery} returns this
 */
proto.model.ProductDelivery.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ProductDelivery.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool product_delivery = 2;
 * @return {boolean}
 */
proto.model.ProductDelivery.prototype.getProductDelivery = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.ProductDelivery} returns this
 */
proto.model.ProductDelivery.prototype.setProductDelivery = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.SupportingTool.prototype.toObject = function(opt_includeInstance) {
  return proto.model.SupportingTool.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.SupportingTool} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.SupportingTool.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && common_model_pb.Identifier.toObject(includeInstance, f),
    supportingTool: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.SupportingTool}
 */
proto.model.SupportingTool.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.SupportingTool;
  return proto.model.SupportingTool.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.SupportingTool} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.SupportingTool}
 */
proto.model.SupportingTool.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSupportingTool(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.SupportingTool.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.SupportingTool.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.SupportingTool} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.SupportingTool.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getSupportingTool();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional common.Identifier ID = 1;
 * @return {?proto.common.Identifier}
 */
proto.model.SupportingTool.prototype.getId = function() {
  return /** @type{?proto.common.Identifier} */ (
    jspb.Message.getWrapperField(this, common_model_pb.Identifier, 1));
};


/**
 * @param {?proto.common.Identifier|undefined} value
 * @return {!proto.model.SupportingTool} returns this
*/
proto.model.SupportingTool.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.SupportingTool} returns this
 */
proto.model.SupportingTool.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.SupportingTool.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool supporting_tool = 2;
 * @return {boolean}
 */
proto.model.SupportingTool.prototype.getSupportingTool = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.model.SupportingTool} returns this
 */
proto.model.SupportingTool.prototype.setSupportingTool = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.DismissedBatch.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.DismissedBatch.prototype.toObject = function(opt_includeInstance) {
  return proto.model.DismissedBatch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.DismissedBatch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DismissedBatch.toObject = function(includeInstance, msg) {
  var f, obj = {
    dismissedList: jspb.Message.toObjectList(msg.getDismissedList(),
    proto.model.Dismissed.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.DismissedBatch}
 */
proto.model.DismissedBatch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.DismissedBatch;
  return proto.model.DismissedBatch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.DismissedBatch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.DismissedBatch}
 */
proto.model.DismissedBatch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.Dismissed;
      reader.readMessage(value,proto.model.Dismissed.deserializeBinaryFromReader);
      msg.addDismissed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.DismissedBatch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.DismissedBatch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.DismissedBatch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.DismissedBatch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDismissedList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.model.Dismissed.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Dismissed dismissed = 1;
 * @return {!Array<!proto.model.Dismissed>}
 */
proto.model.DismissedBatch.prototype.getDismissedList = function() {
  return /** @type{!Array<!proto.model.Dismissed>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.Dismissed, 1));
};


/**
 * @param {!Array<!proto.model.Dismissed>} value
 * @return {!proto.model.DismissedBatch} returns this
*/
proto.model.DismissedBatch.prototype.setDismissedList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.Dismissed=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.Dismissed}
 */
proto.model.DismissedBatch.prototype.addDismissed = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.Dismissed, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.DismissedBatch} returns this
 */
proto.model.DismissedBatch.prototype.clearDismissedList = function() {
  return this.setDismissedList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetModelByOIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetModelByOIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetModelByOIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetModelByOIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetModelByOIDRequest}
 */
proto.model.GetModelByOIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetModelByOIDRequest;
  return proto.model.GetModelByOIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetModelByOIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetModelByOIDRequest}
 */
proto.model.GetModelByOIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetModelByOIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetModelByOIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetModelByOIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetModelByOIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.model.GetModelByOIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetModelByOIDRequest} returns this
 */
proto.model.GetModelByOIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.TimeRange.prototype.toObject = function(opt_includeInstance) {
  return proto.model.TimeRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.TimeRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TimeRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    begintime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endtime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.TimeRange}
 */
proto.model.TimeRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.TimeRange;
  return proto.model.TimeRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.TimeRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.TimeRange}
 */
proto.model.TimeRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBegintime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.TimeRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.TimeRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.TimeRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TimeRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBegintime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndtime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 BeginTime = 1;
 * @return {number}
 */
proto.model.TimeRange.prototype.getBegintime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.TimeRange} returns this
 */
proto.model.TimeRange.prototype.setBegintime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 EndTime = 2;
 * @return {number}
 */
proto.model.TimeRange.prototype.getEndtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.TimeRange} returns this
 */
proto.model.TimeRange.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.ControlStats.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ControlStats.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ControlStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ControlStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ControlStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    statsList: jspb.Message.toObjectList(msg.getStatsList(),
    proto.model.ControlStat.toObject, includeInstance),
    totalInScope: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ControlStats}
 */
proto.model.ControlStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ControlStats;
  return proto.model.ControlStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ControlStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ControlStats}
 */
proto.model.ControlStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.ControlStat;
      reader.readMessage(value,proto.model.ControlStat.deserializeBinaryFromReader);
      msg.addStats(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotalInScope(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ControlStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ControlStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ControlStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ControlStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.model.ControlStat.serializeBinaryToWriter
    );
  }
  f = message.getTotalInScope();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated ControlStat Stats = 1;
 * @return {!Array<!proto.model.ControlStat>}
 */
proto.model.ControlStats.prototype.getStatsList = function() {
  return /** @type{!Array<!proto.model.ControlStat>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.model.ControlStat, 1));
};


/**
 * @param {!Array<!proto.model.ControlStat>} value
 * @return {!proto.model.ControlStats} returns this
*/
proto.model.ControlStats.prototype.setStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.model.ControlStat=} opt_value
 * @param {number=} opt_index
 * @return {!proto.model.ControlStat}
 */
proto.model.ControlStats.prototype.addStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.model.ControlStat, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.ControlStats} returns this
 */
proto.model.ControlStats.prototype.clearStatsList = function() {
  return this.setStatsList([]);
};


/**
 * optional int64 total_in_scope = 2;
 * @return {number}
 */
proto.model.ControlStats.prototype.getTotalInScope = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.ControlStats} returns this
 */
proto.model.ControlStats.prototype.setTotalInScope = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ControlStat.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ControlStat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ControlStat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ControlStat.toObject = function(includeInstance, msg) {
  var f, obj = {
    numneedsattention: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numauditready: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numaccepted: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numreadyforreview: jspb.Message.getFieldWithDefault(msg, 4, 0),
    timestamp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    numfailedtest: jspb.Message.getFieldWithDefault(msg, 6, 0),
    numnotapplicable: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ControlStat}
 */
proto.model.ControlStat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ControlStat;
  return proto.model.ControlStat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ControlStat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ControlStat}
 */
proto.model.ControlStat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumneedsattention(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumauditready(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumaccepted(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumreadyforreview(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTimestamp(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumfailedtest(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumnotapplicable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ControlStat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ControlStat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ControlStat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ControlStat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumneedsattention();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getNumauditready();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getNumaccepted();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getNumreadyforreview();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getNumfailedtest();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getNumnotapplicable();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 NumNeedsAttention = 1;
 * @return {number}
 */
proto.model.ControlStat.prototype.getNumneedsattention = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.ControlStat} returns this
 */
proto.model.ControlStat.prototype.setNumneedsattention = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 NumAuditReady = 2;
 * @return {number}
 */
proto.model.ControlStat.prototype.getNumauditready = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.ControlStat} returns this
 */
proto.model.ControlStat.prototype.setNumauditready = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 NumAccepted = 3;
 * @return {number}
 */
proto.model.ControlStat.prototype.getNumaccepted = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.ControlStat} returns this
 */
proto.model.ControlStat.prototype.setNumaccepted = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 NumReadyForReview = 4;
 * @return {number}
 */
proto.model.ControlStat.prototype.getNumreadyforreview = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.ControlStat} returns this
 */
proto.model.ControlStat.prototype.setNumreadyforreview = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 Timestamp = 5;
 * @return {number}
 */
proto.model.ControlStat.prototype.getTimestamp = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.ControlStat} returns this
 */
proto.model.ControlStat.prototype.setTimestamp = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 NumFailedTest = 6;
 * @return {number}
 */
proto.model.ControlStat.prototype.getNumfailedtest = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.ControlStat} returns this
 */
proto.model.ControlStat.prototype.setNumfailedtest = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 NumNotApplicable = 7;
 * @return {number}
 */
proto.model.ControlStat.prototype.getNumnotapplicable = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.ControlStat} returns this
 */
proto.model.ControlStat.prototype.setNumnotapplicable = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetControlStatsByModelIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetControlStatsByModelIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetControlStatsByModelIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetControlStatsByModelIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timeRange: (f = msg.getTimeRange()) && proto.model.TimeRange.toObject(includeInstance, f),
    controlId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetControlStatsByModelIdRequest}
 */
proto.model.GetControlStatsByModelIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetControlStatsByModelIdRequest;
  return proto.model.GetControlStatsByModelIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetControlStatsByModelIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetControlStatsByModelIdRequest}
 */
proto.model.GetControlStatsByModelIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 2:
      var value = new proto.model.TimeRange;
      reader.readMessage(value,proto.model.TimeRange.deserializeBinaryFromReader);
      msg.setTimeRange(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetControlStatsByModelIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetControlStatsByModelIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetControlStatsByModelIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetControlStatsByModelIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimeRange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.model.TimeRange.serializeBinaryToWriter
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string model_id = 1;
 * @return {string}
 */
proto.model.GetControlStatsByModelIdRequest.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetControlStatsByModelIdRequest} returns this
 */
proto.model.GetControlStatsByModelIdRequest.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TimeRange time_range = 2;
 * @return {?proto.model.TimeRange}
 */
proto.model.GetControlStatsByModelIdRequest.prototype.getTimeRange = function() {
  return /** @type{?proto.model.TimeRange} */ (
    jspb.Message.getWrapperField(this, proto.model.TimeRange, 2));
};


/**
 * @param {?proto.model.TimeRange|undefined} value
 * @return {!proto.model.GetControlStatsByModelIdRequest} returns this
*/
proto.model.GetControlStatsByModelIdRequest.prototype.setTimeRange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetControlStatsByModelIdRequest} returns this
 */
proto.model.GetControlStatsByModelIdRequest.prototype.clearTimeRange = function() {
  return this.setTimeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetControlStatsByModelIdRequest.prototype.hasTimeRange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string control_id = 3;
 * @return {string}
 */
proto.model.GetControlStatsByModelIdRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.model.GetControlStatsByModelIdRequest} returns this
 */
proto.model.GetControlStatsByModelIdRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.EvidenceStats.prototype.toObject = function(opt_includeInstance) {
  return proto.model.EvidenceStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.EvidenceStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.EvidenceStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uniqueCaptions: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.EvidenceStats}
 */
proto.model.EvidenceStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.EvidenceStats;
  return proto.model.EvidenceStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.EvidenceStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.EvidenceStats}
 */
proto.model.EvidenceStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUniqueCaptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.EvidenceStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.EvidenceStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.EvidenceStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.EvidenceStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUniqueCaptions();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 total = 1;
 * @return {number}
 */
proto.model.EvidenceStats.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.EvidenceStats} returns this
 */
proto.model.EvidenceStats.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 unique_captions = 2;
 * @return {number}
 */
proto.model.EvidenceStats.prototype.getUniqueCaptions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.EvidenceStats} returns this
 */
proto.model.EvidenceStats.prototype.setUniqueCaptions = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.TestResultStats.prototype.toObject = function(opt_includeInstance) {
  return proto.model.TestResultStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.TestResultStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TestResultStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    uniqueTestDefinitions: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.TestResultStats}
 */
proto.model.TestResultStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.TestResultStats;
  return proto.model.TestResultStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.TestResultStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.TestResultStats}
 */
proto.model.TestResultStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUniqueTestDefinitions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.TestResultStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.TestResultStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.TestResultStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.TestResultStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUniqueTestDefinitions();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 total = 1;
 * @return {number}
 */
proto.model.TestResultStats.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.TestResultStats} returns this
 */
proto.model.TestResultStats.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 unique_test_definitions = 2;
 * @return {number}
 */
proto.model.TestResultStats.prototype.getUniqueTestDefinitions = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.model.TestResultStats} returns this
 */
proto.model.TestResultStats.prototype.setUniqueTestDefinitions = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetControlStatsByModelIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetControlStatsByModelIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetControlStatsByModelIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetControlStatsByModelIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceStats: (f = msg.getEvidenceStats()) && proto.model.EvidenceStats.toObject(includeInstance, f),
    testResultStats: (f = msg.getTestResultStats()) && proto.model.TestResultStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetControlStatsByModelIdResponse}
 */
proto.model.GetControlStatsByModelIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetControlStatsByModelIdResponse;
  return proto.model.GetControlStatsByModelIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetControlStatsByModelIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetControlStatsByModelIdResponse}
 */
proto.model.GetControlStatsByModelIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.EvidenceStats;
      reader.readMessage(value,proto.model.EvidenceStats.deserializeBinaryFromReader);
      msg.setEvidenceStats(value);
      break;
    case 2:
      var value = new proto.model.TestResultStats;
      reader.readMessage(value,proto.model.TestResultStats.deserializeBinaryFromReader);
      msg.setTestResultStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetControlStatsByModelIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetControlStatsByModelIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetControlStatsByModelIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetControlStatsByModelIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceStats();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.model.EvidenceStats.serializeBinaryToWriter
    );
  }
  f = message.getTestResultStats();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.model.TestResultStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional EvidenceStats evidence_stats = 1;
 * @return {?proto.model.EvidenceStats}
 */
proto.model.GetControlStatsByModelIdResponse.prototype.getEvidenceStats = function() {
  return /** @type{?proto.model.EvidenceStats} */ (
    jspb.Message.getWrapperField(this, proto.model.EvidenceStats, 1));
};


/**
 * @param {?proto.model.EvidenceStats|undefined} value
 * @return {!proto.model.GetControlStatsByModelIdResponse} returns this
*/
proto.model.GetControlStatsByModelIdResponse.prototype.setEvidenceStats = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetControlStatsByModelIdResponse} returns this
 */
proto.model.GetControlStatsByModelIdResponse.prototype.clearEvidenceStats = function() {
  return this.setEvidenceStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetControlStatsByModelIdResponse.prototype.hasEvidenceStats = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TestResultStats test_result_stats = 2;
 * @return {?proto.model.TestResultStats}
 */
proto.model.GetControlStatsByModelIdResponse.prototype.getTestResultStats = function() {
  return /** @type{?proto.model.TestResultStats} */ (
    jspb.Message.getWrapperField(this, proto.model.TestResultStats, 2));
};


/**
 * @param {?proto.model.TestResultStats|undefined} value
 * @return {!proto.model.GetControlStatsByModelIdResponse} returns this
*/
proto.model.GetControlStatsByModelIdResponse.prototype.setTestResultStats = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetControlStatsByModelIdResponse} returns this
 */
proto.model.GetControlStatsByModelIdResponse.prototype.clearTestResultStats = function() {
  return this.setTestResultStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetControlStatsByModelIdResponse.prototype.hasTestResultStats = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetControlStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetControlStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetControlStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetControlStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeRange: (f = msg.getTimeRange()) && proto.model.TimeRange.toObject(includeInstance, f),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetControlStatsRequest}
 */
proto.model.GetControlStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetControlStatsRequest;
  return proto.model.GetControlStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetControlStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetControlStatsRequest}
 */
proto.model.GetControlStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.model.TimeRange;
      reader.readMessage(value,proto.model.TimeRange.deserializeBinaryFromReader);
      msg.setTimeRange(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetControlStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetControlStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetControlStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetControlStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeRange();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.model.TimeRange.serializeBinaryToWriter
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimeRange time_range = 1;
 * @return {?proto.model.TimeRange}
 */
proto.model.GetControlStatsRequest.prototype.getTimeRange = function() {
  return /** @type{?proto.model.TimeRange} */ (
    jspb.Message.getWrapperField(this, proto.model.TimeRange, 1));
};


/**
 * @param {?proto.model.TimeRange|undefined} value
 * @return {!proto.model.GetControlStatsRequest} returns this
*/
proto.model.GetControlStatsRequest.prototype.setTimeRange = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetControlStatsRequest} returns this
 */
proto.model.GetControlStatsRequest.prototype.clearTimeRange = function() {
  return this.setTimeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetControlStatsRequest.prototype.hasTimeRange = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue audit_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.model.GetControlStatsRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.model.GetControlStatsRequest} returns this
*/
proto.model.GetControlStatsRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.GetControlStatsRequest} returns this
 */
proto.model.GetControlStatsRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.GetControlStatsRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.ListModelsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.ListModelsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.ListModelsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.ListModelsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ListModelsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    isDismissed: (f = msg.getIsDismissed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    modelIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    modelType: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.ListModelsRequest}
 */
proto.model.ListModelsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.ListModelsRequest;
  return proto.model.ListModelsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.ListModelsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.ListModelsRequest}
 */
proto.model.ListModelsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsDismissed(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addModelIds(value);
      break;
    case 3:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setModelType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.ListModelsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.ListModelsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.ListModelsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.ListModelsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsDismissed();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getModelIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getModelType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional google.protobuf.BoolValue is_dismissed = 1;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.model.ListModelsRequest.prototype.getIsDismissed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 1));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.model.ListModelsRequest} returns this
*/
proto.model.ListModelsRequest.prototype.setIsDismissed = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.model.ListModelsRequest} returns this
 */
proto.model.ListModelsRequest.prototype.clearIsDismissed = function() {
  return this.setIsDismissed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.model.ListModelsRequest.prototype.hasIsDismissed = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string model_ids = 2;
 * @return {!Array<string>}
 */
proto.model.ListModelsRequest.prototype.getModelIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.ListModelsRequest} returns this
 */
proto.model.ListModelsRequest.prototype.setModelIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.ListModelsRequest} returns this
 */
proto.model.ListModelsRequest.prototype.addModelIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.ListModelsRequest} returns this
 */
proto.model.ListModelsRequest.prototype.clearModelIdsList = function() {
  return this.setModelIdsList([]);
};


/**
 * optional common.MODEL_TYPE model_type = 3;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.model.ListModelsRequest.prototype.getModelType = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.model.ListModelsRequest} returns this
 */
proto.model.ListModelsRequest.prototype.setModelType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.GetComplianceFrameworkObjectivesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetComplianceFrameworkObjectivesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetComplianceFrameworkObjectivesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetComplianceFrameworkObjectivesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetComplianceFrameworkObjectivesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    complianceFrameworkIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetComplianceFrameworkObjectivesRequest}
 */
proto.model.GetComplianceFrameworkObjectivesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetComplianceFrameworkObjectivesRequest;
  return proto.model.GetComplianceFrameworkObjectivesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetComplianceFrameworkObjectivesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetComplianceFrameworkObjectivesRequest}
 */
proto.model.GetComplianceFrameworkObjectivesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addComplianceFrameworkIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetComplianceFrameworkObjectivesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetComplianceFrameworkObjectivesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetComplianceFrameworkObjectivesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetComplianceFrameworkObjectivesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComplianceFrameworkIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string compliance_framework_ids = 1;
 * @return {!Array<string>}
 */
proto.model.GetComplianceFrameworkObjectivesRequest.prototype.getComplianceFrameworkIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.GetComplianceFrameworkObjectivesRequest} returns this
 */
proto.model.GetComplianceFrameworkObjectivesRequest.prototype.setComplianceFrameworkIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.GetComplianceFrameworkObjectivesRequest} returns this
 */
proto.model.GetComplianceFrameworkObjectivesRequest.prototype.addComplianceFrameworkIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.GetComplianceFrameworkObjectivesRequest} returns this
 */
proto.model.GetComplianceFrameworkObjectivesRequest.prototype.clearComplianceFrameworkIdsList = function() {
  return this.setComplianceFrameworkIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.model.GetObjectivesByModelIdRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.model.GetObjectivesByModelIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.model.GetObjectivesByModelIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.model.GetObjectivesByModelIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetObjectivesByModelIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.model.GetObjectivesByModelIdRequest}
 */
proto.model.GetObjectivesByModelIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.model.GetObjectivesByModelIdRequest;
  return proto.model.GetObjectivesByModelIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.model.GetObjectivesByModelIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.model.GetObjectivesByModelIdRequest}
 */
proto.model.GetObjectivesByModelIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addModelIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.model.GetObjectivesByModelIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.model.GetObjectivesByModelIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.model.GetObjectivesByModelIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.model.GetObjectivesByModelIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string model_ids = 1;
 * @return {!Array<string>}
 */
proto.model.GetObjectivesByModelIdRequest.prototype.getModelIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.model.GetObjectivesByModelIdRequest} returns this
 */
proto.model.GetObjectivesByModelIdRequest.prototype.setModelIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.model.GetObjectivesByModelIdRequest} returns this
 */
proto.model.GetObjectivesByModelIdRequest.prototype.addModelIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.model.GetObjectivesByModelIdRequest} returns this
 */
proto.model.GetObjectivesByModelIdRequest.prototype.clearModelIdsList = function() {
  return this.setModelIdsList([]);
};


/**
 * @enum {number}
 */
proto.model.TEMPLATE_TYPE = {
  COMPLIANCE_FRAMEWORK: 0,
  CONTROL: 1,
  POLICY: 2,
  DEPARTMENT: 3,
  COMPLIANCE_FRAMEWORK_OPTION: 4,
  OBJECTIVE: 5,
  TEST_DEFINITION: 6,
  CONTROL_TEST: 7,
  SUGGESTED_EVIDENCE: 8,
  SUGGESTED_TEST: 9,
  HOW_TO_HANDLE_IT: 10
};

goog.object.extend(exports, proto.model);

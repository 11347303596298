import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import {
  Controls,
  GetControlsForComplianceFrameworkRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'

export const useControlsForComplianceFramework = (
  complianceFrameworkId: string,
): GrpcResponse<Controls> => {
  const { response } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.getControlsForComplianceFramework,
    new GetControlsForComplianceFrameworkRequest().setComplianceFrameworkId(
      complianceFrameworkId,
    ),
  )
  return NewGrpcResponse(response)
}

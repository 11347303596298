export enum LINKER_MODEL_IDS {
  GOOGLE_DRIVE = 'trl-gdrive',
}

export enum LINKER_LABELS {
  GOOGLE_DRIVE = 'Google Drive',
}

export const LinkerModelIdToLabel = Object.freeze({
  [LINKER_MODEL_IDS.GOOGLE_DRIVE]: LINKER_LABELS.GOOGLE_DRIVE,
})

type InjectorType = 'init' | 'loading' | 'loaded' | 'error'
export interface InjectorState {
  queue: Record<string, ((e: boolean) => void)[]>
  injectorMap: Record<string, InjectorType>
  scriptMap: Record<string, HTMLScriptElement>
}

export type StateType = {
  loaded: boolean
  error: boolean
}

export type PickerConfiguration = {
  cbFunc: (tokendata: {
    action: string
    docs: google.picker.DocumentObject[]
  }) => Promise<void>
  allowMultiselect: boolean
  viewId: google.picker.ViewId
  selectFolderEnabled?: boolean
}

export type PickerResponse = {
  isShown: boolean
  invalidCredentials: boolean
  exceptions: string
}

/**
 * @fileoverview gRPC-Web generated client stub for service
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: service/service.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.service = require('./service_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.service.ServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.service.ServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.service.ListServicesRequest,
 *   !proto.service.ListServicesResponse>}
 */
const methodDescriptor_Service_ListServices = new grpc.web.MethodDescriptor(
  '/service.Service/ListServices',
  grpc.web.MethodType.UNARY,
  proto.service.ListServicesRequest,
  proto.service.ListServicesResponse,
  /**
   * @param {!proto.service.ListServicesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.service.ListServicesResponse.deserializeBinary
);


/**
 * @param {!proto.service.ListServicesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.service.ListServicesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.service.ListServicesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.ServiceClient.prototype.listServices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.Service/ListServices',
      request,
      metadata || {},
      methodDescriptor_Service_ListServices,
      callback);
};


/**
 * @param {!proto.service.ListServicesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.service.ListServicesResponse>}
 *     Promise that resolves to the response
 */
proto.service.ServicePromiseClient.prototype.listServices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.Service/ListServices',
      request,
      metadata || {},
      methodDescriptor_Service_ListServices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.service.GetServiceRequest,
 *   !proto.service.ServiceRecord>}
 */
const methodDescriptor_Service_GetServiceById = new grpc.web.MethodDescriptor(
  '/service.Service/GetServiceById',
  grpc.web.MethodType.UNARY,
  proto.service.GetServiceRequest,
  proto.service.ServiceRecord,
  /**
   * @param {!proto.service.GetServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.service.ServiceRecord.deserializeBinary
);


/**
 * @param {!proto.service.GetServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.service.ServiceRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.service.ServiceRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.ServiceClient.prototype.getServiceById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.Service/GetServiceById',
      request,
      metadata || {},
      methodDescriptor_Service_GetServiceById,
      callback);
};


/**
 * @param {!proto.service.GetServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.service.ServiceRecord>}
 *     Promise that resolves to the response
 */
proto.service.ServicePromiseClient.prototype.getServiceById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.Service/GetServiceById',
      request,
      metadata || {},
      methodDescriptor_Service_GetServiceById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.service.PrimaryInfrastructureMappings>}
 */
const methodDescriptor_Service_GetPrimaryInfrastructureMappings = new grpc.web.MethodDescriptor(
  '/service.Service/GetPrimaryInfrastructureMappings',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.service.PrimaryInfrastructureMappings,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.service.PrimaryInfrastructureMappings.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.service.PrimaryInfrastructureMappings)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.service.PrimaryInfrastructureMappings>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.ServiceClient.prototype.getPrimaryInfrastructureMappings =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.Service/GetPrimaryInfrastructureMappings',
      request,
      metadata || {},
      methodDescriptor_Service_GetPrimaryInfrastructureMappings,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.service.PrimaryInfrastructureMappings>}
 *     Promise that resolves to the response
 */
proto.service.ServicePromiseClient.prototype.getPrimaryInfrastructureMappings =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.Service/GetPrimaryInfrastructureMappings',
      request,
      metadata || {},
      methodDescriptor_Service_GetPrimaryInfrastructureMappings);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.service.CreateOrUpdateServiceRequest,
 *   !proto.service.ServiceRecord>}
 */
const methodDescriptor_Service_CreateOrUpdateService = new grpc.web.MethodDescriptor(
  '/service.Service/CreateOrUpdateService',
  grpc.web.MethodType.UNARY,
  proto.service.CreateOrUpdateServiceRequest,
  proto.service.ServiceRecord,
  /**
   * @param {!proto.service.CreateOrUpdateServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.service.ServiceRecord.deserializeBinary
);


/**
 * @param {!proto.service.CreateOrUpdateServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.service.ServiceRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.service.ServiceRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.ServiceClient.prototype.createOrUpdateService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.Service/CreateOrUpdateService',
      request,
      metadata || {},
      methodDescriptor_Service_CreateOrUpdateService,
      callback);
};


/**
 * @param {!proto.service.CreateOrUpdateServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.service.ServiceRecord>}
 *     Promise that resolves to the response
 */
proto.service.ServicePromiseClient.prototype.createOrUpdateService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.Service/CreateOrUpdateService',
      request,
      metadata || {},
      methodDescriptor_Service_CreateOrUpdateService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.service.BulkCreateOrUpdateServicesRequest,
 *   !proto.service.ServiceRecords>}
 */
const methodDescriptor_Service_BulkCreateOrUpdateServices = new grpc.web.MethodDescriptor(
  '/service.Service/BulkCreateOrUpdateServices',
  grpc.web.MethodType.UNARY,
  proto.service.BulkCreateOrUpdateServicesRequest,
  proto.service.ServiceRecords,
  /**
   * @param {!proto.service.BulkCreateOrUpdateServicesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.service.ServiceRecords.deserializeBinary
);


/**
 * @param {!proto.service.BulkCreateOrUpdateServicesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.service.ServiceRecords)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.service.ServiceRecords>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.ServiceClient.prototype.bulkCreateOrUpdateServices =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.Service/BulkCreateOrUpdateServices',
      request,
      metadata || {},
      methodDescriptor_Service_BulkCreateOrUpdateServices,
      callback);
};


/**
 * @param {!proto.service.BulkCreateOrUpdateServicesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.service.ServiceRecords>}
 *     Promise that resolves to the response
 */
proto.service.ServicePromiseClient.prototype.bulkCreateOrUpdateServices =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.Service/BulkCreateOrUpdateServices',
      request,
      metadata || {},
      methodDescriptor_Service_BulkCreateOrUpdateServices);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.service.CreateCustomServiceRequest,
 *   !proto.service.ServiceRecord>}
 */
const methodDescriptor_Service_CreateCustomService = new grpc.web.MethodDescriptor(
  '/service.Service/CreateCustomService',
  grpc.web.MethodType.UNARY,
  proto.service.CreateCustomServiceRequest,
  proto.service.ServiceRecord,
  /**
   * @param {!proto.service.CreateCustomServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.service.ServiceRecord.deserializeBinary
);


/**
 * @param {!proto.service.CreateCustomServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.service.ServiceRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.service.ServiceRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.service.ServiceClient.prototype.createCustomService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/service.Service/CreateCustomService',
      request,
      metadata || {},
      methodDescriptor_Service_CreateCustomService,
      callback);
};


/**
 * @param {!proto.service.CreateCustomServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.service.ServiceRecord>}
 *     Promise that resolves to the response
 */
proto.service.ServicePromiseClient.prototype.createCustomService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/service.Service/CreateCustomService',
      request,
      metadata || {},
      methodDescriptor_Service_CreateCustomService);
};


module.exports = proto.service;


// source: agent/receptor.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var common_collections_pb = require('../common/collections_pb.js');
goog.object.extend(proto, common_collections_pb);
goog.exportSymbol('proto.agent.ControlMapping', null, global);
goog.exportSymbol('proto.agent.ControlMappingItem', null, global);
goog.exportSymbol('proto.agent.Enabled', null, global);
goog.exportSymbol('proto.agent.GetControlMappingsForReceptorRequest', null, global);
goog.exportSymbol('proto.agent.GetControlMappingsForReceptorResponse', null, global);
goog.exportSymbol('proto.agent.GetOrCreateOauthReceptorRequest', null, global);
goog.exportSymbol('proto.agent.ListIdsRequest', null, global);
goog.exportSymbol('proto.agent.ListInProgressScansResponse', null, global);
goog.exportSymbol('proto.agent.ReceptorID', null, global);
goog.exportSymbol('proto.agent.ReceptorRecord', null, global);
goog.exportSymbol('proto.agent.ReceptorRecords', null, global);
goog.exportSymbol('proto.agent.StringMessage', null, global);
goog.exportSymbol('proto.agent.Tracer', null, global);
goog.exportSymbol('proto.agent.UpdateConfigRequest', null, global);
goog.exportSymbol('proto.agent.VerifyRequest', null, global);
goog.exportSymbol('proto.agent.VerifyResult', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.StringMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.StringMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.StringMessage.displayName = 'proto.agent.StringMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ReceptorRecords = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.ReceptorRecords.repeatedFields_, null);
};
goog.inherits(proto.agent.ReceptorRecords, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ReceptorRecords.displayName = 'proto.agent.ReceptorRecords';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ReceptorRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.ReceptorRecord.repeatedFields_, null);
};
goog.inherits(proto.agent.ReceptorRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ReceptorRecord.displayName = 'proto.agent.ReceptorRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ReceptorID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.ReceptorID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ReceptorID.displayName = 'proto.agent.ReceptorID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.Enabled = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.Enabled, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.Enabled.displayName = 'proto.agent.Enabled';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.GetOrCreateOauthReceptorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.GetOrCreateOauthReceptorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.GetOrCreateOauthReceptorRequest.displayName = 'proto.agent.GetOrCreateOauthReceptorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.VerifyResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.VerifyResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.VerifyResult.displayName = 'proto.agent.VerifyResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.VerifyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.VerifyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.VerifyRequest.displayName = 'proto.agent.VerifyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.Tracer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.Tracer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.Tracer.displayName = 'proto.agent.Tracer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ListIdsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.ListIdsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ListIdsRequest.displayName = 'proto.agent.ListIdsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ListInProgressScansResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.ListInProgressScansResponse.repeatedFields_, null);
};
goog.inherits(proto.agent.ListInProgressScansResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ListInProgressScansResponse.displayName = 'proto.agent.ListInProgressScansResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ControlMappingItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.ControlMappingItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ControlMappingItem.displayName = 'proto.agent.ControlMappingItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.ControlMapping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.agent.ControlMapping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.ControlMapping.displayName = 'proto.agent.ControlMapping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.UpdateConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.UpdateConfigRequest.repeatedFields_, null);
};
goog.inherits(proto.agent.UpdateConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.UpdateConfigRequest.displayName = 'proto.agent.UpdateConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.GetControlMappingsForReceptorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.GetControlMappingsForReceptorRequest.repeatedFields_, null);
};
goog.inherits(proto.agent.GetControlMappingsForReceptorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.GetControlMappingsForReceptorRequest.displayName = 'proto.agent.GetControlMappingsForReceptorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.agent.GetControlMappingsForReceptorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.agent.GetControlMappingsForReceptorResponse.repeatedFields_, null);
};
goog.inherits(proto.agent.GetControlMappingsForReceptorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.agent.GetControlMappingsForReceptorResponse.displayName = 'proto.agent.GetControlMappingsForReceptorResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.StringMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.StringMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.StringMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.StringMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.agent.ReceptorID.toObject(includeInstance, f),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.StringMessage}
 */
proto.agent.StringMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.StringMessage;
  return proto.agent.StringMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.StringMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.StringMessage}
 */
proto.agent.StringMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.ReceptorID;
      reader.readMessage(value,proto.agent.ReceptorID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.StringMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.StringMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.StringMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.StringMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.agent.ReceptorID.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ReceptorID ID = 1;
 * @return {?proto.agent.ReceptorID}
 */
proto.agent.StringMessage.prototype.getId = function() {
  return /** @type{?proto.agent.ReceptorID} */ (
    jspb.Message.getWrapperField(this, proto.agent.ReceptorID, 1));
};


/**
 * @param {?proto.agent.ReceptorID|undefined} value
 * @return {!proto.agent.StringMessage} returns this
*/
proto.agent.StringMessage.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.StringMessage} returns this
 */
proto.agent.StringMessage.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.StringMessage.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.agent.StringMessage.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.StringMessage} returns this
 */
proto.agent.StringMessage.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.ReceptorRecords.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ReceptorRecords.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ReceptorRecords.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ReceptorRecords} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ReceptorRecords.toObject = function(includeInstance, msg) {
  var f, obj = {
    receptorsList: jspb.Message.toObjectList(msg.getReceptorsList(),
    proto.agent.ReceptorRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ReceptorRecords}
 */
proto.agent.ReceptorRecords.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ReceptorRecords;
  return proto.agent.ReceptorRecords.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ReceptorRecords} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ReceptorRecords}
 */
proto.agent.ReceptorRecords.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.ReceptorRecord;
      reader.readMessage(value,proto.agent.ReceptorRecord.deserializeBinaryFromReader);
      msg.addReceptors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ReceptorRecords.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ReceptorRecords.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ReceptorRecords} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ReceptorRecords.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceptorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.agent.ReceptorRecord.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReceptorRecord receptors = 1;
 * @return {!Array<!proto.agent.ReceptorRecord>}
 */
proto.agent.ReceptorRecords.prototype.getReceptorsList = function() {
  return /** @type{!Array<!proto.agent.ReceptorRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.agent.ReceptorRecord, 1));
};


/**
 * @param {!Array<!proto.agent.ReceptorRecord>} value
 * @return {!proto.agent.ReceptorRecords} returns this
*/
proto.agent.ReceptorRecords.prototype.setReceptorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.agent.ReceptorRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.agent.ReceptorRecord}
 */
proto.agent.ReceptorRecords.prototype.addReceptors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.agent.ReceptorRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.ReceptorRecords} returns this
 */
proto.agent.ReceptorRecords.prototype.clearReceptorsList = function() {
  return this.setReceptorsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.ReceptorRecord.repeatedFields_ = [12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ReceptorRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ReceptorRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ReceptorRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ReceptorRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdat: jspb.Message.getFieldWithDefault(msg, 1, 0),
    updatedat: jspb.Message.getFieldWithDefault(msg, 2, 0),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    modelid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    credentials: jspb.Message.getFieldWithDefault(msg, 6, ""),
    config: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isenabled: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    iscredvalid: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    tenantid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    redirect: jspb.Message.getFieldWithDefault(msg, 11, ""),
    automatedcontrolsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    lastrun: jspb.Message.getFieldWithDefault(msg, 13, 0),
    exceptions: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ReceptorRecord}
 */
proto.agent.ReceptorRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ReceptorRecord;
  return proto.agent.ReceptorRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ReceptorRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ReceptorRecord}
 */
proto.agent.ReceptorRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUpdatedat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCredentials(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfig(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsenabled(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscredvalid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirect(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addAutomatedcontrols(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLastrun(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setExceptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ReceptorRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ReceptorRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ReceptorRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ReceptorRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getUpdatedat();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModelid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCredentials();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getConfig();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsenabled();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIscredvalid();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRedirect();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAutomatedcontrolsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getLastrun();
  if (f !== 0) {
    writer.writeInt64(
      13,
      f
    );
  }
  f = message.getExceptions();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional int64 CreatedAt = 1;
 * @return {number}
 */
proto.agent.ReceptorRecord.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 UpdatedAt = 2;
 * @return {number}
 */
proto.agent.ReceptorRecord.prototype.getUpdatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.setUpdatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string ID = 3;
 * @return {string}
 */
proto.agent.ReceptorRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string AccountID = 4;
 * @return {string}
 */
proto.agent.ReceptorRecord.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ModelID = 5;
 * @return {string}
 */
proto.agent.ReceptorRecord.prototype.getModelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.setModelid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Credentials = 6;
 * @return {string}
 */
proto.agent.ReceptorRecord.prototype.getCredentials = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.setCredentials = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Config = 7;
 * @return {string}
 */
proto.agent.ReceptorRecord.prototype.getConfig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.setConfig = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool IsEnabled = 8;
 * @return {boolean}
 */
proto.agent.ReceptorRecord.prototype.getIsenabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.setIsenabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool IsCredValid = 9;
 * @return {boolean}
 */
proto.agent.ReceptorRecord.prototype.getIscredvalid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.setIscredvalid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional string TenantId = 10;
 * @return {string}
 */
proto.agent.ReceptorRecord.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string redirect = 11;
 * @return {string}
 */
proto.agent.ReceptorRecord.prototype.getRedirect = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.setRedirect = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated string AutomatedControls = 12;
 * @return {!Array<string>}
 */
proto.agent.ReceptorRecord.prototype.getAutomatedcontrolsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.setAutomatedcontrolsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.addAutomatedcontrols = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.clearAutomatedcontrolsList = function() {
  return this.setAutomatedcontrolsList([]);
};


/**
 * optional int64 LastRun = 13;
 * @return {number}
 */
proto.agent.ReceptorRecord.prototype.getLastrun = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.setLastrun = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string Exceptions = 14;
 * @return {string}
 */
proto.agent.ReceptorRecord.prototype.getExceptions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReceptorRecord} returns this
 */
proto.agent.ReceptorRecord.prototype.setExceptions = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ReceptorID.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ReceptorID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ReceptorID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ReceptorID.toObject = function(includeInstance, msg) {
  var f, obj = {
    oid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tenantid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    currenturl: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorredirect: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ReceptorID}
 */
proto.agent.ReceptorID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ReceptorID;
  return proto.agent.ReceptorID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ReceptorID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ReceptorID}
 */
proto.agent.ReceptorID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrenturl(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorredirect(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ReceptorID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ReceptorID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ReceptorID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ReceptorID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCurrenturl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorredirect();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string OID = 1;
 * @return {string}
 */
proto.agent.ReceptorID.prototype.getOid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReceptorID} returns this
 */
proto.agent.ReceptorID.prototype.setOid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ModelID = 2;
 * @return {string}
 */
proto.agent.ReceptorID.prototype.getModelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReceptorID} returns this
 */
proto.agent.ReceptorID.prototype.setModelid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string TenantID = 3;
 * @return {string}
 */
proto.agent.ReceptorID.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReceptorID} returns this
 */
proto.agent.ReceptorID.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string CurrentURL = 4;
 * @return {string}
 */
proto.agent.ReceptorID.prototype.getCurrenturl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReceptorID} returns this
 */
proto.agent.ReceptorID.prototype.setCurrenturl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ErrorRedirect = 5;
 * @return {string}
 */
proto.agent.ReceptorID.prototype.getErrorredirect = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ReceptorID} returns this
 */
proto.agent.ReceptorID.prototype.setErrorredirect = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.Enabled.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.Enabled.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.Enabled} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.Enabled.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.agent.ReceptorID.toObject(includeInstance, f),
    isenabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.Enabled}
 */
proto.agent.Enabled.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.Enabled;
  return proto.agent.Enabled.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.Enabled} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.Enabled}
 */
proto.agent.Enabled.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.ReceptorID;
      reader.readMessage(value,proto.agent.ReceptorID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsenabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.Enabled.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.Enabled.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.Enabled} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.Enabled.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.agent.ReceptorID.serializeBinaryToWriter
    );
  }
  f = message.getIsenabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional ReceptorID ID = 1;
 * @return {?proto.agent.ReceptorID}
 */
proto.agent.Enabled.prototype.getId = function() {
  return /** @type{?proto.agent.ReceptorID} */ (
    jspb.Message.getWrapperField(this, proto.agent.ReceptorID, 1));
};


/**
 * @param {?proto.agent.ReceptorID|undefined} value
 * @return {!proto.agent.Enabled} returns this
*/
proto.agent.Enabled.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.Enabled} returns this
 */
proto.agent.Enabled.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.Enabled.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool IsEnabled = 2;
 * @return {boolean}
 */
proto.agent.Enabled.prototype.getIsenabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.Enabled} returns this
 */
proto.agent.Enabled.prototype.setIsenabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.GetOrCreateOauthReceptorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.GetOrCreateOauthReceptorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.GetOrCreateOauthReceptorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.GetOrCreateOauthReceptorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.agent.ReceptorID.toObject(includeInstance, f),
    redirect: jspb.Message.getFieldWithDefault(msg, 2, ""),
    credentials: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tenantId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    errorRedirect: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.GetOrCreateOauthReceptorRequest}
 */
proto.agent.GetOrCreateOauthReceptorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.GetOrCreateOauthReceptorRequest;
  return proto.agent.GetOrCreateOauthReceptorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.GetOrCreateOauthReceptorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.GetOrCreateOauthReceptorRequest}
 */
proto.agent.GetOrCreateOauthReceptorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.ReceptorID;
      reader.readMessage(value,proto.agent.ReceptorID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirect(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCredentials(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorRedirect(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.GetOrCreateOauthReceptorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.GetOrCreateOauthReceptorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.GetOrCreateOauthReceptorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.GetOrCreateOauthReceptorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.agent.ReceptorID.serializeBinaryToWriter
    );
  }
  f = message.getRedirect();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCredentials();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTenantId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getErrorRedirect();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional ReceptorID ID = 1;
 * @return {?proto.agent.ReceptorID}
 */
proto.agent.GetOrCreateOauthReceptorRequest.prototype.getId = function() {
  return /** @type{?proto.agent.ReceptorID} */ (
    jspb.Message.getWrapperField(this, proto.agent.ReceptorID, 1));
};


/**
 * @param {?proto.agent.ReceptorID|undefined} value
 * @return {!proto.agent.GetOrCreateOauthReceptorRequest} returns this
*/
proto.agent.GetOrCreateOauthReceptorRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.GetOrCreateOauthReceptorRequest} returns this
 */
proto.agent.GetOrCreateOauthReceptorRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.GetOrCreateOauthReceptorRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string redirect = 2;
 * @return {string}
 */
proto.agent.GetOrCreateOauthReceptorRequest.prototype.getRedirect = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.GetOrCreateOauthReceptorRequest} returns this
 */
proto.agent.GetOrCreateOauthReceptorRequest.prototype.setRedirect = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string credentials = 3;
 * @return {string}
 */
proto.agent.GetOrCreateOauthReceptorRequest.prototype.getCredentials = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.GetOrCreateOauthReceptorRequest} returns this
 */
proto.agent.GetOrCreateOauthReceptorRequest.prototype.setCredentials = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tenant_id = 4;
 * @return {string}
 */
proto.agent.GetOrCreateOauthReceptorRequest.prototype.getTenantId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.GetOrCreateOauthReceptorRequest} returns this
 */
proto.agent.GetOrCreateOauthReceptorRequest.prototype.setTenantId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string error_redirect = 5;
 * @return {string}
 */
proto.agent.GetOrCreateOauthReceptorRequest.prototype.getErrorRedirect = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.GetOrCreateOauthReceptorRequest} returns this
 */
proto.agent.GetOrCreateOauthReceptorRequest.prototype.setErrorRedirect = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.VerifyResult.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.VerifyResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.VerifyResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.VerifyResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.agent.ReceptorID.toObject(includeInstance, f),
    iscredvalid: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    message: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tenantid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    exceptions: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.VerifyResult}
 */
proto.agent.VerifyResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.VerifyResult;
  return proto.agent.VerifyResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.VerifyResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.VerifyResult}
 */
proto.agent.VerifyResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.ReceptorID;
      reader.readMessage(value,proto.agent.ReceptorID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscredvalid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTenantid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExceptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.VerifyResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.VerifyResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.VerifyResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.VerifyResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.agent.ReceptorID.serializeBinaryToWriter
    );
  }
  f = message.getIscredvalid();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTenantid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExceptions();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional ReceptorID ID = 1;
 * @return {?proto.agent.ReceptorID}
 */
proto.agent.VerifyResult.prototype.getId = function() {
  return /** @type{?proto.agent.ReceptorID} */ (
    jspb.Message.getWrapperField(this, proto.agent.ReceptorID, 1));
};


/**
 * @param {?proto.agent.ReceptorID|undefined} value
 * @return {!proto.agent.VerifyResult} returns this
*/
proto.agent.VerifyResult.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.VerifyResult} returns this
 */
proto.agent.VerifyResult.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.VerifyResult.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool IsCredValid = 2;
 * @return {boolean}
 */
proto.agent.VerifyResult.prototype.getIscredvalid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.agent.VerifyResult} returns this
 */
proto.agent.VerifyResult.prototype.setIscredvalid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string Message = 3;
 * @return {string}
 */
proto.agent.VerifyResult.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.VerifyResult} returns this
 */
proto.agent.VerifyResult.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string TenantId = 4;
 * @return {string}
 */
proto.agent.VerifyResult.prototype.getTenantid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.VerifyResult} returns this
 */
proto.agent.VerifyResult.prototype.setTenantid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Exceptions = 5;
 * @return {string}
 */
proto.agent.VerifyResult.prototype.getExceptions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.VerifyResult} returns this
 */
proto.agent.VerifyResult.prototype.setExceptions = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.VerifyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.VerifyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.VerifyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.VerifyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && proto.agent.ReceptorID.toObject(includeInstance, f),
    credentials: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.VerifyRequest}
 */
proto.agent.VerifyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.VerifyRequest;
  return proto.agent.VerifyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.VerifyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.VerifyRequest}
 */
proto.agent.VerifyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.ReceptorID;
      reader.readMessage(value,proto.agent.ReceptorID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCredentials(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.VerifyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.VerifyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.VerifyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.VerifyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.agent.ReceptorID.serializeBinaryToWriter
    );
  }
  f = message.getCredentials();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ReceptorID ID = 1;
 * @return {?proto.agent.ReceptorID}
 */
proto.agent.VerifyRequest.prototype.getId = function() {
  return /** @type{?proto.agent.ReceptorID} */ (
    jspb.Message.getWrapperField(this, proto.agent.ReceptorID, 1));
};


/**
 * @param {?proto.agent.ReceptorID|undefined} value
 * @return {!proto.agent.VerifyRequest} returns this
*/
proto.agent.VerifyRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.VerifyRequest} returns this
 */
proto.agent.VerifyRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.VerifyRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Credentials = 2;
 * @return {string}
 */
proto.agent.VerifyRequest.prototype.getCredentials = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.VerifyRequest} returns this
 */
proto.agent.VerifyRequest.prototype.setCredentials = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.Tracer.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.Tracer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.Tracer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.Tracer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    taskid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.Tracer}
 */
proto.agent.Tracer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.Tracer;
  return proto.agent.Tracer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.Tracer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.Tracer}
 */
proto.agent.Tracer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaskid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.Tracer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.Tracer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.Tracer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.Tracer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTaskid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.agent.Tracer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.Tracer} returns this
 */
proto.agent.Tracer.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string TaskID = 2;
 * @return {string}
 */
proto.agent.Tracer.prototype.getTaskid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.Tracer} returns this
 */
proto.agent.Tracer.prototype.setTaskid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ListIdsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ListIdsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ListIdsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ListIdsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ListIdsRequest}
 */
proto.agent.ListIdsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ListIdsRequest;
  return proto.agent.ListIdsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ListIdsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ListIdsRequest}
 */
proto.agent.ListIdsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ListIdsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ListIdsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ListIdsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ListIdsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.ListInProgressScansResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ListInProgressScansResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ListInProgressScansResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ListInProgressScansResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ListInProgressScansResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    receptorIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ListInProgressScansResponse}
 */
proto.agent.ListInProgressScansResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ListInProgressScansResponse;
  return proto.agent.ListInProgressScansResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ListInProgressScansResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ListInProgressScansResponse}
 */
proto.agent.ListInProgressScansResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addReceptorIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ListInProgressScansResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ListInProgressScansResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ListInProgressScansResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ListInProgressScansResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceptorIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string receptor_ids = 1;
 * @return {!Array<string>}
 */
proto.agent.ListInProgressScansResponse.prototype.getReceptorIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.agent.ListInProgressScansResponse} returns this
 */
proto.agent.ListInProgressScansResponse.prototype.setReceptorIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.agent.ListInProgressScansResponse} returns this
 */
proto.agent.ListInProgressScansResponse.prototype.addReceptorIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.ListInProgressScansResponse} returns this
 */
proto.agent.ListInProgressScansResponse.prototype.clearReceptorIdsList = function() {
  return this.setReceptorIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ControlMappingItem.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ControlMappingItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ControlMappingItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ControlMappingItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ControlMappingItem}
 */
proto.agent.ControlMappingItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ControlMappingItem;
  return proto.agent.ControlMappingItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ControlMappingItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ControlMappingItem}
 */
proto.agent.ControlMappingItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ControlMappingItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ControlMappingItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ControlMappingItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ControlMappingItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.agent.ControlMappingItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ControlMappingItem} returns this
 */
proto.agent.ControlMappingItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.agent.ControlMappingItem.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ControlMappingItem} returns this
 */
proto.agent.ControlMappingItem.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.ControlMapping.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.ControlMapping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.ControlMapping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ControlMapping.toObject = function(includeInstance, msg) {
  var f, obj = {
    control: (f = msg.getControl()) && proto.agent.ControlMappingItem.toObject(includeInstance, f),
    source: (f = msg.getSource()) && proto.agent.ControlMappingItem.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serviceModelId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.ControlMapping}
 */
proto.agent.ControlMapping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.ControlMapping;
  return proto.agent.ControlMapping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.ControlMapping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.ControlMapping}
 */
proto.agent.ControlMapping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.ControlMappingItem;
      reader.readMessage(value,proto.agent.ControlMappingItem.deserializeBinaryFromReader);
      msg.setControl(value);
      break;
    case 2:
      var value = new proto.agent.ControlMappingItem;
      reader.readMessage(value,proto.agent.ControlMappingItem.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.ControlMapping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.ControlMapping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.ControlMapping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.ControlMapping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControl();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.agent.ControlMappingItem.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.agent.ControlMappingItem.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServiceModelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional ControlMappingItem control = 1;
 * @return {?proto.agent.ControlMappingItem}
 */
proto.agent.ControlMapping.prototype.getControl = function() {
  return /** @type{?proto.agent.ControlMappingItem} */ (
    jspb.Message.getWrapperField(this, proto.agent.ControlMappingItem, 1));
};


/**
 * @param {?proto.agent.ControlMappingItem|undefined} value
 * @return {!proto.agent.ControlMapping} returns this
*/
proto.agent.ControlMapping.prototype.setControl = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ControlMapping} returns this
 */
proto.agent.ControlMapping.prototype.clearControl = function() {
  return this.setControl(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ControlMapping.prototype.hasControl = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ControlMappingItem source = 2;
 * @return {?proto.agent.ControlMappingItem}
 */
proto.agent.ControlMapping.prototype.getSource = function() {
  return /** @type{?proto.agent.ControlMappingItem} */ (
    jspb.Message.getWrapperField(this, proto.agent.ControlMappingItem, 2));
};


/**
 * @param {?proto.agent.ControlMappingItem|undefined} value
 * @return {!proto.agent.ControlMapping} returns this
*/
proto.agent.ControlMapping.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.agent.ControlMapping} returns this
 */
proto.agent.ControlMapping.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.agent.ControlMapping.prototype.hasSource = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.agent.ControlMapping.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ControlMapping} returns this
 */
proto.agent.ControlMapping.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string service_model_id = 4;
 * @return {string}
 */
proto.agent.ControlMapping.prototype.getServiceModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.ControlMapping} returns this
 */
proto.agent.ControlMapping.prototype.setServiceModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.UpdateConfigRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.UpdateConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.UpdateConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.UpdateConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.UpdateConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    receptorId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    config: jspb.Message.getFieldWithDefault(msg, 2, ""),
    controlMappingsList: jspb.Message.toObjectList(msg.getControlMappingsList(),
    proto.agent.ControlMapping.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.UpdateConfigRequest}
 */
proto.agent.UpdateConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.UpdateConfigRequest;
  return proto.agent.UpdateConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.UpdateConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.UpdateConfigRequest}
 */
proto.agent.UpdateConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceptorId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfig(value);
      break;
    case 3:
      var value = new proto.agent.ControlMapping;
      reader.readMessage(value,proto.agent.ControlMapping.deserializeBinaryFromReader);
      msg.addControlMappings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.UpdateConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.UpdateConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.UpdateConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.UpdateConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceptorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConfig();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getControlMappingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.agent.ControlMapping.serializeBinaryToWriter
    );
  }
};


/**
 * optional string receptor_id = 1;
 * @return {string}
 */
proto.agent.UpdateConfigRequest.prototype.getReceptorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.UpdateConfigRequest} returns this
 */
proto.agent.UpdateConfigRequest.prototype.setReceptorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string config = 2;
 * @return {string}
 */
proto.agent.UpdateConfigRequest.prototype.getConfig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.UpdateConfigRequest} returns this
 */
proto.agent.UpdateConfigRequest.prototype.setConfig = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ControlMapping control_mappings = 3;
 * @return {!Array<!proto.agent.ControlMapping>}
 */
proto.agent.UpdateConfigRequest.prototype.getControlMappingsList = function() {
  return /** @type{!Array<!proto.agent.ControlMapping>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.agent.ControlMapping, 3));
};


/**
 * @param {!Array<!proto.agent.ControlMapping>} value
 * @return {!proto.agent.UpdateConfigRequest} returns this
*/
proto.agent.UpdateConfigRequest.prototype.setControlMappingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.agent.ControlMapping=} opt_value
 * @param {number=} opt_index
 * @return {!proto.agent.ControlMapping}
 */
proto.agent.UpdateConfigRequest.prototype.addControlMappings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.agent.ControlMapping, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.UpdateConfigRequest} returns this
 */
proto.agent.UpdateConfigRequest.prototype.clearControlMappingsList = function() {
  return this.setControlMappingsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.GetControlMappingsForReceptorRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.GetControlMappingsForReceptorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.GetControlMappingsForReceptorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.GetControlMappingsForReceptorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.GetControlMappingsForReceptorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    receptorId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.GetControlMappingsForReceptorRequest}
 */
proto.agent.GetControlMappingsForReceptorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.GetControlMappingsForReceptorRequest;
  return proto.agent.GetControlMappingsForReceptorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.GetControlMappingsForReceptorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.GetControlMappingsForReceptorRequest}
 */
proto.agent.GetControlMappingsForReceptorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceptorId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addFields(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.GetControlMappingsForReceptorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.GetControlMappingsForReceptorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.GetControlMappingsForReceptorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.GetControlMappingsForReceptorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceptorId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string receptor_id = 1;
 * @return {string}
 */
proto.agent.GetControlMappingsForReceptorRequest.prototype.getReceptorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.agent.GetControlMappingsForReceptorRequest} returns this
 */
proto.agent.GetControlMappingsForReceptorRequest.prototype.setReceptorId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string fields = 2;
 * @return {!Array<string>}
 */
proto.agent.GetControlMappingsForReceptorRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.agent.GetControlMappingsForReceptorRequest} returns this
 */
proto.agent.GetControlMappingsForReceptorRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.agent.GetControlMappingsForReceptorRequest} returns this
 */
proto.agent.GetControlMappingsForReceptorRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.GetControlMappingsForReceptorRequest} returns this
 */
proto.agent.GetControlMappingsForReceptorRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.agent.GetControlMappingsForReceptorResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.agent.GetControlMappingsForReceptorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.agent.GetControlMappingsForReceptorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.agent.GetControlMappingsForReceptorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.GetControlMappingsForReceptorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlMappingsList: jspb.Message.toObjectList(msg.getControlMappingsList(),
    proto.agent.ControlMapping.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.agent.GetControlMappingsForReceptorResponse}
 */
proto.agent.GetControlMappingsForReceptorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.agent.GetControlMappingsForReceptorResponse;
  return proto.agent.GetControlMappingsForReceptorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.agent.GetControlMappingsForReceptorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.agent.GetControlMappingsForReceptorResponse}
 */
proto.agent.GetControlMappingsForReceptorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.agent.ControlMapping;
      reader.readMessage(value,proto.agent.ControlMapping.deserializeBinaryFromReader);
      msg.addControlMappings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.agent.GetControlMappingsForReceptorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.agent.GetControlMappingsForReceptorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.agent.GetControlMappingsForReceptorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.agent.GetControlMappingsForReceptorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlMappingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.agent.ControlMapping.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ControlMapping control_mappings = 1;
 * @return {!Array<!proto.agent.ControlMapping>}
 */
proto.agent.GetControlMappingsForReceptorResponse.prototype.getControlMappingsList = function() {
  return /** @type{!Array<!proto.agent.ControlMapping>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.agent.ControlMapping, 1));
};


/**
 * @param {!Array<!proto.agent.ControlMapping>} value
 * @return {!proto.agent.GetControlMappingsForReceptorResponse} returns this
*/
proto.agent.GetControlMappingsForReceptorResponse.prototype.setControlMappingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.agent.ControlMapping=} opt_value
 * @param {number=} opt_index
 * @return {!proto.agent.ControlMapping}
 */
proto.agent.GetControlMappingsForReceptorResponse.prototype.addControlMappings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.agent.ControlMapping, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.agent.GetControlMappingsForReceptorResponse} returns this
 */
proto.agent.GetControlMappingsForReceptorResponse.prototype.clearControlMappingsList = function() {
  return this.setControlMappingsList([]);
};


goog.object.extend(exports, proto.agent);

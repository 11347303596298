import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { GridColumn, GridRow } from '../Grid'
import { ExpandButton } from '../../Buttons'
import { RowItemElement } from './RowItemProp'
import { ExpandableGrid } from './ExpandableGrid'

type Props = RowItemElement & {
  isExpanded: boolean
  gridRow?: number
  onExpanded?: (state: boolean) => void
  expandedElement?: RowItemElement
  gridTemplateColumns?: string
}

export function AccordionGridRow(props: Props): JSX.Element {
  const RowItem = props.RowItem
  return (
    <GridRow as={Accordion}>
      {props.isExpanded && props.expandedElement && (
        <ExpandableGrid {...props}>
          {props.expandedElement.RowItem({
            gridRow: props.gridRow,
            isExpanded: props.isExpanded,
          })}
        </ExpandableGrid>
      )}
      <RowItem {...props} isExpanded={props.isExpanded} />
      <GridColumn px="0" pr="s" gridRow={props.gridRow}>
        {props.expandedElement && (
          <ExpandButton
            onToggled={(newState) =>
              props.onExpanded && props.onExpanded(newState)
            }
          />
        )}
      </GridColumn>
    </GridRow>
  )
}

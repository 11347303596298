import jwtDecode from 'jwt-decode'
import {
  USE_CASE,
  USER_ROLE,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import AuthService from '../services/auth.service'
import {
  AuthAction,
  AuthActionType,
  JwtNtrcePayload,
} from '../context/authContext'
import { NTRCE_API_URL, translateAuthError } from './gRpcAdapter'

export const getOAuthUrl = (path: string): string => NTRCE_API_URL + path

class AuthAdapter {
  login(
    authDispatch: React.Dispatch<AuthAction>,
    email: string,
    password: string,
  ) {
    return AuthService.login(email, password)
      .then((response) => {
        const accessToken = response.getJwsToken()
        const { auc: useCase, rol: role } =
          jwtDecode<JwtNtrcePayload>(accessToken)
        authDispatch({
          type: AuthActionType.LOGIN,
          email,
          accessToken,
          useCase: Number(useCase) || USE_CASE.SAAS_BUYER,
          role: Number(role),
        })
        return { success: true, errors: null }
      })
      .catch((err) => {
        return { success: false, errors: translateAuthError(err) }
      })
  }

  logout(authDispatch: React.Dispatch<AuthAction>) {
    authDispatch({
      type: AuthActionType.LOGOUT,
      useCase: USE_CASE.SAAS_BUYER,
      role: USER_ROLE.READONLY, // we want to default to limited access
    })
  }

  register(
    authDispatch: React.Dispatch<AuthAction>,
    domain: string,
    email: string,
    password: string,
  ) {
    return AuthService.register(domain, email, password)
      .then((response) => {
        authDispatch({
          type: AuthActionType.SET_EMAIL,
          email,
          useCase: USE_CASE.SAAS_BUYER,
          role: USER_ROLE.READONLY,
        })
        return { success: true, callid: response.getCallid(), errors: null }
      })
      .catch((err) => {
        return {
          success: false,
          callid: null,
          errors: translateAuthError(err),
        }
      })
  }

  registerConfirm(authDispatch: React.Dispatch<AuthAction>, callid: string) {
    return AuthService.registerConfirm(callid)
      .then((response) => {
        const accessToken = response.getAccesstoken()
        const {
          aid: accountId,
          sub: email,
          auc: useCase,
          rol: role,
        } = jwtDecode<JwtNtrcePayload>(accessToken)
        authDispatch({
          type: AuthActionType.REGISTER_LOGIN,
          accessToken,
          accountId,
          email,
          useCase: Number(useCase) || USE_CASE.SAAS_BUYER,
          role: Number(role),
        })
        return { success: true, errors: null }
      })
      .catch((err) => {
        return { success: false, errors: translateAuthError(err) }
      })
  }
}

export default new AuthAdapter()

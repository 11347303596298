// source: request/request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var common_collections_pb = require('../common/collections_pb.js');
goog.object.extend(proto, common_collections_pb);
goog.exportSymbol('proto.request.CountDocumentRequestsResponse', null, global);
goog.exportSymbol('proto.request.CreateOrUpdateDocumentRequestRequest', null, global);
goog.exportSymbol('proto.request.DOCUMENT_REQUEST_STATUS', null, global);
goog.exportSymbol('proto.request.DeleteDocumentRequestRequest', null, global);
goog.exportSymbol('proto.request.DocumentRequest', null, global);
goog.exportSymbol('proto.request.DocumentRequests', null, global);
goog.exportSymbol('proto.request.GetDocumentRequestRequest', null, global);
goog.exportSymbol('proto.request.ListDocumentRequestsRequest', null, global);
goog.exportSymbol('proto.request.OwnerFilter', null, global);
goog.exportSymbol('proto.request.REQUEST_SORT_COLUMN', null, global);
goog.exportSymbol('proto.request.REQUEST_SORT_ORDER', null, global);
goog.exportSymbol('proto.request.RequestSort', null, global);
goog.exportSymbol('proto.request.StatusFilter', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.DocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.DocumentRequest.repeatedFields_, null);
};
goog.inherits(proto.request.DocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.DocumentRequest.displayName = 'proto.request.DocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.DocumentRequests = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.DocumentRequests.repeatedFields_, null);
};
goog.inherits(proto.request.DocumentRequests, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.DocumentRequests.displayName = 'proto.request.DocumentRequests';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.CreateOrUpdateDocumentRequestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.CreateOrUpdateDocumentRequestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.CreateOrUpdateDocumentRequestRequest.displayName = 'proto.request.CreateOrUpdateDocumentRequestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.GetDocumentRequestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.GetDocumentRequestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.GetDocumentRequestRequest.displayName = 'proto.request.GetDocumentRequestRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.StatusFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.StatusFilter.repeatedFields_, null);
};
goog.inherits(proto.request.StatusFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.StatusFilter.displayName = 'proto.request.StatusFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.OwnerFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.OwnerFilter.repeatedFields_, null);
};
goog.inherits(proto.request.OwnerFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.OwnerFilter.displayName = 'proto.request.OwnerFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.RequestSort = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.RequestSort, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.RequestSort.displayName = 'proto.request.RequestSort';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.ListDocumentRequestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.ListDocumentRequestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.ListDocumentRequestsRequest.displayName = 'proto.request.ListDocumentRequestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.CountDocumentRequestsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.CountDocumentRequestsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.CountDocumentRequestsResponse.displayName = 'proto.request.CountDocumentRequestsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.DeleteDocumentRequestRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.DeleteDocumentRequestRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.DeleteDocumentRequestRequest.displayName = 'proto.request.DeleteDocumentRequestRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.DocumentRequest.repeatedFields_ = [11,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.DocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.DocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.DocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.DocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    auditId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    creatorId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    request: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, 0),
    dueDate: (f = msg.getDueDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    controlIdsList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    evidenceIdsList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    controlsCount: jspb.Message.getFieldWithDefault(msg, 13, 0),
    evidenceCount: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.DocumentRequest}
 */
proto.request.DocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.DocumentRequest;
  return proto.request.DocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.DocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.DocumentRequest}
 */
proto.request.DocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatorId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequest(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDueDate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addControlIds(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addEvidenceIds(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setControlsCount(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEvidenceCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.DocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.DocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.DocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.DocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreatorId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRequest();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getDueDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getControlIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = message.getEvidenceIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getControlsCount();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getEvidenceCount();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp created_at = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.request.DocumentRequest.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.request.DocumentRequest} returns this
*/
proto.request.DocumentRequest.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.DocumentRequest.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.request.DocumentRequest.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.request.DocumentRequest} returns this
*/
proto.request.DocumentRequest.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.DocumentRequest.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.request.DocumentRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_id = 4;
 * @return {string}
 */
proto.request.DocumentRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string audit_id = 5;
 * @return {string}
 */
proto.request.DocumentRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string creator_id = 6;
 * @return {string}
 */
proto.request.DocumentRequest.prototype.getCreatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.setCreatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string owner_id = 7;
 * @return {string}
 */
proto.request.DocumentRequest.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string request = 8;
 * @return {string}
 */
proto.request.DocumentRequest.prototype.getRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.setRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 status = 9;
 * @return {number}
 */
proto.request.DocumentRequest.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional google.protobuf.Timestamp due_date = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.request.DocumentRequest.prototype.getDueDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.request.DocumentRequest} returns this
*/
proto.request.DocumentRequest.prototype.setDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.clearDueDate = function() {
  return this.setDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.DocumentRequest.prototype.hasDueDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * repeated string control_ids = 11;
 * @return {!Array<string>}
 */
proto.request.DocumentRequest.prototype.getControlIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.setControlIdsList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.addControlIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.clearControlIdsList = function() {
  return this.setControlIdsList([]);
};


/**
 * repeated string evidence_ids = 12;
 * @return {!Array<string>}
 */
proto.request.DocumentRequest.prototype.getEvidenceIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.setEvidenceIdsList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.addEvidenceIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.clearEvidenceIdsList = function() {
  return this.setEvidenceIdsList([]);
};


/**
 * optional int32 controls_count = 13;
 * @return {number}
 */
proto.request.DocumentRequest.prototype.getControlsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.setControlsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 evidence_count = 14;
 * @return {number}
 */
proto.request.DocumentRequest.prototype.getEvidenceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.DocumentRequest} returns this
 */
proto.request.DocumentRequest.prototype.setEvidenceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.DocumentRequests.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.DocumentRequests.prototype.toObject = function(opt_includeInstance) {
  return proto.request.DocumentRequests.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.DocumentRequests} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.DocumentRequests.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.request.DocumentRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.DocumentRequests}
 */
proto.request.DocumentRequests.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.DocumentRequests;
  return proto.request.DocumentRequests.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.DocumentRequests} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.DocumentRequests}
 */
proto.request.DocumentRequests.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.request.DocumentRequest;
      reader.readMessage(value,proto.request.DocumentRequest.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.DocumentRequests.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.DocumentRequests.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.DocumentRequests} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.DocumentRequests.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.request.DocumentRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DocumentRequest items = 1;
 * @return {!Array<!proto.request.DocumentRequest>}
 */
proto.request.DocumentRequests.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.request.DocumentRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.DocumentRequest, 1));
};


/**
 * @param {!Array<!proto.request.DocumentRequest>} value
 * @return {!proto.request.DocumentRequests} returns this
*/
proto.request.DocumentRequests.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.request.DocumentRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.DocumentRequest}
 */
proto.request.DocumentRequests.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.request.DocumentRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.DocumentRequests} returns this
 */
proto.request.DocumentRequests.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.CreateOrUpdateDocumentRequestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.CreateOrUpdateDocumentRequestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.CreateOrUpdateDocumentRequestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: (f = msg.getRequest()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    controlIds: (f = msg.getControlIds()) && common_collections_pb.StringList.toObject(includeInstance, f),
    dueDate: (f = msg.getDueDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: (f = msg.getId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    ownerId: (f = msg.getOwnerId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    unlinkControlId: (f = msg.getUnlinkControlId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    controlModelIds: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.CreateOrUpdateDocumentRequestRequest;
  return proto.request.CreateOrUpdateDocumentRequestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.CreateOrUpdateDocumentRequestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 2:
      var value = new common_collections_pb.StringList;
      reader.readMessage(value,common_collections_pb.StringList.deserializeBinaryFromReader);
      msg.setControlIds(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDueDate(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setOwnerId(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setUnlinkControlId(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setControlModelIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.CreateOrUpdateDocumentRequestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.CreateOrUpdateDocumentRequestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.CreateOrUpdateDocumentRequestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getControlIds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_collections_pb.StringList.serializeBinaryToWriter
    );
  }
  f = message.getDueDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOwnerId();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUnlinkControlId();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getControlModelIds();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional google.protobuf.StringValue request = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.getRequest = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
*/
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.StringList control_ids = 2;
 * @return {?proto.common.StringList}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.getControlIds = function() {
  return /** @type{?proto.common.StringList} */ (
    jspb.Message.getWrapperField(this, common_collections_pb.StringList, 2));
};


/**
 * @param {?proto.common.StringList|undefined} value
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
*/
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.setControlIds = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.clearControlIds = function() {
  return this.setControlIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.hasControlIds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp due_date = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.getDueDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
*/
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.setDueDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.clearDueDate = function() {
  return this.setDueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.hasDueDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue id = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.getId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
*/
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value status = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.getStatus = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
*/
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.StringValue audit_id = 6;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 6));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
*/
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue owner_id = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.getOwnerId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
*/
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.setOwnerId = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.clearOwnerId = function() {
  return this.setOwnerId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.hasOwnerId = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.StringValue unlink_control_id = 8;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.getUnlinkControlId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 8));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
*/
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.setUnlinkControlId = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.clearUnlinkControlId = function() {
  return this.setUnlinkControlId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.hasUnlinkControlId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool control_model_ids = 13;
 * @return {boolean}
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.getControlModelIds = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.CreateOrUpdateDocumentRequestRequest} returns this
 */
proto.request.CreateOrUpdateDocumentRequestRequest.prototype.setControlModelIds = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.GetDocumentRequestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.GetDocumentRequestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.GetDocumentRequestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetDocumentRequestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.GetDocumentRequestRequest}
 */
proto.request.GetDocumentRequestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.GetDocumentRequestRequest;
  return proto.request.GetDocumentRequestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.GetDocumentRequestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.GetDocumentRequestRequest}
 */
proto.request.GetDocumentRequestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.GetDocumentRequestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.GetDocumentRequestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.GetDocumentRequestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetDocumentRequestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.request.GetDocumentRequestRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.GetDocumentRequestRequest} returns this
 */
proto.request.GetDocumentRequestRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.StatusFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.StatusFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.request.StatusFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.StatusFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.StatusFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.StatusFilter}
 */
proto.request.StatusFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.StatusFilter;
  return proto.request.StatusFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.StatusFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.StatusFilter}
 */
proto.request.StatusFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.request.DOCUMENT_REQUEST_STATUS>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addStatuses(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.StatusFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.StatusFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.StatusFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.StatusFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated DOCUMENT_REQUEST_STATUS statuses = 1;
 * @return {!Array<!proto.request.DOCUMENT_REQUEST_STATUS>}
 */
proto.request.StatusFilter.prototype.getStatusesList = function() {
  return /** @type {!Array<!proto.request.DOCUMENT_REQUEST_STATUS>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.request.DOCUMENT_REQUEST_STATUS>} value
 * @return {!proto.request.StatusFilter} returns this
 */
proto.request.StatusFilter.prototype.setStatusesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.request.DOCUMENT_REQUEST_STATUS} value
 * @param {number=} opt_index
 * @return {!proto.request.StatusFilter} returns this
 */
proto.request.StatusFilter.prototype.addStatuses = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.StatusFilter} returns this
 */
proto.request.StatusFilter.prototype.clearStatusesList = function() {
  return this.setStatusesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.OwnerFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.OwnerFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.request.OwnerFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.OwnerFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.OwnerFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    ownerIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    includeUnassigned: (f = msg.getIncludeUnassigned()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.OwnerFilter}
 */
proto.request.OwnerFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.OwnerFilter;
  return proto.request.OwnerFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.OwnerFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.OwnerFilter}
 */
proto.request.OwnerFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addOwnerIds(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeUnassigned(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.OwnerFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.OwnerFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.OwnerFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.OwnerFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOwnerIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getIncludeUnassigned();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string owner_ids = 1;
 * @return {!Array<string>}
 */
proto.request.OwnerFilter.prototype.getOwnerIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.request.OwnerFilter} returns this
 */
proto.request.OwnerFilter.prototype.setOwnerIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.request.OwnerFilter} returns this
 */
proto.request.OwnerFilter.prototype.addOwnerIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.OwnerFilter} returns this
 */
proto.request.OwnerFilter.prototype.clearOwnerIdsList = function() {
  return this.setOwnerIdsList([]);
};


/**
 * optional google.protobuf.BoolValue include_unassigned = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.request.OwnerFilter.prototype.getIncludeUnassigned = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.request.OwnerFilter} returns this
*/
proto.request.OwnerFilter.prototype.setIncludeUnassigned = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.OwnerFilter} returns this
 */
proto.request.OwnerFilter.prototype.clearIncludeUnassigned = function() {
  return this.setIncludeUnassigned(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.OwnerFilter.prototype.hasIncludeUnassigned = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.RequestSort.prototype.toObject = function(opt_includeInstance) {
  return proto.request.RequestSort.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.RequestSort} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.RequestSort.toObject = function(includeInstance, msg) {
  var f, obj = {
    sortOrder: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sortColumn: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.RequestSort}
 */
proto.request.RequestSort.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.RequestSort;
  return proto.request.RequestSort.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.RequestSort} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.RequestSort}
 */
proto.request.RequestSort.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.request.REQUEST_SORT_ORDER} */ (reader.readEnum());
      msg.setSortOrder(value);
      break;
    case 2:
      var value = /** @type {!proto.request.REQUEST_SORT_COLUMN} */ (reader.readEnum());
      msg.setSortColumn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.RequestSort.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.RequestSort.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.RequestSort} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.RequestSort.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSortOrder();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getSortColumn();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional REQUEST_SORT_ORDER sort_order = 1;
 * @return {!proto.request.REQUEST_SORT_ORDER}
 */
proto.request.RequestSort.prototype.getSortOrder = function() {
  return /** @type {!proto.request.REQUEST_SORT_ORDER} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.request.REQUEST_SORT_ORDER} value
 * @return {!proto.request.RequestSort} returns this
 */
proto.request.RequestSort.prototype.setSortOrder = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional REQUEST_SORT_COLUMN sort_column = 2;
 * @return {!proto.request.REQUEST_SORT_COLUMN}
 */
proto.request.RequestSort.prototype.getSortColumn = function() {
  return /** @type {!proto.request.REQUEST_SORT_COLUMN} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.request.REQUEST_SORT_COLUMN} value
 * @return {!proto.request.RequestSort} returns this
 */
proto.request.RequestSort.prototype.setSortColumn = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.ListDocumentRequestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.ListDocumentRequestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.ListDocumentRequestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ListDocumentRequestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    statusFilter: (f = msg.getStatusFilter()) && proto.request.StatusFilter.toObject(includeInstance, f),
    ownerFilter: (f = msg.getOwnerFilter()) && proto.request.OwnerFilter.toObject(includeInstance, f),
    controlId: (f = msg.getControlId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    requestSort: (f = msg.getRequestSort()) && proto.request.RequestSort.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.ListDocumentRequestsRequest}
 */
proto.request.ListDocumentRequestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.ListDocumentRequestsRequest;
  return proto.request.ListDocumentRequestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.ListDocumentRequestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.ListDocumentRequestsRequest}
 */
proto.request.ListDocumentRequestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    case 3:
      var value = new proto.request.StatusFilter;
      reader.readMessage(value,proto.request.StatusFilter.deserializeBinaryFromReader);
      msg.setStatusFilter(value);
      break;
    case 4:
      var value = new proto.request.OwnerFilter;
      reader.readMessage(value,proto.request.OwnerFilter.deserializeBinaryFromReader);
      msg.setOwnerFilter(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setControlId(value);
      break;
    case 6:
      var value = new proto.request.RequestSort;
      reader.readMessage(value,proto.request.RequestSort.deserializeBinaryFromReader);
      msg.setRequestSort(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.ListDocumentRequestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.ListDocumentRequestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.ListDocumentRequestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ListDocumentRequestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getStatusFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.request.StatusFilter.serializeBinaryToWriter
    );
  }
  f = message.getOwnerFilter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.request.OwnerFilter.serializeBinaryToWriter
    );
  }
  f = message.getControlId();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRequestSort();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.request.RequestSort.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue audit_id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.request.ListDocumentRequestsRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.request.ListDocumentRequestsRequest} returns this
*/
proto.request.ListDocumentRequestsRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.ListDocumentRequestsRequest} returns this
 */
proto.request.ListDocumentRequestsRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.ListDocumentRequestsRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StatusFilter status_filter = 3;
 * @return {?proto.request.StatusFilter}
 */
proto.request.ListDocumentRequestsRequest.prototype.getStatusFilter = function() {
  return /** @type{?proto.request.StatusFilter} */ (
    jspb.Message.getWrapperField(this, proto.request.StatusFilter, 3));
};


/**
 * @param {?proto.request.StatusFilter|undefined} value
 * @return {!proto.request.ListDocumentRequestsRequest} returns this
*/
proto.request.ListDocumentRequestsRequest.prototype.setStatusFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.ListDocumentRequestsRequest} returns this
 */
proto.request.ListDocumentRequestsRequest.prototype.clearStatusFilter = function() {
  return this.setStatusFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.ListDocumentRequestsRequest.prototype.hasStatusFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional OwnerFilter owner_filter = 4;
 * @return {?proto.request.OwnerFilter}
 */
proto.request.ListDocumentRequestsRequest.prototype.getOwnerFilter = function() {
  return /** @type{?proto.request.OwnerFilter} */ (
    jspb.Message.getWrapperField(this, proto.request.OwnerFilter, 4));
};


/**
 * @param {?proto.request.OwnerFilter|undefined} value
 * @return {!proto.request.ListDocumentRequestsRequest} returns this
*/
proto.request.ListDocumentRequestsRequest.prototype.setOwnerFilter = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.ListDocumentRequestsRequest} returns this
 */
proto.request.ListDocumentRequestsRequest.prototype.clearOwnerFilter = function() {
  return this.setOwnerFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.ListDocumentRequestsRequest.prototype.hasOwnerFilter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue control_id = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.request.ListDocumentRequestsRequest.prototype.getControlId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.request.ListDocumentRequestsRequest} returns this
*/
proto.request.ListDocumentRequestsRequest.prototype.setControlId = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.ListDocumentRequestsRequest} returns this
 */
proto.request.ListDocumentRequestsRequest.prototype.clearControlId = function() {
  return this.setControlId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.ListDocumentRequestsRequest.prototype.hasControlId = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional RequestSort request_sort = 6;
 * @return {?proto.request.RequestSort}
 */
proto.request.ListDocumentRequestsRequest.prototype.getRequestSort = function() {
  return /** @type{?proto.request.RequestSort} */ (
    jspb.Message.getWrapperField(this, proto.request.RequestSort, 6));
};


/**
 * @param {?proto.request.RequestSort|undefined} value
 * @return {!proto.request.ListDocumentRequestsRequest} returns this
*/
proto.request.ListDocumentRequestsRequest.prototype.setRequestSort = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.ListDocumentRequestsRequest} returns this
 */
proto.request.ListDocumentRequestsRequest.prototype.clearRequestSort = function() {
  return this.setRequestSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.ListDocumentRequestsRequest.prototype.hasRequestSort = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.CountDocumentRequestsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.request.CountDocumentRequestsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.CountDocumentRequestsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.CountDocumentRequestsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.CountDocumentRequestsResponse}
 */
proto.request.CountDocumentRequestsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.CountDocumentRequestsResponse;
  return proto.request.CountDocumentRequestsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.CountDocumentRequestsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.CountDocumentRequestsResponse}
 */
proto.request.CountDocumentRequestsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.CountDocumentRequestsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.CountDocumentRequestsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.CountDocumentRequestsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.CountDocumentRequestsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 count = 1;
 * @return {number}
 */
proto.request.CountDocumentRequestsResponse.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.CountDocumentRequestsResponse} returns this
 */
proto.request.CountDocumentRequestsResponse.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.DeleteDocumentRequestRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.DeleteDocumentRequestRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.DeleteDocumentRequestRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.DeleteDocumentRequestRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.DeleteDocumentRequestRequest}
 */
proto.request.DeleteDocumentRequestRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.DeleteDocumentRequestRequest;
  return proto.request.DeleteDocumentRequestRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.DeleteDocumentRequestRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.DeleteDocumentRequestRequest}
 */
proto.request.DeleteDocumentRequestRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.DeleteDocumentRequestRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.DeleteDocumentRequestRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.DeleteDocumentRequestRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.DeleteDocumentRequestRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.request.DeleteDocumentRequestRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.DeleteDocumentRequestRequest} returns this
 */
proto.request.DeleteDocumentRequestRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string audit_id = 2;
 * @return {string}
 */
proto.request.DeleteDocumentRequestRequest.prototype.getAuditId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.DeleteDocumentRequestRequest} returns this
 */
proto.request.DeleteDocumentRequestRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.request.REQUEST_SORT_ORDER = {
  ASC: 0,
  DESC: 1
};

/**
 * @enum {number}
 */
proto.request.REQUEST_SORT_COLUMN = {
  EVIDENCE: 0,
  CONTROLS: 1
};

/**
 * @enum {number}
 */
proto.request.DOCUMENT_REQUEST_STATUS = {
  OPEN: 0,
  READY: 1,
  ACCEPTED: 2,
  RETURNED: 3
};

goog.object.extend(exports, proto.request);

import React from 'react'
import { ReceptorRecord } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import styled from 'styled-components/macro'
import BootstrapAccordionItem from 'react-bootstrap/AccordionItem'
import { getReceptorTemplate } from '../../../../xgenerated'
import { ReceptorHeader } from '../ReceptorHeader'
import { ReceptorBody } from '../ReceptorBody'

const AccordionItem = styled(BootstrapAccordionItem).attrs({
  forwardedAs: 'section',
})`
  &.accordion-item {
    border: none;
    border-radius: 6px;
    margin-bottom: 8px;
  }
`

export const ReceptorItem = ({
  receptor,
  isSelected,
}: {
  receptor: ReceptorRecord.AsObject
  isSelected: boolean
}): JSX.Element => {
  const receptorTemplate = getReceptorTemplate(receptor.modelid)

  return (
    <AccordionItem eventKey={receptor.id}>
      <ReceptorHeader
        receptor={receptor}
        receptorTemplate={receptorTemplate}
        isSelected={isSelected}
      />
      <ReceptorBody
        receptor={receptor}
        receptorTemplate={receptorTemplate}
        isSelected={isSelected}
      />
    </AccordionItem>
  )
}

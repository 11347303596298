import React, { useMemo } from 'react'
import {
  Instance,
  ListInstancesRequest,
  ListInstancesResponse,
} from '@trustero/trustero-api-web/lib/model/service_instance_pb'
import { ServiceInstancePromiseClient } from '@trustero/trustero-api-web/lib/model/service_instance_grpc_web_pb'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { BaseProps, OnUnpackedResponse } from '../../types'
import { ResponseProps } from '../../utils'
import { AsyncImmutableComponent } from '../../AsyncImmutableComponent'

type Props<ExternalProps> = {
  receptorId: string
  modelIds: string[]
  dismissed?: boolean
} & BaseProps<
  ExternalProps,
  OnUnpackedResponse<
    ExternalProps,
    ListInstancesResponse,
    {
      instances: Instance[]
    }
  >
>

export function ListInstances<ExternalProps>(
  externalProps: Props<ExternalProps>,
): JSX.Element {
  const request = useMemo(() => {
    const request = new ListInstancesRequest()
      .setServiceIdsList(externalProps.modelIds)
      .setReceptorId(externalProps.receptorId)
    if (externalProps.dismissed !== undefined) {
      const dismissed = new BoolValue()
      dismissed.setValue(externalProps.dismissed)
      request.setDismissed(dismissed)
    }
    return request
  }, [
    externalProps.modelIds,
    externalProps.dismissed,
    externalProps.receptorId,
  ])

  const onResponse = useMemo(
    () =>
      (p: { props?: ExternalProps } & ResponseProps<ListInstancesResponse>) => {
        return externalProps.child({
          ...p,
          instances: p.response.getInstancesList(),
        })
      },
    [externalProps],
  )

  return (
    <AsyncImmutableComponent
      {...externalProps}
      asyncCall={ServiceInstancePromiseClient.prototype.list}
      request={request}
      child={onResponse}
    />
  )
}

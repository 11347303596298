import React, { useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import log from 'loglevel'
import { DocumentRequest } from '@trustero/trustero-api-web/lib/request/request_pb'
import { StatusIndicator } from 'src/components/Reusable/StatusIndicator'
import { STATUS_TYPES } from 'src/components/Reusable/StatusIndicator/StatusIndicator.constants'
import { NONE_NAME } from 'src/Utils/globalConstants'
import { Markup } from 'src/components/Reusable/Text/Markup'
import { GridColumn, GridRow } from '../../../components/Reusable/Grid'
import { dateFormatter } from '../../../Utils/formatDate'
import { RequestsAbsoluteRoutes } from '../RequestsRootPage'
import { useAddOrUpdateDocumentRequest } from '../../../components/async/DocumentRequest/useDocumentRequests'
import { TestIds } from '../../../Utils/testIds'
import { useAudit } from '../../../components/async/model'
import { useInAudit } from '../../../context/AuditContext'
import { RequestItemMenu } from './RequestItemComponents/RequestItemMenu/RequestItemMenu'
import { RequestStatusDropdown } from './RequestItemComponents/RequestStatus/RequestStatusDropdown'
import { RequestOwnerButton } from './RequestItemComponents/RequestOwnerButton'
import {
  AuditName,
  DateLabel,
  EditableRequestColumn,
  GridColumnWithFlex,
  RequestColumn,
} from './RequestsItem.styles'
import { RequestItemEditableOnClick } from './RequestItemComponents/RequestItemEditableOnClick'

export interface RequestsItemProps {
  request: DocumentRequest
  fromShowPage?: boolean
  fromControlShowPage?: boolean
}

export const RequestsItem = ({
  request,
  fromShowPage = false,
  fromControlShowPage = false,
}: RequestsItemProps): JSX.Element => {
  const params = useParams()
  const requestId = request.getId()
  const updateDocumentRequest = useAddOrUpdateDocumentRequest()
  const gridRef = useRef<HTMLDivElement>(null)

  const saveRequestBody = async (newValue: string) => {
    if (request.getRequest() === newValue) {
      return
    }
    try {
      await updateDocumentRequest({ id: requestId, request: newValue })
    } catch (e) {
      log.error(`Error updating request for request id: ${requestId}`, e)
    }
  }

  const date = request.getDueDate()
    ? dateFormatter(request.getDueDate()?.toDate(), false)
    : NONE_NAME
  const ownerId = request.getOwnerId()
  const status = request.getStatus()
  const requestString = request.getRequest()
  const requestAudit = useAudit(request.getAuditId()).data
  const requestAuditName = requestAudit ? requestAudit.getName() : ''
  const { auditId: currentAuditId } = useInAudit()
  const belongsToCurrentAudit = requestAudit?.getId() === currentAuditId
  const evidenceCount = request.getEvidenceCount()
  const controlsCount = request.getControlsCount()
  const gridRowType =
    fromControlShowPage && !belongsToCurrentAudit ? GridRow : Link
  const requestPermalink = `/${params.pageContext}/${RequestsAbsoluteRoutes.SHOW}/${requestId}`
  const showDelete = !fromControlShowPage

  return (
    <GridRow as={gridRowType} to={requestPermalink}>
      <GridColumn justifyContent="center" overflow="hidden">
        <StatusIndicator statusType={STATUS_TYPES.REQUEST} status={status} />
      </GridColumn>
      {fromShowPage ? (
        <EditableRequestColumn
          data-testid={TestIds.EDITABLE_TEXT_AREA_COLUMN}
          ref={gridRef}
        >
          <RequestItemEditableOnClick
            initialValue={requestString}
            onSave={saveRequestBody}
          >
            <Markup markdown={requestString}></Markup>
          </RequestItemEditableOnClick>
        </EditableRequestColumn>
      ) : (
        <>
          <RequestColumn data-testid={TestIds.REQUEST_COLUMN}>
            <Markup markdown={requestString} truncate={2} />
            {fromControlShowPage && (
              <AuditName>Audit: {requestAuditName}</AuditName>
            )}
          </RequestColumn>
          <GridColumn justifyContent="center">
            <p>{evidenceCount}</p>
          </GridColumn>
          <GridColumn justifyContent="center">
            <p>{controlsCount}</p>
          </GridColumn>
        </>
      )}
      <GridColumn>
        <RequestStatusDropdown
          request={request}
          isDisabled={!belongsToCurrentAudit}
        />
      </GridColumn>

      <GridColumn>
        <RequestOwnerButton
          id={requestId}
          ownerId={ownerId}
          isDisabled={!belongsToCurrentAudit}
        />
      </GridColumn>

      {fromShowPage ? (
        <GridColumnWithFlex>
          <DateLabel>Due Date</DateLabel>
          <p>{date}</p>
        </GridColumnWithFlex>
      ) : (
        <>
          <GridColumn>
            <p>{date}</p>
          </GridColumn>
          {showDelete && (
            <GridColumn>
              <RequestItemMenu request={request} permalink={requestPermalink} />
            </GridColumn>
          )}
        </>
      )}
    </GridRow>
  )
}

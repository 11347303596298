import React, { ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import { compose, fontWeight, grid, typography } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { ChevronSVG } from '../../../../components/Icons/Basic'

type Children = { children: ReactNode }
export const ReceptorHeaderContainer = styled.header.attrs<Children>(
  ({ children }) => ({
    role: 'button',
    tabIndex: 0,
    fontSize: '3',
    gridColumnGap: 'xl',
    children: (
      <>
        {children}
        <ChevronSVG
          className="receptor-accordion-chevron"
          width="8px"
          height="12px"
          fill="fill.primary.dark"
        />
      </>
    ),
  }),
)<{ $deactivated: boolean }>`
  ${compose(grid, typography)}
  display: grid;
  grid-template-columns: min-content repeat(1, minmax(0, 1fr)) min-content;
  align-items: center;
  flex-grow: 1;
  padding: 16px 20px;
  border-radius: 6px;
  background-color: ${themeGet('colors.bg.neutral')};

  ${(props) => {
    if (props.$deactivated)
      return css`
        border: 1px solid ${themeGet('colors.border.neutral.superlight')};
        background-color: white;
        color: ${themeGet('colors.text.icon.disabled')};
        .receptor-logo {
          opacity: 0.5;
        }
        .receptor-accordion-chevron {
          path {
            fill: currentColor;
          }
        }
      `
  }}

  &:hover,
  &[aria-expanded='true'] {
    background-color: ${themeGet('colors.bg.neutralHover')};
  }

  &[aria-expanded='true'] {
    .receptor-detail {
      opacity: 1;
    }

    .receptor-accordion-chevron {
      transform: rotate(90deg);
    }
  }

  .receptor-accordion-chevron {
    transition: transform 0.25s;
  }
`

export const ReceptorName = styled.p.attrs({
  fontSize: '4',
  fontWeight: 'medium',
})`
  ${typography}
  display: flex;
  align-items: center;
  transition: display 0.25s;
  img {
    margin-right: 8px;
  }
  *:last-child {
    flex: 1 1;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const ReceptorDetail = styled.p.attrs({
  className: 'receptor-detail',
  fontWeight: 'semiBold',
})`
  opacity: 0;
  transition: opacity 0.35s;

  strong {
    ${fontWeight}
  }
`

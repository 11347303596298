import log from 'loglevel'
import {
  DOCUMENT_TYPE,
  GetChangeLogEntriesRequest,
  IDsQuery,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import {
  ModelRecord,
  ModelType,
} from '@trustero/trustero-api-web/lib/model/model_pb'
import {
  ReceptorID,
  ReceptorRecord,
} from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import React, { Dispatch } from 'react'
import { Cache } from 'swr'
import {
  AccountPromiseClient,
  UserPromiseClient,
} from '@trustero/trustero-api-web/lib/account/account_grpc_web_pb'
import { ReceptorPromiseClient } from '@trustero/trustero-api-web/lib/agent/receptor_grpc_web_pb'
import { Message } from 'google-protobuf'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { PolicyPromiseClient } from '@trustero/trustero-api-web/lib/model/policy_grpc_web_pb'
import { ModelStatsServicePromiseClient } from '@trustero/trustero-api-web/lib/model/stats_grpc_web_pb'
import {
  Identifier,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { KeyedPromises } from '../Utils'
import { Content, contentInitialState, Model } from '../context/Content/defs'
import { Overview, Receptor, Service } from '../xgenerated'
import { AuthAction, AuthRecord } from '../context/authContext'
import { mutateModelRecord } from '../components/async'
import { GrpcCall } from '../components/async/utils'
import { isGrpcError } from '../Utils/isGrpcError'
import { mutateControlDependencies } from '../components/async/model'
import AttachmentAdapter, {
  AddDocumentType,
  convertDocToObject,
  DeleteDocumentType,
  DocumentObj,
} from './AttachmentAdapter'
import {
  callApiAndUpdateContent,
  createLocalModel,
  fetchModelDataFromServer,
} from './dataModelAdapterUtils'
import { isOverview, isReceptor, isUser } from './typeUtils'
import ChangelogAdapter, { LogEntry } from './ChangelogAdapter'
import { authorizedGrpcClientFactory } from './grpcClient'

const logger = log.getLogger('adapter')

// Given a list of changelog entries, calculate all the modelIds mentioned
const getChangedModelIds = (changelogEntries: LogEntry[]): string[] =>
  Array.from(new Set(changelogEntries.map((p) => p.subjectmodelid)).values())

function _initLocalModelFromServerModel(
  localModelIn: Model,
  modelRecord?: ModelRecord.AsObject,
): Model {
  if (!modelRecord) {
    // no server-side copy of this object exists
    return localModelIn
  }
  let localModel = localModelIn
  localModel = {
    ...localModel,
    ...modelRecord,
    id: localModel.id,
    ...('timestamp' in localModel
      ? { timestamp: modelRecord.updatedat.valueOf() }
      : {}),
    ...{ owneremail: modelRecord.owneremail || 'Unassigned' },
  }
  if (
    'content' in localModel &&
    !('custom' in localModel) &&
    Boolean(modelRecord.description)
  ) {
    // initial objects already have content which isn't saved in the
    // backend, so we update only if the description is not empty
    localModel.content = modelRecord.description
  }
  if ('custom' in localModel) {
    // this is a content object added by the customer, not
    // one included by Trustero.  That means the extra data
    // is in the description field.
    try {
      const objDescription = JSON.parse(modelRecord.description)
      Object.assign(localModel, objDescription) // nosemgrep
    } catch (err) {
      logger.error('Error loading custom obj:', localModel.id, err)
    }
  }

  return localModel
}

const _initLocalModelFromDocuments = (
  modelType: MODEL_TYPE,
  localModel: Model,
  documents: DocumentObj[],
) => {
  const filterByTypeAndReverse = (modelId: string, docType: DOCUMENT_TYPE) =>
    documents
      .filter(
        (doc) => doc.subjectmodelid === modelId && doc.doctype === docType,
      )
      .reverse()

  // update each of the document fields
  switch (modelType) {
    case MODEL_TYPE.POLICY:
      break
    case MODEL_TYPE.OVERVIEW: {
      if (!isOverview(localModel)) {
        break
      }
      // there can be only one attachment for Overview questions, so take
      // the first one.
      const overviewDocs = filterByTypeAndReverse(
        localModel.id,
        DOCUMENT_TYPE.COMPANY_INFO,
      )
      localModel.document = overviewDocs.shift()
      if (overviewDocs.length > 0) {
        logger.error(
          'Found extra documents for overview',
          localModel.id,
          '=',
          overviewDocs,
        )
      }
      break
    }
    default:
      // no documents for the rest
      break
  }
}

function mergeOverviewDocuments(
  overviews: Overview[],
  documents: DocumentObj[],
) {
  const docMap = documents.reduce((agg, curr) => {
    agg[curr.subjectmodelid] = curr
    return agg
  }, {} as { [key: string]: DocumentObj }) // skipcq: JS-0369

  return overviews.map((o) => ({
    ...o,
    document: docMap[o.modelid],
  }))
}

const getCustomServices = (
  content: Content,
  staticServices: Service[],
  dynamicServices: ModelRecord.AsObject[],
): Service[] => {
  const staticServiceIds = staticServices.map((service) => service.modelid)
  const customServiceModels = dynamicServices.filter(
    (service) => !staticServiceIds.includes(service.modelid),
  )

  return customServiceModels.reduce((acc: Service[], serviceModel) => {
    const model = createLocalModel(
      content,
      MODEL_TYPE.SERVICE,
      serviceModel.modelid,
    )
    model && // skipcq: JS-0354
      acc.push(_initLocalModelFromServerModel(model, serviceModel) as Service) // skipcq: JS-0354
    return acc
  }, [])
}

/**
 * Adapter class which writes changes back to ntrced and then fetches the
 * updated objects to place into our "global UI cache" (ContentContext).
 */
// skipcq: JS-0327
export default class DataModelAdapter {
  // skipcq: JS-0327
  static async reload(
    authCtx: AuthRecord | null,
    authDispatch: React.Dispatch<AuthAction> | null,
    contentDispatch: React.Dispatch<Content>,
    cache: Cache,
  ): Promise<void> {
    contentDispatch({ deltaUpdateRunning: true } as Content)
    if (cache instanceof Map) cache.clear()
    const staticContent = await loadStaticContent()
    const modelIds = {
      services: staticContent.services.map((p) => p.modelid),
      receptors: staticContent.receptors.map((p) => p.id),
      overviews: staticContent.overviews.map((p) => p.id),
    }
    const dynamicContent = await loadDynamicContent(
      modelIds,
      authCtx,
      authDispatch,
    )
    const dynamicModelIdMap = [
      ...dynamicContent.services,
      ...dynamicContent.receptors,
    ].reduce((aggr, curr) => {
      aggr.set(curr.modelid, curr)
      return aggr
    }, new Map<string, ModelRecord.AsObject | ReceptorRecord.AsObject>())

    const updatedStaticServices = staticContent.services.map((p) =>
      _initLocalModelFromServerModel(
        p,
        dynamicModelIdMap.get(p.id) as ModelRecord.AsObject,
      ),
    )
    const customServices = getCustomServices(
      contentInitialState(),
      staticContent.services,
      dynamicContent.services,
    )

    contentDispatch({
      ...staticContent,
      ...dynamicContent,
      services: [...updatedStaticServices, ...customServices],
      overviews: mergeOverviewDocuments(
        staticContent.overviews,
        dynamicContent.documents,
      ),
      receptors: staticContent.receptors.map((p) => ({
        ...(dynamicModelIdMap.get(p.id) as ReceptorRecord.AsObject),
        ...p,
      })),
      deltaUpdateRunning: false,
      loaded: true,
    } as unknown as Content)
  }

  // Contact the changelog service to figure out which objects have
  // updated since the last update we have locally seen.
  static async getDeltaUpdate(
    lastUpdateStamp: number,
    content: Content,
    authCtx: AuthRecord | null,
    authDispatch: React.Dispatch<AuthAction> | null,
    methodMutator: (asyncCall: unknown) => Promise<unknown>,
    methodRequestMutator: (
      asyncCall: GrpcCall<Message, Message>,
      request: Message,
    ) => Promise<Message>,
  ): Promise<Content | undefined> {
    let newChangelogEntries
    try {
      newChangelogEntries = await ChangelogAdapter.getChangeLog(
        lastUpdateStamp,
        authCtx,
        authDispatch,
      )
    } catch (err) {
      if (
        isGrpcError(err) &&
        err.code === 2 &&
        err.message === 'Http response at 400 or 500 level'
      ) {
        return
      }
      throw err
    }
    if (newChangelogEntries.length === 0) {
      return
    }
    logger.debug('Changelog entries = ', newChangelogEntries)
    // group into object types
    const changelogByType = newChangelogEntries.reduce((acc, curr) => {
      if (!acc[curr.subjectmodeltype]) {
        acc[curr.subjectmodeltype] = []
      }
      acc[curr.subjectmodeltype].push(curr)
      return acc
    }, {} as Record<MODEL_TYPE, LogEntry[]>) // skipcq: JS-0369

    if (authCtx?.email) {
      await doCacheInvalidations(
        authCtx.email,
        changelogByType,
        methodMutator,
        methodRequestMutator,
      )
    }
    // fetch new objects
    const promises = {
      loaded: true,
      overviews: DataModelAdapter.updateModels(
        content,
        MODEL_TYPE.OVERVIEW,
        changelogByType[MODEL_TYPE.OVERVIEW] ?? [],
        authCtx,
        authDispatch,
        false,
      ),
      services: this.updateServices(
        content,
        changelogByType[MODEL_TYPE.SERVICE],
        authCtx,
        authDispatch,
      ),
    }

    return {
      ...((await KeyedPromises(promises)) as Content),
      lastUpdateStamp: newChangelogEntries[0].createdat,
    }
  }

  private static updateServices(
    content: Content,
    logEntries: LogEntry[] | undefined,
    authCtx: AuthRecord | null,
    authDispatch: ((value: AuthAction) => void) | null,
  ) {
    if (!logEntries || logEntries.length === 0) {
      return content.services
    }
    return DataModelAdapter.updateModels(
      content,
      MODEL_TYPE.SERVICE,
      logEntries,
      authCtx,
      authDispatch,
      false,
    )
  }

  static deltaUpdate(
    authCtx: AuthRecord,
    authDispatch: React.Dispatch<AuthAction>,
    content: Content,
    contentDispatch: React.Dispatch<Content>,
    cache: Cache,
    methodMutator: (asyncCall: unknown) => Promise<unknown>,
    methodRequestMutator: (
      asyncCall: GrpcCall<Message, Message>,
      request: Message,
    ) => Promise<Message>,
  ): void {
    if (content.deltaUpdateRunning) {
      // don't do anything if we're already running!
      return
    }
    // launch the actual delta update in the background
    const workFunc = async () => {
      contentDispatch({ deltaUpdateRunning: true } as Content)
      if (!content.loaded) {
        // don't be dumb - if the content isn't loaded from scratch, do
        // that now
        await DataModelAdapter.reload(
          authCtx,
          authDispatch,
          contentDispatch,
          cache,
        )
      } else {
        await callApiAndUpdateContent(
          async () => {
            return null
          },
          content,
          contentDispatch,
          authCtx,
          authDispatch,
          methodMutator,
          methodRequestMutator,
        )
      }
      contentDispatch({ deltaUpdateRunning: false } as Content)
    }
    workFunc()
  }

  static async updateModels(
    content: Content,
    modelType: MODEL_TYPE,
    changelogEntries: LogEntry[],
    authCtx: AuthRecord | null,
    authDispatch: React.Dispatch<AuthAction> | null,
    excludeBody: boolean,
  ): Promise<Model[]> {
    // assume that `changelogEntries` is already filtered on `modelType`
    const modelIds = getChangedModelIds(changelogEntries)

    const modelData = await fetchModelDataFromServer(
      modelType,
      modelIds,
      changelogEntries,
      excludeBody,
      authCtx,
      authDispatch,
    )
    // The server doesn't have models for everything, so locally get the
    // master list of every object we're thinking to update in this round
    const localModels = (
      modelIds
        .map((id) => createLocalModel(content, modelType, id))
        .filter((rec) => rec) as Model[]
    ).map((localModel) => {
      // create a copy of the old state of the service
      const modelRecord = modelData.modelRecords.find(
        (record) => !isUser(record) && record.modelid === localModel.id,
      )

      if (!modelRecord || isUser(modelRecord) || isReceptor(modelRecord)) {
        return localModel
      }

      return _initLocalModelFromServerModel(localModel, modelRecord)
    })

    // update the localModels with their documents, if the changelog
    // indicated a change in the DOCUMENT attachments.

    // for each content object, grab its documents
    localModels
      .filter(
        (localModel) =>
          localModel &&
          modelData.docsForSubjectModelIds.includes(localModel.id),
      )
      .forEach(
        (localModel) =>
          localModel &&
          _initLocalModelFromDocuments(
            modelType,
            localModel,
            modelData.documents,
          ),
      )

    return localModels
  }

  // MODEL Actions (shared across several models)

  static modelEditDocument(
    authCtx: AuthRecord,
    authDispatch: Dispatch<AuthAction>,
    content: Content,
    contentUpdate: Dispatch<Content>,
    arg: AddDocumentType & DeleteDocumentType,
    methodMutator: (asyncCall: unknown) => Promise<unknown>,
    methodRequestMutator: (
      asyncCall: GrpcCall<Message, Message>,
      request: Message,
    ) => Promise<Message>,
  ): Promise<DocumentObj | undefined> {
    return callApiAndUpdateContent(
      async () => {
        const addedDocument = await AttachmentAdapter.addDocument(
          arg,
          authCtx,
          authDispatch,
        )
        await AttachmentAdapter.deleteDocument(arg, authCtx, authDispatch)
        return addedDocument
      },
      content,
      contentUpdate,
      authCtx,
      authDispatch,
      methodMutator,
      methodRequestMutator,
    )
  }
}

/**
 * Invalidate SWR caches based on activity events generated by others
 */
export async function doCacheInvalidations(
  selfActor: string,
  changelogByType: Record<MODEL_TYPE, LogEntry[]>,
  methodMutator: (asyncCall: unknown) => Promise<unknown>,
  methodRequestMutator: (
    asyncCall: GrpcCall<Message, Message>,
    request: Message,
  ) => Promise<Message>,
): Promise<void> {
  const promises: Promise<unknown>[] = []
  const getSubjectIds = (modelType: MODEL_TYPE) =>
    changelogByType[modelType]
      ?.filter((p) => p.actor !== selfActor)
      .map((p) => p.subjectmodelid)
      .reduce((curr, p) => curr.add(p), new Set<string>()) ?? new Set()

  if (getSubjectIds(MODEL_TYPE.OVERVIEW).size > 0) {
    promises.push(
      methodMutator(AttachmentPromiseClient.prototype.getDocumentsBySubjectIDs),
    )
    promises.push(
      methodMutator(
        AttachmentPromiseClient.prototype.getDocumentsBySubjectType,
      ),
    )
    promises.push(
      methodMutator(AttachmentPromiseClient.prototype.getDocumentByOID),
    )
    promises.push(
      methodMutator(
        AttachmentPromiseClient.prototype.getDocumentOIDsByAuditPeriodOID,
      ),
    )
    promises.push(
      methodMutator(AttachmentPromiseClient.prototype.getDocumentCounts),
    )
  }
  if (getSubjectIds(MODEL_TYPE.POLICY).size > 0) {
    promises.push(methodMutator(PolicyPromiseClient.prototype.get))
    promises.push(methodMutator(PolicyPromiseClient.prototype.listIds))
    promises.push(methodMutator(PolicyPromiseClient.prototype.getControls))
    promises.push(methodMutator(PolicyPromiseClient.prototype.getFrameworks))
    promises.push(
      methodMutator(ModelStatsServicePromiseClient.prototype.getTrusteroScore),
    )
  }
  if (getSubjectIds(MODEL_TYPE.CONTROL).size > 0) {
    promises.push(methodMutator(ModelPromiseClient.prototype.listControls))
    promises.push(mutateControlDependencies(methodMutator))
  }

  const serviceIds = getSubjectIds(MODEL_TYPE.SERVICE)
  serviceIds.forEach((modelId: string) =>
    mutateModelRecord(
      MODEL_TYPE.SERVICE,
      modelId,
      methodMutator,
      methodRequestMutator,
    ),
  )
  if (serviceIds.size > 0) {
    promises.push(methodMutator(ModelPromiseClient.prototype.listIds))
  }

  if (getSubjectIds(MODEL_TYPE.RECEPTOROBJ).size > 0) {
    promises.push(methodMutator(ReceptorPromiseClient.prototype.getReceptors))
    promises.push(methodMutator(ReceptorPromiseClient.prototype.listIds))
  }
  if (getSubjectIds(MODEL_TYPE.USEROBJ).size > 0) {
    promises.push(methodMutator(UserPromiseClient.prototype.getUsersInAccount))
    promises.push(methodMutator(UserPromiseClient.prototype.get))
  }
  if (getSubjectIds(MODEL_TYPE.ACCOUNT).size > 0) {
    promises.push(methodMutator(AccountPromiseClient.prototype.get))
    promises.push(methodMutator(AccountPromiseClient.prototype.getAccounts))
  }

  await Promise.all(promises)
}

type StaticContent = {
  overviews: Overview[]
  receptors: Receptor[]
  services: Service[]
}

async function loadStaticContent(): Promise<StaticContent> {
  const promises = {
    overviews: import('../xgenerated/overview')
      .then((mod) => mod.default)
      .catch((err) => logger.error('Error importing overview data:', err)),
    receptors: import('../xgenerated/receptor')
      .then((mod) => mod.default)
      .catch((err) => logger.error('Error importing receptor data:', err)),
    services: import('../xgenerated/service')
      .then((mod) => mod.default)
      .catch((err) => logger.error('Error importing services data:', err)),
  }
  const results = (await KeyedPromises(promises)) as StaticContent
  return {
    overviews: results.overviews ?? [],
    receptors: results.receptors ?? [],
    services: results.services ?? [],
  }
}

type DynamicContent = {
  services: ModelRecord.AsObject[]
  receptors: ReceptorRecord.AsObject[]
  documents: DocumentObj[]
  lastUpdateStamp: number
}

async function loadDynamicContent(
  models: { services: string[]; receptors: string[]; overviews: string[] },
  authCtx: AuthRecord | null,
  authDispatch: React.Dispatch<AuthAction> | null,
): Promise<DynamicContent> {
  const modelClient = authorizedGrpcClientFactory(
    ModelPromiseClient,
    authCtx,
    authDispatch,
  )
  const receptorClient = authorizedGrpcClientFactory(
    ReceptorPromiseClient,
    authCtx,
    authDispatch,
  )
  const attachmentClient = authorizedGrpcClientFactory(
    AttachmentPromiseClient,
    authCtx,
    authDispatch,
  )

  // We request the last log in the activity stream to get a base timestamp
  // from which to start our delta syncing. That means this request needs to
  // be made before fetching models from ntrced to avoid race conditions
  const lastLogRequest = new GetChangeLogEntriesRequest()
    .setEntriesPerPage(1)
    .setPageNumber(1)
  const lastLog = await attachmentClient.getChangeLogEntries(lastLogRequest)
  const overviewDocumentsQuery = new IDsQuery().setSubjectidsList(
    models.overviews.map((p) =>
      new Identifier().setModelid(p).setModeltype(MODEL_TYPE.OVERVIEW),
    ),
  )
  const [services, receptors, documents] = await Promise.all([
    modelClient.getByModelType(
      new ModelType().setModeltype(MODEL_TYPE.SERVICE),
    ),
    receptorClient.getReceptors(new ReceptorID()),
    attachmentClient.getDocumentsBySubjectIDs(overviewDocumentsQuery),
  ])

  return {
    services: services.toObject().recordsList,
    receptors: receptors.toObject().receptorsList,
    documents: documents.getDocumentsList().map((p) => convertDocToObject(p)),
    lastUpdateStamp:
      (await lastLog).getLogentriesList()?.[0]?.getCreatedat() ?? 0,
  }
}

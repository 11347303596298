import React from 'react'
import styled from 'styled-components/macro'
import { padding } from 'styled-system'
import palette from 'src/designSystem/variables/palette'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import {
  CheckSVG,
  CircleMinusSVG,
  ErrorSVG,
  StyledIcon,
  XSVG,
} from 'src/components/Icons/Basic'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'

export const RoadmapProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 14px 38px 45px 38px;
  background: ${palette.neutral[30]};
  border-radius: 7px;
  min-width: 300px;
  max-height: 300px;

  align-self: flex-start;
  justify-self: flex-start;
`

export const RoadmapProgressTitle = styled.div`
  font-size: 22px;
  font-weight: 400;
  color: ${palette.neutral[900]};
`

// Index Page Styles
export const RoadmapContent = styled.article.attrs({
  px: 'm',
})`
  ${padding}
  position: relative;
  background-color: white;

  span {
    margin-right: 0.5em;
  }
`

export const RoadmapLink = styled.div`
  margin-top: 1em;
`

export const RoadmapRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 2em 2.5em;
`

export const DateLimitText = styled.span`
  font-weight: 600;
  font-style: normal;
  margin: 0 0 0 0;
`

export const RoadmapRow = styled.div<{ isCurrentStep?: boolean }>`
  &:hover {
    box-shadow: 0 0 4px 4px ${palette.neutral[100]};
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-bottom: 1.5em;
  padding: 1em 0;
  border-radius: 6px;
  background-color: ${palette.neutral[30]};
  cursor: pointer;
  box-shadow: ${({ isCurrentStep }) =>
      isCurrentStep ? '0 0 4px 4px rgba(89, 61, 200, 0.25);' : 'none'}
    p {
    font-size: 1em;
  }
`

export const RoadmapInfoContainer = styled.div`
  margin-top: 1em;
`

export const RoadmapTotalPercentage = styled.div`
  display: flex;
  gap: 1.5em;
  align-items: center;
  margin-bottom: 1.5em;
  h3 {
    margin: 0;
  }
  padding: 1em;
`

export const RoadmapTotalPercentageText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`

export const RoadmapComplete = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 2em;
  padding: 1em 2em;
  border-radius: 6px;
  background-color: ${palette.neutral[30]};

  h3 {
    margin: 0 0 0 1em;
  }
`

export const RowCompletion = styled.div`
  margin-left: 1.5em;
`

export const RoadmapRowText = styled.div`
  flex: 1;
  margin-left: 2em;
  h4 {
    font-size: 20px;
  }
`

export const RoadmapRowLink = styled.div`
  height: 48px;
  width: 48px;
  margin: 0 1em;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-radius: 50%;
  background-color: ${palette.neutral.white};
`

// Helper component styles
export const ReadinessContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 18px;
  margin: 20px 0;
`

//Icons
const ChevronRightIcon = (): JSX.Element => (
  <StyledIcon fontSize={28}>
    <FontAwesomeIcon icon={faChevronRight} />
  </StyledIcon>
)

const RoadmapRowIcon = styled.div`
  ${StyledIcon} {
    position: relative;
    top: 10px;
    left: -9px;
  }
`

export const RoadmapChevron = (): JSX.Element => (
  <RoadmapRowIcon>
    <ChevronRightIcon />
  </RoadmapRowIcon>
)

export const ControlLink = styled.div<{
  hasIcon?: boolean
  isPrepareContent?: boolean
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  margin-left: ${({ isPrepareContent }) =>
    isPrepareContent ? '-8px' : 'initial'};

  p {
    margin-left: ${({ hasIcon }) => (hasIcon ? '0.5em' : '0')};
  }

  a {
    margin-left: 0.5em;
  }
`

export const RoadmapCheckIcon = styled(CheckSVG)`
  width: 16px;
  height: 16px;
`

export const RoadmapNAIcon = styled(CheckSVG)`
  width: 16px;
  height: 16px;
  path {
    fill: ${palette.neutral[300]};
  }
`

export const RoadmapXIcon = styled(XSVG)`
  height: 12px;
  width: 16px;
  path {
    fill: ${palette.magenta[500]};
  }
`

export const RoadmapUnknownIcon = styled(CircleMinusSVG)`
  height: 16px;
  width: 16px;
  path {
    fill: ${palette.neutral[200]};
  }
`

export const RoadmapNotScannedIcon = styled(ErrorSVG)`
  height: 18px;
  width: 18px;
  path {
    fill: ${palette.neutral[500]};
  }
`

export const RoadmapSelectButtonsContainer = styled.span`
  display: inline-flex;
  flex-direction: row;
  gap: 16px;
  margin-left: 16px;
`

export const SelectStaleControlsButton = styled(TextButton)`
  color: ${palette.orange[900]};
`

export const SelectFailingControlsButton = styled(TextButton)`
  svg {
    color: ${palette.neutral[600]};
  }
`

export const SelectOutdatedControls = styled(TextButton)`
  color: ${palette.orange[900]};
`

export const StyledSelectIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`

export const DateLimitWarningContainer = styled.div`
  font-weight: 500;
  color: ${palette.orange[900]};
`

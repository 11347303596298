import React, { Dispatch, SetStateAction, useState } from 'react'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { GridHeader, GridRow } from '../../../../../components/Reusable/Grid'
import { ListInstanceIds } from '../../../../../components/async/model/service_instance/ListInstanceIds'
import { ModelComponent } from '../../../../../components/async/model/ModelComponent'
import { useContent } from '../../../../../context/hooks'
import { Service } from '../../../../../xgenerated'
import { SubServiceItem } from './SubServiceItem'
import { SubServiceListContainer } from './SubServiceList.styles'

export function SubServiceList({
  receptorId,
  serviceIds,
  selectedServiceId,
  setSelectedServiceId,
}: {
  receptorId: string
  serviceIds: string[]
  selectedServiceId: string | undefined
  setSelectedServiceId: Dispatch<SetStateAction<string | undefined>>
}): JSX.Element {
  /**
   * This state keeps track of which SubService is currently highlighted,
   * and is used to slide the highlight indicator up and down
   */
  const [activePosition, setActivePosition] = useState(0)

  /*
   * This chunk of code is meant to sort the services on the Sub Services Tab.
   * The services are passed to this component by model id. The model id is opaque
   * to the user, so we need to query for the name displayed. We sort on that
   * value and reassemble the service ids in alphabetical order of their names.
   */
  const content = useContent()
  const getService = (id: string) => content.idIndex[id] as unknown as Service
  const sortServices = (serviceModelIds: string[]) => {
    const sortedServiceIds = serviceModelIds
      .map((model_id) => getService(model_id))
      .filter((svc) => svc) // filter out nulls
      .sort((a: Service, b: Service) => a.name.localeCompare(b.name))
      .map((svc: Service) => svc.modelid)
    return sortedServiceIds
  }

  const sortedServiceIds = sortServices(serviceIds)

  return (
    <SubServiceListContainer>
      <GridRow>
        <GridHeader>Name</GridHeader>
        <GridHeader>Instances Included</GridHeader>
        <GridHeader />
      </GridRow>
      {sortedServiceIds.map((serviceId, idx) => {
        return (
          <ListInstanceIds
            key={serviceId}
            receptorId={receptorId}
            modelIds={[serviceId]}
            child={({ ids }) => {
              return (
                <ModelComponent
                  modelId={serviceId}
                  modelType={MODEL_TYPE.SERVICE}
                  child={({ model }) => {
                    /** Parent Service is enabled */
                    const serviceEnabled = !model.getDismissed()
                    /** SubService has instances */
                    const serviceHasInstances = ids.length
                    /** SubService is disabled */
                    const disabled = !serviceEnabled || !serviceHasInstances

                    return (
                      <SubServiceItem
                        receptorId={receptorId}
                        serviceId={serviceId}
                        selectedServiceId={selectedServiceId}
                        setSelectedServiceId={setSelectedServiceId}
                        disabled={disabled}
                        position={idx}
                        activePosition={activePosition}
                        setActivePosition={setActivePosition}
                      />
                    )
                  }}
                />
              )
            }}
          />
        )
      })}
    </SubServiceListContainer>
  )
}

import React from 'react'
import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'
import {
  border,
  color,
  compose,
  height,
  padding,
  textStyle,
  width,
  WidthProps,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { DropdownSVG } from '../../Icons/Basic'

export const StyledDropdownToggle = styled.button.attrs<
  WidthProps & { color?: string; borderColor?: string }
>((props) => {
  return {
    type: 'button',
    width: props.width || 'auto',
    height: '40px',
    pl: 's',
    pr: 'xs',
    border: `1px solid ${props.borderColor || palette.neutral['200']}`,
    borderRadius: '2px',
    color: props.color || 'text.default',
    textStyle: 'body.default',
    children: (
      <>
        <StyledDropdownToggleText width={props.width || 'auto'}>
          {props.children}
        </StyledDropdownToggleText>
        {!props.disabled && <StyledDropdownIcon />}
      </>
    ),
  }
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;

  ${compose(width, height, padding, border, color, textStyle)}

  :hover {
    background-color: ${themeGet('colors.bg.neutralHover')};
  }

  &[aria-expanded='true'] {
    color: white;
    background-color: #515d75;

    > svg {
      transform: scaleY(-1);
    }
  }

  svg {
    transform: scaleY(1);
    transition: transform 0.35s;
    fill: ${themeGet('colors.text.icon.low')};
  }

  :after {
    // TODO: Find a better way to hide the caret icon for drop up menus
    display: none !important;
  }
`

const StyledDropdownToggleText = styled.div<WidthProps>`
  width: ${(props) => {
    return props.width === '100%' || props.width === 'auto'
      ? props.width
      : `calc(${props.width} - 44px)`
  }};
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
`

export const StyledDropdownIcon = styled(DropdownSVG)`
  fill: ${themeGet('colors.fill.tertiary.base')};
  flex-shrink: 0;
`

export const StyledDropdownMenu = styled.div<WidthProps>`
  width: ${(props) => (props.width ? width : '100%')};
  max-width: ${(props) => (props.width ? '' : '100% !important')};
`

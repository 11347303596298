import React, { Dispatch, SetStateAction, useRef } from 'react'
import { TextButton } from '../../Buttons/TextButton'
import { StyledTypeButton } from '../../../ModalForms/FileType/TypeSelector.styles'
import { ReactComponent as FileUploadIcon } from '../../../../components/Icons/assets/file-upload-icon.svg'
import { ImportCSVFormData } from '../../../ModalForms/Models/Control/ImportControlsForm'
import {} from 'styled-components/macro'
import { TestIds } from '../../../../Utils/testIds'
import { EXTENSION, MIME_TYPE } from '../../../../Utils/globalEnums'
import { UploadCSVModalDescription, UploadCSVTypeRow } from './UploadCSV.styles'

type DocumentTypeSelectorProps = {
  setFormData?: Dispatch<SetStateAction<ImportCSVFormData>>
  attached: boolean
  setAttached: Dispatch<SetStateAction<boolean>>
  handleChange: (file: File) => Promise<void>
  isBulkUpload?: boolean
}

export function UploadCSV({
  setFormData,
  attached,
  setAttached,
  handleChange,
  isBulkUpload,
}: DocumentTypeSelectorProps): JSX.Element {
  const fileInput = useRef<HTMLInputElement>(null)
  if (attached) {
    return (
      <TextButton
        onClick={(e) => {
          e.preventDefault()
          setAttached(false)
          setFormData &&
            setFormData((state) => {
              const newState: ImportCSVFormData = {
                ...state,
                body: null,
              }
              return newState
            })
        }}
      >
        Clear
      </TextButton>
    )
  }

  return (
    <>
      <UploadCSVTypeRow isBulkUpload={isBulkUpload ?? false}>
        <StyledTypeButton
          as="label"
          onKeyPress={() => {
            fileInput?.current?.click()
          }}
        >
          <FileUploadIcon />
          <UploadCSVModalDescription>Attach File</UploadCSVModalDescription>
          <input
            hidden
            data-testid={TestIds.FILE_INPUT}
            type="file"
            ref={fileInput}
            accept={`${EXTENSION.CSV}, ${MIME_TYPE.TEXT_CSV}`}
            onChange={async (e) => {
              e.preventDefault()
              const file = e.target.files?.[0]
              if (file) {
                await handleChange(file)
              }
            }}
          />
        </StyledTypeButton>
      </UploadCSVTypeRow>
    </>
  )
}

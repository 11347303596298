import React from 'react'
import styled from 'styled-components/macro'
import { color, compose, margin, textStyle } from 'styled-system'
import { ErrorSVG, PoliciesSVG } from '../../../components/Icons/Basic'

type PolicyTitleProps = {
  error?: boolean
}

const Icon = styled(PoliciesSVG).attrs({
  mr: 'xxxs',
})`
  ${margin}
  width: 12px;
  height: 12px;
  filter: grayscale(1);
`

const MissingIcon = styled(ErrorSVG).attrs({
  fill: 'fill.status.error.dark',
  mr: 'xxxs',
})`
  ${margin}
  width: 12px;
  height: 12px;
`

const Style = styled.div.attrs({
  mb: 'xxs',
})`
  ${margin}
  display: flex;
  align-items: center;
`
const PolicyTitle = styled.span.attrs<PolicyTitleProps>((props) => ({
  color: props.error ? 'fill.status.error.dark' : 'fill.status.info.extraDark',
  textStyle: 'subtitle.caption',
}))<PolicyTitleProps>`
  ${compose(color, textStyle)}
  font-size: 12px;
`

type Props = {
  policyName: string
  error?: boolean
}

export function PolicyInfo(props: Props): JSX.Element {
  const err = !props.policyName
  const noName = 'No Name'
  return (
    <Style>
      {props.error ? <MissingIcon /> : <Icon />}
      <PolicyTitle error={props.error}>
        {err ? noName : props.policyName}
      </PolicyTitle>
    </Style>
  )
}

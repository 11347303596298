import React from 'react'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import { ModalFormId } from 'src/components/ModalForms'
import { StandardButtonSize } from 'src/components/Reusable/Buttons'
import { E2E_IDS } from 'src/Utils/testIds'
import { RISK_MATH } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { RiskFields } from './risks.constants'
import { RisksMathMenu } from './menus'
import { getRiskMathCalculation } from './risks.helpers'

export const AddRiskModalBtn = ({
  text,
  handleClick,
}: {
  text: string
  handleClick: () => void
}): JSX.Element => (
  <StandardOpenModalButton
    modalId={ModalFormId.ADD_RISK}
    size={StandardButtonSize.SMALL}
    text={text}
    testId={E2E_IDS.ADD_RISK}
    onClick={handleClick}
  />
)
/**
 * Helper component to display the Inherent Risk which is based off impact & likelihood
 *
 * The formula essentially takes an average of the two values and returns a RISK_MATH calculation
 *
 * @param impact
 * @param likelihood
 * @returns <RiskMathMenu /> component
 */
export const CalculatedRiskMathChip = ({
  riskId,
  impact,
  likelihood,
  field,
}: {
  riskId: string
  impact: RISK_MATH
  likelihood: RISK_MATH
  field: RiskFields
}): JSX.Element => (
  <RisksMathMenu
    riskId={riskId}
    field={field}
    initValue={getRiskMathCalculation(impact, likelihood)}
    isReadOnly
    hasNoBorder
  />
)

import React, { useReducer } from 'react'
import { Dropdown } from 'react-bootstrap'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import {
  ATTESTATION_STATUS,
  VendorRecord,
} from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import { RisksAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { GridColumn, GridRow } from 'src/components/Reusable/Grid'
import { useUrlSearchParams } from 'src/Utils/helpers/navigation'
import {
  GRID_COL_SORT_CONFIGS_VENDORS,
  GridColumnSortActionVendors,
  GridColumnSortType,
  GridColumnSortVendors,
} from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort.constants'
import { CenteredGridColumn } from 'src/components/Reusable/Grid/Grid.styles'
import { VendorsGridColumnSort } from 'src/components/Reusable/Grid/GridColumnSort/VendorsGridColumnSort'
import { gridColSortReducerVendors } from 'src/components/Reusable/Grid/GridColumnSort/GridColumnSort.utils'
import { DropdownToggleButton } from 'src/components/Reusable/Buttons'
import { Changelog, useInfoPanelContext } from 'src/InfoPanel'
import { Link, useLocation, useParams } from 'react-router-dom'
import { generatePermalink } from 'src/components/PageLayout/Permalink'
import { showInfoToast } from 'src/Utils/helpers/toast'
import { useRisk } from 'src/pages/Risks/risks.hooks'
import { Tooltip } from 'src/components/Reusable/Tooltip'
import {
  TooltipOverlayType,
  TooltipPositions,
} from 'src/components/Reusable/Tooltip/Tooltip'
import { PERMISSIONS } from 'src/config/roleConfig'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import {
  ATTESTATION_STATUS_TO_ICON,
  RISK_LEVEL_TO_LABEL,
  VENDORS_HEADERS,
  VENDORS_HEADERS_TO_SORT_COL,
} from '../vendors.constants'
import { ReactComponent as VerticalEllipsesIcon } from '../../../components/Icons/assets/vertical-ellipses-icon.svg'
import { RiskLevelChip, StyledVendorsGridHeader } from '../vendors.styles'
import { useDeleteVendor, useUnlinkVendorRiskModal } from '../vendors.hooks'
import { getVendorFreshTooltipBody } from '../vendors.helpers'

export const VendorsGridItemMenu = ({
  vendorId,
}: {
  vendorId: string
}): JSX.Element => {
  const deleteFunc = useDeleteVendor()
  const { setInfoPanelState } = useInfoPanelContext()

  const copyLink = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    navigator.clipboard.writeText(window.location.href)
    showInfoToast('Copied to clipboard')
  }

  const deleteVendor = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    deleteFunc(vendorId)
  }

  const viewActivities = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setInfoPanelState({
      isShown: true,
      render: <Changelog subjectId={vendorId} />,
    })
  }

  return (
    <Dropdown id={`vendors-grid-${vendorId}`}>
      <Dropdown.Toggle as={DropdownToggleButton}>
        <VerticalEllipsesIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="1" as="button" onClick={copyLink}>
          Copy Link
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="2" as="button" onClick={deleteVendor}>
          Delete Vendor
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item eventKey="3" as="button" onClick={viewActivities}>
          View Activities
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export const RiskVendorsGridItemMenu = ({
  vendor,
}: {
  vendor: VendorRecord
}): JSX.Element => {
  const { riskId } = useParams() as { riskId: string }
  const risk = useRisk(riskId)
  const vendorId = vendor.getId()
  const vendorName = vendor.getName()
  const riskOID = risk ? risk.getId() : ''
  const riskCustomId = risk ? risk.getCustomId() : ''
  const riskName = risk ? risk.getName() : ''
  const openUnlinkModal = useUnlinkVendorRiskModal(
    vendorId,
    vendorName,
    riskOID,
    riskCustomId,
    riskName,
    MODEL_TYPE.RISK,
  )
  if (!risk) {
    return <></>
  }

  const openModal = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    openUnlinkModal()
  }

  return (
    <Dropdown id={`vendors-grid-${vendorId}`}>
      <Dropdown.Toggle as={DropdownToggleButton}>
        <VerticalEllipsesIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item eventKey="1" as="button" onClick={openModal}>
          Unlink Vendor
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export const VendorsGridRow = ({
  vendor,
}: {
  vendor: VendorRecord
}): JSX.Element => {
  const hasMenuPermissions = useHasRequiredPermissions([
    PERMISSIONS.READ,
    PERMISSIONS.EDIT,
  ])
  const { pageContext } = useParams()
  const location = useLocation()
  const isRiskPage = location.pathname.includes(RisksAbsoluteRoutes.SHOW)
  const id = vendor.getId()
  const name = vendor.getName()
  const riskTier = vendor.getRiskLevel()
  const attestation = vendor.getAttestationStatus()
  const risksCount = vendor.getRisksCount()
  const permalink = generatePermalink({
    pageContext: pageContext as string,
    modelType: MODEL_TYPE.VENDOR,
    modelId: id,
    isInternalLink: true,
    includeSearchParams: true,
  })

  const toolTipBody = getVendorFreshTooltipBody(attestation)

  return (
    <GridRow id={`vendors-index-row-${id}`} as={Link} to={permalink}>
      <GridColumn>{name}</GridColumn>
      <CenteredGridColumn>
        <RiskLevelChip $riskLevel={riskTier}>
          {RISK_LEVEL_TO_LABEL[riskTier]}
        </RiskLevelChip>
      </CenteredGridColumn>
      <CenteredGridColumn>
        <Tooltip
          id={`vendor-freshness-tooltip-${vendor.getId()}`}
          placement={TooltipPositions.left}
          overlayType={TooltipOverlayType.popover}
          tooltipBody={toolTipBody}
          disabled={attestation === ATTESTATION_STATUS.FRESH}
        >
          {ATTESTATION_STATUS_TO_ICON[attestation]}
        </Tooltip>
      </CenteredGridColumn>
      <CenteredGridColumn>{risksCount}</CenteredGridColumn>
      <CenteredGridColumn>
        {hasMenuPermissions && (
          <>
            {isRiskPage ? (
              <RiskVendorsGridItemMenu vendor={vendor} />
            ) : (
              <VendorsGridItemMenu vendorId={id} />
            )}
          </>
        )}
      </CenteredGridColumn>
    </GridRow>
  )
}

export const VendorsGridHeader = (): JSX.Element => {
  const { searchParams, updateUrlParams } = useUrlSearchParams()
  const sortBy =
    (searchParams.sort_by && (searchParams.sort_by[0] as GridColumnSortType)) ||
    undefined
  const sortCol: GridColumnSortVendors | undefined =
    (searchParams.sort_col &&
      (searchParams.sort_col[0] as GridColumnSortVendors)) ||
    undefined
  const [columnMapState, dispatch] = useReducer(
    gridColSortReducerVendors,
    GRID_COL_SORT_CONFIGS_VENDORS(sortBy, sortCol),
  )

  const fetchSortedVendors = (action: GridColumnSortActionVendors) => {
    dispatch(action)
    updateUrlParams({ sort_by: [action.type], sort_col: [action.colName] })
  }

  return (
    <GridRow>
      {Object.values(VENDORS_HEADERS).map(
        ({ name, isCentered }, idx: number) => (
          <StyledVendorsGridHeader
            key={`${name}-${idx}`}
            isCentered={isCentered}
          >
            {name}
            {VENDORS_HEADERS_TO_SORT_COL[name] && (
              <VendorsGridColumnSort
                type={columnMapState[VENDORS_HEADERS_TO_SORT_COL[name]].type}
                isActive={
                  columnMapState[VENDORS_HEADERS_TO_SORT_COL[name]].isActive
                }
                colName={VENDORS_HEADERS_TO_SORT_COL[name]}
                onSort={fetchSortedVendors}
              />
            )}
          </StyledVendorsGridHeader>
        ),
      )}
    </GridRow>
  )
}

import React from 'react'
import styled from 'styled-components/macro'
import { margin } from 'styled-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { themeGet } from '@styled-system/theme-get'
import { ErrorSVG, PoliciesSVG } from '../../../../components/Icons/Basic'
import {
  Tooltip,
  TooltipPositions,
  TooltipOverlayType,
} from '../../../../components/Reusable/Tooltip/Tooltip'
import { InfoButton } from '../../../../components/PageLayout/InfoButton'
import palette from '../../../../designSystem/variables/palette'

export const AdditionalInfo = styled.div.attrs({
  mr: 's',
})`
  display: flex;
  align-items: center;

  > :not(:last-child) {
    ${margin}
  }
`

export const MissingItemIcon = styled(ErrorSVG).attrs({
  fill: 'fill.status.error.dark',
  mr: 'xxxs',
})`
  ${margin}
  width: 20px;
  height: 20px;
`

export const PoliciesIcon = styled(PoliciesSVG).attrs({
  mr: 'xxxs',
})`
  ${margin}
  width: 20px;
  height: 20px;
  filter: grayscale(1);
`

export const RelatedPolicy = styled.div`
  a {
    text-decoration: none;
  }
  svg {
    margin-left: 5px;
    fill: ${themeGet('colors.text.icon.default')};
  }
`

export const ControlObjectiveContainer = styled.header.attrs((props) => {
  return {
    my: 'l',
    children: (
      <>
        <h1>Objective</h1>
        {props.children}
      </>
    ),
  }
})`
  ${margin}
  h1 {
    margin: 0;
    font-weight: ${themeGet('fontWeights.semiBold')};
    font-size: 14px;
    line-height: 21px;
  }
`

export const HowToHandleItHeader = styled.h1`
  display: inline-flex;
  align-items: center;
  margin: 0;
  font-weight: ${themeGet('fontWeights.semiBold')};
  font-size: 14px;
  line-height: 21px;
`

export const ControlDescriptionContainer = styled.main.attrs((props) => {
  return {
    mt: 'l',
    children: (
      <>
        <h1>
          Procedure
          <Tooltip
            id="showpage-item-description-tooltip"
            placement={TooltipPositions.right}
            overlayType={TooltipOverlayType.popover}
            tooltipBody="Please write a few sentences describing how you achieve the control objective. This should cover the basic who, what, when, where, how of the control."
          >
            <InfoButton as="span" ml="xxxs" />
          </Tooltip>
        </h1>
        {props.children}
      </>
    ),
  }
})`
  ${margin}
  h1 {
    display: inline-flex;
    align-items: center;
    margin: 0;
    font-weight: ${themeGet('fontWeights.semiBold')};
    font-size: 14px;
    line-height: 21px;
  }
`
export const ControlNoteContainer = styled.main.attrs((props) => {
  return {
    mt: 'l',
    children: (
      <>
        <h1>Notes</h1>
        {props.children}
      </>
    ),
  }
})`
  ${margin}
  h1 {
    display: inline-flex;
    align-items: center;
    margin: 0;
    font-weight: ${themeGet('fontWeights.semiBold')};
    font-size: 14px;
    line-height: 21px;
  }
`
export const ControlMarketplaceContainer = styled.main.attrs(({ children }) => {
  return {
    mt: 'l',
    children: (
      <>
        <h1>
          Tools that Can Help
          <Tooltip
            id="Marketplace-Recommendations-Tooltip"
            placement={TooltipPositions.default}
            overlayType={TooltipOverlayType.popover}
            tooltipBody="Recommended tools that can help you implement this control."
          >
            <InfoButton as="span" ml="xxxs" />
          </Tooltip>
        </h1>
        {children}
      </>
    ),
  }
})`
  ${margin}
  h1 {
    display: inline-flex;
    align-items: center;
    margin: 0;
    font-weight: ${themeGet('fontWeights.semiBold')};
    font-size: 14px;
    line-height: 21px;
  }
`

export const DateFilterWrapper = styled.div`
  grid-column: 9 / -1;
  position: relative;
  width: 100%;
`

export const DateFilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  width: 100%;
  height: 39px;
  font-size: 16px;
  font-weight: 400;
  color: ${palette.neutral[900]};

  > *:last-child {
    margin: 0 12px;
  }
`

export const NotApplicableSection = styled.article.attrs({
  mb: 'm',
})`
  ${margin}
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const NotApplicableMessageContainer = styled.div`
  background-color: ${palette.neutral[600]};
  width: fit-content;
  display: flex;
  gap: 10px;
  padding: 8px;
  border-radius: 6px;
  font-size: 18px;
  color: ${palette.neutral.white};
`

export const NotApplicableObjective = styled.div`
  font-size: 18px;
  color: ${palette.neutral[400]};
`

export const NotApplicableNotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 24px;
  border-radius: 14px;
  border: 1px solid ${palette.neutral[100]};
`

export const ControlResponsibilityReasonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`

export const NotApplicableNotesHeader = styled.div`
  display: flex;
`
export const ControlResponsibilitySectionTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 12px;
  color: ${palette.neutral[900]};
`

export const NotApplicableNotesIcon = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: ${palette.neutral[600]};
  font-weight: 900;
`

export const NotApplicableNotesDescription = styled.div`
  margin: 0 auto;
  color: ${palette.neutral[600]};
  font-weight: 300;
`

import React from 'react'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { PERMISSIONS } from 'src/config/roleConfig'
import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'
import { colorStyle, compose, typography } from 'styled-system'

export const TextButton = ({
  color,
  disabled,
  onClick,
  requiredPermissions = [PERMISSIONS.READ, PERMISSIONS.EDIT],
  children,
  style,
}: {
  color?: string
  disabled?: boolean
  onClick: (e: React.MouseEvent<HTMLElement>) => void
  requiredPermissions?: PERMISSIONS[]
  children?: JSX.Element | string | (JSX.Element | string)[]
  style?: React.CSSProperties
}): JSX.Element => {
  const hasPermission = useHasRequiredPermissions(requiredPermissions)

  let isButtonDisabled = disabled
  if (!hasPermission) {
    isButtonDisabled = true
  }

  return (
    <StyledTextButton
      color={color}
      disabled={isButtonDisabled}
      onClick={onClick}
      style={style}
    >
      {children}
    </StyledTextButton>
  )
}

export const StyledTextButton = styled.button.attrs<{
  color: string
  disabled?: boolean
}>({
  colors: 'link.primary',
  fontSize: 3,
})`
  ${compose(colorStyle, typography)}
  border: none;
  padding: 0;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
  ${({ color }) => color && `color: ${color};`};
  ${({ disabled, color }) =>
    disabled &&
    `:hover {color: ${color || palette.neutral['900']};} cursor: not-allowed`};
`

import React from 'react'
import { MIME_TYPE } from 'src/Utils/globalEnums'
import { useGooglePicker } from 'src/lib/Linkers/Linkers.hooks'
import { useLinkPolicyDocsConfig } from 'src/pages/Policies/Policies.hooks'
import { useGoogleDriveContext } from 'src/lib/Linkers/context/GoogleDriveContext'
import { ReactComponent as FileUploadIcon } from '../../../components/Icons/assets/file-upload-icon.svg'
import { ReactComponent as LinkIcon } from '../../../components/Icons/assets/permalink-icon.svg'
import { TypeButton } from '../FileType/TypeSelector.styles'
import { DocumentFormData, DocumentType } from './AddDocumentForm'
import { POLICY_DOC_ACCEPTED_FILE_TYPES } from './AddDocumentForm.constants'
import { GoogleDriveIcon, StyledTypeButton } from './AddDocumentForm.styles'

export const PolicyDocLinkTypeButton = ({
  setFormData,
  setType,
}: {
  setFormData: React.Dispatch<React.SetStateAction<DocumentFormData>>
  setType: React.Dispatch<React.SetStateAction<DocumentType>>
}): JSX.Element => {
  const onClick = (e: React.MouseEvent) => {
    e.preventDefault()
    setFormData((state) => ({ ...state, mime: MIME_TYPE.TEXT_URI_LIST }))
    setType(DocumentType.LINK)
  }

  return (
    <TypeButton onClick={onClick}>
      <LinkIcon width="48px" height="48px" />
      <span>Paste Link</span>
    </TypeButton>
  )
}

export const PolicyDocFileTypeButton = ({
  setFormData,
  setType,
}: {
  setFormData: React.Dispatch<React.SetStateAction<DocumentFormData>>
  setType: React.Dispatch<React.SetStateAction<DocumentType>>
}): JSX.Element => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const file = e.target.files?.[0]

    if (file) {
      let fileType = file.type
      if (!fileType) {
        const split = file.name.split('.')
        const extension = split[split.length - 1]
        if (extension === 'key') {
          fileType = MIME_TYPE.APPLE_KEYNOTE
        } else if (extension === 'numbers') {
          fileType = MIME_TYPE.APPLE_NUMBERS
        } else if (extension === 'pages') {
          fileType = MIME_TYPE.APPLE_PAGES
        }
      }

      setFormData((state) => {
        const newState: DocumentFormData = {
          ...state,
          mime: fileType,
          body: file,
        }
        return newState
      })
      setType(DocumentType.FILE)
    }
  }

  return (
    <StyledTypeButton
      as="label"
      htmlFor="evidence-file-upload"
      onKeyPress={() => {
        const uploadInput = document.getElementById('evidence-file-upload')
        if (uploadInput) uploadInput.click()
      }}
    >
      <FileUploadIcon />
      <span>Attach File</span>
      <input
        hidden
        type="file"
        id="evidence-file-upload"
        accept={POLICY_DOC_ACCEPTED_FILE_TYPES}
        onChange={onChange}
      />
    </StyledTypeButton>
  )
}

export const PolicyDocGoogleDriveButton = ({
  policyId,
  hide,
  setGdriveError,
}: {
  policyId: string
  hide: () => void
  setGdriveError: React.Dispatch<React.SetStateAction<string>>
}): JSX.Element => {
  const openPicker = useGooglePicker()
  const linkPolicyDocsConfig = useLinkPolicyDocsConfig(policyId)
  const { setPickerConfig } = useGoogleDriveContext()

  return (
    <TypeButton
      onClick={async (e: React.MouseEvent) => {
        e.preventDefault()
        if (!linkPolicyDocsConfig) return
        setPickerConfig(linkPolicyDocsConfig)
        const { isShown, invalidCredentials, exceptions } = await openPicker(
          linkPolicyDocsConfig,
        )
        if (isShown) {
          hide()
        } else if (exceptions) {
          setGdriveError(exceptions)
        } else if (invalidCredentials) {
          setGdriveError('Invalid Credentials.')
        }
      }}
    >
      <GoogleDriveIcon />
      <span>Google Drive</span>
    </TypeButton>
  )
}

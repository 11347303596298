import { MouseEventHandler } from 'react'
import { useCurrentEvidenceId } from 'src/context/FormContext/CurrentEvidenceContext'
import { useSetActiveModal } from 'src/Modal/ModalStateContext'
import { ModalFormId } from '../../ModalForm'

/**
 * Hook used to toggle the `<ViewEvidenceForm>` component
 *
 * The `<ViewEvidenceForm>` component must be rendered on the same page,
 * in order for this hook to toggle it
 */
export const useViewEvidence = <T,>(
  evidenceId: string,
): MouseEventHandler<T> => {
  const openViewEvidenceModal = useSetActiveModal(ModalFormId.VIEW_EVIDENCE)
  const { setEvidenceId } = useCurrentEvidenceId()
  return (e) => {
    e.preventDefault()
    openViewEvidenceModal()
    setEvidenceId(evidenceId)
  }
}

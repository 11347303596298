import React from 'react'
import { themeGet } from '@styled-system/theme-get'
import styled from 'styled-components/macro'
import { GridHeader, GridRow } from '../../../../components/Reusable/Grid'

const LeftAlignedHeader = styled.p`
  margin-left: ${themeGet('space.m')}px;
`

export const EvidenceGridHeader = (): JSX.Element => (
  <GridRow>
    <GridHeader>
      <LeftAlignedHeader>Type</LeftAlignedHeader>
    </GridHeader>
    <GridHeader>Caption</GridHeader>
    <GridHeader>By</GridHeader>
    <GridHeader>Relevant Date</GridHeader>
    <GridHeader>Created</GridHeader>
    <GridHeader />
    <GridHeader />
  </GridRow>
)

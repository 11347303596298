import {
  CsvTemplateType,
  HeaderValues,
  ParseColumnType,
} from 'src/Utils/csv/csv.constants'
import { ModalFormId } from '../ModalForm'

export const UPLOAD_TEXT = {
  errorTitle: 'We had a few errors processing your upload',
  moreErrors: 'more errors...',
  successTitle: 'Got it',
}

export const MAX_ERRORS = 10

export const CSV_UPLOAD_TYPES = Object.freeze({
  REQUEST: 'requests',
  CONTROL: 'controls',
  RISK: 'risks',
  QUESTIONS: 'questions',
  DEFAULT: 'items',
  VENDOR: 'vendors',
})

export interface UploadCSVModalProps<T> {
  modalId: ModalFormId
  uploadItemsFunc: (
    items: T[],
    file?: File | null,
    setErrors?: React.Dispatch<React.SetStateAction<string[]>>,
  ) => Promise<void> | void
  titleText: string
  csvHeaders: HeaderValues[]
  csvColumnParsers: ParseColumnType
  formatSampleText?: string
  submitText: string
  description?: string | JSX.Element
  instruction?: string | JSX.Element
  prevModalId?: ModalFormId
  csvTemplate?: CsvTemplateType[]
  additionalDetails?: JSX.Element
  uploadType?: string
  processUploadedFile?: boolean
  customCSVDownload?: {
    text: string
    downloadFunc: () => void
  }
  gap?: number
  validateForm?: () => boolean
  onAttach?: (items: T[]) => void
  customAboveSubmit?: JSX.Element
  onHide?: () => void
  isAIPowered?: boolean
  showOverflow?: boolean
  customForm?: JSX.Element
  showCustomForm?: boolean
  customSubmitButton?: JSX.Element
  enforceFocus?: boolean
}

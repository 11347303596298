import { USE_CASE } from '@trustero/trustero-api-web/lib/account/account_pb'
import { ComplianceFramework } from '@trustero/trustero-api-web/lib/audit/framework_pb'
import { Framework } from '@trustero/trustero-api-web/lib/model/framework_pb'

export interface ComplianceFrameworkGroup {
  base: ComplianceFramework
  objectives: Framework[]
}

export const FieldConstants = Object.freeze({
  NONE: 'None',
})

export type AdminOnlyTooltipBody = {
  isAdminMessage: string
  isNotAdminMessage: string
}

export const NONE_ID = 'none'
export const NONE_NAME = 'None'

// Keeping naming consistent for now, may need to update these to "Free" and "Paid" or "Basic" and "Advanced" later
export const AccountTypes = Object.freeze({
  [USE_CASE.SAAS_PROVIDER]: 'provider',
  [USE_CASE.SAAS_BUYER]: 'buyer',
})

export const INPUT_MAX_LENGTH = 255

export const COMPANY_NAME_ADMIN_MESSAGE =
  'You must be an admin user to edit the company name.'

export const AI_LOADING_MESSAGES = [
  'Doing my research',
  'Digging into the details',
  'Checking the playbook',
  'Exploring options',
  'Finding the best path',
  'Reviewing best practices',
]

export const TYPEWRITER_INTERVAL = 10

export const SCHEDULER_EMAIL = 'scheduler@trustero.com'

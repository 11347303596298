import React from 'react'
import { ReactComponent as AwsSVG } from '../Dependencies/amazon/amazon-web-services-icon.svg'
import { ReactComponent as GithubSVG } from '../Dependencies/github/Octicons-mark-github.svg'
import { ReactComponent as GoogleSVG } from '../Dependencies/google/google.svg'
import { ReactComponent as TrinetSVG } from '../Dependencies/general/trinet-icon.svg'
import { ReactComponent as RecruiteeSVG } from '../Dependencies/general/recruitee.svg'
import { ReactComponent as JamfSVG } from '../Dependencies/general/jamf-icon.svg'
import { ReactComponent as GoogleCloudSVG } from '../Dependencies/google/google-cloud-platform.svg'
import { small } from './styles'

export const NoReceptorIcons = (): JSX.Element => {
  return (
    <div
      data-testid={'NO_RECEPTOR_ICONS_CONTAINER'}
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '4em',
        marginBottom: '10px',
      }}
    >
      <AwsSVG style={small} />
      <GithubSVG style={small} />
      <GoogleSVG style={small} />
      <TrinetSVG style={small} />
      <RecruiteeSVG style={small} />
      <JamfSVG style={small} />
      <GoogleCloudSVG style={small} />
    </div>
  )
}

import React from 'react'
import styled from 'styled-components/macro'
import {
  useHideModal,
  useIsShowModal,
  useSetActiveModal,
} from 'src/Modal/ModalStateContext'
import { useCurrentReceptor } from 'src/context/FormContext/CurrentReceptorContext'
import { Receptor } from 'src/xgenerated'
import { CUSTOM_RECEPTOR_ID } from 'src/Utils/Receptors/receptors.constants'
import { ModalForm, ModalFormId, ModalFormIdQueryParam } from '../../ModalForm'
import { Grid } from '../../../Reusable/Grid'
import theme from '../../../../designSystem/theme'
import { getAllReceptorTemplatesFiltered } from '../../../../Utils/Receptors/getAllReceptorTemplatesFiltered'
import { ReceptorCard } from './ReceptorCard'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`

const AddCustomReceptor = ({
  template,
}: {
  template: Receptor
}): JSX.Element => {
  const { setReceptor } = useCurrentReceptor()
  const openActivateReceptorModal = useSetActiveModal(
    ModalFormId.ACTIVATE_RECEPTOR,
  )

  const openCustomReceptorModal = () => {
    openActivateReceptorModal()
    setReceptor(template)
  }

  return (
    <Container>
      <span>Want to connect somewhere else?&nbsp;</span>
      <a
        href="#"
        onClick={(e: React.MouseEvent) => {
          e.preventDefault()
          openCustomReceptorModal()
        }}
      >
        Add a custom receptor.
      </a>
    </Container>
  )
}

export const SelectReceptorFormQueryParams = {
  MODAL_FORM_ID: ModalFormIdQueryParam,
}

export const SelectReceptorForm = (): JSX.Element => {
  const show = useIsShowModal(ModalFormId.SELECT_RECEPTOR)
  const hide = useHideModal({ modalId: ModalFormId.SELECT_RECEPTOR })

  const receptorTemplates = getAllReceptorTemplatesFiltered().sort((a, b) =>
    a.name.localeCompare(b.name),
  )

  const filteredReceptorTemplates = receptorTemplates.filter(
    (receptorTemplate) => receptorTemplate.modelid !== CUSTOM_RECEPTOR_ID,
  )

  const customReceptorTemplate = getAllReceptorTemplatesFiltered().find(
    (template: Receptor) => {
      return template.modelid === CUSTOM_RECEPTOR_ID
    },
  )

  return (
    <ModalForm
      show={show}
      hide={hide}
      formId={ModalFormId.SELECT_RECEPTOR}
      title="Select Receptor"
      customFooter={
        customReceptorTemplate && (
          <AddCustomReceptor template={customReceptorTemplate} />
        )
      }
      hideButtons
      description={
        'A receptor automates evidence collection for a specific tool.'
      }
    >
      <Grid
        theme={{ ...theme, breakpoints: ['576px', '992px', '1200px'] }}
        gridGap="40px"
        gridTemplateColumns={['1fr', '1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)']}
      >
        {filteredReceptorTemplates.map((receptorTemplate) => (
          <ReceptorCard
            key={receptorTemplate.modelid}
            template={receptorTemplate}
          />
        ))}
      </Grid>
    </ModalForm>
  )
}

import { ContentStorePromiseClient } from '@trustero/trustero-api-web/lib/contentstore/contentstore_grpc_web_pb'
import {
  GetSignedUrlWithKeyRequest,
  GetSignedUrlWithKeyResponse,
} from '@trustero/trustero-api-web/lib/contentstore/contentstore_pb'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'

export const useSignedContentUrl = (
  key: string,
): GrpcResponse<GetSignedUrlWithKeyResponse> => {
  const request = new GetSignedUrlWithKeyRequest().setKey(key)
  const { response } = useSwrImmutableGrpc(
    ContentStorePromiseClient.prototype.getSignedUrlWithKey,
    request,
  )
  return NewGrpcResponse(response)
}

import React, { ButtonHTMLAttributes, ReactNode } from 'react'
import styled from 'styled-components/macro'
import {
  FlexboxProps,
  GridProps,
  PositionProps,
  SpaceProps,
} from 'styled-system'
import {
  TooltipOverlayType,
  TooltipPositions,
} from 'src/components/Reusable/Tooltip/Tooltip'
import { Tooltip } from '../../components/Reusable/Tooltip'
import {
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from '../../components/Reusable/Buttons'
import { ReactComponent as PlusSVG } from '../../components/Icons/assets/plus-icon.svg'
import { ActivateSVG, EditSVG, ErrorSVG } from '../../components/Icons/Basic'

export enum ScopingAddOrEditButtonType {
  ADD,
  EDIT,
}

type ScopingAddOrEditButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  FlexboxProps &
  GridProps &
  PositionProps &
  SpaceProps & {
    id: string
    deactivated?: boolean
    error?: boolean
    tooltipBody: ReactNode
    buttonType: ScopingAddOrEditButtonType
  }

export const StyledAddOrEditButton = styled(StandardButton).attrs({
  variant: StandardButtonVariant.PRIMARY,
  buttonSize: StandardButtonSize.SMALL,
})<{
  buttonType: ScopingAddOrEditButtonType
}>`
  width: 34px;
  height: 34px;
  padding: 0;
  border-radius: 50%;
  background-color: ${({ buttonType }) => {
    return buttonType === ScopingAddOrEditButtonType.EDIT ? 'white' : ''
  }};
  &:hover {
    .activate-icon {
      path {
        fill: white;
      }
    }
    .edit-icon {
      path {
        fill: white;
      }
    }
  }
`

export function ScopingAddOrEditButton({
  id,
  deactivated,
  error,
  buttonType,
  tooltipBody,
  disabled,

  ...buttonProps
}: ScopingAddOrEditButtonProps): JSX.Element {
  let Icon
  if (buttonType === ScopingAddOrEditButtonType.ADD) {
    Icon = <PlusSVG width="14px" height="24px" fill="white" />
  } else {
    if (deactivated) {
      Icon = (
        <ActivateSVG
          className="activate-icon"
          width="24px"
          height="24px"
          fill="text.icon.default"
        />
      )
    } else if (error) {
      Icon = (
        <ErrorSVG width="34px" height="34px" fill="fill.status.error.dark" />
      )
    } else {
      Icon = (
        <EditSVG
          className="edit-icon"
          width="24px"
          height="24px"
          fill="text.icon.default"
        />
      )
    }
  }

  return (
    <Tooltip
      id={id}
      placement={disabled ? TooltipPositions.right : TooltipPositions.top}
      tooltipBody={tooltipBody}
      overlayType={
        disabled ? TooltipOverlayType.popover : TooltipOverlayType.tooltip
      }
    >
      <StyledAddOrEditButton
        buttonType={buttonType}
        {...buttonProps}
        disabled={disabled}
      >
        {Icon}
      </StyledAddOrEditButton>
    </Tooltip>
  )
}

/**
 * @fileoverview gRPC-Web generated client stub for model
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: model/model.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var common_collections_pb = require('../common/collections_pb.js')

var common_model_pb = require('../common/model_pb.js')

var model_control_pb = require('../model/control_pb.js')

var model_policy_pb = require('../model/policy_pb.js')

var model_department_pb = require('../model/department_pb.js')

var model_framework_pb = require('../model/framework_pb.js')
const proto = {};
proto.model = require('./model_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.model.ModelClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.model.ModelPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ModelRecord,
 *   !proto.model.ModelRecord>}
 */
const methodDescriptor_Model_GetOrCreate = new grpc.web.MethodDescriptor(
  '/model.Model/GetOrCreate',
  grpc.web.MethodType.UNARY,
  proto.model.ModelRecord,
  proto.model.ModelRecord,
  /**
   * @param {!proto.model.ModelRecord} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ModelRecord.deserializeBinary
);


/**
 * @param {!proto.model.ModelRecord} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ModelRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ModelRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getOrCreate =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetOrCreate',
      request,
      metadata || {},
      methodDescriptor_Model_GetOrCreate,
      callback);
};


/**
 * @param {!proto.model.ModelRecord} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ModelRecord>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getOrCreate =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetOrCreate',
      request,
      metadata || {},
      methodDescriptor_Model_GetOrCreate);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ModelIdAndType,
 *   !proto.model.ModelRecord>}
 */
const methodDescriptor_Model_GetByModelIdAndType = new grpc.web.MethodDescriptor(
  '/model.Model/GetByModelIdAndType',
  grpc.web.MethodType.UNARY,
  proto.model.ModelIdAndType,
  proto.model.ModelRecord,
  /**
   * @param {!proto.model.ModelIdAndType} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ModelRecord.deserializeBinary
);


/**
 * @param {!proto.model.ModelIdAndType} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ModelRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ModelRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getByModelIdAndType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetByModelIdAndType',
      request,
      metadata || {},
      methodDescriptor_Model_GetByModelIdAndType,
      callback);
};


/**
 * @param {!proto.model.ModelIdAndType} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ModelRecord>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getByModelIdAndType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetByModelIdAndType',
      request,
      metadata || {},
      methodDescriptor_Model_GetByModelIdAndType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ModelObjectiveAndType,
 *   !proto.model.ModelRecord>}
 */
const methodDescriptor_Model_GetByObjectiveAndType = new grpc.web.MethodDescriptor(
  '/model.Model/GetByObjectiveAndType',
  grpc.web.MethodType.UNARY,
  proto.model.ModelObjectiveAndType,
  proto.model.ModelRecord,
  /**
   * @param {!proto.model.ModelObjectiveAndType} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ModelRecord.deserializeBinary
);


/**
 * @param {!proto.model.ModelObjectiveAndType} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ModelRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ModelRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getByObjectiveAndType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetByObjectiveAndType',
      request,
      metadata || {},
      methodDescriptor_Model_GetByObjectiveAndType,
      callback);
};


/**
 * @param {!proto.model.ModelObjectiveAndType} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ModelRecord>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getByObjectiveAndType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetByObjectiveAndType',
      request,
      metadata || {},
      methodDescriptor_Model_GetByObjectiveAndType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ListModelsRequest,
 *   !proto.common.StringList>}
 */
const methodDescriptor_Model_ListIds = new grpc.web.MethodDescriptor(
  '/model.Model/ListIds',
  grpc.web.MethodType.UNARY,
  proto.model.ListModelsRequest,
  common_collections_pb.StringList,
  /**
   * @param {!proto.model.ListModelsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_collections_pb.StringList.deserializeBinary
);


/**
 * @param {!proto.model.ListModelsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.common.StringList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.common.StringList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.listIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/ListIds',
      request,
      metadata || {},
      methodDescriptor_Model_ListIds,
      callback);
};


/**
 * @param {!proto.model.ListModelsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.common.StringList>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.listIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/ListIds',
      request,
      metadata || {},
      methodDescriptor_Model_ListIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.CreateUpdateControlRequest,
 *   !proto.model.Control>}
 */
const methodDescriptor_Model_CreateOrUpdateControl = new grpc.web.MethodDescriptor(
  '/model.Model/CreateOrUpdateControl',
  grpc.web.MethodType.UNARY,
  model_control_pb.CreateUpdateControlRequest,
  model_control_pb.Control,
  /**
   * @param {!proto.model.CreateUpdateControlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_control_pb.Control.deserializeBinary
);


/**
 * @param {!proto.model.CreateUpdateControlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Control)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Control>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.createOrUpdateControl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/CreateOrUpdateControl',
      request,
      metadata || {},
      methodDescriptor_Model_CreateOrUpdateControl,
      callback);
};


/**
 * @param {!proto.model.CreateUpdateControlRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Control>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.createOrUpdateControl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/CreateOrUpdateControl',
      request,
      metadata || {},
      methodDescriptor_Model_CreateOrUpdateControl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.CreateOrUpdateDepartmentRequest,
 *   !proto.model.Department>}
 */
const methodDescriptor_Model_CreateOrUpdateDepartment = new grpc.web.MethodDescriptor(
  '/model.Model/CreateOrUpdateDepartment',
  grpc.web.MethodType.UNARY,
  model_department_pb.CreateOrUpdateDepartmentRequest,
  model_department_pb.Department,
  /**
   * @param {!proto.model.CreateOrUpdateDepartmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_department_pb.Department.deserializeBinary
);


/**
 * @param {!proto.model.CreateOrUpdateDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Department)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Department>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.createOrUpdateDepartment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/CreateOrUpdateDepartment',
      request,
      metadata || {},
      methodDescriptor_Model_CreateOrUpdateDepartment,
      callback);
};


/**
 * @param {!proto.model.CreateOrUpdateDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Department>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.createOrUpdateDepartment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/CreateOrUpdateDepartment',
      request,
      metadata || {},
      methodDescriptor_Model_CreateOrUpdateDepartment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.CreateOrUpdateFrameworkRequest,
 *   !proto.model.Framework>}
 */
const methodDescriptor_Model_CreateOrUpdateFramework = new grpc.web.MethodDescriptor(
  '/model.Model/CreateOrUpdateFramework',
  grpc.web.MethodType.UNARY,
  model_framework_pb.CreateOrUpdateFrameworkRequest,
  model_framework_pb.Framework,
  /**
   * @param {!proto.model.CreateOrUpdateFrameworkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_framework_pb.Framework.deserializeBinary
);


/**
 * @param {!proto.model.CreateOrUpdateFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Framework)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Framework>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.createOrUpdateFramework =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/CreateOrUpdateFramework',
      request,
      metadata || {},
      methodDescriptor_Model_CreateOrUpdateFramework,
      callback);
};


/**
 * @param {!proto.model.CreateOrUpdateFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Framework>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.createOrUpdateFramework =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/CreateOrUpdateFramework',
      request,
      metadata || {},
      methodDescriptor_Model_CreateOrUpdateFramework);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ListControlsRequest,
 *   !proto.model.Controls>}
 */
const methodDescriptor_Model_ListControls = new grpc.web.MethodDescriptor(
  '/model.Model/ListControls',
  grpc.web.MethodType.UNARY,
  model_control_pb.ListControlsRequest,
  model_control_pb.Controls,
  /**
   * @param {!proto.model.ListControlsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_control_pb.Controls.deserializeBinary
);


/**
 * @param {!proto.model.ListControlsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Controls)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Controls>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.listControls =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/ListControls',
      request,
      metadata || {},
      methodDescriptor_Model_ListControls,
      callback);
};


/**
 * @param {!proto.model.ListControlsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Controls>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.listControls =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/ListControls',
      request,
      metadata || {},
      methodDescriptor_Model_ListControls);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ListControlsRequest,
 *   !proto.common.StringList>}
 */
const methodDescriptor_Model_ListControlIds = new grpc.web.MethodDescriptor(
  '/model.Model/ListControlIds',
  grpc.web.MethodType.UNARY,
  model_control_pb.ListControlsRequest,
  common_collections_pb.StringList,
  /**
   * @param {!proto.model.ListControlsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_collections_pb.StringList.deserializeBinary
);


/**
 * @param {!proto.model.ListControlsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.common.StringList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.common.StringList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.listControlIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/ListControlIds',
      request,
      metadata || {},
      methodDescriptor_Model_ListControlIds,
      callback);
};


/**
 * @param {!proto.model.ListControlsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.common.StringList>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.listControlIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/ListControlIds',
      request,
      metadata || {},
      methodDescriptor_Model_ListControlIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.common.StringList>}
 */
const methodDescriptor_Model_ListDepartmentIds = new grpc.web.MethodDescriptor(
  '/model.Model/ListDepartmentIds',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  common_collections_pb.StringList,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_collections_pb.StringList.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.common.StringList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.common.StringList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.listDepartmentIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/ListDepartmentIds',
      request,
      metadata || {},
      methodDescriptor_Model_ListDepartmentIds,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.common.StringList>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.listDepartmentIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/ListDepartmentIds',
      request,
      metadata || {},
      methodDescriptor_Model_ListDepartmentIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.model.Departments>}
 */
const methodDescriptor_Model_ListDepartments = new grpc.web.MethodDescriptor(
  '/model.Model/ListDepartments',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  model_department_pb.Departments,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_department_pb.Departments.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Departments)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Departments>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.listDepartments =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/ListDepartments',
      request,
      metadata || {},
      methodDescriptor_Model_ListDepartments,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Departments>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.listDepartments =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/ListDepartments',
      request,
      metadata || {},
      methodDescriptor_Model_ListDepartments);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.model.Frameworks>}
 */
const methodDescriptor_Model_ListFrameworks = new grpc.web.MethodDescriptor(
  '/model.Model/ListFrameworks',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  model_framework_pb.Frameworks,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_framework_pb.Frameworks.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Frameworks)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Frameworks>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.listFrameworks =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/ListFrameworks',
      request,
      metadata || {},
      methodDescriptor_Model_ListFrameworks,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Frameworks>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.listFrameworks =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/ListFrameworks',
      request,
      metadata || {},
      methodDescriptor_Model_ListFrameworks);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.common.StringList>}
 */
const methodDescriptor_Model_ListFrameworkIds = new grpc.web.MethodDescriptor(
  '/model.Model/ListFrameworkIds',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  common_collections_pb.StringList,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_collections_pb.StringList.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.common.StringList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.common.StringList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.listFrameworkIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/ListFrameworkIds',
      request,
      metadata || {},
      methodDescriptor_Model_ListFrameworkIds,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.common.StringList>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.listFrameworkIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/ListFrameworkIds',
      request,
      metadata || {},
      methodDescriptor_Model_ListFrameworkIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ListFrameworkIdsByComplianceFrameworkIdRequest,
 *   !proto.common.StringList>}
 */
const methodDescriptor_Model_ListFrameworkIdsByComplianceFrameworkId = new grpc.web.MethodDescriptor(
  '/model.Model/ListFrameworkIdsByComplianceFrameworkId',
  grpc.web.MethodType.UNARY,
  model_framework_pb.ListFrameworkIdsByComplianceFrameworkIdRequest,
  common_collections_pb.StringList,
  /**
   * @param {!proto.model.ListFrameworkIdsByComplianceFrameworkIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  common_collections_pb.StringList.deserializeBinary
);


/**
 * @param {!proto.model.ListFrameworkIdsByComplianceFrameworkIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.common.StringList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.common.StringList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.listFrameworkIdsByComplianceFrameworkId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/ListFrameworkIdsByComplianceFrameworkId',
      request,
      metadata || {},
      methodDescriptor_Model_ListFrameworkIdsByComplianceFrameworkId,
      callback);
};


/**
 * @param {!proto.model.ListFrameworkIdsByComplianceFrameworkIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.common.StringList>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.listFrameworkIdsByComplianceFrameworkId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/ListFrameworkIdsByComplianceFrameworkId',
      request,
      metadata || {},
      methodDescriptor_Model_ListFrameworkIdsByComplianceFrameworkId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetControlRequest,
 *   !proto.model.Control>}
 */
const methodDescriptor_Model_GetControl = new grpc.web.MethodDescriptor(
  '/model.Model/GetControl',
  grpc.web.MethodType.UNARY,
  model_control_pb.GetControlRequest,
  model_control_pb.Control,
  /**
   * @param {!proto.model.GetControlRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_control_pb.Control.deserializeBinary
);


/**
 * @param {!proto.model.GetControlRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Control)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Control>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getControl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetControl',
      request,
      metadata || {},
      methodDescriptor_Model_GetControl,
      callback);
};


/**
 * @param {!proto.model.GetControlRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Control>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getControl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetControl',
      request,
      metadata || {},
      methodDescriptor_Model_GetControl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetDepartmentRequest,
 *   !proto.model.Department>}
 */
const methodDescriptor_Model_GetDepartment = new grpc.web.MethodDescriptor(
  '/model.Model/GetDepartment',
  grpc.web.MethodType.UNARY,
  model_department_pb.GetDepartmentRequest,
  model_department_pb.Department,
  /**
   * @param {!proto.model.GetDepartmentRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_department_pb.Department.deserializeBinary
);


/**
 * @param {!proto.model.GetDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Department)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Department>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getDepartment =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetDepartment',
      request,
      metadata || {},
      methodDescriptor_Model_GetDepartment,
      callback);
};


/**
 * @param {!proto.model.GetDepartmentRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Department>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getDepartment =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetDepartment',
      request,
      metadata || {},
      methodDescriptor_Model_GetDepartment);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetFrameworkRequest,
 *   !proto.model.Framework>}
 */
const methodDescriptor_Model_GetFramework = new grpc.web.MethodDescriptor(
  '/model.Model/GetFramework',
  grpc.web.MethodType.UNARY,
  model_framework_pb.GetFrameworkRequest,
  model_framework_pb.Framework,
  /**
   * @param {!proto.model.GetFrameworkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_framework_pb.Framework.deserializeBinary
);


/**
 * @param {!proto.model.GetFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Framework)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Framework>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getFramework =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetFramework',
      request,
      metadata || {},
      methodDescriptor_Model_GetFramework,
      callback);
};


/**
 * @param {!proto.model.GetFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Framework>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getFramework =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetFramework',
      request,
      metadata || {},
      methodDescriptor_Model_GetFramework);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetByModelIDsRequest,
 *   !proto.model.Records>}
 */
const methodDescriptor_Model_GetByModelIDs = new grpc.web.MethodDescriptor(
  '/model.Model/GetByModelIDs',
  grpc.web.MethodType.UNARY,
  proto.model.GetByModelIDsRequest,
  proto.model.Records,
  /**
   * @param {!proto.model.GetByModelIDsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.Records.deserializeBinary
);


/**
 * @param {!proto.model.GetByModelIDsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Records)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Records>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getByModelIDs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetByModelIDs',
      request,
      metadata || {},
      methodDescriptor_Model_GetByModelIDs,
      callback);
};


/**
 * @param {!proto.model.GetByModelIDsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Records>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getByModelIDs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetByModelIDs',
      request,
      metadata || {},
      methodDescriptor_Model_GetByModelIDs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ModelType,
 *   !proto.model.Records>}
 */
const methodDescriptor_Model_GetByModelType = new grpc.web.MethodDescriptor(
  '/model.Model/GetByModelType',
  grpc.web.MethodType.UNARY,
  proto.model.ModelType,
  proto.model.Records,
  /**
   * @param {!proto.model.ModelType} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.Records.deserializeBinary
);


/**
 * @param {!proto.model.ModelType} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Records)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Records>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getByModelType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetByModelType',
      request,
      metadata || {},
      methodDescriptor_Model_GetByModelType,
      callback);
};


/**
 * @param {!proto.model.ModelType} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Records>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getByModelType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetByModelType',
      request,
      metadata || {},
      methodDescriptor_Model_GetByModelType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ModelType,
 *   !proto.model.RecordCount>}
 */
const methodDescriptor_Model_GetCountByModelType = new grpc.web.MethodDescriptor(
  '/model.Model/GetCountByModelType',
  grpc.web.MethodType.UNARY,
  proto.model.ModelType,
  proto.model.RecordCount,
  /**
   * @param {!proto.model.ModelType} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.RecordCount.deserializeBinary
);


/**
 * @param {!proto.model.ModelType} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.RecordCount)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.RecordCount>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getCountByModelType =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetCountByModelType',
      request,
      metadata || {},
      methodDescriptor_Model_GetCountByModelType,
      callback);
};


/**
 * @param {!proto.model.ModelType} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.RecordCount>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getCountByModelType =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetCountByModelType',
      request,
      metadata || {},
      methodDescriptor_Model_GetCountByModelType);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetModelByOIDRequest,
 *   !proto.model.ModelRecord>}
 */
const methodDescriptor_Model_GetModelByOID = new grpc.web.MethodDescriptor(
  '/model.Model/GetModelByOID',
  grpc.web.MethodType.UNARY,
  proto.model.GetModelByOIDRequest,
  proto.model.ModelRecord,
  /**
   * @param {!proto.model.GetModelByOIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ModelRecord.deserializeBinary
);


/**
 * @param {!proto.model.GetModelByOIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ModelRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ModelRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getModelByOID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetModelByOID',
      request,
      metadata || {},
      methodDescriptor_Model_GetModelByOID,
      callback);
};


/**
 * @param {!proto.model.GetModelByOIDRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ModelRecord>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getModelByOID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetModelByOID',
      request,
      metadata || {},
      methodDescriptor_Model_GetModelByOID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.Owner,
 *   !proto.model.ModelRecord>}
 */
const methodDescriptor_Model_SetOwner = new grpc.web.MethodDescriptor(
  '/model.Model/SetOwner',
  grpc.web.MethodType.UNARY,
  proto.model.Owner,
  proto.model.ModelRecord,
  /**
   * @param {!proto.model.Owner} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ModelRecord.deserializeBinary
);


/**
 * @param {!proto.model.Owner} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ModelRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ModelRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.setOwner =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/SetOwner',
      request,
      metadata || {},
      methodDescriptor_Model_SetOwner,
      callback);
};


/**
 * @param {!proto.model.Owner} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ModelRecord>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.setOwner =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/SetOwner',
      request,
      metadata || {},
      methodDescriptor_Model_SetOwner);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ControlStatusRequest,
 *   !proto.model.ModelRecord>}
 */
const methodDescriptor_Model_SetControlStatus = new grpc.web.MethodDescriptor(
  '/model.Model/SetControlStatus',
  grpc.web.MethodType.UNARY,
  proto.model.ControlStatusRequest,
  proto.model.ModelRecord,
  /**
   * @param {!proto.model.ControlStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ModelRecord.deserializeBinary
);


/**
 * @param {!proto.model.ControlStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ModelRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ModelRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.setControlStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/SetControlStatus',
      request,
      metadata || {},
      methodDescriptor_Model_SetControlStatus,
      callback);
};


/**
 * @param {!proto.model.ControlStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ModelRecord>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.setControlStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/SetControlStatus',
      request,
      metadata || {},
      methodDescriptor_Model_SetControlStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.Dismissed,
 *   !proto.model.ModelRecord>}
 */
const methodDescriptor_Model_SetDismissed = new grpc.web.MethodDescriptor(
  '/model.Model/SetDismissed',
  grpc.web.MethodType.UNARY,
  proto.model.Dismissed,
  proto.model.ModelRecord,
  /**
   * @param {!proto.model.Dismissed} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ModelRecord.deserializeBinary
);


/**
 * @param {!proto.model.Dismissed} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ModelRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ModelRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.setDismissed =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/SetDismissed',
      request,
      metadata || {},
      methodDescriptor_Model_SetDismissed,
      callback);
};


/**
 * @param {!proto.model.Dismissed} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ModelRecord>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.setDismissed =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/SetDismissed',
      request,
      metadata || {},
      methodDescriptor_Model_SetDismissed);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.CreateCustomServiceRequest,
 *   !proto.model.ModelRecord>}
 */
const methodDescriptor_Model_CreateCustomService = new grpc.web.MethodDescriptor(
  '/model.Model/CreateCustomService',
  grpc.web.MethodType.UNARY,
  proto.model.CreateCustomServiceRequest,
  proto.model.ModelRecord,
  /**
   * @param {!proto.model.CreateCustomServiceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ModelRecord.deserializeBinary
);


/**
 * @param {!proto.model.CreateCustomServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ModelRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ModelRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.createCustomService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/CreateCustomService',
      request,
      metadata || {},
      methodDescriptor_Model_CreateCustomService,
      callback);
};


/**
 * @param {!proto.model.CreateCustomServiceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ModelRecord>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.createCustomService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/CreateCustomService',
      request,
      metadata || {},
      methodDescriptor_Model_CreateCustomService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.HasCustomerData,
 *   !proto.model.ModelRecord>}
 */
const methodDescriptor_Model_SetHasCustomerData = new grpc.web.MethodDescriptor(
  '/model.Model/SetHasCustomerData',
  grpc.web.MethodType.UNARY,
  proto.model.HasCustomerData,
  proto.model.ModelRecord,
  /**
   * @param {!proto.model.HasCustomerData} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ModelRecord.deserializeBinary
);


/**
 * @param {!proto.model.HasCustomerData} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ModelRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ModelRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.setHasCustomerData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/SetHasCustomerData',
      request,
      metadata || {},
      methodDescriptor_Model_SetHasCustomerData,
      callback);
};


/**
 * @param {!proto.model.HasCustomerData} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ModelRecord>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.setHasCustomerData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/SetHasCustomerData',
      request,
      metadata || {},
      methodDescriptor_Model_SetHasCustomerData);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ProductDelivery,
 *   !proto.model.ModelRecord>}
 */
const methodDescriptor_Model_SetProductDelivery = new grpc.web.MethodDescriptor(
  '/model.Model/SetProductDelivery',
  grpc.web.MethodType.UNARY,
  proto.model.ProductDelivery,
  proto.model.ModelRecord,
  /**
   * @param {!proto.model.ProductDelivery} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ModelRecord.deserializeBinary
);


/**
 * @param {!proto.model.ProductDelivery} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ModelRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ModelRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.setProductDelivery =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/SetProductDelivery',
      request,
      metadata || {},
      methodDescriptor_Model_SetProductDelivery,
      callback);
};


/**
 * @param {!proto.model.ProductDelivery} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ModelRecord>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.setProductDelivery =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/SetProductDelivery',
      request,
      metadata || {},
      methodDescriptor_Model_SetProductDelivery);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.SupportingTool,
 *   !proto.model.ModelRecord>}
 */
const methodDescriptor_Model_SetSupportingTool = new grpc.web.MethodDescriptor(
  '/model.Model/SetSupportingTool',
  grpc.web.MethodType.UNARY,
  proto.model.SupportingTool,
  proto.model.ModelRecord,
  /**
   * @param {!proto.model.SupportingTool} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ModelRecord.deserializeBinary
);


/**
 * @param {!proto.model.SupportingTool} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ModelRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ModelRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.setSupportingTool =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/SetSupportingTool',
      request,
      metadata || {},
      methodDescriptor_Model_SetSupportingTool,
      callback);
};


/**
 * @param {!proto.model.SupportingTool} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ModelRecord>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.setSupportingTool =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/SetSupportingTool',
      request,
      metadata || {},
      methodDescriptor_Model_SetSupportingTool);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.DismissedBatch,
 *   !proto.model.Records>}
 */
const methodDescriptor_Model_SetDismissedBatch = new grpc.web.MethodDescriptor(
  '/model.Model/SetDismissedBatch',
  grpc.web.MethodType.UNARY,
  proto.model.DismissedBatch,
  proto.model.Records,
  /**
   * @param {!proto.model.DismissedBatch} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.Records.deserializeBinary
);


/**
 * @param {!proto.model.DismissedBatch} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Records)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Records>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.setDismissedBatch =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/SetDismissedBatch',
      request,
      metadata || {},
      methodDescriptor_Model_SetDismissedBatch,
      callback);
};


/**
 * @param {!proto.model.DismissedBatch} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Records>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.setDismissedBatch =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/SetDismissedBatch',
      request,
      metadata || {},
      methodDescriptor_Model_SetDismissedBatch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.common.Identifier,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Model_Delete = new grpc.web.MethodDescriptor(
  '/model.Model/Delete',
  grpc.web.MethodType.UNARY,
  common_model_pb.Identifier,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.common.Identifier} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.common.Identifier} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.delete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/Delete',
      request,
      metadata || {},
      methodDescriptor_Model_Delete,
      callback);
};


/**
 * @param {!proto.common.Identifier} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.delete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/Delete',
      request,
      metadata || {},
      methodDescriptor_Model_Delete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetControlStatsRequest,
 *   !proto.model.ControlStats>}
 */
const methodDescriptor_Model_GetControlStats = new grpc.web.MethodDescriptor(
  '/model.Model/GetControlStats',
  grpc.web.MethodType.UNARY,
  proto.model.GetControlStatsRequest,
  proto.model.ControlStats,
  /**
   * @param {!proto.model.GetControlStatsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ControlStats.deserializeBinary
);


/**
 * @param {!proto.model.GetControlStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ControlStats)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ControlStats>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getControlStats =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetControlStats',
      request,
      metadata || {},
      methodDescriptor_Model_GetControlStats,
      callback);
};


/**
 * @param {!proto.model.GetControlStatsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ControlStats>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getControlStats =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetControlStats',
      request,
      metadata || {},
      methodDescriptor_Model_GetControlStats);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetControlStatsByModelIdRequest,
 *   !proto.model.GetControlStatsByModelIdResponse>}
 */
const methodDescriptor_Model_GetControlStatsByModelId = new grpc.web.MethodDescriptor(
  '/model.Model/GetControlStatsByModelId',
  grpc.web.MethodType.UNARY,
  proto.model.GetControlStatsByModelIdRequest,
  proto.model.GetControlStatsByModelIdResponse,
  /**
   * @param {!proto.model.GetControlStatsByModelIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.GetControlStatsByModelIdResponse.deserializeBinary
);


/**
 * @param {!proto.model.GetControlStatsByModelIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.GetControlStatsByModelIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.GetControlStatsByModelIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getControlStatsByModelId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetControlStatsByModelId',
      request,
      metadata || {},
      methodDescriptor_Model_GetControlStatsByModelId,
      callback);
};


/**
 * @param {!proto.model.GetControlStatsByModelIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.GetControlStatsByModelIdResponse>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getControlStatsByModelId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetControlStatsByModelId',
      request,
      metadata || {},
      methodDescriptor_Model_GetControlStatsByModelId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Model_UpdateDefaultControls = new grpc.web.MethodDescriptor(
  '/model.Model/UpdateDefaultControls',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.updateDefaultControls =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/UpdateDefaultControls',
      request,
      metadata || {},
      methodDescriptor_Model_UpdateDefaultControls,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.updateDefaultControls =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/UpdateDefaultControls',
      request,
      metadata || {},
      methodDescriptor_Model_UpdateDefaultControls);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetControlsForComplianceFrameworkRequest,
 *   !proto.model.Controls>}
 */
const methodDescriptor_Model_GetControlsForComplianceFramework = new grpc.web.MethodDescriptor(
  '/model.Model/GetControlsForComplianceFramework',
  grpc.web.MethodType.UNARY,
  model_control_pb.GetControlsForComplianceFrameworkRequest,
  model_control_pb.Controls,
  /**
   * @param {!proto.model.GetControlsForComplianceFrameworkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_control_pb.Controls.deserializeBinary
);


/**
 * @param {!proto.model.GetControlsForComplianceFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Controls)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Controls>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getControlsForComplianceFramework =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetControlsForComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Model_GetControlsForComplianceFramework,
      callback);
};


/**
 * @param {!proto.model.GetControlsForComplianceFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Controls>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getControlsForComplianceFramework =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetControlsForComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Model_GetControlsForComplianceFramework);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetControlsInAccountForComplianceFrameworkRequest,
 *   !proto.model.Controls>}
 */
const methodDescriptor_Model_GetControlsInAccountForComplianceFramework = new grpc.web.MethodDescriptor(
  '/model.Model/GetControlsInAccountForComplianceFramework',
  grpc.web.MethodType.UNARY,
  model_control_pb.GetControlsInAccountForComplianceFrameworkRequest,
  model_control_pb.Controls,
  /**
   * @param {!proto.model.GetControlsInAccountForComplianceFrameworkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_control_pb.Controls.deserializeBinary
);


/**
 * @param {!proto.model.GetControlsInAccountForComplianceFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Controls)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Controls>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getControlsInAccountForComplianceFramework =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetControlsInAccountForComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Model_GetControlsInAccountForComplianceFramework,
      callback);
};


/**
 * @param {!proto.model.GetControlsInAccountForComplianceFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Controls>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getControlsInAccountForComplianceFramework =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetControlsInAccountForComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Model_GetControlsInAccountForComplianceFramework);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetPoliciesForComplianceFrameworkRequest,
 *   !proto.model.PolicyRecords>}
 */
const methodDescriptor_Model_GetPoliciesForComplianceFramework = new grpc.web.MethodDescriptor(
  '/model.Model/GetPoliciesForComplianceFramework',
  grpc.web.MethodType.UNARY,
  model_policy_pb.GetPoliciesForComplianceFrameworkRequest,
  model_policy_pb.PolicyRecords,
  /**
   * @param {!proto.model.GetPoliciesForComplianceFrameworkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_policy_pb.PolicyRecords.deserializeBinary
);


/**
 * @param {!proto.model.GetPoliciesForComplianceFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.PolicyRecords)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.PolicyRecords>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getPoliciesForComplianceFramework =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetPoliciesForComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Model_GetPoliciesForComplianceFramework,
      callback);
};


/**
 * @param {!proto.model.GetPoliciesForComplianceFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.PolicyRecords>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getPoliciesForComplianceFramework =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetPoliciesForComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Model_GetPoliciesForComplianceFramework);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetPoliciesInAccountForComplianceFrameworkRequest,
 *   !proto.model.PolicyRecords>}
 */
const methodDescriptor_Model_GetPoliciesInAccountForComplianceFramework = new grpc.web.MethodDescriptor(
  '/model.Model/GetPoliciesInAccountForComplianceFramework',
  grpc.web.MethodType.UNARY,
  model_policy_pb.GetPoliciesInAccountForComplianceFrameworkRequest,
  model_policy_pb.PolicyRecords,
  /**
   * @param {!proto.model.GetPoliciesInAccountForComplianceFrameworkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_policy_pb.PolicyRecords.deserializeBinary
);


/**
 * @param {!proto.model.GetPoliciesInAccountForComplianceFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.PolicyRecords)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.PolicyRecords>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getPoliciesInAccountForComplianceFramework =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetPoliciesInAccountForComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Model_GetPoliciesInAccountForComplianceFramework,
      callback);
};


/**
 * @param {!proto.model.GetPoliciesInAccountForComplianceFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.PolicyRecords>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getPoliciesInAccountForComplianceFramework =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetPoliciesInAccountForComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Model_GetPoliciesInAccountForComplianceFramework);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetPolicyForComplianceFrameworkRequest,
 *   !proto.model.PolicyRecord>}
 */
const methodDescriptor_Model_GetPolicyForComplianceFramework = new grpc.web.MethodDescriptor(
  '/model.Model/GetPolicyForComplianceFramework',
  grpc.web.MethodType.UNARY,
  model_policy_pb.GetPolicyForComplianceFrameworkRequest,
  model_policy_pb.PolicyRecord,
  /**
   * @param {!proto.model.GetPolicyForComplianceFrameworkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_policy_pb.PolicyRecord.deserializeBinary
);


/**
 * @param {!proto.model.GetPolicyForComplianceFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.PolicyRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.PolicyRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getPolicyForComplianceFramework =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetPolicyForComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Model_GetPolicyForComplianceFramework,
      callback);
};


/**
 * @param {!proto.model.GetPolicyForComplianceFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.PolicyRecord>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getPolicyForComplianceFramework =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetPolicyForComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Model_GetPolicyForComplianceFramework);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetDepartmentForComplianceFrameworkRequest,
 *   !proto.model.Department>}
 */
const methodDescriptor_Model_GetDepartmentForComplianceFramework = new grpc.web.MethodDescriptor(
  '/model.Model/GetDepartmentForComplianceFramework',
  grpc.web.MethodType.UNARY,
  model_department_pb.GetDepartmentForComplianceFrameworkRequest,
  model_department_pb.Department,
  /**
   * @param {!proto.model.GetDepartmentForComplianceFrameworkRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_department_pb.Department.deserializeBinary
);


/**
 * @param {!proto.model.GetDepartmentForComplianceFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Department)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Department>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getDepartmentForComplianceFramework =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetDepartmentForComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Model_GetDepartmentForComplianceFramework,
      callback);
};


/**
 * @param {!proto.model.GetDepartmentForComplianceFrameworkRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Department>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getDepartmentForComplianceFramework =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetDepartmentForComplianceFramework',
      request,
      metadata || {},
      methodDescriptor_Model_GetDepartmentForComplianceFramework);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetComplianceFrameworkObjectivesRequest,
 *   !proto.model.Frameworks>}
 */
const methodDescriptor_Model_GetComplianceFrameworkObjectives = new grpc.web.MethodDescriptor(
  '/model.Model/GetComplianceFrameworkObjectives',
  grpc.web.MethodType.UNARY,
  proto.model.GetComplianceFrameworkObjectivesRequest,
  model_framework_pb.Frameworks,
  /**
   * @param {!proto.model.GetComplianceFrameworkObjectivesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_framework_pb.Frameworks.deserializeBinary
);


/**
 * @param {!proto.model.GetComplianceFrameworkObjectivesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Frameworks)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Frameworks>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getComplianceFrameworkObjectives =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetComplianceFrameworkObjectives',
      request,
      metadata || {},
      methodDescriptor_Model_GetComplianceFrameworkObjectives,
      callback);
};


/**
 * @param {!proto.model.GetComplianceFrameworkObjectivesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Frameworks>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getComplianceFrameworkObjectives =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetComplianceFrameworkObjectives',
      request,
      metadata || {},
      methodDescriptor_Model_GetComplianceFrameworkObjectives);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetObjectivesByModelIdRequest,
 *   !proto.model.Frameworks>}
 */
const methodDescriptor_Model_GetObjectivesByModelId = new grpc.web.MethodDescriptor(
  '/model.Model/GetObjectivesByModelId',
  grpc.web.MethodType.UNARY,
  proto.model.GetObjectivesByModelIdRequest,
  model_framework_pb.Frameworks,
  /**
   * @param {!proto.model.GetObjectivesByModelIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  model_framework_pb.Frameworks.deserializeBinary
);


/**
 * @param {!proto.model.GetObjectivesByModelIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Frameworks)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Frameworks>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ModelClient.prototype.getObjectivesByModelId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.Model/GetObjectivesByModelId',
      request,
      metadata || {},
      methodDescriptor_Model_GetObjectivesByModelId,
      callback);
};


/**
 * @param {!proto.model.GetObjectivesByModelIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Frameworks>}
 *     Promise that resolves to the response
 */
proto.model.ModelPromiseClient.prototype.getObjectivesByModelId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.Model/GetObjectivesByModelId',
      request,
      metadata || {},
      methodDescriptor_Model_GetObjectivesByModelId);
};


module.exports = proto.model;


// source: service/service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.service.BulkCreateOrUpdateServicesRequest', null, global);
goog.exportSymbol('proto.service.CreateCustomServiceRequest', null, global);
goog.exportSymbol('proto.service.CreateOrUpdateServiceRequest', null, global);
goog.exportSymbol('proto.service.GetServiceRequest', null, global);
goog.exportSymbol('proto.service.ListServicesRequest', null, global);
goog.exportSymbol('proto.service.ListServicesResponse', null, global);
goog.exportSymbol('proto.service.PrimaryInfrastructureMapping', null, global);
goog.exportSymbol('proto.service.PrimaryInfrastructureMappings', null, global);
goog.exportSymbol('proto.service.PrimaryInfrastructureService', null, global);
goog.exportSymbol('proto.service.ReceptorFilter', null, global);
goog.exportSymbol('proto.service.SERVICE_ROLE', null, global);
goog.exportSymbol('proto.service.ServiceRecord', null, global);
goog.exportSymbol('proto.service.ServiceRecords', null, global);
goog.exportSymbol('proto.service.ServiceRoleFilter', null, global);
goog.exportSymbol('proto.service.ServiceRoles', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.service.ServiceRecord = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.service.ServiceRecord.repeatedFields_, null);
};
goog.inherits(proto.service.ServiceRecord, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.service.ServiceRecord.displayName = 'proto.service.ServiceRecord';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.service.ServiceRecords = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.service.ServiceRecords.repeatedFields_, null);
};
goog.inherits(proto.service.ServiceRecords, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.service.ServiceRecords.displayName = 'proto.service.ServiceRecords';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.service.ServiceRoleFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.service.ServiceRoleFilter.repeatedFields_, null);
};
goog.inherits(proto.service.ServiceRoleFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.service.ServiceRoleFilter.displayName = 'proto.service.ServiceRoleFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.service.ReceptorFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.service.ReceptorFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.service.ReceptorFilter.displayName = 'proto.service.ReceptorFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.service.ListServicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.service.ListServicesRequest.repeatedFields_, null);
};
goog.inherits(proto.service.ListServicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.service.ListServicesRequest.displayName = 'proto.service.ListServicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.service.ListServicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.service.ListServicesResponse.repeatedFields_, null);
};
goog.inherits(proto.service.ListServicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.service.ListServicesResponse.displayName = 'proto.service.ListServicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.service.GetServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.service.GetServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.service.GetServiceRequest.displayName = 'proto.service.GetServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.service.ServiceRoles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.service.ServiceRoles.repeatedFields_, null);
};
goog.inherits(proto.service.ServiceRoles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.service.ServiceRoles.displayName = 'proto.service.ServiceRoles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.service.CreateOrUpdateServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.service.CreateOrUpdateServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.service.CreateOrUpdateServiceRequest.displayName = 'proto.service.CreateOrUpdateServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.service.BulkCreateOrUpdateServicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.service.BulkCreateOrUpdateServicesRequest.repeatedFields_, null);
};
goog.inherits(proto.service.BulkCreateOrUpdateServicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.service.BulkCreateOrUpdateServicesRequest.displayName = 'proto.service.BulkCreateOrUpdateServicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.service.CreateCustomServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.service.CreateCustomServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.service.CreateCustomServiceRequest.displayName = 'proto.service.CreateCustomServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.service.PrimaryInfrastructureService = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.service.PrimaryInfrastructureService, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.service.PrimaryInfrastructureService.displayName = 'proto.service.PrimaryInfrastructureService';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.service.PrimaryInfrastructureMapping = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.service.PrimaryInfrastructureMapping.repeatedFields_, null);
};
goog.inherits(proto.service.PrimaryInfrastructureMapping, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.service.PrimaryInfrastructureMapping.displayName = 'proto.service.PrimaryInfrastructureMapping';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.service.PrimaryInfrastructureMappings = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.service.PrimaryInfrastructureMappings.repeatedFields_, null);
};
goog.inherits(proto.service.PrimaryInfrastructureMappings, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.service.PrimaryInfrastructureMappings.displayName = 'proto.service.PrimaryInfrastructureMappings';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.service.ServiceRecord.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.service.ServiceRecord.prototype.toObject = function(opt_includeInstance) {
  return proto.service.ServiceRecord.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.service.ServiceRecord} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.ServiceRecord.toObject = function(includeInstance, msg) {
  var f, obj = {
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    name: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isDismissed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isCustom: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    instanceCount: jspb.Message.getFieldWithDefault(msg, 9, 0),
    serviceRolesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    isExcluded: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.service.ServiceRecord}
 */
proto.service.ServiceRecord.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.service.ServiceRecord;
  return proto.service.ServiceRecord.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.service.ServiceRecord} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.service.ServiceRecord}
 */
proto.service.ServiceRecord.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDismissed(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCustom(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInstanceCount(value);
      break;
    case 10:
      var values = /** @type {!Array<!proto.service.SERVICE_ROLE>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addServiceRoles(values[i]);
      }
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExcluded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.service.ServiceRecord.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.service.ServiceRecord.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.service.ServiceRecord} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.ServiceRecord.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsDismissed();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsCustom();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getInstanceCount();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getServiceRolesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
  f = message.getIsExcluded();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional google.protobuf.Timestamp created_at = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.service.ServiceRecord.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.service.ServiceRecord} returns this
*/
proto.service.ServiceRecord.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.service.ServiceRecord} returns this
 */
proto.service.ServiceRecord.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.service.ServiceRecord.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp updated_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.service.ServiceRecord.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.service.ServiceRecord} returns this
*/
proto.service.ServiceRecord.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.service.ServiceRecord} returns this
 */
proto.service.ServiceRecord.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.service.ServiceRecord.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string id = 3;
 * @return {string}
 */
proto.service.ServiceRecord.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.service.ServiceRecord} returns this
 */
proto.service.ServiceRecord.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_id = 4;
 * @return {string}
 */
proto.service.ServiceRecord.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.service.ServiceRecord} returns this
 */
proto.service.ServiceRecord.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string model_id = 5;
 * @return {string}
 */
proto.service.ServiceRecord.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.service.ServiceRecord} returns this
 */
proto.service.ServiceRecord.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string name = 6;
 * @return {string}
 */
proto.service.ServiceRecord.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.service.ServiceRecord} returns this
 */
proto.service.ServiceRecord.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_dismissed = 7;
 * @return {boolean}
 */
proto.service.ServiceRecord.prototype.getIsDismissed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.service.ServiceRecord} returns this
 */
proto.service.ServiceRecord.prototype.setIsDismissed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_custom = 8;
 * @return {boolean}
 */
proto.service.ServiceRecord.prototype.getIsCustom = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.service.ServiceRecord} returns this
 */
proto.service.ServiceRecord.prototype.setIsCustom = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 instance_count = 9;
 * @return {number}
 */
proto.service.ServiceRecord.prototype.getInstanceCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.service.ServiceRecord} returns this
 */
proto.service.ServiceRecord.prototype.setInstanceCount = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * repeated SERVICE_ROLE service_roles = 10;
 * @return {!Array<!proto.service.SERVICE_ROLE>}
 */
proto.service.ServiceRecord.prototype.getServiceRolesList = function() {
  return /** @type {!Array<!proto.service.SERVICE_ROLE>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.service.SERVICE_ROLE>} value
 * @return {!proto.service.ServiceRecord} returns this
 */
proto.service.ServiceRecord.prototype.setServiceRolesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.service.SERVICE_ROLE} value
 * @param {number=} opt_index
 * @return {!proto.service.ServiceRecord} returns this
 */
proto.service.ServiceRecord.prototype.addServiceRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.service.ServiceRecord} returns this
 */
proto.service.ServiceRecord.prototype.clearServiceRolesList = function() {
  return this.setServiceRolesList([]);
};


/**
 * optional bool is_excluded = 11;
 * @return {boolean}
 */
proto.service.ServiceRecord.prototype.getIsExcluded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.service.ServiceRecord} returns this
 */
proto.service.ServiceRecord.prototype.setIsExcluded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.service.ServiceRecords.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.service.ServiceRecords.prototype.toObject = function(opt_includeInstance) {
  return proto.service.ServiceRecords.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.service.ServiceRecords} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.ServiceRecords.toObject = function(includeInstance, msg) {
  var f, obj = {
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.service.ServiceRecord.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.service.ServiceRecords}
 */
proto.service.ServiceRecords.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.service.ServiceRecords;
  return proto.service.ServiceRecords.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.service.ServiceRecords} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.service.ServiceRecords}
 */
proto.service.ServiceRecords.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.service.ServiceRecord;
      reader.readMessage(value,proto.service.ServiceRecord.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.service.ServiceRecords.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.service.ServiceRecords.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.service.ServiceRecords} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.ServiceRecords.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.service.ServiceRecord.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ServiceRecord services = 1;
 * @return {!Array<!proto.service.ServiceRecord>}
 */
proto.service.ServiceRecords.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.service.ServiceRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.service.ServiceRecord, 1));
};


/**
 * @param {!Array<!proto.service.ServiceRecord>} value
 * @return {!proto.service.ServiceRecords} returns this
*/
proto.service.ServiceRecords.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.service.ServiceRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.service.ServiceRecord}
 */
proto.service.ServiceRecords.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.service.ServiceRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.service.ServiceRecords} returns this
 */
proto.service.ServiceRecords.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.service.ServiceRoleFilter.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.service.ServiceRoleFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.service.ServiceRoleFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.service.ServiceRoleFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.ServiceRoleFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceRolesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    includeNone: (f = msg.getIncludeNone()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.service.ServiceRoleFilter}
 */
proto.service.ServiceRoleFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.service.ServiceRoleFilter;
  return proto.service.ServiceRoleFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.service.ServiceRoleFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.service.ServiceRoleFilter}
 */
proto.service.ServiceRoleFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.service.SERVICE_ROLE>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addServiceRoles(values[i]);
      }
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeNone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.service.ServiceRoleFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.service.ServiceRoleFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.service.ServiceRoleFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.ServiceRoleFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceRolesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
  f = message.getIncludeNone();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SERVICE_ROLE service_roles = 1;
 * @return {!Array<!proto.service.SERVICE_ROLE>}
 */
proto.service.ServiceRoleFilter.prototype.getServiceRolesList = function() {
  return /** @type {!Array<!proto.service.SERVICE_ROLE>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.service.SERVICE_ROLE>} value
 * @return {!proto.service.ServiceRoleFilter} returns this
 */
proto.service.ServiceRoleFilter.prototype.setServiceRolesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.service.SERVICE_ROLE} value
 * @param {number=} opt_index
 * @return {!proto.service.ServiceRoleFilter} returns this
 */
proto.service.ServiceRoleFilter.prototype.addServiceRoles = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.service.ServiceRoleFilter} returns this
 */
proto.service.ServiceRoleFilter.prototype.clearServiceRolesList = function() {
  return this.setServiceRolesList([]);
};


/**
 * optional google.protobuf.BoolValue include_none = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.service.ServiceRoleFilter.prototype.getIncludeNone = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.service.ServiceRoleFilter} returns this
*/
proto.service.ServiceRoleFilter.prototype.setIncludeNone = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.service.ServiceRoleFilter} returns this
 */
proto.service.ServiceRoleFilter.prototype.clearIncludeNone = function() {
  return this.setIncludeNone(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.service.ServiceRoleFilter.prototype.hasIncludeNone = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.service.ReceptorFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.service.ReceptorFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.service.ReceptorFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.ReceptorFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    receptorId: (f = msg.getReceptorId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    receptorModelId: (f = msg.getReceptorModelId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.service.ReceptorFilter}
 */
proto.service.ReceptorFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.service.ReceptorFilter;
  return proto.service.ReceptorFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.service.ReceptorFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.service.ReceptorFilter}
 */
proto.service.ReceptorFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setReceptorId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setReceptorModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.service.ReceptorFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.service.ReceptorFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.service.ReceptorFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.ReceptorFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceptorId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getReceptorModelId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue receptor_id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.service.ReceptorFilter.prototype.getReceptorId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.service.ReceptorFilter} returns this
*/
proto.service.ReceptorFilter.prototype.setReceptorId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.service.ReceptorFilter} returns this
 */
proto.service.ReceptorFilter.prototype.clearReceptorId = function() {
  return this.setReceptorId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.service.ReceptorFilter.prototype.hasReceptorId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue receptor_model_id = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.service.ReceptorFilter.prototype.getReceptorModelId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.service.ReceptorFilter} returns this
*/
proto.service.ReceptorFilter.prototype.setReceptorModelId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.service.ReceptorFilter} returns this
 */
proto.service.ReceptorFilter.prototype.clearReceptorModelId = function() {
  return this.setReceptorModelId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.service.ReceptorFilter.prototype.hasReceptorModelId = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.service.ListServicesRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.service.ListServicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.service.ListServicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.service.ListServicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.ListServicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    receptorFilter: (f = msg.getReceptorFilter()) && proto.service.ReceptorFilter.toObject(includeInstance, f),
    modelIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    serviceRoleFilter: (f = msg.getServiceRoleFilter()) && proto.service.ServiceRoleFilter.toObject(includeInstance, f),
    includeExcluded: (f = msg.getIncludeExcluded()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.service.ListServicesRequest}
 */
proto.service.ListServicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.service.ListServicesRequest;
  return proto.service.ListServicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.service.ListServicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.service.ListServicesRequest}
 */
proto.service.ListServicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.service.ReceptorFilter;
      reader.readMessage(value,proto.service.ReceptorFilter.deserializeBinaryFromReader);
      msg.setReceptorFilter(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addModelIds(value);
      break;
    case 3:
      var value = new proto.service.ServiceRoleFilter;
      reader.readMessage(value,proto.service.ServiceRoleFilter.deserializeBinaryFromReader);
      msg.setServiceRoleFilter(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIncludeExcluded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.service.ListServicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.service.ListServicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.service.ListServicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.ListServicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceptorFilter();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.service.ReceptorFilter.serializeBinaryToWriter
    );
  }
  f = message.getModelIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getServiceRoleFilter();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.service.ServiceRoleFilter.serializeBinaryToWriter
    );
  }
  f = message.getIncludeExcluded();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional ReceptorFilter receptor_filter = 1;
 * @return {?proto.service.ReceptorFilter}
 */
proto.service.ListServicesRequest.prototype.getReceptorFilter = function() {
  return /** @type{?proto.service.ReceptorFilter} */ (
    jspb.Message.getWrapperField(this, proto.service.ReceptorFilter, 1));
};


/**
 * @param {?proto.service.ReceptorFilter|undefined} value
 * @return {!proto.service.ListServicesRequest} returns this
*/
proto.service.ListServicesRequest.prototype.setReceptorFilter = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.service.ListServicesRequest} returns this
 */
proto.service.ListServicesRequest.prototype.clearReceptorFilter = function() {
  return this.setReceptorFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.service.ListServicesRequest.prototype.hasReceptorFilter = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string model_ids = 2;
 * @return {!Array<string>}
 */
proto.service.ListServicesRequest.prototype.getModelIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.service.ListServicesRequest} returns this
 */
proto.service.ListServicesRequest.prototype.setModelIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.service.ListServicesRequest} returns this
 */
proto.service.ListServicesRequest.prototype.addModelIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.service.ListServicesRequest} returns this
 */
proto.service.ListServicesRequest.prototype.clearModelIdsList = function() {
  return this.setModelIdsList([]);
};


/**
 * optional ServiceRoleFilter service_role_filter = 3;
 * @return {?proto.service.ServiceRoleFilter}
 */
proto.service.ListServicesRequest.prototype.getServiceRoleFilter = function() {
  return /** @type{?proto.service.ServiceRoleFilter} */ (
    jspb.Message.getWrapperField(this, proto.service.ServiceRoleFilter, 3));
};


/**
 * @param {?proto.service.ServiceRoleFilter|undefined} value
 * @return {!proto.service.ListServicesRequest} returns this
*/
proto.service.ListServicesRequest.prototype.setServiceRoleFilter = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.service.ListServicesRequest} returns this
 */
proto.service.ListServicesRequest.prototype.clearServiceRoleFilter = function() {
  return this.setServiceRoleFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.service.ListServicesRequest.prototype.hasServiceRoleFilter = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.BoolValue include_excluded = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.service.ListServicesRequest.prototype.getIncludeExcluded = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.service.ListServicesRequest} returns this
*/
proto.service.ListServicesRequest.prototype.setIncludeExcluded = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.service.ListServicesRequest} returns this
 */
proto.service.ListServicesRequest.prototype.clearIncludeExcluded = function() {
  return this.setIncludeExcluded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.service.ListServicesRequest.prototype.hasIncludeExcluded = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.service.ListServicesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.service.ListServicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.service.ListServicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.service.ListServicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.ListServicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.service.ServiceRecord.toObject, includeInstance),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.service.ListServicesResponse}
 */
proto.service.ListServicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.service.ListServicesResponse;
  return proto.service.ListServicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.service.ListServicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.service.ListServicesResponse}
 */
proto.service.ListServicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.service.ServiceRecord;
      reader.readMessage(value,proto.service.ServiceRecord.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.service.ListServicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.service.ListServicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.service.ListServicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.ListServicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.service.ServiceRecord.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * repeated ServiceRecord services = 1;
 * @return {!Array<!proto.service.ServiceRecord>}
 */
proto.service.ListServicesResponse.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.service.ServiceRecord>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.service.ServiceRecord, 1));
};


/**
 * @param {!Array<!proto.service.ServiceRecord>} value
 * @return {!proto.service.ListServicesResponse} returns this
*/
proto.service.ListServicesResponse.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.service.ServiceRecord=} opt_value
 * @param {number=} opt_index
 * @return {!proto.service.ServiceRecord}
 */
proto.service.ListServicesResponse.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.service.ServiceRecord, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.service.ListServicesResponse} returns this
 */
proto.service.ListServicesResponse.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};


/**
 * optional int32 total = 2;
 * @return {number}
 */
proto.service.ListServicesResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.service.ListServicesResponse} returns this
 */
proto.service.ListServicesResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.service.GetServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.service.GetServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.service.GetServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.GetServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.service.GetServiceRequest}
 */
proto.service.GetServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.service.GetServiceRequest;
  return proto.service.GetServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.service.GetServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.service.GetServiceRequest}
 */
proto.service.GetServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.service.GetServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.service.GetServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.service.GetServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.GetServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.service.GetServiceRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.service.GetServiceRequest} returns this
 */
proto.service.GetServiceRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_id = 2;
 * @return {string}
 */
proto.service.GetServiceRequest.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.service.GetServiceRequest} returns this
 */
proto.service.GetServiceRequest.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.service.ServiceRoles.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.service.ServiceRoles.prototype.toObject = function(opt_includeInstance) {
  return proto.service.ServiceRoles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.service.ServiceRoles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.ServiceRoles.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.service.ServiceRoles}
 */
proto.service.ServiceRoles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.service.ServiceRoles;
  return proto.service.ServiceRoles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.service.ServiceRoles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.service.ServiceRoles}
 */
proto.service.ServiceRoles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.service.SERVICE_ROLE>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addItems(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.service.ServiceRoles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.service.ServiceRoles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.service.ServiceRoles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.ServiceRoles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated SERVICE_ROLE items = 1;
 * @return {!Array<!proto.service.SERVICE_ROLE>}
 */
proto.service.ServiceRoles.prototype.getItemsList = function() {
  return /** @type {!Array<!proto.service.SERVICE_ROLE>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.service.SERVICE_ROLE>} value
 * @return {!proto.service.ServiceRoles} returns this
 */
proto.service.ServiceRoles.prototype.setItemsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.service.SERVICE_ROLE} value
 * @param {number=} opt_index
 * @return {!proto.service.ServiceRoles} returns this
 */
proto.service.ServiceRoles.prototype.addItems = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.service.ServiceRoles} returns this
 */
proto.service.ServiceRoles.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.service.CreateOrUpdateServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.service.CreateOrUpdateServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.service.CreateOrUpdateServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.CreateOrUpdateServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    isDismissed: (f = msg.getIsDismissed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    serviceRoles: (f = msg.getServiceRoles()) && proto.service.ServiceRoles.toObject(includeInstance, f),
    isExcluded: (f = msg.getIsExcluded()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.service.CreateOrUpdateServiceRequest}
 */
proto.service.CreateOrUpdateServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.service.CreateOrUpdateServiceRequest;
  return proto.service.CreateOrUpdateServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.service.CreateOrUpdateServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.service.CreateOrUpdateServiceRequest}
 */
proto.service.CreateOrUpdateServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsDismissed(value);
      break;
    case 3:
      var value = new proto.service.ServiceRoles;
      reader.readMessage(value,proto.service.ServiceRoles.deserializeBinaryFromReader);
      msg.setServiceRoles(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsExcluded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.service.CreateOrUpdateServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.service.CreateOrUpdateServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.service.CreateOrUpdateServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.CreateOrUpdateServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIsDismissed();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getServiceRoles();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.service.ServiceRoles.serializeBinaryToWriter
    );
  }
  f = message.getIsExcluded();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string model_id = 1;
 * @return {string}
 */
proto.service.CreateOrUpdateServiceRequest.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.service.CreateOrUpdateServiceRequest} returns this
 */
proto.service.CreateOrUpdateServiceRequest.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.BoolValue is_dismissed = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.service.CreateOrUpdateServiceRequest.prototype.getIsDismissed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.service.CreateOrUpdateServiceRequest} returns this
*/
proto.service.CreateOrUpdateServiceRequest.prototype.setIsDismissed = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.service.CreateOrUpdateServiceRequest} returns this
 */
proto.service.CreateOrUpdateServiceRequest.prototype.clearIsDismissed = function() {
  return this.setIsDismissed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.service.CreateOrUpdateServiceRequest.prototype.hasIsDismissed = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ServiceRoles service_roles = 3;
 * @return {?proto.service.ServiceRoles}
 */
proto.service.CreateOrUpdateServiceRequest.prototype.getServiceRoles = function() {
  return /** @type{?proto.service.ServiceRoles} */ (
    jspb.Message.getWrapperField(this, proto.service.ServiceRoles, 3));
};


/**
 * @param {?proto.service.ServiceRoles|undefined} value
 * @return {!proto.service.CreateOrUpdateServiceRequest} returns this
*/
proto.service.CreateOrUpdateServiceRequest.prototype.setServiceRoles = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.service.CreateOrUpdateServiceRequest} returns this
 */
proto.service.CreateOrUpdateServiceRequest.prototype.clearServiceRoles = function() {
  return this.setServiceRoles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.service.CreateOrUpdateServiceRequest.prototype.hasServiceRoles = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.BoolValue is_excluded = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.service.CreateOrUpdateServiceRequest.prototype.getIsExcluded = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.service.CreateOrUpdateServiceRequest} returns this
*/
proto.service.CreateOrUpdateServiceRequest.prototype.setIsExcluded = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.service.CreateOrUpdateServiceRequest} returns this
 */
proto.service.CreateOrUpdateServiceRequest.prototype.clearIsExcluded = function() {
  return this.setIsExcluded(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.service.CreateOrUpdateServiceRequest.prototype.hasIsExcluded = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.service.BulkCreateOrUpdateServicesRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.service.BulkCreateOrUpdateServicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.service.BulkCreateOrUpdateServicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.service.BulkCreateOrUpdateServicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.BulkCreateOrUpdateServicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    proto.service.CreateOrUpdateServiceRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.service.BulkCreateOrUpdateServicesRequest}
 */
proto.service.BulkCreateOrUpdateServicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.service.BulkCreateOrUpdateServicesRequest;
  return proto.service.BulkCreateOrUpdateServicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.service.BulkCreateOrUpdateServicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.service.BulkCreateOrUpdateServicesRequest}
 */
proto.service.BulkCreateOrUpdateServicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.service.CreateOrUpdateServiceRequest;
      reader.readMessage(value,proto.service.CreateOrUpdateServiceRequest.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.service.BulkCreateOrUpdateServicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.service.BulkCreateOrUpdateServicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.service.BulkCreateOrUpdateServicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.BulkCreateOrUpdateServicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.service.CreateOrUpdateServiceRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CreateOrUpdateServiceRequest requests = 1;
 * @return {!Array<!proto.service.CreateOrUpdateServiceRequest>}
 */
proto.service.BulkCreateOrUpdateServicesRequest.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.service.CreateOrUpdateServiceRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.service.CreateOrUpdateServiceRequest, 1));
};


/**
 * @param {!Array<!proto.service.CreateOrUpdateServiceRequest>} value
 * @return {!proto.service.BulkCreateOrUpdateServicesRequest} returns this
*/
proto.service.BulkCreateOrUpdateServicesRequest.prototype.setRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.service.CreateOrUpdateServiceRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.service.CreateOrUpdateServiceRequest}
 */
proto.service.BulkCreateOrUpdateServicesRequest.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.service.CreateOrUpdateServiceRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.service.BulkCreateOrUpdateServicesRequest} returns this
 */
proto.service.BulkCreateOrUpdateServicesRequest.prototype.clearRequestsList = function() {
  return this.setRequestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.service.CreateCustomServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.service.CreateCustomServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.service.CreateCustomServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.CreateCustomServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceRoles: (f = msg.getServiceRoles()) && proto.service.ServiceRoles.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.service.CreateCustomServiceRequest}
 */
proto.service.CreateCustomServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.service.CreateCustomServiceRequest;
  return proto.service.CreateCustomServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.service.CreateCustomServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.service.CreateCustomServiceRequest}
 */
proto.service.CreateCustomServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = new proto.service.ServiceRoles;
      reader.readMessage(value,proto.service.ServiceRoles.deserializeBinaryFromReader);
      msg.setServiceRoles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.service.CreateCustomServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.service.CreateCustomServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.service.CreateCustomServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.CreateCustomServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceRoles();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.service.ServiceRoles.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.service.CreateCustomServiceRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.service.CreateCustomServiceRequest} returns this
 */
proto.service.CreateCustomServiceRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ServiceRoles service_roles = 2;
 * @return {?proto.service.ServiceRoles}
 */
proto.service.CreateCustomServiceRequest.prototype.getServiceRoles = function() {
  return /** @type{?proto.service.ServiceRoles} */ (
    jspb.Message.getWrapperField(this, proto.service.ServiceRoles, 2));
};


/**
 * @param {?proto.service.ServiceRoles|undefined} value
 * @return {!proto.service.CreateCustomServiceRequest} returns this
*/
proto.service.CreateCustomServiceRequest.prototype.setServiceRoles = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.service.CreateCustomServiceRequest} returns this
 */
proto.service.CreateCustomServiceRequest.prototype.clearServiceRoles = function() {
  return this.setServiceRoles(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.service.CreateCustomServiceRequest.prototype.hasServiceRoles = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.service.PrimaryInfrastructureService.prototype.toObject = function(opt_includeInstance) {
  return proto.service.PrimaryInfrastructureService.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.service.PrimaryInfrastructureService} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.PrimaryInfrastructureService.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.service.PrimaryInfrastructureService}
 */
proto.service.PrimaryInfrastructureService.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.service.PrimaryInfrastructureService;
  return proto.service.PrimaryInfrastructureService.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.service.PrimaryInfrastructureService} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.service.PrimaryInfrastructureService}
 */
proto.service.PrimaryInfrastructureService.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.service.PrimaryInfrastructureService.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.service.PrimaryInfrastructureService.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.service.PrimaryInfrastructureService} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.PrimaryInfrastructureService.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.service.PrimaryInfrastructureService.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.service.PrimaryInfrastructureService} returns this
 */
proto.service.PrimaryInfrastructureService.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string model_id = 2;
 * @return {string}
 */
proto.service.PrimaryInfrastructureService.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.service.PrimaryInfrastructureService} returns this
 */
proto.service.PrimaryInfrastructureService.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.service.PrimaryInfrastructureMapping.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.service.PrimaryInfrastructureMapping.prototype.toObject = function(opt_includeInstance) {
  return proto.service.PrimaryInfrastructureMapping.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.service.PrimaryInfrastructureMapping} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.PrimaryInfrastructureMapping.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceRole: jspb.Message.getFieldWithDefault(msg, 1, 0),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.service.PrimaryInfrastructureService.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.service.PrimaryInfrastructureMapping}
 */
proto.service.PrimaryInfrastructureMapping.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.service.PrimaryInfrastructureMapping;
  return proto.service.PrimaryInfrastructureMapping.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.service.PrimaryInfrastructureMapping} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.service.PrimaryInfrastructureMapping}
 */
proto.service.PrimaryInfrastructureMapping.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.service.SERVICE_ROLE} */ (reader.readEnum());
      msg.setServiceRole(value);
      break;
    case 2:
      var value = new proto.service.PrimaryInfrastructureService;
      reader.readMessage(value,proto.service.PrimaryInfrastructureService.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.service.PrimaryInfrastructureMapping.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.service.PrimaryInfrastructureMapping.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.service.PrimaryInfrastructureMapping} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.PrimaryInfrastructureMapping.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceRole();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.service.PrimaryInfrastructureService.serializeBinaryToWriter
    );
  }
};


/**
 * optional SERVICE_ROLE service_role = 1;
 * @return {!proto.service.SERVICE_ROLE}
 */
proto.service.PrimaryInfrastructureMapping.prototype.getServiceRole = function() {
  return /** @type {!proto.service.SERVICE_ROLE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.service.SERVICE_ROLE} value
 * @return {!proto.service.PrimaryInfrastructureMapping} returns this
 */
proto.service.PrimaryInfrastructureMapping.prototype.setServiceRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated PrimaryInfrastructureService services = 2;
 * @return {!Array<!proto.service.PrimaryInfrastructureService>}
 */
proto.service.PrimaryInfrastructureMapping.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.service.PrimaryInfrastructureService>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.service.PrimaryInfrastructureService, 2));
};


/**
 * @param {!Array<!proto.service.PrimaryInfrastructureService>} value
 * @return {!proto.service.PrimaryInfrastructureMapping} returns this
*/
proto.service.PrimaryInfrastructureMapping.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.service.PrimaryInfrastructureService=} opt_value
 * @param {number=} opt_index
 * @return {!proto.service.PrimaryInfrastructureService}
 */
proto.service.PrimaryInfrastructureMapping.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.service.PrimaryInfrastructureService, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.service.PrimaryInfrastructureMapping} returns this
 */
proto.service.PrimaryInfrastructureMapping.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.service.PrimaryInfrastructureMappings.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.service.PrimaryInfrastructureMappings.prototype.toObject = function(opt_includeInstance) {
  return proto.service.PrimaryInfrastructureMappings.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.service.PrimaryInfrastructureMappings} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.PrimaryInfrastructureMappings.toObject = function(includeInstance, msg) {
  var f, obj = {
    mappingsList: jspb.Message.toObjectList(msg.getMappingsList(),
    proto.service.PrimaryInfrastructureMapping.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.service.PrimaryInfrastructureMappings}
 */
proto.service.PrimaryInfrastructureMappings.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.service.PrimaryInfrastructureMappings;
  return proto.service.PrimaryInfrastructureMappings.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.service.PrimaryInfrastructureMappings} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.service.PrimaryInfrastructureMappings}
 */
proto.service.PrimaryInfrastructureMappings.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.service.PrimaryInfrastructureMapping;
      reader.readMessage(value,proto.service.PrimaryInfrastructureMapping.deserializeBinaryFromReader);
      msg.addMappings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.service.PrimaryInfrastructureMappings.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.service.PrimaryInfrastructureMappings.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.service.PrimaryInfrastructureMappings} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.service.PrimaryInfrastructureMappings.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMappingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.service.PrimaryInfrastructureMapping.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PrimaryInfrastructureMapping mappings = 1;
 * @return {!Array<!proto.service.PrimaryInfrastructureMapping>}
 */
proto.service.PrimaryInfrastructureMappings.prototype.getMappingsList = function() {
  return /** @type{!Array<!proto.service.PrimaryInfrastructureMapping>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.service.PrimaryInfrastructureMapping, 1));
};


/**
 * @param {!Array<!proto.service.PrimaryInfrastructureMapping>} value
 * @return {!proto.service.PrimaryInfrastructureMappings} returns this
*/
proto.service.PrimaryInfrastructureMappings.prototype.setMappingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.service.PrimaryInfrastructureMapping=} opt_value
 * @param {number=} opt_index
 * @return {!proto.service.PrimaryInfrastructureMapping}
 */
proto.service.PrimaryInfrastructureMappings.prototype.addMappings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.service.PrimaryInfrastructureMapping, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.service.PrimaryInfrastructureMappings} returns this
 */
proto.service.PrimaryInfrastructureMappings.prototype.clearMappingsList = function() {
  return this.setMappingsList([]);
};


/**
 * @enum {number}
 */
proto.service.SERVICE_ROLE = {
  OTHER: 0,
  APPLICANT_TRACKING_SYSTEM: 1,
  APPICATION_LOGGING_MONITORING_AND_ALERTING: 2,
  BACKGROUND_CHECKS: 3,
  BUSINESS_PRODUCTIVITY_AND_COLLABORATION_SUITE: 4,
  CLOUD_INFRASTRUCTURE_VULNERABILITY_SCANNING: 5,
  CLOUD_MALWARE_AND_THREAT_PREVENTION: 6,
  CODE_REPOSITORY: 7,
  CODE_SCANNING_AND_ANALYSIS: 8,
  CONFIGURATION_MANAGEMENT: 9,
  CONFIGURATION_MANAGEMENT_MONITORING: 10,
  CONTRACT_MANAGEMENT: 11,
  CRM: 12,
  CUSTOMER_SUPPORT_MANAGEMENT: 13,
  EMPLOYEE_PERFORMANCE_MANAGEMENT: 14,
  HRIS: 15,
  IAM: 16,
  INCIDENT_MANAGEMENT_AND_COMMUNICATION: 17,
  INTERNAL_WIKI: 18,
  IT_REQUEST_MANAGEMENT: 19,
  MOBILE_DEVICE_MANAGEMENT: 20,
  NETWORK_PENETRATION_TESTING_SERVICE: 21,
  SECURITY_AWARENESS_TRAINING: 22,
  SOFTWARE_DEVELOPMENT_PROJECT_MANAGEMENT: 23,
  TECHNICAL_OPERATIONAL_CHANGE_MANAGEMENT: 24,
  INFRASTRUCTURE_COMPUTE: 25,
  INFRASTRUCTURE_NETWORK: 26,
  INFRASTRUCTURE_STORAGE: 27,
  INFRASTRUCTURE_DATASTORE: 28,
  CI_CD: 29,
  FILE_STORAGE: 30,
  CLOUD_PLATFORM_IAM: 31,
  APPLICATION_PLATFORM_AS_A_SERVICE: 32,
  ENDPOINT_PROTECTION_AND_ANTIVIRUS: 33,
  CLOUD_SECURITY_POSTURE_MANAGEMENT: 34,
  INTERNAL_SECURITY_EVENT_AND_INCIDENT_MANAGEMENT_COMMUNICATION: 35
};

goog.object.extend(exports, proto.service);

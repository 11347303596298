import styled, { css } from 'styled-components/macro'
import { border, color, compose, space, typography } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const SubServiceListContainer = styled.ul.attrs({
  m: 0,
  p: 0,
  borderRight: '1px solid',
  borderColor: 'border.neutral.light',
})`
  ${compose(space, border)}
  grid-column: span 4;
  display: grid;
  grid-template-columns: 1fr max-content min-content;
  grid-auto-rows: min-content;
  position: relative;
  max-height: 400px;
  overflow-y: auto;

  .grid-header {
    position: sticky;
    top: 0;
    z-index: 1;
    padding-left: 0;
  }
`

export const SubServiceItemContainer = styled.li<{ $disabled: boolean }>`
  display: contents;
  ${({ $disabled }) => {
    if (!$disabled) {
      return css`
        &:hover {
          .subservice-button {
            color: white;
            background-color: ${themeGet('colors.fill.neutral.dark')};
          }
        }

        &:active,
        &:focus {
          .subservice-button {
            color: white;
            background-color: ${themeGet('colors.fill.neutral.extraDark')};
          }
        }

        &.selected {
          .subservice-highlight {
            border-right-color: ${themeGet('colors.border.primary.dark')};
          }
        }
      `
    }
  }}
`

const SubServiceButton = styled.button.attrs({
  className: 'subservice-button',
  mb: 'xxs',
  p: 0,
  bg: 'bg.neutral',
  fontSize: '3',
})<{ disabled: boolean }>`
  ${compose(space, color, typography)}
  display: flex;
  align-items: center;
  height: 48px;
  border: none;
  text-align: left;

  :disabled {
    background-color: white;
    color: ${themeGet('colors.text.icon.disabled')};
    img {
      opacity: 0.5;
    }
  }
`

export const SubServiceName = styled(SubServiceButton).attrs({
  pl: 'm',
})`
  ${space}
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  :disabled {
    border-top: 1px solid ${themeGet('colors.border.neutral.superlight')};
    border-bottom: 1px solid ${themeGet('colors.border.neutral.superlight')};
    border-left: 1px solid ${themeGet('colors.border.neutral.superlight')};
  }

  svg,
  img {
    margin-right: 12px;
  }

  overflow: hidden;
  white-space: nowrap;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const IncludedInstances = styled(SubServiceButton).attrs({
  pr: 'm',
})`
  ${space}
  justify-content: center;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;

  :disabled {
    border-top: 1px solid ${themeGet('colors.border.neutral.superlight')};
    border-bottom: 1px solid ${themeGet('colors.border.neutral.superlight')};
    border-right: 1px solid ${themeGet('colors.border.neutral.superlight')};
  }
`

export const SubServiceHighlight = styled.div.attrs({
  className: 'subservice-highlight',
  ml: 'xxxs',
  mb: 'xxs',
  p: 0,
})<{
  /** The position of this specific SubService */
  position: number
  /** The position of the active SubService */
  activePosition: number
}>`
  ${compose(space)}
  border-right: 4px solid transparent;
  transform: ${({ position, activePosition }) => {
    return position === activePosition
      ? 'translateY(0%)'
      : `translateY(${100 * (activePosition - position)}%)`
  }};
  transition: transform 0.25s linear;
`

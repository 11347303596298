import {
  AUDIT_RESULT,
  ControlTest,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'

export type AuditBotControlChecksType = {
  modelId: string
  name: string
  checks: ControlTest[]
  isNotApplicable?: boolean
}

interface ControlResultCountConfig {
  count: number
  text: string
  key?: AUDIT_RESULT
}

export type ControlResultCountsType = {
  [AUDIT_RESULT.PASS]: ControlResultCountConfig
  [AUDIT_RESULT.FAIL]: ControlResultCountConfig
  [AUDIT_RESULT.NOT_APPLICABLE]: ControlResultCountConfig
  [AUDIT_RESULT.UNKNOWN]: ControlResultCountConfig
  [AUDIT_RESULT.MISSING_SUGGESTIONS]: ControlResultCountConfig
} & { SUCCESSFUL: ControlResultCountConfig }

// TODO: Move this enum to Ntrced side to ensure we always use the proper values and avoid duplicate enums/constants
export enum CONTROL_TEST_NAMES {
  POLICY_MATCH = 'Control Matches Policy',
  COMPLETENESS = 'Completeness',
  ONBOARDING = 'Onboarding',
  OFFBOARDING = 'Offboarding',
  ONGOING_EMPLOYEES = 'Ongoing Employees',
  SPOT_CHECK = 'Spot Check',
}

export const PASSED_CONTROL_CHECK_TEXT = Object.freeze({
  [CONTROL_TEST_NAMES.POLICY_MATCH]:
    'This control is required by and effectively supports the parent policy',
  [CONTROL_TEST_NAMES.COMPLETENESS]:
    'This control should have all evidence relevant to it',
  [CONTROL_TEST_NAMES.ONBOARDING]:
    'Control requirements fulfilled for all new employees during this period',
  [CONTROL_TEST_NAMES.OFFBOARDING]:
    'Offboarded employees track across control requirement',
  [CONTROL_TEST_NAMES.ONGOING_EMPLOYEES]:
    'Employee list matches control requirement',
  [CONTROL_TEST_NAMES.SPOT_CHECK]: 'Evidence passes required tests',
})

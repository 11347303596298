import React from 'react'
import ContentLoader from 'react-content-loader'
import { NoResults } from 'src/components/PageLayout/NoResults'
import { Grid } from 'src/components/Reusable/Grid'
import { Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { NoFilterResults } from 'src/components/PageLayout/NoFilterResults'
import { useAnalytics } from 'src/analytics/useAnalytics'
import { useRisks, useRisksRequest } from '../risks.hooks'
import { AddRiskModalBtn } from '../risks.components'
import { NoRisksIcon, RiskGridContainer } from '../risks.styles'
import { RisksGridHeader } from '../grid/RisksGridHeader'
import { RiskGridRows } from '../grid/RisksGridRow'

export const RiskGrid = ({ riskIds }: { riskIds: string[] }): JSX.Element => {
  return (
    <Grid
      pb="xs"
      gridTemplateColumns="min-content min-content minmax(auto, 30%) repeat(6, auto)"
    >
      <RisksGridHeader />
      <RiskGridRows riskIds={riskIds} />
    </Grid>
  )
}

export const RisksIndexGrid = (): JSX.Element => {
  const { track, events } = useAnalytics()
  const req = useRisksRequest()
  const { data, isLoading } = useRisks(req)

  if (isLoading || !data) {
    return <ContentLoader />
  }

  const totalRisks = data.getTotal()
  const riskIds = data.getRisksList().map((risk: Risk) => risk.getId())

  // Empty State if no risks present in account
  if (totalRisks === 0) {
    return (
      <RiskGridContainer>
        <NoResults
          icons={<NoRisksIcon />}
          title="Your Risk Register is empty."
          buttons={
            <AddRiskModalBtn
              text="Add Risk Scenarios"
              handleClick={() => track(events.C_ADD_MANUAL_RISK)}
            />
          }
        />
      </RiskGridContainer>
    )
  }

  return (
    <>
      {riskIds.length > 0 ? (
        <Grid
          pb="xs"
          gridTemplateColumns="min-content min-content minmax(auto, 30%) repeat(6, auto)"
        >
          <RisksGridHeader />
          <RiskGridRows riskIds={riskIds} />
        </Grid>
      ) : (
        <NoFilterResults />
      )}
    </>
  )
}

import React, { useMemo } from 'react'
import { ReceptorRecord } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import AccordionBody from 'react-bootstrap/AccordionBody'
import { ListInstancesRequest } from '@trustero/trustero-api-web/lib/model/service_instance_pb'
import { ServiceInstancePromiseClient } from '@trustero/trustero-api-web/lib/model/service_instance_grpc_web_pb'
import { CUSTOM_RECEPTOR_ID } from 'src/Utils/Receptors/receptors.constants'
import { CustomReceptorDetails } from 'src/pages/Receptors/receptors.components'
import { Tabs, TabType } from '../../../components/Reusable/Tabs'
import { useSwrImmutableGrpc } from '../../../components/async/useSwrImmutableGrpc'
import { Receptor } from '../../../xgenerated'
import { ReceptorSubServices } from './ReceptorSubServices'
import { AutomatedControls } from './ReceptorsPage.components'

export const ReceptorBody = ({
  receptor,
  receptorTemplate,
  isSelected,
}: {
  receptor: ReceptorRecord.AsObject
  receptorTemplate: Receptor
  isSelected: boolean
}): JSX.Element => {
  const request2 = useMemo(() => {
    return new ListInstancesRequest()
      .setServiceIdsList(receptorTemplate.service_ids)
      .setReceptorId(receptor.id)
  }, [receptor.id, receptorTemplate.service_ids])

  const { response } = useSwrImmutableGrpc(
    ServiceInstancePromiseClient.prototype.listIds,
    request2,
  )
  if (!isSelected) return <></>

  let tabsToShow: TabType[] = [
    {
      title: 'Sub Services',
      toolTipBody:
        'A SaaS vendor may offer many sub services.  Each service you’re using for service delivery should be included.  In addition, you can exclude instances of a service that’s not used for service delivery.  For example, S3 buckets used for developer experimentation should be excluded.',
      body: (
        <ReceptorSubServices
          receptorId={receptor.id}
          serviceIds={receptorTemplate.service_ids}
        />
      ),
      disabled: !receptor.iscredvalid,
    },
  ]

  // This code checks if a receptor has only 1 service with 0 instances.
  // Does not show the Sub Services tab if true
  if (receptorTemplate.service_ids.length === 1) {
    if (!response?.data?.getIdsList().length) {
      tabsToShow = []
    }
  }

  if (receptor.modelid !== CUSTOM_RECEPTOR_ID) {
    tabsToShow.push({
      title: 'Automatable Controls',
      toolTipBody:
        'The receptor will generate evidence for this list of controls.',
      body: (
        <AutomatedControls modelIds={receptor.automatedcontrolsList ?? []} />
      ),
    })
  } else {
    // AP-4734: Custom Receptors should only show Custom Receptor Config Tab
    tabsToShow = [
      {
        title: 'Custom Receptor Config',
        toolTipBody: 'Configuration details for your custom receptor.',
        body: <CustomReceptorDetails receptorId={receptor.id} />,
      },
    ]
  }

  return (
    <AccordionBody>
      <Tabs
        tabs={tabsToShow}
        defaultActiveKey={receptor.iscredvalid ? '0' : '1'}
      />
    </AccordionBody>
  )
}

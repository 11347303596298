import React from 'react'
import { ServiceInstancePromiseClient } from '@trustero/trustero-api-web/lib/model/service_instance_grpc_web_pb'
import { SetInstanceDismissRequest } from '@trustero/trustero-api-web/lib/model/service_instance_pb'
import { useAuthorizedGrpcClient } from '../../../../adapter'
import { ServiceInstance } from '../index'
import { CheckBoxInput } from '../../../Reusable/Inputs/CheckBox'
import { Spinner } from '../../../../Throbber'

type Props = {
  id: string
}

export function InstanceScopeCheckbox(external: Props): JSX.Element {
  const client = useAuthorizedGrpcClient(ServiceInstancePromiseClient)

  return (
    <ServiceInstance
      id={external.id}
      placeholder={<Spinner size="s" color="primary" />}
      child={({ instance, mutate }) => (
        <CheckBoxInput
          checked={!instance.getDismissed()}
          onChange={async () => {
            await client.setDismiss(
              new SetInstanceDismissRequest()
                .setId(instance.getId())
                .setDismissed(!instance.getDismissed()),
            )
            await mutate()
          }}
        />
      )}
    />
  )
}

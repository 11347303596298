import React, {
  FormEventHandler,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react'
import { isWebUri } from 'valid-url'
import utf8 from 'utf8'
import { Range } from 'react-date-range'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import isString from 'lodash/isString'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { FlexAlign, FlexRow } from 'src/components/Reusable/Flex'
import { HUBSPOT } from 'src/Utils/hubspot/hubspot.utils'
import { ExternalLink } from 'src/components/Reusable/Text/Link'
import palette from 'src/designSystem/variables/palette'
import { P } from 'src/components/Reusable/Text/Text.styles'
import { Spinner } from '../../../../Throbber'
import { AuditContext } from '../../../../context/AuditContext'
import { dateToTimestamp } from '../../../../Utils/formatDate'
import { dateFormatter } from '../../../../Utils/formatDate'
import { ModalForm, ModalFormId, ModalFormIdQueryParam } from '../../ModalForm'
import { useAudit } from '../../../async/model'
import { TrusteroDateRange } from '../../../Reusable/TrusteroDateRange'
import { useCreateEvidence } from '../../../async/suggestions/useCreateEvidence'
import { showInfoToast, ToastPrompts } from '../../../../Utils/helpers/toast'
import { useBulkCreateEvidence } from '../../../async/evidence/useBulkCreateEvidence'
import { TestIds } from '../../../../Utils/testIds'
import { MIME_TYPE } from '../../../../Utils/globalEnums'
import {
  EvidenceDetailsContainer,
  FilesLoadingContainer,
  Label,
  AddEvidenceFormForm,
  AddEvidenceError,
  UploadInfo,
  UploadInfoSection,
} from './AddEvidenceForm.styles'
import {
  AddEvidenceFormDescription,
  CaptionTextInput,
  EvidenceFiles,
  EvidenceText,
  EvidenceTypeSelector,
} from './AddEvidenceForm.components'

export type EvidenceFormData = {
  mime: string
  body: string | File
}

export enum AddEvidenceErrorType {
  CAPTION = 'caption',
  DATE = 'date',
  EVIDENCE = 'evidence',
}

type AddEvidenceErrors = {
  captionError: string
  dateError: string
  evidenceError: string
}

export enum EvidenceType {
  NULL = 'Null',
  TEXT = 'Text',
  LINK = 'Link',
  FILE = 'File',
}

export const AddEvidenceQueryParams = {
  MODAL_FORM_ID: ModalFormIdQueryParam,
  CONTROL_ID: 'controlId',
  REQUEST_ID: 'requestId',
}

type AddEvidenceFormProps = {
  modelId?: string
  controlId?: string
  requestId?: string
  timeRange?: TimeRange
  name: string
} & (
  | { requestId: string; controlId?: never; modelId?: never }
  | { controlId: string; modelId: string; requestId?: never }
)
/**
  Modal form to add evidence
 ** MUST HAVE Either a requestId or a modelId
 * Update 5/15/23 - We need BOTH the model id and the control id when adding evidence to a control. Otherwise, use the request id.
 */
export const AddEvidenceForm = ({
  modelId,
  controlId,
  requestId,
  timeRange,
  name,
}: AddEvidenceFormProps): JSX.Element => {
  const { auditId } = useContext(AuditContext)
  const { data } = useAudit(auditId ?? '')
  const createEvidence = useCreateEvidence({
    controlId,
    requestId,
    timeRange,
  })
  const bulkCreateEvidence = useBulkCreateEvidence({
    controlId,
    modelId,
    requestId,
    timeRange,
  })
  const auditStart = useMemo(
    (): Date | false => data?.getStartDate()?.toDate() || false,
    [data],
  )
  const auditEnd = useMemo(
    (): Date | false => data?.getEndDate()?.toDate() || false,
    [data],
  )
  const getEndDate = useMemo((): Date => {
    let date: Date = new Date()
    if (auditStart && auditEnd) {
      if (auditEnd < date) {
        date = auditEnd
      }
      if (auditStart > date) {
        date = auditStart
      }
    }
    return date
  }, [auditStart, auditEnd])

  const [range, setRange] = useState<Range>({
    startDate: new Date(),
    endDate: getEndDate,
  })

  const [errors, setErrors] = useState<AddEvidenceErrors>({
    captionError: '',
    dateError: '',
    evidenceError: '',
  })
  const captionErrorMessage = 'Please enter a caption.'
  const isError = useCallback(
    (field: string, error: boolean) => {
      switch (field) {
        case AddEvidenceErrorType.CAPTION:
          if (error) {
            return setErrors((state) => {
              const newState = {
                ...state,
                captionError: captionErrorMessage,
              }
              return newState
            })
          } else {
            return setErrors((state) => {
              const newState = {
                ...state,
                captionError: '',
              }
              return newState
            })
          }
        case AddEvidenceErrorType.DATE:
          if (error) {
            return setErrors((state) => {
              const newState = {
                ...state,
                dateError: `Select a date within the range of your current audit so evidence is included in this audit. This audit's date range: ${dateFormatter(
                  auditStart,
                  false,
                )} to ${dateFormatter(auditEnd, false)}`,
              }
              return newState
            })
          } else {
            return setErrors((state) => {
              const newState = {
                ...state,
                dateError: '',
              }
              return newState
            })
          }
        case AddEvidenceErrorType.EVIDENCE:
          if (error) {
            return setErrors((state) => {
              const newState = {
                ...state,
                evidenceError:
                  'Please ensure total size of upload is 100 MB or less',
              }
              return newState
            })
          } else {
            return setErrors((state) => {
              const newState = {
                ...state,
                evidenceError: '',
              }
              return newState
            })
          }
        default:
          return null
      }
    },
    [auditEnd, auditStart],
  )
  const [loadingFile, setLoadingFile] = useState<boolean>(false)

  const [formData, formDataSet] = useState<EvidenceFormData>({
    mime: '',
    body: '',
  })
  const [formDataList, formDataListSet] = useState<EvidenceFormData[]>([])
  const [caption, setCaption] = useState<string>('')
  const [uploadSize, setUploadSize] = useState<number>(0)
  const updateUploadSize = (totalSizeInMB: number) => {
    setUploadSize(totalSizeInMB)
  }
  const createCaption = (captionString: string) => setCaption(captionString)

  const submitRef = useRef<HTMLButtonElement>(null)

  const [type, typeSet] = useState<EvidenceType>(EvidenceType.NULL)
  const updateEvidence = (mime: MIME_TYPE, type: EvidenceType) => {
    formDataSet({
      mime,
      body: '',
    })
    typeSet(type)
  }
  const updateEvidenceFiles = (
    files: EvidenceFormData[],
    type: EvidenceType,
  ) => {
    formDataListSet(files)
    typeSet(type)
  }
  useEffect(() => {
    if (submitRef?.current) {
      submitRef.current.disabled = formDataList.some(
        (formData) =>
          !formData.body ||
          (type === EvidenceType.LINK && !isWebUri(formData.body as string)),
      )
    }
  }, [formDataList, type])

  useEffect(() => {
    if (auditStart && range?.endDate) {
      if (auditEnd < range.endDate || auditStart > range.endDate) {
        isError('date', true)
      } else {
        isError('date', false)
      }
    }
  }, [range, isError, auditStart, auditEnd])

  // TODO: Check everywhere this form is used and make sure it is working for the correct control
  const show = useIsShowModal(ModalFormId.ADD_EVIDENCE)
  const hideModal = useHideModal({ modalId: ModalFormId.ADD_EVIDENCE })
  const hide = useCallback(() => {
    // Clear the form
    formDataSet({
      mime: '',
      body: '',
    })
    formDataListSet([])
    setCaption('')
    setErrors({
      captionError: '',
      dateError: '',
      evidenceError: '',
    })
    setRange({
      startDate: new Date(),
      endDate: getEndDate,
    })
    setLoadingFile(false)
    // Clear the type
    typeSet(EvidenceType.NULL)
    // Close the modal
    hideModal()
  }, [getEndDate, hideModal])

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()
      if (!caption.length) {
        return setErrors((state) => {
          const newState = {
            ...state,
            captionError: captionErrorMessage,
          }
          return newState
        })
      } else if (errors.dateError || errors.evidenceError) {
        return null
      }
      if (!formDataList.length && !formData.body) {
        return null
      }
      setLoadingFile(true)

      const date = dateToTimestamp(range.endDate)
      if (type === EvidenceType.FILE) {
        await bulkCreateEvidence(formDataList, caption, date, uploadSize)
      } else {
        const { mime, body } = formData
        // Convert string into utf8 encoded codes
        const bodyBytes = new Uint8Array(
          isString(body)
            ? Array.from(utf8.encode(body.trim())).map((s) => s.charCodeAt(0))
            : [],
        )
        // If this fails the user will get a toast
        await createEvidence({
          mime,
          bodyBytes,
          caption: `${caption.trim()}`,
          relevantDate: date,
        })
      }

      // Clear state and close modal
      return hide()
    },
    [
      bulkCreateEvidence,
      createEvidence,
      formDataList,
      formData,
      type,
      caption,
      hide,
      errors,
      range.endDate,
      uploadSize,
    ],
  )

  const numFiles = useMemo(() => {
    return formDataList.length
  }, [formDataList])

  useEffect(() => {
    // If the modal is open and we don't have either a requestId or a modelId then uploading evidence won't work correctly.
    if (!requestId && !modelId && show) {
      hide()
      showInfoToast(ToastPrompts.SOMETHING_WRONG)
    }
  }, [requestId, modelId, hide, show])

  return (
    <ModalForm
      show={show}
      hide={hide}
      formId={ModalFormId.ADD_EVIDENCE}
      title={`Add Evidence for ${name}`}
      description={<AddEvidenceFormDescription />}
      submitRef={submitRef}
      submitText={'Add Evidence'}
      enforceFocus={false}
      hideButtons={loadingFile}
    >
      {loadingFile ? (
        <FilesLoadingContainer>
          <Spinner
            data-testid={TestIds.SPINNER}
            color={'primary'}
            size={'xl'}
          />
          Processing upload
          {type === EvidenceType.FILE && (
            <UploadInfoSection>
              <UploadInfo>
                {`${numFiles} file${numFiles > 1 ? 's' : ''}`}
              </UploadInfo>
              <UploadInfo>{`${uploadSize} MB`}</UploadInfo>
            </UploadInfoSection>
          )}
        </FilesLoadingContainer>
      ) : (
        <AddEvidenceFormForm id={ModalFormId.ADD_EVIDENCE} onSubmit={onSubmit}>
          <CaptionTextInput
            label="Caption"
            name="caption"
            initVal={caption}
            captionError={errors.captionError}
            isError={isError}
            placeholder="Add Caption for Evidence"
            form={ModalFormId.ADD_EVIDENCE}
            createCaption={createCaption}
          />
          <AddEvidenceError showError={!!errors.captionError}>
            {errors.captionError}
          </AddEvidenceError>
          <br />
          <FlexRow
            justify={FlexAlign.FLEX_START}
            align={FlexAlign.CENTER}
            gap={6}
          >
            <Label>
              Relevant Date. Useful when you&apos;re adding evidence outside the
              audit period.
            </Label>
            <ExternalLink
              href={HUBSPOT.RELEVANT_DATE_DOCUMENTATION}
              text="Learn more"
            />
          </FlexRow>
          <TrusteroDateRange range={range} rangeSet={setRange} single={true} />
          <AddEvidenceError showError={!!errors.dateError}>
            {errors.dateError}
          </AddEvidenceError>
          <br />
          <EvidenceDetailsContainer>
            <Label>Document Details</Label>
            {type === EvidenceType.LINK && (
              <P $color={palette.orange['900']} $isBold>
                Currently Audit Scan can only evaluate evidence that is stored
                in the platform. If you store your evidence somewhere else that
                you link to, audit scan will not be able to evaluate it.
              </P>
            )}
            <EvidenceTypeSelector
              {...{
                updateEvidence,
                updateEvidenceFiles,
                type,
                isError,
                updateUploadSize,
              }}
            />
            <EvidenceFiles {...{ formDataList, type }} />
            <EvidenceText
              {...{
                formData,
                setFormData: formDataSet,
                formId: ModalFormId.ADD_EVIDENCE,
                type,
              }}
            />
          </EvidenceDetailsContainer>
          <AddEvidenceError showError={!!errors.evidenceError}>
            {errors.evidenceError}
          </AddEvidenceError>
        </AddEvidenceFormForm>
      )}
    </ModalForm>
  )
}

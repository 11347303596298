import { useCallback, useMemo } from 'react'
import isBoolean from 'lodash/isBoolean'
import { BoolValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { AuditBotPromiseClient } from '@trustero/trustero-api-web/lib/audit/auditbot_grpc_web_pb'
import { RoadmapServicePromiseClient } from '@trustero/trustero-api-web/lib/roadmap/roadmap_grpc_web_pb'
import {
  GetInitialScopingRequest,
  GetInitialScopingResponse,
  GetLiveAuditRunsResponse,
  GetOrCreateComplianceRoadmapRequest,
  GetOrCreateComplianceRoadmapResponse,
  ToggleRequiredDocumentsRequest,
} from '@trustero/trustero-api-web/lib/roadmap/roadmap_pb'
import { GrpcResponse, NewGrpcResponse } from 'src/components/async/hooks/types'
import { useSwrImmutableGrpc } from 'src/components/async/useSwrImmutableGrpc'
import { useInAudit } from 'src/context/AuditContext'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'
import {
  GetAuditReadinessRequest,
  GetAuditReadinessResponse,
  GetSmartChecksTestInformationRequest,
  GetSmartChecksTestInformationResponse,
} from '@trustero/trustero-api-web/lib/audit/auditbot_pb'
import { useGrpcRevalidateByMethod } from 'src/components/async/useGrpcMutate'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import { CONTROL_TEST_NAMES } from '../AuditBot/accordion/subsection/ControlChecks/AuditBotControlChecks.constants'
import { PREPARE_CONTENT_TESTS, RoadmapIndexConfig } from './roadmap.constants'
import { getRoadmapWidgetRequestConfig } from './widgets/RoadmapWidgets.helpers'
import { ROADMAP_WIDGET_LOCATION } from './widgets/RoadmapWidgets.constants'
import { useRoadmapWidgetCounts } from './widgets/RoadmapWidgets.hooks'
import {
  getRoadMapIndexRowsConfig,
  handleRoadmapErrors,
} from './roadmap.helpers'

export const useInitialScoping =
  (): GrpcResponse<GetInitialScopingResponse> => {
    const { auditId } = useInAudit()
    const request = new GetInitialScopingRequest()
    auditId && request.setAuditId(auditId)

    const { response } = useSwrImmutableGrpc(
      RoadmapServicePromiseClient.prototype.getInitialScoping,
      request,
      true,
    )

    return NewGrpcResponse(response)
  }

/**
 * Get the current compliance_roadmap for the audit or create one if it doesn't exist
 *
 * @param auditId: <string> the audit id
 * @returns <GrpcResponse<GetOrCreateComplianceRoadmapResponse>> which contains a Roadamp message
 */
export const useFindOrCreateRoadmap = (
  auditId?: string,
): GrpcResponse<GetOrCreateComplianceRoadmapResponse> => {
  const req = useMemo(
    () => new GetOrCreateComplianceRoadmapRequest().setAuditId(auditId || ''),
    [auditId],
  )
  const { response } = useSwrImmutableGrpc(
    RoadmapServicePromiseClient.prototype.getOrCreateComplianceRoadmap,
    req,
  )
  return NewGrpcResponse(response)
}

/**
 * Get the list of control tests in an audit period and the associated controls with pass/fail status
 *
 * @param auditId: <string> the audit id
 * @returns <GrpcResponse<GetSmartChecksTestInformationResponse>> which contains a map of control tests to applicable controls
 */
export const useSmartChecks = (
  auditId?: string,
  controlTests?: string[],
): GrpcResponse<GetSmartChecksTestInformationResponse> => {
  const req = useMemo(
    () =>
      new GetSmartChecksTestInformationRequest()
        .setAuditId(auditId || '')
        .setControlTestsList(controlTests || []),
    [auditId, controlTests],
  )
  const { response } = useSwrImmutableGrpc(
    AuditBotPromiseClient.prototype.getSmartChecksTestInformation,
    req,
  )
  return NewGrpcResponse(response)
}

/**
 * Get the list of controls in an audit period and the associated control tests with pass/fail status
 * This is essentially the inverse of the useSmartChecks hook
 *
 * @param auditId: <string> the audit id
 * @returns: <GrpcResponse<GetAuditReadinessResponse>> which contains a map of controls to applicable control tests
 */
export const useAuditReadiness = (
  auditId?: string,
  relevantTests?: CONTROL_TEST_NAMES[],
): GrpcResponse<GetAuditReadinessResponse> => {
  const req = useMemo(
    () => new GetAuditReadinessRequest().setAuditId(auditId || ''),
    [auditId],
  )
  if (relevantTests) {
    req.setControlTestsList(relevantTests)
  }
  const { response } = useSwrImmutableGrpc(
    AuditBotPromiseClient.prototype.getAuditReadiness,
    req,
  )
  return NewGrpcResponse(response)
}

export const useControlChecks = (
  request: GetAuditReadinessRequest,
  auditId?: string,
  shouldFetch = true,
): GrpcResponse<GetAuditReadinessResponse> => {
  if (auditId) {
    request.setAuditId(auditId)
  }

  request.setControlTestsList([
    CONTROL_TEST_NAMES.POLICY_MATCH,
    CONTROL_TEST_NAMES.COMPLETENESS,
    CONTROL_TEST_NAMES.SPOT_CHECK,
  ])

  const { response } = useSwrImmutableGrpc(
    AuditBotPromiseClient.prototype.getAuditReadiness,
    request,
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useHasAuditReadiness = ({
  auditId,
  relevantTests,
}: {
  auditId?: string
  relevantTests?: CONTROL_TEST_NAMES[]
}): boolean => {
  const { data, isLoading } = useAuditReadiness(auditId, relevantTests)
  // return true while loading or if there is no data
  // this prevents masking the loading state
  if (isLoading || !data) {
    return true
  }
  const records = data?.getAuditReadinessList()
  const hasAuditReadiness = records?.reduce((acc, record) => {
    if (record.getControlTestsList().length > 0) {
      return true
    }
    return acc
  }, false)
  return hasAuditReadiness || false
}

/**
 * Update the compliance_roadmap row approvals based on user input
 *
 * @param auditId : <string> the audit id
 * @returns: <void>
 */
export const useToggleRequiredDocuments = (
  auditId?: string,
): (({
  hasSupportingDocs,
  approvedSupportingDocs,
  policiesApproved,
}: {
  hasSupportingDocs?: boolean
  approvedSupportingDocs?: boolean
  policiesApproved?: boolean
}) => Promise<void>) => {
  const client = useAuthorizedGrpcClient(RoadmapServicePromiseClient)
  const mutate = useGrpcRevalidateByMethod()

  const toggleRequiredDocs = useCallback(
    async ({
      hasSupportingDocs,
      approvedSupportingDocs,
      policiesApproved,
    }: {
      hasSupportingDocs?: boolean
      approvedSupportingDocs?: boolean
      policiesApproved?: boolean
    }) => {
      const req = new ToggleRequiredDocumentsRequest().setAuditId(auditId || '')
      isBoolean(hasSupportingDocs) &&
        req.setHasSupportingDocuments(
          new BoolValue().setValue(hasSupportingDocs),
        )
      isBoolean(approvedSupportingDocs) &&
        req.setApprovedSupportDocuments(
          new BoolValue().setValue(approvedSupportingDocs),
        )
      isBoolean(policiesApproved) &&
        req.setPoliciesApproved(new BoolValue().setValue(policiesApproved))
      await client.toggleRequiredDocuments(req)
      await mutate(
        RoadmapServicePromiseClient.prototype.getOrCreateComplianceRoadmap,
      )
    },
    [auditId, client, mutate],
  )

  return toggleRequiredDocs
}

export const useRoadmapIndexRowsConfig = (
  auditId?: string,
  isSoc2 = false,
): RoadmapIndexConfig => {
  const { data: initialScopingData } = useInitialScoping()
  const prepareContentConfig = getRoadmapWidgetRequestConfig({
    appLocation: ROADMAP_WIDGET_LOCATION.PREPARE_CONTENT,
    auditId,
    isSoc2,
  })
  const operationalizeConfig = getRoadmapWidgetRequestConfig({
    appLocation: ROADMAP_WIDGET_LOCATION.OPERATIONALIZE,
    auditId,
    isSoc2,
  })
  const handleRequestsConfig = getRoadmapWidgetRequestConfig({
    appLocation: ROADMAP_WIDGET_LOCATION.HANDLE_REQUESTS,
    auditId,
    isSoc2,
  })
  const { data: prepareContentWidgetData, error: prepareContentError } =
    useRoadmapWidgetCounts({
      auditId,
      requestConfig: prepareContentConfig,
    })
  const { data: operationalizeWidgetData, error: operationalizeError } =
    useRoadmapWidgetCounts({
      auditId,
      requestConfig: operationalizeConfig,
    })
  const { data: handleRequestsWidgetData, error: handleRequestsError } =
    useRoadmapWidgetCounts({
      auditId,
      requestConfig: handleRequestsConfig,
    })
  const { data: roadmapToggleData, error: roadmapToggleError } =
    useFindOrCreateRoadmap(auditId)
  // TODO: Update when we add more tests
  const { data: smartChecksData, error: smartCheckError } = useSmartChecks(
    auditId,
    PREPARE_CONTENT_TESTS,
  )
  const { data: auditReadinessData, error: auditReadinessError } =
    useAuditReadiness(auditId)

  handleRoadmapErrors({
    auditId,
    prepareContentError,
    operationalizeError,
    handleRequestsError,
    roadmapToggleError,
    smartCheckError,
    auditReadinessError,
  })

  return getRoadMapIndexRowsConfig({
    initialScopingData,
    prepareContentWidgetData,
    operationalizeWidgetData,
    handleRequestsWidgetData,
    roadmapToggleData,
    smartChecksData,
    auditReadinessData,
  })
}

/**
 * Grab the live audit runs for a given audit & compliance roadmap
 * Used to indicate whether a scan is running on a given control or control test in the roadmap accordions
 *
 * We will only fetch data if a valid ID is passed in (i.e. we are in an audit)
 */
export const useLiveAuditRuns = (): GrpcResponse<GetLiveAuditRunsResponse> => {
  const { response } = useSwrImmutableGrpc(
    RoadmapServicePromiseClient.prototype.getLiveAuditRuns,
    new Empty(),
  )
  return NewGrpcResponse(response)
}

/**
 * @fileoverview gRPC-Web generated client stub for agent
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: recommend/recommend.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var model_control_pb = require('../model/control_pb.js')

var common_model_pb = require('../common/model_pb.js')
const proto = {};
proto.agent = require('./recommend_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.agent.RecommendClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.agent.RecommendPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.agent.ListReportsReply>}
 */
const methodDescriptor_Recommend_ListReports = new grpc.web.MethodDescriptor(
  '/agent.Recommend/ListReports',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.agent.ListReportsReply,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ListReportsReply.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ListReportsReply)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ListReportsReply>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.listReports =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/ListReports',
      request,
      metadata || {},
      methodDescriptor_Recommend_ListReports,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ListReportsReply>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.listReports =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/ListReports',
      request,
      metadata || {},
      methodDescriptor_Recommend_ListReports);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.GetReportRequest,
 *   !proto.agent.ReportSummary>}
 */
const methodDescriptor_Recommend_GetReport = new grpc.web.MethodDescriptor(
  '/agent.Recommend/GetReport',
  grpc.web.MethodType.UNARY,
  proto.agent.GetReportRequest,
  proto.agent.ReportSummary,
  /**
   * @param {!proto.agent.GetReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ReportSummary.deserializeBinary
);


/**
 * @param {!proto.agent.GetReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReportSummary)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReportSummary>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.getReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/GetReport',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetReport,
      callback);
};


/**
 * @param {!proto.agent.GetReportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReportSummary>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.getReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/GetReport',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.PDFDocument,
 *   !proto.agent.AddReportResponse>}
 */
const methodDescriptor_Recommend_AddReport = new grpc.web.MethodDescriptor(
  '/agent.Recommend/AddReport',
  grpc.web.MethodType.UNARY,
  proto.agent.PDFDocument,
  proto.agent.AddReportResponse,
  /**
   * @param {!proto.agent.PDFDocument} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.AddReportResponse.deserializeBinary
);


/**
 * @param {!proto.agent.PDFDocument} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.AddReportResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.AddReportResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.addReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/AddReport',
      request,
      metadata || {},
      methodDescriptor_Recommend_AddReport,
      callback);
};


/**
 * @param {!proto.agent.PDFDocument} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.AddReportResponse>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.addReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/AddReport',
      request,
      metadata || {},
      methodDescriptor_Recommend_AddReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.UpdateReportRequest,
 *   !proto.agent.ReportSummary>}
 */
const methodDescriptor_Recommend_UpdateReport = new grpc.web.MethodDescriptor(
  '/agent.Recommend/UpdateReport',
  grpc.web.MethodType.UNARY,
  proto.agent.UpdateReportRequest,
  proto.agent.ReportSummary,
  /**
   * @param {!proto.agent.UpdateReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ReportSummary.deserializeBinary
);


/**
 * @param {!proto.agent.UpdateReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReportSummary)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReportSummary>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.updateReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/UpdateReport',
      request,
      metadata || {},
      methodDescriptor_Recommend_UpdateReport,
      callback);
};


/**
 * @param {!proto.agent.UpdateReportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReportSummary>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.updateReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/UpdateReport',
      request,
      metadata || {},
      methodDescriptor_Recommend_UpdateReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.RestoreOriginalReportValueRequest,
 *   !proto.agent.ReportSummary>}
 */
const methodDescriptor_Recommend_RestoreOriginalReportValue = new grpc.web.MethodDescriptor(
  '/agent.Recommend/RestoreOriginalReportValue',
  grpc.web.MethodType.UNARY,
  proto.agent.RestoreOriginalReportValueRequest,
  proto.agent.ReportSummary,
  /**
   * @param {!proto.agent.RestoreOriginalReportValueRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ReportSummary.deserializeBinary
);


/**
 * @param {!proto.agent.RestoreOriginalReportValueRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReportSummary)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReportSummary>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.restoreOriginalReportValue =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/RestoreOriginalReportValue',
      request,
      metadata || {},
      methodDescriptor_Recommend_RestoreOriginalReportValue,
      callback);
};


/**
 * @param {!proto.agent.RestoreOriginalReportValueRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReportSummary>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.restoreOriginalReportValue =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/RestoreOriginalReportValue',
      request,
      metadata || {},
      methodDescriptor_Recommend_RestoreOriginalReportValue);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.DeleteReportRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Recommend_DeleteReport = new grpc.web.MethodDescriptor(
  '/agent.Recommend/DeleteReport',
  grpc.web.MethodType.UNARY,
  proto.agent.DeleteReportRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.agent.DeleteReportRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.agent.DeleteReportRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.deleteReport =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/DeleteReport',
      request,
      metadata || {},
      methodDescriptor_Recommend_DeleteReport,
      callback);
};


/**
 * @param {!proto.agent.DeleteReportRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.deleteReport =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/DeleteReport',
      request,
      metadata || {},
      methodDescriptor_Recommend_DeleteReport);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.agent.CSOCS>}
 */
const methodDescriptor_Recommend_GetCSOCS = new grpc.web.MethodDescriptor(
  '/agent.Recommend/GetCSOCS',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.agent.CSOCS,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.CSOCS.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.CSOCS)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.CSOCS>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.getCSOCS =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/GetCSOCS',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetCSOCS,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.CSOCS>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.getCSOCS =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/GetCSOCS',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetCSOCS);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.common.Identifier,
 *   !proto.agent.SuggestedReceptors>}
 */
const methodDescriptor_Recommend_GetSuggestedReceptor = new grpc.web.MethodDescriptor(
  '/agent.Recommend/GetSuggestedReceptor',
  grpc.web.MethodType.UNARY,
  common_model_pb.Identifier,
  proto.agent.SuggestedReceptors,
  /**
   * @param {!proto.common.Identifier} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.SuggestedReceptors.deserializeBinary
);


/**
 * @param {!proto.common.Identifier} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.SuggestedReceptors)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.SuggestedReceptors>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.getSuggestedReceptor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/GetSuggestedReceptor',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetSuggestedReceptor,
      callback);
};


/**
 * @param {!proto.common.Identifier} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.SuggestedReceptors>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.getSuggestedReceptor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/GetSuggestedReceptor',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetSuggestedReceptor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.SuggestedDLRReceptorRequest,
 *   !proto.agent.SuggestedAutomatedEvidence>}
 */
const methodDescriptor_Recommend_GetSuggestedReceptorForDLR = new grpc.web.MethodDescriptor(
  '/agent.Recommend/GetSuggestedReceptorForDLR',
  grpc.web.MethodType.UNARY,
  proto.agent.SuggestedDLRReceptorRequest,
  proto.agent.SuggestedAutomatedEvidence,
  /**
   * @param {!proto.agent.SuggestedDLRReceptorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.SuggestedAutomatedEvidence.deserializeBinary
);


/**
 * @param {!proto.agent.SuggestedDLRReceptorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.SuggestedAutomatedEvidence)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.SuggestedAutomatedEvidence>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.getSuggestedReceptorForDLR =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/GetSuggestedReceptorForDLR',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetSuggestedReceptorForDLR,
      callback);
};


/**
 * @param {!proto.agent.SuggestedDLRReceptorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.SuggestedAutomatedEvidence>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.getSuggestedReceptorForDLR =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/GetSuggestedReceptorForDLR',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetSuggestedReceptorForDLR);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.ImportControlsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Recommend_ImportControls = new grpc.web.MethodDescriptor(
  '/agent.Recommend/ImportControls',
  grpc.web.MethodType.UNARY,
  proto.agent.ImportControlsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.agent.ImportControlsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.agent.ImportControlsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.importControls =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/ImportControls',
      request,
      metadata || {},
      methodDescriptor_Recommend_ImportControls,
      callback);
};


/**
 * @param {!proto.agent.ImportControlsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.importControls =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/ImportControls',
      request,
      metadata || {},
      methodDescriptor_Recommend_ImportControls);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.SuggestedEvidenceRequest,
 *   !proto.agent.SuggestedEvidenceRecord>}
 */
const methodDescriptor_Recommend_CreateSuggestedEvidence = new grpc.web.MethodDescriptor(
  '/agent.Recommend/CreateSuggestedEvidence',
  grpc.web.MethodType.UNARY,
  proto.agent.SuggestedEvidenceRequest,
  proto.agent.SuggestedEvidenceRecord,
  /**
   * @param {!proto.agent.SuggestedEvidenceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.SuggestedEvidenceRecord.deserializeBinary
);


/**
 * @param {!proto.agent.SuggestedEvidenceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.SuggestedEvidenceRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.SuggestedEvidenceRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.createSuggestedEvidence =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/CreateSuggestedEvidence',
      request,
      metadata || {},
      methodDescriptor_Recommend_CreateSuggestedEvidence,
      callback);
};


/**
 * @param {!proto.agent.SuggestedEvidenceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.SuggestedEvidenceRecord>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.createSuggestedEvidence =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/CreateSuggestedEvidence',
      request,
      metadata || {},
      methodDescriptor_Recommend_CreateSuggestedEvidence);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.SuggestedTestRequest,
 *   !proto.agent.SuggestedTestRecord>}
 */
const methodDescriptor_Recommend_CreateSuggestedTest = new grpc.web.MethodDescriptor(
  '/agent.Recommend/CreateSuggestedTest',
  grpc.web.MethodType.UNARY,
  proto.agent.SuggestedTestRequest,
  proto.agent.SuggestedTestRecord,
  /**
   * @param {!proto.agent.SuggestedTestRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.SuggestedTestRecord.deserializeBinary
);


/**
 * @param {!proto.agent.SuggestedTestRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.SuggestedTestRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.SuggestedTestRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.createSuggestedTest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/CreateSuggestedTest',
      request,
      metadata || {},
      methodDescriptor_Recommend_CreateSuggestedTest,
      callback);
};


/**
 * @param {!proto.agent.SuggestedTestRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.SuggestedTestRecord>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.createSuggestedTest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/CreateSuggestedTest',
      request,
      metadata || {},
      methodDescriptor_Recommend_CreateSuggestedTest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.HowToHandleItRequest,
 *   !proto.agent.HowToHandleItRecord>}
 */
const methodDescriptor_Recommend_CreateHowToHandleIt = new grpc.web.MethodDescriptor(
  '/agent.Recommend/CreateHowToHandleIt',
  grpc.web.MethodType.UNARY,
  proto.agent.HowToHandleItRequest,
  proto.agent.HowToHandleItRecord,
  /**
   * @param {!proto.agent.HowToHandleItRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.HowToHandleItRecord.deserializeBinary
);


/**
 * @param {!proto.agent.HowToHandleItRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.HowToHandleItRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.HowToHandleItRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.createHowToHandleIt =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/CreateHowToHandleIt',
      request,
      metadata || {},
      methodDescriptor_Recommend_CreateHowToHandleIt,
      callback);
};


/**
 * @param {!proto.agent.HowToHandleItRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.HowToHandleItRecord>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.createHowToHandleIt =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/CreateHowToHandleIt',
      request,
      metadata || {},
      methodDescriptor_Recommend_CreateHowToHandleIt);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.GetSuggestedRequest,
 *   !proto.agent.SuggestedEvidenceResponse>}
 */
const methodDescriptor_Recommend_GetSuggestedEvidenceFromTable = new grpc.web.MethodDescriptor(
  '/agent.Recommend/GetSuggestedEvidenceFromTable',
  grpc.web.MethodType.UNARY,
  proto.agent.GetSuggestedRequest,
  proto.agent.SuggestedEvidenceResponse,
  /**
   * @param {!proto.agent.GetSuggestedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.SuggestedEvidenceResponse.deserializeBinary
);


/**
 * @param {!proto.agent.GetSuggestedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.SuggestedEvidenceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.SuggestedEvidenceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.getSuggestedEvidenceFromTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/GetSuggestedEvidenceFromTable',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetSuggestedEvidenceFromTable,
      callback);
};


/**
 * @param {!proto.agent.GetSuggestedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.SuggestedEvidenceResponse>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.getSuggestedEvidenceFromTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/GetSuggestedEvidenceFromTable',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetSuggestedEvidenceFromTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.GetSuggestedRequest,
 *   !proto.agent.SuggestedTestResponse>}
 */
const methodDescriptor_Recommend_GetSuggestedTestFromTable = new grpc.web.MethodDescriptor(
  '/agent.Recommend/GetSuggestedTestFromTable',
  grpc.web.MethodType.UNARY,
  proto.agent.GetSuggestedRequest,
  proto.agent.SuggestedTestResponse,
  /**
   * @param {!proto.agent.GetSuggestedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.SuggestedTestResponse.deserializeBinary
);


/**
 * @param {!proto.agent.GetSuggestedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.SuggestedTestResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.SuggestedTestResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.getSuggestedTestFromTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/GetSuggestedTestFromTable',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetSuggestedTestFromTable,
      callback);
};


/**
 * @param {!proto.agent.GetSuggestedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.SuggestedTestResponse>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.getSuggestedTestFromTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/GetSuggestedTestFromTable',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetSuggestedTestFromTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.GetSuggestedRequest,
 *   !proto.agent.HowToHandleItResponse>}
 */
const methodDescriptor_Recommend_GetHowToHandleItFromTable = new grpc.web.MethodDescriptor(
  '/agent.Recommend/GetHowToHandleItFromTable',
  grpc.web.MethodType.UNARY,
  proto.agent.GetSuggestedRequest,
  proto.agent.HowToHandleItResponse,
  /**
   * @param {!proto.agent.GetSuggestedRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.HowToHandleItResponse.deserializeBinary
);


/**
 * @param {!proto.agent.GetSuggestedRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.HowToHandleItResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.HowToHandleItResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.getHowToHandleItFromTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/GetHowToHandleItFromTable',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetHowToHandleItFromTable,
      callback);
};


/**
 * @param {!proto.agent.GetSuggestedRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.HowToHandleItResponse>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.getHowToHandleItFromTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/GetHowToHandleItFromTable',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetHowToHandleItFromTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.GetOperationalizationRequest,
 *   !proto.agent.OperationalizationResponse>}
 */
const methodDescriptor_Recommend_GetSuggestedOperationalizationFromTable = new grpc.web.MethodDescriptor(
  '/agent.Recommend/GetSuggestedOperationalizationFromTable',
  grpc.web.MethodType.UNARY,
  proto.agent.GetOperationalizationRequest,
  proto.agent.OperationalizationResponse,
  /**
   * @param {!proto.agent.GetOperationalizationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.OperationalizationResponse.deserializeBinary
);


/**
 * @param {!proto.agent.GetOperationalizationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.OperationalizationResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.OperationalizationResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.getSuggestedOperationalizationFromTable =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/GetSuggestedOperationalizationFromTable',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetSuggestedOperationalizationFromTable,
      callback);
};


/**
 * @param {!proto.agent.GetOperationalizationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.OperationalizationResponse>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.getSuggestedOperationalizationFromTable =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/GetSuggestedOperationalizationFromTable',
      request,
      metadata || {},
      methodDescriptor_Recommend_GetSuggestedOperationalizationFromTable);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.ToggleSuggestionsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Recommend_ToggleSuggestions = new grpc.web.MethodDescriptor(
  '/agent.Recommend/ToggleSuggestions',
  grpc.web.MethodType.UNARY,
  proto.agent.ToggleSuggestionsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.agent.ToggleSuggestionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.agent.ToggleSuggestionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.toggleSuggestions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/ToggleSuggestions',
      request,
      metadata || {},
      methodDescriptor_Recommend_ToggleSuggestions,
      callback);
};


/**
 * @param {!proto.agent.ToggleSuggestionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.toggleSuggestions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/ToggleSuggestions',
      request,
      metadata || {},
      methodDescriptor_Recommend_ToggleSuggestions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.UpdateSuggestedTestsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Recommend_UpdateSuggestedTests = new grpc.web.MethodDescriptor(
  '/agent.Recommend/UpdateSuggestedTests',
  grpc.web.MethodType.UNARY,
  proto.agent.UpdateSuggestedTestsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.agent.UpdateSuggestedTestsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.agent.UpdateSuggestedTestsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.updateSuggestedTests =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/UpdateSuggestedTests',
      request,
      metadata || {},
      methodDescriptor_Recommend_UpdateSuggestedTests,
      callback);
};


/**
 * @param {!proto.agent.UpdateSuggestedTestsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.updateSuggestedTests =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/UpdateSuggestedTests',
      request,
      metadata || {},
      methodDescriptor_Recommend_UpdateSuggestedTests);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.ResetSuggestedTestsRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Recommend_ResetSuggestedTests = new grpc.web.MethodDescriptor(
  '/agent.Recommend/ResetSuggestedTests',
  grpc.web.MethodType.UNARY,
  proto.agent.ResetSuggestedTestsRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.agent.ResetSuggestedTestsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.agent.ResetSuggestedTestsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.resetSuggestedTests =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/ResetSuggestedTests',
      request,
      metadata || {},
      methodDescriptor_Recommend_ResetSuggestedTests,
      callback);
};


/**
 * @param {!proto.agent.ResetSuggestedTestsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.resetSuggestedTests =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/ResetSuggestedTests',
      request,
      metadata || {},
      methodDescriptor_Recommend_ResetSuggestedTests);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.UpdateSuggestedEvidenceRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Recommend_UpdateSuggestedEvidence = new grpc.web.MethodDescriptor(
  '/agent.Recommend/UpdateSuggestedEvidence',
  grpc.web.MethodType.UNARY,
  proto.agent.UpdateSuggestedEvidenceRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.agent.UpdateSuggestedEvidenceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.agent.UpdateSuggestedEvidenceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.updateSuggestedEvidence =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/UpdateSuggestedEvidence',
      request,
      metadata || {},
      methodDescriptor_Recommend_UpdateSuggestedEvidence,
      callback);
};


/**
 * @param {!proto.agent.UpdateSuggestedEvidenceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.updateSuggestedEvidence =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/UpdateSuggestedEvidence',
      request,
      metadata || {},
      methodDescriptor_Recommend_UpdateSuggestedEvidence);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.ResetSuggestedEvidenceRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Recommend_ResetSuggestedEvidence = new grpc.web.MethodDescriptor(
  '/agent.Recommend/ResetSuggestedEvidence',
  grpc.web.MethodType.UNARY,
  proto.agent.ResetSuggestedEvidenceRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.agent.ResetSuggestedEvidenceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.agent.ResetSuggestedEvidenceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.resetSuggestedEvidence =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/ResetSuggestedEvidence',
      request,
      metadata || {},
      methodDescriptor_Recommend_ResetSuggestedEvidence,
      callback);
};


/**
 * @param {!proto.agent.ResetSuggestedEvidenceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.resetSuggestedEvidence =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/ResetSuggestedEvidence',
      request,
      metadata || {},
      methodDescriptor_Recommend_ResetSuggestedEvidence);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.agent.ListSuggestionsRequest,
 *   !proto.agent.ListSuggestionsResponse>}
 */
const methodDescriptor_Recommend_ListSuggestionsForAccount = new grpc.web.MethodDescriptor(
  '/agent.Recommend/ListSuggestionsForAccount',
  grpc.web.MethodType.UNARY,
  proto.agent.ListSuggestionsRequest,
  proto.agent.ListSuggestionsResponse,
  /**
   * @param {!proto.agent.ListSuggestionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.agent.ListSuggestionsResponse.deserializeBinary
);


/**
 * @param {!proto.agent.ListSuggestionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ListSuggestionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ListSuggestionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.agent.RecommendClient.prototype.listSuggestionsForAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/agent.Recommend/ListSuggestionsForAccount',
      request,
      metadata || {},
      methodDescriptor_Recommend_ListSuggestionsForAccount,
      callback);
};


/**
 * @param {!proto.agent.ListSuggestionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ListSuggestionsResponse>}
 *     Promise that resolves to the response
 */
proto.agent.RecommendPromiseClient.prototype.listSuggestionsForAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/agent.Recommend/ListSuggestionsForAccount',
      request,
      metadata || {},
      methodDescriptor_Recommend_ListSuggestionsForAccount);
};


module.exports = proto.agent;


import React, { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import {
  RouteTitle,
  ShowPageBackArrow,
  ShowPageHeaderButtons,
  ShowPageHeaderContainer,
  ShowPageHeaderTitles,
} from 'src/components/PageLayout/ShowPage'
import { FlexAlign, FlexColumn, FlexRow } from 'src/components/Reusable/Flex'
import {
  ROOT_ID_PARAM_TYPE,
  RisksAbsoluteRoutes,
} from 'src/components/Reusable/RootPage/RootPage.constants'
import { PrevNextButtons } from 'src/components/Reusable/prevNext'
import { StatusDropdownContainer } from 'src/components/Status/styles'
import {
  ChangelogButton,
  PermalinkButton,
} from 'src/components/Reusable/Buttons'
import { BASE_ROUTES } from 'src/components/Reusable/prevNext/usePrevNext'
import {
  generatePermalink,
  linkWithSearch,
} from 'src/components/PageLayout/Permalink'
import { StatusDropdown } from 'src/components/Status'
import { ShowPageTitle } from 'src/components/Reusable/ShowPage/Title/ShowPageTitle'
import { ShowPageHeader } from 'src/components/PageLayout/ShowPage/showpage.components'
import { useRisks, useRisksRequest } from '../risks.hooks'
import { StatusContainer } from '../risks.styles'

export const RisksShowPageHeader = ({ risk }: { risk: Risk }): JSX.Element => {
  const params = useParams()
  const riskRequest = useRisksRequest()
  const { data, isLoading } = useRisks(riskRequest)

  const riskName = risk.getName()
  const riskCustomId = risk.getCustomId()
  const riskStatus = risk.getStatus()
  const riskId = risk.getId()

  const permalinkUrl = useMemo(() => {
    return generatePermalink({
      pageContext: params.pageContext as string,
      modelId: riskId,
      modelType: MODEL_TYPE.RISK,
      isInternalLink: false,
    })
  }, [params.pageContext, riskId])

  if (isLoading || !data || !risk) {
    return <></>
  }
  const customRisksIds = data
    .getRisksList()
    .map((risk: Risk) => risk.getCustomId())

  const breadcrumb = linkWithSearch(
    `../../../${RisksAbsoluteRoutes.INDEX_PAGE}`,
  )

  return (
    <ShowPageHeaderContainer>
      <ShowPageHeader>
        <ShowPageHeaderTitles>
          <RouteTitle>
            <FlexRow
              isFullWidth
              align={FlexAlign.CENTER}
              justify={FlexAlign.SPACE_BETWEEN}
            >
              <Link to={breadcrumb}>
                <ShowPageBackArrow icon={faArrowLeft} />
                Risks
              </Link>
            </FlexRow>
          </RouteTitle>
          <ShowPageTitle
            id={riskId}
            name={riskName}
            customId={riskCustomId}
            model={risk}
            modelType={MODEL_TYPE.RISK}
          />
        </ShowPageHeaderTitles>
        <ShowPageHeaderButtons>
          <FlexColumn gap={22}>
            <PrevNextButtons
              baseRoute={BASE_ROUTES.RISKS}
              idArray={customRisksIds}
              param={ROOT_ID_PARAM_TYPE.RISK_ID}
            />
            <StatusContainer gap={16} isFullWidth justify={FlexAlign.FLEX_END}>
              <StatusDropdownContainer>
                <StatusDropdown
                  model={risk}
                  modelId={riskId}
                  modelType={MODEL_TYPE.RISK}
                  isDismissed={false}
                  status={riskStatus}
                />
              </StatusDropdownContainer>
              <ChangelogButton subjectId={riskId} />
              <PermalinkButton isPermalink permalinkUrl={permalinkUrl} />
            </StatusContainer>
          </FlexColumn>
        </ShowPageHeaderButtons>
      </ShowPageHeader>
    </ShowPageHeaderContainer>
  )
}

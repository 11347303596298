import { useEffect, useRef, useState } from 'react'
import { TYPEWRITER_INTERVAL } from './globalConstants'

export const useTypewriter = ({
  text,
  interval = TYPEWRITER_INTERVAL,
  shouldLoop,
}: {
  text: string
  interval?: number
  shouldLoop?: boolean
}): string => {
  const [currentPosition, setCurrentPosition] = useState<number>(0)
  const currentPositionRef = useRef<number>(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      currentPositionRef.current += 1
      setCurrentPosition((prev) => prev + 1)
      if (currentPositionRef.current > text.length) {
        if (shouldLoop) {
          setCurrentPosition(0)
          currentPositionRef.current = 0
        } else {
          clearInterval(intervalId)
        }
      }
    }, interval)
    return () => {
      clearInterval(intervalId)
      setCurrentPosition(0)
      currentPositionRef.current = 0
    }
  }, [text, interval, shouldLoop])

  return text.substring(0, currentPosition)
}

import React, { PropsWithChildren } from 'react'
import styled from 'styled-components/macro'
import { color, compose, grid, layout, textStyle } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import palette from 'src/designSystem/variables/palette'
import {
  IconButton,
  IconButtonVariant,
  IconButtonProps,
} from '../Reusable/Buttons/IconButton'
import { EroDarkSVG } from '../Icons/Brand'
import {
  headerContentHeight,
  mainNavWidth,
  subPageNavHeight,
} from './dimensionUtils'

export const PageContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  background-color: white;
  scroll-margin-left: ${mainNavWidth}px;
`

export const PageHeaderStyle = styled.header<{
  showBottomBorder?: boolean
  isAIPowered?: boolean
}>`
  position: sticky;
  top: 0;
  z-index: 4;
  background-color: white;
  border-bottom: ${({ showBottomBorder, isAIPowered }) =>
    isAIPowered
      ? `1px solid ${palette.purple[200]}`
      : showBottomBorder
      ? '1px solid #acb3c3'
      : 'none'};
`

export const MainHeaderContent = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: ${headerContentHeight}px;
  padding-top: ${themeGet('space.xl')}px;
  padding-left: ${themeGet('space.l')}px;
  padding-bottom: ${themeGet('space.l')}px;
  padding-right: ${themeGet('space.l')}px;
`

export const AIHeaderContent = styled(MainHeaderContent)`
  background: linear-gradient(
    270deg,
    ${palette.purple[600]} 0%,
    ${palette.purple[50]} 18.5%
  );
`

export const AICopilotIcon = styled(EroDarkSVG)<{ $isLarge?: boolean }>`
  width: ${({ $isLarge }) => ($isLarge ? '100px' : '32px')};
  height: ${({ $isLarge }) => ($isLarge ? '100px' : '32px')};
`
export const TrusteroLabsRow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
export const TrusteroLabsIndexRow = styled(TrusteroLabsRow)`
  padding: 8px 20px 0px 20px;
  position: sticky;
  top: ${headerContentHeight + 1}px; // 1px to account for header border
  z-index: 4;
  background-color: white;
  height: ${subPageNavHeight - 1}px; // 1px to account for header border
`

export const AICopilotText = styled.div<{
  useDarkText?: boolean
  isLarge?: boolean
}>`
  color: ${({ useDarkText }) =>
    useDarkText ? palette.neutral[900] : palette.neutral.white};
  ${({ useDarkText }) => useDarkText && 'font-weight: 400;'}
  font-size: ${({ isLarge }) => (isLarge ? '50px' : '16px')};
`

export const HeaderText = styled.div`
  display: inline-flex;
  margin-right: 15px;
  text-align: left;
  word-break: break-word;
`

export const HeaderTitle = styled.h1`
  display: inline;
  margin: 0;
  padding-right: ${themeGet('space.xs')}px;
  font-size: 24px;
  font-weight: 500;
  text-align: left;
`

export const HeaderDescription = styled.span`
  font-size: 16px;
  font-weight: 400;
  text-align: left;
`

export const HeaderTextBorder = styled.span`
  height: 100%;
  margin: 0 15px;
  border-left: 1px solid #00db9d;
`

export const HeaderBorder = styled.div`
  border-bottom: 1px solid #cbd4de;
`

export const PageContent = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: #1f2532;
  font-size: 18px;
  margin-bottom: 20px;
`

export const SubpageNav = styled.nav.attrs<
  PropsWithChildren<{ [key: string]: unknown }>
>((props) => ({
  children: <ul>{props.children}</ul>,
}))`
  padding: 0 0 0 12px;
  margin: 0;
  border-bottom: 1px solid #acb3c3;
  height: ${subPageNavHeight}px;

  ul {
    display: flex;
    margin: 0;
    padding: 0;
  }
`

export const SubpageNavButtonContainer = styled.section`
  display: flex;
  flex-grow: 1;
  justify-content: right;
`

export const SubpageNavItemContainer = styled.li`
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 4px 4px 0 0;
  overflow-y: visible;
  ${themeGet('textStyles.subtitle.1')}

  a {
    display: flex;
    align-items: center;
    color: ${themeGet('colors.text.med')};
    text-decoration: none;
    padding-left: ${themeGet('space.m')}px;
    padding-right: ${themeGet('space.m')}px;
    height: 40px;
    border-bottom: 4px solid transparent;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;

    :hover {
      color: ${themeGet('colors.text.med')};
      background-color: ${themeGet('colors.bg.primary')};
      border-bottom-color: ${themeGet('colors.border.primary.base')};
    }
    &.active:not(.inactive) {
      border-bottom-color: ${themeGet('colors.border.primary.dark')};
      color: ${themeGet('colors.text.default')};
      font-weight: ${themeGet('fontWeights.semiBold')};
      .sub-nav-item-number {
        background-color: ${themeGet('colors.border.primary.dark')};
      }
    }
  }
`

export const PageItem = styled.div<React.CSSProperties>`
  ${layout};
  display: grid;
  grid-template-columns: 1fr 360px;
  grid-auto-rows: auto;
  ${grid};
  column-gap: 20px;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: #ffffff;
  border-bottom: 1px solid ${themeGet('colors.border.neutral.light')};
`

export const Summary = styled.div`
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
`

export const Sidebar = styled.div`
  grid-column: 2;
  grid-row: 1;
  display: flex;
  flex-direction: column;
`

export const SideBarButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  margin-bottom: 3px;
  padding-right: 12px;
`

export const SideBarButton = styled(IconButton).attrs({
  variant: IconButtonVariant.icon,
})<Omit<IconButtonProps, 'variant'>>`
  :not(:first-child) {
    margin-left: 20px;
  }
`

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const TagSection = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const TagStyle = styled.div`
  position: relative;
  max-width: max-content;
  margin-top: 2px;
  margin-right: 2px;
  padding: 3px 8px;
  border-radius: 2px;
  border: 1px solid #cbd2de;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  background-color: #ffffff;
`

export const TagContent = styled.p<{ color?: string; textStyle?: string }>`
  ${compose(color, textStyle)}
  max-width: 150px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`
export const ActionRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const ActionRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
`

import {
  EvidenceGenerators,
  GetModelEvidenceGeneratorRequest,
  ListConnectedEvidenceGeneratorsRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  Identifier,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { useCallback } from 'react'
import log from 'loglevel'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useGrpcRevalidateByMethod } from '../useGrpcMutate'
import { useAuthorizedGrpcClient } from '../../../adapter'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'
import { useInvalidateEvidence } from '../../../Utils/swrCacheInvalidation'

export const useEvidenceGenerators = (
  modelType: MODEL_TYPE,
  controlId: string,
  connected: boolean,
  shouldFetch = true,
): GrpcResponse<EvidenceGenerators> => {
  const identifier = new Identifier()
    .setModeltype(modelType)
    .setModelid(controlId)
  const { response } = useSwrImmutableGrpc(
    AttachmentPromiseClient.prototype.listConnectedEvidenceGenerators,
    new ListConnectedEvidenceGeneratorsRequest()
      .setIdentifier(identifier)
      .setConnected(connected),
    shouldFetch,
  )
  return NewGrpcResponse(response)
}

export const useInvalidateEvidenceGeneratorsCache =
  (): (() => Promise<void>) => {
    const mutateFunc = useGrpcRevalidateByMethod()

    return useCallback(async () => {
      await mutateFunc(
        AttachmentPromiseClient.prototype.listConnectedEvidenceGenerators,
      )
    }, [mutateFunc])
  }

export const useAddEvidenceGenerator = (): ((
  evidenceGeneratorId: string,
  controlId: string,
) => Promise<void>) => {
  const client = useAuthorizedGrpcClient(AttachmentPromiseClient)
  const invalidateEvidenceGeneratorsCache =
    useInvalidateEvidenceGeneratorsCache()
  // We might need to update this with the request id to get the evidence cache to invalidate correctly on the request show page
  const invalidateEvidenceCache = useInvalidateEvidence({})
  return async (evidenceGeneratorId: string, controlId: string) => {
    try {
      const response = await client.getOrCreateModelEvidenceGenerator(
        new GetModelEvidenceGeneratorRequest()
          .setEvidenceGeneratorId(evidenceGeneratorId)
          .setControlModelIdentifier(
            new Identifier()
              .setModelid(controlId)
              .setModeltype(MODEL_TYPE.CONTROL),
          ),
      )
      await client.updateModelEvidenceGenerator(response.setIsEnabled(true))
    } catch (e) {
      log.error('Error creating evidence generator', e)
    } finally {
      await invalidateEvidenceGeneratorsCache()
      await invalidateEvidenceCache()
    }
  }
}

import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { ListInstances } from '../../../../../components/async/model'
import { Spinner } from '../../../../../Throbber'
import { useContent } from '../../../../../context/hooks'
import { Service } from '../../../../../xgenerated'
import {
  IncludedInstances,
  SubServiceHighlight,
  SubServiceItemContainer,
  SubServiceName,
} from './SubServiceList.styles'

export function SubServiceItem({
  receptorId,
  serviceId,
  selectedServiceId,
  setSelectedServiceId,
  disabled,
  position,
  activePosition,
  setActivePosition,
}: {
  receptorId: string
  serviceId: string
  selectedServiceId: string | undefined
  setSelectedServiceId: Dispatch<SetStateAction<string | undefined>>
  disabled: boolean
  position: number
  activePosition: number
  setActivePosition: Dispatch<SetStateAction<number>>
}): JSX.Element {
  const content = useContent()
  const getService = (id: string) => content.idIndex[id] as unknown as Service

  const className = useMemo(
    () => (serviceId === selectedServiceId ? 'selected' : ''),
    [serviceId, selectedServiceId],
  )

  return (
    <SubServiceItemContainer
      className={className}
      $disabled={disabled}
      onClick={(e) => {
        e.preventDefault()
        if (disabled) return
        setSelectedServiceId(serviceId)
        setActivePosition(position)
      }}
    >
      <SubServiceName disabled={disabled}>
        {getService(serviceId).logo({
          width: '24px',
          height: '24px',
        })}
        <p>{getService(serviceId).name}</p>
      </SubServiceName>
      <IncludedInstances disabled={disabled}>
        <ListInstances
          receptorId={receptorId}
          placeholder={<Spinner size="s" color="primary" />}
          modelIds={[serviceId]}
          dismissed={false}
          child={({ instances }) => <>{disabled ? 0 : instances.length}</>}
        />
      </IncludedInstances>
      <SubServiceHighlight
        position={position}
        activePosition={activePosition}
      />
    </SubServiceItemContainer>
  )
}

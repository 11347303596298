import React, { ReactNode } from 'react'
import {
  filterBarHeight,
  headerHeight,
} from 'src/components/PageLayout/dimensionUtils'
import styled from 'styled-components/macro'
import { GridColumn, GridHeader, GridItemProps } from './Grid'

type CenteredGridColumnProps = {
  children: ReactNode
} & GridItemProps

export const CenteredGridColumn = ({
  ...props
}: CenteredGridColumnProps): JSX.Element => (
  <GridColumn {...props} color="text.default" justifyContent="center">
    {props.children}
  </GridColumn>
)

export const NoPaddingGridColumn = styled(GridColumn)`
  padding: 0px;
`

export const StickyGridHeader = styled(GridHeader).attrs({
  top: headerHeight + filterBarHeight,
})`
  position: sticky;
  z-index: 1;
`

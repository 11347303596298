import React, { useContext, useMemo, useState } from 'react'
import { PolicyRecord } from '@trustero/trustero-api-web/lib/model/policy_pb'

type CurrentPolicy = {
  currentPolicy: PolicyRecord.AsObject
  setCurrentPolicy: React.Dispatch<React.SetStateAction<PolicyRecord.AsObject>>
}

const CurrentPolicyContext = React.createContext<CurrentPolicy>({
  currentPolicy: new PolicyRecord().toObject(),
  setCurrentPolicy: () => new PolicyRecord().toObject(),
})

export const useCurrentPolicy = (): CurrentPolicy =>
  useContext(CurrentPolicyContext)

export const CurrentPolicyContextProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [currentPolicy, setCurrentPolicy] = useState<PolicyRecord.AsObject>(
    new PolicyRecord().toObject(),
  )

  const policyData: CurrentPolicy = useMemo(
    () => ({
      currentPolicy,
      setCurrentPolicy,
    }),
    [currentPolicy, setCurrentPolicy],
  )
  return (
    <CurrentPolicyContext.Provider value={policyData}>
      {children}
    </CurrentPolicyContext.Provider>
  )
}

import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
} from 'react'
import { ComplianceFramework } from '@trustero/trustero-api-web/lib/audit/framework_pb'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import { PolicyRecord } from '@trustero/trustero-api-web/lib/model/policy_pb'
import { useModalState, useSetActiveModal } from 'src/Modal/ModalStateContext'
import { ModalFormId } from '../../components/ModalForms/ModalForm'
import { useControlsForComplianceFramework } from '../../components/async/complianceframework/useControlsForComplianceFramework'
import { usePoliciesForComplianceFramework } from '../../components/async/complianceframework/usePoliciesForComplianceFramework'
import { useControlsInAccountForComplianceFramework } from '../../components/async/complianceframework/useControlsInAccountForComplianceFramework'
import { usePoliciesInAccountForComplianceFramework } from '../../components/async/complianceframework/usePoliciesInAccountForComplianceFramework'

interface FrameworkWizard {
  frameworkState: {
    framework: ComplianceFramework | null
    setFramework: React.Dispatch<
      React.SetStateAction<ComplianceFramework | null>
    >
  }
  controlsState: {
    suggestedControls: Control[]
    setSuggestedControls: React.Dispatch<React.SetStateAction<Control[]>>
    selectedControls: string[]
    setSelectedControls: React.Dispatch<React.SetStateAction<string[]>>
    controlsInAccount: Control[]
    setControlsInAccount: React.Dispatch<React.SetStateAction<Control[]>>
  }
  policiesState: {
    suggestedPolicies: PolicyRecord[]
    setSuggestedPolicies: React.Dispatch<React.SetStateAction<PolicyRecord[]>>
    selectedPolicies: string[]
    setSelectedPolicies: React.Dispatch<React.SetStateAction<string[]>>
    policiesInAccount: PolicyRecord[]
    setPoliciesInAccount: React.Dispatch<React.SetStateAction<PolicyRecord[]>>
  }
  loadingState: {
    loading: boolean
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
  }
}

export const FrameworkWizardContext = createContext<FrameworkWizard>({
  frameworkState: {
    framework: null,
    setFramework: () => null,
  },
  controlsState: {
    suggestedControls: [],
    setSuggestedControls: () => null,
    selectedControls: [],
    setSelectedControls: () => null,
    controlsInAccount: [],
    setControlsInAccount: () => null,
  },
  policiesState: {
    suggestedPolicies: [],
    setSuggestedPolicies: () => null,
    selectedPolicies: [],
    setSelectedPolicies: () => null,
    policiesInAccount: [],
    setPoliciesInAccount: () => null,
  },
  loadingState: {
    loading: true,
    setLoading: () => null,
  },
})

export const useFrameworkWizard = (): FrameworkWizard => {
  const startingModalFormId = ModalFormId.CHOOSE_COMPLIANCE_FRAMEWORK
  const frameworkWizardState = useContext(FrameworkWizardContext)
  const {
    frameworkState: { framework },
    controlsState: {
      setSuggestedControls,
      setControlsInAccount,
      controlsInAccount,
    },
    policiesState: {
      setSuggestedPolicies,
      setPoliciesInAccount,
      policiesInAccount,
    },
    loadingState: { loading, setLoading },
  } = frameworkWizardState

  // ------- Getting data for the wizard once we have the framework --------
  // controls for framework already in the account
  const controlsInAccountResponse = useControlsInAccountForComplianceFramework(
    framework?.getModelId() || '',
  )
  useEffect(() => {
    setControlsInAccount(controlsInAccountResponse.data?.getItemsList() || [])
  }, [setControlsInAccount, controlsInAccountResponse.data])

  // suggested controls to add
  const controlsResponse = useControlsForComplianceFramework(
    framework?.getModelId() || '',
  )
  useEffect(() => {
    setSuggestedControls(controlsResponse.data?.getItemsList() || [])
  }, [setSuggestedControls, controlsResponse.data])

  // policies for framework already in the account
  const policiesInAccountResponse = usePoliciesInAccountForComplianceFramework(
    framework?.getModelId() || '',
  )
  useEffect(() => {
    setPoliciesInAccount(policiesInAccountResponse.data?.getItemsList() || [])
  }, [setPoliciesInAccount, policiesInAccountResponse.data])

  // suggested policies to add
  const policiesResponse = usePoliciesForComplianceFramework(
    framework?.getModelId() || '',
  )
  useEffect(() => {
    setSuggestedPolicies(policiesResponse.data?.getItemsList() || [])
  }, [setSuggestedPolicies, policiesResponse.data])

  // --------
  // -------- Controlling the loading state ----------
  useEffect(() => {
    if (
      controlsInAccountResponse.loading ||
      controlsResponse.loading ||
      policiesResponse.loading ||
      policiesInAccountResponse.loading
    ) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [
    controlsInAccountResponse.loading,
    controlsResponse.loading,
    policiesResponse.loading,
    policiesInAccountResponse.loading,
    setLoading,
    framework,
  ])
  // -------
  // --------- Routing based on data in the wizard -------
  const { activeModal: currentModalFormId } = useModalState()
  const inFrameworkWizard = useMemo(() => {
    const frameworkWizardModalFormIds = [
      ModalFormId.SELECT_CONTROLS,
      ModalFormId.REUSABLE_CONTROLS,
      ModalFormId.SELECT_POLICIES,
      ModalFormId.REUSABLE_POLICIES,
      ModalFormId.CONFIRM_FRAMEWORK,
    ]
    return frameworkWizardModalFormIds.some(
      (modalFormId) => modalFormId === currentModalFormId,
    )
  }, [currentModalFormId])

  const navigateToStartingModal = useSetActiveModal(startingModalFormId)
  const navigateToSelectControlsModal = useSetActiveModal(
    ModalFormId.SELECT_CONTROLS,
  )
  const navigateToSelectPoliciesModal = useSetActiveModal(
    ModalFormId.SELECT_POLICIES,
  )

  useEffect(() => {
    // If we don't have a framework and the user is in the wizard we need to route them back to the start of the wizard
    if (inFrameworkWizard && framework === null) {
      return navigateToStartingModal()
    } else if (
      !loading &&
      controlsInAccountResponse.data &&
      policiesInAccountResponse.data
    ) {
      // If the user navigate to the Reusable controls modal but has no reusable controls we need to navigate them to the next modal
      if (
        currentModalFormId === ModalFormId.REUSABLE_CONTROLS &&
        !controlsInAccount.length
      ) {
        return navigateToSelectControlsModal()
      }
      // If the user navigate to the Reusable policies modal but has no reusable controls we need to navigate them to the next modal
      if (
        currentModalFormId === ModalFormId.REUSABLE_POLICIES &&
        !policiesInAccount.length
      ) {
        return navigateToSelectPoliciesModal()
      }
    } else {
      // Default case (avoid deepsource warning for no return)
      return
    }
  }, [
    inFrameworkWizard,
    framework,
    startingModalFormId,
    currentModalFormId,
    loading,
    controlsInAccount.length,
    policiesInAccount.length,
    controlsInAccountResponse.data,
    policiesInAccountResponse.data,
    navigateToStartingModal,
    navigateToSelectControlsModal,
    navigateToSelectPoliciesModal,
  ])
  // ------
  return frameworkWizardState
}

export const FrameworkContextProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [framework, setFramework] = useState<ComplianceFramework | null>(null)
  const [suggestedControls, setSuggestedControls] = useState<Control[]>([])
  const [selectedControls, setSelectedControls] = useState<string[]>([])
  const [controlsInAccount, setControlsInAccount] = useState<Control[]>([])
  const [suggestedPolicies, setSuggestedPolicies] = useState<PolicyRecord[]>([])
  const [selectedPolicies, setSelectedPolicies] = useState<string[]>([])
  const [policiesInAccount, setPoliciesInAccount] = useState<PolicyRecord[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const wizardData: FrameworkWizard = useMemo(
    () => ({
      frameworkState: {
        framework,
        setFramework,
      },
      controlsState: {
        suggestedControls,
        setSuggestedControls,
        selectedControls,
        setSelectedControls,
        controlsInAccount,
        setControlsInAccount,
      },
      policiesState: {
        suggestedPolicies,
        setSuggestedPolicies,
        selectedPolicies,
        setSelectedPolicies,
        policiesInAccount,
        setPoliciesInAccount,
      },
      loadingState: {
        loading,
        setLoading,
      },
    }),
    [
      framework,
      suggestedControls,
      selectedControls,
      controlsInAccount,
      suggestedPolicies,
      selectedPolicies,
      policiesInAccount,
      loading,
    ],
  )

  return (
    <FrameworkWizardContext.Provider value={wizardData}>
      {children}
    </FrameworkWizardContext.Provider>
  )
}

/**
 * @fileoverview gRPC-Web generated client stub for contentstore
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: contentstore/contentstore.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')
const proto = {};
proto.contentstore = require('./contentstore_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.contentstore.ContentStoreClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.contentstore.ContentStorePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.contentstore.ContentID,
 *   !proto.contentstore.ByteArray>}
 */
const methodDescriptor_ContentStore_GetBytes = new grpc.web.MethodDescriptor(
  '/contentstore.ContentStore/GetBytes',
  grpc.web.MethodType.UNARY,
  proto.contentstore.ContentID,
  proto.contentstore.ByteArray,
  /**
   * @param {!proto.contentstore.ContentID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.contentstore.ByteArray.deserializeBinary
);


/**
 * @param {!proto.contentstore.ContentID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.contentstore.ByteArray)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.contentstore.ByteArray>|undefined}
 *     The XHR Node Readable Stream
 */
proto.contentstore.ContentStoreClient.prototype.getBytes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/contentstore.ContentStore/GetBytes',
      request,
      metadata || {},
      methodDescriptor_ContentStore_GetBytes,
      callback);
};


/**
 * @param {!proto.contentstore.ContentID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.contentstore.ByteArray>}
 *     Promise that resolves to the response
 */
proto.contentstore.ContentStorePromiseClient.prototype.getBytes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/contentstore.ContentStore/GetBytes',
      request,
      metadata || {},
      methodDescriptor_ContentStore_GetBytes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.contentstore.ContentID,
 *   !proto.contentstore.ByteArray>}
 */
const methodDescriptor_ContentStore_GetLinkedDocument = new grpc.web.MethodDescriptor(
  '/contentstore.ContentStore/GetLinkedDocument',
  grpc.web.MethodType.UNARY,
  proto.contentstore.ContentID,
  proto.contentstore.ByteArray,
  /**
   * @param {!proto.contentstore.ContentID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.contentstore.ByteArray.deserializeBinary
);


/**
 * @param {!proto.contentstore.ContentID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.contentstore.ByteArray)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.contentstore.ByteArray>|undefined}
 *     The XHR Node Readable Stream
 */
proto.contentstore.ContentStoreClient.prototype.getLinkedDocument =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/contentstore.ContentStore/GetLinkedDocument',
      request,
      metadata || {},
      methodDescriptor_ContentStore_GetLinkedDocument,
      callback);
};


/**
 * @param {!proto.contentstore.ContentID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.contentstore.ByteArray>}
 *     Promise that resolves to the response
 */
proto.contentstore.ContentStorePromiseClient.prototype.getLinkedDocument =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/contentstore.ContentStore/GetLinkedDocument',
      request,
      metadata || {},
      methodDescriptor_ContentStore_GetLinkedDocument);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.contentstore.ContentID,
 *   !proto.contentstore.URL>}
 */
const methodDescriptor_ContentStore_GetContentUrl = new grpc.web.MethodDescriptor(
  '/contentstore.ContentStore/GetContentUrl',
  grpc.web.MethodType.UNARY,
  proto.contentstore.ContentID,
  proto.contentstore.URL,
  /**
   * @param {!proto.contentstore.ContentID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.contentstore.URL.deserializeBinary
);


/**
 * @param {!proto.contentstore.ContentID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.contentstore.URL)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.contentstore.URL>|undefined}
 *     The XHR Node Readable Stream
 */
proto.contentstore.ContentStoreClient.prototype.getContentUrl =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/contentstore.ContentStore/GetContentUrl',
      request,
      metadata || {},
      methodDescriptor_ContentStore_GetContentUrl,
      callback);
};


/**
 * @param {!proto.contentstore.ContentID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.contentstore.URL>}
 *     Promise that resolves to the response
 */
proto.contentstore.ContentStorePromiseClient.prototype.getContentUrl =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/contentstore.ContentStore/GetContentUrl',
      request,
      metadata || {},
      methodDescriptor_ContentStore_GetContentUrl);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.contentstore.ContentID,
 *   !proto.contentstore.URL>}
 */
const methodDescriptor_ContentStore_GetDownloadURL = new grpc.web.MethodDescriptor(
  '/contentstore.ContentStore/GetDownloadURL',
  grpc.web.MethodType.UNARY,
  proto.contentstore.ContentID,
  proto.contentstore.URL,
  /**
   * @param {!proto.contentstore.ContentID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.contentstore.URL.deserializeBinary
);


/**
 * @param {!proto.contentstore.ContentID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.contentstore.URL)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.contentstore.URL>|undefined}
 *     The XHR Node Readable Stream
 */
proto.contentstore.ContentStoreClient.prototype.getDownloadURL =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/contentstore.ContentStore/GetDownloadURL',
      request,
      metadata || {},
      methodDescriptor_ContentStore_GetDownloadURL,
      callback);
};


/**
 * @param {!proto.contentstore.ContentID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.contentstore.URL>}
 *     Promise that resolves to the response
 */
proto.contentstore.ContentStorePromiseClient.prototype.getDownloadURL =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/contentstore.ContentStore/GetDownloadURL',
      request,
      metadata || {},
      methodDescriptor_ContentStore_GetDownloadURL);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.contentstore.GetSignedUrlWithKeyRequest,
 *   !proto.contentstore.GetSignedUrlWithKeyResponse>}
 */
const methodDescriptor_ContentStore_GetSignedUrlWithKey = new grpc.web.MethodDescriptor(
  '/contentstore.ContentStore/GetSignedUrlWithKey',
  grpc.web.MethodType.UNARY,
  proto.contentstore.GetSignedUrlWithKeyRequest,
  proto.contentstore.GetSignedUrlWithKeyResponse,
  /**
   * @param {!proto.contentstore.GetSignedUrlWithKeyRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.contentstore.GetSignedUrlWithKeyResponse.deserializeBinary
);


/**
 * @param {!proto.contentstore.GetSignedUrlWithKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.contentstore.GetSignedUrlWithKeyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.contentstore.GetSignedUrlWithKeyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.contentstore.ContentStoreClient.prototype.getSignedUrlWithKey =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/contentstore.ContentStore/GetSignedUrlWithKey',
      request,
      metadata || {},
      methodDescriptor_ContentStore_GetSignedUrlWithKey,
      callback);
};


/**
 * @param {!proto.contentstore.GetSignedUrlWithKeyRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.contentstore.GetSignedUrlWithKeyResponse>}
 *     Promise that resolves to the response
 */
proto.contentstore.ContentStorePromiseClient.prototype.getSignedUrlWithKey =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/contentstore.ContentStore/GetSignedUrlWithKey',
      request,
      metadata || {},
      methodDescriptor_ContentStore_GetSignedUrlWithKey);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.contentstore.ContentID,
 *   !proto.contentstore.ContentMetadata>}
 */
const methodDescriptor_ContentStore_GetMetadata = new grpc.web.MethodDescriptor(
  '/contentstore.ContentStore/GetMetadata',
  grpc.web.MethodType.UNARY,
  proto.contentstore.ContentID,
  proto.contentstore.ContentMetadata,
  /**
   * @param {!proto.contentstore.ContentID} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.contentstore.ContentMetadata.deserializeBinary
);


/**
 * @param {!proto.contentstore.ContentID} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.contentstore.ContentMetadata)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.contentstore.ContentMetadata>|undefined}
 *     The XHR Node Readable Stream
 */
proto.contentstore.ContentStoreClient.prototype.getMetadata =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/contentstore.ContentStore/GetMetadata',
      request,
      metadata || {},
      methodDescriptor_ContentStore_GetMetadata,
      callback);
};


/**
 * @param {!proto.contentstore.ContentID} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.contentstore.ContentMetadata>}
 *     Promise that resolves to the response
 */
proto.contentstore.ContentStorePromiseClient.prototype.getMetadata =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/contentstore.ContentStore/GetMetadata',
      request,
      metadata || {},
      methodDescriptor_ContentStore_GetMetadata);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.contentstore.ContentCreatedSince,
 *   !proto.contentstore.ContentMetadataList>}
 */
const methodDescriptor_ContentStore_GetMetadataCreatedSince = new grpc.web.MethodDescriptor(
  '/contentstore.ContentStore/GetMetadataCreatedSince',
  grpc.web.MethodType.UNARY,
  proto.contentstore.ContentCreatedSince,
  proto.contentstore.ContentMetadataList,
  /**
   * @param {!proto.contentstore.ContentCreatedSince} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.contentstore.ContentMetadataList.deserializeBinary
);


/**
 * @param {!proto.contentstore.ContentCreatedSince} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.contentstore.ContentMetadataList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.contentstore.ContentMetadataList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.contentstore.ContentStoreClient.prototype.getMetadataCreatedSince =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/contentstore.ContentStore/GetMetadataCreatedSince',
      request,
      metadata || {},
      methodDescriptor_ContentStore_GetMetadataCreatedSince,
      callback);
};


/**
 * @param {!proto.contentstore.ContentCreatedSince} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.contentstore.ContentMetadataList>}
 *     Promise that resolves to the response
 */
proto.contentstore.ContentStorePromiseClient.prototype.getMetadataCreatedSince =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/contentstore.ContentStore/GetMetadataCreatedSince',
      request,
      metadata || {},
      methodDescriptor_ContentStore_GetMetadataCreatedSince);
};


module.exports = proto.contentstore;


import React, { Fragment } from 'react'
import styled from 'styled-components/macro'
import Accordion from 'react-bootstrap/Accordion'
import { compose, margin, textStyle } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'
import { DropdownSVG } from '../../components/Icons/Basic'

export const HelpAccordion = styled(Accordion).attrs({
  forwardedAs: 'section',
})``

export const HelpAccordionItem = styled(Accordion.Item).attrs({
  forwardedAs: 'article',
})`
  border: none;
`

export const HelpAccordionHeader = styled(Accordion.Header).attrs((props) => {
  return {
    forwardedAs: 'header',
    children: (
      <Fragment>
        {props.children}
        <DropdownSVG className="help-accordion-chevron" />
      </Fragment>
    ),
  }
})`
  .accordion-button {
    min-height: 110px;
    margin-bottom: 8px;
    padding: 0 32px;
    border-radius: 4px;
    background-color: ${themeGet('colors.bg.neutral')};
    color: unset;

    :focus {
      box-shadow: none;
    }

    :hover {
      box-shadow: ${themeGet('shadows.outer.base')};
    }

    :focus,
    :hover,
    :not(.collapsed) {
      outline: none;
      background-color: ${themeGet('colors.bg.neutralHover')};
    }

    ::after {
      display: none;
    }

    .help-accordion-chevron {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
      fill: ${themeGet('colors.text.icon.low')};
      transition: transform 0.25s;
    }

    &.collapsed {
      .help-accordion-chevron {
        transform: rotate(-90deg);
      }
    }
  }
`

export const HelpAccordionBody = styled(Accordion.Body)`
  padding: 24px 32px 32px 32px;
`

export const HelpAccordionText = styled.p.attrs({
  mx: 'm',
  textStyle: 'body.big',
})`
  ${compose(margin, textStyle)}
  flex-grow: 1;
`

export const HelpAccordionBodyTextSection = styled.section.attrs({
  textStyles: 'body.default',
})`
  ${textStyle}
  a {
    text-decoration: none;
  }
`

export const HelpAccordionFigure = styled.figure`
  display: flex;
  justify-content: left;
  padding-top: 24px;
  align-items: flex-end;
`

export const HelpAccordionImage = styled.img`
  width: 100%;
  margin-right: 22px;
  box-shadow: ${themeGet('shadows.outer.base')};
`

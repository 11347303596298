import { DocumentRequest } from '@trustero/trustero-api-web/lib/request/request_pb'
import React from 'react'
import { RequestsItem } from '../components/RequestsItem'

export const RequestsIndexGridRows = ({
  requests,
  fromControlShowPage,
}: {
  requests: DocumentRequest[]
  fromControlShowPage: boolean
}): JSX.Element => (
  <>
    {requests.map((request) => (
      <RequestsItem
        key={request.getId()}
        request={request}
        fromControlShowPage={fromControlShowPage}
      />
    ))}
  </>
)

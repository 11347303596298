import React, { createContext, useContext, useMemo } from 'react'
import {
  Control,
  ListControlsRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { SWRResponse, KeyedMutator } from 'swr'
import has from 'lodash/has'
import { useControls } from '../model/control'

interface ControlLookupContextType {
  (controlModelId: string): SWRResponse<Control>
}

const initialControlLookupValue: ControlLookupContextType = (
  _controlModelId: string,
) => ({
  data: undefined,
  error: undefined,
  isValidating: false,
  isLoading: false,
  mutate: (() => null) as unknown as KeyedMutator<Control>,
})

const ControlLookupContext = createContext<ControlLookupContextType>(
  initialControlLookupValue,
)

export const useLookupControlContext = (): ControlLookupContextType =>
  useContext(ControlLookupContext)

export const ControlLookupProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const lookupControl = useLookupControl()

  return (
    <ControlLookupContext.Provider value={lookupControl}>
      {children}
    </ControlLookupContext.Provider>
  )
}

const useLookupControl = (): ((
  controlModelId: string,
) => SWRResponse<Control>) => {
  const controls = useControls(new ListControlsRequest(), true, {
    ignoreAuditContext: true,
  })
  const controlMap = useMemo(() => {
    const controlsList = controls.data?.getItemsList() || []
    return controlsList.reduce(
      (acc: Record<string, Control>, control: Control) => {
        acc[control.getModelId()] = control
        return acc
      },
      {} as Record<string, Control>,
    )
  }, [controls.data])
  return (controlModelId: string): SWRResponse<Control> => {
    const res = {
      data: controlMap[controlModelId],
      error: controls.error,
      isValidating: controls.isValidating,
      isLoading: controls.isLoading,
      mutate: (() => null) as unknown as KeyedMutator<Control>,
    }

    if (!has(controlMap, controlModelId)) {
      // if we don't have the a key value pair with the controlModelId, we might have been given the controlId
      const control = controls.data
        ?.getItemsList()
        .find((control: Control) => control.getId() === controlModelId)
      if (control) {
        res.data = control
      }
    }
    return res
  }
}

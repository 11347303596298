import { Key } from 'react'
import { Key as SWRKey } from 'swr'

export type RowItemProp = {
  gridRow?: number
  isExpanded?: boolean
}

export type AsyncCall<Response> = {
  cacheId: [SWRKey] | SWRKey[]
  request: () => Promise<Response>
}

export type RowItemElement = {
  RowItem: (props: RowItemProp) => JSX.Element
  key?: Key | null
  total?: number
}

export type RowItemExpandableElement = RowItemElement & {
  total: number
  expandedItems: RowItemElement
  actionsRow?: JSX.Element
}

/**
 * @deprecated use RowItemExpandableElement
 */
export type AsyncRowItemExpandableElement = RowItemElement & {
  total: number
  asyncCall: RowItemElement
}

export function isAsyncExpandableRowItem(
  p: RowItemElement | AsyncRowItemExpandableElement,
): p is AsyncRowItemExpandableElement {
  return 'asyncCall' in p
}

export function isExpandableRowItem(
  item: RowItemElement | RowItemExpandableElement,
): item is RowItemExpandableElement {
  return 'expandedItems' in item
}

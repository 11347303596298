import React, {
  Dispatch,
  FormEventHandler,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { useAddCustomService } from '../../../components/async/model/service'
import { ModalForm, ModalFormId } from '../../../components/ModalForms'
import { DerivedTextInput } from '../../../components/Reusable/Inputs/TextInput'

type CustomServiceFormData = {
  serviceName: string
  category: string
}

const formInitialState = {
  serviceName: '',
  category: '',
}

const formId = ModalFormId.ADD_CUSTOM_SERVICE

export const AddCustomServiceModal = (): JSX.Element => {
  const [formData, setFormData] =
    useState<CustomServiceFormData>(formInitialState)
  const [isValidName, setIsValidName] = useState<boolean>(true)
  const hasSubmitted = useRef<boolean>(false)
  const show = useIsShowModal(formId)
  const onHide = () => {
    setFormData(formInitialState)
    setIsValidName(true)
    hasSubmitted.current = false
  }
  const hide = useHideModal({ modalId: formId, onHide })
  const addCustomService = useAddCustomService()

  useEffect(() => {
    hasSubmitted.current && setIsValidName(formData.serviceName?.length > 0)
  }, [formData.serviceName])

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    const isValid: boolean = formData.serviceName?.length > 0
    setIsValidName(isValid)
    hasSubmitted.current = true
    if (!isValid) {
      return
    }

    await addCustomService({
      serviceName: formData.serviceName,
      category: formData.category,
    })
    hide()
  }

  return (
    <ModalForm
      show={show}
      hide={hide}
      title={'Add Custom Service'}
      submitText={'Add Custom Service'}
      formId={formId}
    >
      <form id={formId} onSubmit={onSubmit}>
        <DerivedTextInput
          label="Name"
          name="serviceName"
          initVal={formData.serviceName}
          placeholder={'Enter service name'}
          form={formId}
          isValid={isValidName}
          setFormData={
            setFormData as Dispatch<SetStateAction<{ [key: string]: string }>>
          }
          errorMessage={'Please enter a name'}
        />
        <DerivedTextInput
          label="Category"
          name="category"
          initVal={formData.category}
          placeholder={
            'Short description of what you use it for, e.g. payroll or CI/CD'
          }
          form={formId}
          setFormData={
            setFormData as Dispatch<SetStateAction<{ [key: string]: string }>>
          }
        />
      </form>
    </ModalForm>
  )
}

import React, { useState } from 'react'
import log from 'loglevel'
import isFunction from 'lodash/isFunction'
import { useNavigate } from 'react-router-dom'
import { RpcError } from 'grpc-web'
import { useAuthorizedGrpcClient } from 'src/adapter'
import { ItemTitle } from 'src/components/PageLayout/ShowPage'
import { EditOnClick } from 'src/components/Reusable/Inputs/EditOnClick'
import { StyledTextInput } from 'src/components/Reusable/Inputs/TextInput/styles'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { SubRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { ERROR_CODES } from 'src/Utils/globalEnums'
import { getErrorCode, getErrorMessage } from 'src/Utils/globalHelpers'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { useCreateOrUpdateRisks } from 'src/pages/Risks/risks.hooks'
import { RiskFields } from 'src/pages/Risks'
import { updatedRiskRpcData } from 'src/pages/Risks/risks.helpers'
import { Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { VendorRecord } from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import {
  getControlRequest,
  getLengthError,
  TITLE_LABELS,
  TITLE_VALIDATORS,
} from './ShowPageTitle.helpers'
import {
  ControlIdsError,
  StyledEditRow,
  StyledInputContainer,
} from './ShowPageTitle.styles'

export const ShowPageTitle = ({
  id,
  name,
  customId,
  model,
  modelType,
  mutate,
}: {
  id: string
  name: string
  customId: string
  model?: Risk | VendorRecord
  modelType: MODEL_TYPE
  mutate?: () => void
}): JSX.Element => {
  const navigate = useNavigate()
  const modelClient = useAuthorizedGrpcClient(ModelPromiseClient)
  const createOrUpdateRisks = useCreateOrUpdateRisks()
  const [customModelId, setCustomModelId] = useState<string>(customId)
  const [error, setError] = useState<string>('')
  const [modelName, setModelName] = useState<string>(name)
  const LABELS = TITLE_LABELS(modelType)

  if (modelType === MODEL_TYPE.RISK && model === null) {
    return <></>
  }

  const onCancel = () => {
    setModelName(name)
    setError('')
    customId && setCustomModelId(customId)
  }

  const onSave = async (): Promise<boolean> => {
    const newName = modelName.trim()
    const newModelId = customModelId.trim()
    // Validate inputs
    if (name === newName && customId === newModelId) {
      return true
    } else if (newName.length > 200) {
      setError(
        getLengthError(LABELS.MODEL_NAME, TITLE_VALIDATORS.NAME_MAX_LENGTH),
      )
      return false
    } else if (!newModelId.length) {
      setError(LABELS.MISSING_MODEL_ID)
      return false
    } else if (newModelId.length > 10) {
      setError(
        getLengthError(LABELS.MODEL_ID, TITLE_VALIDATORS.MODEL_ID_MAX_LENGTH),
      )
      return false
    }
    try {
      setError('')
      // TODO: Convert to switch once we need to handle more model types
      if (modelType === MODEL_TYPE.RISK) {
        if (!model) {
          log.error('Failed to update Risk Show Title - model is undefined')
          return false
        }
        let updatedRisk = updatedRiskRpcData(
          model as Risk,
          RiskFields.name,
          newName,
        )
        updatedRisk = updatedRiskRpcData(
          updatedRisk,
          RiskFields.customId,
          newModelId,
        )
        await createOrUpdateRisks([updatedRisk])
      } else {
        await modelClient.createOrUpdateControl(
          getControlRequest(newName, id, newModelId),
        )
        isFunction(mutate) && (await mutate())
      }
    } catch (err) {
      const resError = err as RpcError
      const duplicateError =
        getErrorCode(resError.message) === ERROR_CODES.DUPLICATE_ENTRY
      duplicateError
        ? setError(LABELS.UNIQUE_MODEL_ID)
        : setError(getErrorMessage(resError).message)
      !duplicateError &&
        log.error('Failed to update control show page:', resError)
      return false
    } finally {
      if (newModelId !== customId) {
        navigate(`../../${SubRoutes.SHOW}/${id}`)
      }
    }
    return true
  }

  return (
    <EditOnClick
      isInline
      onSaveControlClose={onSave}
      onCancel={onCancel}
      isEmpty={!modelName.length && !customModelId.length}
      editMode={
        <StyledEditRow>
          <StyledInputContainer>
            <StyledTextInput
              name={LABELS.MODEL_ID_FIELD}
              onChange={(e) => setCustomModelId(e.target.value)}
              value={customModelId}
              showError={!customModelId}
            />
            <ControlIdsError showError={!!error}>{error}</ControlIdsError>
          </StyledInputContainer>
          <StyledTextInput
            name={LABELS.NAME_FIELD}
            onChange={(e) => setModelName(e.target.value)}
            value={modelName}
            showError={false}
          />
        </StyledEditRow>
      }
    >
      <ItemTitle>{`${customId} ${name}`}</ItemTitle>
    </EditOnClick>
  )
}

import React from 'react'
import { Tooltip } from 'src/components/Reusable/Tooltip'
import { faEye as solidEye } from '@fortawesome/free-solid-svg-icons'
import { faEye as regularEye } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  TooltipOverlayType,
  TooltipPositions,
} from 'src/components/Reusable/Tooltip/Tooltip'
import { IconButton, IconButtonVariant } from '../Buttons/IconButton'

export const ToggleWatchingButton = ({
  isWatching,
  toggleWatching,
}: {
  isWatching: boolean
  toggleWatching: () => void
}): JSX.Element => (
  <Tooltip
    id="toggle-watching-tooltip"
    placement={TooltipPositions.top}
    trigger={['hover', 'focus']}
    overlayType={TooltipOverlayType.tooltip}
    tooltipBody={
      isWatching
        ? 'Stop watching this request'
        : 'Watch this request to get notified about changes'
    }
  >
    <IconButton
      variant={IconButtonVariant.icon}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        toggleWatching()
      }}
    >
      {isWatching ? (
        <FontAwesomeIcon icon={solidEye} />
      ) : (
        <FontAwesomeIcon icon={regularEye} />
      )}
    </IconButton>
  </Tooltip>
)

import {
  Control,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { ModelStatsServicePromiseClient } from '@trustero/trustero-api-web/lib/model/stats_grpc_web_pb'
import {
  ListControlsRequest,
} from '@trustero/trustero-api-web/lib/model/control_pb'
import { GrpcResponse, NewGrpcResponse } from '../../hooks/types'
import { useLookupControlContext } from '../../fetchingContext/LookupControlContext'
import { useControls, useInvalidateControlsCache } from './useControls'

export const mutateDependencies = async (
  methodMutator: (asyncCall: unknown) => Promise<unknown>,
): Promise<void> => {
  await Promise.all([
    methodMutator(
      ModelStatsServicePromiseClient.prototype.listControlComplianceStats,
    ),
    methodMutator(ModelPromiseClient.prototype.getControlStats),
    methodMutator(ModelPromiseClient.prototype.listControlIds),
    methodMutator(ModelStatsServicePromiseClient.prototype.getTrusteroScore),
  ])
}

/**
 * @param controlModelId modelId
 * Can take control id (PK) or model id
 * Will only have instant lookup in the cache if model id is passed in
 */
export const useControl = (controlModelId?: string): GrpcResponse<Control> => {
  useControls(new ListControlsRequest(), !!controlModelId, {
    ignoreAuditContext: true,
  })
  const getControlFromCache = useLookupControlContext()
  const mutate = useInvalidateControlsCache()
  const swrResponse = getControlFromCache(controlModelId || '')

  // Getting a lot of false positives for this error. Commenting out for now
  // if (!swrResponse.data && !swrResponse.error && !swrResponse.isValidating && !swrResponse.isLoading) {
    // We are assuming that if the controlModelId is an empty string that it is in a loading state
    // controlModelId && log.error('Control not found in cache', controlModelId)
  // }
  return NewGrpcResponse({
    ...swrResponse,
    mutate,
  })
}

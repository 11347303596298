import React, { useMemo } from 'react'
import { useSetActiveModal } from 'src/Modal/ModalStateContext'
import { useCurrentReceptor } from 'src/context/FormContext/CurrentReceptorContext'
import { ReceptorRecord } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import { ModalFormId } from '../../../../components/ModalForms'
import {
  ScopingAddOrEditButton,
  ScopingAddOrEditButtonType,
} from '../../ServicesAddOrEditButton'

export const EditCredentialsButton = ({
  receptor,
  activated,
  error,
}: {
  receptor: ReceptorRecord.AsObject
  activated: boolean
  error: boolean
}): JSX.Element => {
  const openEditModal = useSetActiveModal(ModalFormId.EDIT_RECEPTOR)
  const { setReceptor } = useCurrentReceptor()
  const tooltipBody = useMemo(
    () => (activated ? 'Manage Receptor' : 'Activate Receptor'),
    [activated],
  )

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    e.stopPropagation()
    setReceptor(receptor)
    openEditModal()
  }

  return (
    <ScopingAddOrEditButton
      id={receptor.id}
      deactivated={!activated}
      error={error}
      tooltipBody={tooltipBody}
      buttonType={ScopingAddOrEditButtonType.EDIT}
      onClick={onClick}
    />
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'
import log from 'loglevel'
import { SWRResponse } from 'swr'
import { Tab } from 'react-bootstrap'
import isString from 'lodash/isString'
import { Excluded } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { Evidence } from '@trustero/trustero-api-web/lib/receptor_v1/receptor_pb'
import {
  useExclusions,
  useFetchDocumentBody,
} from 'src/components/async/document/useDocument'
import { getEvidenceSources } from 'src/Utils/Evidence/evidence.helpers'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import { MIME_TYPE } from 'src/Utils/globalEnums'
import { openInNewTab } from 'src/Utils/globalHelpers'
import { ToastPrompts, showInfoToast } from 'src/Utils/helpers/toast'
import { getServiceTemplate } from 'src/xgenerated/service'
import { P } from 'src/components/Reusable/Text/Text.styles'
import palette from 'src/designSystem/variables/palette'
import { Markup } from '../../../Reusable/Text/Markup'
import { useFileTypeIcon } from '../../FileType/useFileTypeIcon'
import { EvidenceTabIcons } from './TabIcons'
import {
  DiscoveriesToMarkdown,
  ExclusionsToMarkdown,
  formatEvidence,
} from './ViewEvidenceForm.utils'
import {
  CustomTabs,
  PreviewDiv,
  StyledEvidenceP,
  StyledImagePreview,
  StyledPreviewModal,
  StyledPreviewModalIframe,
  StyledPreviewModalImage,
} from './ViewEvidenceForm.styles'

export const markupSectionProps = {
  m: '0 !important',
  p: 's',
  border: '1px solid',
  borderColor: 'border.neutral.light',
  textStyle: 'body.default',
}

interface AutomaticEvidenceBodyProps {
  documentId: string
  contentId: string
  mime: string
  exclusions: Excluded[]
}

export const withAsync = <Data,>(
  asyncCall: SWRResponse<Data, Error>,
  onResult: (data: Data) => JSX.Element,
): JSX.Element => {
  if (asyncCall.error) {
    log.error('error in withAsync:', asyncCall.error.message)
    return <></>
  }
  if (!asyncCall.data) {
    return <>Loading...</>
  }
  return onResult(asyncCall.data)
}

export const MarkdownArea = ({
  title,
  body,
}: {
  title?: string
  body: string
}): JSX.Element => {
  return (
    <>
      {title && <StyledEvidenceP>{title}</StyledEvidenceP>}
      <Markup
        markdown={body}
        sectionProps={{
          ...markupSectionProps,
          justifyContent: 'flex-start',
          height: '240px',
          overflowY: 'auto',
        }}
        isEvidence
      />
    </>
  )
}

export const ManualEvidenceBody = ({
  contentId,
  mime,
  documentTitle = 'Evidence',
}: {
  contentId: string
  mime: string
  documentTitle?: string
}): JSX.Element => {
  const [markdownBody, setMarkdownBody] = useState<string>('')
  const fetchDocumentBody = useFetchDocumentBody(contentId, mime)

  useEffect(() => {
    const getBody = async () => {
      try {
        const body = await fetchDocumentBody()
        if (!body || !isString(body)) {
          showInfoToast(ToastPrompts.EVIDENCE_DOWNLOAD_ERROR)
          return
        }
        setMarkdownBody(body)
      } catch (err) {
        log.error('error when downloading evidence body', err)
        showInfoToast(ToastPrompts.EVIDENCE_DOWNLOAD_ERROR)
      }
    }
    if (!markdownBody) {
      getBody()
    }
  })

  const isLink = mime === MIME_TYPE.TEXT_URI_LIST
  const loadingMessage = 'Loading...'

  return (
    <>
      <StyledEvidenceP>{documentTitle}</StyledEvidenceP>
      {isLink ? (
        <>
          {!markdownBody ? (
            <>{loadingMessage}</>
          ) : (
            <TextButton
              onClick={(e) => {
                e.preventDefault()
                openInNewTab(markdownBody.trim())
              }}
            >
              {markdownBody.trim()}
            </TextButton>
          )}
        </>
      ) : (
        <MarkdownArea body={markdownBody || loadingMessage} />
      )}
    </>
  )
}

export const AutomaticEvidenceBody = ({
  documentId,
  contentId,
  mime,
}: AutomaticEvidenceBodyProps): JSX.Element => {
  const { data: exclusionsData } = useExclusions(documentId)
  const fetchDocumentBody = useFetchDocumentBody(contentId, mime)
  const [docBody, setDocBody] = useState<Uint8Array | undefined>(undefined)

  useEffect(() => {
    const getBody = async () => {
      try {
        const body = await fetchDocumentBody()
        if (!body || !(body instanceof Uint8Array)) {
          showInfoToast(ToastPrompts.EVIDENCE_DOWNLOAD_ERROR)
          return
        }
        setDocBody(body)
      } catch (err) {
        log.error('error when downloading evidence body', err)
        showInfoToast(ToastPrompts.EVIDENCE_DOWNLOAD_ERROR)
      }
    }
    if (!docBody) {
      getBody()
    }
  }, [fetchDocumentBody, docBody])

  const getServiceName = useCallback(
    (id: string) => getServiceTemplate(id)?.name || 'Unknown Service',
    [],
  )

  const exclusionBody = useMemo(
    () =>
      ExclusionsToMarkdown(
        exclusionsData?.getExclusions()?.getExclusionsList() || [],
        getServiceName,
      ),
    [exclusionsData, getServiceName],
  )

  if (!docBody) {
    return <MarkdownArea title="Evidence" body="Loading..." />
  }

  const evidence: Evidence = Evidence.deserializeBinary(docBody)
  const evidenceSources = getEvidenceSources(evidence)
  const sourcesMarkdown = DiscoveriesToMarkdown(evidenceSources)
  const formattedEvidence = formatEvidence({ evidence })

  return (
    <CustomTabs
      defaultActiveKey="included"
      variant="pills"
      id="uncontrolled-tab-example"
    >
      <Tab eventKey="included" title={<EvidenceTabIcons.Included />}>
        <MarkdownArea title="Evidence" body={formattedEvidence} />
      </Tab>
      <Tab eventKey="excluded" title={<EvidenceTabIcons.Excluded />}>
        <MarkdownArea title="Excluded from Scope" body={exclusionBody} />
      </Tab>
      <Tab eventKey="source" title={<EvidenceTabIcons.Raw />}>
        <MarkdownArea title="Evidence Source Data" body={sourcesMarkdown} />
      </Tab>
    </CustomTabs>
  )
}

export const ImagePreview = ({ file }: { file: File }): JSX.Element => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [link, setLink] = useState<string | null>(null)
  const FileTypeIcon = useFileTypeIcon({ mime: file.type })

  const toggleModal = () => {
    setModalOpen(!isModalOpen)
  }

  const isImage = file.type.startsWith('image/')
  const isPdf = file.type === 'application/pdf'

  useEffect(() => {
    setLink(URL.createObjectURL(file))
  }, [file])

  if ((!isImage && !isPdf) || !link) {
    return <></>
  }

  return isImage ? (
    <PreviewDiv>
      <StyledImagePreview src={link} alt="Preview" onClick={toggleModal} />
      {isModalOpen && (
        <StyledPreviewModal onClick={toggleModal}>
          <StyledPreviewModalImage
            src={link as string}
            alt="Expanded Preview"
          />
          <CloseButton />
        </StyledPreviewModal>
      )}
    </PreviewDiv>
  ) : (
    <PreviewDiv onClick={toggleModal}>
      <FileTypeIcon width="120px" height="120px" />
      <p>View PDF</p>
      {isModalOpen && (
        <StyledPreviewModal onClick={toggleModal}>
          <StyledPreviewModalIframe
            src={link as string}
            typeof="application/pdf"
          />
          <CloseButton />
        </StyledPreviewModal>
      )}
    </PreviewDiv>
  )
}

const CloseButton = (): JSX.Element => (
  <P $color={palette.neutral['50']} $isUnderline>
    close
  </P>
)

import React from 'react'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import {
  QUESTIONNAIRE_ACCEPTANCE,
  QUESTIONNAIRE_TYPE,
  Questionnaire,
} from '@trustero/trustero-api-web/lib/questionnaire/questionnaire_pb'
import { Link } from 'react-router-dom'
import {
  ItemTitle,
  RouteTitle,
  ShowPageBackArrow,
  ShowPageHeaderButtons,
  ShowPageHeaderContainer,
  ShowPageHeaderTitles,
} from 'src/components/PageLayout/ShowPage'
import { ChangelogButton } from 'src/components/Reusable/Buttons/ChangelogButton'
import { PermalinkButton } from 'src/components/Reusable/Buttons/PermalinkButton'
import { FlexAlign, FlexColumn, FlexRow } from 'src/components/Reusable/Flex'
import { DownloadFileWithIconTooltip } from 'src/components/ModalForms/Reusable/UploadCSVModal.components'
import {
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import { P, SectionTitle } from 'src/components/Reusable/Text/Text.styles'
import { SecurityQuestionnaireAbsoluteRoutes } from 'src/components/Reusable/RootPage/RootPage.constants'
import { getPluralization } from 'src/Utils/globalHelpers'
import { InfoTooltip } from 'src/components/Reusable/Tooltip/InfoTooltip'
import {
  Tooltip,
  TooltipOverlayType,
  TooltipPositions,
} from 'src/components/Reusable/Tooltip/Tooltip'
import { CheckSVG } from 'src/components/Icons/Basic'
import { AICopilotLogo } from 'src/components/PageLayout/PageLayout.components'
import { useTypewriter } from 'src/Utils/globalHooks'
import {
  AIShowPageDivider,
  ShowPageHeader,
} from 'src/components/PageLayout/ShowPage/showpage.components'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import { formatTimestamp } from 'src/Utils/formatDate'
import { DATE_FORMATS } from 'src/Utils/dateConstants'
import { useHasRequiredPermissions } from 'src/app/AppAuth/AppAuth.hooks'
import { PERMISSIONS } from 'src/config/roleConfig'
import { downloadSecurityQuestionnaire } from '../modals/securityQuestionnaireModals.helpers'
import {
  QuestionnaireAcceptance,
  SecurityQuestionnaireProgressCircle,
} from '../securityQuestionnaire.components'
import {
  ContactSalesLink,
  EroIcon,
  QuestionnaireH3,
  QuestionnaireP,
  SecurityQuestionnaireSection,
  StyledEroContainer,
  StyledQuestionnaireItalicP,
  UserEditedIcon,
} from './securityQuestionnaireShowPage.styles'
import { getSecurityQuestionnaireStatusSectionCopy } from './securityQuestionnaireShowPage.helpers'
import { QuestionnaireGuidance } from './securityQuestionnaireShowPage.constants'

export const SecurityQuestionnaireShowPageHeader = ({
  questionnaire,
}: {
  questionnaire: Questionnaire
}): JSX.Element => {
  const permalinkUrl = location.href
  const breadcrumb = `../../${SecurityQuestionnaireAbsoluteRoutes.INDEX}`
  const name = questionnaire.getName()
  const id = questionnaire.getId()
  const isAccepted =
    questionnaire.getAcceptance() ===
    QUESTIONNAIRE_ACCEPTANCE.ACCEPTED_QUESTIONNAIRE

  return (
    <ShowPageHeaderContainer>
      <ShowPageHeader>
        <ShowPageHeaderTitles>
          <RouteTitle>
            <FlexRow
              isFullWidth
              align={FlexAlign.CENTER}
              justify={FlexAlign.SPACE_BETWEEN}
            >
              <Link to={breadcrumb}>
                <ShowPageBackArrow icon={faArrowLeft} />
                Security Questionnaire
              </Link>
            </FlexRow>
          </RouteTitle>
          <ItemTitle>{name}</ItemTitle>
        </ShowPageHeaderTitles>
        <ShowPageHeaderButtons>
          <FlexColumn align={FlexAlign.FLEX_END} gap={10}>
            <AICopilotLogo useDarkText />
            <FlexRow
              gap={22}
              justify={FlexAlign.CENTER}
              align={FlexAlign.CENTER}
            >
              <QuestionnaireAcceptance isAccepted={isAccepted} />
              <ChangelogButton subjectId={id} />
              <PermalinkButton isPermalink permalinkUrl={permalinkUrl} />
              <DownloadFileWithIconTooltip
                downloadFunc={() =>
                  downloadSecurityQuestionnaire(questionnaire)
                }
                text="Download as CSV"
                id={id}
              />
            </FlexRow>
          </FlexColumn>
        </ShowPageHeaderButtons>
      </ShowPageHeader>
      <FlexRow isFullWidth>
        <AIShowPageDivider />
      </FlexRow>
    </ShowPageHeaderContainer>
  )
}

export const ActionContainer = ({
  body,
  isManual,
}: {
  body: JSX.Element | JSX.Element[]
  isManual?: boolean
}): JSX.Element => (
  <StyledEroContainer>
    {isManual ? <UserEditedIcon /> : <EroIcon />}
    <FlexColumn>{body}</FlexColumn>
  </StyledEroContainer>
)

export const QuestionnaireSecondaryButton = ({
  text,
  onClick,
  isDisabled,
}: {
  text: string
  onClick: () => void
  isDisabled?: boolean
}): JSX.Element => {
  const hasRequiredPermissions = useHasRequiredPermissions([
    PERMISSIONS.READ,
    PERMISSIONS.EDIT,
  ])

  return (
    <StandardButton
      type="button"
      buttonSize={StandardButtonSize.MEDIUM}
      variant={StandardButtonVariant.SECONDARY}
      marginBottom={10}
      onClick={onClick}
      disabled={isDisabled || !hasRequiredPermissions}
    >
      {text}
    </StandardButton>
  )
}

export const QuestionnairePrimaryButton = ({
  text,
  onClick,
  isDisabled,
  disabledReason,
}: {
  text: string
  onClick: () => void
  isDisabled?: boolean
  disabledReason?: string
}): JSX.Element => {
  const hasRequiredPermissions = useHasRequiredPermissions([
    PERMISSIONS.READ,
    PERMISSIONS.EDIT,
  ])

  return (
    <>
      <Tooltip
        id={text}
        tooltipBody={disabledReason}
        disabled={isDisabled || !hasRequiredPermissions}
        overlayType={TooltipOverlayType.popover}
        placement={TooltipPositions.top}
      >
        <StandardButton
          type="button"
          buttonSize={StandardButtonSize.MEDIUM}
          variant={StandardButtonVariant.PRIMARY}
          marginBottom={10}
          onClick={onClick}
          disabled={isDisabled || !hasRequiredPermissions}
        >
          {text}
        </StandardButton>
      </Tooltip>
    </>
  )
}

export const QuestionnaireSecondaryButtonSmall = ({
  text,
  onClickFunc,
  isDisabled,
}: {
  text: string
  onClickFunc: () => void
  isDisabled?: boolean
}): JSX.Element => (
  <StandardButton
    width="120px"
    type="button"
    buttonSize={StandardButtonSize.SMALL}
    variant={StandardButtonVariant.SECONDARY}
    marginBottom={0}
    onClick={onClickFunc}
    disabled={isDisabled}
  >
    {text}
  </StandardButton>
)

export const SecurityQuestionnaireStatusSection = ({
  completed,
  total,
  manual,
  ero,
  hasAcceptedQuestionnaire,
  hasAddedKb,
  acceptQuestionnaire,
  addToKnowledgeBase,
  questionnaireType,
  startTime,
  endTime,
}: {
  completed: number
  total: number
  manual: number
  ero: number
  hasAcceptedQuestionnaire: boolean
  hasAddedKb: boolean
  acceptQuestionnaire: () => void
  addToKnowledgeBase: () => void
  questionnaireType: QUESTIONNAIRE_TYPE
  startTime?: Timestamp
  endTime?: Timestamp
}): JSX.Element => {
  const getQuestionnaireTypeDisplay = (): string => {
    switch (questionnaireType) {
      case QUESTIONNAIRE_TYPE.KB_ONLY:
        return 'QC Basic'
      case QUESTIONNAIRE_TYPE.QC_BASIC:
        return 'Prefer QC Basic'
      case QUESTIONNAIRE_TYPE.QC_ADVANCED:
        return 'Prefer QC Advanced'
    }
  }

  return (
    <SecurityQuestionnaireSection>
      <SectionTitle $mb={10}>
        Security Questionnaire
        <QuestionnaireP $fontSize={12} $isItalicized>
          {`${getQuestionnaireTypeDisplay()}`}
        </QuestionnaireP>
        {startTime && endTime && (
          <>
            <P $isDim>
              Started: {formatTimestamp(startTime, DATE_FORMATS.WITH_TIME)}
            </P>
            <P $isDim>
              Completed: {formatTimestamp(endTime, DATE_FORMATS.WITH_TIME)}
            </P>
          </>
        )}
      </SectionTitle>
      <FlexRow isFullWidth justify={FlexAlign.SPACE_BETWEEN}>
        <FlexRow justify={FlexAlign.FLEX_START} gap={20}>
          <SecurityQuestionnaireProgressCircle
            percentage={(completed / total) * 100}
          />
          {hasAcceptedQuestionnaire ? (
            <FlexColumn align={FlexAlign.FLEX_START} gap={5}>
              <QuestionnaireH3>
                Great work. This security questionnaire has been accepted.
              </QuestionnaireH3>
              <FlexRow>
                <QuestionnaireP>
                  You can no longer edit this security questionnaire.
                </QuestionnaireP>
              </FlexRow>
            </FlexColumn>
          ) : (
            <FlexColumn align={FlexAlign.FLEX_START} gap={5}>
              <QuestionnaireH3>
                {getSecurityQuestionnaireStatusSectionCopy(completed, total)}
              </QuestionnaireH3>
              <FlexRow>
                <EroIcon $isSmall />
                <QuestionnaireP $ml={6}>
                  {ero} {getPluralization('answer', ero)} accepted from AI
                  Copilot
                </QuestionnaireP>
              </FlexRow>
              <FlexRow>
                <UserEditedIcon $isSmall />
                <QuestionnaireP $ml={6}>
                  {manual} {getPluralization('answer', manual)} accepted from
                  Users
                </QuestionnaireP>
              </FlexRow>
            </FlexColumn>
          )}
        </FlexRow>
        <FlexColumn align={FlexAlign.FLEX_END} gap={5}>
          {hasAddedKb && hasAcceptedQuestionnaire ? (
            <>
              <FlexRow>
                <StyledQuestionnaireItalicP>
                  Your security questionnaire is part of your knowledge base.
                </StyledQuestionnaireItalicP>
                <InfoTooltip
                  id="added-sq-to-kb"
                  body={QuestionnaireGuidance.EXPLAIN_ADD_TO_KB}
                  placement={TooltipPositions.left}
                />
              </FlexRow>
              <FlexRow>
                <CheckSVG />
                <QuestionnaireP>Added to knowledge base</QuestionnaireP>
              </FlexRow>
            </>
          ) : hasAcceptedQuestionnaire ? (
            <>
              <FlexRow>
                <StyledQuestionnaireItalicP>
                  Why add questionnaire to knowledge base?
                </StyledQuestionnaireItalicP>
                <InfoTooltip
                  id="explain-add-to-kb"
                  body={QuestionnaireGuidance.EXPLAIN_ADD_TO_KB}
                  placement={TooltipPositions.left}
                />
              </FlexRow>
              <QuestionnaireSecondaryButton
                text="Add to Knowledge Base"
                onClick={addToKnowledgeBase}
              />
            </>
          ) : (
            <>
              <FlexRow>
                <StyledQuestionnaireItalicP>
                  Why accept my questionnaire?
                </StyledQuestionnaireItalicP>
                <InfoTooltip
                  id="explain-accept-sq"
                  body={QuestionnaireGuidance.EXPLAIN_ACCEPT_QUESTIONNAIRE}
                  placement={TooltipPositions.left}
                />
              </FlexRow>
              <QuestionnairePrimaryButton
                text="Accept Questionnaire"
                onClick={acceptQuestionnaire}
                isDisabled={!(total === completed)}
                disabledReason="All answers must be accepted."
              />
            </>
          )}
        </FlexColumn>
      </FlexRow>
    </SecurityQuestionnaireSection>
  )
}

export const EroDefaultAnswer = ({
  hasFailed,
  isBuyerRole,
  isNewAnswer,
}: {
  hasFailed: boolean
  isBuyerRole: boolean
  isNewAnswer?: boolean
}): JSX.Element => {
  const hasFailedText = `The AI Copilot had an issue and couldn't answer this question.`
  const manualText = `Sorry, the AI Copilot could not provide an answer. Please click edit answer and provide a manual response.`
  const hasFailedTyped = useTypewriter({ text: hasFailedText })
  const manualTyped = useTypewriter({ text: manualText })
  const hasFailedMsg = isNewAnswer ? hasFailedTyped : hasFailedText
  const manualMsg = isNewAnswer ? manualTyped : manualText
  const buyerText = `This answer was not found in your knowledge base. However, AI QC Advanced could answer this question.`
  const buyerMsg = useTypewriter({ text: buyerText })
  const showLink = buyerMsg.length === buyerText.length

  const linkMsg = useTypewriter({ text: `Contact Sales to learn more!` })
  let msg: JSX.Element = hasFailed ? (
    <span>{hasFailedMsg}</span>
  ) : (
    <span>{manualMsg}</span>
  )
  // If the user is a buyer and the KB couldn't provide an answer, we want to attempt an upsell Ero
  if (isBuyerRole && !hasFailed) {
    msg = (
      <span>
        {buyerMsg}
        {showLink && (
          <ContactSalesLink
            href="https://trustero.com/qc#packages"
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkMsg}
          </ContactSalesLink>
        )}
      </span>
    )
  }
  return msg
}

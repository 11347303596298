import React, { useContext } from 'react'
import { SWRConfig } from 'swr'
import log from 'loglevel'
import throttle from 'lodash/throttle'
import {
  USE_CASE,
  USER_ROLE,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { showInfoToast } from '../Utils/helpers/toast'
import { translateTokenError } from '../adapter/gRpcAdapter'
import { inJestTestEnvironment } from '../Utils/helpers/test.helpers'
import AuthContext, { AuthActionType } from './authContext'

type Props = {
  children: JSX.Element
}

/**
 * Ensure that network errors only cause 1 alert to fire every 3s.
 * This helps avoid spamming the #alarms-prod slack channel & Datadog when
 * multiple hook calls start to fail when invoked at the same time
 */

const isNetworkError = (err: Error): boolean => err.message === GRPC_NETWORK_ERR
const isUnauthenticatedError = (err: Error): boolean =>
  err.message.includes('only saasProvider tokens have access')

const GRPC_NETWORK_ERR = 'Http response at 400 or 500 level'
const THROTTLE_WAIT = 3000
const showErrToast = () =>
  showInfoToast(
    'There was a network problem reaching the server. Try reloading the page.',
  )
const logDdErr = (error: Error) => log.error('SWR Provider Error: ', error)
const logDdErrWithToast = (error: Error) => {
  showErrToast()
  logDdErr(error)
}
const throttledError = throttle(logDdErrWithToast, THROTTLE_WAIT, {
  leading: true,
  trailing: false,
})

const handleGrpcError = (error: Error): void => {
  if (isNetworkError(error)) {
    return throttledError(error)
  } else if (isUnauthenticatedError(error)) {
    return
  } else {
    return logDdErr(error)
  }
}
/**
 * This is a global config for all of our usages of SWR across the application
 * We ensure that Datadog errors are caught and sent here and if there is network/connectivity issues, we throttle per above
 */
export const SWRProvider = (props: Props): JSX.Element => {
  const { authDispatch } = useContext(AuthContext)
  return (
    <SWRConfig
      value={{
        provider: () => new Map(),
        onError: (error) => {
          try {
            const parsedErrors = translateTokenError(error)
            if (parsedErrors.token) {
              !inJestTestEnvironment() &&
                log.warn('Auth Token issue: ', parsedErrors.token)
              authDispatch({
                type: AuthActionType.LOGOUT,
                useCase: USE_CASE.SAAS_BUYER,
                role: USER_ROLE.READONLY,
              })
            } else {
              handleGrpcError(error)
            }
          } catch {
            handleGrpcError(error)
          }
          return
        },
      }}
    >
      {props.children}
    </SWRConfig>
  )
}

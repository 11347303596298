import React from 'react'
import { Navigate } from 'react-router-dom'
import queryString from 'query-string'
import { FilterParam } from '../../../components/Reusable/IndexPage/FilterBar/FilterBar.types'
import { ChecklistFilterEnum, DashboardQueryParam } from '../types'
import { useGetURLParams } from './useGetURLParams'

export function useRecomputeURLParams(): JSX.Element | undefined {
  const query = useGetURLParams()
  if (
    !query[DashboardQueryParam.COMPLIANCE_CHECKLIST_TAB] ||
    !query[DashboardQueryParam.STATUS_OF_CONTROLS_TAB]
  ) {
    // Recompute query
    if (!query[DashboardQueryParam.COMPLIANCE_CHECKLIST_TAB]) {
      query[DashboardQueryParam.COMPLIANCE_CHECKLIST_TAB] =
        ChecklistFilterEnum.TEAM_WORK
    }
    if (!query[DashboardQueryParam.STATUS_OF_CONTROLS_TAB]) {
      query[DashboardQueryParam.STATUS_OF_CONTROLS_TAB] = FilterParam.DEPARTMENT
    }
    // Redirect with proper default queries
    return (
      <Navigate
        replace
        to={queryString.stringifyUrl({
          url: location.pathname,
          query,
        })}
      />
    )
  }
  return
}

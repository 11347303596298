import React from 'react'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import {
  IconButton,
  IconButtonVariant,
} from '../../../../components/Reusable/Buttons'
import { ReactComponent as DeleteIcon } from '../../../../components/Icons/assets/delete-icon.svg'
import { useDeleteTestResult } from './useDeleteTestResult'

type Props = {
  isDeleteDisabled: boolean
  modelId: string
  testId: string
  timeRange?: TimeRange
}
export function DeleteButton(props: Props): JSX.Element {
  const dataId = 'testDeleteButton_' + props.testId
  return (
    <IconButton
      disabled={props.isDeleteDisabled}
      variant={IconButtonVariant.icon}
      onClick={useDeleteTestResult(props)}
      data-id={dataId}
    >
      <DeleteIcon />
    </IconButton>
  )
}

import React from 'react'
import { PolicyTitle } from './PolicyTitle'

type Props = {
  policyId: string
}

export const ControlPolicyInfo = ({ policyId }: Props): JSX.Element => {
  return <PolicyTitle modelId={policyId} />
}

import React from 'react'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import { Dropdown } from 'react-bootstrap'
import { ControlsDropdownMenu } from './ControlsDropdown.helpers'
import {
  ControlsDropdownCountChip,
  ControlsDropdownDiv,
  ControlsDropdownToggle,
} from './ControlsDropown.styles'

type ControlsDropdownProps = {
  selectedControls: Control[]
  onControlSelected: (control: Control) => React.ChangeEventHandler<HTMLElement>
  showCount?: boolean
  placeholderText?: string
}

export const ControlsDropdown = ({
  selectedControls,
  onControlSelected,
  showCount = false,
  placeholderText = 'Select relevant controls',
}: ControlsDropdownProps): JSX.Element => {
  const listedControlNames = selectedControls
    .map((control) => control.getName())
    .join(', ')
  const displayNames =
    listedControlNames.length > 100
      ? `${listedControlNames.slice(0, 100)}...`
      : listedControlNames

  return (
    <Dropdown id="controls-dropdown" drop="down">
      <ControlsDropdownDiv>
        <Dropdown.Toggle as={ControlsDropdownToggle}>
          {displayNames || placeholderText}
          {showCount && (
            <ControlsDropdownCountChip>
              {selectedControls.length || 0}
            </ControlsDropdownCountChip>
          )}
        </Dropdown.Toggle>
      </ControlsDropdownDiv>
      <ControlsDropdownMenu
        selectedControls={selectedControls}
        onControlSelected={onControlSelected}
      />
    </Dropdown>
  )
}

import React, { useMemo } from 'react'
import { ReceptorRecord } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import { Receptor } from '../../../../xgenerated'
import { ReceptorStaticHelper } from '../../../../context/Content/statichelpers'
import { ReceptorHeaderContainer, ReceptorName } from './ReceptorHeader.styles'
import { EditCredentialsButton } from './EditCredentialsButton'

export function ReceptorHeader({
  receptor,
  receptorTemplate,
  isSelected,
}: {
  receptor: ReceptorRecord.AsObject
  receptorTemplate: Receptor
  isSelected: boolean
}): JSX.Element {
  const activated = useMemo(
    () => ReceptorStaticHelper.isAccredited(receptor),
    [receptor],
  )
  const expand = useAccordionButton(receptor.id)

  return (
    <ReceptorHeaderContainer
      aria-expanded={isSelected}
      $deactivated={!activated}
      onClick={expand}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          expand(e)
        }
      }}
    >
      <EditCredentialsButton
        receptor={receptor}
        activated={activated}
        error={activated && !receptor.iscredvalid}
      />
      <ReceptorName>
        <receptorTemplate.logo
          className="receptor-logo"
          width={'24px'}
          height={'24px'}
        />
        <span>{receptorTemplate.name}</span>
        <span>{receptor.tenantid && ` - ${receptor.tenantid}`}</span>
      </ReceptorName>
    </ReceptorHeaderContainer>
  )
}

import React, { ChangeEventHandler } from 'react'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { useControl } from '../../../../../components/async/model/control/useControl'
import { GridColumn, GridRow } from '../../../../../components/Reusable/Grid'
import { CheckBoxInput } from '../../../../../components/Reusable/Inputs/CheckBox'
import { Title } from '../../../../../components/Reusable/Text/Title'
import { OwnerAssignmentButton as ReusableOwnerAssignmentButton } from '../../../../../components/Reusable/Buttons'
import { DepartmentChip as ReusableDepartmentChip } from '../../../DepartmentChip'
import { ControlPolicyInfo } from '../../../IndexItem/ControlPolicyInfo'

export const ControlsIndexItem = ({
  modelId,
  selected,
  onChange,
}: {
  modelId: string
  selected: string[]
  onChange: ChangeEventHandler
}): JSX.Element => {
  const controlRes = useControl(modelId)?.data?.toObject()

  if (!controlRes) {
    return <></>
  }

  const { policyId, name, ownerEmail, departmentId } = controlRes

  return (
    <GridRow id={`control-${modelId}`}>
      <GridColumn>
        <CheckBoxInput
          value={modelId}
          onChange={onChange}
          checked={selected.includes(modelId)}
        />
      </GridColumn>
      <GridColumn
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="center"
      >
        <div>
          <ControlPolicyInfo policyId={policyId} />
          <Title name={name} />
        </div>
      </GridColumn>
      <GridColumn justifyContent="center">
        <ReusableOwnerAssignmentButton
          modelId={modelId}
          modelType={MODEL_TYPE.CONTROL}
          email={ownerEmail}
          isDisabled
        />
      </GridColumn>
      <GridColumn justifyContent="center">
        <ReusableDepartmentChip departmentId={departmentId} />
      </GridColumn>
    </GridRow>
  )
}

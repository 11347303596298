import React from 'react'
import { ModalFormId } from '../../ModalForm'
import {
  StandardButtonSize,
  StandardButtonVariant,
} from '../../../Reusable/Buttons/StandardButton'
import { StandardOpenModalButton } from '../../ModalButtons'

export const SelectReceptorFormToggle = (): JSX.Element => {
  return (
    <StandardOpenModalButton
      variant={StandardButtonVariant.PRIMARY}
      size={StandardButtonSize.SMALL}
      modalId={ModalFormId.SELECT_RECEPTOR}
    >
      Add Receptor
    </StandardOpenModalButton>
  )
}

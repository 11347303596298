import log from 'loglevel'
import { showInfoToast } from 'src/Utils/helpers/toast'
import { AuthActionType } from '../context/authContext'
import { EnvConfig } from '../config/config'

export const NTRCE_API_URL = EnvConfig.ntrceApiUrl

const logger = log.getLogger('adapter')

// Parse all the formats of error that might come back from ntrced
const parseGrpcErrorMsg = (error) => {
  // Examples of the errors we see:
  // { code: 3, message: "rpc error: code = Unauthenticated desc = No such user.  [thumper@ntrce.co] }
  // { code: 16, message: "No such user.  []"
  try {
    // parse the "code = .. desc =" format
    let desc_match = error.message.match(/desc = (.*) {2}\[(.*)]$/)
    if (!desc_match) {
      // Otherwise, it must be the simpler format
      desc_match = error.message.match(/^(.*) {2}\[(.*)]$/)
    }
    const generic_desc = desc_match[1]
    const argument_desc = desc_match[2]

    // if there is no argument, or it looks like an email, then ignore the arg
    if (!argument_desc || argument_desc.includes('@')) {
      return generic_desc.split(';')
    }

    // otherwise, prefer to use the argument
    return argument_desc.split(';')
  } catch (exc) {
    return error.message
  }
}

export const translateAuthError = (error) => {
  const errorList = parseGrpcErrorMsg(error)
  const parsedErrors = {}
  switch (error.code) {
    case 2: // UNKNOWN
      throw new Error('Our service is currently unavailable.')
    case 3: // INVALID_ARGUMENT
      for (const singleError of errorList) {
        if (singleError.includes('Password')) {
          parsedErrors.password = singleError
        } else if (singleError.includes('Email')) {
          parsedErrors.email = singleError
        } else {
          parsedErrors.general = singleError
        }
      }
      return parsedErrors
    case 16: // AUTHENTICATION ERROR
      for (const singleError of errorList) {
        if (singleError.includes('No such user.')) {
          parsedErrors.email = singleError
        } else {
          parsedErrors.general = singleError
        }
      }
      return parsedErrors
    default:
      throw new Error(error.message)
  }
}

export const translateTokenError = (error) => {
  const errorList = parseGrpcErrorMsg(error)
  const parsedErrors = {}
  switch (error.code) {
    case 3: // INVALID_ARGUMENT
      for (const singleError of errorList) {
        if (singleError === "header authorization doesn't exist") {
          parsedErrors.token = singleError
        } else {
          parsedErrors.general = singleError
        }
      }
      return parsedErrors
    case 7: // PERMISSION_DENIED
      if (errorList.includes('Invalid token version')) {
        parsedErrors.token = 'Token needs to be updated'
      } else if (errorList.find((ele) => ele.includes('readonly tokens'))) {
        showInfoToast('You do not have permission to perform this action')
        parsedErrors.void = true
        return parsedErrors
      } else {
        if (typeof errorList === 'string') {
          parsedErrors.general = errorList?.split(' ,')
        } else if (Array.isArray(errorList)) {
          parsedErrors.general = errorList
        }
      }
      return parsedErrors
    case 16: // UNAUTHENTICATED
      return { token: error.message }
    default:
      // Re-throw the same error, otherwise we lose context from the original error
      throw error
  }
}

export const authHeader = (accessToken) => {
  return accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
}

export const interceptTokenErrors = async (authCtx, authDispatch, apiCall) => {
  const header = authHeader(authCtx.accessToken)
  try {
    return await apiCall(header)
  } catch (err) {
    const parsedErrors = translateTokenError(err, authDispatch)
    if (parsedErrors.token) {
      logger.warn('Auth Token issue: ', parsedErrors.token)
      authDispatch({ type: AuthActionType.LOGOUT })
    }
    throw err
  }
}

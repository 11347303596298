import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import styled from 'styled-components/macro'
import palette from 'src/designSystem/variables/palette'
import { ReactComponent as DeleteIcon } from '../../../../components/Icons/assets/delete-icon.svg'

export const ActionsRowContainer = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 16px;
`

export const EvidenceBulkDeleteButton = styled(TextButton)`
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  &:hover {
    path {
      fill: ${palette.neutral[800]};
    }
`

export const EvidenceBulkDeleteIcon = styled(DeleteIcon)`
  height: 21px;
  path {
    fill: ${palette.neutral[600]};
  }
`

import { SelectItem } from 'src/components/Reusable/SelectDropdown/SelectDropdown.constants'

export const getSelectedItems = (
  options: SelectItem[],
  selected: string,
): SelectItem[] => {
  const selectedArr = selected.split(',')
  return selectedArr.map((selected) => {
    const selectedOption = options.find((option) => option.value === selected)
    return {
      name: selectedOption ? selectedOption.name : '',
      value: selected,
    } as SelectItem
  })
}

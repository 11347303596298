import React, {
  DetailedHTMLProps,
  FunctionComponent,
  ImgHTMLAttributes,
} from 'react'

import styled from 'styled-components/macro'
import { TestIds } from '../../../Utils/testIds'

import { ReactComponent as JourneyNavSVG } from '../Basic/icons_journey.svg'
import JourneyNavPath from '../Basic/icons_journey.svg'
import { ReactComponent as DashboardNavSVG } from '../Basic/icons_dashboard.svg'
import DashboardNavPath from '../Basic/icons_dashboard.svg'
import { ReactComponent as RequestsNavSVG } from '../Basic/icons_requests.svg'
import RequestsNavPath from '../Basic/icons_requests.svg'
import { ReactComponent as UsersNavSVG } from '../Basic/icons_users.svg'
import UsersNavPath from '../Basic/icons_users.svg'
import { ReactComponent as ServicesNavSVG } from '../Basic/icons_services.svg'
import ServicesNavPath from '../Basic/icons_services.svg'
import { ReactComponent as ReportScanNavSVG } from '../Basic/icons_report_scan.svg'
import ReportScanNavPath from '../Basic/icons_report_scan.svg'
import { ReactComponent as PoliciesNavSVG } from '../Basic/icons_policies.svg'
import PoliciesNavPath from '../Basic/icons_policies.svg'
import { ReactComponent as CompanyInfoNavSVG } from '../Basic/icons_company_info.svg'
import CompanyInfoNavPath from '../Basic/icons_company_info.svg'
import { ReactComponent as ActivitiesNavIconSVG } from '../Basic/icons_activities.svg'
import ActivitiesNavPath from '../Basic/icons_activities.svg'
import { ReactComponent as RiskRegisterNavSVG } from '../Basic/icons_warning_gear.svg'
import RiskRegisterNavPath from '../Basic/icons_warning_gear.svg'
import { ReactComponent as ControlsNavSVG } from '../Basic/icons_controls.svg'
import ControlsNavPath from '../Basic/icons_controls.svg'
import { ReactComponent as HomeNavSVG } from '../Basic/icons_home.svg'
import HomeNavPath from '../Basic/icons_home.svg'
import { ReactComponent as AuditNavSVG } from '../Basic/icons_audit.svg'
import AuditNavPath from '../Basic/icons_audit.svg'
import { ReactComponent as SettingsNavSVG } from '../Basic/icons_settings.svg'
import SettingsNavPath from '../Basic/icons_settings.svg'
import { ReactComponent as EroNavSVG } from '../Basic/icons_ero.svg'
import EroNavPath from '../Basic/icons_ero.svg'

const StyledImg = styled.img`
  height: 24px;
  width: 24px;
`

const ActivitiesNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={ActivitiesNavPath} />

const ControlsNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={ControlsNavPath} />

const DashboardNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={DashboardNavPath} data-testid={TestIds.SVG_ICON} />

const JourneyNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={JourneyNavPath} />

const RequestsNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={RequestsNavPath} />

// [AP-5545] TODO: Rename to ContentNavImg
const PoliciesNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={PoliciesNavPath} />

const CompanyInfoNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={CompanyInfoNavPath} />

const ServicesNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={ServicesNavPath} />

const UsersNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={UsersNavPath} />

// [AP-5545] TODO: Rename to AnalyzeNavImg
const ReportScanNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={ReportScanNavPath} />

const RiskRegisterNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={RiskRegisterNavPath} />

const HomeNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={HomeNavPath} />

const AuditNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={AuditNavPath} />

const SettingsNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={SettingsNavPath} />

const EroNavImg: FunctionComponent<
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
> = () => <StyledImg src={EroNavPath} />

StyledImg.defaultProps = {
  src: '',
}

const NavIcons = {
  ActivitiesNavIconSVG,
  ActivitiesNavImg,
  ControlsNavSVG,
  ControlsNavImg,
  DashboardNavSVG,
  DashboardNavImg,
  JourneyNavSVG,
  JourneyNavImg,
  RequestsNavSVG,
  RequestsNavImg,
  PoliciesNavSVG,
  PoliciesNavImg,
  ServicesNavSVG,
  ServicesNavImg,
  UsersNavSVG,
  UsersNavImg,
  CompanyInfoNavSVG,
  CompanyInfoNavImg,
  ReportScanNavSVG,
  ReportScanNavImg,
  RiskRegisterNavSVG,
  RiskRegisterNavImg,
  HomeNavSVG,
  HomeNavImg,
  AuditNavSVG,
  AuditNavImg,
  SettingsNavSVG,
  SettingsNavImg,
  EroNavSVG,
  EroNavImg,
}

export default NavIcons

export {
  ActivitiesNavIconSVG,
  ActivitiesNavImg,
  ControlsNavSVG,
  ControlsNavImg,
  JourneyNavSVG,
  JourneyNavImg,
  RequestsNavSVG,
  RequestsNavImg,
  PoliciesNavSVG,
  PoliciesNavImg,
  ServicesNavSVG,
  ServicesNavImg,
  UsersNavSVG,
  UsersNavImg,
  DashboardNavSVG,
  DashboardNavImg,
  CompanyInfoNavSVG,
  CompanyInfoNavImg,
  ReportScanNavSVG,
  ReportScanNavImg,
  RiskRegisterNavSVG,
  RiskRegisterNavImg,
  HomeNavSVG,
  HomeNavImg,
  AuditNavSVG,
  AuditNavImg,
  SettingsNavSVG,
  SettingsNavImg,
  EroNavSVG,
  EroNavImg,
}

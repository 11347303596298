import React from 'react'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import { Dropdown } from 'react-bootstrap'
import {
  MultiSelectDropdownCheckBox,
  MultiSelectDropdownCheckBoxLabel,
  MultiSelectDropdownItem,
} from '../../../../Reusable/MultiSelectDropdown'
import { StyledDropdownMenu } from '../../../../Reusable/StandardDropdown/StandardDropdown.styles'
import { useControls } from '../../../../async/model/control/useControls'
import { ControlsDropdownMenuPlaceholder } from './ControlsDropown.styles'

type ControlsDropdownItemProps = {
  control: Control
  onControlSelected: (control: Control) => React.ChangeEventHandler<HTMLElement>
  selectedControls: Control[]
}

export const ControlsDropdownItem = ({
  control,
  onControlSelected,
  selectedControls,
}: ControlsDropdownItemProps): JSX.Element => {
  const isChecked = selectedControls.some(
    (selectedControl) => selectedControl.getModelId() === control.getModelId(),
  )
  const controlId = control.getId()
  const controlName = control.getName()
  return (
    <Dropdown.Item eventKey={controlId} as={MultiSelectDropdownItem}>
      <MultiSelectDropdownCheckBoxLabel>
        <>
          <MultiSelectDropdownCheckBox
            id={controlId}
            onChange={onControlSelected(control)}
            checked={isChecked}
          />
          {controlName}
        </>
      </MultiSelectDropdownCheckBoxLabel>
    </Dropdown.Item>
  )
}

type ControlsDropdownMenuProps = {
  selectedControls: Control[]
  onControlSelected: (control: Control) => React.ChangeEventHandler<HTMLElement>
}

export const ControlsDropdownMenu = ({
  selectedControls,
  onControlSelected,
}: ControlsDropdownMenuProps): JSX.Element => {
  const { data, isLoading } = useControls()

  if (isLoading || !data) {
    return (
      <Dropdown.Menu as={StyledDropdownMenu}>
        <ControlsDropdownMenuPlaceholder />
      </Dropdown.Menu>
    )
  }

  const controls = data.getItemsList()

  return (
    <Dropdown.Menu as={StyledDropdownMenu}>
      {controls.map((control) => (
        <ControlsDropdownItem
          key={control.getId()}
          control={control}
          onControlSelected={onControlSelected}
          selectedControls={selectedControls}
        />
      ))}
    </Dropdown.Menu>
  )
}

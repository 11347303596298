export enum ROOT_ID_PARAM_TYPE {
  REPORT_SCAN_ID = 'reportScanId',
  REQUEST_ID = 'requestId',
  CONTROL_MODEL_ID = 'controlModelId', // can be control ID or control (custom) model ID
  POLICY_MODEL_ID = 'policyModelId',
  RISK_ID = 'riskId', // can be risk ID or risk custom ID
  AUDITBOT_RUN_ID = 'auditRunId',
  SECURITY_QUESTIONNAIRE = 'questionnaireId',
  SERVICE_ID = 'serviceId',
  RECEPTOR_ID = 'receptorId',
  VENDOR_ID = 'vendorId',
}

export enum ControlsAbsoluteRoutes {
  ROOT = 'controls',
  INDEX = 'controls/index',
  INDEX_PAGE = 'controls/index?dismissed[]=false',
  SHOW = 'controls/show',
}

export enum ControlsSubRoutes {
  INDEX = 'index',
  SHOW = 'show',
}

export enum PoliciesAbsoluteRoutes {
  ROOT = 'policies',
  INDEX = 'policies/index',
  INDEX_PAGE = 'policies/index?dismissed[]=false',
  SHOW = 'policies/show',
}

export enum PoliciesSubRoutes {
  INDEX = 'index',
  SHOW = 'show',
}

export enum RisksAbsoluteRoutes {
  ROOT = 'risks',
  INDEX = 'risks/index',
  INDEX_PAGE = 'risks/index',
  SHOW = 'risks/show',
}

export enum ServicesAbsoluteRoutes {
  ROOT = 'services',
  SERVICES = 'services/services',
  RECEPTORS = 'services/receptors',
  INDEX = 'services/index',
  INDEX_PAGE = 'services/index',
  SHOW = 'services/show',
}

export enum ReceptorsAbsoluteRoutes {
  ROOT = 'receptors',
  INDEX = 'receptors/index',
  SHOW = 'receptors/show',
}

export enum AuditBotAbsoluteRoutes {
  ROOT = 'auditbot',
  INDEX = 'auditbot/index',
  INDEX_PAGE = 'auditbot/index',
  SHOW = 'auditbot/show',
}

export enum RoadmapAbsoluteRoutes {
  ROOT = 'roadmap',
  INDEX = 'roadmap/index',
  INDEX_PAGE = 'roadmap/index',
  SHOW = 'roadmap/show',
}

export enum SecurityQuestionnaireAbsoluteRoutes {
  ROOT = 'security_questionnaire',
  INDEX = 'security_questionnaire/index',
  SHOW = 'security_questionnaire/show',
}

export enum VendorsAbsoluteRoutes {
  ROOT = 'vendors',
  INDEX = 'vendors/index',
  INDEX_PAGE = 'vendors/index',
  SHOW = 'vendors/show',
}

export enum SubRoutes {
  INDEX = 'index',
  SHOW = 'show',
}

export enum DirectRoutes {
  DASHBOARD = 'dashboard',
  BUYER_DASHBOARD = 'buyer_dashboard',
  USERS = 'users',
  PROFILE = 'profile',
  COMPANY_INFO = 'company_info',
  ACCESS_DENIED = '/access_denied',
  AUDITS = 'audits',
  GENERIC_SIGNUP = 'signup',
  REPORT_SCAN_SIGNUP = 'scan_report',
  SECURITY_QUESTIONNAIRE_SIGNUP = 'answer_questionnaire',
  SCOPE = 'scope',
  ACCOUNT_SETTINGS = 'account_settings',
  ACCOUNTS = 'accounts',
}

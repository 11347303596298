import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { CreateUpdateControlRequest } from '@trustero/trustero-api-web/lib/model/control_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'

export const getLengthError = (field: string, maxLength: number): string =>
  `Try something shorter for the ${field}. ${maxLength} character maximum.`

export const getControlRequest = (
  newValue: string,
  controlId: string,
  modelId: string,
): CreateUpdateControlRequest =>
  new CreateUpdateControlRequest()
    .setId(controlId)
    .setName(new StringValue().setValue(newValue))
    .setModelId(new StringValue().setValue(modelId))

interface TitleLabels {
  MODEL_NAME: string
  MODEL_ID: string
  UNIQUE_MODEL_ID: string
  MISSING_MODEL_ID: string
  NAME_FIELD: string
  MODEL_ID_FIELD: string
}

export const TITLE_LABELS: (modelType: MODEL_TYPE) => TitleLabels = (
  modelType: MODEL_TYPE,
) => {
  // TODO: convert to switch statement once we need more than 2 model types
  const mType = modelType === MODEL_TYPE.RISK ? 'Risk' : 'Control'

  return {
    MODEL_NAME: `${mType} Name`,
    MODEL_ID: `${mType} ID`,
    UNIQUE_MODEL_ID: `Please enter a unique ${mType} ID.`,
    MISSING_MODEL_ID: `Please enter a ${mType} ID.`,
    NAME_FIELD: 'name',
    MODEL_ID_FIELD: 'modelId',
  }
}

export const TITLE_VALIDATORS = {
  NAME_MAX_LENGTH: 200,
  MODEL_ID_MAX_LENGTH: 10,
}

/**
 * @fileoverview gRPC-Web generated client stub for vendormanagement
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: vendormanagement/attestation.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.vendormanagement = require('./attestation_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.vendormanagement.AttestationClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.vendormanagement.AttestationPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vendormanagement.AddAttestationsRequest,
 *   !proto.vendormanagement.Attestations>}
 */
const methodDescriptor_Attestation_AddAttestations = new grpc.web.MethodDescriptor(
  '/vendormanagement.Attestation/AddAttestations',
  grpc.web.MethodType.UNARY,
  proto.vendormanagement.AddAttestationsRequest,
  proto.vendormanagement.Attestations,
  /**
   * @param {!proto.vendormanagement.AddAttestationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vendormanagement.Attestations.deserializeBinary
);


/**
 * @param {!proto.vendormanagement.AddAttestationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vendormanagement.Attestations)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vendormanagement.Attestations>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vendormanagement.AttestationClient.prototype.addAttestations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vendormanagement.Attestation/AddAttestations',
      request,
      metadata || {},
      methodDescriptor_Attestation_AddAttestations,
      callback);
};


/**
 * @param {!proto.vendormanagement.AddAttestationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vendormanagement.Attestations>}
 *     Promise that resolves to the response
 */
proto.vendormanagement.AttestationPromiseClient.prototype.addAttestations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vendormanagement.Attestation/AddAttestations',
      request,
      metadata || {},
      methodDescriptor_Attestation_AddAttestations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vendormanagement.ListAttestationsRequest,
 *   !proto.vendormanagement.Attestations>}
 */
const methodDescriptor_Attestation_ListAttestations = new grpc.web.MethodDescriptor(
  '/vendormanagement.Attestation/ListAttestations',
  grpc.web.MethodType.UNARY,
  proto.vendormanagement.ListAttestationsRequest,
  proto.vendormanagement.Attestations,
  /**
   * @param {!proto.vendormanagement.ListAttestationsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vendormanagement.Attestations.deserializeBinary
);


/**
 * @param {!proto.vendormanagement.ListAttestationsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vendormanagement.Attestations)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vendormanagement.Attestations>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vendormanagement.AttestationClient.prototype.listAttestations =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vendormanagement.Attestation/ListAttestations',
      request,
      metadata || {},
      methodDescriptor_Attestation_ListAttestations,
      callback);
};


/**
 * @param {!proto.vendormanagement.ListAttestationsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vendormanagement.Attestations>}
 *     Promise that resolves to the response
 */
proto.vendormanagement.AttestationPromiseClient.prototype.listAttestations =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vendormanagement.Attestation/ListAttestations',
      request,
      metadata || {},
      methodDescriptor_Attestation_ListAttestations);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vendormanagement.DeleteAttestationRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Attestation_DeleteAttestation = new grpc.web.MethodDescriptor(
  '/vendormanagement.Attestation/DeleteAttestation',
  grpc.web.MethodType.UNARY,
  proto.vendormanagement.DeleteAttestationRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.vendormanagement.DeleteAttestationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.vendormanagement.DeleteAttestationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vendormanagement.AttestationClient.prototype.deleteAttestation =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vendormanagement.Attestation/DeleteAttestation',
      request,
      metadata || {},
      methodDescriptor_Attestation_DeleteAttestation,
      callback);
};


/**
 * @param {!proto.vendormanagement.DeleteAttestationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.vendormanagement.AttestationPromiseClient.prototype.deleteAttestation =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vendormanagement.Attestation/DeleteAttestation',
      request,
      metadata || {},
      methodDescriptor_Attestation_DeleteAttestation);
};


module.exports = proto.vendormanagement;


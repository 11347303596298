import React, { useContext } from 'react'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { ATTACHMENT_TYPE } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import ContentContext from '../../../context/ContentContext'
import { ContentStaticHelper } from '../../../context/Content/statichelpers'
import { AuditContent } from './AuditContent'
import { LinkContent } from './LinkedContent'
import { RequestContent } from './RequestContent'
import { RiskContent } from './RiskContent'
import { AuditBotContent } from './AuditBotContent'
import { NON_LINKABLE_CONFIG, NON_LINKABLE_MESSAGES } from './Subject.constants'
import { QuestionnaireContent } from './QuestionnaireContent'
import { VendorContent } from './VendorContent'
import { LinkerContent } from './LinkerContent'

type Props = {
  subjectModelName?: string
  verb: string
  actorName: string
  modelId: string
  message?: string
  modelType: MODEL_TYPE
  attachmentId?: string
  attachmentType?: ATTACHMENT_TYPE
}

export const Subject = (props: Props): JSX.Element => {
  if (
    !props.modelId ||
    (!props.modelType && props.modelType !== MODEL_TYPE.OVERVIEW)
  ) {
    return <></>
  }
  return <Content {...props} />
}

const Content = (props: Props): JSX.Element => {
  const subjectName = useParseSubjectName(props.modelId, props.subjectModelName)
  const name = subjectName !== props.actorName ? subjectName : undefined

  if (props.modelType === MODEL_TYPE.AUDIT) {
    return <AuditContent auditId={props.modelId} {...props} />
  }
  if (props.modelType === MODEL_TYPE.REQUEST) {
    return <RequestContent requestId={props.modelId} {...props} />
  }
  if (props.modelType === MODEL_TYPE.RISK) {
    return <RiskContent riskId={props.modelId} />
  }
  if (props.modelType === MODEL_TYPE.VENDOR) {
    return <VendorContent vendorId={props.modelId} vendorName={name} />
  }
  if (props.modelType === MODEL_TYPE.AUDITBOT_RUN) {
    return (
      <AuditBotContent
        auditRunId={props.modelId}
        linkable={!NON_LINKABLE_CONFIG[props.verb as NON_LINKABLE_MESSAGES]}
      />
    )
  }
  if (props.modelType === MODEL_TYPE.QUESTIONNAIRE) {
    return <QuestionnaireContent questionnaireId={props.modelId} />
  }
  if (props.modelType === MODEL_TYPE.LINKER) {
    return <LinkerContent linkerName={subjectName} />
  }

  if (name) {
    return (
      <LinkContent
        name={name}
        modelId={props.modelId}
        modelType={props.modelType}
        unlinked={
          NON_LINKABLE_CONFIG[props.verb as NON_LINKABLE_MESSAGES] &&
          !props.attachmentId
        }
      />
    )
  }

  return <>{name}</>
}

const useParseSubjectName = (
  modelId: string,
  modelName?: string,
): string | undefined => {
  const { content } = useContext(ContentContext)
  if (modelName) {
    return modelName
  }
  const obj = ContentStaticHelper.getObjById(content, modelId)
  return obj?.name
}

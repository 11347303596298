// source: attachment/attachment.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var common_model_pb = require('../common/model_pb.js');
goog.object.extend(proto, common_model_pb);
var common_time_pb = require('../common/time_pb.js');
goog.object.extend(proto, common_time_pb);
var common_payload_pb = require('../common/payload_pb.js');
goog.object.extend(proto, common_payload_pb);
goog.exportSymbol('proto.attachment.ACTOR_TYPE', null, global);
goog.exportSymbol('proto.attachment.ATTACHMENT_TYPE', null, global);
goog.exportSymbol('proto.attachment.AddDocumentRequest', null, global);
goog.exportSymbol('proto.attachment.AddEvidenceRequest', null, global);
goog.exportSymbol('proto.attachment.AllExclusions', null, global);
goog.exportSymbol('proto.attachment.AuditModelID', null, global);
goog.exportSymbol('proto.attachment.AuditModelIDs', null, global);
goog.exportSymbol('proto.attachment.AuditPeriod', null, global);
goog.exportSymbol('proto.attachment.AuditPeriods', null, global);
goog.exportSymbol('proto.attachment.BulkAddEvidenceRequest', null, global);
goog.exportSymbol('proto.attachment.Caption', null, global);
goog.exportSymbol('proto.attachment.CloseAudit', null, global);
goog.exportSymbol('proto.attachment.CompleteAudit', null, global);
goog.exportSymbol('proto.attachment.DOCUMENT_TYPE', null, global);
goog.exportSymbol('proto.attachment.DeleteAuditPeriodRequest', null, global);
goog.exportSymbol('proto.attachment.DeleteControlEvidencesByCaptionRequest', null, global);
goog.exportSymbol('proto.attachment.DeleteDocumentRequest', null, global);
goog.exportSymbol('proto.attachment.Document', null, global);
goog.exportSymbol('proto.attachment.DocumentPeriod', null, global);
goog.exportSymbol('proto.attachment.DocumentTypeQuery', null, global);
goog.exportSymbol('proto.attachment.Documents', null, global);
goog.exportSymbol('proto.attachment.Evidence', null, global);
goog.exportSymbol('proto.attachment.EvidenceGenerator', null, global);
goog.exportSymbol('proto.attachment.EvidenceGenerators', null, global);
goog.exportSymbol('proto.attachment.Excluded', null, global);
goog.exportSymbol('proto.attachment.GetAuditPeriodByOIDRequest', null, global);
goog.exportSymbol('proto.attachment.GetChangeByOIDRequest', null, global);
goog.exportSymbol('proto.attachment.GetChangeLogEntriesRequest', null, global);
goog.exportSymbol('proto.attachment.GetDocumentByOIDRequest', null, global);
goog.exportSymbol('proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest', null, global);
goog.exportSymbol('proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceBodyByOIDRequest', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceBodyByOIDRequest.FORMAT', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceBodyByOIDResponse', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceDiscoveryRequest', null, global);
goog.exportSymbol('proto.attachment.GetEvidenceDiscoveryResponse', null, global);
goog.exportSymbol('proto.attachment.GetExclusionsRequest', null, global);
goog.exportSymbol('proto.attachment.GetExclusionsResponse', null, global);
goog.exportSymbol('proto.attachment.GetLatestEvidenceRequest', null, global);
goog.exportSymbol('proto.attachment.GetLatestEvidenceResponse', null, global);
goog.exportSymbol('proto.attachment.GetModelEvidenceGeneratorRequest', null, global);
goog.exportSymbol('proto.attachment.GetPolicyAcknowledgmentFormRequest', null, global);
goog.exportSymbol('proto.attachment.GetPolicyAcknowledgmentFormResponse', null, global);
goog.exportSymbol('proto.attachment.GetRiskRegisterCSVResponse', null, global);
goog.exportSymbol('proto.attachment.IDCount', null, global);
goog.exportSymbol('proto.attachment.IDCounts', null, global);
goog.exportSymbol('proto.attachment.IDsQuery', null, global);
goog.exportSymbol('proto.attachment.IncludeModelIDs', null, global);
goog.exportSymbol('proto.attachment.LatestEvidence', null, global);
goog.exportSymbol('proto.attachment.LinkDocumentRequest', null, global);
goog.exportSymbol('proto.attachment.LinkDocumentsRequest', null, global);
goog.exportSymbol('proto.attachment.ListConnectedEvidenceGeneratorsRequest', null, global);
goog.exportSymbol('proto.attachment.ListEvidenceRequest', null, global);
goog.exportSymbol('proto.attachment.ListEvidenceResponse', null, global);
goog.exportSymbol('proto.attachment.LogEntries', null, global);
goog.exportSymbol('proto.attachment.LogEntry', null, global);
goog.exportSymbol('proto.attachment.LogEntryCount', null, global);
goog.exportSymbol('proto.attachment.LookUpChangeLogEntryRequest', null, global);
goog.exportSymbol('proto.attachment.LookUpChangeLogEntryResponse', null, global);
goog.exportSymbol('proto.attachment.ModelEvidenceGenerator', null, global);
goog.exportSymbol('proto.attachment.ModelTypeQuery', null, global);
goog.exportSymbol('proto.attachment.PolicyAcknowledgmentForm', null, global);
goog.exportSymbol('proto.attachment.ServiceDiscovery', null, global);
goog.exportSymbol('proto.attachment.TimeQuery', null, global);
goog.exportSymbol('proto.attachment.UpdateRelevantDateRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetChangeByOIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetChangeByOIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetChangeByOIDRequest.displayName = 'proto.attachment.GetChangeByOIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.displayName = 'proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.displayName = 'proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetDocumentByOIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetDocumentByOIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetDocumentByOIDRequest.displayName = 'proto.attachment.GetDocumentByOIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetAuditPeriodByOIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetAuditPeriodByOIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetAuditPeriodByOIDRequest.displayName = 'proto.attachment.GetAuditPeriodByOIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.DeleteAuditPeriodRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.DeleteAuditPeriodRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.DeleteAuditPeriodRequest.displayName = 'proto.attachment.DeleteAuditPeriodRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LogEntry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.LogEntry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LogEntry.displayName = 'proto.attachment.LogEntry';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetChangeLogEntriesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetChangeLogEntriesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetChangeLogEntriesRequest.displayName = 'proto.attachment.GetChangeLogEntriesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LogEntries = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.LogEntries.repeatedFields_, null);
};
goog.inherits(proto.attachment.LogEntries, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LogEntries.displayName = 'proto.attachment.LogEntries';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.Document.repeatedFields_, null);
};
goog.inherits(proto.attachment.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.Document.displayName = 'proto.attachment.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AddDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.AddDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AddDocumentRequest.displayName = 'proto.attachment.AddDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LinkDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.LinkDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LinkDocumentRequest.displayName = 'proto.attachment.LinkDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LinkDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.LinkDocumentsRequest.repeatedFields_, null);
};
goog.inherits(proto.attachment.LinkDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LinkDocumentsRequest.displayName = 'proto.attachment.LinkDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.Documents = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.Documents.repeatedFields_, null);
};
goog.inherits(proto.attachment.Documents, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.Documents.displayName = 'proto.attachment.Documents';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AddEvidenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.AddEvidenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AddEvidenceRequest.displayName = 'proto.attachment.AddEvidenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.BulkAddEvidenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.BulkAddEvidenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.BulkAddEvidenceRequest.displayName = 'proto.attachment.BulkAddEvidenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.DocumentPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.DocumentPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.DocumentPeriod.displayName = 'proto.attachment.DocumentPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.Caption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.Caption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.Caption.displayName = 'proto.attachment.Caption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.UpdateRelevantDateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.UpdateRelevantDateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.UpdateRelevantDateRequest.displayName = 'proto.attachment.UpdateRelevantDateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AuditPeriod = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.AuditPeriod, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AuditPeriod.displayName = 'proto.attachment.AuditPeriod';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AuditModelID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.AuditModelID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AuditModelID.displayName = 'proto.attachment.AuditModelID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AuditModelIDs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.AuditModelIDs.repeatedFields_, null);
};
goog.inherits(proto.attachment.AuditModelIDs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AuditModelIDs.displayName = 'proto.attachment.AuditModelIDs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AuditPeriods = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.AuditPeriods.repeatedFields_, null);
};
goog.inherits(proto.attachment.AuditPeriods, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AuditPeriods.displayName = 'proto.attachment.AuditPeriods';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.IncludeModelIDs = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.IncludeModelIDs, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.IncludeModelIDs.displayName = 'proto.attachment.IncludeModelIDs';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.CloseAudit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.CloseAudit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.CloseAudit.displayName = 'proto.attachment.CloseAudit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.CompleteAudit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.CompleteAudit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.CompleteAudit.displayName = 'proto.attachment.CompleteAudit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.TimeQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.TimeQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.TimeQuery.displayName = 'proto.attachment.TimeQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ModelTypeQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.ModelTypeQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ModelTypeQuery.displayName = 'proto.attachment.ModelTypeQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.DocumentTypeQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.DocumentTypeQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.DocumentTypeQuery.displayName = 'proto.attachment.DocumentTypeQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.IDsQuery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.IDsQuery.repeatedFields_, null);
};
goog.inherits(proto.attachment.IDsQuery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.IDsQuery.displayName = 'proto.attachment.IDsQuery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.IDCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.IDCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.IDCount.displayName = 'proto.attachment.IDCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.IDCounts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.IDCounts.repeatedFields_, null);
};
goog.inherits(proto.attachment.IDCounts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.IDCounts.displayName = 'proto.attachment.IDCounts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LogEntryCount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.LogEntryCount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LogEntryCount.displayName = 'proto.attachment.LogEntryCount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceBodyByOIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetEvidenceBodyByOIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceBodyByOIDRequest.displayName = 'proto.attachment.GetEvidenceBodyByOIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceBodyByOIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.GetEvidenceBodyByOIDResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.GetEvidenceBodyByOIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceBodyByOIDResponse.displayName = 'proto.attachment.GetEvidenceBodyByOIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetLatestEvidenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetLatestEvidenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetLatestEvidenceRequest.displayName = 'proto.attachment.GetLatestEvidenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.Excluded = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.Excluded.repeatedFields_, null);
};
goog.inherits(proto.attachment.Excluded, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.Excluded.displayName = 'proto.attachment.Excluded';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.AllExclusions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.AllExclusions.repeatedFields_, null);
};
goog.inherits(proto.attachment.AllExclusions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.AllExclusions.displayName = 'proto.attachment.AllExclusions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceDiscoveryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetEvidenceDiscoveryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceDiscoveryRequest.displayName = 'proto.attachment.GetEvidenceDiscoveryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetEvidenceDiscoveryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.GetEvidenceDiscoveryResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.GetEvidenceDiscoveryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetEvidenceDiscoveryResponse.displayName = 'proto.attachment.GetEvidenceDiscoveryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ServiceDiscovery = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.ServiceDiscovery.repeatedFields_, null);
};
goog.inherits(proto.attachment.ServiceDiscovery, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ServiceDiscovery.displayName = 'proto.attachment.ServiceDiscovery';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ListEvidenceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.ListEvidenceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ListEvidenceRequest.displayName = 'proto.attachment.ListEvidenceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ListEvidenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.ListEvidenceResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.ListEvidenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ListEvidenceResponse.displayName = 'proto.attachment.ListEvidenceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetLatestEvidenceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.GetLatestEvidenceResponse.repeatedFields_, null);
};
goog.inherits(proto.attachment.GetLatestEvidenceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetLatestEvidenceResponse.displayName = 'proto.attachment.GetLatestEvidenceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ModelEvidenceGenerator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.ModelEvidenceGenerator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ModelEvidenceGenerator.displayName = 'proto.attachment.ModelEvidenceGenerator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetModelEvidenceGeneratorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetModelEvidenceGeneratorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetModelEvidenceGeneratorRequest.displayName = 'proto.attachment.GetModelEvidenceGeneratorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.ListConnectedEvidenceGeneratorsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.ListConnectedEvidenceGeneratorsRequest.displayName = 'proto.attachment.ListConnectedEvidenceGeneratorsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LatestEvidence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.LatestEvidence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LatestEvidence.displayName = 'proto.attachment.LatestEvidence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.Evidence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.Evidence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.Evidence.displayName = 'proto.attachment.Evidence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.EvidenceGenerators = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.attachment.EvidenceGenerators.repeatedFields_, null);
};
goog.inherits(proto.attachment.EvidenceGenerators, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.EvidenceGenerators.displayName = 'proto.attachment.EvidenceGenerators';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.EvidenceGenerator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.EvidenceGenerator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.EvidenceGenerator.displayName = 'proto.attachment.EvidenceGenerator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.DeleteDocumentRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.DeleteDocumentRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.DeleteDocumentRequest.displayName = 'proto.attachment.DeleteDocumentRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetPolicyAcknowledgmentFormRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetPolicyAcknowledgmentFormRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetPolicyAcknowledgmentFormRequest.displayName = 'proto.attachment.GetPolicyAcknowledgmentFormRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetPolicyAcknowledgmentFormResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetPolicyAcknowledgmentFormResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetPolicyAcknowledgmentFormResponse.displayName = 'proto.attachment.GetPolicyAcknowledgmentFormResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.PolicyAcknowledgmentForm = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.PolicyAcknowledgmentForm, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.PolicyAcknowledgmentForm.displayName = 'proto.attachment.PolicyAcknowledgmentForm';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetRiskRegisterCSVResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetRiskRegisterCSVResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetRiskRegisterCSVResponse.displayName = 'proto.attachment.GetRiskRegisterCSVResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetExclusionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetExclusionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetExclusionsRequest.displayName = 'proto.attachment.GetExclusionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.GetExclusionsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.GetExclusionsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.GetExclusionsResponse.displayName = 'proto.attachment.GetExclusionsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.DeleteControlEvidencesByCaptionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.DeleteControlEvidencesByCaptionRequest.displayName = 'proto.attachment.DeleteControlEvidencesByCaptionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LookUpChangeLogEntryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.LookUpChangeLogEntryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LookUpChangeLogEntryRequest.displayName = 'proto.attachment.LookUpChangeLogEntryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.attachment.LookUpChangeLogEntryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.attachment.LookUpChangeLogEntryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.attachment.LookUpChangeLogEntryResponse.displayName = 'proto.attachment.LookUpChangeLogEntryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetChangeByOIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetChangeByOIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetChangeByOIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetChangeByOIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetChangeByOIDRequest}
 */
proto.attachment.GetChangeByOIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetChangeByOIDRequest;
  return proto.attachment.GetChangeByOIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetChangeByOIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetChangeByOIDRequest}
 */
proto.attachment.GetChangeByOIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetChangeByOIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetChangeByOIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetChangeByOIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetChangeByOIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.GetChangeByOIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetChangeByOIDRequest} returns this
 */
proto.attachment.GetChangeByOIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest;
  return proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest} returns this
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse;
  return proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string ids = 1;
 * @return {!Array<string>}
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.prototype.getIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse} returns this
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.prototype.setIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse} returns this
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.prototype.addIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse} returns this
 */
proto.attachment.GetDocumentOIDsByAuditPeriodOIDResponse.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetDocumentByOIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetDocumentByOIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetDocumentByOIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetDocumentByOIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    includeBody: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetDocumentByOIDRequest}
 */
proto.attachment.GetDocumentByOIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetDocumentByOIDRequest;
  return proto.attachment.GetDocumentByOIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetDocumentByOIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetDocumentByOIDRequest}
 */
proto.attachment.GetDocumentByOIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludeBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetDocumentByOIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetDocumentByOIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetDocumentByOIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetDocumentByOIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIncludeBody();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.GetDocumentByOIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetDocumentByOIDRequest} returns this
 */
proto.attachment.GetDocumentByOIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool include_body = 2;
 * @return {boolean}
 */
proto.attachment.GetDocumentByOIDRequest.prototype.getIncludeBody = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.GetDocumentByOIDRequest} returns this
 */
proto.attachment.GetDocumentByOIDRequest.prototype.setIncludeBody = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetAuditPeriodByOIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetAuditPeriodByOIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetAuditPeriodByOIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetAuditPeriodByOIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetAuditPeriodByOIDRequest}
 */
proto.attachment.GetAuditPeriodByOIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetAuditPeriodByOIDRequest;
  return proto.attachment.GetAuditPeriodByOIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetAuditPeriodByOIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetAuditPeriodByOIDRequest}
 */
proto.attachment.GetAuditPeriodByOIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetAuditPeriodByOIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetAuditPeriodByOIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetAuditPeriodByOIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetAuditPeriodByOIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.GetAuditPeriodByOIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetAuditPeriodByOIDRequest} returns this
 */
proto.attachment.GetAuditPeriodByOIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.DeleteAuditPeriodRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.DeleteAuditPeriodRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.DeleteAuditPeriodRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DeleteAuditPeriodRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.DeleteAuditPeriodRequest}
 */
proto.attachment.DeleteAuditPeriodRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.DeleteAuditPeriodRequest;
  return proto.attachment.DeleteAuditPeriodRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.DeleteAuditPeriodRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.DeleteAuditPeriodRequest}
 */
proto.attachment.DeleteAuditPeriodRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.DeleteAuditPeriodRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.DeleteAuditPeriodRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.DeleteAuditPeriodRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DeleteAuditPeriodRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.DeleteAuditPeriodRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.DeleteAuditPeriodRequest} returns this
 */
proto.attachment.DeleteAuditPeriodRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LogEntry.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LogEntry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LogEntry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LogEntry.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subjectmodeltype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    subjectmodelid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    actortype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    actor: jspb.Message.getFieldWithDefault(msg, 7, ""),
    message: jspb.Message.getFieldWithDefault(msg, 8, ""),
    attachmenttype: jspb.Message.getFieldWithDefault(msg, 9, 0),
    attachmentid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    subjectmodelname: (f = msg.getSubjectmodelname()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    subjectid: (f = msg.getSubjectid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    actorid: (f = msg.getActorid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LogEntry}
 */
proto.attachment.LogEntry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LogEntry;
  return proto.attachment.LogEntry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LogEntry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LogEntry}
 */
proto.attachment.LogEntry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 4:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setSubjectmodeltype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectmodelid(value);
      break;
    case 6:
      var value = /** @type {!proto.attachment.ACTOR_TYPE} */ (reader.readEnum());
      msg.setActortype(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setActor(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 9:
      var value = /** @type {!proto.attachment.ATTACHMENT_TYPE} */ (reader.readEnum());
      msg.setAttachmenttype(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentid(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSubjectmodelname(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSubjectid(value);
      break;
    case 13:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setActorid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LogEntry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LogEntry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LogEntry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LogEntry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubjectmodeltype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSubjectmodelid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActortype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getActor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAttachmenttype();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getAttachmentid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSubjectmodelname();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSubjectid();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getActorid();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.attachment.LogEntry.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 CreatedAt = 2;
 * @return {number}
 */
proto.attachment.LogEntry.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string AccountID = 3;
 * @return {string}
 */
proto.attachment.LogEntry.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.MODEL_TYPE SubjectModelType = 4;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.attachment.LogEntry.prototype.getSubjectmodeltype = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setSubjectmodeltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string SubjectModelID = 5;
 * @return {string}
 */
proto.attachment.LogEntry.prototype.getSubjectmodelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setSubjectmodelid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ACTOR_TYPE ActorType = 6;
 * @return {!proto.attachment.ACTOR_TYPE}
 */
proto.attachment.LogEntry.prototype.getActortype = function() {
  return /** @type {!proto.attachment.ACTOR_TYPE} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.attachment.ACTOR_TYPE} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setActortype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string Actor = 7;
 * @return {string}
 */
proto.attachment.LogEntry.prototype.getActor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setActor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Message = 8;
 * @return {string}
 */
proto.attachment.LogEntry.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional ATTACHMENT_TYPE AttachmentType = 9;
 * @return {!proto.attachment.ATTACHMENT_TYPE}
 */
proto.attachment.LogEntry.prototype.getAttachmenttype = function() {
  return /** @type {!proto.attachment.ATTACHMENT_TYPE} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.attachment.ATTACHMENT_TYPE} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setAttachmenttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string AttachmentID = 10;
 * @return {string}
 */
proto.attachment.LogEntry.prototype.getAttachmentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.setAttachmentid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.protobuf.StringValue SubjectModelName = 11;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.LogEntry.prototype.getSubjectmodelname = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 11));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.LogEntry} returns this
*/
proto.attachment.LogEntry.prototype.setSubjectmodelname = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.clearSubjectmodelname = function() {
  return this.setSubjectmodelname(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.LogEntry.prototype.hasSubjectmodelname = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue SubjectId = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.LogEntry.prototype.getSubjectid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.LogEntry} returns this
*/
proto.attachment.LogEntry.prototype.setSubjectid = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.clearSubjectid = function() {
  return this.setSubjectid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.LogEntry.prototype.hasSubjectid = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.StringValue ActorId = 13;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.LogEntry.prototype.getActorid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 13));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.LogEntry} returns this
*/
proto.attachment.LogEntry.prototype.setActorid = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.LogEntry} returns this
 */
proto.attachment.LogEntry.prototype.clearActorid = function() {
  return this.setActorid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.LogEntry.prototype.hasActorid = function() {
  return jspb.Message.getField(this, 13) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetChangeLogEntriesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetChangeLogEntriesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetChangeLogEntriesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    entriesPerPage: jspb.Message.getFieldWithDefault(msg, 1, 0),
    pageNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    subjectModelId: (f = msg.getSubjectModelId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    subjectId: (f = msg.getSubjectId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetChangeLogEntriesRequest}
 */
proto.attachment.GetChangeLogEntriesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetChangeLogEntriesRequest;
  return proto.attachment.GetChangeLogEntriesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetChangeLogEntriesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetChangeLogEntriesRequest}
 */
proto.attachment.GetChangeLogEntriesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEntriesPerPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPageNumber(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSubjectModelId(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setSubjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetChangeLogEntriesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetChangeLogEntriesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetChangeLogEntriesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntriesPerPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPageNumber();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getSubjectModelId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getSubjectId();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 entries_per_page = 1;
 * @return {number}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.getEntriesPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.GetChangeLogEntriesRequest} returns this
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.setEntriesPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 page_number = 2;
 * @return {number}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.getPageNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.GetChangeLogEntriesRequest} returns this
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.setPageNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue subject_model_id = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.getSubjectModelId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.GetChangeLogEntriesRequest} returns this
*/
proto.attachment.GetChangeLogEntriesRequest.prototype.setSubjectModelId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetChangeLogEntriesRequest} returns this
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.clearSubjectModelId = function() {
  return this.setSubjectModelId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.hasSubjectModelId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue subject_id = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.getSubjectId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.GetChangeLogEntriesRequest} returns this
*/
proto.attachment.GetChangeLogEntriesRequest.prototype.setSubjectId = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetChangeLogEntriesRequest} returns this
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.clearSubjectId = function() {
  return this.setSubjectId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetChangeLogEntriesRequest.prototype.hasSubjectId = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.LogEntries.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LogEntries.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LogEntries.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LogEntries} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LogEntries.toObject = function(includeInstance, msg) {
  var f, obj = {
    logentriesList: jspb.Message.toObjectList(msg.getLogentriesList(),
    proto.attachment.LogEntry.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LogEntries}
 */
proto.attachment.LogEntries.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LogEntries;
  return proto.attachment.LogEntries.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LogEntries} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LogEntries}
 */
proto.attachment.LogEntries.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.LogEntry;
      reader.readMessage(value,proto.attachment.LogEntry.deserializeBinaryFromReader);
      msg.addLogentries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LogEntries.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LogEntries.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LogEntries} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LogEntries.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogentriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.LogEntry.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LogEntry LogEntries = 1;
 * @return {!Array<!proto.attachment.LogEntry>}
 */
proto.attachment.LogEntries.prototype.getLogentriesList = function() {
  return /** @type{!Array<!proto.attachment.LogEntry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.LogEntry, 1));
};


/**
 * @param {!Array<!proto.attachment.LogEntry>} value
 * @return {!proto.attachment.LogEntries} returns this
*/
proto.attachment.LogEntries.prototype.setLogentriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.LogEntry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.LogEntry}
 */
proto.attachment.LogEntries.prototype.addLogentries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.LogEntry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.LogEntries} returns this
 */
proto.attachment.LogEntries.prototype.clearLogentriesList = function() {
  return this.setLogentriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.Document.repeatedFields_ = [8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdat: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subjectmodeltype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    subjectmodelid: jspb.Message.getFieldWithDefault(msg, 5, ""),
    actortype: jspb.Message.getFieldWithDefault(msg, 6, 0),
    actor: jspb.Message.getFieldWithDefault(msg, 7, ""),
    auditperiodidsList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    doctype: jspb.Message.getFieldWithDefault(msg, 9, 0),
    mime: jspb.Message.getFieldWithDefault(msg, 10, ""),
    body: msg.getBody_asB64(),
    bodysize: jspb.Message.getFieldWithDefault(msg, 12, 0),
    caption: jspb.Message.getFieldWithDefault(msg, 13, ""),
    discoveryid: (f = msg.getDiscoveryid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    relevantdate: (f = msg.getRelevantdate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    requestid: jspb.Message.getFieldWithDefault(msg, 16, ""),
    allexclusions: (f = msg.getAllexclusions()) && proto.attachment.AllExclusions.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 18, ""),
    subjectid: jspb.Message.getFieldWithDefault(msg, 19, ""),
    contentid: jspb.Message.getFieldWithDefault(msg, 20, ""),
    isCustomized: (f = msg.getIsCustomized()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    linkerModelId: jspb.Message.getFieldWithDefault(msg, 22, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.Document}
 */
proto.attachment.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.Document;
  return proto.attachment.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.Document}
 */
proto.attachment.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountid(value);
      break;
    case 4:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setSubjectmodeltype(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectmodelid(value);
      break;
    case 6:
      var value = /** @type {!proto.attachment.ACTOR_TYPE} */ (reader.readEnum());
      msg.setActortype(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setActor(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.addAuditperiodids(value);
      break;
    case 9:
      var value = /** @type {!proto.attachment.DOCUMENT_TYPE} */ (reader.readEnum());
      msg.setDoctype(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 11:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBody(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBodysize(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 14:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setDiscoveryid(value);
      break;
    case 15:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRelevantdate(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestid(value);
      break;
    case 17:
      var value = new proto.attachment.AllExclusions;
      reader.readMessage(value,proto.attachment.AllExclusions.deserializeBinaryFromReader);
      msg.setAllexclusions(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectid(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentid(value);
      break;
    case 21:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsCustomized(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinkerModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedat();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAccountid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubjectmodeltype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getSubjectmodelid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActortype();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getActor();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAuditperiodidsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      8,
      f
    );
  }
  f = message.getDoctype();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBody_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      11,
      f
    );
  }
  f = message.getBodysize();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDiscoveryid();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRelevantdate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getRequestid();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAllexclusions();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.attachment.AllExclusions.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSubjectid();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getContentid();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getIsCustomized();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getLinkerModelId();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.attachment.Document.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 CreatedAt = 2;
 * @return {number}
 */
proto.attachment.Document.prototype.getCreatedat = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setCreatedat = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string AccountID = 3;
 * @return {string}
 */
proto.attachment.Document.prototype.getAccountid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setAccountid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.MODEL_TYPE SubjectModelType = 4;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.attachment.Document.prototype.getSubjectmodeltype = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setSubjectmodeltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string SubjectModelID = 5;
 * @return {string}
 */
proto.attachment.Document.prototype.getSubjectmodelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setSubjectmodelid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional ACTOR_TYPE ActorType = 6;
 * @return {!proto.attachment.ACTOR_TYPE}
 */
proto.attachment.Document.prototype.getActortype = function() {
  return /** @type {!proto.attachment.ACTOR_TYPE} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.attachment.ACTOR_TYPE} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setActortype = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string Actor = 7;
 * @return {string}
 */
proto.attachment.Document.prototype.getActor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setActor = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated string AuditPeriodIDs = 8;
 * @return {!Array<string>}
 */
proto.attachment.Document.prototype.getAuditperiodidsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setAuditperiodidsList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.addAuditperiodids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.clearAuditperiodidsList = function() {
  return this.setAuditperiodidsList([]);
};


/**
 * optional DOCUMENT_TYPE DocType = 9;
 * @return {!proto.attachment.DOCUMENT_TYPE}
 */
proto.attachment.Document.prototype.getDoctype = function() {
  return /** @type {!proto.attachment.DOCUMENT_TYPE} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.attachment.DOCUMENT_TYPE} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setDoctype = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional string Mime = 10;
 * @return {string}
 */
proto.attachment.Document.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bytes Body = 11;
 * @return {!(string|Uint8Array)}
 */
proto.attachment.Document.prototype.getBody = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * optional bytes Body = 11;
 * This is a type-conversion wrapper around `getBody()`
 * @return {string}
 */
proto.attachment.Document.prototype.getBody_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBody()));
};


/**
 * optional bytes Body = 11;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBody()`
 * @return {!Uint8Array}
 */
proto.attachment.Document.prototype.getBody_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBody()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setBody = function(value) {
  return jspb.Message.setProto3BytesField(this, 11, value);
};


/**
 * optional int64 BodySize = 12;
 * @return {number}
 */
proto.attachment.Document.prototype.getBodysize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setBodysize = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string Caption = 13;
 * @return {string}
 */
proto.attachment.Document.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional google.protobuf.StringValue DiscoveryId = 14;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.Document.prototype.getDiscoveryid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 14));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.Document} returns this
*/
proto.attachment.Document.prototype.setDiscoveryid = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.clearDiscoveryid = function() {
  return this.setDiscoveryid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.Document.prototype.hasDiscoveryid = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.protobuf.Timestamp RelevantDate = 15;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.attachment.Document.prototype.getRelevantdate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 15));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.attachment.Document} returns this
*/
proto.attachment.Document.prototype.setRelevantdate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.clearRelevantdate = function() {
  return this.setRelevantdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.Document.prototype.hasRelevantdate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string RequestId = 16;
 * @return {string}
 */
proto.attachment.Document.prototype.getRequestid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setRequestid = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional AllExclusions AllExclusions = 17;
 * @return {?proto.attachment.AllExclusions}
 */
proto.attachment.Document.prototype.getAllexclusions = function() {
  return /** @type{?proto.attachment.AllExclusions} */ (
    jspb.Message.getWrapperField(this, proto.attachment.AllExclusions, 17));
};


/**
 * @param {?proto.attachment.AllExclusions|undefined} value
 * @return {!proto.attachment.Document} returns this
*/
proto.attachment.Document.prototype.setAllexclusions = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.clearAllexclusions = function() {
  return this.setAllexclusions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.Document.prototype.hasAllexclusions = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string Description = 18;
 * @return {string}
 */
proto.attachment.Document.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string SubjectID = 19;
 * @return {string}
 */
proto.attachment.Document.prototype.getSubjectid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setSubjectid = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string ContentID = 20;
 * @return {string}
 */
proto.attachment.Document.prototype.getContentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setContentid = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional google.protobuf.BoolValue is_customized = 21;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.attachment.Document.prototype.getIsCustomized = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 21));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.attachment.Document} returns this
*/
proto.attachment.Document.prototype.setIsCustomized = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.clearIsCustomized = function() {
  return this.setIsCustomized(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.Document.prototype.hasIsCustomized = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional string linker_model_id = 22;
 * @return {string}
 */
proto.attachment.Document.prototype.getLinkerModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Document} returns this
 */
proto.attachment.Document.prototype.setLinkerModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AddDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AddDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AddDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AddDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: (f = msg.getDocument()) && proto.attachment.Document.toObject(includeInstance, f),
    overrideActor: (f = msg.getOverrideActor()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    overrideActorType: (f = msg.getOverrideActorType()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AddDocumentRequest}
 */
proto.attachment.AddDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AddDocumentRequest;
  return proto.attachment.AddDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AddDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AddDocumentRequest}
 */
proto.attachment.AddDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Document;
      reader.readMessage(value,proto.attachment.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setOverrideActor(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setOverrideActorType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AddDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AddDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AddDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AddDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.Document.serializeBinaryToWriter
    );
  }
  f = message.getOverrideActor();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getOverrideActorType();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional Document document = 1;
 * @return {?proto.attachment.Document}
 */
proto.attachment.AddDocumentRequest.prototype.getDocument = function() {
  return /** @type{?proto.attachment.Document} */ (
    jspb.Message.getWrapperField(this, proto.attachment.Document, 1));
};


/**
 * @param {?proto.attachment.Document|undefined} value
 * @return {!proto.attachment.AddDocumentRequest} returns this
*/
proto.attachment.AddDocumentRequest.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.AddDocumentRequest} returns this
 */
proto.attachment.AddDocumentRequest.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.AddDocumentRequest.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue override_actor = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.attachment.AddDocumentRequest.prototype.getOverrideActor = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.attachment.AddDocumentRequest} returns this
*/
proto.attachment.AddDocumentRequest.prototype.setOverrideActor = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.AddDocumentRequest} returns this
 */
proto.attachment.AddDocumentRequest.prototype.clearOverrideActor = function() {
  return this.setOverrideActor(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.AddDocumentRequest.prototype.hasOverrideActor = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue override_actor_type = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.attachment.AddDocumentRequest.prototype.getOverrideActorType = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.attachment.AddDocumentRequest} returns this
*/
proto.attachment.AddDocumentRequest.prototype.setOverrideActorType = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.AddDocumentRequest} returns this
 */
proto.attachment.AddDocumentRequest.prototype.clearOverrideActorType = function() {
  return this.setOverrideActorType(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.AddDocumentRequest.prototype.hasOverrideActorType = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LinkDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LinkDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LinkDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LinkDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: (f = msg.getDocument()) && proto.attachment.Document.toObject(includeInstance, f),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LinkDocumentRequest}
 */
proto.attachment.LinkDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LinkDocumentRequest;
  return proto.attachment.LinkDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LinkDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LinkDocumentRequest}
 */
proto.attachment.LinkDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Document;
      reader.readMessage(value,proto.attachment.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LinkDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LinkDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LinkDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LinkDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.Document.serializeBinaryToWriter
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional Document document = 1;
 * @return {?proto.attachment.Document}
 */
proto.attachment.LinkDocumentRequest.prototype.getDocument = function() {
  return /** @type{?proto.attachment.Document} */ (
    jspb.Message.getWrapperField(this, proto.attachment.Document, 1));
};


/**
 * @param {?proto.attachment.Document|undefined} value
 * @return {!proto.attachment.LinkDocumentRequest} returns this
*/
proto.attachment.LinkDocumentRequest.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.LinkDocumentRequest} returns this
 */
proto.attachment.LinkDocumentRequest.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.LinkDocumentRequest.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.attachment.LinkDocumentRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LinkDocumentRequest} returns this
 */
proto.attachment.LinkDocumentRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.LinkDocumentsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LinkDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LinkDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LinkDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LinkDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.attachment.LinkDocumentRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LinkDocumentsRequest}
 */
proto.attachment.LinkDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LinkDocumentsRequest;
  return proto.attachment.LinkDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LinkDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LinkDocumentsRequest}
 */
proto.attachment.LinkDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.LinkDocumentRequest;
      reader.readMessage(value,proto.attachment.LinkDocumentRequest.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LinkDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LinkDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LinkDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LinkDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.LinkDocumentRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LinkDocumentRequest documents = 1;
 * @return {!Array<!proto.attachment.LinkDocumentRequest>}
 */
proto.attachment.LinkDocumentsRequest.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.attachment.LinkDocumentRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.LinkDocumentRequest, 1));
};


/**
 * @param {!Array<!proto.attachment.LinkDocumentRequest>} value
 * @return {!proto.attachment.LinkDocumentsRequest} returns this
*/
proto.attachment.LinkDocumentsRequest.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.LinkDocumentRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.LinkDocumentRequest}
 */
proto.attachment.LinkDocumentsRequest.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.LinkDocumentRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.LinkDocumentsRequest} returns this
 */
proto.attachment.LinkDocumentsRequest.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.Documents.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.Documents.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.Documents.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.Documents} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Documents.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentsList: jspb.Message.toObjectList(msg.getDocumentsList(),
    proto.attachment.Document.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.Documents}
 */
proto.attachment.Documents.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.Documents;
  return proto.attachment.Documents.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.Documents} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.Documents}
 */
proto.attachment.Documents.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Document;
      reader.readMessage(value,proto.attachment.Document.deserializeBinaryFromReader);
      msg.addDocuments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.Documents.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.Documents.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.Documents} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Documents.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.Document.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Document Documents = 1;
 * @return {!Array<!proto.attachment.Document>}
 */
proto.attachment.Documents.prototype.getDocumentsList = function() {
  return /** @type{!Array<!proto.attachment.Document>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.Document, 1));
};


/**
 * @param {!Array<!proto.attachment.Document>} value
 * @return {!proto.attachment.Documents} returns this
*/
proto.attachment.Documents.prototype.setDocumentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.Document=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.Document}
 */
proto.attachment.Documents.prototype.addDocuments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.Document, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.Documents} returns this
 */
proto.attachment.Documents.prototype.clearDocumentsList = function() {
  return this.setDocumentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AddEvidenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AddEvidenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AddEvidenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AddEvidenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    document: (f = msg.getDocument()) && proto.attachment.Document.toObject(includeInstance, f),
    discoveryId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    receptorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serviceModelId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AddEvidenceRequest}
 */
proto.attachment.AddEvidenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AddEvidenceRequest;
  return proto.attachment.AddEvidenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AddEvidenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AddEvidenceRequest}
 */
proto.attachment.AddEvidenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Document;
      reader.readMessage(value,proto.attachment.Document.deserializeBinaryFromReader);
      msg.setDocument(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscoveryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceptorId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AddEvidenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AddEvidenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AddEvidenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AddEvidenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocument();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.Document.serializeBinaryToWriter
    );
  }
  f = message.getDiscoveryId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReceptorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServiceModelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional Document document = 1;
 * @return {?proto.attachment.Document}
 */
proto.attachment.AddEvidenceRequest.prototype.getDocument = function() {
  return /** @type{?proto.attachment.Document} */ (
    jspb.Message.getWrapperField(this, proto.attachment.Document, 1));
};


/**
 * @param {?proto.attachment.Document|undefined} value
 * @return {!proto.attachment.AddEvidenceRequest} returns this
*/
proto.attachment.AddEvidenceRequest.prototype.setDocument = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.AddEvidenceRequest} returns this
 */
proto.attachment.AddEvidenceRequest.prototype.clearDocument = function() {
  return this.setDocument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.AddEvidenceRequest.prototype.hasDocument = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string discovery_id = 2;
 * @return {string}
 */
proto.attachment.AddEvidenceRequest.prototype.getDiscoveryId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AddEvidenceRequest} returns this
 */
proto.attachment.AddEvidenceRequest.prototype.setDiscoveryId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string receptor_id = 3;
 * @return {string}
 */
proto.attachment.AddEvidenceRequest.prototype.getReceptorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AddEvidenceRequest} returns this
 */
proto.attachment.AddEvidenceRequest.prototype.setReceptorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string service_model_id = 4;
 * @return {string}
 */
proto.attachment.AddEvidenceRequest.prototype.getServiceModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AddEvidenceRequest} returns this
 */
proto.attachment.AddEvidenceRequest.prototype.setServiceModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.BulkAddEvidenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.BulkAddEvidenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.BulkAddEvidenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    documents: (f = msg.getDocuments()) && proto.attachment.Documents.toObject(includeInstance, f),
    identifier: (f = msg.getIdentifier()) && common_model_pb.Identifier.toObject(includeInstance, f),
    requestId: (f = msg.getRequestId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    uploadSize: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.BulkAddEvidenceRequest}
 */
proto.attachment.BulkAddEvidenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.BulkAddEvidenceRequest;
  return proto.attachment.BulkAddEvidenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.BulkAddEvidenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.BulkAddEvidenceRequest}
 */
proto.attachment.BulkAddEvidenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Documents;
      reader.readMessage(value,proto.attachment.Documents.deserializeBinaryFromReader);
      msg.setDocuments(value);
      break;
    case 2:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.setIdentifier(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRequestId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUploadSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.BulkAddEvidenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.BulkAddEvidenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.BulkAddEvidenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocuments();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.Documents.serializeBinaryToWriter
    );
  }
  f = message.getIdentifier();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getRequestId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getUploadSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional Documents documents = 1;
 * @return {?proto.attachment.Documents}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.getDocuments = function() {
  return /** @type{?proto.attachment.Documents} */ (
    jspb.Message.getWrapperField(this, proto.attachment.Documents, 1));
};


/**
 * @param {?proto.attachment.Documents|undefined} value
 * @return {!proto.attachment.BulkAddEvidenceRequest} returns this
*/
proto.attachment.BulkAddEvidenceRequest.prototype.setDocuments = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.BulkAddEvidenceRequest} returns this
 */
proto.attachment.BulkAddEvidenceRequest.prototype.clearDocuments = function() {
  return this.setDocuments(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.hasDocuments = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional common.Identifier identifier = 2;
 * @return {?proto.common.Identifier}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.getIdentifier = function() {
  return /** @type{?proto.common.Identifier} */ (
    jspb.Message.getWrapperField(this, common_model_pb.Identifier, 2));
};


/**
 * @param {?proto.common.Identifier|undefined} value
 * @return {!proto.attachment.BulkAddEvidenceRequest} returns this
*/
proto.attachment.BulkAddEvidenceRequest.prototype.setIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.BulkAddEvidenceRequest} returns this
 */
proto.attachment.BulkAddEvidenceRequest.prototype.clearIdentifier = function() {
  return this.setIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.hasIdentifier = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue request_id = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.getRequestId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.BulkAddEvidenceRequest} returns this
*/
proto.attachment.BulkAddEvidenceRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.BulkAddEvidenceRequest} returns this
 */
proto.attachment.BulkAddEvidenceRequest.prototype.clearRequestId = function() {
  return this.setRequestId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.hasRequestId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 upload_size = 4;
 * @return {number}
 */
proto.attachment.BulkAddEvidenceRequest.prototype.getUploadSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.BulkAddEvidenceRequest} returns this
 */
proto.attachment.BulkAddEvidenceRequest.prototype.setUploadSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.DocumentPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.DocumentPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.DocumentPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DocumentPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditperiodid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.DocumentPeriod}
 */
proto.attachment.DocumentPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.DocumentPeriod;
  return proto.attachment.DocumentPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.DocumentPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.DocumentPeriod}
 */
proto.attachment.DocumentPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditperiodid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.DocumentPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.DocumentPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.DocumentPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DocumentPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditperiodid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string DocumentID = 1;
 * @return {string}
 */
proto.attachment.DocumentPeriod.prototype.getDocumentid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.DocumentPeriod} returns this
 */
proto.attachment.DocumentPeriod.prototype.setDocumentid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string AuditPeriodID = 2;
 * @return {string}
 */
proto.attachment.DocumentPeriod.prototype.getAuditperiodid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.DocumentPeriod} returns this
 */
proto.attachment.DocumentPeriod.prototype.setAuditperiodid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.Caption.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.Caption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.Caption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Caption.toObject = function(includeInstance, msg) {
  var f, obj = {
    oid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.Caption}
 */
proto.attachment.Caption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.Caption;
  return proto.attachment.Caption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.Caption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.Caption}
 */
proto.attachment.Caption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.Caption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.Caption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.Caption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Caption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string OID = 1;
 * @return {string}
 */
proto.attachment.Caption.prototype.getOid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Caption} returns this
 */
proto.attachment.Caption.prototype.setOid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Caption = 2;
 * @return {string}
 */
proto.attachment.Caption.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Caption} returns this
 */
proto.attachment.Caption.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.UpdateRelevantDateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.UpdateRelevantDateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.UpdateRelevantDateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.UpdateRelevantDateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    relevantDate: (f = msg.getRelevantDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.UpdateRelevantDateRequest}
 */
proto.attachment.UpdateRelevantDateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.UpdateRelevantDateRequest;
  return proto.attachment.UpdateRelevantDateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.UpdateRelevantDateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.UpdateRelevantDateRequest}
 */
proto.attachment.UpdateRelevantDateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRelevantDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.UpdateRelevantDateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.UpdateRelevantDateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.UpdateRelevantDateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.UpdateRelevantDateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRelevantDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.UpdateRelevantDateRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.UpdateRelevantDateRequest} returns this
 */
proto.attachment.UpdateRelevantDateRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp relevant_date = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.attachment.UpdateRelevantDateRequest.prototype.getRelevantDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.attachment.UpdateRelevantDateRequest} returns this
*/
proto.attachment.UpdateRelevantDateRequest.prototype.setRelevantDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.UpdateRelevantDateRequest} returns this
 */
proto.attachment.UpdateRelevantDateRequest.prototype.clearRelevantDate = function() {
  return this.setRelevantDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.UpdateRelevantDateRequest.prototype.hasRelevantDate = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AuditPeriod.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AuditPeriod.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AuditPeriod} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditPeriod.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    subjectModelId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actor: jspb.Message.getFieldWithDefault(msg, 5, ""),
    beginTime: jspb.Message.getFieldWithDefault(msg, 6, 0),
    endTime: jspb.Message.getFieldWithDefault(msg, 7, 0),
    notes: jspb.Message.getFieldWithDefault(msg, 8, ""),
    modelVersion: jspb.Message.getFieldWithDefault(msg, 9, ""),
    modelIds: (f = msg.getModelIds()) && proto.attachment.AuditModelIDs.toObject(includeInstance, f),
    auditor: jspb.Message.getFieldWithDefault(msg, 11, ""),
    auditTime: jspb.Message.getFieldWithDefault(msg, 12, 0),
    closed: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AuditPeriod}
 */
proto.attachment.AuditPeriod.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AuditPeriod;
  return proto.attachment.AuditPeriod.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AuditPeriod} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AuditPeriod}
 */
proto.attachment.AuditPeriod.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubjectModelId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setActor(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBeginTime(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndTime(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelVersion(value);
      break;
    case 10:
      var value = new proto.attachment.AuditModelIDs;
      reader.readMessage(value,proto.attachment.AuditModelIDs.deserializeBinaryFromReader);
      msg.setModelIds(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditor(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAuditTime(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClosed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AuditPeriod.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AuditPeriod.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AuditPeriod} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditPeriod.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSubjectModelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActor();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBeginTime();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getEndTime();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getModelVersion();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getModelIds();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.attachment.AuditModelIDs.serializeBinaryToWriter
    );
  }
  f = message.getAuditor();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getAuditTime();
  if (f !== 0) {
    writer.writeInt64(
      12,
      f
    );
  }
  f = message.getClosed();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.AuditPeriod.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 created_at = 2;
 * @return {number}
 */
proto.attachment.AuditPeriod.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.attachment.AuditPeriod.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string subject_model_id = 4;
 * @return {string}
 */
proto.attachment.AuditPeriod.prototype.getSubjectModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setSubjectModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string actor = 5;
 * @return {string}
 */
proto.attachment.AuditPeriod.prototype.getActor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setActor = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 begin_time = 6;
 * @return {number}
 */
proto.attachment.AuditPeriod.prototype.getBeginTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setBeginTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 end_time = 7;
 * @return {number}
 */
proto.attachment.AuditPeriod.prototype.getEndTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setEndTime = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string notes = 8;
 * @return {string}
 */
proto.attachment.AuditPeriod.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string model_version = 9;
 * @return {string}
 */
proto.attachment.AuditPeriod.prototype.getModelVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setModelVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional AuditModelIDs model_ids = 10;
 * @return {?proto.attachment.AuditModelIDs}
 */
proto.attachment.AuditPeriod.prototype.getModelIds = function() {
  return /** @type{?proto.attachment.AuditModelIDs} */ (
    jspb.Message.getWrapperField(this, proto.attachment.AuditModelIDs, 10));
};


/**
 * @param {?proto.attachment.AuditModelIDs|undefined} value
 * @return {!proto.attachment.AuditPeriod} returns this
*/
proto.attachment.AuditPeriod.prototype.setModelIds = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.clearModelIds = function() {
  return this.setModelIds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.AuditPeriod.prototype.hasModelIds = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string auditor = 11;
 * @return {string}
 */
proto.attachment.AuditPeriod.prototype.getAuditor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setAuditor = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int64 audit_time = 12;
 * @return {number}
 */
proto.attachment.AuditPeriod.prototype.getAuditTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setAuditTime = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional bool closed = 13;
 * @return {boolean}
 */
proto.attachment.AuditPeriod.prototype.getClosed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.AuditPeriod} returns this
 */
proto.attachment.AuditPeriod.prototype.setClosed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AuditModelID.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AuditModelID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AuditModelID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditModelID.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modeltype: jspb.Message.getFieldWithDefault(msg, 2, 0),
    modelid: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AuditModelID}
 */
proto.attachment.AuditModelID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AuditModelID;
  return proto.attachment.AuditModelID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AuditModelID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AuditModelID}
 */
proto.attachment.AuditModelID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditid(value);
      break;
    case 2:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setModeltype(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AuditModelID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AuditModelID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AuditModelID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditModelID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModeltype();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getModelid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string AuditID = 1;
 * @return {string}
 */
proto.attachment.AuditModelID.prototype.getAuditid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditModelID} returns this
 */
proto.attachment.AuditModelID.prototype.setAuditid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.MODEL_TYPE ModelType = 2;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.attachment.AuditModelID.prototype.getModeltype = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.attachment.AuditModelID} returns this
 */
proto.attachment.AuditModelID.prototype.setModeltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string ModelID = 3;
 * @return {string}
 */
proto.attachment.AuditModelID.prototype.getModelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.AuditModelID} returns this
 */
proto.attachment.AuditModelID.prototype.setModelid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.AuditModelIDs.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AuditModelIDs.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AuditModelIDs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AuditModelIDs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditModelIDs.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    proto.attachment.AuditModelID.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AuditModelIDs}
 */
proto.attachment.AuditModelIDs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AuditModelIDs;
  return proto.attachment.AuditModelIDs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AuditModelIDs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AuditModelIDs}
 */
proto.attachment.AuditModelIDs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.AuditModelID;
      reader.readMessage(value,proto.attachment.AuditModelID.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AuditModelIDs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AuditModelIDs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AuditModelIDs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditModelIDs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.AuditModelID.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AuditModelID IDs = 1;
 * @return {!Array<!proto.attachment.AuditModelID>}
 */
proto.attachment.AuditModelIDs.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.attachment.AuditModelID>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.AuditModelID, 1));
};


/**
 * @param {!Array<!proto.attachment.AuditModelID>} value
 * @return {!proto.attachment.AuditModelIDs} returns this
*/
proto.attachment.AuditModelIDs.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.AuditModelID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.AuditModelID}
 */
proto.attachment.AuditModelIDs.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.AuditModelID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.AuditModelIDs} returns this
 */
proto.attachment.AuditModelIDs.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.AuditPeriods.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AuditPeriods.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AuditPeriods.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AuditPeriods} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditPeriods.toObject = function(includeInstance, msg) {
  var f, obj = {
    periodsList: jspb.Message.toObjectList(msg.getPeriodsList(),
    proto.attachment.AuditPeriod.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AuditPeriods}
 */
proto.attachment.AuditPeriods.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AuditPeriods;
  return proto.attachment.AuditPeriods.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AuditPeriods} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AuditPeriods}
 */
proto.attachment.AuditPeriods.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.AuditPeriod;
      reader.readMessage(value,proto.attachment.AuditPeriod.deserializeBinaryFromReader);
      msg.addPeriods(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AuditPeriods.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AuditPeriods.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AuditPeriods} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AuditPeriods.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPeriodsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.AuditPeriod.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AuditPeriod Periods = 1;
 * @return {!Array<!proto.attachment.AuditPeriod>}
 */
proto.attachment.AuditPeriods.prototype.getPeriodsList = function() {
  return /** @type{!Array<!proto.attachment.AuditPeriod>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.AuditPeriod, 1));
};


/**
 * @param {!Array<!proto.attachment.AuditPeriod>} value
 * @return {!proto.attachment.AuditPeriods} returns this
*/
proto.attachment.AuditPeriods.prototype.setPeriodsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.AuditPeriod=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.AuditPeriod}
 */
proto.attachment.AuditPeriods.prototype.addPeriods = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.AuditPeriod, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.AuditPeriods} returns this
 */
proto.attachment.AuditPeriods.prototype.clearPeriodsList = function() {
  return this.setPeriodsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.IncludeModelIDs.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.IncludeModelIDs.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.IncludeModelIDs} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IncludeModelIDs.toObject = function(includeInstance, msg) {
  var f, obj = {
    includemodelids: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.IncludeModelIDs}
 */
proto.attachment.IncludeModelIDs.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.IncludeModelIDs;
  return proto.attachment.IncludeModelIDs.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.IncludeModelIDs} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.IncludeModelIDs}
 */
proto.attachment.IncludeModelIDs.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIncludemodelids(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.IncludeModelIDs.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.IncludeModelIDs.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.IncludeModelIDs} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IncludeModelIDs.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIncludemodelids();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool IncludeModelIDs = 1;
 * @return {boolean}
 */
proto.attachment.IncludeModelIDs.prototype.getIncludemodelids = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.IncludeModelIDs} returns this
 */
proto.attachment.IncludeModelIDs.prototype.setIncludemodelids = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.CloseAudit.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.CloseAudit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.CloseAudit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.CloseAudit.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditperiodid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    modelversion: jspb.Message.getFieldWithDefault(msg, 2, ""),
    endtime: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.CloseAudit}
 */
proto.attachment.CloseAudit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.CloseAudit;
  return proto.attachment.CloseAudit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.CloseAudit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.CloseAudit}
 */
proto.attachment.CloseAudit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditperiodid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelversion(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.CloseAudit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.CloseAudit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.CloseAudit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.CloseAudit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditperiodid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getModelversion();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEndtime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string AuditPeriodID = 1;
 * @return {string}
 */
proto.attachment.CloseAudit.prototype.getAuditperiodid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.CloseAudit} returns this
 */
proto.attachment.CloseAudit.prototype.setAuditperiodid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ModelVersion = 2;
 * @return {string}
 */
proto.attachment.CloseAudit.prototype.getModelversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.CloseAudit} returns this
 */
proto.attachment.CloseAudit.prototype.setModelversion = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 EndTime = 3;
 * @return {number}
 */
proto.attachment.CloseAudit.prototype.getEndtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.CloseAudit} returns this
 */
proto.attachment.CloseAudit.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.CompleteAudit.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.CompleteAudit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.CompleteAudit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.CompleteAudit.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditperiodid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    auditor: jspb.Message.getFieldWithDefault(msg, 2, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.CompleteAudit}
 */
proto.attachment.CompleteAudit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.CompleteAudit;
  return proto.attachment.CompleteAudit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.CompleteAudit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.CompleteAudit}
 */
proto.attachment.CompleteAudit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditperiodid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuditor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.CompleteAudit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.CompleteAudit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.CompleteAudit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.CompleteAudit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditperiodid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAuditor();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string AuditPeriodID = 1;
 * @return {string}
 */
proto.attachment.CompleteAudit.prototype.getAuditperiodid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.CompleteAudit} returns this
 */
proto.attachment.CompleteAudit.prototype.setAuditperiodid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Auditor = 2;
 * @return {string}
 */
proto.attachment.CompleteAudit.prototype.getAuditor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.CompleteAudit} returns this
 */
proto.attachment.CompleteAudit.prototype.setAuditor = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Notes = 3;
 * @return {string}
 */
proto.attachment.CompleteAudit.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.CompleteAudit} returns this
 */
proto.attachment.CompleteAudit.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.TimeQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.TimeQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.TimeQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.TimeQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    begintime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endtime: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.TimeQuery}
 */
proto.attachment.TimeQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.TimeQuery;
  return proto.attachment.TimeQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.TimeQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.TimeQuery}
 */
proto.attachment.TimeQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBegintime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndtime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.TimeQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.TimeQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.TimeQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.TimeQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBegintime();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndtime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 BeginTime = 1;
 * @return {number}
 */
proto.attachment.TimeQuery.prototype.getBegintime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.TimeQuery} returns this
 */
proto.attachment.TimeQuery.prototype.setBegintime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 EndTime = 2;
 * @return {number}
 */
proto.attachment.TimeQuery.prototype.getEndtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.TimeQuery} returns this
 */
proto.attachment.TimeQuery.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ModelTypeQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ModelTypeQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ModelTypeQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ModelTypeQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    modeltype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    begintime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endtime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    excludebody: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ModelTypeQuery}
 */
proto.attachment.ModelTypeQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ModelTypeQuery;
  return proto.attachment.ModelTypeQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ModelTypeQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ModelTypeQuery}
 */
proto.attachment.ModelTypeQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setModeltype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBegintime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndtime(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludebody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ModelTypeQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ModelTypeQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ModelTypeQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ModelTypeQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModeltype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getBegintime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndtime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getExcludebody();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional common.MODEL_TYPE ModelType = 1;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.attachment.ModelTypeQuery.prototype.getModeltype = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.attachment.ModelTypeQuery} returns this
 */
proto.attachment.ModelTypeQuery.prototype.setModeltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional int64 BeginTime = 2;
 * @return {number}
 */
proto.attachment.ModelTypeQuery.prototype.getBegintime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.ModelTypeQuery} returns this
 */
proto.attachment.ModelTypeQuery.prototype.setBegintime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 EndTime = 3;
 * @return {number}
 */
proto.attachment.ModelTypeQuery.prototype.getEndtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.ModelTypeQuery} returns this
 */
proto.attachment.ModelTypeQuery.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool ExcludeBody = 4;
 * @return {boolean}
 */
proto.attachment.ModelTypeQuery.prototype.getExcludebody = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.ModelTypeQuery} returns this
 */
proto.attachment.ModelTypeQuery.prototype.setExcludebody = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.DocumentTypeQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.DocumentTypeQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.DocumentTypeQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DocumentTypeQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    documenttype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    excludebody: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.DocumentTypeQuery}
 */
proto.attachment.DocumentTypeQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.DocumentTypeQuery;
  return proto.attachment.DocumentTypeQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.DocumentTypeQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.DocumentTypeQuery}
 */
proto.attachment.DocumentTypeQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.attachment.DOCUMENT_TYPE} */ (reader.readEnum());
      msg.setDocumenttype(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludebody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.DocumentTypeQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.DocumentTypeQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.DocumentTypeQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DocumentTypeQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumenttype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getExcludebody();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional DOCUMENT_TYPE DocumentType = 1;
 * @return {!proto.attachment.DOCUMENT_TYPE}
 */
proto.attachment.DocumentTypeQuery.prototype.getDocumenttype = function() {
  return /** @type {!proto.attachment.DOCUMENT_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.attachment.DOCUMENT_TYPE} value
 * @return {!proto.attachment.DocumentTypeQuery} returns this
 */
proto.attachment.DocumentTypeQuery.prototype.setDocumenttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional bool ExcludeBody = 2;
 * @return {boolean}
 */
proto.attachment.DocumentTypeQuery.prototype.getExcludebody = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.DocumentTypeQuery} returns this
 */
proto.attachment.DocumentTypeQuery.prototype.setExcludebody = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.IDsQuery.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.IDsQuery.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.IDsQuery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.IDsQuery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IDsQuery.toObject = function(includeInstance, msg) {
  var f, obj = {
    subjectidsList: jspb.Message.toObjectList(msg.getSubjectidsList(),
    common_model_pb.Identifier.toObject, includeInstance),
    begintime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    endtime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    excludebody: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.IDsQuery}
 */
proto.attachment.IDsQuery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.IDsQuery;
  return proto.attachment.IDsQuery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.IDsQuery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.IDsQuery}
 */
proto.attachment.IDsQuery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.addSubjectids(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setBegintime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndtime(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExcludebody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.IDsQuery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.IDsQuery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.IDsQuery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IDsQuery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSubjectidsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getBegintime();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getEndtime();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getExcludebody();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * repeated common.Identifier SubjectIDs = 1;
 * @return {!Array<!proto.common.Identifier>}
 */
proto.attachment.IDsQuery.prototype.getSubjectidsList = function() {
  return /** @type{!Array<!proto.common.Identifier>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_model_pb.Identifier, 1));
};


/**
 * @param {!Array<!proto.common.Identifier>} value
 * @return {!proto.attachment.IDsQuery} returns this
*/
proto.attachment.IDsQuery.prototype.setSubjectidsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.common.Identifier=} opt_value
 * @param {number=} opt_index
 * @return {!proto.common.Identifier}
 */
proto.attachment.IDsQuery.prototype.addSubjectids = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.common.Identifier, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.IDsQuery} returns this
 */
proto.attachment.IDsQuery.prototype.clearSubjectidsList = function() {
  return this.setSubjectidsList([]);
};


/**
 * optional int64 BeginTime = 2;
 * @return {number}
 */
proto.attachment.IDsQuery.prototype.getBegintime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.IDsQuery} returns this
 */
proto.attachment.IDsQuery.prototype.setBegintime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 EndTime = 3;
 * @return {number}
 */
proto.attachment.IDsQuery.prototype.getEndtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.IDsQuery} returns this
 */
proto.attachment.IDsQuery.prototype.setEndtime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool ExcludeBody = 4;
 * @return {boolean}
 */
proto.attachment.IDsQuery.prototype.getExcludebody = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.IDsQuery} returns this
 */
proto.attachment.IDsQuery.prototype.setExcludebody = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.IDCount.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.IDCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.IDCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IDCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    modeltype: jspb.Message.getFieldWithDefault(msg, 1, 0),
    modelid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    count: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.IDCount}
 */
proto.attachment.IDCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.IDCount;
  return proto.attachment.IDCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.IDCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.IDCount}
 */
proto.attachment.IDCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.common.MODEL_TYPE} */ (reader.readEnum());
      msg.setModeltype(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.IDCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.IDCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.IDCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IDCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModeltype();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getModelid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional common.MODEL_TYPE ModelType = 1;
 * @return {!proto.common.MODEL_TYPE}
 */
proto.attachment.IDCount.prototype.getModeltype = function() {
  return /** @type {!proto.common.MODEL_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.common.MODEL_TYPE} value
 * @return {!proto.attachment.IDCount} returns this
 */
proto.attachment.IDCount.prototype.setModeltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string ModelID = 2;
 * @return {string}
 */
proto.attachment.IDCount.prototype.getModelid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.IDCount} returns this
 */
proto.attachment.IDCount.prototype.setModelid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int64 Count = 3;
 * @return {number}
 */
proto.attachment.IDCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.IDCount} returns this
 */
proto.attachment.IDCount.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.IDCounts.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.IDCounts.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.IDCounts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.IDCounts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IDCounts.toObject = function(includeInstance, msg) {
  var f, obj = {
    countsList: jspb.Message.toObjectList(msg.getCountsList(),
    proto.attachment.IDCount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.IDCounts}
 */
proto.attachment.IDCounts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.IDCounts;
  return proto.attachment.IDCounts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.IDCounts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.IDCounts}
 */
proto.attachment.IDCounts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.IDCount;
      reader.readMessage(value,proto.attachment.IDCount.deserializeBinaryFromReader);
      msg.addCounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.IDCounts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.IDCounts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.IDCounts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.IDCounts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.IDCount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated IDCount Counts = 1;
 * @return {!Array<!proto.attachment.IDCount>}
 */
proto.attachment.IDCounts.prototype.getCountsList = function() {
  return /** @type{!Array<!proto.attachment.IDCount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.IDCount, 1));
};


/**
 * @param {!Array<!proto.attachment.IDCount>} value
 * @return {!proto.attachment.IDCounts} returns this
*/
proto.attachment.IDCounts.prototype.setCountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.IDCount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.IDCount}
 */
proto.attachment.IDCounts.prototype.addCounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.IDCount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.IDCounts} returns this
 */
proto.attachment.IDCounts.prototype.clearCountsList = function() {
  return this.setCountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LogEntryCount.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LogEntryCount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LogEntryCount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LogEntryCount.toObject = function(includeInstance, msg) {
  var f, obj = {
    count: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LogEntryCount}
 */
proto.attachment.LogEntryCount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LogEntryCount;
  return proto.attachment.LogEntryCount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LogEntryCount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LogEntryCount}
 */
proto.attachment.LogEntryCount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LogEntryCount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LogEntryCount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LogEntryCount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LogEntryCount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
};


/**
 * optional int64 Count = 1;
 * @return {number}
 */
proto.attachment.LogEntryCount.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.LogEntryCount} returns this
 */
proto.attachment.LogEntryCount.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceBodyByOIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceBodyByOIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceBodyByOIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    format: jspb.Message.getFieldWithDefault(msg, 2, 0),
    evidenceTestResultId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceBodyByOIDRequest}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceBodyByOIDRequest;
  return proto.attachment.GetEvidenceBodyByOIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceBodyByOIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceBodyByOIDRequest}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.attachment.GetEvidenceBodyByOIDRequest.FORMAT} */ (reader.readEnum());
      msg.setFormat(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidenceTestResultId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceBodyByOIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceBodyByOIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceBodyByOIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFormat();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getEvidenceTestResultId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.FORMAT = {
  CSV: 0,
  MARKDOWN: 1,
  PROTOBUF: 2
};

/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetEvidenceBodyByOIDRequest} returns this
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional FORMAT format = 2;
 * @return {!proto.attachment.GetEvidenceBodyByOIDRequest.FORMAT}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.getFormat = function() {
  return /** @type {!proto.attachment.GetEvidenceBodyByOIDRequest.FORMAT} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.attachment.GetEvidenceBodyByOIDRequest.FORMAT} value
 * @return {!proto.attachment.GetEvidenceBodyByOIDRequest} returns this
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.setFormat = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string evidence_test_result_id = 3;
 * @return {string}
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.getEvidenceTestResultId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetEvidenceBodyByOIDRequest} returns this
 */
proto.attachment.GetEvidenceBodyByOIDRequest.prototype.setEvidenceTestResultId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.GetEvidenceBodyByOIDResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceBodyByOIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceBodyByOIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceBodyByOIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidence: (f = msg.getEvidence()) && common_payload_pb.Payload.toObject(includeInstance, f),
    exclusionsList: jspb.Message.toObjectList(msg.getExclusionsList(),
    proto.attachment.Excluded.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceBodyByOIDResponse}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceBodyByOIDResponse;
  return proto.attachment.GetEvidenceBodyByOIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceBodyByOIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceBodyByOIDResponse}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_payload_pb.Payload;
      reader.readMessage(value,common_payload_pb.Payload.deserializeBinaryFromReader);
      msg.setEvidence(value);
      break;
    case 2:
      var value = new proto.attachment.Excluded;
      reader.readMessage(value,proto.attachment.Excluded.deserializeBinaryFromReader);
      msg.addExclusions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceBodyByOIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceBodyByOIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceBodyByOIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidence();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_payload_pb.Payload.serializeBinaryToWriter
    );
  }
  f = message.getExclusionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.attachment.Excluded.serializeBinaryToWriter
    );
  }
};


/**
 * optional common.Payload evidence = 1;
 * @return {?proto.common.Payload}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.getEvidence = function() {
  return /** @type{?proto.common.Payload} */ (
    jspb.Message.getWrapperField(this, common_payload_pb.Payload, 1));
};


/**
 * @param {?proto.common.Payload|undefined} value
 * @return {!proto.attachment.GetEvidenceBodyByOIDResponse} returns this
*/
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.setEvidence = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetEvidenceBodyByOIDResponse} returns this
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.clearEvidence = function() {
  return this.setEvidence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.hasEvidence = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Excluded exclusions = 2;
 * @return {!Array<!proto.attachment.Excluded>}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.getExclusionsList = function() {
  return /** @type{!Array<!proto.attachment.Excluded>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.Excluded, 2));
};


/**
 * @param {!Array<!proto.attachment.Excluded>} value
 * @return {!proto.attachment.GetEvidenceBodyByOIDResponse} returns this
*/
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.setExclusionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.attachment.Excluded=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.Excluded}
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.addExclusions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.attachment.Excluded, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetEvidenceBodyByOIDResponse} returns this
 */
proto.attachment.GetEvidenceBodyByOIDResponse.prototype.clearExclusionsList = function() {
  return this.setExclusionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetLatestEvidenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetLatestEvidenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetLatestEvidenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetLatestEvidenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timeRange: (f = msg.getTimeRange()) && common_time_pb.TimeRange.toObject(includeInstance, f),
    documentRequestId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetLatestEvidenceRequest}
 */
proto.attachment.GetLatestEvidenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetLatestEvidenceRequest;
  return proto.attachment.GetLatestEvidenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetLatestEvidenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetLatestEvidenceRequest}
 */
proto.attachment.GetLatestEvidenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = new common_time_pb.TimeRange;
      reader.readMessage(value,common_time_pb.TimeRange.deserializeBinaryFromReader);
      msg.setTimeRange(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetLatestEvidenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetLatestEvidenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetLatestEvidenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetLatestEvidenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimeRange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_time_pb.TimeRange.serializeBinaryToWriter
    );
  }
  f = message.getDocumentRequestId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.attachment.GetLatestEvidenceRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetLatestEvidenceRequest} returns this
 */
proto.attachment.GetLatestEvidenceRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional common.TimeRange time_range = 2;
 * @return {?proto.common.TimeRange}
 */
proto.attachment.GetLatestEvidenceRequest.prototype.getTimeRange = function() {
  return /** @type{?proto.common.TimeRange} */ (
    jspb.Message.getWrapperField(this, common_time_pb.TimeRange, 2));
};


/**
 * @param {?proto.common.TimeRange|undefined} value
 * @return {!proto.attachment.GetLatestEvidenceRequest} returns this
*/
proto.attachment.GetLatestEvidenceRequest.prototype.setTimeRange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetLatestEvidenceRequest} returns this
 */
proto.attachment.GetLatestEvidenceRequest.prototype.clearTimeRange = function() {
  return this.setTimeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetLatestEvidenceRequest.prototype.hasTimeRange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string document_request_id = 3;
 * @return {string}
 */
proto.attachment.GetLatestEvidenceRequest.prototype.getDocumentRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetLatestEvidenceRequest} returns this
 */
proto.attachment.GetLatestEvidenceRequest.prototype.setDocumentRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.Excluded.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.Excluded.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.Excluded.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.Excluded} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Excluded.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    instanceNamesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.Excluded}
 */
proto.attachment.Excluded.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.Excluded;
  return proto.attachment.Excluded.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.Excluded} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.Excluded}
 */
proto.attachment.Excluded.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addInstanceNames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.Excluded.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.Excluded.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.Excluded} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Excluded.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstanceNamesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string service_id = 1;
 * @return {string}
 */
proto.attachment.Excluded.prototype.getServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Excluded} returns this
 */
proto.attachment.Excluded.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string instance_names = 2;
 * @return {!Array<string>}
 */
proto.attachment.Excluded.prototype.getInstanceNamesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.Excluded} returns this
 */
proto.attachment.Excluded.prototype.setInstanceNamesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.Excluded} returns this
 */
proto.attachment.Excluded.prototype.addInstanceNames = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.Excluded} returns this
 */
proto.attachment.Excluded.prototype.clearInstanceNamesList = function() {
  return this.setInstanceNamesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.AllExclusions.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.AllExclusions.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.AllExclusions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.AllExclusions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AllExclusions.toObject = function(includeInstance, msg) {
  var f, obj = {
    exclusionsList: jspb.Message.toObjectList(msg.getExclusionsList(),
    proto.attachment.Excluded.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.AllExclusions}
 */
proto.attachment.AllExclusions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.AllExclusions;
  return proto.attachment.AllExclusions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.AllExclusions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.AllExclusions}
 */
proto.attachment.AllExclusions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Excluded;
      reader.readMessage(value,proto.attachment.Excluded.deserializeBinaryFromReader);
      msg.addExclusions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.AllExclusions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.AllExclusions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.AllExclusions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.AllExclusions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExclusionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.Excluded.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Excluded exclusions = 1;
 * @return {!Array<!proto.attachment.Excluded>}
 */
proto.attachment.AllExclusions.prototype.getExclusionsList = function() {
  return /** @type{!Array<!proto.attachment.Excluded>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.Excluded, 1));
};


/**
 * @param {!Array<!proto.attachment.Excluded>} value
 * @return {!proto.attachment.AllExclusions} returns this
*/
proto.attachment.AllExclusions.prototype.setExclusionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.Excluded=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.Excluded}
 */
proto.attachment.AllExclusions.prototype.addExclusions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.Excluded, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.AllExclusions} returns this
 */
proto.attachment.AllExclusions.prototype.clearExclusionsList = function() {
  return this.setExclusionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceDiscoveryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceDiscoveryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceDiscoveryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceDiscoveryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceDiscoveryRequest}
 */
proto.attachment.GetEvidenceDiscoveryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceDiscoveryRequest;
  return proto.attachment.GetEvidenceDiscoveryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceDiscoveryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceDiscoveryRequest}
 */
proto.attachment.GetEvidenceDiscoveryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidenceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceDiscoveryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceDiscoveryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceDiscoveryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceDiscoveryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string evidence_id = 1;
 * @return {string}
 */
proto.attachment.GetEvidenceDiscoveryRequest.prototype.getEvidenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetEvidenceDiscoveryRequest} returns this
 */
proto.attachment.GetEvidenceDiscoveryRequest.prototype.setEvidenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.GetEvidenceDiscoveryResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetEvidenceDiscoveryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetEvidenceDiscoveryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetEvidenceDiscoveryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceDiscoveryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceDiscoveriesList: jspb.Message.toObjectList(msg.getServiceDiscoveriesList(),
    proto.attachment.ServiceDiscovery.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetEvidenceDiscoveryResponse}
 */
proto.attachment.GetEvidenceDiscoveryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetEvidenceDiscoveryResponse;
  return proto.attachment.GetEvidenceDiscoveryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetEvidenceDiscoveryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetEvidenceDiscoveryResponse}
 */
proto.attachment.GetEvidenceDiscoveryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.ServiceDiscovery;
      reader.readMessage(value,proto.attachment.ServiceDiscovery.deserializeBinaryFromReader);
      msg.addServiceDiscoveries(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetEvidenceDiscoveryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetEvidenceDiscoveryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetEvidenceDiscoveryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetEvidenceDiscoveryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceDiscoveriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.ServiceDiscovery.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ServiceDiscovery service_discoveries = 1;
 * @return {!Array<!proto.attachment.ServiceDiscovery>}
 */
proto.attachment.GetEvidenceDiscoveryResponse.prototype.getServiceDiscoveriesList = function() {
  return /** @type{!Array<!proto.attachment.ServiceDiscovery>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.ServiceDiscovery, 1));
};


/**
 * @param {!Array<!proto.attachment.ServiceDiscovery>} value
 * @return {!proto.attachment.GetEvidenceDiscoveryResponse} returns this
*/
proto.attachment.GetEvidenceDiscoveryResponse.prototype.setServiceDiscoveriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.ServiceDiscovery=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.ServiceDiscovery}
 */
proto.attachment.GetEvidenceDiscoveryResponse.prototype.addServiceDiscoveries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.ServiceDiscovery, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetEvidenceDiscoveryResponse} returns this
 */
proto.attachment.GetEvidenceDiscoveryResponse.prototype.clearServiceDiscoveriesList = function() {
  return this.setServiceDiscoveriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.ServiceDiscovery.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ServiceDiscovery.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ServiceDiscovery.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ServiceDiscovery} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ServiceDiscovery.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    apiCallsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    discovery: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ServiceDiscovery}
 */
proto.attachment.ServiceDiscovery.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ServiceDiscovery;
  return proto.attachment.ServiceDiscovery.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ServiceDiscovery} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ServiceDiscovery}
 */
proto.attachment.ServiceDiscovery.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addApiCalls(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscovery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ServiceDiscovery.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ServiceDiscovery.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ServiceDiscovery} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ServiceDiscovery.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApiCallsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getDiscovery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string service_id = 1;
 * @return {string}
 */
proto.attachment.ServiceDiscovery.prototype.getServiceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.ServiceDiscovery} returns this
 */
proto.attachment.ServiceDiscovery.prototype.setServiceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated string api_calls = 2;
 * @return {!Array<string>}
 */
proto.attachment.ServiceDiscovery.prototype.getApiCallsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.attachment.ServiceDiscovery} returns this
 */
proto.attachment.ServiceDiscovery.prototype.setApiCallsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.attachment.ServiceDiscovery} returns this
 */
proto.attachment.ServiceDiscovery.prototype.addApiCalls = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.ServiceDiscovery} returns this
 */
proto.attachment.ServiceDiscovery.prototype.clearApiCallsList = function() {
  return this.setApiCallsList([]);
};


/**
 * optional string discovery = 3;
 * @return {string}
 */
proto.attachment.ServiceDiscovery.prototype.getDiscovery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.ServiceDiscovery} returns this
 */
proto.attachment.ServiceDiscovery.prototype.setDiscovery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ListEvidenceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ListEvidenceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ListEvidenceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListEvidenceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: (f = msg.getCaption()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    timeRange: (f = msg.getTimeRange()) && common_time_pb.TimeRange.toObject(includeInstance, f),
    documentRequestId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ListEvidenceRequest}
 */
proto.attachment.ListEvidenceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ListEvidenceRequest;
  return proto.attachment.ListEvidenceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ListEvidenceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ListEvidenceRequest}
 */
proto.attachment.ListEvidenceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setCaption(value);
      break;
    case 3:
      var value = new common_time_pb.TimeRange;
      reader.readMessage(value,common_time_pb.TimeRange.deserializeBinaryFromReader);
      msg.setTimeRange(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ListEvidenceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ListEvidenceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ListEvidenceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListEvidenceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getTimeRange();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_time_pb.TimeRange.serializeBinaryToWriter
    );
  }
  f = message.getDocumentRequestId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.attachment.ListEvidenceRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.ListEvidenceRequest} returns this
 */
proto.attachment.ListEvidenceRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue caption = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.ListEvidenceRequest.prototype.getCaption = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.ListEvidenceRequest} returns this
*/
proto.attachment.ListEvidenceRequest.prototype.setCaption = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ListEvidenceRequest} returns this
 */
proto.attachment.ListEvidenceRequest.prototype.clearCaption = function() {
  return this.setCaption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ListEvidenceRequest.prototype.hasCaption = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional common.TimeRange time_range = 3;
 * @return {?proto.common.TimeRange}
 */
proto.attachment.ListEvidenceRequest.prototype.getTimeRange = function() {
  return /** @type{?proto.common.TimeRange} */ (
    jspb.Message.getWrapperField(this, common_time_pb.TimeRange, 3));
};


/**
 * @param {?proto.common.TimeRange|undefined} value
 * @return {!proto.attachment.ListEvidenceRequest} returns this
*/
proto.attachment.ListEvidenceRequest.prototype.setTimeRange = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ListEvidenceRequest} returns this
 */
proto.attachment.ListEvidenceRequest.prototype.clearTimeRange = function() {
  return this.setTimeRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ListEvidenceRequest.prototype.hasTimeRange = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string document_request_id = 4;
 * @return {string}
 */
proto.attachment.ListEvidenceRequest.prototype.getDocumentRequestId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.ListEvidenceRequest} returns this
 */
proto.attachment.ListEvidenceRequest.prototype.setDocumentRequestId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.ListEvidenceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ListEvidenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ListEvidenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ListEvidenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListEvidenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.attachment.Evidence.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ListEvidenceResponse}
 */
proto.attachment.ListEvidenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ListEvidenceResponse;
  return proto.attachment.ListEvidenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ListEvidenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ListEvidenceResponse}
 */
proto.attachment.ListEvidenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Evidence;
      reader.readMessage(value,proto.attachment.Evidence.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ListEvidenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ListEvidenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ListEvidenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListEvidenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.Evidence.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Evidence items = 1;
 * @return {!Array<!proto.attachment.Evidence>}
 */
proto.attachment.ListEvidenceResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.attachment.Evidence>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.Evidence, 1));
};


/**
 * @param {!Array<!proto.attachment.Evidence>} value
 * @return {!proto.attachment.ListEvidenceResponse} returns this
*/
proto.attachment.ListEvidenceResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.Evidence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.Evidence}
 */
proto.attachment.ListEvidenceResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.Evidence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.ListEvidenceResponse} returns this
 */
proto.attachment.ListEvidenceResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.GetLatestEvidenceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetLatestEvidenceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetLatestEvidenceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetLatestEvidenceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetLatestEvidenceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.attachment.LatestEvidence.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetLatestEvidenceResponse}
 */
proto.attachment.GetLatestEvidenceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetLatestEvidenceResponse;
  return proto.attachment.GetLatestEvidenceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetLatestEvidenceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetLatestEvidenceResponse}
 */
proto.attachment.GetLatestEvidenceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.LatestEvidence;
      reader.readMessage(value,proto.attachment.LatestEvidence.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetLatestEvidenceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetLatestEvidenceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetLatestEvidenceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetLatestEvidenceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.LatestEvidence.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LatestEvidence items = 1;
 * @return {!Array<!proto.attachment.LatestEvidence>}
 */
proto.attachment.GetLatestEvidenceResponse.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.attachment.LatestEvidence>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.LatestEvidence, 1));
};


/**
 * @param {!Array<!proto.attachment.LatestEvidence>} value
 * @return {!proto.attachment.GetLatestEvidenceResponse} returns this
*/
proto.attachment.GetLatestEvidenceResponse.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.LatestEvidence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.LatestEvidence}
 */
proto.attachment.GetLatestEvidenceResponse.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.LatestEvidence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.GetLatestEvidenceResponse} returns this
 */
proto.attachment.GetLatestEvidenceResponse.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ModelEvidenceGenerator.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ModelEvidenceGenerator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ModelEvidenceGenerator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ModelEvidenceGenerator.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceGeneratorId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    controlModelIdentifier: (f = msg.getControlModelIdentifier()) && common_model_pb.Identifier.toObject(includeInstance, f),
    isEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ModelEvidenceGenerator}
 */
proto.attachment.ModelEvidenceGenerator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ModelEvidenceGenerator;
  return proto.attachment.ModelEvidenceGenerator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ModelEvidenceGenerator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ModelEvidenceGenerator}
 */
proto.attachment.ModelEvidenceGenerator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidenceGeneratorId(value);
      break;
    case 5:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.setControlModelIdentifier(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ModelEvidenceGenerator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ModelEvidenceGenerator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ModelEvidenceGenerator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ModelEvidenceGenerator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceGeneratorId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getControlModelIdentifier();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getIsEnabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional string evidence_generator_id = 4;
 * @return {string}
 */
proto.attachment.ModelEvidenceGenerator.prototype.getEvidenceGeneratorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.ModelEvidenceGenerator} returns this
 */
proto.attachment.ModelEvidenceGenerator.prototype.setEvidenceGeneratorId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional common.Identifier control_model_identifier = 5;
 * @return {?proto.common.Identifier}
 */
proto.attachment.ModelEvidenceGenerator.prototype.getControlModelIdentifier = function() {
  return /** @type{?proto.common.Identifier} */ (
    jspb.Message.getWrapperField(this, common_model_pb.Identifier, 5));
};


/**
 * @param {?proto.common.Identifier|undefined} value
 * @return {!proto.attachment.ModelEvidenceGenerator} returns this
*/
proto.attachment.ModelEvidenceGenerator.prototype.setControlModelIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ModelEvidenceGenerator} returns this
 */
proto.attachment.ModelEvidenceGenerator.prototype.clearControlModelIdentifier = function() {
  return this.setControlModelIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ModelEvidenceGenerator.prototype.hasControlModelIdentifier = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool is_enabled = 6;
 * @return {boolean}
 */
proto.attachment.ModelEvidenceGenerator.prototype.getIsEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.ModelEvidenceGenerator} returns this
 */
proto.attachment.ModelEvidenceGenerator.prototype.setIsEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetModelEvidenceGeneratorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetModelEvidenceGeneratorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetModelEvidenceGeneratorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceGeneratorId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    controlModelIdentifier: (f = msg.getControlModelIdentifier()) && common_model_pb.Identifier.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetModelEvidenceGeneratorRequest}
 */
proto.attachment.GetModelEvidenceGeneratorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetModelEvidenceGeneratorRequest;
  return proto.attachment.GetModelEvidenceGeneratorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetModelEvidenceGeneratorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetModelEvidenceGeneratorRequest}
 */
proto.attachment.GetModelEvidenceGeneratorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidenceGeneratorId(value);
      break;
    case 4:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.setControlModelIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetModelEvidenceGeneratorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetModelEvidenceGeneratorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetModelEvidenceGeneratorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceGeneratorId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getControlModelIdentifier();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
};


/**
 * optional string evidence_generator_id = 3;
 * @return {string}
 */
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.getEvidenceGeneratorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetModelEvidenceGeneratorRequest} returns this
 */
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.setEvidenceGeneratorId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional common.Identifier control_model_identifier = 4;
 * @return {?proto.common.Identifier}
 */
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.getControlModelIdentifier = function() {
  return /** @type{?proto.common.Identifier} */ (
    jspb.Message.getWrapperField(this, common_model_pb.Identifier, 4));
};


/**
 * @param {?proto.common.Identifier|undefined} value
 * @return {!proto.attachment.GetModelEvidenceGeneratorRequest} returns this
*/
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.setControlModelIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetModelEvidenceGeneratorRequest} returns this
 */
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.clearControlModelIdentifier = function() {
  return this.setControlModelIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetModelEvidenceGeneratorRequest.prototype.hasControlModelIdentifier = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.ListConnectedEvidenceGeneratorsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: (f = msg.getIdentifier()) && common_model_pb.Identifier.toObject(includeInstance, f),
    connected: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.ListConnectedEvidenceGeneratorsRequest}
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.ListConnectedEvidenceGeneratorsRequest;
  return proto.attachment.ListConnectedEvidenceGeneratorsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.ListConnectedEvidenceGeneratorsRequest}
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new common_model_pb.Identifier;
      reader.readMessage(value,common_model_pb.Identifier.deserializeBinaryFromReader);
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConnected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.ListConnectedEvidenceGeneratorsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      common_model_pb.Identifier.serializeBinaryToWriter
    );
  }
  f = message.getConnected();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional common.Identifier identifier = 1;
 * @return {?proto.common.Identifier}
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.getIdentifier = function() {
  return /** @type{?proto.common.Identifier} */ (
    jspb.Message.getWrapperField(this, common_model_pb.Identifier, 1));
};


/**
 * @param {?proto.common.Identifier|undefined} value
 * @return {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} returns this
*/
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.setIdentifier = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} returns this
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.clearIdentifier = function() {
  return this.setIdentifier(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.hasIdentifier = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool connected = 2;
 * @return {boolean}
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.getConnected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.ListConnectedEvidenceGeneratorsRequest} returns this
 */
proto.attachment.ListConnectedEvidenceGeneratorsRequest.prototype.setConnected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LatestEvidence.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LatestEvidence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LatestEvidence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LatestEvidence.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidence: (f = msg.getEvidence()) && proto.attachment.Evidence.toObject(includeInstance, f),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LatestEvidence}
 */
proto.attachment.LatestEvidence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LatestEvidence;
  return proto.attachment.LatestEvidence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LatestEvidence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LatestEvidence}
 */
proto.attachment.LatestEvidence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.Evidence;
      reader.readMessage(value,proto.attachment.Evidence.deserializeBinaryFromReader);
      msg.setEvidence(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LatestEvidence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LatestEvidence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LatestEvidence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LatestEvidence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidence();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.Evidence.serializeBinaryToWriter
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional Evidence evidence = 1;
 * @return {?proto.attachment.Evidence}
 */
proto.attachment.LatestEvidence.prototype.getEvidence = function() {
  return /** @type{?proto.attachment.Evidence} */ (
    jspb.Message.getWrapperField(this, proto.attachment.Evidence, 1));
};


/**
 * @param {?proto.attachment.Evidence|undefined} value
 * @return {!proto.attachment.LatestEvidence} returns this
*/
proto.attachment.LatestEvidence.prototype.setEvidence = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.LatestEvidence} returns this
 */
proto.attachment.LatestEvidence.prototype.clearEvidence = function() {
  return this.setEvidence(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.LatestEvidence.prototype.hasEvidence = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 total = 2;
 * @return {number}
 */
proto.attachment.LatestEvidence.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.attachment.LatestEvidence} returns this
 */
proto.attachment.LatestEvidence.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.Evidence.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.Evidence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.Evidence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Evidence.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    actorEmail: jspb.Message.getFieldWithDefault(msg, 4, ""),
    actorName: (f = msg.getActorName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    controlId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isAutomated: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    mime: jspb.Message.getFieldWithDefault(msg, 8, ""),
    relevantDate: (f = msg.getRelevantDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    isViaRequest: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    contentId: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.Evidence}
 */
proto.attachment.Evidence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.Evidence;
  return proto.attachment.Evidence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.Evidence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.Evidence}
 */
proto.attachment.Evidence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setActorEmail(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setActorName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAutomated(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setRelevantDate(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsViaRequest(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setContentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.Evidence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.Evidence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.Evidence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.Evidence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getActorEmail();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getActorName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsAutomated();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRelevantDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getIsViaRequest();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getContentId();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.Evidence.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.attachment.Evidence.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.attachment.Evidence.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.attachment.Evidence} returns this
*/
proto.attachment.Evidence.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.Evidence.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string actor_email = 4;
 * @return {string}
 */
proto.attachment.Evidence.prototype.getActorEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setActorEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.StringValue actor_name = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.Evidence.prototype.getActorName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.Evidence} returns this
*/
proto.attachment.Evidence.prototype.setActorName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.clearActorName = function() {
  return this.setActorName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.Evidence.prototype.hasActorName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string control_id = 6;
 * @return {string}
 */
proto.attachment.Evidence.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_automated = 7;
 * @return {boolean}
 */
proto.attachment.Evidence.prototype.getIsAutomated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setIsAutomated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional string mime = 8;
 * @return {string}
 */
proto.attachment.Evidence.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp relevant_date = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.attachment.Evidence.prototype.getRelevantDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.attachment.Evidence} returns this
*/
proto.attachment.Evidence.prototype.setRelevantDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.clearRelevantDate = function() {
  return this.setRelevantDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.Evidence.prototype.hasRelevantDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool is_via_request = 10;
 * @return {boolean}
 */
proto.attachment.Evidence.prototype.getIsViaRequest = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setIsViaRequest = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional string content_id = 11;
 * @return {string}
 */
proto.attachment.Evidence.prototype.getContentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.Evidence} returns this
 */
proto.attachment.Evidence.prototype.setContentId = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.attachment.EvidenceGenerators.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.EvidenceGenerators.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.EvidenceGenerators.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.EvidenceGenerators} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceGenerators.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.attachment.EvidenceGenerator.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.EvidenceGenerators}
 */
proto.attachment.EvidenceGenerators.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.EvidenceGenerators;
  return proto.attachment.EvidenceGenerators.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.EvidenceGenerators} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.EvidenceGenerators}
 */
proto.attachment.EvidenceGenerators.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.EvidenceGenerator;
      reader.readMessage(value,proto.attachment.EvidenceGenerator.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.EvidenceGenerators.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.EvidenceGenerators.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.EvidenceGenerators} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceGenerators.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.attachment.EvidenceGenerator.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EvidenceGenerator items = 1;
 * @return {!Array<!proto.attachment.EvidenceGenerator>}
 */
proto.attachment.EvidenceGenerators.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.attachment.EvidenceGenerator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.attachment.EvidenceGenerator, 1));
};


/**
 * @param {!Array<!proto.attachment.EvidenceGenerator>} value
 * @return {!proto.attachment.EvidenceGenerators} returns this
*/
proto.attachment.EvidenceGenerators.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.attachment.EvidenceGenerator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.attachment.EvidenceGenerator}
 */
proto.attachment.EvidenceGenerators.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.attachment.EvidenceGenerator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.attachment.EvidenceGenerators} returns this
 */
proto.attachment.EvidenceGenerators.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.EvidenceGenerator.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.EvidenceGenerator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.EvidenceGenerator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceGenerator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    evidenceId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    receptorModelId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.EvidenceGenerator}
 */
proto.attachment.EvidenceGenerator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.EvidenceGenerator;
  return proto.attachment.EvidenceGenerator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.EvidenceGenerator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.EvidenceGenerator}
 */
proto.attachment.EvidenceGenerator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEvidenceId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceptorModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.EvidenceGenerator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.EvidenceGenerator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.EvidenceGenerator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.EvidenceGenerator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEvidenceId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReceptorModelId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.attachment.EvidenceGenerator.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGenerator} returns this
 */
proto.attachment.EvidenceGenerator.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string evidence_id = 3;
 * @return {string}
 */
proto.attachment.EvidenceGenerator.prototype.getEvidenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGenerator} returns this
 */
proto.attachment.EvidenceGenerator.prototype.setEvidenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string receptor_model_id = 4;
 * @return {string}
 */
proto.attachment.EvidenceGenerator.prototype.getReceptorModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.EvidenceGenerator} returns this
 */
proto.attachment.EvidenceGenerator.prototype.setReceptorModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.DeleteDocumentRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.DeleteDocumentRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.DeleteDocumentRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DeleteDocumentRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    deleteAssociatedTests: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    requestId: (f = msg.getRequestId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.DeleteDocumentRequest}
 */
proto.attachment.DeleteDocumentRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.DeleteDocumentRequest;
  return proto.attachment.DeleteDocumentRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.DeleteDocumentRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.DeleteDocumentRequest}
 */
proto.attachment.DeleteDocumentRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDocumentId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeleteAssociatedTests(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.DeleteDocumentRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.DeleteDocumentRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.DeleteDocumentRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DeleteDocumentRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDeleteAssociatedTests();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getRequestId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional string document_id = 1;
 * @return {string}
 */
proto.attachment.DeleteDocumentRequest.prototype.getDocumentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.DeleteDocumentRequest} returns this
 */
proto.attachment.DeleteDocumentRequest.prototype.setDocumentId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool delete_associated_tests = 2;
 * @return {boolean}
 */
proto.attachment.DeleteDocumentRequest.prototype.getDeleteAssociatedTests = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.DeleteDocumentRequest} returns this
 */
proto.attachment.DeleteDocumentRequest.prototype.setDeleteAssociatedTests = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional google.protobuf.StringValue request_id = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.DeleteDocumentRequest.prototype.getRequestId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.DeleteDocumentRequest} returns this
*/
proto.attachment.DeleteDocumentRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.DeleteDocumentRequest} returns this
 */
proto.attachment.DeleteDocumentRequest.prototype.clearRequestId = function() {
  return this.setRequestId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.DeleteDocumentRequest.prototype.hasRequestId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetPolicyAcknowledgmentFormRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetPolicyAcknowledgmentFormRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetPolicyAcknowledgmentFormRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetPolicyAcknowledgmentFormRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    auditId: (f = msg.getAuditId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    override: (f = msg.getOverride()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetPolicyAcknowledgmentFormRequest}
 */
proto.attachment.GetPolicyAcknowledgmentFormRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetPolicyAcknowledgmentFormRequest;
  return proto.attachment.GetPolicyAcknowledgmentFormRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetPolicyAcknowledgmentFormRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetPolicyAcknowledgmentFormRequest}
 */
proto.attachment.GetPolicyAcknowledgmentFormRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAuditId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setOverride(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetPolicyAcknowledgmentFormRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetPolicyAcknowledgmentFormRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetPolicyAcknowledgmentFormRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetPolicyAcknowledgmentFormRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuditId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getOverride();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue audit_id = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.GetPolicyAcknowledgmentFormRequest.prototype.getAuditId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.GetPolicyAcknowledgmentFormRequest} returns this
*/
proto.attachment.GetPolicyAcknowledgmentFormRequest.prototype.setAuditId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetPolicyAcknowledgmentFormRequest} returns this
 */
proto.attachment.GetPolicyAcknowledgmentFormRequest.prototype.clearAuditId = function() {
  return this.setAuditId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetPolicyAcknowledgmentFormRequest.prototype.hasAuditId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.BoolValue override = 2;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.attachment.GetPolicyAcknowledgmentFormRequest.prototype.getOverride = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 2));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.attachment.GetPolicyAcknowledgmentFormRequest} returns this
*/
proto.attachment.GetPolicyAcknowledgmentFormRequest.prototype.setOverride = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetPolicyAcknowledgmentFormRequest} returns this
 */
proto.attachment.GetPolicyAcknowledgmentFormRequest.prototype.clearOverride = function() {
  return this.setOverride(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetPolicyAcknowledgmentFormRequest.prototype.hasOverride = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetPolicyAcknowledgmentFormResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetPolicyAcknowledgmentFormResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetPolicyAcknowledgmentFormResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetPolicyAcknowledgmentFormResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    missingdocuments: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    form: (f = msg.getForm()) && proto.attachment.PolicyAcknowledgmentForm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetPolicyAcknowledgmentFormResponse}
 */
proto.attachment.GetPolicyAcknowledgmentFormResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetPolicyAcknowledgmentFormResponse;
  return proto.attachment.GetPolicyAcknowledgmentFormResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetPolicyAcknowledgmentFormResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetPolicyAcknowledgmentFormResponse}
 */
proto.attachment.GetPolicyAcknowledgmentFormResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMissingdocuments(value);
      break;
    case 2:
      var value = new proto.attachment.PolicyAcknowledgmentForm;
      reader.readMessage(value,proto.attachment.PolicyAcknowledgmentForm.deserializeBinaryFromReader);
      msg.setForm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetPolicyAcknowledgmentFormResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetPolicyAcknowledgmentFormResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetPolicyAcknowledgmentFormResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetPolicyAcknowledgmentFormResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMissingdocuments();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getForm();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.attachment.PolicyAcknowledgmentForm.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool missingDocuments = 1;
 * @return {boolean}
 */
proto.attachment.GetPolicyAcknowledgmentFormResponse.prototype.getMissingdocuments = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.GetPolicyAcknowledgmentFormResponse} returns this
 */
proto.attachment.GetPolicyAcknowledgmentFormResponse.prototype.setMissingdocuments = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional PolicyAcknowledgmentForm form = 2;
 * @return {?proto.attachment.PolicyAcknowledgmentForm}
 */
proto.attachment.GetPolicyAcknowledgmentFormResponse.prototype.getForm = function() {
  return /** @type{?proto.attachment.PolicyAcknowledgmentForm} */ (
    jspb.Message.getWrapperField(this, proto.attachment.PolicyAcknowledgmentForm, 2));
};


/**
 * @param {?proto.attachment.PolicyAcknowledgmentForm|undefined} value
 * @return {!proto.attachment.GetPolicyAcknowledgmentFormResponse} returns this
*/
proto.attachment.GetPolicyAcknowledgmentFormResponse.prototype.setForm = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetPolicyAcknowledgmentFormResponse} returns this
 */
proto.attachment.GetPolicyAcknowledgmentFormResponse.prototype.clearForm = function() {
  return this.setForm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetPolicyAcknowledgmentFormResponse.prototype.hasForm = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.PolicyAcknowledgmentForm.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.PolicyAcknowledgmentForm.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.PolicyAcknowledgmentForm} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.PolicyAcknowledgmentForm.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    body: msg.getBody_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.PolicyAcknowledgmentForm}
 */
proto.attachment.PolicyAcknowledgmentForm.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.PolicyAcknowledgmentForm;
  return proto.attachment.PolicyAcknowledgmentForm.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.PolicyAcknowledgmentForm} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.PolicyAcknowledgmentForm}
 */
proto.attachment.PolicyAcknowledgmentForm.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.PolicyAcknowledgmentForm.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.PolicyAcknowledgmentForm.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.PolicyAcknowledgmentForm} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.PolicyAcknowledgmentForm.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBody_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.attachment.PolicyAcknowledgmentForm.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.PolicyAcknowledgmentForm} returns this
 */
proto.attachment.PolicyAcknowledgmentForm.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string mime = 2;
 * @return {string}
 */
proto.attachment.PolicyAcknowledgmentForm.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.PolicyAcknowledgmentForm} returns this
 */
proto.attachment.PolicyAcknowledgmentForm.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes body = 3;
 * @return {!(string|Uint8Array)}
 */
proto.attachment.PolicyAcknowledgmentForm.prototype.getBody = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes body = 3;
 * This is a type-conversion wrapper around `getBody()`
 * @return {string}
 */
proto.attachment.PolicyAcknowledgmentForm.prototype.getBody_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBody()));
};


/**
 * optional bytes body = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBody()`
 * @return {!Uint8Array}
 */
proto.attachment.PolicyAcknowledgmentForm.prototype.getBody_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBody()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.attachment.PolicyAcknowledgmentForm} returns this
 */
proto.attachment.PolicyAcknowledgmentForm.prototype.setBody = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetRiskRegisterCSVResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetRiskRegisterCSVResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetRiskRegisterCSVResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetRiskRegisterCSVResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    csv: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetRiskRegisterCSVResponse}
 */
proto.attachment.GetRiskRegisterCSVResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetRiskRegisterCSVResponse;
  return proto.attachment.GetRiskRegisterCSVResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetRiskRegisterCSVResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetRiskRegisterCSVResponse}
 */
proto.attachment.GetRiskRegisterCSVResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCsv(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetRiskRegisterCSVResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetRiskRegisterCSVResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetRiskRegisterCSVResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetRiskRegisterCSVResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCsv();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string csv = 1;
 * @return {string}
 */
proto.attachment.GetRiskRegisterCSVResponse.prototype.getCsv = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.GetRiskRegisterCSVResponse} returns this
 */
proto.attachment.GetRiskRegisterCSVResponse.prototype.setCsv = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetExclusionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetExclusionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetExclusionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetExclusionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    evidenceid: (f = msg.getEvidenceid()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetExclusionsRequest}
 */
proto.attachment.GetExclusionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetExclusionsRequest;
  return proto.attachment.GetExclusionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetExclusionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetExclusionsRequest}
 */
proto.attachment.GetExclusionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEvidenceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetExclusionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetExclusionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetExclusionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetExclusionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvidenceid();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue evidenceId = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.attachment.GetExclusionsRequest.prototype.getEvidenceid = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.attachment.GetExclusionsRequest} returns this
*/
proto.attachment.GetExclusionsRequest.prototype.setEvidenceid = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetExclusionsRequest} returns this
 */
proto.attachment.GetExclusionsRequest.prototype.clearEvidenceid = function() {
  return this.setEvidenceid(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetExclusionsRequest.prototype.hasEvidenceid = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.GetExclusionsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.GetExclusionsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.GetExclusionsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetExclusionsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    exclusions: (f = msg.getExclusions()) && proto.attachment.AllExclusions.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.GetExclusionsResponse}
 */
proto.attachment.GetExclusionsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.GetExclusionsResponse;
  return proto.attachment.GetExclusionsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.GetExclusionsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.GetExclusionsResponse}
 */
proto.attachment.GetExclusionsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.attachment.AllExclusions;
      reader.readMessage(value,proto.attachment.AllExclusions.deserializeBinaryFromReader);
      msg.setExclusions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.GetExclusionsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.GetExclusionsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.GetExclusionsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.GetExclusionsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExclusions();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.attachment.AllExclusions.serializeBinaryToWriter
    );
  }
};


/**
 * optional AllExclusions exclusions = 1;
 * @return {?proto.attachment.AllExclusions}
 */
proto.attachment.GetExclusionsResponse.prototype.getExclusions = function() {
  return /** @type{?proto.attachment.AllExclusions} */ (
    jspb.Message.getWrapperField(this, proto.attachment.AllExclusions, 1));
};


/**
 * @param {?proto.attachment.AllExclusions|undefined} value
 * @return {!proto.attachment.GetExclusionsResponse} returns this
*/
proto.attachment.GetExclusionsResponse.prototype.setExclusions = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.attachment.GetExclusionsResponse} returns this
 */
proto.attachment.GetExclusionsResponse.prototype.clearExclusions = function() {
  return this.setExclusions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.attachment.GetExclusionsResponse.prototype.hasExclusions = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.DeleteControlEvidencesByCaptionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.DeleteControlEvidencesByCaptionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    controlId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    caption: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.DeleteControlEvidencesByCaptionRequest}
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.DeleteControlEvidencesByCaptionRequest;
  return proto.attachment.DeleteControlEvidencesByCaptionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.DeleteControlEvidencesByCaptionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.DeleteControlEvidencesByCaptionRequest}
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setControlId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.DeleteControlEvidencesByCaptionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.DeleteControlEvidencesByCaptionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getControlId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string control_id = 1;
 * @return {string}
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.prototype.getControlId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.DeleteControlEvidencesByCaptionRequest} returns this
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.prototype.setControlId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string caption = 2;
 * @return {string}
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.DeleteControlEvidencesByCaptionRequest} returns this
 */
proto.attachment.DeleteControlEvidencesByCaptionRequest.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LookUpChangeLogEntryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LookUpChangeLogEntryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LookUpChangeLogEntryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    attachmentType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    attachmentId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    message: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LookUpChangeLogEntryRequest}
 */
proto.attachment.LookUpChangeLogEntryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LookUpChangeLogEntryRequest;
  return proto.attachment.LookUpChangeLogEntryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LookUpChangeLogEntryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LookUpChangeLogEntryRequest}
 */
proto.attachment.LookUpChangeLogEntryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.attachment.ATTACHMENT_TYPE} */ (reader.readEnum());
      msg.setAttachmentType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LookUpChangeLogEntryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LookUpChangeLogEntryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LookUpChangeLogEntryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAttachmentType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getAttachmentId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional ATTACHMENT_TYPE attachment_type = 1;
 * @return {!proto.attachment.ATTACHMENT_TYPE}
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.getAttachmentType = function() {
  return /** @type {!proto.attachment.ATTACHMENT_TYPE} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.attachment.ATTACHMENT_TYPE} value
 * @return {!proto.attachment.LookUpChangeLogEntryRequest} returns this
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.setAttachmentType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string attachment_id = 2;
 * @return {string}
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.getAttachmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LookUpChangeLogEntryRequest} returns this
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.setAttachmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string message = 3;
 * @return {string}
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.attachment.LookUpChangeLogEntryRequest} returns this
 */
proto.attachment.LookUpChangeLogEntryRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.attachment.LookUpChangeLogEntryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.attachment.LookUpChangeLogEntryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.attachment.LookUpChangeLogEntryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LookUpChangeLogEntryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isFound: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.attachment.LookUpChangeLogEntryResponse}
 */
proto.attachment.LookUpChangeLogEntryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.attachment.LookUpChangeLogEntryResponse;
  return proto.attachment.LookUpChangeLogEntryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.attachment.LookUpChangeLogEntryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.attachment.LookUpChangeLogEntryResponse}
 */
proto.attachment.LookUpChangeLogEntryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFound(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.attachment.LookUpChangeLogEntryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.attachment.LookUpChangeLogEntryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.attachment.LookUpChangeLogEntryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.attachment.LookUpChangeLogEntryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsFound();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_found = 1;
 * @return {boolean}
 */
proto.attachment.LookUpChangeLogEntryResponse.prototype.getIsFound = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.attachment.LookUpChangeLogEntryResponse} returns this
 */
proto.attachment.LookUpChangeLogEntryResponse.prototype.setIsFound = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.attachment.ACTOR_TYPE = {
  USER: 0,
  RECEPTOR: 1,
  TRUSTERO: 2,
  LINKER: 3
};

/**
 * @enum {number}
 */
proto.attachment.DOCUMENT_TYPE = {
  POLICYDOC: 0,
  TEST: 1,
  EVIDENCE: 2,
  COMMENT: 3,
  REPORT: 4,
  USER_ACTION: 5,
  COMPANY_INFO: 6,
  KNOWLEDGE_BASE: 7
};

/**
 * @enum {number}
 */
proto.attachment.ATTACHMENT_TYPE = {
  AUDITPERIOD: 0,
  DOCUMENT: 1,
  TRACER: 2,
  TESTRESULT: 3,
  CONTROL: 4,
  POLICY: 5,
  REQUEST: 6,
  RISK: 7,
  AUDIT_RUN: 8,
  QUESTIONNAIRE_ANSWER: 9,
  ATTESTATION: 10,
  VENDOR: 11
};

goog.object.extend(exports, proto.attachment);

import { AccountPromiseClient } from '@trustero/trustero-api-web/lib/account/account_grpc_web_pb'
import { Empty } from 'google-protobuf/google/protobuf/empty_pb'
import {
  AccountRecord,
  UpdateAccount,
  PLG_TYPE,
  PlgDetails,
  GetAccountsResponse,
  JoinReadOnlyAccountRequest,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { useAuthorizedGrpcClient } from 'src/adapter/grpcClient'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import log from 'loglevel'
import { useAuth } from 'src/context/authContext'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'

export const useAccount = (): GrpcResponse<AccountRecord> => {
  const { response } = useSwrImmutableGrpc(
    AccountPromiseClient.prototype.get,
    new Empty(),
  )
  return NewGrpcResponse(response)
}

export const useAccounts = (): GrpcResponse<GetAccountsResponse> => {
  const { response } = useSwrImmutableGrpc(
    AccountPromiseClient.prototype.getAccounts,
    new Empty(),
  )
  return NewGrpcResponse(response)
}

export const useRandomAccountForUser = (): string => {
  const { accountId } = useAuth()
  const response = useAccounts()

  return (
    response?.data
      ?.getAccountsList()
      .find((acc) => acc.getId() !== accountId)
      ?.getId() || ''
  )
}

export const useUpdateAccount = (): ((
  organization: string,
) => Promise<void>) => {
  const { mutate: mutateAccount } = useAccount()
  const accountService = useAuthorizedGrpcClient(AccountPromiseClient)
  return async (organization: string) => {
    if (organization.length) {
      const updatedAccount = await accountService.update(
        new UpdateAccount().setOrgName(
          new StringValue().setValue(organization),
        ),
      )
      await mutateAccount(updatedAccount, false)
    }
  }
}

export const useUpdatePlgDetails = (): (({
  plgType,
  completionTime,
}: {
  plgType?: PLG_TYPE
  completionTime?: Timestamp
}) => Promise<void>) => {
  const accountService = useAuthorizedGrpcClient(AccountPromiseClient)
  return async ({ plgType, completionTime }) => {
    try {
      const request = new PlgDetails()
      if (completionTime !== undefined) {
        request.setCompletionTime(completionTime)
      }
      if (plgType !== undefined) {
        request.setPlgType(plgType)
      }
      await accountService.updatePlgDetails(request)
    } catch (err) {
      log.error(`error when updating account PLG details. error: ${err}`)
    }
  }
}

export const useReadonlyAccounts = (): GrpcResponse<GetAccountsResponse> => {
  const { response } = useSwrImmutableGrpc(
    AccountPromiseClient.prototype.getReadOnlyAccounts,
    new Empty(),
  )
  return NewGrpcResponse(response)
}

export const useJoinReadonlyAccount = (): (({
  accountId,
}: {
  accountId: string
}) => Promise<void>) => {
  const { mutate: mutateAccounts } = useAccounts()
  const { mutate: mutateReadonlyAccounts } = useReadonlyAccounts()
  const accountService = useAuthorizedGrpcClient(AccountPromiseClient)
  return async ({ accountId }) => {
    try {
      await accountService.joinReadOnlyAccount(
        new JoinReadOnlyAccountRequest().setAccountId(accountId),
      )
      await mutateAccounts(undefined, true)
      await mutateReadonlyAccounts(undefined, true)
    } catch (err) {
      log.error(`error when joining readonly account. error: ${err}`)
    }
  }
}

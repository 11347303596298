import { ServiceDiscovery } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import {
  Evidence,
  Struct,
  Value,
} from '@trustero/trustero-api-web/lib/receptor_v1/receptor_pb'
import { EvidenceIssue } from '@trustero/trustero-api-web/lib/evidence/testing_pb'
import { dateFormatter } from '../formatDate'
import { EvidenceTable, blankHeaderPlaceholder } from './evidence.constants'

const getHeaderKeys = (evidenceStruct: Struct): string[] => {
  const evidenceRows = evidenceStruct.getRowsList()
  const allHeaders: string[] = evidenceStruct.getColDisplayOrderList()
  if (evidenceRows.length === 0) {
    return []
  }

  return allHeaders
}

const toStringValue = (value: Value): string => {
  let stringValue = ''
  const valueType = value.getValueTypeCase()
  switch (valueType) {
    case Value.ValueTypeCase.DOUBLE_VALUE:
      stringValue = value.getDoubleValue().toString()
      return stringValue
    case Value.ValueTypeCase.FLOAT_VALUE:
      stringValue = value.getFloatValue().toString()
      return stringValue
    case Value.ValueTypeCase.INT32_VALUE:
      stringValue = value.getInt32Value().toString()
      return stringValue
    case Value.ValueTypeCase.INT64_VALUE:
      stringValue = value.getInt64Value().toString()
      return stringValue
    case Value.ValueTypeCase.UINT32_VALUE:
      stringValue = value.getUint32Value().toString()
      return stringValue
    case Value.ValueTypeCase.UINT64_VALUE:
      stringValue = value.getUint64Value().toString()
      return stringValue
    case Value.ValueTypeCase.BOOL_VALUE:
      if (value.getBoolValue()) {
        stringValue = ':heavy_check_mark:'
      } else {
        stringValue = '-'
      }
      return stringValue
    case Value.ValueTypeCase.STRING_VALUE:
      stringValue = value.getStringValue().trim()
      return stringValue
    case Value.ValueTypeCase.TIMESTAMP_VALUE: {
      const timestamp = value.getTimestampValue()
      stringValue = dateFormatter(timestamp?.toDate(), false)
      return stringValue
    }
    default:
      return stringValue
  }
}
/*
This function converts a receptor_v1.Struct to an ordered and displayable array header strings, and an array of rows of strings. Each row's columns are ordered according to its headers in the headers array.
*/
export const tabulate = (evidenceStruct: Struct): EvidenceTable => {
  const headerKeys = getHeaderKeys(evidenceStruct)
  const rows = evidenceStruct.getRowsList()
  const table: EvidenceTable = {
    headers: [],
    body: [],
    problemRows: [],
  }

  rows.forEach((row) => {
    const cols = Array(headerKeys.length).fill('')
    headerKeys.forEach((key, index) => {
      if (key.length === 0) {
        return
      }
      const value = row.getColsMap().get(key)
      cols[index] = value && toStringValue(value)
    })
    table.body.push(cols)
  })

  // Get displayable headers
  headerKeys.forEach((key) => {
    let name = key
    const colDisplayNames = evidenceStruct.getColDisplayNamesMap()
    const colDisplayName = colDisplayNames.get(key)
    if (colDisplayName) {
      name = colDisplayName
    }
    table.headers.push(name)
  })
  return table
}

export const getEvidenceSources = (evidence: Evidence): ServiceDiscovery[] => {
  const sources = evidence.getSourcesList()
  const discoveries: ServiceDiscovery[] = []
  sources.forEach((source) => {
    discoveries.push(
      new ServiceDiscovery()
        .setServiceId(evidence.getServiceName())
        .setApiCallsList([source.getRawApiRequest()])
        .setDiscovery(source.getRawApiResponse()),
    )
  })
  return discoveries
}

export const addEvidenceIssuesToTable = (
  table: EvidenceTable,
  issues: EvidenceIssue[],
): EvidenceTable => {
  issues.forEach((issue) => {
    const problemIdx = issue.getLocation()?.getObservationsindex()
    if (problemIdx !== undefined) {
      table.problemRows[problemIdx] = true
    }
  })
  return table
}

export const evidenceToMarkdown = (table: EvidenceTable): string => {
  if (table.headers.length === 0) {
    return ''
  }
  const preparedTable = prepareTable(table)
  const isColumnCenterJustified: boolean[] = Array(
    preparedTable.headers.length,
  ).fill(true)
  const parts = [
    printRow(preparedTable.headers),
    genColumnJustification(isColumnCenterJustified),
    printBody(preparedTable.body),
  ]
  return parts.join('\n')
}

const prepareTable = (table: EvidenceTable): EvidenceTable => {
  const annotatedTable = getAnnotatedTable(table)
  const preparedTable = excludeIgnored(annotatedTable)
  return preparedTable
}

const printRow = (row: string[]): string => {
  return `| ${row.join(' | ')} |`
}

const genColumnJustification = (isCenterJustified: boolean[]): string => {
  if (isCenterJustified.length === 0) {
    return ''
  }
  const columnJustification: string[] = []
  isCenterJustified.forEach((isCentered) => {
    if (isCentered) {
      columnJustification.push(':---:')
      return
    }
    columnJustification.push(':---')
  })
  return `|${columnJustification.join('|')}|`
}

const printBody = (body: string[][]): string => {
  if (body.length === 0) {
    return ''
  }
  const bodyMarkdown: string[] = Array(body.length)
  body.forEach((row, index) => {
    bodyMarkdown[index] = printRow(row)
  })
  return bodyMarkdown.join('\n')
}

const getAnnotatedTable = (table: EvidenceTable): EvidenceTable => {
  let hasProblem = false
  for (const problem of Object.values(table.problemRows)) {
    if (problem) {
      hasProblem = true
      break
    }
  }
  if (!hasProblem) {
    return table
  }

  if (table.headers.length !== 0) {
    table.headers.unshift(blankHeaderPlaceholder)
  }

  table.body.forEach((row, index) => {
    if (table.problemRows[index]) {
      row.forEach((content, contentIndex) => {
        row[contentIndex] = `***${content}***`
      })
      row.unshift('❌')
    } else {
      row.unshift('')
    }
    table.body[index] = row
  })
  return table
}

const excludeIgnored = (table: EvidenceTable): EvidenceTable => {
  const ignoredIndexes: Record<number, boolean> = {}
  let j = 0
  table.headers.forEach((header, index) => {
    if (header === '') {
      ignoredIndexes[index] = true
      return
    } else if (header === blankHeaderPlaceholder) {
      table.headers[j] = ''
    } else {
      table.headers[j] = header
    }
    j++
  })
  table.headers = table.headers.slice(0, j)

  table.body.forEach((row, index) => {
    let j = 0
    row.forEach((content, contentIndex) => {
      if (ignoredIndexes[contentIndex]) {
        return
      }
      row[j] = content
      j++
    })
    table.body[index] = row.slice(0, j)
  })
  return table
}

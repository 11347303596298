import React, { useContext, useEffect, useState } from 'react'
import log from 'loglevel'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import { Range } from 'react-date-range'
import { getEvidenceDateRangeForAudit } from 'src/pages/Controls/ControlsShowPage/ControlsShowPage.helpers'
import { useModalState } from 'src/Modal/ModalStateContext'
import { Spinner } from '../../../../Throbber'
import { ModalFormId } from '../../../ModalForms'
import {
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from '../../Buttons'
import { AddTestForm } from '../../../ModalForms/Test/AddTest/AddTestForm'
import { useControl } from '../../../async/model'
import ReceptorScanContext from '../../../../context/ReceptorScanStatus'
import { useRunAccountTests } from '../../../../pages/Controls/ControlsShowPage/TestGrid/TestGrid.hooks'
import { showInfoToast } from '../../../../Utils/helpers/toast'
import { useAnalytics } from '../../../../analytics/useAnalytics'
import { ShowLatestDateFilter } from '../../TrusteroDateRange/ShowLatestDateFilter'
import { FlexRow } from '../../Flex'
import {
  TabPanelButtonGroup,
  TabPanelNoteHeader,
  TabPanelNoteItem,
} from './styles'
import { TestTabNoteProps } from './TabNote.components'

export const TestTabNote = ({
  modelId,
  button,
  timeRange,
  currentAudit,
  showLatest,
  setShowLatest,
  isNotApplicable,
}: TestTabNoteProps): JSX.Element => {
  const scanAndTest = useRunAccountTests()
  const { track, events } = useAnalytics()
  const { receptorScanRunning, setReceptorScanRunning } =
    useContext(ReceptorScanContext)
  const { openModal } = useModalState()

  const [dateRange, dateRangeSet] = useState<Range>({
    startDate: undefined,
    endDate: undefined,
  })

  useEffect(() => {
    if (currentAudit) {
      dateRangeSet(getEvidenceDateRangeForAudit(currentAudit))
    }
  }, [currentAudit])
  const { data: control, isLoading: isControlLoading } = useControl(modelId)

  if (isControlLoading || !control) {
    return <></>
  }

  const runScan = async (e: React.MouseEvent) => {
    if (isNotApplicable) {
      openModal(ModalFormId.NA_CONTROLS)
      return
    }
    try {
      e.preventDefault()
      if (receptorScanRunning) {
        return
      }
      track(events.C_CTRL_SCAN_TEST)
      setReceptorScanRunning(true)
      showInfoToast('Receptor scan started')
      await scanAndTest()
      showInfoToast('Receptor scan finished')
    } catch (err) {
      showInfoToast(
        'Sorry, there was an issue scanning your receptors. Please try again later.',
      )
      log.error('Failed to complete receptor scan: ', err)
    } finally {
      setReceptorScanRunning(false)
    }
  }

  const addTestModalId = isNotApplicable
    ? ModalFormId.NA_CONTROLS
    : ModalFormId.ADD_TEST

  return (
    <TabPanelNoteItem>
      <TabPanelNoteHeader>
        <TabPanelButtonGroup>
          {button}
          <StandardButton
            variant={StandardButtonVariant.SECONDARY}
            buttonSize={StandardButtonSize.SMALL}
            disabled={receptorScanRunning}
            onClick={runScan}
          >
            <span>
              {receptorScanRunning ? (
                <FlexRow>
                  Scanning...&nbsp;&nbsp;
                  <Spinner size="s" color="primary" />
                </FlexRow>
              ) : (
                'Scan and Test'
              )}
            </span>
          </StandardButton>
          <AddTestForm modelId={control.getModelId()} timeRange={timeRange} />
          <StandardOpenModalButton
            modalId={addTestModalId}
            variant={StandardButtonVariant.PRIMARY}
            size={StandardButtonSize.SMALL}
            onClick={() => track(events.C_CTRL_ADD_TEST)}
            text="Add Test"
          />
        </TabPanelButtonGroup>
        <ShowLatestDateFilter
          showLatest={showLatest}
          setShowLatest={setShowLatest}
          currentAudit={currentAudit?.getId()}
          dateRange={dateRange}
          setDateRange={dateRangeSet}
          tooltipBody={`Show only the latest test runs collected organized by their respective captions${
            currentAudit ? ' (within the audit period).' : '.'
          }`}
          additionalToolTip={Boolean(currentAudit)}
          additionalToolTipBody="Auditors only consider evidence and tests from within the date range of your current audit."
        />
      </TabPanelNoteHeader>
    </TabPanelNoteItem>
  )
}

import React, { useMemo } from 'react'
import { StringList } from '@trustero/trustero-api-web/lib/common/collections_pb'
import ContentLoader from 'react-content-loader'
import { ReceptorRecord } from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import { BaseProps, OnUnpackedResponse } from '../../types'
import { getAllReceptorTemplates } from '../../../../xgenerated'
import { useAccountReceptors } from './useReceptors'

interface ListReceptorProps
  extends BaseProps<
    ListReceptorProps,
    OnUnpackedResponse<
      ListReceptorProps,
      StringList,
      { receptorIds: string[]; totalReceptors: number }
    >
  > {
  filterSelect: {
    activated: boolean
    deactivated: boolean
  }
  setFilterSelect: React.Dispatch<
    React.SetStateAction<{
      activated: boolean
      deactivated: boolean
    }>
  >
}

export const ListReceptorIds = (
  initialProps: ListReceptorProps,
): JSX.Element => {
  const response = useAccountReceptors()
  const receptorNames = useMemo(
    () =>
      getAllReceptorTemplates()
        .map((p) => ({
          key: p.modelid,
          value: p.name,
        }))
        .reduce((acc, { key, value }) => {
          acc[key] = value
          return acc
        }, {} as { [key: string]: string }),
    [],
  )

  const receptorIds = useMemo(() => {
    const sorted =
      response?.data
        ?.getReceptorsList()
        .sort((a, b) =>
          receptorNames[a.getModelid()].localeCompare(
            receptorNames[b.getModelid()],
          ),
        ) || []

    if (
      !initialProps.filterSelect.activated &&
      !initialProps.filterSelect.deactivated
    ) {
      return sorted.map((receptor: ReceptorRecord) => receptor.getId())
    }

    return (
      sorted.reduce((ac: string[], receptor: ReceptorRecord) => {
        if (initialProps.filterSelect.activated && receptor.getIsenabled())
          ac.push(receptor.getId())
        if (initialProps.filterSelect.deactivated && !receptor.getIsenabled())
          ac.push(receptor.getId())
        return ac
      }, []) || []
    )
  }, [
    initialProps.filterSelect.activated,
    initialProps.filterSelect.deactivated,
    receptorNames,
    response?.data,
  ])

  if (response.isValidating && !receptorIds.length) {
    return initialProps.placeholder || <ContentLoader />
  }

  if (response.error || !response.data) {
    return initialProps.onError ? (
      initialProps.onError({
        error: response.error ?? new Error('not found'),
        props: initialProps.props,
      })
    ) : (
      <></>
    )
  }

  return initialProps.child({
    props: initialProps.props,
    receptorIds,
    totalReceptors: response.data.getReceptorsList().length || 0,
    mutate: async () => {
      const m = await response.mutate()
      return m
        ? new StringList().setItemList(
            m.getReceptorsList().map((p) => p.getId()),
          )
        : undefined
    },
  })
}

export enum DATE_FORMATS {
  US_STYLE = 'MM/DD/YYYY',
  ISO = 'YYYY-MM-DD',
  FULL_WITH_DAY = 'dddd, MMMM D, YYYY',
  SHORT = 'MMM D, YYYY',
  VERBOSE = 'MMMM D, YYYY',
  EUROPEAN = 'DD/MM/YYYY',
  YEAR_MONTH_DAY = 'YYYY, MMMM D',
  MONTH_YEAR = 'MMMM YYYY',
  DOTS = 'DD.MM.YYYY',
  YEAR_ONLY = 'YYYY',
  MONTH_DAY = 'MMM D',
  WITH_TIME = 'MMM D, YYYY, h:mm A', // Example format: Apr 9, 2024, 11:56 AM
  WITH_TIME_24H = 'MMM D, YYYY, HH:mm', // Example format: Apr 9, 2024, 23:56
  TIME_ONLY_12H = 'h:mm A', // Example format: 11:56 AM
  TIME_ONLY_24H = 'HH:mm', // Example format: 23:56
}

import { ModalFormIdQueryParam } from '../../ModalForm'

export enum AddDocumentRequestFormState {
  NOT_VALIDATED,
  HAS_ERRORS,
  NO_ERRORS,
}

export const AddDocumentRequestQueryParams = {
  MODAL_FORM_ID: ModalFormIdQueryParam,
  MODEL_ID: 'modelId',
}

// source: receptor_v1/receptor.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.receptor_v1.Credential', null, global);
goog.exportSymbol('proto.receptor_v1.Document', null, global);
goog.exportSymbol('proto.receptor_v1.Evidence', null, global);
goog.exportSymbol('proto.receptor_v1.Evidence.EvidenceTypeCase', null, global);
goog.exportSymbol('proto.receptor_v1.Finding', null, global);
goog.exportSymbol('proto.receptor_v1.JobResult', null, global);
goog.exportSymbol('proto.receptor_v1.ReceptorConfiguration', null, global);
goog.exportSymbol('proto.receptor_v1.ReceptorOID', null, global);
goog.exportSymbol('proto.receptor_v1.Row', null, global);
goog.exportSymbol('proto.receptor_v1.ServiceEntities', null, global);
goog.exportSymbol('proto.receptor_v1.ServiceEntity', null, global);
goog.exportSymbol('proto.receptor_v1.Source', null, global);
goog.exportSymbol('proto.receptor_v1.Struct', null, global);
goog.exportSymbol('proto.receptor_v1.Value', null, global);
goog.exportSymbol('proto.receptor_v1.Value.ValueTypeCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.receptor_v1.Finding = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.receptor_v1.Finding.repeatedFields_, null);
};
goog.inherits(proto.receptor_v1.Finding, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.receptor_v1.Finding.displayName = 'proto.receptor_v1.Finding';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.receptor_v1.Evidence = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.receptor_v1.Evidence.repeatedFields_, proto.receptor_v1.Evidence.oneofGroups_);
};
goog.inherits(proto.receptor_v1.Evidence, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.receptor_v1.Evidence.displayName = 'proto.receptor_v1.Evidence';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.receptor_v1.Source = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.receptor_v1.Source, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.receptor_v1.Source.displayName = 'proto.receptor_v1.Source';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.receptor_v1.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.receptor_v1.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.receptor_v1.Document.displayName = 'proto.receptor_v1.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.receptor_v1.Struct = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.receptor_v1.Struct.repeatedFields_, null);
};
goog.inherits(proto.receptor_v1.Struct, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.receptor_v1.Struct.displayName = 'proto.receptor_v1.Struct';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.receptor_v1.Row = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.receptor_v1.Row, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.receptor_v1.Row.displayName = 'proto.receptor_v1.Row';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.receptor_v1.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.receptor_v1.Value.oneofGroups_);
};
goog.inherits(proto.receptor_v1.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.receptor_v1.Value.displayName = 'proto.receptor_v1.Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.receptor_v1.ServiceEntities = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.receptor_v1.ServiceEntities.repeatedFields_, null);
};
goog.inherits(proto.receptor_v1.ServiceEntities, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.receptor_v1.ServiceEntities.displayName = 'proto.receptor_v1.ServiceEntities';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.receptor_v1.ServiceEntity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.receptor_v1.ServiceEntity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.receptor_v1.ServiceEntity.displayName = 'proto.receptor_v1.ServiceEntity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.receptor_v1.Credential = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.receptor_v1.Credential, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.receptor_v1.Credential.displayName = 'proto.receptor_v1.Credential';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.receptor_v1.ReceptorOID = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.receptor_v1.ReceptorOID, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.receptor_v1.ReceptorOID.displayName = 'proto.receptor_v1.ReceptorOID';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.receptor_v1.ReceptorConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.receptor_v1.ReceptorConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.receptor_v1.ReceptorConfiguration.displayName = 'proto.receptor_v1.ReceptorConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.receptor_v1.JobResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.receptor_v1.JobResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.receptor_v1.JobResult.displayName = 'proto.receptor_v1.JobResult';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.receptor_v1.Finding.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.receptor_v1.Finding.prototype.toObject = function(opt_includeInstance) {
  return proto.receptor_v1.Finding.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.receptor_v1.Finding} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Finding.toObject = function(includeInstance, msg) {
  var f, obj = {
    receptorType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceProviderAccount: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entitiesList: jspb.Message.toObjectList(msg.getEntitiesList(),
    proto.receptor_v1.ServiceEntity.toObject, includeInstance),
    evidencesList: jspb.Message.toObjectList(msg.getEvidencesList(),
    proto.receptor_v1.Evidence.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.receptor_v1.Finding}
 */
proto.receptor_v1.Finding.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.receptor_v1.Finding;
  return proto.receptor_v1.Finding.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.receptor_v1.Finding} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.receptor_v1.Finding}
 */
proto.receptor_v1.Finding.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceptorType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceProviderAccount(value);
      break;
    case 3:
      var value = new proto.receptor_v1.ServiceEntity;
      reader.readMessage(value,proto.receptor_v1.ServiceEntity.deserializeBinaryFromReader);
      msg.addEntities(value);
      break;
    case 4:
      var value = new proto.receptor_v1.Evidence;
      reader.readMessage(value,proto.receptor_v1.Evidence.deserializeBinaryFromReader);
      msg.addEvidences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.receptor_v1.Finding.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.receptor_v1.Finding.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.receptor_v1.Finding} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Finding.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceptorType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceProviderAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.receptor_v1.ServiceEntity.serializeBinaryToWriter
    );
  }
  f = message.getEvidencesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.receptor_v1.Evidence.serializeBinaryToWriter
    );
  }
};


/**
 * optional string receptor_type = 1;
 * @return {string}
 */
proto.receptor_v1.Finding.prototype.getReceptorType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Finding} returns this
 */
proto.receptor_v1.Finding.prototype.setReceptorType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string service_provider_account = 2;
 * @return {string}
 */
proto.receptor_v1.Finding.prototype.getServiceProviderAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Finding} returns this
 */
proto.receptor_v1.Finding.prototype.setServiceProviderAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ServiceEntity entities = 3;
 * @return {!Array<!proto.receptor_v1.ServiceEntity>}
 */
proto.receptor_v1.Finding.prototype.getEntitiesList = function() {
  return /** @type{!Array<!proto.receptor_v1.ServiceEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.receptor_v1.ServiceEntity, 3));
};


/**
 * @param {!Array<!proto.receptor_v1.ServiceEntity>} value
 * @return {!proto.receptor_v1.Finding} returns this
*/
proto.receptor_v1.Finding.prototype.setEntitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.receptor_v1.ServiceEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.receptor_v1.ServiceEntity}
 */
proto.receptor_v1.Finding.prototype.addEntities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.receptor_v1.ServiceEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.receptor_v1.Finding} returns this
 */
proto.receptor_v1.Finding.prototype.clearEntitiesList = function() {
  return this.setEntitiesList([]);
};


/**
 * repeated Evidence evidences = 4;
 * @return {!Array<!proto.receptor_v1.Evidence>}
 */
proto.receptor_v1.Finding.prototype.getEvidencesList = function() {
  return /** @type{!Array<!proto.receptor_v1.Evidence>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.receptor_v1.Evidence, 4));
};


/**
 * @param {!Array<!proto.receptor_v1.Evidence>} value
 * @return {!proto.receptor_v1.Finding} returns this
*/
proto.receptor_v1.Finding.prototype.setEvidencesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.receptor_v1.Evidence=} opt_value
 * @param {number=} opt_index
 * @return {!proto.receptor_v1.Evidence}
 */
proto.receptor_v1.Finding.prototype.addEvidences = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.receptor_v1.Evidence, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.receptor_v1.Finding} returns this
 */
proto.receptor_v1.Finding.prototype.clearEvidencesList = function() {
  return this.setEvidencesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.receptor_v1.Evidence.repeatedFields_ = [5];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.receptor_v1.Evidence.oneofGroups_ = [[6,7]];

/**
 * @enum {number}
 */
proto.receptor_v1.Evidence.EvidenceTypeCase = {
  EVIDENCE_TYPE_NOT_SET: 0,
  DOC: 6,
  STRUCT: 7
};

/**
 * @return {proto.receptor_v1.Evidence.EvidenceTypeCase}
 */
proto.receptor_v1.Evidence.prototype.getEvidenceTypeCase = function() {
  return /** @type {proto.receptor_v1.Evidence.EvidenceTypeCase} */(jspb.Message.computeOneofCase(this, proto.receptor_v1.Evidence.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.receptor_v1.Evidence.prototype.toObject = function(opt_includeInstance) {
  return proto.receptor_v1.Evidence.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.receptor_v1.Evidence} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Evidence.toObject = function(includeInstance, msg) {
  var f, obj = {
    caption: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    serviceName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    entityType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sourcesList: jspb.Message.toObjectList(msg.getSourcesList(),
    proto.receptor_v1.Source.toObject, includeInstance),
    doc: (f = msg.getDoc()) && proto.receptor_v1.Document.toObject(includeInstance, f),
    struct: (f = msg.getStruct()) && proto.receptor_v1.Struct.toObject(includeInstance, f),
    serviceAccountId: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.receptor_v1.Evidence}
 */
proto.receptor_v1.Evidence.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.receptor_v1.Evidence;
  return proto.receptor_v1.Evidence.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.receptor_v1.Evidence} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.receptor_v1.Evidence}
 */
proto.receptor_v1.Evidence.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaption(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityType(value);
      break;
    case 5:
      var value = new proto.receptor_v1.Source;
      reader.readMessage(value,proto.receptor_v1.Source.deserializeBinaryFromReader);
      msg.addSources(value);
      break;
    case 6:
      var value = new proto.receptor_v1.Document;
      reader.readMessage(value,proto.receptor_v1.Document.deserializeBinaryFromReader);
      msg.setDoc(value);
      break;
    case 7:
      var value = new proto.receptor_v1.Struct;
      reader.readMessage(value,proto.receptor_v1.Struct.deserializeBinaryFromReader);
      msg.setStruct(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.receptor_v1.Evidence.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.receptor_v1.Evidence.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.receptor_v1.Evidence} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Evidence.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCaption();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEntityType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSourcesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.receptor_v1.Source.serializeBinaryToWriter
    );
  }
  f = message.getDoc();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.receptor_v1.Document.serializeBinaryToWriter
    );
  }
  f = message.getStruct();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.receptor_v1.Struct.serializeBinaryToWriter
    );
  }
  f = message.getServiceAccountId();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string caption = 1;
 * @return {string}
 */
proto.receptor_v1.Evidence.prototype.getCaption = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Evidence} returns this
 */
proto.receptor_v1.Evidence.prototype.setCaption = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.receptor_v1.Evidence.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Evidence} returns this
 */
proto.receptor_v1.Evidence.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string service_name = 3;
 * @return {string}
 */
proto.receptor_v1.Evidence.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Evidence} returns this
 */
proto.receptor_v1.Evidence.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string entity_type = 4;
 * @return {string}
 */
proto.receptor_v1.Evidence.prototype.getEntityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Evidence} returns this
 */
proto.receptor_v1.Evidence.prototype.setEntityType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Source sources = 5;
 * @return {!Array<!proto.receptor_v1.Source>}
 */
proto.receptor_v1.Evidence.prototype.getSourcesList = function() {
  return /** @type{!Array<!proto.receptor_v1.Source>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.receptor_v1.Source, 5));
};


/**
 * @param {!Array<!proto.receptor_v1.Source>} value
 * @return {!proto.receptor_v1.Evidence} returns this
*/
proto.receptor_v1.Evidence.prototype.setSourcesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.receptor_v1.Source=} opt_value
 * @param {number=} opt_index
 * @return {!proto.receptor_v1.Source}
 */
proto.receptor_v1.Evidence.prototype.addSources = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.receptor_v1.Source, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.receptor_v1.Evidence} returns this
 */
proto.receptor_v1.Evidence.prototype.clearSourcesList = function() {
  return this.setSourcesList([]);
};


/**
 * optional Document doc = 6;
 * @return {?proto.receptor_v1.Document}
 */
proto.receptor_v1.Evidence.prototype.getDoc = function() {
  return /** @type{?proto.receptor_v1.Document} */ (
    jspb.Message.getWrapperField(this, proto.receptor_v1.Document, 6));
};


/**
 * @param {?proto.receptor_v1.Document|undefined} value
 * @return {!proto.receptor_v1.Evidence} returns this
*/
proto.receptor_v1.Evidence.prototype.setDoc = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.receptor_v1.Evidence.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.receptor_v1.Evidence} returns this
 */
proto.receptor_v1.Evidence.prototype.clearDoc = function() {
  return this.setDoc(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.receptor_v1.Evidence.prototype.hasDoc = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional Struct struct = 7;
 * @return {?proto.receptor_v1.Struct}
 */
proto.receptor_v1.Evidence.prototype.getStruct = function() {
  return /** @type{?proto.receptor_v1.Struct} */ (
    jspb.Message.getWrapperField(this, proto.receptor_v1.Struct, 7));
};


/**
 * @param {?proto.receptor_v1.Struct|undefined} value
 * @return {!proto.receptor_v1.Evidence} returns this
*/
proto.receptor_v1.Evidence.prototype.setStruct = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.receptor_v1.Evidence.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.receptor_v1.Evidence} returns this
 */
proto.receptor_v1.Evidence.prototype.clearStruct = function() {
  return this.setStruct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.receptor_v1.Evidence.prototype.hasStruct = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string service_account_id = 8;
 * @return {string}
 */
proto.receptor_v1.Evidence.prototype.getServiceAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Evidence} returns this
 */
proto.receptor_v1.Evidence.prototype.setServiceAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.receptor_v1.Source.prototype.toObject = function(opt_includeInstance) {
  return proto.receptor_v1.Source.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.receptor_v1.Source} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Source.toObject = function(includeInstance, msg) {
  var f, obj = {
    rawApiRequest: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rawApiResponse: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.receptor_v1.Source}
 */
proto.receptor_v1.Source.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.receptor_v1.Source;
  return proto.receptor_v1.Source.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.receptor_v1.Source} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.receptor_v1.Source}
 */
proto.receptor_v1.Source.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawApiRequest(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRawApiResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.receptor_v1.Source.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.receptor_v1.Source.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.receptor_v1.Source} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Source.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRawApiRequest();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRawApiResponse();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string raw_api_request = 1;
 * @return {string}
 */
proto.receptor_v1.Source.prototype.getRawApiRequest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Source} returns this
 */
proto.receptor_v1.Source.prototype.setRawApiRequest = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string raw_api_response = 2;
 * @return {string}
 */
proto.receptor_v1.Source.prototype.getRawApiResponse = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Source} returns this
 */
proto.receptor_v1.Source.prototype.setRawApiResponse = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.receptor_v1.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.receptor_v1.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.receptor_v1.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    mime: jspb.Message.getFieldWithDefault(msg, 2, ""),
    body: msg.getBody_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.receptor_v1.Document}
 */
proto.receptor_v1.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.receptor_v1.Document;
  return proto.receptor_v1.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.receptor_v1.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.receptor_v1.Document}
 */
proto.receptor_v1.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBody(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.receptor_v1.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.receptor_v1.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.receptor_v1.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBody_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * optional string mime = 2;
 * @return {string}
 */
proto.receptor_v1.Document.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Document} returns this
 */
proto.receptor_v1.Document.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes body = 3;
 * @return {!(string|Uint8Array)}
 */
proto.receptor_v1.Document.prototype.getBody = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes body = 3;
 * This is a type-conversion wrapper around `getBody()`
 * @return {string}
 */
proto.receptor_v1.Document.prototype.getBody_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBody()));
};


/**
 * optional bytes body = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBody()`
 * @return {!Uint8Array}
 */
proto.receptor_v1.Document.prototype.getBody_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBody()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.receptor_v1.Document} returns this
 */
proto.receptor_v1.Document.prototype.setBody = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.receptor_v1.Struct.repeatedFields_ = [2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.receptor_v1.Struct.prototype.toObject = function(opt_includeInstance) {
  return proto.receptor_v1.Struct.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.receptor_v1.Struct} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Struct.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.receptor_v1.Row.toObject, includeInstance),
    colDisplayNamesMap: (f = msg.getColDisplayNamesMap()) ? f.toObject(includeInstance, undefined) : [],
    colDisplayOrderList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    colTagsMap: (f = msg.getColTagsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.receptor_v1.Struct}
 */
proto.receptor_v1.Struct.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.receptor_v1.Struct;
  return proto.receptor_v1.Struct.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.receptor_v1.Struct} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.receptor_v1.Struct}
 */
proto.receptor_v1.Struct.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new proto.receptor_v1.Row;
      reader.readMessage(value,proto.receptor_v1.Row.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    case 3:
      var value = msg.getColDisplayNamesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addColDisplayOrder(value);
      break;
    case 5:
      var value = msg.getColTagsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.receptor_v1.Struct.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.receptor_v1.Struct.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.receptor_v1.Struct} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Struct.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.receptor_v1.Row.serializeBinaryToWriter
    );
  }
  f = message.getColDisplayNamesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getColDisplayOrderList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getColTagsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * repeated Row rows = 2;
 * @return {!Array<!proto.receptor_v1.Row>}
 */
proto.receptor_v1.Struct.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.receptor_v1.Row>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.receptor_v1.Row, 2));
};


/**
 * @param {!Array<!proto.receptor_v1.Row>} value
 * @return {!proto.receptor_v1.Struct} returns this
*/
proto.receptor_v1.Struct.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.receptor_v1.Row=} opt_value
 * @param {number=} opt_index
 * @return {!proto.receptor_v1.Row}
 */
proto.receptor_v1.Struct.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.receptor_v1.Row, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.receptor_v1.Struct} returns this
 */
proto.receptor_v1.Struct.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};


/**
 * map<string, string> col_display_names = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.receptor_v1.Struct.prototype.getColDisplayNamesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.receptor_v1.Struct} returns this
 */
proto.receptor_v1.Struct.prototype.clearColDisplayNamesMap = function() {
  this.getColDisplayNamesMap().clear();
  return this;};


/**
 * repeated string col_display_order = 4;
 * @return {!Array<string>}
 */
proto.receptor_v1.Struct.prototype.getColDisplayOrderList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.receptor_v1.Struct} returns this
 */
proto.receptor_v1.Struct.prototype.setColDisplayOrderList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.receptor_v1.Struct} returns this
 */
proto.receptor_v1.Struct.prototype.addColDisplayOrder = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.receptor_v1.Struct} returns this
 */
proto.receptor_v1.Struct.prototype.clearColDisplayOrderList = function() {
  return this.setColDisplayOrderList([]);
};


/**
 * map<string, string> col_tags = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.receptor_v1.Struct.prototype.getColTagsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.receptor_v1.Struct} returns this
 */
proto.receptor_v1.Struct.prototype.clearColTagsMap = function() {
  this.getColTagsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.receptor_v1.Row.prototype.toObject = function(opt_includeInstance) {
  return proto.receptor_v1.Row.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.receptor_v1.Row} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Row.toObject = function(includeInstance, msg) {
  var f, obj = {
    entityInstanceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    colsMap: (f = msg.getColsMap()) ? f.toObject(includeInstance, proto.receptor_v1.Value.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.receptor_v1.Row}
 */
proto.receptor_v1.Row.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.receptor_v1.Row;
  return proto.receptor_v1.Row.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.receptor_v1.Row} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.receptor_v1.Row}
 */
proto.receptor_v1.Row.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityInstanceId(value);
      break;
    case 2:
      var value = msg.getColsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.receptor_v1.Value.deserializeBinaryFromReader, "", new proto.receptor_v1.Value());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.receptor_v1.Row.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.receptor_v1.Row.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.receptor_v1.Row} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Row.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEntityInstanceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getColsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.receptor_v1.Value.serializeBinaryToWriter);
  }
};


/**
 * optional string entity_instance_id = 1;
 * @return {string}
 */
proto.receptor_v1.Row.prototype.getEntityInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Row} returns this
 */
proto.receptor_v1.Row.prototype.setEntityInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * map<string, Value> cols = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.receptor_v1.Value>}
 */
proto.receptor_v1.Row.prototype.getColsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.receptor_v1.Value>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.receptor_v1.Value));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.receptor_v1.Row} returns this
 */
proto.receptor_v1.Row.prototype.clearColsMap = function() {
  this.getColsMap().clear();
  return this;};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.receptor_v1.Value.oneofGroups_ = [[1,2,3,4,5,6,7,8,9]];

/**
 * @enum {number}
 */
proto.receptor_v1.Value.ValueTypeCase = {
  VALUE_TYPE_NOT_SET: 0,
  DOUBLE_VALUE: 1,
  FLOAT_VALUE: 2,
  INT32_VALUE: 3,
  INT64_VALUE: 4,
  UINT32_VALUE: 5,
  UINT64_VALUE: 6,
  BOOL_VALUE: 7,
  STRING_VALUE: 8,
  TIMESTAMP_VALUE: 9
};

/**
 * @return {proto.receptor_v1.Value.ValueTypeCase}
 */
proto.receptor_v1.Value.prototype.getValueTypeCase = function() {
  return /** @type {proto.receptor_v1.Value.ValueTypeCase} */(jspb.Message.computeOneofCase(this, proto.receptor_v1.Value.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.receptor_v1.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.receptor_v1.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.receptor_v1.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    doubleValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    floatValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    int32Value: jspb.Message.getFieldWithDefault(msg, 3, 0),
    int64Value: jspb.Message.getFieldWithDefault(msg, 4, 0),
    uint32Value: jspb.Message.getFieldWithDefault(msg, 5, 0),
    uint64Value: jspb.Message.getFieldWithDefault(msg, 6, 0),
    boolValue: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    stringValue: jspb.Message.getFieldWithDefault(msg, 8, ""),
    timestampValue: (f = msg.getTimestampValue()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.receptor_v1.Value}
 */
proto.receptor_v1.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.receptor_v1.Value;
  return proto.receptor_v1.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.receptor_v1.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.receptor_v1.Value}
 */
proto.receptor_v1.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFloatValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setInt32Value(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInt64Value(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUint32Value(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUint64Value(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolValue(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTimestampValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.receptor_v1.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.receptor_v1.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.receptor_v1.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTimestampValue();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional double double_value = 1;
 * @return {number}
 */
proto.receptor_v1.Value.prototype.getDoubleValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.setDoubleValue = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.receptor_v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.clearDoubleValue = function() {
  return jspb.Message.setOneofField(this, 1, proto.receptor_v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.receptor_v1.Value.prototype.hasDoubleValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float float_value = 2;
 * @return {number}
 */
proto.receptor_v1.Value.prototype.getFloatValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.setFloatValue = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.receptor_v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.clearFloatValue = function() {
  return jspb.Message.setOneofField(this, 2, proto.receptor_v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.receptor_v1.Value.prototype.hasFloatValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 int32_value = 3;
 * @return {number}
 */
proto.receptor_v1.Value.prototype.getInt32Value = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.setInt32Value = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.receptor_v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.clearInt32Value = function() {
  return jspb.Message.setOneofField(this, 3, proto.receptor_v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.receptor_v1.Value.prototype.hasInt32Value = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 int64_value = 4;
 * @return {number}
 */
proto.receptor_v1.Value.prototype.getInt64Value = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.setInt64Value = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.receptor_v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.clearInt64Value = function() {
  return jspb.Message.setOneofField(this, 4, proto.receptor_v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.receptor_v1.Value.prototype.hasInt64Value = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 uint32_value = 5;
 * @return {number}
 */
proto.receptor_v1.Value.prototype.getUint32Value = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.setUint32Value = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.receptor_v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.clearUint32Value = function() {
  return jspb.Message.setOneofField(this, 5, proto.receptor_v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.receptor_v1.Value.prototype.hasUint32Value = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint64 uint64_value = 6;
 * @return {number}
 */
proto.receptor_v1.Value.prototype.getUint64Value = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.setUint64Value = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.receptor_v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.clearUint64Value = function() {
  return jspb.Message.setOneofField(this, 6, proto.receptor_v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.receptor_v1.Value.prototype.hasUint64Value = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool bool_value = 7;
 * @return {boolean}
 */
proto.receptor_v1.Value.prototype.getBoolValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.setBoolValue = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.receptor_v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.clearBoolValue = function() {
  return jspb.Message.setOneofField(this, 7, proto.receptor_v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.receptor_v1.Value.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string string_value = 8;
 * @return {string}
 */
proto.receptor_v1.Value.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.setStringValue = function(value) {
  return jspb.Message.setOneofField(this, 8, proto.receptor_v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.clearStringValue = function() {
  return jspb.Message.setOneofField(this, 8, proto.receptor_v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.receptor_v1.Value.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Timestamp timestamp_value = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.receptor_v1.Value.prototype.getTimestampValue = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.receptor_v1.Value} returns this
*/
proto.receptor_v1.Value.prototype.setTimestampValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.receptor_v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.receptor_v1.Value} returns this
 */
proto.receptor_v1.Value.prototype.clearTimestampValue = function() {
  return this.setTimestampValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.receptor_v1.Value.prototype.hasTimestampValue = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.receptor_v1.ServiceEntities.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.receptor_v1.ServiceEntities.prototype.toObject = function(opt_includeInstance) {
  return proto.receptor_v1.ServiceEntities.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.receptor_v1.ServiceEntities} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.ServiceEntities.toObject = function(includeInstance, msg) {
  var f, obj = {
    receptorType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    serviceProviderAccount: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entitiesList: jspb.Message.toObjectList(msg.getEntitiesList(),
    proto.receptor_v1.ServiceEntity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.receptor_v1.ServiceEntities}
 */
proto.receptor_v1.ServiceEntities.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.receptor_v1.ServiceEntities;
  return proto.receptor_v1.ServiceEntities.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.receptor_v1.ServiceEntities} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.receptor_v1.ServiceEntities}
 */
proto.receptor_v1.ServiceEntities.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceptorType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceProviderAccount(value);
      break;
    case 3:
      var value = new proto.receptor_v1.ServiceEntity;
      reader.readMessage(value,proto.receptor_v1.ServiceEntity.deserializeBinaryFromReader);
      msg.addEntities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.receptor_v1.ServiceEntities.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.receptor_v1.ServiceEntities.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.receptor_v1.ServiceEntities} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.ServiceEntities.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceptorType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServiceProviderAccount();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.receptor_v1.ServiceEntity.serializeBinaryToWriter
    );
  }
};


/**
 * optional string receptor_type = 1;
 * @return {string}
 */
proto.receptor_v1.ServiceEntities.prototype.getReceptorType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.ServiceEntities} returns this
 */
proto.receptor_v1.ServiceEntities.prototype.setReceptorType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string service_provider_account = 2;
 * @return {string}
 */
proto.receptor_v1.ServiceEntities.prototype.getServiceProviderAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.ServiceEntities} returns this
 */
proto.receptor_v1.ServiceEntities.prototype.setServiceProviderAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated ServiceEntity entities = 3;
 * @return {!Array<!proto.receptor_v1.ServiceEntity>}
 */
proto.receptor_v1.ServiceEntities.prototype.getEntitiesList = function() {
  return /** @type{!Array<!proto.receptor_v1.ServiceEntity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.receptor_v1.ServiceEntity, 3));
};


/**
 * @param {!Array<!proto.receptor_v1.ServiceEntity>} value
 * @return {!proto.receptor_v1.ServiceEntities} returns this
*/
proto.receptor_v1.ServiceEntities.prototype.setEntitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.receptor_v1.ServiceEntity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.receptor_v1.ServiceEntity}
 */
proto.receptor_v1.ServiceEntities.prototype.addEntities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.receptor_v1.ServiceEntity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.receptor_v1.ServiceEntities} returns this
 */
proto.receptor_v1.ServiceEntities.prototype.clearEntitiesList = function() {
  return this.setEntitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.receptor_v1.ServiceEntity.prototype.toObject = function(opt_includeInstance) {
  return proto.receptor_v1.ServiceEntity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.receptor_v1.ServiceEntity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.ServiceEntity.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entityType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    entityInstanceName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    entityInstanceId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    serviceAccountId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.receptor_v1.ServiceEntity}
 */
proto.receptor_v1.ServiceEntity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.receptor_v1.ServiceEntity;
  return proto.receptor_v1.ServiceEntity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.receptor_v1.ServiceEntity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.receptor_v1.ServiceEntity}
 */
proto.receptor_v1.ServiceEntity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityInstanceName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntityInstanceId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.receptor_v1.ServiceEntity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.receptor_v1.ServiceEntity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.receptor_v1.ServiceEntity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.ServiceEntity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntityType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEntityInstanceName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEntityInstanceId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getServiceAccountId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string service_name = 1;
 * @return {string}
 */
proto.receptor_v1.ServiceEntity.prototype.getServiceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.ServiceEntity} returns this
 */
proto.receptor_v1.ServiceEntity.prototype.setServiceName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entity_type = 2;
 * @return {string}
 */
proto.receptor_v1.ServiceEntity.prototype.getEntityType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.ServiceEntity} returns this
 */
proto.receptor_v1.ServiceEntity.prototype.setEntityType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string entity_instance_name = 3;
 * @return {string}
 */
proto.receptor_v1.ServiceEntity.prototype.getEntityInstanceName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.ServiceEntity} returns this
 */
proto.receptor_v1.ServiceEntity.prototype.setEntityInstanceName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string entity_instance_id = 4;
 * @return {string}
 */
proto.receptor_v1.ServiceEntity.prototype.getEntityInstanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.ServiceEntity} returns this
 */
proto.receptor_v1.ServiceEntity.prototype.setEntityInstanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string service_account_id = 5;
 * @return {string}
 */
proto.receptor_v1.ServiceEntity.prototype.getServiceAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.ServiceEntity} returns this
 */
proto.receptor_v1.ServiceEntity.prototype.setServiceAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.receptor_v1.Credential.prototype.toObject = function(opt_includeInstance) {
  return proto.receptor_v1.Credential.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.receptor_v1.Credential} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Credential.toObject = function(includeInstance, msg) {
  var f, obj = {
    receptorObjectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    credential: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isCredentialValid: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    message: jspb.Message.getFieldWithDefault(msg, 4, ""),
    exceptions: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.receptor_v1.Credential}
 */
proto.receptor_v1.Credential.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.receptor_v1.Credential;
  return proto.receptor_v1.Credential.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.receptor_v1.Credential} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.receptor_v1.Credential}
 */
proto.receptor_v1.Credential.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceptorObjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCredential(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCredentialValid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExceptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.receptor_v1.Credential.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.receptor_v1.Credential.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.receptor_v1.Credential} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.Credential.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceptorObjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCredential();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsCredentialValid();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExceptions();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string receptor_object_id = 1;
 * @return {string}
 */
proto.receptor_v1.Credential.prototype.getReceptorObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Credential} returns this
 */
proto.receptor_v1.Credential.prototype.setReceptorObjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string credential = 2;
 * @return {string}
 */
proto.receptor_v1.Credential.prototype.getCredential = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Credential} returns this
 */
proto.receptor_v1.Credential.prototype.setCredential = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_credential_valid = 3;
 * @return {boolean}
 */
proto.receptor_v1.Credential.prototype.getIsCredentialValid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.receptor_v1.Credential} returns this
 */
proto.receptor_v1.Credential.prototype.setIsCredentialValid = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.receptor_v1.Credential.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Credential} returns this
 */
proto.receptor_v1.Credential.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string exceptions = 5;
 * @return {string}
 */
proto.receptor_v1.Credential.prototype.getExceptions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.Credential} returns this
 */
proto.receptor_v1.Credential.prototype.setExceptions = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.receptor_v1.ReceptorOID.prototype.toObject = function(opt_includeInstance) {
  return proto.receptor_v1.ReceptorOID.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.receptor_v1.ReceptorOID} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.ReceptorOID.toObject = function(includeInstance, msg) {
  var f, obj = {
    receptorObjectId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.receptor_v1.ReceptorOID}
 */
proto.receptor_v1.ReceptorOID.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.receptor_v1.ReceptorOID;
  return proto.receptor_v1.ReceptorOID.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.receptor_v1.ReceptorOID} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.receptor_v1.ReceptorOID}
 */
proto.receptor_v1.ReceptorOID.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceptorObjectId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.receptor_v1.ReceptorOID.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.receptor_v1.ReceptorOID.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.receptor_v1.ReceptorOID} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.ReceptorOID.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceptorObjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string receptor_object_id = 1;
 * @return {string}
 */
proto.receptor_v1.ReceptorOID.prototype.getReceptorObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.ReceptorOID} returns this
 */
proto.receptor_v1.ReceptorOID.prototype.setReceptorObjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.receptor_v1.ReceptorConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.receptor_v1.ReceptorConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.receptor_v1.ReceptorConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.ReceptorConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    receptorObjectId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    credential: jspb.Message.getFieldWithDefault(msg, 2, ""),
    config: jspb.Message.getFieldWithDefault(msg, 3, ""),
    serviceProviderAccount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    modelId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.receptor_v1.ReceptorConfiguration}
 */
proto.receptor_v1.ReceptorConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.receptor_v1.ReceptorConfiguration;
  return proto.receptor_v1.ReceptorConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.receptor_v1.ReceptorConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.receptor_v1.ReceptorConfiguration}
 */
proto.receptor_v1.ReceptorConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceptorObjectId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCredential(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfig(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setServiceProviderAccount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.receptor_v1.ReceptorConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.receptor_v1.ReceptorConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.receptor_v1.ReceptorConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.ReceptorConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReceptorObjectId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCredential();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConfig();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServiceProviderAccount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModelId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string receptor_object_id = 1;
 * @return {string}
 */
proto.receptor_v1.ReceptorConfiguration.prototype.getReceptorObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.ReceptorConfiguration} returns this
 */
proto.receptor_v1.ReceptorConfiguration.prototype.setReceptorObjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string credential = 2;
 * @return {string}
 */
proto.receptor_v1.ReceptorConfiguration.prototype.getCredential = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.ReceptorConfiguration} returns this
 */
proto.receptor_v1.ReceptorConfiguration.prototype.setCredential = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string config = 3;
 * @return {string}
 */
proto.receptor_v1.ReceptorConfiguration.prototype.getConfig = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.ReceptorConfiguration} returns this
 */
proto.receptor_v1.ReceptorConfiguration.prototype.setConfig = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string service_provider_account = 4;
 * @return {string}
 */
proto.receptor_v1.ReceptorConfiguration.prototype.getServiceProviderAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.ReceptorConfiguration} returns this
 */
proto.receptor_v1.ReceptorConfiguration.prototype.setServiceProviderAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string model_id = 5;
 * @return {string}
 */
proto.receptor_v1.ReceptorConfiguration.prototype.getModelId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.ReceptorConfiguration} returns this
 */
proto.receptor_v1.ReceptorConfiguration.prototype.setModelId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.receptor_v1.JobResult.prototype.toObject = function(opt_includeInstance) {
  return proto.receptor_v1.JobResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.receptor_v1.JobResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.JobResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    tracerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    command: jspb.Message.getFieldWithDefault(msg, 2, ""),
    result: jspb.Message.getFieldWithDefault(msg, 3, ""),
    receptorObjectId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    exceptions: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.receptor_v1.JobResult}
 */
proto.receptor_v1.JobResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.receptor_v1.JobResult;
  return proto.receptor_v1.JobResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.receptor_v1.JobResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.receptor_v1.JobResult}
 */
proto.receptor_v1.JobResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTracerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommand(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setResult(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceptorObjectId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setExceptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.receptor_v1.JobResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.receptor_v1.JobResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.receptor_v1.JobResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.receptor_v1.JobResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTracerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCommand();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getResult();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getReceptorObjectId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExceptions();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string tracer_id = 1;
 * @return {string}
 */
proto.receptor_v1.JobResult.prototype.getTracerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.JobResult} returns this
 */
proto.receptor_v1.JobResult.prototype.setTracerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string command = 2;
 * @return {string}
 */
proto.receptor_v1.JobResult.prototype.getCommand = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.JobResult} returns this
 */
proto.receptor_v1.JobResult.prototype.setCommand = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string result = 3;
 * @return {string}
 */
proto.receptor_v1.JobResult.prototype.getResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.JobResult} returns this
 */
proto.receptor_v1.JobResult.prototype.setResult = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string receptor_object_id = 4;
 * @return {string}
 */
proto.receptor_v1.JobResult.prototype.getReceptorObjectId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.JobResult} returns this
 */
proto.receptor_v1.JobResult.prototype.setReceptorObjectId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string exceptions = 5;
 * @return {string}
 */
proto.receptor_v1.JobResult.prototype.getExceptions = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.receptor_v1.JobResult} returns this
 */
proto.receptor_v1.JobResult.prototype.setExceptions = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.receptor_v1);

import React from 'react'
import { Evidence } from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import { useLocation } from 'react-router-dom'
import {
  RowItemElement,
  RowItemProp,
} from '../../../../components/Reusable/Grid/Accordion/RowItemProp'
import { APP_ROUTES } from '../../../../Utils/globalEnums'
import { EvidenceRow, EvidenceRowProps } from './EvidenceRow'

export const evidenceAsRowItemElement = (
  evidence: Evidence,
  isDeleteDisabled: boolean,
  controlOID: string,
  controlId?: string,
  timeRange?: TimeRange,
  requestId?: string,
  total?: number,
): RowItemElement => ({
  RowItem: (p: RowItemProp) => {
    const location = useLocation()

    const isControlShowPage = location.pathname.includes(APP_ROUTES.CONTROLS)

    const props: EvidenceRowProps = {
      ...p,
      isDeleteDisabled,
      controlId,
      timeRange,
      caption: evidence.getCaption(),
      evidenceId: evidence.getId(),
      contentId: evidence.getContentId(),
      mime: evidence.getMime(),
      actorEmail: evidence.getActorEmail(),
      actorName: evidence.getActorName()?.getValue(),
      createdAt: evidence.getCreatedAt()?.toDate() ?? new Date(),
      isAutomated: evidence.getIsAutomated(),
      relevantDate: evidence.getRelevantDate(),
      isViaRequest: evidence.getIsViaRequest(),
      isControlShowPage,
      columnType: 'a',
      requestId,
      controlOID,
      total,
    }

    return <EvidenceRow {...props} />
  },
  key: evidence.getId(),
})

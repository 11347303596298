import React, { useContext, useEffect, useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import { useHref } from 'react-router-dom'
import {
  StandardButton,
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons'
import { useSignedContentUrl } from 'src/components/async/content/ContentStore.hooks'
import { ListItemPlaceholder } from 'src/components/Placeholders/ListItemPlaceholder'
import { useAnalytics } from 'src/analytics/useAnalytics'
import { HUBSPOT, goToHubspotSupport } from 'src/Utils/hubspot/hubspot.utils'
import AuthContext from 'src/context/authContext'
import { SideBar } from './SideBar'
import { HelpSubRoutes } from './HelpRoutes'
import { GlossaryPageHashes } from './Glossary/Glossary.constants'
import {
  HeaderButton,
  SideContainer,
  StyledHelpLinksSection,
  StyledHelpTextButton,
} from './Help.styles'
import { ISO_LINK, SOC2_PDF_LINK, SOC2_PDF_OBJECT_KEY } from './Help.constants'

export const KnowledgeBaseBtn = ({
  asTextButton,
}: {
  asTextButton?: boolean
}): JSX.Element => {
  const { track, events } = useAnalytics()
  const { authCtx } = useContext(AuthContext)
  const token = authCtx.accessToken

  const goToKB = () => {
    track(events.C_KNOWLEDGE_BASE)
    goToHubspotSupport(
      `${HUBSPOT.DOMAIN}${HUBSPOT.KNOWLEDGE_BASE}`,
      token,
      true,
    )
  }

  return asTextButton ? (
    <StyledHelpTextButton onClick={goToKB}>Knowledge Base</StyledHelpTextButton>
  ) : (
    <StandardButton
      onClick={goToKB}
      variant={StandardButtonVariant.SECONDARY}
      buttonSize={StandardButtonSize.MEDIUM}
    >
      Knowledge Base
    </StandardButton>
  )
}

export const ContactSupportBtn = ({
  asTextButton,
}: {
  asTextButton?: boolean
}): JSX.Element => {
  const { track, events } = useAnalytics()
  const { authCtx } = useContext(AuthContext)
  const token = authCtx.accessToken

  const goToSupport = () => {
    track(events.C_KNOWLEDGE_BASE)
    goToHubspotSupport(`${HUBSPOT.DOMAIN}${HUBSPOT.TICKETS_VIEW}`, token, true)
  }

  return asTextButton ? (
    <StyledHelpTextButton onClick={goToSupport}>
      Contact Support
    </StyledHelpTextButton>
  ) : (
    <StandardButton
      onClick={goToSupport}
      variant={StandardButtonVariant.PRIMARY}
      buttonSize={StandardButtonSize.MEDIUM}
    >
      Contact Support
    </StandardButton>
  )
}

const SideBarHeader = (): JSX.Element => (
  <HeaderButton>
    <KnowledgeBaseBtn />
    <ContactSupportBtn />
  </HeaderButton>
)

export const HelpLinks = (): JSX.Element => (
  <StyledHelpLinksSection>
    <KnowledgeBaseBtn asTextButton />
    <ContactSupportBtn asTextButton />
  </StyledHelpLinksSection>
)

export const GlossarySideBar = (): JSX.Element => (
  <SideContainer>
    <SideBarHeader />
    <SideBar title="Glossary">
      <HashLink
        to={useHref({
          pathname: `../${HelpSubRoutes.GLOSSARY}`,
          hash: GlossaryPageHashes.POLICIES,
        })}
      >
        Policies
      </HashLink>
      <HashLink
        to={useHref({
          pathname: `../${HelpSubRoutes.GLOSSARY}`,
          hash: GlossaryPageHashes.CONTROLS,
        })}
      >
        Controls
      </HashLink>
      <HashLink
        to={useHref({
          pathname: `../${HelpSubRoutes.GLOSSARY}`,
          hash: GlossaryPageHashes.SERVICES,
        })}
      >
        Services
      </HashLink>
      <HashLink
        to={useHref({
          pathname: `../${HelpSubRoutes.GLOSSARY}`,
          hash: GlossaryPageHashes.FRAMEWORKS,
        })}
      >
        Frameworks
      </HashLink>
      <HashLink
        to={useHref({
          pathname: `../${HelpSubRoutes.GLOSSARY}`,
          hash: GlossaryPageHashes.EVIDENCE,
        })}
      >
        Evidence
      </HashLink>
      <HashLink
        to={useHref({
          pathname: `../${HelpSubRoutes.GLOSSARY}`,
          hash: GlossaryPageHashes.RECEPTORS,
        })}
      >
        Receptors
      </HashLink>
    </SideBar>
  </SideContainer>
)

export const SOC2SideBar = (): JSX.Element => {
  const [fileUrl, setFileUrl] = useState<string>()
  const { data: url, isLoading } = useSignedContentUrl(SOC2_PDF_OBJECT_KEY)

  useEffect(() => {
    const newFileUrl = url?.getUrlObject()?.getUrl()
    if (!newFileUrl) return
    setFileUrl(newFileUrl)
  }, [url])

  return (
    <SideContainer>
      <SideBarHeader />
      <SideBar title="AICPA's SOC 2 Standard for 2022">
        <a href={SOC2_PDF_LINK} target="_blank" rel="noopener noreferrer">
          Get the standard from AICPA
        </a>
        {isLoading ? (
          <ListItemPlaceholder />
        ) : (
          <a href={fileUrl} target="_blank" rel="noopener noreferrer">
            Download the PDF here
          </a>
        )}
      </SideBar>
    </SideContainer>
  )
}

export const ISOSideBar = (): JSX.Element => {
  return (
    <SideContainer>
      <SideBarHeader />
      <SideBar title="ISO/IEC 27001 Standard">
        <a href={ISO_LINK} target="_blank" rel="noopener noreferrer">
          License the standard from ISO/IEC
        </a>
      </SideBar>
    </SideContainer>
  )
}

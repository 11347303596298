import { CompositeDecorator } from 'draft-js'
import { findLinkEntities } from './Link/findLinkEntities'
import { LinkEntity } from './Link/LinkEntity'

export const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: LinkEntity,
  },
])

import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import { LINKER_MODEL_IDS } from 'src/lib/Linkers/Linkers.constants'
import { LinkerError } from 'src/lib/Linkers/Linkers.components'
import { TextButton } from '../../Reusable/Buttons/TextButton'
import { TypeRow, TypeSelectorContainer } from '../FileType/TypeSelector.styles'

import { DocumentFormData, DocumentType } from './AddDocumentForm'
import {
  PolicyDocFileTypeButton,
  PolicyDocGoogleDriveButton,
  PolicyDocLinkTypeButton,
} from './AddDocumentForm.components'

type DocumentTypeSelectorProps = {
  setFormData: Dispatch<SetStateAction<DocumentFormData>>
  type: DocumentType
  setType: Dispatch<SetStateAction<DocumentType>>
  hide: () => void
  policyId: string
}

export function DocumentTypeSelector({
  setFormData,
  type,
  setType,
  hide,
  policyId,
}: DocumentTypeSelectorProps): JSX.Element {
  const [gdriveError, setGdriveError] = useState<string>('')
  const isGoogleDriveEnabled = IsGatekeeperEnabled(
    GatekeeperFlag.GOOGLE_DRIVE_AUTH,
  )

  const clearText = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      setFormData((state) => {
        const newState: DocumentFormData = {
          ...state,
          mime: '',
          body: '',
        }
        return newState
      })
      if (type !== DocumentType.LINK) setType(DocumentType.NULL)
    },
    [setFormData, type, setType],
  )

  if (type !== DocumentType.NULL) {
    return <TextButton onClick={clearText}>Clear</TextButton>
  }

  return (
    <>
      <TypeSelectorContainer>
        <span>You can use any of these as Evidence Details.</span>
        <TypeRow>
          <PolicyDocLinkTypeButton
            setFormData={setFormData}
            setType={setType}
          />
          <PolicyDocFileTypeButton
            setFormData={setFormData}
            setType={setType}
          />
          {isGoogleDriveEnabled && (
            <PolicyDocGoogleDriveButton
              policyId={policyId}
              hide={hide}
              setGdriveError={setGdriveError}
            />
          )}
        </TypeRow>
      </TypeSelectorContainer>
      {gdriveError && (
        <LinkerError
          errMsg={gdriveError}
          linkerId={LINKER_MODEL_IDS.GOOGLE_DRIVE}
        />
      )}
    </>
  )
}

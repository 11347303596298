import React, { useMemo, useRef } from 'react'
import log from 'loglevel'
import { ReceptorPromiseClient } from '@trustero/trustero-api-web/lib/agent/receptor_grpc_web_pb'
import {
  ReceptorID,
  ReceptorRecord,
} from '@trustero/trustero-api-web/lib/agent/receptor_pb'
import { BaseProps, OnUnpackedResponse } from '../../types'
import { useAuthorizedGrpcClient } from '../../../../adapter'
import { useGetFromCache, useUpdateCache } from '../../useGrpcMutate'
import { ReceptorListComponent } from './ReceptorListComponent'

type Props<ExternalProps> = {
  id: string
} & BaseProps<
  ExternalProps,
  OnUnpackedResponse<
    ExternalProps,
    ReceptorRecord,
    { receptor: ReceptorRecord }
  >
>

export const ReceptorComponent = <ExternalProps,>(
  receptorComponentProps: Props<ExternalProps>,
): JSX.Element => {
  const receptorClient = useAuthorizedGrpcClient(ReceptorPromiseClient)
  const getReceptorsAsyncCall = ReceptorPromiseClient.prototype.getReceptors
  const receptorID = useRef<ReceptorID>(new ReceptorID())
  const getReceptorRequest = useMemo(
    () => new ReceptorID().setOid(receptorComponentProps.id),
    [receptorComponentProps.id],
  )
  const updateCache = useUpdateCache()
  const getFromCache = useGetFromCache()
  const mutateLocalCache = useMemo(
    () => async (): Promise<ReceptorRecord | undefined> => {
      const cachedResponse = await getFromCache(
        getReceptorsAsyncCall,
        receptorID.current,
      )
      if (!cachedResponse) {
        // if the cache is empty, exit early.
        // Cache misses will signal the caller its time to reload the whole
        // control list.
        return undefined
      }
      const receptorsList = cachedResponse.getReceptorsList()
      const idx = receptorsList.findIndex(
        (r) => r.getId() === receptorComponentProps.id,
      )
      if (idx < 0) {
        // if the item to reload is no longer in the cache, we are in
        // an inconsistent state, and reload the cache again
        log.debug(
          'inconsistent cache state: the application asked for a receptor that was not in the cache',
          receptorComponentProps.id,
        )
        return undefined
      }
      receptorsList[idx] = await receptorClient.getReceptor(getReceptorRequest)
      cachedResponse.setReceptorsList(receptorsList)
      await updateCache(getReceptorsAsyncCall, receptorID.current, cachedResponse)
      return receptorsList[idx]
    },
    [
      getFromCache,
      getReceptorsAsyncCall,
      receptorID,
      receptorClient,
      getReceptorRequest,
      updateCache,
      receptorComponentProps.id,
    ],
  )

  return (
    <ReceptorListComponent
      child={({ response, mutate }) => {
        const receptor = response
          .getReceptorsList()
          .find((receptor) => receptor.getId() === receptorComponentProps.id)
        if (!receptor) {
          return (
            receptorComponentProps.onError?.({
              props: receptorComponentProps.props,
              error: new Error(
                `receptor id ${receptorComponentProps.id} not found`,
              ),
            }) ?? <></>
          )
        }
        return receptorComponentProps.child({
          props: receptorComponentProps.props,
          receptor,
          mutate: async () => {
            let receptor: ReceptorRecord | undefined
            try {
              receptor = await mutateLocalCache()
              if (receptor) {
                return receptor
              }
            } catch (e) {
              log.error('Error in ReceptorComponent', e)
            }
            // if the receptor is not found in the local cache, revalidate the
            // getReceptors call and attempt to extract the receptor from the
            // response. This will automatically update the cache with the
            // latest receptor list
            const receptors = await mutate()
            return receptors
              ?.getReceptorsList()
              .find((r) => r.getId() === receptorComponentProps.id)
          },
        })
      }}
    />
  )
}

import { faBan, faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown } from 'react-bootstrap'
import {
  filterBarHeight,
  headerHeight,
} from 'src/components/PageLayout/dimensionUtils'
import { CountChip } from 'src/components/Reusable/Chips/BasicChip'
import {
  Grid,
  GridColumn,
  GridHeader,
  GridRow,
} from 'src/components/Reusable/Grid'
import { TextButton } from 'src/components/Reusable/Buttons/TextButton'
import { ChevronSVG } from 'src/components/Icons/Basic'
import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'
import { space } from 'styled-system'
import { ReactComponent as DeleteIcon } from '../../components/Icons/assets/delete-icon.svg'

export const StyledServicesGrid = styled(Grid).attrs({
  gridTemplateColumns: 'repeat(3, 1fr) max-content',
})``

export const StyledServicesGridHeader = styled(GridHeader).attrs({
  top: headerHeight + filterBarHeight,
})`
  position: sticky;
  z-index: 1;
`

export const ServiceNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export const ServiceReceptorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export const ServiceRolesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export const ServiceRolesDropdownToggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: ${palette.neutral[400]};
`

export const ServiceRolesDropdownText = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StyledCountChip = styled(CountChip).attrs({
  ml: 'xxs',
  bg: palette.green[500],
})`
  color: ${palette.neutral.white};
`

export const AddServiceFooterTextStyle = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

export const AddServiceRolesBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: visible;
  &.scroll {
    max-height: 400px;
    overflow-y: auto;
  }
`

export const AddServiceRolesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 400px;
`

export const StyledServiceRolesDropdownMenu = styled(Dropdown.Menu)`
  &.dropdown-menu.show {
    max-height: 300px;
    width: 100%;
    max-width: 100%;
  }
`

export const AddServiceRolesOptionsContainer = styled.div`
  padding: 8px 16px;
`

export const AddServiceRolesOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`

export const AddServiceRolesHeader = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  gap: 5px;
`

export const AddMoreServiceRolesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export const AddMoreServiceRolesIcon = styled(FontAwesomeIcon).attrs({
  icon: faCirclePlus,
})`
  path {
    fill: ${palette.neutral[400]};
  }
  height: 16px;
  width: 16px;
`

export const ServiceRolesDropdownContainer = styled(Dropdown)`
  width: 100%;
`

export const ScopingContent = styled.article.attrs({
  p: 'l',
})`
  ${space}
`

export const AddServicesGridRow = styled(GridRow)`
  cursor: pointer;
`

export const ServiceTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export const ServicesSectionHeader = styled.h6`
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
`

export const ServicesSectionContainer = styled.div`
  padding-bottom: 12px;
  width: 100%;
`

export const ServicesDescriptionContainer = styled.div`
  display: flex;
  padding: 0px 20px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
`

export const ReceptorListContainer = styled.div`
  margin-bottom: 12px;
`

export const ReceptorInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 13px;
  margin-bottom: 8px;
`

export const ReceptorNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const InstancesGridHeader = styled(GridHeader)`
  padding: 0 16px;
`

export const InstancesGridColumn = styled(GridColumn)`
  padding: 8px 16px;
`

export const ServicesExcludeButtonBody = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
`

export const ServicesDeleteButtonBody = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
`

export const ServicesDeleteButton = styled(TextButton)`
  &:hover {
    path {
      fill: ${palette.neutral[800]};
    }
  }
`

export const StyledServicesGridRow = styled(GridRow)<{ $isexcluded?: boolean }>`
  ${({ $isexcluded }) =>
    $isexcluded &&
    `
    .grid-column {
      color: ${palette.neutral[200]};
      border: 1px solid ${palette.neutral[50]};
      &:not(:first-child) {
        border-left-style: none;
      }
      &:not(:last-child) {
        border-right-style: none;
      }
      background-color: transparent;
    }
    &:hover {
      background-color: transparent;
      .grid-column {
        background-color: transparent;
      }
    }
    `}
`

export const ServicesExcludeIcon = styled(FontAwesomeIcon).attrs({
  icon: faBan,
})`
  height: 16px;
  path {
    fill: ${palette.neutral[600]};
  }
`

export const ServicesIncludeIcon = styled(FontAwesomeIcon).attrs({
  icon: faCirclePlus,
})`
  height: 16px;
  path {
    fill: ${palette.neutral[600]};
  }
`

export const ServicesDeleteIcon = styled(DeleteIcon)`
  height: 21px;
  path {
    fill: ${palette.neutral[600]};
  }
`

export const ServicesTabText = styled.div.attrs({
  className: 'services-tab-text',
})`
  display: flex;
`

export const StyledServicesTabGridRow = styled(GridRow)<{
  $isDismissed?: boolean
  $possiblyInactive?: boolean
  $isExcluded?: boolean
}>`
  .services-tab-text {
    opacity: ${({ $isDismissed, $possiblyInactive }) =>
      $isDismissed || $possiblyInactive ? 0.5 : 1};
  }
  ${({ $isExcluded }) =>
    $isExcluded &&
    `
    .grid-column {
      color: ${palette.neutral[200]};
      border: 1px solid ${palette.neutral[50]};
      &:not(:first-child) {
        border-left-style: none;
      }
      &:not(:last-child) {
        border-right-style: none;
      }
      background-color: transparent;
    }
    &:hover {
      background-color: transparent;
      .grid-column {
        background-color: transparent;
      }
    }
    `}
`

export const ViewReceptorButton = styled(TextButton)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  text-decoration: none;
  font-weight: 500;
  color: ${palette.neutral[600]};
  &:hover {
    color: ${palette.blue[600]};
    path {
      fill: ${palette.blue[600]};
    }
  }
`

export const ViewReceptorIcon = styled(ChevronSVG)`
  height: 10px;
  path {
    fill: ${palette.neutral[600]};
  }
`

export const CreateCustomServiceModalBody = styled.div`
  padding: 0px 16px;
  overflow: visible;
  &.scroll {
    max-height: 400px;
    overflow-y: auto;
  }
`

export const ViewReceptorsModalContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
`

export const ServicesRightAlignedColumn = styled(GridColumn)`
  display: flex;
  justify-content: flex-end;
`

export const ExcludeServiceModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

import styled from 'styled-components/macro'
import { border, color, compose, padding, typography } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const ReceptorCardContainer = styled.div.attrs({
  p: 'xxl',
  border: '1px solid',
  borderColor: 'border.primary.light',
  borderRadius: '14px',
})`
  ${compose(padding, border)}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  &:hover {
    color: unset;
    background-color: ${themeGet('colors.bg.primary')};
  }
  &:active {
    background-color: ${themeGet('colors.border.neutral.light')};
  }
`

export const ReceptorLogo = styled.div.attrs({
  bg: 'bg.primary',
})`
  ${compose(color)}
  display: flex;
  align-items: center;
  justify-content: center;
  width: 104px;
  height: 104px;
  border-radius: 16px;
`

export const ReceptorName = styled.p.attrs({
  fontSize: 7,
  fontWeight: '600',
})`
  ${typography}
`

export const ReceptorCategory = styled.p.attrs({
  color: 'text.med',
})`
  ${compose(color, typography)}
`

import React, {
  FormEventHandler,
  useCallback,
  useContext,
  useState,
} from 'react'
import {
  EvidenceTestResult,
  TestResult,
  TestStatus,
  TestType,
} from '@trustero/trustero-api-web/lib/evidence/testing_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { useHref } from 'react-router-dom'
import {
  ACTOR_TYPE,
  GetLatestEvidenceRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { TestingPromiseClient } from '@trustero/trustero-api-web/lib/evidence/testing_grpc_web_pb'
import { HashLink } from 'react-router-hash-link'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import log from 'loglevel'
import { useHardTestInvalidation } from 'src/Utils/swrCacheInvalidation/useInvalidateOnTestResultMutation'
import AuthContext from '../../../../context/authContext'
import { ModalForm, ModalFormId, ModalFormIdQueryParam } from '../../ModalForm'
import { DerivedTextArea, DerivedTextInput } from '../../../Reusable/Inputs'
import { RadioInput } from '../../../Reusable/Inputs/Radio'
import { useAuthorizedGrpcClient } from '../../../../adapter'
import { useControl } from '../../../async/model/control/useControl'
import { InfoLinkSection } from '../../../Reusable/Text/InfoLink.styles'

export enum TestFormFields {
  CAPTION = 'caption',
  DESCRIPTION = 'description',
  STATUS = 'status',
}

export type TestFormData = {
  [key in TestFormFields]: string
}

export const AddTestQueryParams = {
  MODAL_FORM_ID: ModalFormIdQueryParam,
  CONTROL_ID: 'controlId',
}

type AddTestFormProps = {
  modelId: string
  timeRange?: TimeRange
}

export const AddTestForm = ({ modelId }: AddTestFormProps): JSX.Element => {
  const { authCtx } = useContext(AuthContext)
  const control = useControl(modelId)
  const attachmentClient = useAuthorizedGrpcClient(AttachmentPromiseClient)
  const testingClient = useAuthorizedGrpcClient(TestingPromiseClient)
  const invalidateTestResults = useHardTestInvalidation()

  const [formData, setFormData] = useState<TestFormData>({
    [TestFormFields.CAPTION]: '',
    [TestFormFields.DESCRIPTION]: '',
    [TestFormFields.STATUS]: '',
  })

  const show = useIsShowModal(ModalFormId.ADD_TEST)
  const hide = useHideModal({
    onHide: () => {
      // Clear the form
      setFormData({
        caption: '',
        description: '',
        status: '',
      })
    },
  })

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()

      try {
        // [AP-5006] TODO: Improve error handling
        const latestEvidenceResponse = await attachmentClient.getLatestEvidence(
          new GetLatestEvidenceRequest().setControlId(
            control.data?.getId() ?? '',
          ),
        )

        // Add test
        const testResultMessage = new TestResult()
          .setName(formData.caption)
          .setDescription(formData.description)
          .setStatus(
            TestStatus[formData.status as 'PASS' | 'FAIL'] || TestStatus.PASS,
          )
          .setType(TestType.MANUAL)
          .setModeltype(MODEL_TYPE.CONTROL)
          .setActor(authCtx.email || '')
          .setActortype(ACTOR_TYPE.USER)
          .setControlId(control.data?.getId() ?? '')
          .setEvidencetestresultsList(
            latestEvidenceResponse
              .getItemsList()
              .map((p) =>
                new EvidenceTestResult()
                  .setDocumentId(p.getEvidence()?.getId() ?? '')
                  .setStatus(
                    TestStatus[formData.status as 'PASS' | 'FAIL'] ||
                      TestStatus.PASS,
                  ),
              ),
          )

        await testingClient.addTest(testResultMessage)
      } catch (e) {
        log.error('Error in AddTestForm', e)
      } finally {
        invalidateTestResults()
        hide()
      }
    },
    [
      attachmentClient,
      authCtx.email,
      control.data,
      formData.caption,
      formData.description,
      formData.status,
      hide,
      invalidateTestResults,
      testingClient,
    ],
  )
  const infoLink = useHref({
    pathname: `../../../help/overview`,
    hash: 'test',
  })
  if (!control.data) {
    return <></>
  }

  return (
    <ModalForm
      show={show}
      hide={hide}
      formId={ModalFormId.ADD_TEST}
      title={`Add Test for Control: ${control.data.getName()}`}
      description={
        <InfoLinkSection>
          If you are reviewing evidence for this control, add a manual test here
          to summarize your findings.{' '}
          <HashLink to={infoLink}>Learn more</HashLink>
        </InfoLinkSection>
      }
    >
      <form id={ModalFormId.ADD_TEST} onSubmit={onSubmit}>
        <DerivedTextInput
          required
          label="Caption"
          name={TestFormFields.CAPTION}
          initVal={formData.caption}
          placeholder="Add Caption for Test"
          form={ModalFormId.ADD_TEST}
          setFormData={
            setFormData as React.Dispatch<
              React.SetStateAction<{ [key: string]: string }>
            >
          }
        />
        <DerivedTextArea
          required
          label="Description"
          name={TestFormFields.DESCRIPTION}
          initVal={formData.description}
          placeholder="Describe Your Test Results"
          form={ModalFormId.ADD_TEST}
          setFormData={
            setFormData as React.Dispatch<
              React.SetStateAction<{ [key: string]: string }>
            >
          }
        />
        <fieldset>
          <p
            style={{
              fontSize: '14px',
              marginBottom: '2px',
            }}
          >
            Result
          </p>
          <RadioInput
            inline
            required
            id="test-form-status-pass"
            label="Pass"
            name={TestFormFields.STATUS}
            value="PASS"
            checked={formData.status === 'PASS'}
            onChange={() => {
              setFormData((state) => {
                const newState = { ...state }
                newState[TestFormFields.STATUS] = 'PASS'
                return newState
              })
            }}
          />
          <RadioInput
            inline
            required
            id="test-form-status-fail"
            label="Fail"
            name={TestFormFields.STATUS}
            value="FAIL"
            checked={formData.status === 'FAIL'}
            onChange={() => {
              setFormData((state) => {
                const newState = { ...state }
                newState[TestFormFields.STATUS] = 'FAIL'
                return newState
              })
            }}
          />
        </fieldset>
      </form>
    </ModalForm>
  )
}

/**
 * @fileoverview gRPC-Web generated client stub for linker
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: linker/linker.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var agent_receptor_pb = require('../agent/receptor_pb.js')

var model_policy_pb = require('../model/policy_pb.js')
const proto = {};
proto.linker = require('./linker_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.linker.LinkerClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.linker.LinkerPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.linker.GetLinkerRequest,
 *   !proto.agent.ReceptorRecord>}
 */
const methodDescriptor_Linker_GetLinker = new grpc.web.MethodDescriptor(
  '/linker.Linker/GetLinker',
  grpc.web.MethodType.UNARY,
  proto.linker.GetLinkerRequest,
  agent_receptor_pb.ReceptorRecord,
  /**
   * @param {!proto.linker.GetLinkerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  agent_receptor_pb.ReceptorRecord.deserializeBinary
);


/**
 * @param {!proto.linker.GetLinkerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReceptorRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReceptorRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.linker.LinkerClient.prototype.getLinker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/linker.Linker/GetLinker',
      request,
      metadata || {},
      methodDescriptor_Linker_GetLinker,
      callback);
};


/**
 * @param {!proto.linker.GetLinkerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReceptorRecord>}
 *     Promise that resolves to the response
 */
proto.linker.LinkerPromiseClient.prototype.getLinker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/linker.Linker/GetLinker',
      request,
      metadata || {},
      methodDescriptor_Linker_GetLinker);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.linker.GetHasLinkerRequest,
 *   !proto.linker.GetHasLinkerResponse>}
 */
const methodDescriptor_Linker_GetHasLinker = new grpc.web.MethodDescriptor(
  '/linker.Linker/GetHasLinker',
  grpc.web.MethodType.UNARY,
  proto.linker.GetHasLinkerRequest,
  proto.linker.GetHasLinkerResponse,
  /**
   * @param {!proto.linker.GetHasLinkerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.linker.GetHasLinkerResponse.deserializeBinary
);


/**
 * @param {!proto.linker.GetHasLinkerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.linker.GetHasLinkerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.linker.GetHasLinkerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.linker.LinkerClient.prototype.getHasLinker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/linker.Linker/GetHasLinker',
      request,
      metadata || {},
      methodDescriptor_Linker_GetHasLinker,
      callback);
};


/**
 * @param {!proto.linker.GetHasLinkerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.linker.GetHasLinkerResponse>}
 *     Promise that resolves to the response
 */
proto.linker.LinkerPromiseClient.prototype.getHasLinker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/linker.Linker/GetHasLinker',
      request,
      metadata || {},
      methodDescriptor_Linker_GetHasLinker);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.linker.ConnectLinkerRequest,
 *   !proto.agent.ReceptorRecord>}
 */
const methodDescriptor_Linker_ConnectLinker = new grpc.web.MethodDescriptor(
  '/linker.Linker/ConnectLinker',
  grpc.web.MethodType.UNARY,
  proto.linker.ConnectLinkerRequest,
  agent_receptor_pb.ReceptorRecord,
  /**
   * @param {!proto.linker.ConnectLinkerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  agent_receptor_pb.ReceptorRecord.deserializeBinary
);


/**
 * @param {!proto.linker.ConnectLinkerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReceptorRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReceptorRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.linker.LinkerClient.prototype.connectLinker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/linker.Linker/ConnectLinker',
      request,
      metadata || {},
      methodDescriptor_Linker_ConnectLinker,
      callback);
};


/**
 * @param {!proto.linker.ConnectLinkerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReceptorRecord>}
 *     Promise that resolves to the response
 */
proto.linker.LinkerPromiseClient.prototype.connectLinker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/linker.Linker/ConnectLinker',
      request,
      metadata || {},
      methodDescriptor_Linker_ConnectLinker);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.linker.DisconnectLinkerRequest,
 *   !proto.agent.ReceptorRecord>}
 */
const methodDescriptor_Linker_DisconnectLinker = new grpc.web.MethodDescriptor(
  '/linker.Linker/DisconnectLinker',
  grpc.web.MethodType.UNARY,
  proto.linker.DisconnectLinkerRequest,
  agent_receptor_pb.ReceptorRecord,
  /**
   * @param {!proto.linker.DisconnectLinkerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  agent_receptor_pb.ReceptorRecord.deserializeBinary
);


/**
 * @param {!proto.linker.DisconnectLinkerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.agent.ReceptorRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.agent.ReceptorRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.linker.LinkerClient.prototype.disconnectLinker =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/linker.Linker/DisconnectLinker',
      request,
      metadata || {},
      methodDescriptor_Linker_DisconnectLinker,
      callback);
};


/**
 * @param {!proto.linker.DisconnectLinkerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.agent.ReceptorRecord>}
 *     Promise that resolves to the response
 */
proto.linker.LinkerPromiseClient.prototype.disconnectLinker =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/linker.Linker/DisconnectLinker',
      request,
      metadata || {},
      methodDescriptor_Linker_DisconnectLinker);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.linker.GetGoogleDrivePoliciesRequest,
 *   !proto.linker.GetGoogleDrivePoliciesResponse>}
 */
const methodDescriptor_Linker_GetGoogleDrivePolicies = new grpc.web.MethodDescriptor(
  '/linker.Linker/GetGoogleDrivePolicies',
  grpc.web.MethodType.UNARY,
  proto.linker.GetGoogleDrivePoliciesRequest,
  proto.linker.GetGoogleDrivePoliciesResponse,
  /**
   * @param {!proto.linker.GetGoogleDrivePoliciesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.linker.GetGoogleDrivePoliciesResponse.deserializeBinary
);


/**
 * @param {!proto.linker.GetGoogleDrivePoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.linker.GetGoogleDrivePoliciesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.linker.GetGoogleDrivePoliciesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.linker.LinkerClient.prototype.getGoogleDrivePolicies =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/linker.Linker/GetGoogleDrivePolicies',
      request,
      metadata || {},
      methodDescriptor_Linker_GetGoogleDrivePolicies,
      callback);
};


/**
 * @param {!proto.linker.GetGoogleDrivePoliciesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.linker.GetGoogleDrivePoliciesResponse>}
 *     Promise that resolves to the response
 */
proto.linker.LinkerPromiseClient.prototype.getGoogleDrivePolicies =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/linker.Linker/GetGoogleDrivePolicies',
      request,
      metadata || {},
      methodDescriptor_Linker_GetGoogleDrivePolicies);
};


module.exports = proto.linker;


import React, { FormEventHandler, useEffect, useState } from 'react'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { ModalForm, ModalFormId } from '../../../../../components/ModalForms'
import { MultiSelectForm } from '../../../../../components/Reusable/Forms/MultiSelectForm'
import { SelectControlsRowItem } from '../../../../../components/Reusable/Forms/MultiSelectFromComponents/SelectControlsRowItem'
import { NoRecommendedTemplates } from '../../../../../components/ModalForms/Frameworks/AddFramework/NoRecommendedTemplates'
import { useListControls } from '../BringIntoAudit/useListControls'
import { useAddOrUpdateDocumentRequest } from '../../../../../components/async/DocumentRequest/useDocumentRequests'

interface AddControlsToRequestProps {
  existingControlIds: string[]
  requestId: string
}

export const AddControlsToRequestModal = ({
  existingControlIds,
  requestId,
}: AddControlsToRequestProps): JSX.Element => {
  const [selectedControls, setSelectedControls] = useState<string[]>([])
  const [availableControls, setAvailableControls] = useState<Control[]>([])
  const updateRequest = useAddOrUpdateDocumentRequest()

  const response = useListControls({
    excludeFromAudit: false,
    isDismissed: false,
    complianceFrameworkIds: [],
    excludeNotApplicable: true,
  })

  useEffect(() => {
    const allControlsForAudit = response.data?.getItemsList() || []
    const controlsList = allControlsForAudit.filter(
      (elem: Control) => !existingControlIds.includes(elem.getId()),
    )
    setAvailableControls(controlsList)
  }, [response.loading, response.data, existingControlIds])

  const onHide = useHideModal({})

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault()

    await updateRequest({
      id: requestId,
      controlIds: [...existingControlIds, ...selectedControls],
    })
    onHide()
  }

  const multiSelectFormProps = {
    formId: ModalFormId.ADD_CONTROLS_TO_REQUEST,
    title:
      'Associate this request with controls. Ultimately, requested evidence has to be tested for each control. Evidence will be synched to mapped controls.',
    loading: response.loading,
    gridTemplateColumnStyling:
      'min-content minmax(min-content, auto) minmax(min-content, auto) minmax(min-content, 1fr)',
    gridItems: availableControls.length ? (
      <>
        {availableControls.map((control) => (
          <SelectControlsRowItem
            key={control.getId()}
            control={control}
            setSelectedControlIds={setSelectedControls}
            selectedControlIds={selectedControls}
          />
        ))}
      </>
    ) : (
      <NoRecommendedTemplates templateType={'controls'} />
    ),
    onSubmit,
  }

  return (
    <ModalForm
      show={useIsShowModal(ModalFormId.ADD_CONTROLS_TO_REQUEST)}
      hide={onHide}
      formId={ModalFormId.ADD_CONTROLS_TO_REQUEST}
      size="xl"
      title="Link to Controls"
      submitText="Link Controls"
    >
      <MultiSelectForm {...multiSelectFormProps} />
    </ModalForm>
  )
}

import React, { useContext, useMemo, useState } from 'react'
import { PROVIDER_ROLE } from '@trustero/trustero-api-web/lib/provider/provider_pb'

type ScopingContextType = {
  currentProviderRole: PROVIDER_ROLE
  setCurrentProviderRole: React.Dispatch<React.SetStateAction<PROVIDER_ROLE>>
}

const ScopingContext = React.createContext<ScopingContextType>({
  currentProviderRole: PROVIDER_ROLE.CLOUD_HOSTING, // default value
  setCurrentProviderRole: () => null,
})

export const useScopingContext = (): ScopingContextType =>
  useContext(ScopingContext)

export const ScopingContextProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [currentProviderRole, setCurrentProviderRole] = useState<PROVIDER_ROLE>(
    PROVIDER_ROLE.CLOUD_HOSTING,
  )

  const providerData: ScopingContextType = useMemo(
    () => ({
      currentProviderRole,
      setCurrentProviderRole,
    }),
    [currentProviderRole, setCurrentProviderRole],
  )
  return (
    <ScopingContext.Provider value={providerData}>
      {children}
    </ScopingContext.Provider>
  )
}

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components/macro'
import { useHideModal, useIsShowModal } from 'src/Modal/ModalStateContext'
import { ModalForm, ModalFormId } from '../..'
import palette from '../../../../designSystem/variables/palette'
import { ModalButtonContainer } from '../../ModalForm.styles'
import { AddDocumentRequestModal } from '../AddRequest/AddDocumentRequestModal'
import { ReactComponent as AddIcon } from '../../../../components/Icons/assets/add-icon.svg'
import { AddRequestBulkUpload } from '../AddRequestBulkUpload/AddRequestBulkUpload'
import { LargeOpenModalButton } from '../../ModalButtons'

const SpreadsheetIcon = styled(FontAwesomeIcon)`
  color: ${palette.neutral[600]};
  height: 41px;
`

export const ChooseRequestUploadType = (): JSX.Element => {
  const show = useIsShowModal(ModalFormId.CHOOSE_REQUEST_UPLOAD_TYPE)
  const hide = useHideModal({ modalId: ModalFormId.CHOOSE_REQUEST_UPLOAD_TYPE })

  return (
    <div>
      <AddDocumentRequestModal />
      <AddRequestBulkUpload />
      <ModalForm
        show={show}
        hide={hide}
        formId={ModalFormId.CHOOSE_REQUEST_UPLOAD_TYPE}
        size="xl"
        title="Add Document Request"
        description={
          'Document requests come from auditors or others trying to help you gather your evidence.'
        }
        hideButtons={true}
      >
        <form id={ModalFormId.CHOOSE_REQUEST_UPLOAD_TYPE}>
          <ModalButtonContainer>
            <LargeOpenModalButton modalId={ModalFormId.ADD_REQUEST_BULK_UPLOAD}>
              <SpreadsheetIcon icon={faFileCsv} />
              <p>Upload a spreadsheet</p>
            </LargeOpenModalButton>
            <LargeOpenModalButton modalId={ModalFormId.ADD_DOCUMENT_REQUEST}>
              <AddIcon />
              <p>Add Manually</p>
            </LargeOpenModalButton>
          </ModalButtonContainer>
        </form>
      </ModalForm>
    </div>
  )
}

import React from 'react'
import styled from 'styled-components/macro'
import { color } from 'styled-system'
import { SubpageNav } from '../../components/PageLayout/PageLayout.styles'
import { useHasReceptorError } from '../../components/async/model/receptor/useReceptors'
import SubpageNavItem from '../../components/PageLayout/SubpageNavItem'
import { ServiceSubRoutes } from './ServicesRootPage'

const ErrorIcon = styled.div.attrs({
  bg: 'fill.status.error.dark',
})`
  ${color}
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  bottom: 20px;
  left: 105px;
  border-radius: 50%;
`

export function ServicesSubNav(): JSX.Element {
  const hasErrorFunc = useHasReceptorError()
  const items = [
    {
      route: `${ServiceSubRoutes.RECEPTORS}`,
      title: 'Receptors',
      error: hasErrorFunc(),
      tooltipBody:
        'Receptors collect configuration information from a SaaS vendor you’re using for service delivery. The information is translated into evidence supporting business controls. For example, AWS IAM users and their MFA settings are collected by the AWS receptor to support the‘Multi-factor Authentication for Remote Systems’ business control.',
    },
    {
      route: `${ServiceSubRoutes.SERVICES}`,
      title: 'Services',
      tooltipBody:
        'Services lists SaaS your business uses to provide service delivery or as a supporting tool. The SaaS listed here is in addition to the services included in the receptors scope section. Information provided here is used to describe the supporting services section of a compliance report.',
    },
  ]

  return (
    <SubpageNav>
      {items.map((item, idx) => {
        return (
          <SubpageNavItem
            key={idx}
            end={item.route === ServiceSubRoutes.SERVICES}
            to={item.route}
          >
            {item.title}
            {item.error && <ErrorIcon />}
          </SubpageNavItem>
        )
      })}
    </SubpageNav>
  )
}

import React from 'react'
import { useHasRemoveControlTests } from 'src/context/Gatekeeper'
import {
  ChangelogContainer,
  ChangelogHeader,
  ChangelogHeaderText,
  Logs,
} from './Changelog.styles'
import { ChangelogPage } from './ChangelogPage'
import { CHANGELOG_PAGE_SIZE } from './ChangelogPage.helpers'

export const Changelog = ({
  subjectmodelid,
  hideHeader,
  subjectId,
  isDashboardWidget,
}: {
  subjectmodelid?: string[]
  hideHeader?: boolean
  subjectId?: string
  isDashboardWidget?: boolean
}): JSX.Element => {
  const showNewActivitiesWidget = useHasRemoveControlTests()
  const subjectModelId = subjectmodelid && subjectmodelid[0]
  const isWide = isDashboardWidget && showNewActivitiesWidget

  return (
    <ChangelogContainer $isWide={isWide}>
      {!hideHeader && (
        <ChangelogHeader>
          <ChangelogHeaderText>Activities</ChangelogHeaderText>
        </ChangelogHeader>
      )}
      <Logs>
        <ChangelogPage
          itemsPerPage={CHANGELOG_PAGE_SIZE}
          subjectModelId={subjectModelId}
          subjectId={subjectId}
          isWide={isWide}
        />
      </Logs>
    </ChangelogContainer>
  )
}

import React, { useEffect, useState } from 'react'
import { useDRLRequest } from 'src/pages/Requests/requests.hooks'
import { useDocumentRequests } from 'src/components/async/DocumentRequest'
import { GridColumnSortRequestsProps } from './GridColumnSort.constants'
import { getColSortIcon, getNextColSortType } from './GridColumnSort.utils'
import { GridColumnSort } from '.'

export const RequestsGridColumnSort = ({
  type,
  isActive,
  colName,
  onSort,
}: GridColumnSortRequestsProps): JSX.Element | null => {
  const drlRequest = useDRLRequest()
  const { loading } = useDocumentRequests(drlRequest)
  const [showSpinner, setShowSpinner] = useState<boolean>(loading && isActive)
  const currIcon = getColSortIcon(type, isActive)

  useEffect(() => {
    showSpinner && isActive && setShowSpinner(loading)
  }, [showSpinner, isActive, loading])

  const applySorting = (e: React.MouseEvent) => {
    e.preventDefault()
    if (loading) {
      return
    }
    setShowSpinner(true)
    onSort({ colName, type: getNextColSortType(type) })
  }

  return (
    <GridColumnSort {...{ colName, showSpinner, currIcon, applySorting }} />
  )
}

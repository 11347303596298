import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  GetChangeLogEntriesRequest,
  LogEntries,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { StringValue } from 'google-protobuf/google/protobuf/wrappers_pb'
import {
  GrpcInfiniteResponse,
  NewGrpcInfiniteResponse,
} from '../../components/async/hooks/types'
import { useSwrInfiniteGrpc } from '../../components/async/useSwrInfiniteGrpc'

export const useChangeLogEntries = (
  itemsPerPage: number,
  pageNumber: number,
  subjectModelId?: string,
  subjectId?: string,
): GrpcInfiniteResponse<LogEntries> => {
  const requestBuilder = () => {
    const request = new GetChangeLogEntriesRequest()
      .setEntriesPerPage(itemsPerPage)
      .setPageNumber(pageNumber)
    if (subjectModelId) {
      request.setSubjectModelId(new StringValue().setValue(subjectModelId))
    }
    if (subjectId) {
      request.setSubjectId(new StringValue().setValue(subjectId))
    }
    return request
  }
  const { response } = useSwrInfiniteGrpc(
    AttachmentPromiseClient.prototype.getChangeLogEntries,
    requestBuilder,
    pageNumber,
  )
  return NewGrpcInfiniteResponse(response)
}

import React from 'react'
import { SortNoneSvg, SortAscSvg, SortDescSvg } from '../../../Icons/Basic'
import {
  GridColumnSortType,
  GridColumnSortControls,
  GRID_COL_SORT_CONFIGS_CONTROLS,
  GRID_COL_SORT_CONFIGS_POLICIES,
  GridColumnSortConfigControls,
  GridColumnSortConfigPolicies,
  GridColumnSortPolicies,
  GridColumnSortConfigRequests,
  GridColumnSortRequests,
  GRID_COL_SORT_CONFIGS_REQUESTS,
  GridColumnSortVendors,
  GridColumnSortConfigVendors,
} from '../GridColumnSort/GridColumnSort.constants'

export const getColSortIcon = (
  sortType: GridColumnSortType,
  isActive?: boolean,
): JSX.Element => {
  if (!isActive) {
    return <SortNoneSvg />
  }
  switch (sortType) {
    case GridColumnSortType.DESCENDING:
      return <SortDescSvg />
    case GridColumnSortType.ASCENDING:
      return <SortAscSvg />
    default:
      return <SortNoneSvg />
  }
}

/**
 * If no sort direction is set, use descending to start. Every subsequent toggle should just
 * alternate between desc/asc post the first click
 *
 * @param currentType: The active grid column's sort direction
 * @returns
 */
export const getNextColSortType = (
  currentType: GridColumnSortType,
): GridColumnSortType => {
  switch (currentType) {
    case GridColumnSortType.DESCENDING:
      return GridColumnSortType.ASCENDING
    case GridColumnSortType.ASCENDING:
      return GridColumnSortType.DESCENDING
    default:
      return GridColumnSortType.DESCENDING
  }
}

/**
 * Resets sort headers to base state & update current sorting type (assume all others are none)
 *
 * @param state: initial state from GRID_COL_SORT_CONFIGS
 * @param activeCol current column dispatching event
 * @returns copy of columnMapState with new active column and sort direction
 */
export const gridColSortReducerControls = (
  state: GridColumnSortConfigControls,
  activeCol: {
    colName: GridColumnSortControls
    type: GridColumnSortType
  },
): GridColumnSortConfigControls => {
  return {
    ...state,
    ...GRID_COL_SORT_CONFIGS_CONTROLS(),
    [`${activeCol.colName}`]: {
      type: activeCol.type,
      isActive: true,
    },
  }
}

export const gridColSortReducerPolicies = (
  state: GridColumnSortConfigPolicies,
  activeCol: {
    colName: GridColumnSortPolicies
    type: GridColumnSortType
  },
): GridColumnSortConfigPolicies => {
  return {
    ...state,
    ...GRID_COL_SORT_CONFIGS_POLICIES(),
    [`${activeCol.colName}`]: {
      type: activeCol.type,
      isActive: true,
    },
  }
}

export const gridColSortReducerRequests = (
  state: GridColumnSortConfigRequests,
  activeCol: {
    colName: GridColumnSortRequests
    type: GridColumnSortType
  },
): GridColumnSortConfigRequests => {
  return {
    ...state,
    ...GRID_COL_SORT_CONFIGS_REQUESTS(),
    [`${activeCol.colName}`]: {
      type: activeCol.type,
      isActive: true,
    },
  }
}

export const gridColSortReducerVendors = (
  state: GridColumnSortConfigVendors,
  activeCol: {
    colName: GridColumnSortVendors
    type: GridColumnSortType
  },
): GridColumnSortConfigVendors => {
  return {
    ...state,
    ...GRID_COL_SORT_CONFIGS_REQUESTS(),
    [`${activeCol.colName}`]: {
      type: activeCol.type,
      isActive: true,
    },
  }
}

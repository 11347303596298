import React from 'react'
import { Tooltip } from '../../../../components/Reusable/Tooltip'
import { Gravatar } from '../../../../components/Gravatar'
import formatDate from '../../../../Utils/formatDate'
import {
  GridHeaderText,
  StatusIndicator,
  TestColumn,
  TestFixitIcon,
} from './TestGrid.styles'

type TestResultRowContentProps = {
  id: string
  name: string | JSX.Element
  gridRow?: number
  didPass: boolean
  hideDidPass?: boolean
  actorEmail: string
  actorName?: string
  isAutomated: boolean
  evidenceTestResultCount: number
  createdAt: number
  leftMargin?: string
  permalink?: string
  children?: JSX.Element | JSX.Element[] | null | false
}

export const TestResultRowContent = (
  props: TestResultRowContentProps,
): JSX.Element => {
  const permalink = props.permalink || `test/${props.id}`

  return (
    <>
      <TestColumn
        to={permalink}
        gridRow={props.gridRow}
        {...(props.leftMargin ? { ml: props.leftMargin } : {})}
        px="0"
        pl="22px"
        pr="m"
      >
        <StatusIndicator isPassing={props.didPass} />
        <GridHeaderText>
          {props.isAutomated ? 'Automated' : 'Manual'}
        </GridHeaderText>
      </TestColumn>
      <TestColumn to={permalink} gridRow={props.gridRow}>
        {props.name}
      </TestColumn>
      <TestColumn
        to={permalink}
        gridRow={props.gridRow}
        justifyContent="center"
      >
        {props.evidenceTestResultCount}
      </TestColumn>
      <Tooltip
        id={`${props.id}-actor`}
        placement="top-end"
        tooltipBody={
          props.isAutomated ? 'Trustero' : props.actorName ?? props.actorEmail
        }
      >
        <TestColumn to={permalink} gridRow={props.gridRow}>
          <Gravatar email={props.actorEmail} emptyIsUnassigned={false} />
        </TestColumn>
      </Tooltip>
      {!props.hideDidPass && (
        <TestColumn to={permalink} gridRow={props.gridRow}>
          {props.didPass ? 'Pass' : 'Fail'}
        </TestColumn>
      )}
      <TestColumn to={permalink} gridRow={props.gridRow}>
        <TestFixitIcon {...props} />
      </TestColumn>
      <TestColumn to={permalink} gridRow={props.gridRow}>
        {formatDate(props.createdAt)}
      </TestColumn>
      {props.children}
    </>
  )
}

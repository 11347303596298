import React from 'react'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import {
  ActionsRowContainer,
  EvidenceBulkDeleteButton,
  EvidenceBulkDeleteIcon,
} from './evidence.styles'
import { useBulkDeleteEvidenceModal } from './evidence.hooks'

export const ExpandedEvidenceActionsRow = ({
  controlId,
  requestId,
  timeRange,
  caption,
  totalEvidenceCount,
}: {
  controlId: string
  requestId: string
  timeRange: TimeRange | undefined
  caption: string
  totalEvidenceCount: number
}): JSX.Element => {
  const bulkDeleteEvidence = useBulkDeleteEvidenceModal({
    controlId,
    requestId,
    timeRange,
    caption,
    totalEvidenceCount,
  })

  return (
    <ActionsRowContainer>
      This stack of evidence samples the same data over time.
      <EvidenceBulkDeleteButton onClick={bulkDeleteEvidence}>
        <>
          <EvidenceBulkDeleteIcon />
          Delete All Evidence in This Stack
        </>
      </EvidenceBulkDeleteButton>
    </ActionsRowContainer>
  )
}

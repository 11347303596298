import React, { useMemo } from 'react'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import styled from 'styled-components/macro'
import { space } from 'styled-system'
import { useContent } from '../../../../../context/hooks'
import { Service } from '../../../../../xgenerated'
import { ListInstanceIds } from '../../../../../components/async/model/service_instance/ListInstanceIds'
import {
  GridColumn,
  GridHeader,
  GridRow,
} from '../../../../../components/Reusable/Grid'
import {
  InstanceScopeCheckbox,
  ModelComponent,
  ServiceInstance,
} from '../../../../../components/async/model'
import {
  Tooltip,
  TooltipOverlayType,
  TooltipPositions,
} from '../../../../../components/Reusable/Tooltip/Tooltip'
import { InfoButton } from '../../../../../components/PageLayout/InfoButton'

const SubServiceInstanceListContainer = styled.ul.attrs({
  m: 0,
  p: 0,
  pl: 'l',
})`
  ${space}
  grid-column: 5 / -1;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-auto-rows: min-content;
  position: relative;
  max-height: 400px;
  overflow-y: auto;
`

const StyledGridRow = styled(GridRow)`
  .grid-header {
    position: sticky;
    top: 0;
    z-index: 1;
  }
`

export function SubServiceInstanceList({
  receptorId,
  selectedServiceId,
}: {
  receptorId: string
  selectedServiceId: string
}): JSX.Element {
  const { idIndex } = useContent()
  const logo = useMemo(() => {
    const serviceTemplate = idIndex[selectedServiceId] as Service
    return (
      <>
        <serviceTemplate.logo width="32px" height="32px" />
        <span style={{ marginLeft: '8px' }}>{serviceTemplate.name}</span>
      </>
    )
  }, [idIndex, selectedServiceId])

  return (
    <SubServiceInstanceListContainer>
      <ListInstanceIds
        receptorId={receptorId}
        modelIds={[selectedServiceId]}
        child={({ ids }) => {
          // Render Fragment if SubService has no instances
          if (!ids.length) return <></>

          return (
            <ModelComponent
              modelId={selectedServiceId}
              modelType={MODEL_TYPE.SERVICE}
              child={({ model }) => {
                // Render Fragment if Parent Service is dismissed
                if (model.getDismissed()) return <></>
                return (
                  <>
                    <StyledGridRow>
                      <GridHeader>{logo}</GridHeader>
                      <GridHeader>
                        Included
                        <Tooltip
                          id="receptor-instance-included-tooltip"
                          placement={TooltipPositions.bottom}
                          overlayType={TooltipOverlayType.popover}
                          tooltipBody="Indicate if the sub service or sub service instance should be included as part of evidence generation and control tests."
                        >
                          <InfoButton as="div" ml="xxxs" />
                        </Tooltip>
                      </GridHeader>
                    </StyledGridRow>
                    {ids.map((id) => {
                      return (
                        <GridRow key={id}>
                          <GridColumn bg="white">
                            <ServiceInstance
                              id={id}
                              child={({ instance }) => (
                                <>{instance.getName()}</>
                              )}
                            />
                          </GridColumn>
                          <GridColumn justifyContent="center" bg="white">
                            <InstanceScopeCheckbox id={id} />
                          </GridColumn>
                        </GridRow>
                      )
                    })}
                  </>
                )
              }}
            />
          )
        }}
      />
    </SubServiceInstanceListContainer>
  )
}

import React from 'react'
import AccordionCollapse from 'react-bootstrap/AccordionCollapse'
import { Grid } from '../Grid'
import { ExpandableGridContainer } from './accordion.styles'

export type ExpandableElementGridProps = {
  gridRow?: number
  gridTemplateColumns?: string
  children: React.ReactNode | React.ReactNodeArray
  actionsRow?: JSX.Element
}

export function ExpandableGrid(props: ExpandableElementGridProps): JSX.Element {
  return (
    <AccordionCollapse
      eventKey="0"
      style={{
        gridColumn: '1 / -1',
        gridRow: props.gridRow ? props.gridRow + 1 : undefined,
        maxHeight: '500px',
        overflow: 'overlay',
      }}
    >
      <ExpandableGridContainer>
        {props.actionsRow}
        <Grid gridTemplateColumns={props.gridTemplateColumns || ''}>
          {props.children}
        </Grid>
      </ExpandableGridContainer>
    </AccordionCollapse>
  )
}

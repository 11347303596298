import styled from 'styled-components/macro'
import {
  color,
  compose,
  layout,
  space,
  textStyle,
  variant,
} from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

export const ReceptorFormInput = styled.input.attrs({
  width: '100%',
  px: 's',
  bg: 'white',
  color: 'text.input.default',
  textStyle: 'body.default',
})<{ status: 'default' | 'error' }>`
  height: 40px;
  border: 1px solid;

  ::placeholder {
    color: ${themeGet('colors.text.input.placeholder')};
    font-style: italic;
  }

  :hover {
    background-color: ${themeGet('colors.bg.neutralHover')};
    border-color: ${themeGet('colors.border.neutral.base')};
  }

  :active,
  :focus {
    background-color: white;
    border-color: ${themeGet('colors.border.neutral.dark')};
    outline: none;
  }

  ${compose(
    variant({
      prop: 'status',
      variants: {
        default: {
          borderColor: 'border.neutral.light',
          ':active, :focus': {
            borderColor: 'border.neutral.dark',
            outline: 'none',
          },
        },
        error: {
          borderColor: 'border.status.error',
          ':active, :focus': {
            borderColor: 'border.neutral.dark',
            outline: 'none',
          },
        },
      },
    }),
    layout,
    space,
    color,
    textStyle,
  )}
`

export const ReceptorFormErrorMsg = styled.p.attrs({
  textStyle: 'caption',
})<{ status: 'default' | 'error' }>`
  ${compose(
    variant({
      prop: 'status',
      variants: {
        default: {
          color: 'transparent',
        },
        error: {
          color: 'fill.status.error.base',
        },
      },
    }),
    textStyle,
  )}
  width: 100%;
  max-height: 16px;
`

// ** WARNING ** - Autogenerated file
import { ModelRecord } from '@trustero/trustero-api-web/lib/model/model_pb'
import { MODEL_TYPE } from '@trustero/trustero-api-web/lib/common/model_pb'
import { SERVICE_ROLE } from '@trustero/trustero-api-web/lib/service/service_pb'
import { PROVIDERS } from '@trustero/trustero-api-web/lib/provider/provider_pb'

            import React from 'react'
            import * as logos from '../components/Icons/Dependencies'
            
            import { DocumentObj } from '../adapter/AttachmentAdapter'
            
export enum ServiceCategory {
  Compute = 'Compute',
  Storage = 'Storage',
  Network = 'Network',
  Infrastructure = 'Infrastructure',
  'CI/CD' = 'CI/CD',
  'Business Services and Tools' = 'Business Services and Tools',
}

export enum ServiceType {
  SaaS = 'SaaS',
  Software = 'Software',
}

export type Service = ModelRecord.AsObject & {
  name:                     string
  content:                  string
  component_category:       ServiceCategory
  component_type:           ServiceType
  compliance?:              {[key: string]: string }
  control_ids:              string[]
  recommended_service:      boolean
  logo:                    ({ width, height}: { width: string, height: string}) => React.FunctionComponentElement<{ width: string; height: string }>
  logo_name:                string
  receptor_id?:              string
  custom?: boolean
  default_roles?:            SERVICE_ROLE[]
  service_roles?:            SERVICE_ROLE[]
  provider?:                 PROVIDERS
  instance_count?:           number
  has_scopable_instances?:   boolean
  can_be_inactive?:          boolean
  is_excluded?:               boolean
}

const DATA:Service[] = [

        {
            ...({} as ModelRecord.AsObject),
id: "trs0",
modelid: "trs0",
modeltype:    MODEL_TYPE.SERVICE,
name: "Trustero",
content: "Compliance Tool",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    false,
logo:        logos.Trustero,
logo_name:   "Trustero",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs1",
modelid: "trs1",
modeltype:    MODEL_TYPE.SERVICE,
name: "CircleCI",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: ["trc12", "trc13", "trc64", "trc65", "trc66", "trc67", "trc68", "trc69", "trc74", "trc98", "trc99", "trc103", "trc107", "trc228"],
recommended_service: true,
dismissed:    true,
logo:        logos.CircleCI,
logo_name:   "CircleCI",
default_roles:   [29],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"],
compliance:  {"SOC 2": "https://circleci.com/security/"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs2",
modelid: "trs2",
modeltype:    MODEL_TYPE.SERVICE,
name: "GitHub Repositories",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: ["trc12", "trc13", "trc14", "trc38", "trc39", "trc40", "trc43", "trc44", "trc63", "trc64", "trc65", "trc66", "trc67", "trc68", "trc74", "trc98", "trc99", "trc103", "trc107", "trc228"],
recommended_service: true,
dismissed:    true,
logo:        logos.GitHubMark,
logo_name:   "GitHubMark",
default_roles:   [7],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"],
compliance:  {"SOC 2": "https://github.com/security/trust"},
receptor_id: "trr-github",
provider: 4,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs3",
modelid: "trs3",
modeltype:    MODEL_TYPE.SERVICE,
name: "Gusto",
content: "HR Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Gusto,
logo_name:   "Gusto",
default_roles:   [15, 14],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"HIPAA": "https://support.gusto.com/state-registration-compliance/compliance/compliance-checklist/1521661211/The-Health-Insurance-Portability-and-Accountability-Act-of-1996-HIPAA.htm"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs4",
modelid: "trs4",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS S3",
content: "Object Storage",
component_type:     ServiceType["SaaS"],
control_ids: ["trc29", "trc85"],
recommended_service: true,
dismissed:    true,
logo:        logos.AmazonS3,
logo_name:   "AmazonS3",
default_roles:   [27],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Storage"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs5",
modelid: "trs5",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS EC2",
content: "Compute",
component_type:     ServiceType["SaaS"],
control_ids: ["trc49", "trc85", "trc106"],
recommended_service: true,
dismissed:    true,
logo:        logos.AmazonEC2,
logo_name:   "AmazonEC2",
default_roles:   [25],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Compute"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs6",
modelid: "trs6",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS EBS",
content: "Block Storage",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AmazonEBS,
logo_name:   "AmazonEBS",
default_roles:   [27],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Storage"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs7",
modelid: "trs7",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS RDS",
content: "Relational Database",
component_type:     ServiceType["SaaS"],
control_ids: ["trc29"],
recommended_service: true,
dismissed:    true,
logo:        logos.AmazonRDS,
logo_name:   "AmazonRDS",
default_roles:   [28],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Storage"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs8",
modelid: "trs8",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS ELB",
content: "Load Balancing",
component_type:     ServiceType["SaaS"],
control_ids: ["trc30", "trc85", "trc106"],
recommended_service: false,
dismissed:    true,
logo:        logos.AmazonELB,
logo_name:   "AmazonELB",
default_roles:   [26],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Network"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs9",
modelid: "trs9",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS CloudFormation",
content: "Infrastructure as Code",
component_type:     ServiceType["SaaS"],
control_ids: ["trc22", "trc30", "trc73", "trc107"],
recommended_service: true,
dismissed:    true,
logo:        logos.AmazonCloudFormation,
logo_name:   "AmazonCloudFormation",
default_roles:   [9],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs10",
modelid: "trs10",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS Config",
content: "Configuration Audit",
component_type:     ServiceType["SaaS"],
control_ids: ["trc14", "trc73"],
recommended_service: true,
dismissed:    true,
logo:        logos.AmazonConfig,
logo_name:   "AmazonConfig",
default_roles:   [10],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: true
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs11",
modelid: "trs11",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS VPC",
content: "Virtual Private Cloud",
component_type:     ServiceType["SaaS"],
control_ids: ["trc85", "trc106"],
recommended_service: true,
dismissed:    true,
logo:        logos.AmazonVPC,
logo_name:   "AmazonVPC",
default_roles:   [26],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Network"],
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs12",
modelid: "trs12",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS CloudFront",
content: "Content Delivery Network",
component_type:     ServiceType["SaaS"],
control_ids: ["trc14"],
recommended_service: true,
dismissed:    true,
logo:        logos.AmazonCloudFront,
logo_name:   "AmazonCloudFront",
default_roles:   [26],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs13",
modelid: "trs13",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS IAM",
content: "Identity and Access Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AmazonIAM,
logo_name:   "AmazonIAM",
default_roles:   [31],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs14",
modelid: "trs14",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS CloudWatch",
content: "Application and Infrastructure Monitoring",
component_type:     ServiceType["SaaS"],
control_ids: ["trc60", "trc61", "trc83"],
recommended_service: true,
dismissed:    true,
logo:        logos.AmazonCloudWatch,
logo_name:   "AmazonCloudWatch",
default_roles:   [2],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs16",
modelid: "trs16",
modeltype:    MODEL_TYPE.SERVICE,
name: "Confluence",
content: "Wiki",
component_type:     ServiceType["SaaS"],
control_ids: ["trc0", "trc1", "trc2", "trc3", "trc4", "trc5", "trc6", "trc7", "trc8", "trc9", "trc10", "trc11", "trc12", "trc13", "trc14", "trc15", "trc16", "trc17", "trc18", "trc19", "trc20", "trc21", "trc22", "trc23", "trc24", "trc25", "trc26", "trc27", "trc28", "trc29", "trc30", "trc31", "trc32", "trc33", "trc34", "trc35", "trc36", "trc37", "trc38", "trc39", "trc40", "trc41", "trc42", "trc43", "trc44", "trc45", "trc46", "trc47", "trc48", "trc49", "trc50", "trc51", "trc52", "trc53", "trc54", "trc55", "trc56", "trc57", "trc58", "trc59", "trc60", "trc61", "trc62", "trc64", "trc65", "trc66", "trc67", "trc68", "trc69", "trc70", "trc71", "trc72", "trc73", "trc74", "trc75", "trc76", "trc77", "trc78", "trc79", "trc80", "trc81", "trc82", "trc83", "trc84", "trc86", "trc87", "trc88", "trc89", "trc90", "trc91", "trc92", "trc93", "trc94", "trc95", "trc96", "trc97", "trc98", "trc99", "trc100", "trc101", "trc102", "trc103", "trc105", "trc107", "trc108", "trc109", "trc110", "trc228"],
recommended_service: true,
dismissed:    true,
logo:        logos.Confluence,
logo_name:   "Confluence",
default_roles:   [18],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.atlassian.com/trust/compliance", "ISO 27001": "https://www.atlassian.com/trust/compliance"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs17",
modelid: "trs17",
modeltype:    MODEL_TYPE.SERVICE,
name: "Jira",
content: "Task Tracker",
component_type:     ServiceType["SaaS"],
control_ids: ["trc12", "trc13", "trc82", "trc86", "trc93", "trc94", "trc98", "trc99", "trc101", "trc103"],
recommended_service: true,
dismissed:    true,
logo:        logos.Jira,
logo_name:   "Jira",
default_roles:   [23, 19, 24],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.atlassian.com/trust/compliance", "ISO 27001": "https://www.atlassian.com/trust/compliance"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs21",
modelid: "trs21",
modeltype:    MODEL_TYPE.SERVICE,
name: "Docker Container Registry",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.DockerCR,
logo_name:   "DockerCR",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs24",
modelid: "trs24",
modeltype:    MODEL_TYPE.SERVICE,
name: "Bitbucket Repositories",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: ["trc12", "trc13", "trc64", "trc65", "trc66", "trc67", "trc68", "trc69", "trc74", "trc107", "trc228"],
recommended_service: true,
dismissed:    true,
logo:        logos.Bitbucket,
logo_name:   "Bitbucket",
default_roles:   [7],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"],
compliance:  {"SOC 2": "https://www.atlassian.com/trust/compliance", "ISO 27001": "https://www.atlassian.com/trust/compliance"},
receptor_id: "trr-bitbucket",
provider: 6,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs25",
modelid: "trs25",
modeltype:    MODEL_TYPE.SERVICE,
name: "GitLab Groups",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: ["trc12", "trc13", "trc64", "trc65", "trc66", "trc67", "trc68", "trc69", "trc74", "trc98", "trc99", "trc103", "trc107", "trc228"],
recommended_service: true,
dismissed:    true,
logo:        logos.GitLab,
logo_name:   "GitLab",
default_roles:   [7],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"],
compliance:  {"SOC 2": "https://about.gitlab.com/handbook/engineering/security/security-assurance/security-compliance/certifications.html"},
receptor_id: "trr-gitlab",
provider: 7,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs26",
modelid: "trs26",
modeltype:    MODEL_TYPE.SERVICE,
name: "TravisCI",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: ["trc98", "trc99", "trc103"],
recommended_service: true,
dismissed:    true,
logo:        logos.TravisCI,
logo_name:   "TravisCI",
default_roles:   [29],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs28",
modelid: "trs28",
modeltype:    MODEL_TYPE.SERVICE,
name: "Splunk",
content: "Log Analysis",
component_type:     ServiceType["SaaS"],
control_ids: ["trc21", "trc36", "trc37", "trc38", "trc39", "trc48", "trc49", "trc50", "trc51", "trc52", "trc53", "trc54", "trc56", "trc60", "trc61", "trc102"],
recommended_service: true,
dismissed:    true,
logo:        logos.Splunk,
logo_name:   "Splunk",
default_roles:   [2],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://www.splunk.com/en_us/legal/splunk-cloud-security-addendum.html", "ISO 27001": "https://www.splunk.com/en_us/legal/splunk-cloud-security-addendum.html"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs29",
modelid: "trs29",
modeltype:    MODEL_TYPE.SERVICE,
name: "Lattice",
content: "Business Services and Tools",
component_type:     ServiceType["SaaS"],
control_ids: ["trc4", "trc5", "trc9", "trc10", "trc11"],
recommended_service: true,
dismissed:    true,
logo:        logos.Lattice,
logo_name:   "Lattice",
default_roles:   [15],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://lattice.com/security"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs31",
modelid: "trs31",
modeltype:    MODEL_TYPE.SERVICE,
name: "Skilljar",
content: "Learning Management",
component_type:     ServiceType["SaaS"],
control_ids: ["trc8", "trc35"],
recommended_service: true,
dismissed:    true,
logo:        logos.Skilljar,
logo_name:   "Skilljar",
default_roles:   [22],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.skilljar.com/security/"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs32",
modelid: "trs32",
modeltype:    MODEL_TYPE.SERVICE,
name: "Quickbooks",
content: "Accounting",
component_type:     ServiceType["SaaS"],
control_ids: ["trc26"],
recommended_service: false,
dismissed:    true,
logo:        logos.Quickbooks,
logo_name:   "Quickbooks",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://quickbooks.intuit.com/learn-support/en-us/use-products-or-services/obtaining-a-soc2-report/00/370632"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs33",
modelid: "trs33",
modeltype:    MODEL_TYPE.SERVICE,
name: "Box",
content: "File Storage",
component_type:     ServiceType["SaaS"],
control_ids: ["trc0", "trc1", "trc2", "trc3", "trc4", "trc5", "trc6", "trc7", "trc8", "trc9", "trc10", "trc11", "trc14", "trc15", "trc16", "trc17", "trc18", "trc19", "trc20", "trc21", "trc22", "trc23", "trc24", "trc25", "trc26", "trc27", "trc28", "trc29", "trc30", "trc31", "trc32", "trc33", "trc34", "trc35", "trc36", "trc37", "trc38", "trc40", "trc41", "trc42", "trc43", "trc44", "trc45", "trc46", "trc47", "trc48", "trc49", "trc50", "trc51", "trc52", "trc53", "trc54", "trc55", "trc56", "trc57", "trc58", "trc59", "trc60", "trc61", "trc62", "trc64", "trc65", "trc66", "trc67", "trc68", "trc69", "trc70", "trc71", "trc72", "trc73", "trc74", "trc75", "trc76", "trc77", "trc78", "trc79", "trc80", "trc81", "trc82", "trc83", "trc84", "trc86", "trc87", "trc88", "trc89", "trc90", "trc91", "trc92", "trc93", "trc94", "trc95", "trc96", "trc97", "trc98", "trc99", "trc100", "trc101", "trc102", "trc103", "trc105", "trc107", "trc108", "trc109", "trc110", "trc228"],
recommended_service: true,
dismissed:    true,
logo:        logos.Box,
logo_name:   "Box",
default_roles:   [30],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Storage"],
compliance:  {"SOC 2": "https://www.box.com/legal/compliance", "ISO 27001": "https://www.box.com/legal/compliance", "HIPAA": "https://www.box.com/industries/healthcare/hipaa-compliance"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs34",
modelid: "trs34",
modeltype:    MODEL_TYPE.SERVICE,
name: "Lever",
content: "Applicant Tracking",
component_type:     ServiceType["SaaS"],
control_ids: ["trc0", "trc7", "trc9", "trc10", "trc11"],
recommended_service: true,
dismissed:    true,
logo:        logos.Lever,
logo_name:   "Lever",
default_roles:   [1],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.lever.co/security-exhibit/"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs35",
modelid: "trs35",
modeltype:    MODEL_TYPE.SERVICE,
name: "Paycom",
content: "HR Platform",
component_type:     ServiceType["SaaS"],
control_ids: ["trc35"],
recommended_service: true,
dismissed:    true,
logo:        logos.Paycom,
logo_name:   "Paycom",
default_roles:   [15, 3],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.paycom.com/media/resources-content/SecurityStandards.pdf?v=2", "ISO 27001": "https://www.paycom.com/media/resources-content/SecurityStandards.pdf?v=2"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs37",
modelid: "trs37",
modeltype:    MODEL_TYPE.SERVICE,
name: "Workday",
content: "HR Platform",
component_type:     ServiceType["SaaS"],
control_ids: ["trc0", "trc6", "trc7", "trc9", "trc10", "trc11", "trc26", "trc35", "trc36", "trc37", "trc38", "trc39", "trc40", "trc41", "trc42", "trc43", "trc44", "trc45", "trc46", "trc47", "trc48", "trc49", "trc50", "trc51", "trc52", "trc53", "trc54", "trc56", "trc57", "trc58", "trc60", "trc61", "trc62", "trc64", "trc69", "trc94"],
recommended_service: true,
dismissed:    true,
logo:        logos.Workday,
logo_name:   "Workday",
default_roles:   [15, 14],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.workday.com/en-us/why-workday/security-trust.html", "ISO 27001": "https://www.workday.com/content/dam/web/en-us/documents/workday-iso-certificate-27001.pdf", "HIPAA": "https://www.workday.com/en-us/why-workday/security-trust.html"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs38",
modelid: "trs38",
modeltype:    MODEL_TYPE.SERVICE,
name: "Google Drive",
content: "File Storage",
component_type:     ServiceType["SaaS"],
control_ids: ["trc0", "trc1", "trc2", "trc3", "trc4", "trc5", "trc6", "trc7", "trc8", "trc9", "trc10", "trc11", "trc14", "trc15", "trc16", "trc17", "trc18", "trc19", "trc20", "trc21", "trc22", "trc23", "trc24", "trc25", "trc26", "trc27", "trc28", "trc29", "trc30", "trc31", "trc32", "trc33", "trc34", "trc35", "trc36", "trc37", "trc38", "trc40", "trc41", "trc42", "trc43", "trc44", "trc45", "trc46", "trc47", "trc48", "trc49", "trc50", "trc51", "trc52", "trc53", "trc54", "trc55", "trc56", "trc57", "trc58", "trc59", "trc60", "trc61", "trc62", "trc64", "trc65", "trc66", "trc67", "trc68", "trc69", "trc70", "trc71", "trc72", "trc73", "trc74", "trc75", "trc76", "trc77", "trc78", "trc79", "trc80", "trc81", "trc82", "trc83", "trc84", "trc86", "trc87", "trc88", "trc89", "trc90", "trc91", "trc92", "trc93", "trc94", "trc95", "trc96", "trc97", "trc98", "trc99", "trc100", "trc101", "trc102", "trc103", "trc105", "trc107", "trc108", "trc109", "trc110", "trc228"],
recommended_service: true,
dismissed:    true,
logo:        logos.GoogleDrive,
logo_name:   "GoogleDrive",
default_roles:   [30],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Storage"],
compliance:  {"SOC 2": "https://cloud.google.com/security/compliance/soc-2", "ISO 27001": "https://cloud.google.com/security/compliance/iso-27001"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs40",
modelid: "trs40",
modeltype:    MODEL_TYPE.SERVICE,
name: "Gerrit",
content: "Code Review",
component_type:     ServiceType["SaaS"],
control_ids: ["trc12", "trc13"],
recommended_service: true,
dismissed:    true,
logo:        logos.Gerrit,
logo_name:   "Gerrit",
default_roles:   [7],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs41",
modelid: "trs41",
modeltype:    MODEL_TYPE.SERVICE,
name: "Greenhouse",
content: "Applicant Tracking",
component_type:     ServiceType["SaaS"],
control_ids: ["trc1", "trc2"],
recommended_service: true,
dismissed:    true,
logo:        logos.Greenhouse,
logo_name:   "Greenhouse",
default_roles:   [1],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.greenhouse.io/security", "ISO 27001": "https://www.greenhouse.io/security"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs42",
modelid: "trs42",
modeltype:    MODEL_TYPE.SERVICE,
name: "BambooHR",
content: "HR Platform",
component_type:     ServiceType["SaaS"],
control_ids: ["trc0", "trc1", "trc2", "trc4", "trc5", "trc6", "trc7", "trc8", "trc9", "trc10", "trc11", "trc94"],
recommended_service: true,
dismissed:    true,
logo:        logos.BambooHR,
logo_name:   "BambooHR",
default_roles:   [15, 14],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.bamboohr.com/security-and-privacy-surfacing/"},
has_scopable_instances: false,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs43",
modelid: "trs43",
modeltype:    MODEL_TYPE.SERVICE,
name: "Dropbox",
content: "File Storage",
component_type:     ServiceType["SaaS"],
control_ids: ["trc0", "trc1", "trc2", "trc3", "trc4", "trc5", "trc6", "trc7", "trc8", "trc9", "trc10", "trc11", "trc14", "trc15", "trc16", "trc17", "trc18", "trc19", "trc20", "trc21", "trc22", "trc23", "trc24", "trc25", "trc26", "trc27", "trc28", "trc29", "trc30", "trc31", "trc32", "trc33", "trc34", "trc35", "trc36", "trc37", "trc38", "trc40", "trc41", "trc42", "trc43", "trc44", "trc45", "trc46", "trc47", "trc48", "trc49", "trc50", "trc51", "trc52", "trc53", "trc54", "trc55", "trc56", "trc57", "trc58", "trc59", "trc60", "trc61", "trc62", "trc64", "trc65", "trc66", "trc67", "trc68", "trc69", "trc70", "trc71", "trc72", "trc73", "trc74", "trc75", "trc76", "trc77", "trc78", "trc79", "trc80", "trc81", "trc82", "trc83", "trc84", "trc86", "trc87", "trc88", "trc89", "trc90", "trc91", "trc92", "trc93", "trc94", "trc95", "trc96", "trc97", "trc98", "trc99", "trc100", "trc101", "trc102", "trc103", "trc105", "trc107", "trc108", "trc109", "trc110", "trc228"],
recommended_service: true,
dismissed:    true,
logo:        logos.Dropbox,
logo_name:   "Dropbox",
default_roles:   [30],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Storage"],
compliance:  {"SOC 2": "https://www.dropbox.com/business/trust/compliance/SOC", "ISO 27001": "https://www.dropbox.com/business/trust/compliance/ISO", "HIPAA": "https://www.dropbox.com/business/trust/compliance/HIPAA"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs44",
modelid: "trs44",
modeltype:    MODEL_TYPE.SERVICE,
name: "SuccessFactors",
content: "Performance Management",
component_type:     ServiceType["SaaS"],
control_ids: ["trc4", "trc5", "trc9", "trc10", "trc11"],
recommended_service: true,
dismissed:    true,
logo:        logos.SuccessFactors,
logo_name:   "SuccessFactors",
default_roles:   [15],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.sap.com/about/trust-center/certification-compliance.soc-reports.html#compliance-rc", "ISO 27001": "https://www.sap.com/about/trust-center/certification-compliance.iso-bs-certificates.html#compliance-rc"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs45",
modelid: "trs45",
modeltype:    MODEL_TYPE.SERVICE,
name: "Loggly",
content: "Log Analysis",
component_type:     ServiceType["SaaS"],
control_ids: ["trc21", "trc36", "trc37", "trc38", "trc39", "trc48", "trc49", "trc50", "trc51", "trc52", "trc53", "trc54", "trc56", "trc60", "trc61", "trc102"],
recommended_service: true,
dismissed:    true,
logo:        logos.Loggly,
logo_name:   "Loggly",
default_roles:   [2],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://www.solarwinds.com/trust-center", "ISO 27001": "https://www.solarwinds.com/trust-center"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs47",
modelid: "trs47",
modeltype:    MODEL_TYPE.SERVICE,
name: "CloudFlare",
content: "Content Delivery Network",
component_type:     ServiceType["SaaS"],
control_ids: ["trc14"],
recommended_service: true,
dismissed:    true,
logo:        logos.Cloudflare,
logo_name:   "Cloudflare",
default_roles:   [26],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Network"],
compliance:  {"SOC 2": "https://www.cloudflare.com/resources/assets/slt3lc6tev37/7vZlrNo1tW8fmtSV3ASMqA/559cae2da74617f2c89e08e3d8b9a439/BDES-1265_Privacy_Compliance_Whitepapers_SOC2.pdf", "ISO 27001": "https://www.cloudflare.com/resources/assets/slt3lc6tev37/4ALYDhIak6XNrKmB8l1I3E/12db0ad12e4190ce980bbc2ffc902255/BDES-1265_Privacy_Compliance_Whitepapers_ISO.pdf", "HIPAA": "https://www.cloudflare.com/privacy-and-compliance/hipaa/"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs48",
modelid: "trs48",
modeltype:    MODEL_TYPE.SERVICE,
name: "Recruitee",
content: "Applicant Tracking",
component_type:     ServiceType["SaaS"],
control_ids: ["trc0", "trc7", "trc9", "trc10", "trc11"],
recommended_service: true,
dismissed:    true,
logo:        logos.Recruitee,
logo_name:   "Recruitee",
default_roles:   [1],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
receptor_id: "trr-recruitee",
has_scopable_instances: false,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs49",
modelid: "trs49",
modeltype:    MODEL_TYPE.SERVICE,
name: "Akamai",
content: "Content Delivery Network",
component_type:     ServiceType["SaaS"],
control_ids: ["trc14"],
recommended_service: true,
dismissed:    true,
logo:        logos.Akamai,
logo_name:   "Akamai",
default_roles:   [26],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Network"],
compliance:  {"SOC 2": "https://www.akamai.com/us/en/about/compliance/#soc2type2", "ISO 27001": "https://www.akamai.com/us/en/about/compliance/#iso27001", "HIPAA": "https://www.akamai.com/us/en/about/compliance/#hipaa"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs52",
modelid: "trs52",
modeltype:    MODEL_TYPE.SERVICE,
name: "Google Cloud Platform",
content: "Public Cloud Platform",
component_type:     ServiceType["SaaS"],
control_ids: ["trc59", "trc65", "trc66", "trc67", "trc68", "trc72", "trc83", "trc85", "trc106", "trc228"],
recommended_service: true,
dismissed:    true,
logo:        logos.GCP,
logo_name:   "GCP",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://cloud.google.com/security/compliance/soc-2", "ISO 27001": "https://cloud.google.com/security/compliance/iso-27001", "HIPAA": "https://cloud.google.com/security/compliance/hipaa"},
provider: 2,
has_scopable_instances: false,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs53",
modelid: "trs53",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Users",
content: "Public Cloud Platform",
component_type:     ServiceType["SaaS"],
control_ids: ["trc59", "trc65", "trc66", "trc67", "trc68", "trc72", "trc83", "trc85", "trc106", "trc228"],
recommended_service: true,
dismissed:    true,
logo:        logos.MicrosoftAzure,
logo_name:   "MicrosoftAzure",
default_roles:   [31],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-SOC", "ISO 27001": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-ISO-27001", "HIPAA": "https://www.microsoft.com/en-us/trust-center/cloudservices/health"},
receptor_id: "trr-azure",
provider: 3,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs54",
modelid: "trs54",
modeltype:    MODEL_TYPE.SERVICE,
name: "Salesforce",
content: "CRM",
component_type:     ServiceType["SaaS"],
control_ids: ["trc55"],
recommended_service: true,
dismissed:    true,
logo:        logos.Salesforce,
logo_name:   "Salesforce",
default_roles:   [12],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://compliance.salesforce.com/en/soc-2", "ISO 27001": "https://compliance.salesforce.com/en/iso-27001", "HIPAA": "https://compliance.salesforce.com/en/hipaa"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs55",
modelid: "trs55",
modeltype:    MODEL_TYPE.SERVICE,
name: "Salesforce Platform",
content: "Platform as a Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Salesforce,
logo_name:   "Salesforce",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://compliance.salesforce.com/en/soc-2", "ISO 27001": "https://compliance.salesforce.com/en/iso-27001", "HIPAA": "https://compliance.salesforce.com/en/hipaa"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs59",
modelid: "trs59",
modeltype:    MODEL_TYPE.SERVICE,
name: "Alibaba Cloud",
content: "Public Cloud Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AlibabaCloud,
logo_name:   "AlibabaCloud",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://www.alibabacloud.com/trust-center/soc", "ISO 27001": "https://www.alibabacloud.com/trust-center/iso27001"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs62",
modelid: "trs62",
modeltype:    MODEL_TYPE.SERVICE,
name: "Google APIs",
content: "Various public services",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.GoogleAPI,
logo_name:   "GoogleAPI",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs63",
modelid: "trs63",
modeltype:    MODEL_TYPE.SERVICE,
name: "Slack",
content: "Business communication platform that offers many IRC-style features, including persistent chat rooms organized by topic, private groups, and direct messaging.",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Slack,
logo_name:   "Slack",
default_roles:   [35],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://slack.com/trust/compliance", "ISO 27001": "https://slack.com/trust/compliance", "HIPAA": "https://slack.com/trust/compliance"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs64",
modelid: "trs64",
modeltype:    MODEL_TYPE.SERVICE,
name: "Zendesk",
content: "Customer Service software",
component_type:     ServiceType["SaaS"],
control_ids: ["trc55", "trc78", "trc100"],
recommended_service: true,
dismissed:    true,
logo:        logos.Zendesk,
logo_name:   "Zendesk",
default_roles:   [12, 19],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.zendesk.com/product/zendesk-security/", "ISO 27001": "https://www.zendesk.com/product/zendesk-security/", "HIPAA": "https://www.zendesk.com/product/zendesk-security/"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs65",
modelid: "trs65",
modeltype:    MODEL_TYPE.SERVICE,
name: "Intercom",
content: "Customer Service software",
component_type:     ServiceType["SaaS"],
control_ids: ["trc55", "trc78", "trc100"],
recommended_service: true,
dismissed:    true,
logo:        logos.Intercom,
logo_name:   "Intercom",
default_roles:   [13],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.intercom.com/security"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs66",
modelid: "trs66",
modeltype:    MODEL_TYPE.SERVICE,
name: "Freshdesk",
content: "Customer Service software",
component_type:     ServiceType["SaaS"],
control_ids: ["trc55", "trc78", "trc100"],
recommended_service: true,
dismissed:    true,
logo:        logos.Freshdesk,
logo_name:   "Freshdesk",
default_roles:   [13],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.freshworks.com/security/", "ISO 27001": "https://www.freshworks.com/security/"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs67",
modelid: "trs67",
modeltype:    MODEL_TYPE.SERVICE,
name: "Asana",
content: "Project management software",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Asana,
logo_name:   "Asana",
default_roles:   [23, 19, 24],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://asana.com/security-statement"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs68",
modelid: "trs68",
modeltype:    MODEL_TYPE.SERVICE,
name: "Datadog",
content: "Service for cloud-scale applications, providing monitoring of servers, databases, tools, and services, through a SaaS-based data analytics platform",
component_type:     ServiceType["SaaS"],
control_ids: ["trc21", "trc60", "trc61", "trc102"],
recommended_service: true,
dismissed:    true,
logo:        logos.Datadog,
logo_name:   "Datadog",
default_roles:   [2, 35],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.datadoghq.com/security/?tab=compliance", "ISO 27001": "https://www.datadoghq.com/security/?tab=compliance", "HIPAA": "https://www.datadoghq.com/security/?tab=compliance"},
has_scopable_instances: false,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs69",
modelid: "trs69",
modeltype:    MODEL_TYPE.SERVICE,
name: "MobileIron",
content: "Endpoint Management Service",
component_type:     ServiceType["SaaS"],
control_ids: ["trc26"],
recommended_service: true,
dismissed:    true,
logo:        logos.MobileIron,
logo_name:   "MobileIron",
default_roles:   [16],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.mobileiron.com/en/certifications-and-uptime", "ISO 27001": "https://www.mobileiron.com/en/certifications-and-uptime", "HIPAA": "https://www.mobileiron.com/en/resources-library/whitepapers/hipaa-compliance-checklist"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs70",
modelid: "trs70",
modeltype:    MODEL_TYPE.SERVICE,
name: "Workspace ONE",
content: "Endpoint Management Service",
component_type:     ServiceType["SaaS"],
control_ids: ["trc26", "trc105"],
recommended_service: true,
dismissed:    true,
logo:        logos.WorkspaceOne,
logo_name:   "WorkspaceOne",
default_roles:   [20],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://cloud.vmware.com/trust-center/compliance/soc", "ISO 27001": "https://cloud.vmware.com/trust-center/compliance/iso"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs71",
modelid: "trs71",
modeltype:    MODEL_TYPE.SERVICE,
name: "Malwarebytes Cloud",
content: "Malware Detection and Mitigation",
component_type:     ServiceType["SaaS"],
control_ids: ["trc105"],
recommended_service: true,
dismissed:    true,
logo:        logos.Malwarebytes,
logo_name:   "Malwarebytes",
default_roles:   [20],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs72",
modelid: "trs72",
modeltype:    MODEL_TYPE.SERVICE,
name: "SentinelOne Singularity Cloud",
content: "Cloud VM and Container Security",
component_type:     ServiceType["SaaS"],
control_ids: ["trc105"],
recommended_service: true,
dismissed:    true,
logo:        logos.SentinalOneSingularity,
logo_name:   "SentinalOneSingularity",
default_roles:   [6],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"ISO 27001": "https://www.sentinelone.com/security-compliance/"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs73",
modelid: "trs73",
modeltype:    MODEL_TYPE.SERVICE,
name: "Google Workspace Users",
content: "Identity and Productivity",
component_type:     ServiceType["SaaS"],
control_ids: ["trc6", "trc9", "trc10", "trc11", "trc23", "trc27", "trc37", "trc38", "trc39", "trc40", "trc41", "trc42", "trc43", "trc44", "trc45", "trc63", "trc64", "trc65", "trc66", "trc67", "trc68", "trc228"],
recommended_service: true,
dismissed:    true,
logo:        logos.Google,
logo_name:   "Google",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://cloud.google.com/security/compliance/soc-2/", "ISO 27001": "https://cloud.google.com/security/compliance/iso-27001", "HIPAA": "https://cloud.google.com/security/compliance/hipaa-compliance"},
receptor_id: "trr-gsuite",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs74",
modelid: "trs74",
modeltype:    MODEL_TYPE.SERVICE,
name: "Microsoft 365",
content: "Identity and Productivity",
component_type:     ServiceType["SaaS"],
control_ids: ["trc41", "trc42", "trc44", "trc45", "trc63"],
recommended_service: true,
dismissed:    true,
logo:        logos.MicrosoftOffice,
logo_name:   "MicrosoftOffice",
default_roles:   [4],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-soc", "ISO 27001": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-iso-27001", "HIPAA": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-hipaa-hitech"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs75",
modelid: "trs75",
modeltype:    MODEL_TYPE.SERVICE,
name: "PAN Prisma Cloud",
content: "Cloud Native Security Platform (CNSP)",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.PrismaCloud,
logo_name:   "PrismaCloud",
default_roles:   [9],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.paloaltonetworks.com/legal-notices/trust-center/soc2", "ISO 27001": "https://www.paloaltonetworks.com/legal-notices/trust-center/iso-27001"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs76",
modelid: "trs76",
modeltype:    MODEL_TYPE.SERVICE,
name: "Palo Alto Networks",
content: "Diversified Cyber Security Vendor",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.PaloAltoNetworks,
logo_name:   "PaloAltoNetworks",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.paloaltonetworks.com/legal-notices/trust-center/soc2", "ISO 27001": "https://www.paloaltonetworks.com/legal-notices/trust-center/iso-27001"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs77",
modelid: "trs77",
modeltype:    MODEL_TYPE.SERVICE,
name: "Checkr",
content: "Background Check Service",
component_type:     ServiceType["SaaS"],
control_ids: ["trc3"],
recommended_service: true,
dismissed:    true,
logo:        logos.Checkr,
logo_name:   "Checkr",
default_roles:   [3],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://checkr.com/trust-and-security", "ISO 27001": "https://checkr.com/trust-and-security"},
receptor_id: "trr-checkr",
has_scopable_instances: false,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs78",
modelid: "trs78",
modeltype:    MODEL_TYPE.SERVICE,
name: "TriNet Employees",
content: "HR Platform",
component_type:     ServiceType["SaaS"],
control_ids: ["trc1", "trc2", "trc37", "trc38", "trc39", "trc40", "trc43", "trc44", "trc64", "trc65", "trc68"],
recommended_service: true,
dismissed:    true,
logo:        logos.TriNet,
logo_name:   "TriNet",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
receptor_id: "trr-trinet",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs79",
modelid: "trs79",
modeltype:    MODEL_TYPE.SERVICE,
name: "Okta",
content: "Federated Identity",
component_type:     ServiceType["SaaS"],
control_ids: ["trc44", "trc45", "trc63", "trc64", "trc65", "trc66", "trc67", "trc68", "trc69", "trc228"],
recommended_service: true,
dismissed:    true,
logo:        logos.Okta,
logo_name:   "Okta",
default_roles:   [16],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://trust.okta.com/compliance", "ISO 27001": "https://trust.okta.com/compliance", "HIPAA": "https://www.okta.com/hipaa/"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs80",
modelid: "trs80",
modeltype:    MODEL_TYPE.SERVICE,
name: "Ping Identity",
content: "Federated Identity",
component_type:     ServiceType["SaaS"],
control_ids: ["trc26", "trc44", "trc45", "trc63", "trc64", "trc65", "trc66", "trc67", "trc68", "trc69", "trc228"],
recommended_service: true,
dismissed:    true,
logo:        logos.PingIdentity,
logo_name:   "PingIdentity",
default_roles:   [16],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://www.pingidentity.com/en/company/security-at-ping-identity.html", "ISO 27001": "https://www.pingidentity.com/en/company/security-at-ping-identity.html"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs81",
modelid: "trs81",
modeltype:    MODEL_TYPE.SERVICE,
name: "OneLogin",
content: "Federated Identity",
component_type:     ServiceType["SaaS"],
control_ids: ["trc44", "trc45", "trc63", "trc64", "trc65", "trc66", "trc67", "trc68", "trc69", "trc228"],
recommended_service: true,
dismissed:    true,
logo:        logos.OneLogin,
logo_name:   "OneLogin",
default_roles:   [16],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://www.onelogin.com/compliance", "ISO 27001": "https://www.onelogin.com/compliance", "HIPAA": "https://www.onelogin.com/compliance"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs82",
modelid: "trs82",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS CloudTrail",
content: "Log Analysis",
component_type:     ServiceType["SaaS"],
control_ids: ["trc37", "trc38"],
recommended_service: true,
dismissed:    true,
logo:        logos.AmazonCloudTrail,
logo_name:   "AmazonCloudTrail",
default_roles:   [2],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: false,
can_be_inactive: true
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs83",
modelid: "trs83",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS Security Hub",
content: "AWS Security Hub gives you a comprehensive view of your security alerts and security posture across your AWS accounts.",
component_type:     ServiceType["SaaS"],
control_ids: ["trc86"],
recommended_service: true,
dismissed:    true,
logo:        logos.AmazonSecurityHub,
logo_name:   "AmazonSecurityHub",
default_roles:   [10],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs84",
modelid: "trs84",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS DynamoDB",
content: "AWS Document Based Storage",
component_type:     ServiceType["SaaS"],
control_ids: ["trc29"],
recommended_service: true,
dismissed:    true,
logo:        logos.AmazonDynamoDB,
logo_name:   "AmazonDynamoDB",
default_roles:   [28],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Storage"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs85",
modelid: "trs85",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS ECS",
content: "AWS Elastic Container Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AmazonECS,
logo_name:   "AmazonECS",
default_roles:   [25],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Compute"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs86",
modelid: "trs86",
modeltype:    MODEL_TYPE.SERVICE,
name: "Jamf Workstations",
content: "Device Management",
component_type:     ServiceType["SaaS"],
control_ids: ["trc26", "trc61", "trc87"],
recommended_service: true,
dismissed:    true,
logo:        logos.Jamf,
logo_name:   "Jamf",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
receptor_id: "trr-jamf",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs87",
modelid: "trs87",
modeltype:    MODEL_TYPE.SERVICE,
name: "GitHub Dependabot",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.GitHubDependabot,
logo_name:   "GitHubDependabot",
default_roles:   [8],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"],
compliance:  {"SOC 2": "https://github.com/security/trust"},
receptor_id: "trr-github-old",
provider: 4,
has_scopable_instances: false,
can_be_inactive: true
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs88",
modelid: "trs88",
modeltype:    MODEL_TYPE.SERVICE,
name: "GCP Users",
content: "Access Control and Resource Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.GCPAdminResource,
logo_name:   "GCPAdminResource",
default_roles:   [31],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://cloud.google.com/security/compliance/soc-2", "ISO 27001": "https://cloud.google.com/security/compliance/iso-27001", "HIPAA": "https://cloud.google.com/security/compliance/hipaa-compliance"},
receptor_id: "trr-gcp",
provider: 2,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs89",
modelid: "trs89",
modeltype:    MODEL_TYPE.SERVICE,
name: "GCP Cloud Storage",
content: "Object Storage",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.GCPCloudStorage,
logo_name:   "GCPCloudStorage",
default_roles:   [27],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://cloud.google.com/security/compliance/soc-2", "ISO 27001": "https://cloud.google.com/security/compliance/iso-27001", "HIPAA": "https://cloud.google.com/security/compliance/hipaa-compliance"},
receptor_id: "trr-gcp",
provider: 2,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs90",
modelid: "trs90",
modeltype:    MODEL_TYPE.SERVICE,
name: "GCP Cloud SQL",
content: "Database",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.GCPCloudSQL,
logo_name:   "GCPCloudSQL",
default_roles:   [28],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://cloud.google.com/security/compliance/soc-2", "ISO 27001": "https://cloud.google.com/security/compliance/iso-27001", "HIPAA": "https://cloud.google.com/security/compliance/hipaa-compliance"},
receptor_id: "trr-gcp",
provider: 2,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs91",
modelid: "trs91",
modeltype:    MODEL_TYPE.SERVICE,
name: "Quickbase",
content: "No-code platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.QuickBase,
logo_name:   "QuickBase",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs92",
modelid: "trs92",
modeltype:    MODEL_TYPE.SERVICE,
name: "NetSuite",
content: "Cloud business management suite",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.NetSuite,
logo_name:   "NetSuite",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs93",
modelid: "trs93",
modeltype:    MODEL_TYPE.SERVICE,
name: "Verkada",
content: "cloud-based building security systems",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Verkada,
logo_name:   "Verkada",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs94",
modelid: "trs94",
modeltype:    MODEL_TYPE.SERVICE,
name: "Employee Navigator",
content: "HR software",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.EmployeeNavigator,
logo_name:   "EmployeeNavigator",
default_roles:   [15],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs95",
modelid: "trs95",
modeltype:    MODEL_TYPE.SERVICE,
name: "Trello",
content: "Project management tool",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Trello,
logo_name:   "Trello",
default_roles:   [23],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs96",
modelid: "trs96",
modeltype:    MODEL_TYPE.SERVICE,
name: "Lucidchart",
content: "Colaborative chart and drawing",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.LucidChart,
logo_name:   "LucidChart",
default_roles:   [4],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs97",
modelid: "trs97",
modeltype:    MODEL_TYPE.SERVICE,
name: "Custom Service",
content: "Custom Receptor Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.CustomReceptor,
logo_name:   "CustomReceptor",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs98",
modelid: "trs98",
modeltype:    MODEL_TYPE.SERVICE,
name: "DeepSource Repositories",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Deepsource,
logo_name:   "Deepsource",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"],
receptor_id: "trr-deepsource",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs99",
modelid: "trs99",
modeltype:    MODEL_TYPE.SERVICE,
name: "Jamf Account Users",
content: "Device Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Jamf,
logo_name:   "Jamf",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
receptor_id: "trr-jamf",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs100",
modelid: "trs100",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS EKS",
content: "AWS Kubernetes Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AmazonEKS,
logo_name:   "AmazonEKS",
default_roles:   [25],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Compute"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs101",
modelid: "trs101",
modeltype:    MODEL_TYPE.SERVICE,
name: "Auth0",
content: "Authentication and Authorization Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Auth0,
logo_name:   "Auth0",
default_roles:   [16],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://auth0.com/blog/auth0-achieves-soc-2-certification/", "ISO 27001": "https://auth0.com/security"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs102",
modelid: "trs102",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Virtual Machines",
content: "Public Cloud Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.MicrosoftAzure,
logo_name:   "MicrosoftAzure",
default_roles:   [25],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-SOC", "ISO 27001": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-ISO-27001", "HIPAA": "https://www.microsoft.com/en-us/trust-center/cloudservices/health"},
receptor_id: "trr-azure",
provider: 3,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs103",
modelid: "trs103",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS Inspector",
content: "AWS Inspector Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AmazonInspector,
logo_name:   "AmazonInspector",
default_roles:   [5],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: false,
can_be_inactive: true
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs104",
modelid: "trs104",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Load Balancers",
content: "Public Cloud Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.MicrosoftAzure,
logo_name:   "MicrosoftAzure",
default_roles:   [26],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-SOC", "ISO 27001": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-ISO-27001", "HIPAA": "https://www.microsoft.com/en-us/trust-center/cloudservices/health"},
receptor_id: "trr-azure",
provider: 3,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs105",
modelid: "trs105",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Application Gateways",
content: "Public Cloud Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.MicrosoftAzure,
logo_name:   "MicrosoftAzure",
default_roles:   [26],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-SOC", "ISO 27001": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-ISO-27001", "HIPAA": "https://www.microsoft.com/en-us/trust-center/cloudservices/health"},
receptor_id: "trr-azure",
provider: 3,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs106",
modelid: "trs106",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Databases",
content: "Public Cloud Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.MicrosoftAzure,
logo_name:   "MicrosoftAzure",
default_roles:   [28],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-SOC", "ISO 27001": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-ISO-27001", "HIPAA": "https://www.microsoft.com/en-us/trust-center/cloudservices/health"},
receptor_id: "trr-azure",
provider: 3,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs107",
modelid: "trs107",
modeltype:    MODEL_TYPE.SERVICE,
name: "Datadog Users",
content: "Cloud Monitoring as a Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Datadog,
logo_name:   "Datadog",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
receptor_id: "trr-datadog",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs108",
modelid: "trs108",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Front Door and CDN",
content: "Public Cloud Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.MicrosoftAzure,
logo_name:   "MicrosoftAzure",
default_roles:   [26],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-SOC", "ISO 27001": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-ISO-27001", "HIPAA": "https://www.microsoft.com/en-us/trust-center/cloudservices/health"},
receptor_id: "trr-azure",
provider: 3,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs109",
modelid: "trs109",
modeltype:    MODEL_TYPE.SERVICE,
name: "Datadog Monitors",
content: "Cloud Monitoring as a Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Datadog,
logo_name:   "Datadog",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
receptor_id: "trr-datadog",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs110",
modelid: "trs110",
modeltype:    MODEL_TYPE.SERVICE,
name: "GitHub Users",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.GitHubMark,
logo_name:   "GitHubMark",
default_roles:   [7],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"],
compliance:  {"SOC 2": "https://github.com/security/trust"},
receptor_id: "trr-github",
provider: 4,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs111",
modelid: "trs111",
modeltype:    MODEL_TYPE.SERVICE,
name: "BambooHR Employee List",
content: "HR Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.BambooHR,
logo_name:   "BambooHR",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.bamboohr.com/security-and-privacy-surfacing/"},
receptor_id: "trr-bamboohr",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs112",
modelid: "trs112",
modeltype:    MODEL_TYPE.SERVICE,
name: "GCP Compute Engine",
content: "Compute",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.GCPComputeEngine,
logo_name:   "GCPComputeEngine",
default_roles:   [25],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://cloud.google.com/security/compliance/soc-2", "ISO 27001": "https://cloud.google.com/security/compliance/iso-27001", "HIPAA": "https://cloud.google.com/security/compliance/hipaa-compliance"},
receptor_id: "trr-gcp",
provider: 2,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs113",
modelid: "trs113",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Intune Devices",
content: "Public Cloud Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.MicrosoftAzure,
logo_name:   "MicrosoftAzure",
default_roles:   [20],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-SOC", "ISO 27001": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-ISO-27001", "HIPAA": "https://www.microsoft.com/en-us/trust-center/cloudservices/health"},
receptor_id: "trr-azure",
provider: 3,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs114",
modelid: "trs114",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS EFS",
content: "AWS Elastic File System Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AmazonECS,
logo_name:   "AmazonECS",
default_roles:   [27],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs115",
modelid: "trs115",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS Certificates",
content: "AWS Certificates Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AmazonCertificateManager,
logo_name:   "AmazonCertificateManager",
default_roles:   [26],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs116",
modelid: "trs116",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS CodeCommit",
content: "AWS CodeCommit Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AmazonCodeCommit,
logo_name:   "AmazonCodeCommit",
default_roles:   [7],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs117",
modelid: "trs117",
modeltype:    MODEL_TYPE.SERVICE,
name: "GCP Security Command Center",
content: "Compute",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.GCPComputeEngine,
logo_name:   "GCPComputeEngine",
default_roles:   [6, 5, 10],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://cloud.google.com/security/compliance/soc-2", "ISO 27001": "https://cloud.google.com/security/compliance/iso-27001", "HIPAA": "https://cloud.google.com/security/compliance/hipaa-compliance"},
receptor_id: "trr-gcp",
provider: 2,
has_scopable_instances: false,
can_be_inactive: true
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs118",
modelid: "trs118",
modeltype:    MODEL_TYPE.SERVICE,
name: "DeepSource Users",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Deepsource,
logo_name:   "Deepsource",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"],
receptor_id: "trr-deepsource",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs119",
modelid: "trs119",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS WAF",
content: "AWS WAF Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AmazonWAF,
logo_name:   "AmazonWAF",
default_roles:   [26],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs120",
modelid: "trs120",
modeltype:    MODEL_TYPE.SERVICE,
name: "AWS GuardDuty",
content: "AWS GuardDuty Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AmazonGuardDuty,
logo_name:   "AmazonGuardDuty",
default_roles:   [6],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://console.aws.amazon.com/artifact/reports?reportArn=arn%3Aaws%3Aartifact%3A%3A%3Areport-package%2FCertifications%20and%20Attestations%2FSOC%2FService%20Organization%20Controls%20(SOC)%202%20Report%20-%20Current", "ISO 27001": "https://d1.awsstatic.com/certifications/iso_27001_global_certification.pdf", "HIPAA": "https://aws.amazon.com/compliance/hipaa-compliance/"},
receptor_id: "trr-aws",
provider: 1,
has_scopable_instances: false,
can_be_inactive: true
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs121",
modelid: "trs121",
modeltype:    MODEL_TYPE.SERVICE,
name: "Bitbucket Members",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Bitbucket,
logo_name:   "Bitbucket",
default_roles:   [7],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"],
compliance:  {"SOC 2": "https://www.atlassian.com/trust/compliance", "ISO 27001": "https://www.atlassian.com/trust/compliance"},
receptor_id: "trr-bitbucket",
provider: 6,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs122",
modelid: "trs122",
modeltype:    MODEL_TYPE.SERVICE,
name: "Jamf Configuration",
content: "Device Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Jamf,
logo_name:   "Jamf",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
receptor_id: "trr-jamf",
has_scopable_instances: false,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs123",
modelid: "trs123",
modeltype:    MODEL_TYPE.SERVICE,
name: "Curricula Users",
content: "Security Training",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Curricula,
logo_name:   "Curricula",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
receptor_id: "trr-curricula",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs124",
modelid: "trs124",
modeltype:    MODEL_TYPE.SERVICE,
name: "Curricula Learners",
content: "Security Training",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Curricula,
logo_name:   "Curricula",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
receptor_id: "trr-curricula",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs125",
modelid: "trs125",
modeltype:    MODEL_TYPE.SERVICE,
name: "GitLab Repositories",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.GitLab,
logo_name:   "GitLab",
default_roles:   [7],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"],
compliance:  {"SOC 2": "https://about.gitlab.com/handbook/engineering/security/security-assurance/security-compliance/certifications.html"},
receptor_id: "trr-gitlab",
provider: 7,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs126",
modelid: "trs126",
modeltype:    MODEL_TYPE.SERVICE,
name: "Snyk Targets",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Snyk,
logo_name:   "Snyk",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"],
receptor_id: "trr-snyk",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs127",
modelid: "trs127",
modeltype:    MODEL_TYPE.SERVICE,
name: "Snyk Users",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Snyk,
logo_name:   "Snyk",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"],
receptor_id: "trr-snyk",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs129",
modelid: "trs129",
modeltype:    MODEL_TYPE.SERVICE,
name: "Miradore Devices",
content: "Device Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Miradore,
logo_name:   "Miradore",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
receptor_id: "trr-miradore",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs130",
modelid: "trs130",
modeltype:    MODEL_TYPE.SERVICE,
name: "ServiceNow",
content: "IT Request Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.ServiceNow,
logo_name:   "ServiceNow",
default_roles:   [19, 24],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.servicenow.com/company/trust/compliance.html#certification4_1", "ISO 27001": "https://www.servicenow.com/company/trust/compliance.html#certification1_1"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs131",
modelid: "trs131",
modeltype:    MODEL_TYPE.SERVICE,
name: "Jira Service Management",
content: "Customer Service software",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.JiraServiceManagement,
logo_name:   "JiraServiceManagement",
default_roles:   [13, 19, 17],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.atlassian.com/trust/compliance/resources/soc2", "ISO 27001": "https://www.atlassian.com/trust/compliance/resources/iso27001", "HIPAA": "https://www.atlassian.com/trust/compliance/resources/hipaa"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs132",
modelid: "trs132",
modeltype:    MODEL_TYPE.SERVICE,
name: "monday.com",
content: "IT Request Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Monday,
logo_name:   "Monday",
default_roles:   [19, 24],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://monday.com/terms/soc2", "ISO 27001": "https://monday.com/trustcenter/iso/", "HIPAA": "https://support.monday.com/hc/en-us/articles/360006506699?_ga=2.162865788.1342488002.1705804445-2020206647.1705804445"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs133",
modelid: "trs133",
modeltype:    MODEL_TYPE.SERVICE,
name: "GitHub Issues",
content: "Software Development Project Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.GitHubMark,
logo_name:   "GitHubMark",
default_roles:   [23, 24],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://github.com/security/trust"},
receptor_id: "trr-github",
provider: 4,
has_scopable_instances: false,
can_be_inactive: true
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs134",
modelid: "trs134",
modeltype:    MODEL_TYPE.SERVICE,
name: "HubSpot",
content: "Customer Service software",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.HubSpot,
logo_name:   "HubSpot",
default_roles:   [12, 13],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://trust.hubspot.com/?itemUid=7bfa66da-33ab-49de-8391-e329738a1ae9&source=click"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs135",
modelid: "trs135",
modeltype:    MODEL_TYPE.SERVICE,
name: "Notion",
content: "Internal Wiki/Intranet",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Notion,
logo_name:   "Notion",
default_roles:   [18],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.notion.so/security", "ISO 27001": "https://www.notion.so/security", "HIPAA": "https://www.notion.so/security"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs136",
modelid: "trs136",
modeltype:    MODEL_TYPE.SERVICE,
name: "Sharepoint",
content: "Internal Wiki/Intranet",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.SharePoint,
logo_name:   "SharePoint",
default_roles:   [18],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs137",
modelid: "trs137",
modeltype:    MODEL_TYPE.SERVICE,
name: "Deel",
content: "Background Check Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Deel,
logo_name:   "Deel",
default_roles:   [3],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.deel.com/security", "ISO 27001": "https://www.deel.com/security"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs138",
modelid: "trs138",
modeltype:    MODEL_TYPE.SERVICE,
name: "AssureHire",
content: "Background Check Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AssureHire,
logo_name:   "AssureHire",
default_roles:   [3],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://mitratech.com/products/assurehire/#:~:text=Accredited%20by%20the%20Professional%20Background,%2Dto%2Dend%20screening%20experience.", "ISO 27001": "https://mitratech.com/products/assurehire/#:~:text=Accredited%20by%20the%20Professional%20Background,%2Dto%2Dend%20screening%20experience."}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs139",
modelid: "trs139",
modeltype:    MODEL_TYPE.SERVICE,
name: "Zinc",
content: "Background Check Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Zinc,
logo_name:   "Zinc",
default_roles:   [3],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs140",
modelid: "trs140",
modeltype:    MODEL_TYPE.SERVICE,
name: "Certn",
content: "Background Check Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Certn,
logo_name:   "Certn",
default_roles:   [3],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://certn.co/blog/what-is-soc2-compliance-how-backgroundchecks-help-you-get-there/#:~:text=Certn%20is%20a%20SOC%202,work%20toward%20achieving%20this%20designation."}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs141",
modelid: "trs141",
modeltype:    MODEL_TYPE.SERVICE,
name: "New Relic",
content: "Application and Infrastructure Monitoring",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.NewRelic,
logo_name:   "NewRelic",
default_roles:   [2, 35],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://newrelic.com/security", "ISO 27001": "https://newrelic.com/security"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs142",
modelid: "trs142",
modeltype:    MODEL_TYPE.SERVICE,
name: "PagerDuty",
content: "Application and Infrastructure Monitoring",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.PagerDuty,
logo_name:   "PagerDuty",
default_roles:   [2, 35],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.pagerduty.com/security/"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs143",
modelid: "trs143",
modeltype:    MODEL_TYPE.SERVICE,
name: "StatusPage",
content: "Incident Management and Communication",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.StatusPage,
logo_name:   "StatusPage",
default_roles:   [17],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.atlassian.com/trust/compliance/resources/soc2", "ISO 27001": "https://www.atlassian.com/trust/compliance/resources/iso27001", "HIPAA": "https://www.atlassian.com/trust/compliance/resources/hipaa"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs144",
modelid: "trs144",
modeltype:    MODEL_TYPE.SERVICE,
name: "Heroku",
content: "Application Platform as a Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Heroku,
logo_name:   "Heroku",
default_roles:   [32],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.heroku.com/compliance", "ISO 27001": "https://www.heroku.com/compliance", "HIPAA": "https://www.heroku.com/compliance"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs145",
modelid: "trs145",
modeltype:    MODEL_TYPE.SERVICE,
name: "Paychex",
content: "HR software",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Paychex,
logo_name:   "Paychex",
default_roles:   [15, 14],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.paychex.com/corporate/security", "ISO 27001": "https://www.paychex.com/corporate/security"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs146",
modelid: "trs146",
modeltype:    MODEL_TYPE.SERVICE,
name: "Linear",
content: "Software Development Project Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Linear,
logo_name:   "Linear",
default_roles:   [23],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://linear.app/docs/security"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs147",
modelid: "trs147",
modeltype:    MODEL_TYPE.SERVICE,
name: "MongoDB Atlas",
content: "Infrastructure - Datastore",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.MongoDBAtlas,
logo_name:   "MongoDBAtlas",
default_roles:   [28],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://www.mongodb.com/products/platform/trust/soc", "ISO 27001": "https://www.mongodb.com/products/platform/trust/iso", "HIPAA": "https://www.mongodb.com/products/platform/trust/hipaa"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs148",
modelid: "trs148",
modeltype:    MODEL_TYPE.SERVICE,
name: "HiBob",
content: "HR software",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.HiBob,
logo_name:   "HiBob",
default_roles:   [15, 14],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.hibob.com/privacy/security/", "ISO 27001": "https://www.hibob.com/privacy/security/"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs149",
modelid: "trs149",
modeltype:    MODEL_TYPE.SERVICE,
name: "Rippling",
content: "HR software",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Rippling,
logo_name:   "Rippling",
default_roles:   [15, 14],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.rippling.com/security#compliance", "ISO 27001": "https://www.rippling.com/security#compliance"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs150",
modelid: "trs150",
modeltype:    MODEL_TYPE.SERVICE,
name: "Justworks",
content: "HR software",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Justworks,
logo_name:   "Justworks",
default_roles:   [15],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs151",
modelid: "trs151",
modeltype:    MODEL_TYPE.SERVICE,
name: "KnowBe4",
content: "Security Training",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.KnowBe4,
logo_name:   "KnowBe4",
default_roles:   [22],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.knowbe4.com/security"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs152",
modelid: "trs152",
modeltype:    MODEL_TYPE.SERVICE,
name: "TeamCity",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.TeamCity,
logo_name:   "TeamCity",
default_roles:   [29],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs153",
modelid: "trs153",
modeltype:    MODEL_TYPE.SERVICE,
name: "JumpCloud",
content: "Device Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.JumpCloud,
logo_name:   "JumpCloud",
default_roles:   [20],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs154",
modelid: "trs154",
modeltype:    MODEL_TYPE.SERVICE,
name: "VerificationX",
content: "Background Check Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.VerificationX,
logo_name:   "VerificationX",
default_roles:   [3],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs155",
modelid: "trs155",
modeltype:    MODEL_TYPE.SERVICE,
name: "Keka",
content: "HR software",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Keka,
logo_name:   "Keka",
default_roles:   [15, 14],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.keka.com/security", "ISO 27001": "https://www.keka.com/security"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs156",
modelid: "trs156",
modeltype:    MODEL_TYPE.SERVICE,
name: "Seismic",
content: "Customer Service software",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Seismic,
logo_name:   "Seismic",
default_roles:   [12],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://seismic.com/trust/", "ISO 27001": "https://seismic.com/trust/"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs157",
modelid: "trs157",
modeltype:    MODEL_TYPE.SERVICE,
name: "Sophos EDR Users",
content: "Endpoint Management Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Sophos,
logo_name:   "Sophos",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.sophos.com/en-us/trust/business-certifications", "HIPAA": "https://www.sophos.com/en-us/trust/business-certifications"},
receptor_id: "trr-sophos",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs158",
modelid: "trs158",
modeltype:    MODEL_TYPE.SERVICE,
name: "Nessus",
content: "Cloud Infrastructure Vulnerability Scanning",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Nessus,
logo_name:   "Nessus",
default_roles:   [5],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"ISO 27001": "https://www.tenable.com/trust/assurance"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs159",
modelid: "trs159",
modeltype:    MODEL_TYPE.SERVICE,
name: "Kandji",
content: "Device Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Kandji,
logo_name:   "Kandji",
default_roles:   [20],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.kandji.io/security/", "ISO 27001": "https://www.kandji.io/security/"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs160",
modelid: "trs160",
modeltype:    MODEL_TYPE.SERVICE,
name: "Wiz",
content: "Cloud Security Posture Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Wiz,
logo_name:   "Wiz",
default_roles:   [34],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs161",
modelid: "trs161",
modeltype:    MODEL_TYPE.SERVICE,
name: "CrowdStrike",
content: "Cloud Infrastructure Vulnerability Scanning",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.CrowdStrike,
logo_name:   "CrowdStrike",
default_roles:   [5],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.crowdstrike.com/why-crowdstrike/crowdstrike-compliance-certification/#service-org-control", "HIPAA": "https://www.crowdstrike.com/why-crowdstrike/crowdstrike-compliance-certification/#hipaa"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs162",
modelid: "trs162",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure DevOps Boards",
content: "Software Development Project Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AzureDevOpsBoards,
logo_name:   "AzureDevOpsBoards",
default_roles:   [23],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-soc-2", "ISO 27001": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-iso-27001", "HIPAA": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-hipaa-us"},
provider: 3
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs163",
modelid: "trs163",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure DevOps Repos",
content: "Code Repository",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AzureDevOpsRepos,
logo_name:   "AzureDevOpsRepos",
default_roles:   [7],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-soc-2", "ISO 27001": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-iso-27001", "HIPAA": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-hipaa-us"},
provider: 3
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs164",
modelid: "trs164",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure DevOps Pipelines",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.AzureDevOpsPipelines,
logo_name:   "AzureDevOpsPipelines",
default_roles:   [29],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"],
compliance:  {"SOC 2": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-soc-2", "ISO 27001": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-iso-27001", "HIPAA": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-hipaa-us"},
provider: 3
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs165",
modelid: "trs165",
modeltype:    MODEL_TYPE.SERVICE,
name: "Right-Hand",
content: "Security Training",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.RightHand,
logo_name:   "RightHand",
default_roles:   [22],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs166",
modelid: "trs166",
modeltype:    MODEL_TYPE.SERVICE,
name: "Dayforce",
content: "HR software",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Dayforce,
logo_name:   "Dayforce",
default_roles:   [15],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs167",
modelid: "trs167",
modeltype:    MODEL_TYPE.SERVICE,
name: "ADP",
content: "HR software",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.ADP,
logo_name:   "ADP",
default_roles:   [15],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"ISO 27001": "https://www.adp.com/about-adp/data-security.aspx"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs168",
modelid: "trs168",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Microsoft Entra ID",
content: "Public Cloud Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.MicrosoftAzure,
logo_name:   "MicrosoftAzure",
default_roles:   [31],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-SOC", "ISO 27001": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-ISO-27001", "HIPAA": "https://www.microsoft.com/en-us/trust-center/cloudservices/health"},
receptor_id: "trr-azure",
provider: 3,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs169",
modelid: "trs169",
modeltype:    MODEL_TYPE.SERVICE,
name: "Paycor",
content: "HR software",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Paycor,
logo_name:   "Paycor",
default_roles:   [15, 14],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.paycor.com/security/"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs170",
modelid: "trs170",
modeltype:    MODEL_TYPE.SERVICE,
name: "Sophos EDR Endpoint Devices",
content: "Endpoint Management Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Sophos,
logo_name:   "Sophos",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.sophos.com/en-us/trust/business-certifications", "HIPAA": "https://www.sophos.com/en-us/trust/business-certifications"},
receptor_id: "trr-sophos",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs171",
modelid: "trs171",
modeltype:    MODEL_TYPE.SERVICE,
name: "Curricula",
content: "Security Training",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Curricula,
logo_name:   "Curricula",
default_roles:   [22],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs172",
modelid: "trs172",
modeltype:    MODEL_TYPE.SERVICE,
name: "DeepSource",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Deepsource,
logo_name:   "Deepsource",
default_roles:   [8],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs173",
modelid: "trs173",
modeltype:    MODEL_TYPE.SERVICE,
name: "Google Workspace",
content: "Identity and Productivity",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Google,
logo_name:   "Google",
default_roles:   [4, 16],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://cloud.google.com/security/compliance/soc-2/", "ISO 27001": "https://cloud.google.com/security/compliance/iso-27001", "HIPAA": "https://cloud.google.com/security/compliance/hipaa-compliance"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs174",
modelid: "trs174",
modeltype:    MODEL_TYPE.SERVICE,
name: "Jamf",
content: "Device Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Jamf,
logo_name:   "Jamf",
default_roles:   [20],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs175",
modelid: "trs175",
modeltype:    MODEL_TYPE.SERVICE,
name: "Miradore",
content: "Device Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Miradore,
logo_name:   "Miradore",
default_roles:   [20],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs176",
modelid: "trs176",
modeltype:    MODEL_TYPE.SERVICE,
name: "Snyk",
content: "CI/CD Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Snyk,
logo_name:   "Snyk",
default_roles:   [8, 5],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["CI/CD"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs177",
modelid: "trs177",
modeltype:    MODEL_TYPE.SERVICE,
name: "TriNet",
content: "HR Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.TriNet,
logo_name:   "TriNet",
default_roles:   [15, 14],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"]
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs178",
modelid: "trs178",
modeltype:    MODEL_TYPE.SERVICE,
name: "Sophos",
content: "Endpoint Management Service",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Sophos,
logo_name:   "Sophos",
default_roles:   [33],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.sophos.com/en-us/trust/business-certifications", "HIPAA": "https://www.sophos.com/en-us/trust/business-certifications"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs179",
modelid: "trs179",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Automation",
content: "Configuration Management/IAC",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Azure,
logo_name:   "Azure",
default_roles:   [9],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-soc-2", "ISO 27001": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-iso-27001", "HIPAA": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-hipaa-us"},
provider: 3
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs180",
modelid: "trs180",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Log Analytics",
content: "Application Logging, Monitoring, and Alerting",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Azure,
logo_name:   "Azure",
default_roles:   [2],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-soc-2", "ISO 27001": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-iso-27001", "HIPAA": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-hipaa-us"},
provider: 3
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs181",
modelid: "trs181",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Monitor",
content: "Application Logging, Monitoring, and Alerting",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Azure,
logo_name:   "Azure",
default_roles:   [2],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-soc-2", "ISO 27001": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-iso-27001", "HIPAA": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-hipaa-us"},
provider: 3
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs182",
modelid: "trs182",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Policy",
content: "Configuration Management/IAC",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Azure,
logo_name:   "Azure",
default_roles:   [9],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-soc-2", "ISO 27001": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-iso-27001", "HIPAA": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-hipaa-us"},
provider: 3
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs183",
modelid: "trs183",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Security Center",
content: "Cloud Infrastructure Vulnerability Scanning",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Azure,
logo_name:   "Azure",
default_roles:   [5],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-soc-2", "ISO 27001": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-iso-27001", "HIPAA": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-hipaa-us"},
provider: 3
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs184",
modelid: "trs184",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Microsoft Sentinel",
content: "Malware Detection and Mitigation",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Azure,
logo_name:   "Azure",
default_roles:   [6],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-soc-2", "ISO 27001": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-iso-27001", "HIPAA": "https://learn.microsoft.com/en-us/azure/compliance/offerings/offering-hipaa-us"},
provider: 3
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs185",
modelid: "trs185",
modeltype:    MODEL_TYPE.SERVICE,
name: "GCP Deployment Manager",
content: "Configuration Management/IAC",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.GCP,
logo_name:   "GCP",
default_roles:   [9],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://cloud.google.com/security/compliance/soc-2", "ISO 27001": "https://cloud.google.com/security/compliance/iso-27001", "HIPAA": "https://cloud.google.com/security/compliance/hipaa-compliance"},
provider: 2
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs186",
modelid: "trs186",
modeltype:    MODEL_TYPE.SERVICE,
name: "GCP Logging",
content: "Application Logging, Monitoring, and Alerting",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.GCP,
logo_name:   "GCP",
default_roles:   [2],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://cloud.google.com/security/compliance/soc-2", "ISO 27001": "https://cloud.google.com/security/compliance/iso-27001", "HIPAA": "https://cloud.google.com/security/compliance/hipaa-compliance"},
provider: 2
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs187",
modelid: "trs187",
modeltype:    MODEL_TYPE.SERVICE,
name: "GCP Monitoring",
content: "Application Logging, Monitoring, and Alerting",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.GCP,
logo_name:   "GCP",
default_roles:   [2],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://cloud.google.com/security/compliance/soc-2", "ISO 27001": "https://cloud.google.com/security/compliance/iso-27001", "HIPAA": "https://cloud.google.com/security/compliance/hipaa-compliance"},
provider: 2
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs188",
modelid: "trs188",
modeltype:    MODEL_TYPE.SERVICE,
name: "OpsGenie",
content: "Incident Management and Communication",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.OpsGenie,
logo_name:   "OpsGenie",
default_roles:   [35],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.atlassian.com/trust/compliance", "ISO 27001": "https://www.atlassian.com/trust/compliance"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs189",
modelid: "trs189",
modeltype:    MODEL_TYPE.SERVICE,
name: "Semgrep",
content: "Code Scanning and Analysis",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Semgrep,
logo_name:   "Semgrep",
default_roles:   [8],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://trust.semgrep.dev/"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs190",
modelid: "trs190",
modeltype:    MODEL_TYPE.SERVICE,
name: "JumpCloud Users",
content: "Device Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.JumpCloud,
logo_name:   "JumpCloud",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
receptor_id: "trr-jumpcloud",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs191",
modelid: "trs191",
modeltype:    MODEL_TYPE.SERVICE,
name: "JumpCloud Devices",
content: "Device Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.JumpCloud,
logo_name:   "JumpCloud",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
receptor_id: "trr-jumpcloud",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs192",
modelid: "trs192",
modeltype:    MODEL_TYPE.SERVICE,
name: "KnowBe4 Users",
content: "Security Training",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.KnowBe4,
logo_name:   "KnowBe4",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.knowbe4.com/security"},
receptor_id: "trr-knowbe4",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs193",
modelid: "trs193",
modeltype:    MODEL_TYPE.SERVICE,
name: "KnowBe4 Assignments",
content: "Security Training",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.KnowBe4,
logo_name:   "KnowBe4",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.knowbe4.com/security"},
receptor_id: "trr-knowbe4",
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs194",
modelid: "trs194",
modeltype:    MODEL_TYPE.SERVICE,
name: "ClickUp",
content: "Software Development Project",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.ClickUp,
logo_name:   "ClickUp",
default_roles:   [23],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://clickup.com/security", "ISO 27001": "https://clickup.com/security"}
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs195",
modelid: "trs195",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure AKS",
content: "Public Cloud Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.MicrosoftAzure,
logo_name:   "MicrosoftAzure",
default_roles:   [26],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-SOC", "ISO 27001": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-ISO-27001", "HIPAA": "https://www.microsoft.com/en-us/trust-center/cloudservices/health"},
receptor_id: "trr-azure",
provider: 3,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs196",
modelid: "trs196",
modeltype:    MODEL_TYPE.SERVICE,
name: "Azure Disks",
content: "Public Cloud Platform",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.MicrosoftAzure,
logo_name:   "MicrosoftAzure",
default_roles:   [27],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Infrastructure"],
compliance:  {"SOC 2": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-SOC", "ISO 27001": "https://docs.microsoft.com/en-us/compliance/regulatory/offering-ISO-27001", "HIPAA": "https://www.microsoft.com/en-us/trust-center/cloudservices/health"},
receptor_id: "trr-azure",
provider: 3,
has_scopable_instances: true,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs197",
modelid: "trs197",
modeltype:    MODEL_TYPE.SERVICE,
name: "Jira Issues",
content: "Software Development Project Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Jira,
logo_name:   "Jira",
default_roles:   [23, 19, 24],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.atlassian.com/trust/compliance", "ISO 27001": "https://www.atlassian.com/trust/compliance"},
receptor_id: "trr-jira",
has_scopable_instances: false,
can_be_inactive: false
        },
        
        {
            ...({} as ModelRecord.AsObject),
id: "trs198",
modelid: "trs198",
modeltype:    MODEL_TYPE.SERVICE,
name: "Jira Users",
content: "Software Development Project Management",
component_type:     ServiceType["SaaS"],
control_ids: [],
recommended_service: false,
dismissed:    true,
logo:        logos.Jira,
logo_name:   "Jira",
default_roles:   [],
service_roles: [],
is_excluded: false,
component_category: ServiceCategory["Business Services and Tools"],
compliance:  {"SOC 2": "https://www.atlassian.com/trust/compliance", "ISO 27001": "https://www.atlassian.com/trust/compliance"},
receptor_id: "trr-jira",
has_scopable_instances: true,
can_be_inactive: false
        },
        ]

export default DATA


export const getServiceTemplate = (modelId: string): Service => {
  const matchedService = DATA.find((s) => s.modelid === modelId)
  if (!matchedService) {
    throw new Error('Reference to unknown service:' + modelId)
  }
  return matchedService
}

export const getAllServiceTemplates = (): Service[] => {
  return DATA
}


import React from 'react'
import ContentLoader from 'react-content-loader'
import log from 'loglevel'
import { RiskFilter } from '@trustero/trustero-api-web/lib/vendormanagement/vendormanagement_pb'
import { Risk } from '@trustero/trustero-api-web/lib/risk/risk_pb'
import { Grid } from 'src/components/Reusable/Grid'
import { TabType, Tabs } from 'src/components/Reusable/Tabs'
import {
  TabPanelButtonGroup,
  TabPanelNoteHeader,
  TabPanelNoteItem,
} from 'src/components/Reusable/Tabs/TabNotes/styles'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import { useVendors, useVendorsRequest } from 'src/pages/Vendors/vendors.hooks'
import { ModalFormId } from 'src/components/ModalForms'
import { GatekeeperFlag, IsGatekeeperEnabled } from 'src/context/Gatekeeper'
import {
  StandardButtonSize,
  StandardButtonVariant,
} from 'src/components/Reusable/Buttons/StandardButton'
import { E2E_IDS } from 'src/Utils/testIds'
import { VendorsGrid } from 'src/pages/Vendors/grid/VendorsGrid'
import { RisksControlsGridRow } from '../grid/RisksControlsGridRow'
import { NoItemsLinked } from '../risks.styles'
import { AddVendorsToRiskModal } from '../modals/AddVendorsToRiskModal'

export const RisksTabs = ({ risk }: { risk: Risk }): JSX.Element => {
  const riskId = risk.getId()
  const ids = risk.getControlIds()
  const controlIds = ids.length > 0 ? ids.split(',') : []
  const vendorsReq = useVendorsRequest()
  vendorsReq.setRiskFilter(new RiskFilter().setRiskId(riskId))
  const { data, isLoading, error } = useVendors(vendorsReq)
  const vendors = data?.getVendorsList() || []

  let vendorsBody
  if (isLoading) {
    vendorsBody = <ContentLoader />
  } else if (error) {
    log.error(`Error loading vendors for risk id ${riskId}:`, error)
    vendorsBody = (
      <NoItemsLinked>
        Sorry, there was an issue loading the related vendors. Please try again.
      </NoItemsLinked>
    )
  } else if (!data || vendors.length === 0) {
    vendorsBody = <NoItemsLinked>No vendors linked to this risk.</NoItemsLinked>
  } else {
    vendorsBody = <VendorsGrid vendors={vendors} />
  }

  const tabs: TabType[] = [
    {
      title: 'Mitigating / Compensating Controls',
      count: controlIds.length,
      body: (
        <>
          {controlIds.length > 0 ? (
            <Grid
              pb="s"
              gridTemplateColumns="min-content min-content minmax(min-content, 1fr) repeat(1, auto)"
            >
              {controlIds.map((controlId: string) => (
                <RisksControlsGridRow
                  key={controlId}
                  controlId={controlId}
                  riskId={riskId}
                />
              ))}
            </Grid>
          ) : (
            <NoItemsLinked>No controls linked to this risk.</NoItemsLinked>
          )}
        </>
      ),
      toolTipBody: 'Controls relevant to managing this risk.',
      note: (
        <TabPanelNoteItem>
          <TabPanelNoteHeader>
            <TabPanelButtonGroup>
              <StandardOpenModalButton
                modalId={ModalFormId.LINK_CONTROL_TO_RISK}
                variant={StandardButtonVariant.SECONDARY}
                size={StandardButtonSize.MEDIUM}
                text="Link Controls"
                testId={E2E_IDS.RISK_LINK_ADD_CONTROL}
              />
            </TabPanelButtonGroup>
          </TabPanelNoteHeader>
        </TabPanelNoteItem>
      ),
    },
  ]
  const vendorsTab = {
    title: 'Related Vendors',
    count: vendors.length,
    body: vendorsBody,
    note: (
      <TabPanelNoteItem>
        <TabPanelNoteHeader>
          <TabPanelButtonGroup>
            <StandardOpenModalButton
              modalId={ModalFormId.LINK_VENDOR_TO_RISK}
              variant={StandardButtonVariant.SECONDARY}
              size={StandardButtonSize.MEDIUM}
              text="Link Vendors"
            />
          </TabPanelButtonGroup>
        </TabPanelNoteHeader>
      </TabPanelNoteItem>
    ),
  }
  if (IsGatekeeperEnabled(GatekeeperFlag.VENDOR_MANAGEMENT)) {
    tabs.push(vendorsTab)
  }

  const vendorIds = vendors.map((vendor) => vendor.getId())

  return (
    <>
      <Tabs tabs={tabs} />
      {/* modals */}
      <AddVendorsToRiskModal riskId={riskId} existingVendorIds={vendorIds} />
    </>
  )
}

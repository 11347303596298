/* eslint-disable @typescript-eslint/no-explicit-any */
// url to your service

import { Dispatch, useContext, useMemo } from 'react'
import log from 'loglevel'
import { Message } from 'google-protobuf'
import {
  USE_CASE,
  USER_ROLE,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { ToastPrompts } from 'src/Utils/helpers/toast'
import { toast } from 'react-toastify'
import AuthContext, {
  AuthAction,
  AuthActionType,
  AuthRecord,
} from '../context/authContext'
import ContentContext, { Content } from '../context/ContentContext'
import { GrpcCall } from '../components/async/utils'
import {
  useGrpcRevalidateByMethod,
  useGrpcRevalidateByMethodRequest,
} from '../components'
import { authHeader, NTRCE_API_URL, translateTokenError } from './gRpcAdapter'
import { callApiAndUpdateContent } from './dataModelAdapterUtils'

export type GrpcClientCons<T> = {
  new (arg1: string, arg2: any, arg3: any): T
}

export type GrpcClient<T> = T & {
  header: { Authorization?: string }
  accountId?: string
}

export const authorizedGrpcClientFactory = <T>(
  clientCons: GrpcClientCons<T>,
  auth?: AuthRecord | null,
  authDispatch?: Dispatch<AuthAction> | null,
): GrpcClient<T> => {
  const client = new clientCons(NTRCE_API_URL, null, null)

  const header = authHeader(auth?.accessToken)
  for (const prop in client) {
    if (typeof client[prop] !== 'function') {
      continue
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    const original = client[prop] as unknown as Function
    client[prop] = ((...args: any[]) => {
      args[1] = {
        ...args[1],
        ...header,
      }

      const call = original.call(client, ...args)

      // Check if it's a Promise or a Stream
      // Promise is a standard API call
      if (typeof call.then === 'function') {
        // It's a Promise
        return call.catch((err: Error & { code?: number }) => {
          if (
            err?.code === 7 &&
            err.message.includes('this account is read only')
          ) {
            toast(ToastPrompts.READ_ONLY_ACCOUNT)
          }

          const parsedErrors:
            | {
                token: any
                general: any
                void?: boolean
              }
            | {
                token: any
                void?: boolean
              } = translateTokenError(err)
          if (parsedErrors.token) {
            log.warn('Auth Token issue: ', parsedErrors.token)
            authDispatch &&
              authDispatch({
                type: AuthActionType.LOGOUT,
                useCase: USE_CASE.SAAS_BUYER,
                role: USER_ROLE.READONLY,
              })
            return
          }

          if (parsedErrors.void === true) {
            return
          }

          throw err
        })
      } else {
        // It's a Stream
        call.on('error', (err: Error) => {
          const parsedErrors:
            | {
                token: any
                general: any
                void?: boolean
              }
            | {
                token: any
                void?: boolean
              } = translateTokenError(err)

          if (parsedErrors.token) {
            log.warn('Auth Token issue: ', parsedErrors.token)
            authDispatch &&
              authDispatch({
                type: AuthActionType.LOGOUT,
                useCase: USE_CASE.SAAS_BUYER,
                role: USER_ROLE.READONLY,
              })
          }

          if (parsedErrors.void === true) {
            return
          }
        })
        return call
      }
    }) as any
  }
  return {
    ...client,
    header,
    accountId: auth?.accountId,
  }
}

export function authorizedGrpcClientFactoryWithContentUpdate<T>(
  clientCons: GrpcClientCons<T>,
  grpcUrl: string,
  auth: AuthRecord,
  authDispatch: Dispatch<AuthAction>,
  content: Content,
  contentUpdate: Dispatch<Content>,
  methodMutator: (asyncCall: unknown) => Promise<unknown>,
  methodRequestMutator: (
    asyncCall: GrpcCall<Message, Message>,
    request: Message,
  ) => Promise<Message>,
): GrpcClient<T> {
  const client = new clientCons(grpcUrl, null, null)

  const header = authHeader(auth.accessToken)
  for (const prop in client) {
    if (typeof client[prop] !== 'function') {
      continue
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    const original = client[prop] as unknown as Function
    client[prop] = ((...args: any[]) => {
      args[1] = {
        ...args[1],
        ...header,
      }

      return callApiAndUpdateContent(
        () => original.call(client, ...args),
        content,
        contentUpdate,
        auth,
        authDispatch,
        methodMutator,
        methodRequestMutator,
      ).catch((err: Error) => {
        const parsedErrors:
          | {
              token: any
              general: any
              void?: boolean
            }
          | {
              token: any
              void?: boolean
            } = translateTokenError(err)

        if (parsedErrors.token) {
          log.warn('Auth Token issue: ', parsedErrors.token)
          authDispatch({
            type: AuthActionType.LOGOUT,
            useCase: USE_CASE.SAAS_BUYER,
            role: USER_ROLE.READONLY,
          })
        }

        if (parsedErrors.void === true) {
          return
        }

        throw err
      })
    }) as any
  }
  return {
    ...client,
    header,
    accountId: auth?.accountId,
  }
}

export function useAuthorizedGrpcClient<T>(
  client: GrpcClientCons<T>,
): GrpcClient<T> {
  const { authCtx, authDispatch } = useContext(AuthContext)
  return useMemo(
    () => authorizedGrpcClientFactory(client, authCtx, authDispatch),
    [authCtx, authDispatch, client],
  )
}

export function useAuthorizedGrpcClientWithContentUpdate<T>(
  client: GrpcClientCons<T>,
): GrpcClient<T> {
  const { authCtx, authDispatch } = useContext(AuthContext)
  const { content, contentDispatch } = useContext(ContentContext)
  const methodMutator = useGrpcRevalidateByMethod()
  const methodRequestMutator = useGrpcRevalidateByMethodRequest()

  return useMemo(
    () =>
      authorizedGrpcClientFactoryWithContentUpdate(
        client,
        NTRCE_API_URL,
        authCtx,
        authDispatch,
        content,
        contentDispatch,
        methodMutator,
        methodRequestMutator,
      ),
    [
      client,
      authCtx,
      authDispatch,
      content,
      contentDispatch,
      methodMutator,
      methodRequestMutator,
    ],
  )
}

/**
 * @fileoverview gRPC-Web generated client stub for vendormanagement
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: vendormanagement/vendormanagement.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var common_sorting_pb = require('../common/sorting_pb.js')
const proto = {};
proto.vendormanagement = require('./vendormanagement_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.vendormanagement.VendorClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.vendormanagement.VendorPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vendormanagement.ListVendorsRequest,
 *   !proto.vendormanagement.ListVendorsResponse>}
 */
const methodDescriptor_Vendor_ListVendors = new grpc.web.MethodDescriptor(
  '/vendormanagement.Vendor/ListVendors',
  grpc.web.MethodType.UNARY,
  proto.vendormanagement.ListVendorsRequest,
  proto.vendormanagement.ListVendorsResponse,
  /**
   * @param {!proto.vendormanagement.ListVendorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vendormanagement.ListVendorsResponse.deserializeBinary
);


/**
 * @param {!proto.vendormanagement.ListVendorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vendormanagement.ListVendorsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vendormanagement.ListVendorsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vendormanagement.VendorClient.prototype.listVendors =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vendormanagement.Vendor/ListVendors',
      request,
      metadata || {},
      methodDescriptor_Vendor_ListVendors,
      callback);
};


/**
 * @param {!proto.vendormanagement.ListVendorsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vendormanagement.ListVendorsResponse>}
 *     Promise that resolves to the response
 */
proto.vendormanagement.VendorPromiseClient.prototype.listVendors =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vendormanagement.Vendor/ListVendors',
      request,
      metadata || {},
      methodDescriptor_Vendor_ListVendors);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vendormanagement.GetVendorRequest,
 *   !proto.vendormanagement.VendorRecord>}
 */
const methodDescriptor_Vendor_GetVendor = new grpc.web.MethodDescriptor(
  '/vendormanagement.Vendor/GetVendor',
  grpc.web.MethodType.UNARY,
  proto.vendormanagement.GetVendorRequest,
  proto.vendormanagement.VendorRecord,
  /**
   * @param {!proto.vendormanagement.GetVendorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vendormanagement.VendorRecord.deserializeBinary
);


/**
 * @param {!proto.vendormanagement.GetVendorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vendormanagement.VendorRecord)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vendormanagement.VendorRecord>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vendormanagement.VendorClient.prototype.getVendor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vendormanagement.Vendor/GetVendor',
      request,
      metadata || {},
      methodDescriptor_Vendor_GetVendor,
      callback);
};


/**
 * @param {!proto.vendormanagement.GetVendorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vendormanagement.VendorRecord>}
 *     Promise that resolves to the response
 */
proto.vendormanagement.VendorPromiseClient.prototype.getVendor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vendormanagement.Vendor/GetVendor',
      request,
      metadata || {},
      methodDescriptor_Vendor_GetVendor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vendormanagement.CreateVendorsRequest,
 *   !proto.vendormanagement.Vendors>}
 */
const methodDescriptor_Vendor_CreateVendors = new grpc.web.MethodDescriptor(
  '/vendormanagement.Vendor/CreateVendors',
  grpc.web.MethodType.UNARY,
  proto.vendormanagement.CreateVendorsRequest,
  proto.vendormanagement.Vendors,
  /**
   * @param {!proto.vendormanagement.CreateVendorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vendormanagement.Vendors.deserializeBinary
);


/**
 * @param {!proto.vendormanagement.CreateVendorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vendormanagement.Vendors)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vendormanagement.Vendors>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vendormanagement.VendorClient.prototype.createVendors =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vendormanagement.Vendor/CreateVendors',
      request,
      metadata || {},
      methodDescriptor_Vendor_CreateVendors,
      callback);
};


/**
 * @param {!proto.vendormanagement.CreateVendorsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vendormanagement.Vendors>}
 *     Promise that resolves to the response
 */
proto.vendormanagement.VendorPromiseClient.prototype.createVendors =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vendormanagement.Vendor/CreateVendors',
      request,
      metadata || {},
      methodDescriptor_Vendor_CreateVendors);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vendormanagement.UpdateVendorsRequest,
 *   !proto.vendormanagement.Vendors>}
 */
const methodDescriptor_Vendor_UpdateVendors = new grpc.web.MethodDescriptor(
  '/vendormanagement.Vendor/UpdateVendors',
  grpc.web.MethodType.UNARY,
  proto.vendormanagement.UpdateVendorsRequest,
  proto.vendormanagement.Vendors,
  /**
   * @param {!proto.vendormanagement.UpdateVendorsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.vendormanagement.Vendors.deserializeBinary
);


/**
 * @param {!proto.vendormanagement.UpdateVendorsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.vendormanagement.Vendors)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.vendormanagement.Vendors>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vendormanagement.VendorClient.prototype.updateVendors =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vendormanagement.Vendor/UpdateVendors',
      request,
      metadata || {},
      methodDescriptor_Vendor_UpdateVendors,
      callback);
};


/**
 * @param {!proto.vendormanagement.UpdateVendorsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.vendormanagement.Vendors>}
 *     Promise that resolves to the response
 */
proto.vendormanagement.VendorPromiseClient.prototype.updateVendors =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vendormanagement.Vendor/UpdateVendors',
      request,
      metadata || {},
      methodDescriptor_Vendor_UpdateVendors);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.vendormanagement.DeleteVendorRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_Vendor_DeleteVendor = new grpc.web.MethodDescriptor(
  '/vendormanagement.Vendor/DeleteVendor',
  grpc.web.MethodType.UNARY,
  proto.vendormanagement.DeleteVendorRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.vendormanagement.DeleteVendorRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.vendormanagement.DeleteVendorRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.vendormanagement.VendorClient.prototype.deleteVendor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/vendormanagement.Vendor/DeleteVendor',
      request,
      metadata || {},
      methodDescriptor_Vendor_DeleteVendor,
      callback);
};


/**
 * @param {!proto.vendormanagement.DeleteVendorRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.vendormanagement.VendorPromiseClient.prototype.deleteVendor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/vendormanagement.Vendor/DeleteVendor',
      request,
      metadata || {},
      methodDescriptor_Vendor_DeleteVendor);
};


module.exports = proto.vendormanagement;


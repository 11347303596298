import React, { FormEventHandler, useEffect, useMemo, useState } from 'react'
import log from 'loglevel'
import { Range } from 'react-date-range'
import { Control } from '@trustero/trustero-api-web/lib/model/control_pb'
import {
  useHideModal,
  useIsShowModal,
  useSetActiveModal,
} from 'src/Modal/ModalStateContext'
import { MdEditor } from 'src/components/Reusable/Text/Markup'
import { P } from 'src/pages/SecurityQuestionnaire/securityQuestionnaire.styles'
import { ModalForm, ModalFormId } from '../..'
import { Spinner } from '../../../../Throbber'
import { FieldLabel } from '../../ModalForm.styles'
import { TrusteroDateRange } from '../../../Reusable/TrusteroDateRange'
import { useAddOrUpdateDocumentRequest } from '../../../async/DocumentRequest/useDocumentRequests'
import { useControl } from '../../../async/model'
import { ControlsDropdown } from '../../Models/Dropdowns/ControlsDropdown/ControlsDropdown'
import { AddDocumentRequestFormState } from './AddDocumentRequestModal.constants'

type RequestFormData = {
  body: string
}

type AddDocumentRequestModalProps = {
  modelId?: string
}

export const AddDocumentRequestModal = ({
  modelId,
}: AddDocumentRequestModalProps): JSX.Element => {
  const { data: control } = useControl(modelId ?? '')
  const [validation, setValidation] = useState<AddDocumentRequestFormState>(
    AddDocumentRequestFormState.NOT_VALIDATED,
  )
  const [request, setRequest] = useState<RequestFormData>({
    body: '',
  })
  const [controls, setControls] = useState<Control[]>([])
  const [dateRange, setDateRange] = useState<Range>({
    startDate: undefined,
    endDate: undefined,
  })
  const [loading, setLoading] = useState(false)
  const onHide = () => {
    setValidation(AddDocumentRequestFormState.NOT_VALIDATED)
    setRequest({
      body: '',
    })
    setControls([])
    setDateRange({
      startDate: undefined,
      endDate: undefined,
    })
  }

  const isValid = request.body.length > 0 && request.body.length <= 1000

  const show = useIsShowModal(ModalFormId.ADD_DOCUMENT_REQUEST)
  const hide = useHideModal({
    modalId: ModalFormId.ADD_DOCUMENT_REQUEST,
    onHide,
  })
  const onBack = useSetActiveModal(ModalFormId.CHOOSE_REQUEST_UPLOAD_TYPE)

  useEffect(() => {
    if (!modelId || !control) {
      return
    }
    setControls([control])
  }, [control, modelId])

  const updateControls = (selectedControl: Control) => () => {
    setControls((state) => {
      const newState = [...state]
      const idx = newState.findIndex(
        (control) => control.getModelId() === selectedControl.getModelId(),
      )
      idx !== -1 ? newState.splice(idx, 1) : newState.push(selectedControl)
      return newState
    })
  }

  const errorMessage = useMemo(() => {
    setValidation(AddDocumentRequestFormState.NO_ERRORS)
    if (!request.body.length) {
      return 'Enter request'
    } else if (request.body.length > 1000) {
      return 'Enter a single request up to 1000 characters.'
    }
    return ''
  }, [request.body.length])

  const addDocumentRequest = useAddOrUpdateDocumentRequest()

  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    if (!isValid) {
      setValidation(AddDocumentRequestFormState.HAS_ERRORS)
      return
    }
    setValidation(AddDocumentRequestFormState.NO_ERRORS)

    const controlIds = controls.map((control) => control.getId())

    let dueDate
    if (dateRange.endDate) {
      dueDate = dateRange.endDate
    }

    try {
      setLoading(true)
      await addDocumentRequest({
        request: request.body,
        controlIds,
        dueDate,
      })
    } catch (e) {
      log.error('Error in AddDocumentRequestModal', e)
    } finally {
      await setLoading(false)
      hide()
    }
  }

  const updateRequest = (newRequest: string) => {
    setRequest((state) => ({ ...state, body: newRequest }))
  }

  return (
    <ModalForm
      show={show}
      hide={hide}
      formId={ModalFormId.ADD_DOCUMENT_REQUEST}
      size="xl"
      title="Add Document Request"
      description={
        'Enter a document request that you can assign to team members, map to your controls, and satisfy with evidence. The request will be specific to this audit, but evidence you add to controls will be visible generally.'
      }
      submitText="Add Request"
      enforceFocus={false}
      onBack={modelId ? undefined : onBack}
    >
      {loading ? (
        <Spinner color={'primary'} size={'m'} />
      ) : (
        <form id={ModalFormId.ADD_DOCUMENT_REQUEST} onSubmit={onSubmit}>
          <fieldset>
            <FieldLabel
              validateForm={
                validation === AddDocumentRequestFormState.HAS_ERRORS
              }
              isInvalid={!isValid}
              message={errorMessage}
            >
              <P>Request</P>
              <MdEditor value={request.body} onChange={updateRequest} />
            </FieldLabel>
          </fieldset>
          <fieldset>
            <FieldLabel>
              Linked Controls
              <ControlsDropdown
                selectedControls={controls}
                onControlSelected={updateControls}
                showCount={Boolean(controls.length)}
                placeholderText="Select controls that need this evidence, if you have them."
              />
            </FieldLabel>
          </fieldset>
          <fieldset>
            <FieldLabel>
              Due Date
              <br />
              <TrusteroDateRange
                single={true}
                range={dateRange}
                rangeSet={setDateRange}
              />
            </FieldLabel>
          </fieldset>
        </form>
      )}
    </ModalForm>
  )
}

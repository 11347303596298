import React, { useMemo } from 'react'
import { ListControlsRequest } from '@trustero/trustero-api-web/lib/model/control_pb'
import {
  HeaderValues,
  ParseColumnType,
  CSV_VALIDATIONS,
} from 'src/Utils/csv/csv.constants'
import { ModalFormId } from '../..'
import { UploadCSVModal } from '../../Reusable/UploadCSVModal'
import { useListControlIds } from '../../../async/model/control/useListControlIds'
import { useBulkRequestUpload } from '../../../async/DocumentRequest/'
import { CSV_UPLOAD_TYPES } from '../../Reusable/UploadCSVModal.constants'

export interface ImportRiskCSVRow {
  request: string
  dueDate: string
  controlIds: string[]
}

const requestCsvTemplate = [
  {
    Request: 'This is a sample request',
    'Due Date': '2021-01-01',
    'Control Ids': 'IM03, IM02',
  },
  {
    Request: 'This is a another sample request',
    'Due Date': '2023-01-01',
    'Control Ids': '',
  },
]

const REQUEST_CSV_HEADERS: HeaderValues[] = [
  {
    input: 'Request',
    output: 'request',
  },
  {
    input: 'Due Date',
    output: 'dueDate',
  },
  {
    input: 'Control Ids',
    output: 'controlIds',
  },
]

const getRequestParsers = (idsSet: Set<string>): ParseColumnType => ({
  request: {
    validator: (columnName, value, row) =>
      CSV_VALIDATIONS.LENGTH(columnName, value, 0, 1000, row),
    mutator: (value) => value.slice(0, 1000),
  },
  dueDate: {
    validator: (columnName, value, row) =>
      CSV_VALIDATIONS.DATE(columnName, value, row),
    mutator: (value) => value,
  },
  controlIds: {
    validator: (_, value, row) =>
      CSV_VALIDATIONS.ID_EXISTS(value, idsSet, row) ||
      CSV_VALIDATIONS.ID_EXISTS_ONCE(row, value),
    mutator: (value) => value.split(',').map((id) => id.trim()),
  },
})

export const AddRequestBulkUpload = (): JSX.Element => {
  const controlIdsRes = useListControlIds(new ListControlsRequest())
  const requestParsers = useMemo(
    () => getRequestParsers(new Set(controlIdsRes.data?.getItemList() || [])),
    [controlIdsRes.data],
  )
  const uploadBulkRequests = useBulkRequestUpload()
  if (controlIdsRes.loading) {
    return <></>
  }

  return (
    <UploadCSVModal<ImportRiskCSVRow>
      modalId={ModalFormId.ADD_REQUEST_BULK_UPLOAD}
      prevModalId={ModalFormId.CHOOSE_REQUEST_UPLOAD_TYPE}
      uploadItemsFunc={uploadBulkRequests}
      csvHeaders={REQUEST_CSV_HEADERS}
      csvColumnParsers={requestParsers}
      titleText="Upload Document Request List"
      description="Upload a CSV file of document requests. You can add them all at once, provided you follow this simple format."
      instruction="Your CSV should have one row for each request with the request, due
      date, and ID of one or more linked controls."
      formatSampleText="Request, Due Date (MM/DD/YYYY), Control Ids (separated by
        commas)"
      submitText="Upload Request List"
      csvTemplate={requestCsvTemplate}
      uploadType={CSV_UPLOAD_TYPES.REQUEST}
    />
  )
}

import React, { MouseEventHandler, useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import { margin } from 'styled-system'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { Dismissed } from '@trustero/trustero-api-web/lib/model/model_pb'
import {
  Identifier,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { StandardOpenModalButton } from 'src/components/ModalForms/ModalButtons'
import { useContent } from '../../../context/hooks'
import {
  StandardButtonSize,
  StandardButtonVariant,
} from '../../../components/Reusable/Buttons'
import { Grid, GridHeader, GridRow } from '../../../components/Reusable/Grid'
import { useAuthorizedGrpcClientWithContentUpdate } from '../../../adapter/grpcClient'
import { headerHeight } from '../../../components/PageLayout/dimensionUtils'
import { InfoLinkSection } from '../../../components/Reusable/Text/InfoLink.styles'
import { NoResults } from '../../../components/PageLayout/NoResults'
import { NoServicesIcons } from '../../../components/Icons/EmptyStateIcons/NoServicesIcons'
import { excludedServiceIds } from '../Services.constants'
import { ModalFormId } from '../../../components/ModalForms'
import { AddServiceForm } from '../../../Modal/Type/AddServices/AddServices'
import { ServiceType } from '../../../xgenerated/service'
import { AddCustomServiceModal } from '../../../Modal/Type/AddCustomService'
import { FilterBarContainer } from '../../../components/Reusable/IndexPage/FilterBar/FilterBar.styles'
import { ServiceItem } from './ServiceItem'

const ServicesPageHeader = styled.header.attrs({
  mb: 'm',
})`
  ${margin}
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const ADD_SERVICE_BUTTON_TEXT = 'Add Service'

export const ServicesPage = (): JSX.Element => {
  const { services } = useContent()
  const detectedServices = useMemo(
    () =>
      services
        .filter(
          (service) =>
            !service.dismissed && !excludedServiceIds.includes(service.id),
        )
        .sort((a, b) => a.name.localeCompare(b.name)),
    [services],
  )
  const servicesToAdd = useMemo(
    () =>
      services
        .filter(
          (service) =>
            service.dismissed && service.component_type === ServiceType.SaaS,
        )
        .filter((service) => !excludedServiceIds.includes(service.id))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [services],
  )

  const modelClientWithUpdate =
    useAuthorizedGrpcClientWithContentUpdate(ModelPromiseClient)
  const onDeleteService = useCallback<
    (serviceId: string) => MouseEventHandler<HTMLButtonElement>
  >(
    (serviceId) => (e) => {
      e.preventDefault()
      modelClientWithUpdate.setDismissed(
        new Dismissed()
          .setId(
            new Identifier()
              .setModeltype(MODEL_TYPE.SERVICE)
              .setModelid(serviceId),
          )
          .setDismissed(true),
      )
    },
    [modelClientWithUpdate],
  )

  return (
    <>
      <AddServiceForm services={servicesToAdd} />
      <AddCustomServiceModal />
      <ServicesPageHeader>
        <FilterBarContainer>
          <InfoLinkSection>
            Track which services your company uses.{' '}
          </InfoLinkSection>
          <StandardOpenModalButton
            modalId={ModalFormId.ADD_SERVICE}
            variant={StandardButtonVariant.PRIMARY}
            size={StandardButtonSize.SMALL}
            text={ADD_SERVICE_BUTTON_TEXT}
          />
        </FilterBarContainer>
      </ServicesPageHeader>
      {detectedServices.length ? (
        <Grid gridTemplateColumns="repeat(5, 1fr) max-content">
          <GridRow>
            <GridHeader position="sticky" top={headerHeight} zIndex={1}>
              Name
            </GridHeader>
            <GridHeader position="sticky" top={headerHeight} zIndex={1}>
              Category
            </GridHeader>
            <GridHeader
              position="sticky"
              top={headerHeight}
              zIndex={1}
              justifyContent="center"
            >
              Affects Product Delivery
            </GridHeader>
            <GridHeader
              position="sticky"
              top={headerHeight}
              zIndex={1}
              justifyContent="center"
            >
              Contains Customer Data
            </GridHeader>
            <GridHeader
              position="sticky"
              top={headerHeight}
              zIndex={1}
              justifyContent="center"
            >
              Supporting Tool
            </GridHeader>
            <GridHeader
              position="sticky"
              top={headerHeight}
              zIndex={1}
              justifyContent="center"
            />
          </GridRow>
          {detectedServices.map((serviceTemplate) => {
            return (
              <ServiceItem
                key={serviceTemplate.id}
                serviceTemplate={serviceTemplate}
                onDeleteService={onDeleteService(serviceTemplate.id)}
              />
            )
          })}
        </Grid>
      ) : (
        <NoResults
          icons={<NoServicesIcons />}
          title="Mark which services you use."
          buttons={
            <StandardOpenModalButton
              variant={StandardButtonVariant.PRIMARY}
              size={StandardButtonSize.MEDIUM}
              modalId={ModalFormId.ADD_SERVICE}
              text={ADD_SERVICE_BUTTON_TEXT}
            />
          }
          largeTitle={true}
        />
      )}
    </>
  )
}

import React, { lazy, Suspense, useMemo } from 'react'
import ContentLoader from 'react-content-loader'
import { ErrorBoundary } from 'react-error-boundary'
import { useAuth } from '../context/authContext'
import { AppUnauth } from './AppUnauth'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

type ErrorProps = {
  error: Error
}

const ErrorFallback = (props: ErrorProps): JSX.Element => {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{props.error.message}</pre>
    </div>
  )
}

export const App = (): JSX.Element => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AppChooser />
    </ErrorBoundary>
  )
}

const AppChooser = () => {
  const AppAuth = useMemo(() => lazy(() => import('./AppAuth')), [])

  const { accountId, isAuthenticated } = useAuth()
  if (!isAuthenticated || !accountId) {
    return <AppUnauth />
  }

  return (
    <Suspense fallback={<ContentLoader />}>
      <AppAuth accountId={accountId} />
    </Suspense>
  )
}

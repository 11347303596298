import { Identifier } from '@trustero/trustero-api-web/lib/common/model_pb'
import {
  SuggestedAutomatedEvidence,
  SuggestedDLRReceptorRequest,
  SuggestedReceptors,
} from '@trustero/trustero-api-web/lib/recommend/recommend_pb'
import { RecommendPromiseClient } from '@trustero/trustero-api-web/lib/recommend/recommend_grpc_web_pb'
import { GrpcResponse, NewGrpcResponse } from '../hooks/types'
import { useSwrImmutableGrpc } from '../useSwrImmutableGrpc'

export const useSuggestedReceptors = (
  request: Identifier,
  shouldFetch = true,
): GrpcResponse<SuggestedReceptors> => {
  const { response } = useSwrImmutableGrpc(
    RecommendPromiseClient.prototype.getSuggestedReceptor,
    request,
    shouldFetch,
  )
  return NewGrpcResponse<SuggestedReceptors>(response)
}

export const useSuggestedDLRReceptor = (
  request: string,
  shouldFetch = true,
): GrpcResponse<SuggestedAutomatedEvidence> => {
  const { response } = useSwrImmutableGrpc(
    RecommendPromiseClient.prototype.getSuggestedReceptorForDLR,
    new SuggestedDLRReceptorRequest().setId(request),
    shouldFetch,
  )
  return NewGrpcResponse<SuggestedAutomatedEvidence>(response)
}

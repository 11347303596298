import React, { useMemo } from 'react'
import queryString, { ParsedQuery } from 'query-string'
import { useLocation, useNavigate } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import {
  UserRecord,
  UserState,
} from '@trustero/trustero-api-web/lib/account/account_pb'
import { PERMISSIONS } from 'src/config/roleConfig'
import { useUsers } from '../../../../async/Users'
import { FilterName, FilterParam } from '../FilterBar.types'
import { Spinner } from '../../../../../Throbber'
import { CountChip } from '../../../Chips/BasicChip'
import { CheckBoxInput } from '../../../Inputs/CheckBox'
import { MenuPlaceholder } from '../../../../Placeholders/MenuPlaceholder'
import { FilterDropdownItem, FilterDropdownToggle } from '../FilterBar.styles'
import { Assignee } from '../../../../../Utils/globalEnums'

const OwnerItem = ({
  name,
  email,
  id,
  searchParams,
  appliedFilters,
}: {
  name?: string
  email: string
  id: string
  searchParams: ParsedQuery
  appliedFilters: string[]
}): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()
  const isRequestsPage = location.pathname.includes('requests')
  const userArg = isRequestsPage ? id : `${email?.toLowerCase()}`
  const newAppliedFilters = new Set(appliedFilters.map((f) => f.toLowerCase()))
  const isChecked = newAppliedFilters.has(userArg)
  isChecked ? newAppliedFilters.delete(userArg) : newAppliedFilters.add(userArg)

  const href = queryString.stringifyUrl(
    {
      url: location.pathname,
      query: {
        ...searchParams,
        [FilterParam.OWNER]: Array.from(newAppliedFilters),
      },
    },
    { arrayFormat: 'bracket' },
  )

  return (
    <FilterDropdownItem replace to={href}>
      <CheckBoxInput
        id={email}
        label={name || email}
        checked={isChecked}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onChange={() => {
          navigate(href, { replace: true })
        }}
        tabIndex={-1}
        requiredPermissions={[PERMISSIONS.READ]}
      />
    </FilterDropdownItem>
  )
}

export const OwnerFilterDropdown = (): JSX.Element => {
  const location = useLocation()
  const userResp = useUsers()

  const searchParams = useMemo(() => {
    return queryString.parse(location.search, {
      arrayFormat: 'bracket',
    })
  }, [location])

  const appliedFilters = useMemo(() => {
    const preprocessed = searchParams[FilterParam.OWNER]
    return Array.isArray(preprocessed)
      ? preprocessed
      : preprocessed
      ? [preprocessed]
      : []
  }, [searchParams])

  if (userResp.error) return <></>
  if (!userResp.data || userResp.isLoading) {
    return (
      <>
        <Dropdown.Toggle
          as={FilterDropdownToggle}
          id={`${FilterName.OWNER}-filter-dropdown`}
        >
          <p>{FilterName.OWNER}</p>
          <Spinner size="s" color="primary" mx="xxs" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <MenuPlaceholder height={108} />
        </Dropdown.Menu>
      </>
    )
  }
  const users = userResp.data
    .getUsersList()
    .filter((user) => user.getState() === UserState.USER_ACTIVE)

  return (
    <Dropdown autoClose="outside">
      <Dropdown.Toggle
        as={FilterDropdownToggle}
        id={`${FilterName.OWNER}-filter-dropdown`}
      >
        <p>{FilterName.OWNER}</p>
        {appliedFilters.length > 0 && (
          <CountChip ml="xxs" color="white" bg="fill.tertiary.dark">
            {appliedFilters.length}
          </CountChip>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <OwnerItem
          key={Assignee.UNASSIGNED}
          name={Assignee.UNASSIGNED}
          email={Assignee.UNASSIGNED_BE}
          id={Assignee.UNASSIGNED_BE}
          searchParams={searchParams}
          appliedFilters={appliedFilters}
        />
        {users.map((user: UserRecord) => {
          return (
            <OwnerItem
              key={user.getEmail()}
              name={user.getName()}
              email={user.getEmail()}
              id={user.getId()}
              searchParams={searchParams}
              appliedFilters={appliedFilters}
            />
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

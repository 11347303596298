import { EroDarkEastSVG, EroDarkSVG } from 'src/components/Icons/Brand'
import { StandardButton } from 'src/components/Reusable/Buttons'
import palette from 'src/designSystem/variables/palette'
import styled from 'styled-components/macro'

export const GuidanceAICopilotIcon = styled(EroDarkSVG)`
  height: 24px;
  width: 24px;
`

export const AICopilotLoadingIcon = styled(EroDarkEastSVG)`
  height: 24px;
  width: 24px;
  flex-shrink: 0;
`

export const GuidanceAICopilotIconEast = styled(EroDarkEastSVG)`
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  margin-top: 8px;
`

export const RestoreGuidanceContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  align-items: center;
`

export const AnswerAgainContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const AnswerAgainButton = styled(StandardButton)`
  color: ${palette.purple[600]};
  border-color: ${palette.purple[600]};
  > svg {
    color: ${palette.purple[600]};
  }
`

export const AICopilotGuidanceContainer = styled.div`
  font-size: 14px;
  display: flex;
  font-style: italic;
  gap: 8px;
`

export const GuidanceLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
`

export const RegularGuidanceLoadingContainer = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
`

export const GuidanceLoadingBeaker = styled.img`
  height: 64px;
  width: 64px;
  margin-bottom: 32px;
`

export const EditOnClickContainer = styled.div`
  width: 100%;
`

import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useMemo,
} from 'react'
import {
  HasCustomerData,
  ModelRecord,
  ProductDelivery,
  SupportingTool,
} from '@trustero/trustero-api-web/lib/model/model_pb'
import {
  Identifier,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { KeyedMutator } from 'swr'
import { Service } from '../../../xgenerated'
import { useSwrImmutableGrpc } from '../../../components/async/useSwrImmutableGrpc'
import { GridColumn, GridRow } from '../../../components/Reusable/Grid'
import { Spinner } from '../../../Throbber'
import {
  IconButton,
  IconButtonVariant,
} from '../../../components/Reusable/Buttons'
import { ReactComponent as DeleteIcon } from '../../../components/Icons/assets/delete-icon.svg'
import { useAuthorizedGrpcClient } from '../../../adapter'
import { CheckBoxInput } from '../../../components/Reusable/Inputs/CheckBox'

/**
 * Renders three `<GridColumn>`s, each with a `<Spinner>` component
 * - Should only be used while the Service is being fetched
 */
function CheckBoxPlaceholders(): JSX.Element {
  return (
    <>
      <GridColumn justifyContent="center">
        <Spinner color="primary" size="s" />
      </GridColumn>
      <GridColumn justifyContent="center">
        <Spinner color="primary" size="s" />
      </GridColumn>
      <GridColumn justifyContent="center">
        <Spinner color="primary" size="s" />
      </GridColumn>
    </>
  )
}

/**
 * Renders three `<GridColumn>`s, each with a `<CheckBoxInput>` for
 * - Product Delivery
 * - Has Customer Data
 * - Supporting Tool
 */
function CheckBoxes({
  service,
  mutate,
}: {
  service: ModelRecord
  mutate: KeyedMutator<ModelRecord>
}): JSX.Element {
  const modelClient = useAuthorizedGrpcClient(ModelPromiseClient)

  const onChangeProductDelivery = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(async () => {
    const request = new ProductDelivery()
      .setId(
        new Identifier()
          .setModelid(service.getModelid())
          .setModeltype(MODEL_TYPE.SERVICE),
      )
      .setProductDelivery(!service.getProductdelivery())
    await modelClient.setProductDelivery(request)
    await mutate()
  }, [service, mutate, modelClient])

  const onChangeCustomerData = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(async () => {
    const request = new HasCustomerData()
      .setId(
        new Identifier()
          .setModelid(service.getModelid())
          .setModeltype(MODEL_TYPE.SERVICE),
      )
      .setHasCustomerData(!service.getHascustomerdata())
    await modelClient.setHasCustomerData(request)
    await mutate()
  }, [service, mutate, modelClient])

  const onChangeSupportingTool = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(async () => {
    const request = new SupportingTool()
      .setId(
        new Identifier()
          .setModelid(service.getModelid())
          .setModeltype(MODEL_TYPE.SERVICE),
      )
      .setSupportingTool(!service.getSupportingtool())
    await modelClient.setSupportingTool(request)
    await mutate()
  }, [service, mutate, modelClient])

  return (
    <>
      <GridColumn justifyContent="center">
        <CheckBoxInput
          checked={service.getProductdelivery()}
          onChange={onChangeProductDelivery}
        />
      </GridColumn>
      <GridColumn justifyContent="center">
        <CheckBoxInput
          checked={service.getHascustomerdata()}
          onChange={onChangeCustomerData}
        />
      </GridColumn>
      <GridColumn justifyContent="center">
        <CheckBoxInput
          checked={service.getSupportingtool()}
          onChange={onChangeSupportingTool}
        />
      </GridColumn>
    </>
  )
}

/**
 * Renders a `<GridRow>` component containing details of the Service
 *
 * Should only be used as a child of
 * - The `<Grid>` rendered within the `<ServicesPage>`
 */
export function ServiceItem({
  serviceTemplate,
  onDeleteService,
}: {
  serviceTemplate: Service
  onDeleteService: MouseEventHandler<HTMLButtonElement>
}): JSX.Element {
  const request = useMemo(
    () =>
      new ModelRecord()
        .setModelid(serviceTemplate.id)
        .setModeltype(MODEL_TYPE.SERVICE),
    [serviceTemplate.id],
  )
  const { response } = useSwrImmutableGrpc(
    ModelPromiseClient.prototype.getOrCreate,
    request,
  )

  return (
    <GridRow id={serviceTemplate.id}>
      <GridColumn>
        {serviceTemplate.logo ? (
          <serviceTemplate.logo width="40px" height="40px" />
        ) : (
          <></>
        )}
        <p style={{ marginLeft: '8px' }}>{serviceTemplate.name}</p>
      </GridColumn>
      <GridColumn>{serviceTemplate.component_category}</GridColumn>
      {response.data ? (
        <CheckBoxes service={response.data} mutate={response.mutate} />
      ) : (
        <CheckBoxPlaceholders />
      )}
      <GridColumn justifyContent="center">
        <IconButton variant={IconButtonVariant.icon} onClick={onDeleteService}>
          <DeleteIcon />
        </IconButton>
      </GridColumn>
    </GridRow>
  )
}

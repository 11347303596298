/**
 * @fileoverview gRPC-Web generated client stub for questionnaire
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: questionnaire/questionnaire.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var common_model_pb = require('../common/model_pb.js')
const proto = {};
proto.questionnaire = require('./questionnaire_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.questionnaire.QuestionnaireServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.questionnaire.QuestionnaireServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.questionnaire.GetQuestionnairesResponse>}
 */
const methodDescriptor_QuestionnaireService_GetQuestionnaires = new grpc.web.MethodDescriptor(
  '/questionnaire.QuestionnaireService/GetQuestionnaires',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.questionnaire.GetQuestionnairesResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.questionnaire.GetQuestionnairesResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.questionnaire.GetQuestionnairesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.questionnaire.GetQuestionnairesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.questionnaire.QuestionnaireServiceClient.prototype.getQuestionnaires =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/GetQuestionnaires',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_GetQuestionnaires,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.questionnaire.GetQuestionnairesResponse>}
 *     Promise that resolves to the response
 */
proto.questionnaire.QuestionnaireServicePromiseClient.prototype.getQuestionnaires =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/GetQuestionnaires',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_GetQuestionnaires);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.questionnaire.GetQuestionnaireRequest,
 *   !proto.questionnaire.Questionnaire>}
 */
const methodDescriptor_QuestionnaireService_GetQuestionnaire = new grpc.web.MethodDescriptor(
  '/questionnaire.QuestionnaireService/GetQuestionnaire',
  grpc.web.MethodType.UNARY,
  proto.questionnaire.GetQuestionnaireRequest,
  proto.questionnaire.Questionnaire,
  /**
   * @param {!proto.questionnaire.GetQuestionnaireRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.questionnaire.Questionnaire.deserializeBinary
);


/**
 * @param {!proto.questionnaire.GetQuestionnaireRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.questionnaire.Questionnaire)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.questionnaire.Questionnaire>|undefined}
 *     The XHR Node Readable Stream
 */
proto.questionnaire.QuestionnaireServiceClient.prototype.getQuestionnaire =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/GetQuestionnaire',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_GetQuestionnaire,
      callback);
};


/**
 * @param {!proto.questionnaire.GetQuestionnaireRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.questionnaire.Questionnaire>}
 *     Promise that resolves to the response
 */
proto.questionnaire.QuestionnaireServicePromiseClient.prototype.getQuestionnaire =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/GetQuestionnaire',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_GetQuestionnaire);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.questionnaire.GetKnowledgeBasesResponse>}
 */
const methodDescriptor_QuestionnaireService_GetKnowledgeBases = new grpc.web.MethodDescriptor(
  '/questionnaire.QuestionnaireService/GetKnowledgeBases',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.questionnaire.GetKnowledgeBasesResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.questionnaire.GetKnowledgeBasesResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.questionnaire.GetKnowledgeBasesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.questionnaire.GetKnowledgeBasesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.questionnaire.QuestionnaireServiceClient.prototype.getKnowledgeBases =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/GetKnowledgeBases',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_GetKnowledgeBases,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.questionnaire.GetKnowledgeBasesResponse>}
 *     Promise that resolves to the response
 */
proto.questionnaire.QuestionnaireServicePromiseClient.prototype.getKnowledgeBases =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/GetKnowledgeBases',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_GetKnowledgeBases);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.questionnaire.UploadQuestionnaireRequest,
 *   !proto.questionnaire.UploadQuestionnaireResponse>}
 */
const methodDescriptor_QuestionnaireService_UploadQuestionnaire = new grpc.web.MethodDescriptor(
  '/questionnaire.QuestionnaireService/UploadQuestionnaire',
  grpc.web.MethodType.UNARY,
  proto.questionnaire.UploadQuestionnaireRequest,
  proto.questionnaire.UploadQuestionnaireResponse,
  /**
   * @param {!proto.questionnaire.UploadQuestionnaireRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.questionnaire.UploadQuestionnaireResponse.deserializeBinary
);


/**
 * @param {!proto.questionnaire.UploadQuestionnaireRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.questionnaire.UploadQuestionnaireResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.questionnaire.UploadQuestionnaireResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.questionnaire.QuestionnaireServiceClient.prototype.uploadQuestionnaire =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/UploadQuestionnaire',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_UploadQuestionnaire,
      callback);
};


/**
 * @param {!proto.questionnaire.UploadQuestionnaireRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.questionnaire.UploadQuestionnaireResponse>}
 *     Promise that resolves to the response
 */
proto.questionnaire.QuestionnaireServicePromiseClient.prototype.uploadQuestionnaire =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/UploadQuestionnaire',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_UploadQuestionnaire);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.questionnaire.Questionnaire,
 *   !proto.questionnaire.QuestionnaireIdResponse>}
 */
const methodDescriptor_QuestionnaireService_UpdateQuestionnaire = new grpc.web.MethodDescriptor(
  '/questionnaire.QuestionnaireService/UpdateQuestionnaire',
  grpc.web.MethodType.UNARY,
  proto.questionnaire.Questionnaire,
  proto.questionnaire.QuestionnaireIdResponse,
  /**
   * @param {!proto.questionnaire.Questionnaire} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.questionnaire.QuestionnaireIdResponse.deserializeBinary
);


/**
 * @param {!proto.questionnaire.Questionnaire} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.questionnaire.QuestionnaireIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.questionnaire.QuestionnaireIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.questionnaire.QuestionnaireServiceClient.prototype.updateQuestionnaire =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/UpdateQuestionnaire',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_UpdateQuestionnaire,
      callback);
};


/**
 * @param {!proto.questionnaire.Questionnaire} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.questionnaire.QuestionnaireIdResponse>}
 *     Promise that resolves to the response
 */
proto.questionnaire.QuestionnaireServicePromiseClient.prototype.updateQuestionnaire =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/UpdateQuestionnaire',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_UpdateQuestionnaire);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.questionnaire.CreateOrUpdateAnswerRequest,
 *   !proto.questionnaire.AnswerIdResponse>}
 */
const methodDescriptor_QuestionnaireService_CreateOrUpdateAnswer = new grpc.web.MethodDescriptor(
  '/questionnaire.QuestionnaireService/CreateOrUpdateAnswer',
  grpc.web.MethodType.UNARY,
  proto.questionnaire.CreateOrUpdateAnswerRequest,
  proto.questionnaire.AnswerIdResponse,
  /**
   * @param {!proto.questionnaire.CreateOrUpdateAnswerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.questionnaire.AnswerIdResponse.deserializeBinary
);


/**
 * @param {!proto.questionnaire.CreateOrUpdateAnswerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.questionnaire.AnswerIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.questionnaire.AnswerIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.questionnaire.QuestionnaireServiceClient.prototype.createOrUpdateAnswer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/CreateOrUpdateAnswer',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_CreateOrUpdateAnswer,
      callback);
};


/**
 * @param {!proto.questionnaire.CreateOrUpdateAnswerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.questionnaire.AnswerIdResponse>}
 *     Promise that resolves to the response
 */
proto.questionnaire.QuestionnaireServicePromiseClient.prototype.createOrUpdateAnswer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/CreateOrUpdateAnswer',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_CreateOrUpdateAnswer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.questionnaire.UploadKnowledgeBaseRequest,
 *   !proto.questionnaire.UploadQuestionnaireResponse>}
 */
const methodDescriptor_QuestionnaireService_UploadKnowledgeBase = new grpc.web.MethodDescriptor(
  '/questionnaire.QuestionnaireService/UploadKnowledgeBase',
  grpc.web.MethodType.UNARY,
  proto.questionnaire.UploadKnowledgeBaseRequest,
  proto.questionnaire.UploadQuestionnaireResponse,
  /**
   * @param {!proto.questionnaire.UploadKnowledgeBaseRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.questionnaire.UploadQuestionnaireResponse.deserializeBinary
);


/**
 * @param {!proto.questionnaire.UploadKnowledgeBaseRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.questionnaire.UploadQuestionnaireResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.questionnaire.UploadQuestionnaireResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.questionnaire.QuestionnaireServiceClient.prototype.uploadKnowledgeBase =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/UploadKnowledgeBase',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_UploadKnowledgeBase,
      callback);
};


/**
 * @param {!proto.questionnaire.UploadKnowledgeBaseRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.questionnaire.UploadQuestionnaireResponse>}
 *     Promise that resolves to the response
 */
proto.questionnaire.QuestionnaireServicePromiseClient.prototype.uploadKnowledgeBase =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/UploadKnowledgeBase',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_UploadKnowledgeBase);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.questionnaire.AddSqToKbRequest,
 *   !proto.questionnaire.UploadQuestionnaireResponse>}
 */
const methodDescriptor_QuestionnaireService_AddQuestionnaireToKnowledgeBase = new grpc.web.MethodDescriptor(
  '/questionnaire.QuestionnaireService/AddQuestionnaireToKnowledgeBase',
  grpc.web.MethodType.UNARY,
  proto.questionnaire.AddSqToKbRequest,
  proto.questionnaire.UploadQuestionnaireResponse,
  /**
   * @param {!proto.questionnaire.AddSqToKbRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.questionnaire.UploadQuestionnaireResponse.deserializeBinary
);


/**
 * @param {!proto.questionnaire.AddSqToKbRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.questionnaire.UploadQuestionnaireResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.questionnaire.UploadQuestionnaireResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.questionnaire.QuestionnaireServiceClient.prototype.addQuestionnaireToKnowledgeBase =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/AddQuestionnaireToKnowledgeBase',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_AddQuestionnaireToKnowledgeBase,
      callback);
};


/**
 * @param {!proto.questionnaire.AddSqToKbRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.questionnaire.UploadQuestionnaireResponse>}
 *     Promise that resolves to the response
 */
proto.questionnaire.QuestionnaireServicePromiseClient.prototype.addQuestionnaireToKnowledgeBase =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/AddQuestionnaireToKnowledgeBase',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_AddQuestionnaireToKnowledgeBase);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.questionnaire.AnswerAgainRequest,
 *   !proto.questionnaire.AnswerAgainResponse>}
 */
const methodDescriptor_QuestionnaireService_AnswerQuestionAgain = new grpc.web.MethodDescriptor(
  '/questionnaire.QuestionnaireService/AnswerQuestionAgain',
  grpc.web.MethodType.UNARY,
  proto.questionnaire.AnswerAgainRequest,
  proto.questionnaire.AnswerAgainResponse,
  /**
   * @param {!proto.questionnaire.AnswerAgainRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.questionnaire.AnswerAgainResponse.deserializeBinary
);


/**
 * @param {!proto.questionnaire.AnswerAgainRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.questionnaire.AnswerAgainResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.questionnaire.AnswerAgainResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.questionnaire.QuestionnaireServiceClient.prototype.answerQuestionAgain =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/AnswerQuestionAgain',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_AnswerQuestionAgain,
      callback);
};


/**
 * @param {!proto.questionnaire.AnswerAgainRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.questionnaire.AnswerAgainResponse>}
 *     Promise that resolves to the response
 */
proto.questionnaire.QuestionnaireServicePromiseClient.prototype.answerQuestionAgain =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/AnswerQuestionAgain',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_AnswerQuestionAgain);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.questionnaire.DeleteQuestionnaireRequest,
 *   !proto.questionnaire.QuestionnaireIdResponse>}
 */
const methodDescriptor_QuestionnaireService_DeleteQuestionnaire = new grpc.web.MethodDescriptor(
  '/questionnaire.QuestionnaireService/DeleteQuestionnaire',
  grpc.web.MethodType.UNARY,
  proto.questionnaire.DeleteQuestionnaireRequest,
  proto.questionnaire.QuestionnaireIdResponse,
  /**
   * @param {!proto.questionnaire.DeleteQuestionnaireRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.questionnaire.QuestionnaireIdResponse.deserializeBinary
);


/**
 * @param {!proto.questionnaire.DeleteQuestionnaireRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.questionnaire.QuestionnaireIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.questionnaire.QuestionnaireIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.questionnaire.QuestionnaireServiceClient.prototype.deleteQuestionnaire =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/DeleteQuestionnaire',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_DeleteQuestionnaire,
      callback);
};


/**
 * @param {!proto.questionnaire.DeleteQuestionnaireRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.questionnaire.QuestionnaireIdResponse>}
 *     Promise that resolves to the response
 */
proto.questionnaire.QuestionnaireServicePromiseClient.prototype.deleteQuestionnaire =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/DeleteQuestionnaire',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_DeleteQuestionnaire);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.questionnaire.DeleteQuestionnaireRequest,
 *   !proto.questionnaire.QuestionnaireIdResponse>}
 */
const methodDescriptor_QuestionnaireService_DeleteKnowledgeBase = new grpc.web.MethodDescriptor(
  '/questionnaire.QuestionnaireService/DeleteKnowledgeBase',
  grpc.web.MethodType.UNARY,
  proto.questionnaire.DeleteQuestionnaireRequest,
  proto.questionnaire.QuestionnaireIdResponse,
  /**
   * @param {!proto.questionnaire.DeleteQuestionnaireRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.questionnaire.QuestionnaireIdResponse.deserializeBinary
);


/**
 * @param {!proto.questionnaire.DeleteQuestionnaireRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.questionnaire.QuestionnaireIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.questionnaire.QuestionnaireIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.questionnaire.QuestionnaireServiceClient.prototype.deleteKnowledgeBase =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/DeleteKnowledgeBase',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_DeleteKnowledgeBase,
      callback);
};


/**
 * @param {!proto.questionnaire.DeleteQuestionnaireRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.questionnaire.QuestionnaireIdResponse>}
 *     Promise that resolves to the response
 */
proto.questionnaire.QuestionnaireServicePromiseClient.prototype.deleteKnowledgeBase =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/DeleteKnowledgeBase',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_DeleteKnowledgeBase);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.questionnaire.ValidateAccountBalanceRequest,
 *   !proto.questionnaire.ValidateAccountBalanceResponse>}
 */
const methodDescriptor_QuestionnaireService_ValidateAccountBalance = new grpc.web.MethodDescriptor(
  '/questionnaire.QuestionnaireService/ValidateAccountBalance',
  grpc.web.MethodType.UNARY,
  proto.questionnaire.ValidateAccountBalanceRequest,
  proto.questionnaire.ValidateAccountBalanceResponse,
  /**
   * @param {!proto.questionnaire.ValidateAccountBalanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.questionnaire.ValidateAccountBalanceResponse.deserializeBinary
);


/**
 * @param {!proto.questionnaire.ValidateAccountBalanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.questionnaire.ValidateAccountBalanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.questionnaire.ValidateAccountBalanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.questionnaire.QuestionnaireServiceClient.prototype.validateAccountBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/ValidateAccountBalance',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_ValidateAccountBalance,
      callback);
};


/**
 * @param {!proto.questionnaire.ValidateAccountBalanceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.questionnaire.ValidateAccountBalanceResponse>}
 *     Promise that resolves to the response
 */
proto.questionnaire.QuestionnaireServicePromiseClient.prototype.validateAccountBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/ValidateAccountBalance',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_ValidateAccountBalance);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.questionnaire.GetAccountBalanceResponse>}
 */
const methodDescriptor_QuestionnaireService_GetAccountBalance = new grpc.web.MethodDescriptor(
  '/questionnaire.QuestionnaireService/GetAccountBalance',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.questionnaire.GetAccountBalanceResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.questionnaire.GetAccountBalanceResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.questionnaire.GetAccountBalanceResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.questionnaire.GetAccountBalanceResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.questionnaire.QuestionnaireServiceClient.prototype.getAccountBalance =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/GetAccountBalance',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_GetAccountBalance,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.questionnaire.GetAccountBalanceResponse>}
 *     Promise that resolves to the response
 */
proto.questionnaire.QuestionnaireServicePromiseClient.prototype.getAccountBalance =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/questionnaire.QuestionnaireService/GetAccountBalance',
      request,
      metadata || {},
      methodDescriptor_QuestionnaireService_GetAccountBalance);
};


module.exports = proto.questionnaire;


import styled from 'styled-components/macro'
import { MenuPlaceholder } from '../../../../Placeholders/MenuPlaceholder'
import { CountChip } from '../../../../Reusable/Chips/BasicChip'
import palette from '../../../../../designSystem/variables/palette'
import { StyledDropdownToggle } from '../../../../Reusable/StandardDropdown/StandardDropdown.styles'

export const ControlsDropdownMenuPlaceholder = styled(MenuPlaceholder)`
  height= 96px;
`

export const ControlsDropdownToggle = styled(StyledDropdownToggle)`
  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`

export const ControlsDropdownDiv = styled.div`
  > button {
    width: 100%;
  }
`

export const ControlsDropdownCountChip = styled(CountChip)`
  margin-left: 4px;
  color: white;
  background-color: ${palette.green[600]};
`

import styled from 'styled-components/macro'
import palette from '../../../../designSystem/variables/palette'
import { TypeRow } from '../../../ModalForms/FileType/TypeSelector.styles'
import { ModalDescription } from '../../../ModalForms/ModalForm.styles'

export const UploadCSVTypeRow = styled(TypeRow)<{ isBulkUpload: boolean }>`
  height: ${(p) => (p.isBulkUpload ? '175px' : '240px')};
`

export const UploadCSVModalDescription = styled(ModalDescription)`
  color: ${palette.neutral['600']};
`

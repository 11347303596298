import React from 'react'
import { useSetActiveModal } from 'src/Modal/ModalStateContext'
import { useCurrentReceptor } from 'src/context/FormContext/CurrentReceptorContext'
import { ModalFormId } from '../..'
import { Receptor } from '../../../../xgenerated'
import {
  ReceptorCardContainer,
  ReceptorLogo,
  ReceptorCategory,
  ReceptorName,
} from './ReceptorCard.styles'

export const ReceptorCard = ({
  template,
}: {
  template: Receptor
}): JSX.Element => {
  const { setReceptor } = useCurrentReceptor()
  const openActivateReceptorModal = useSetActiveModal(
    ModalFormId.ACTIVATE_RECEPTOR,
  )

  const handleClick = () => {
    openActivateReceptorModal()
    setReceptor(template)
  }

  return (
    <ReceptorCardContainer onClick={handleClick}>
      <ReceptorLogo>
        <template.logo width="44px" height="44px" />
      </ReceptorLogo>
      <ReceptorName>{template.name.replace(' Receptor', '')}</ReceptorName>
      <ReceptorCategory>{template.category}</ReceptorCategory>
    </ReceptorCardContainer>
  )
}

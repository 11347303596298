import React from 'react'
import { List } from 'react-content-loader'
import { AuditRecord } from '@trustero/trustero-api-web/lib/audit/audit_pb'
import { FlexRow } from 'src/components/Reusable/Flex'
import { Grid } from '../../../../components/Reusable/Grid'
import { ControlResponsibilityModal } from '../../modals/ControlResponsibilityModal'
import { ControlListItemConfig } from '../../IndexItem/ControlListItem.constants'
import { getControlsListItemConfig } from '../../IndexItem/ControlsListItem.helpers'
import { ControlsIndexGridHeader } from './ControlsIndexGridHeader'
import {
  ControlGridRows,
  getControlsGridEmptyState,
} from './ControlsIndexGrid.helpers'

interface ControlsIndexGridProps {
  controlIds: string[]
  isLoading?: boolean
  audit?: AuditRecord
  config?: ControlListItemConfig
}

export const ControlsIndexGrid = ({
  audit,
  controlIds,
  isLoading,
  config,
}: ControlsIndexGridProps): JSX.Element => {
  if (isLoading) {
    return (
      <FlexRow $mt={40}>
        <List />
      </FlexRow>
    )
  }

  const controlGridEmptyState = getControlsGridEmptyState()

  const { template } = getControlsListItemConfig(config)

  return !controlIds?.length ? (
    <>{controlGridEmptyState}</>
  ) : (
    <>
      <Grid pb="xs" gridTemplateColumns={template}>
        <ControlsIndexGridHeader config={config} />
        <ControlGridRows modelIds={controlIds} audit={audit} config={config} />
      </Grid>
      {/* Modals */}
      <ControlResponsibilityModal />
    </>
  )
}

import React from 'react'
import { DocumentRequest } from '@trustero/trustero-api-web/lib/request/request_pb'
import { Grid } from '../../../components/Reusable/Grid'
import { NoFilterResults } from '../../../components/PageLayout/NoFilterResults'
import { RequestsIndexGridHeader } from './RequestsIndexGridHeader'
import { RequestsIndexGridRows } from './RequestsIndexGridRows'
import { REQUEST_GRID_LOCATIONS } from './RequestsIndexPage.constants'

type RequestsIndexGridProps = {
  requests: DocumentRequest[]
  location: REQUEST_GRID_LOCATIONS
  stickyHeader?: boolean
}

export const RequestsIndexGrid = ({
  requests,
  location,
  stickyHeader = false,
}: RequestsIndexGridProps): JSX.Element => {
  const fromControlShowPage = location === REQUEST_GRID_LOCATIONS.CONTROLS_SHOW
  const numAutoColumns = fromControlShowPage ? 5 : 6

  if (requests.length === 0) {
    return <NoFilterResults />
  }

  return (
    <Grid
      gridTemplateColumns={`min-content 1fr repeat(${numAutoColumns}, auto)`}
      gridRowGap="8px"
    >
      <RequestsIndexGridHeader
        requests={requests}
        location={location}
        stickyHeader={stickyHeader}
      />
      <RequestsIndexGridRows
        requests={requests}
        fromControlShowPage={fromControlShowPage}
      />
    </Grid>
  )
}

/* eslint-disable @typescript-eslint/no-explicit-any */
declare let google: any
declare let window: any
import log from 'loglevel'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useGrpcRevalidateByMethod } from 'src/components'
import { useCurrentReceptor } from 'src/context/FormContext/CurrentReceptorContext'
import { useGetLinker } from 'src/pages/Receptors/receptors.hooks'
import { useModalState } from 'src/Modal/ModalStateContext'
import { ModalFormId, useConfirmationModal } from 'src/components/ModalForms'
import { useAuthorizedGrpcClient } from 'src/adapter'
import { LinkerPromiseClient } from '@trustero/trustero-api-web/lib/linker/linker_grpc_web_pb'
import { DisconnectLinkerRequest } from '@trustero/trustero-api-web/lib/linker/linker_pb'
import {
  InjectorState,
  LINKER_MODEL_IDS,
  PickerConfiguration,
  PickerResponse,
  StateType,
} from './Linkers.constants'
import { getLinkerTemplate } from './Linkers.helpers'

const injectorState: InjectorState = {
  queue: {},
  injectorMap: {},
  scriptMap: {},
}

export const useInvalidateLinkersCache = (): (() => void) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await mutateFunc(LinkerPromiseClient.prototype.getLinker)
    await mutateFunc(LinkerPromiseClient.prototype.getHasLinker)
  }, [mutateFunc])
}

export const useInjectScript = (url: string): [boolean, boolean] => {
  const [state, setState] = useState<StateType>({
    loaded: false,
    error: false,
  })

  useEffect(() => {
    if (!injectorState.injectorMap?.[url]) {
      injectorState.injectorMap[url] = 'init'
    }
    // check if the script is already cached
    if (injectorState.injectorMap[url] === 'loaded') {
      setState({
        loaded: true,
        error: false,
      })
      return
    }

    // check if the script already errored
    if (injectorState.injectorMap[url] === 'error') {
      setState({
        loaded: true,
        error: true,
      })
      return
    }

    const onScriptEvent = (error: boolean) => {
      // Get all error or load functions and call them
      if (error) {
        log.error('Error loading script:', url)
      }
      injectorState.queue?.[url]?.forEach((job) => job(error))

      if (error && injectorState.scriptMap[url]) {
        injectorState.scriptMap?.[url]?.remove()
        injectorState.injectorMap[url] = 'error'
      } else injectorState.injectorMap[url] = 'loaded'
      delete injectorState.scriptMap[url]
    }

    const stateUpdate = (error: boolean) => {
      setState({
        loaded: true,
        error,
      })
    }

    if (!injectorState.scriptMap?.[url]) {
      injectorState.scriptMap[url] = document.createElement('script')
      if (injectorState.scriptMap[url]) {
        injectorState.scriptMap[url].src = url
        injectorState.scriptMap[url].async = true
        // append the script to the body
        document.body.append(injectorState.scriptMap[url] as Node)
        injectorState.scriptMap[url].addEventListener('load', () =>
          onScriptEvent(false),
        )
        injectorState.scriptMap[url].addEventListener('error', () =>
          onScriptEvent(true),
        )
        injectorState.injectorMap[url] = 'loading'
      }
    }

    if (!injectorState.queue?.[url]) {
      injectorState.queue[url] = [stateUpdate]
    } else {
      injectorState.queue?.[url]?.push(stateUpdate)
    }

    // remove the event listeners
    return () => {
      //checks the main injector instance
      //prevents Cannot read property 'removeEventListener' of null in hot reload
      if (!injectorState.scriptMap[url]) return
      injectorState.scriptMap[url]?.removeEventListener('load', () =>
        onScriptEvent(true),
      )
      injectorState.scriptMap[url]?.removeEventListener('error', () =>
        onScriptEvent(true),
      )
    }
  }, [url])

  return [state.loaded, state.error]
}

export const useLoadGoogleApi = (shouldLoad = true): boolean => {
  const [loaded, error] = useInjectScript('https://apis.google.com/js/api.js')
  const [ready, setReady] = useState<boolean>(false)
  useEffect(() => {
    // load the Drive picker api
    const onApiLoad = () => {
      setReady(true)
    }
    const loadApis = () => {
      window.gapi.load('client:auth2', () => null)
      window.gapi.load('picker', onApiLoad)
    }
    if (loaded && !error && !ready && shouldLoad) {
      loadApis()
    }
  }, [loaded, error, ready, shouldLoad])

  return ready
}

export const useGooglePicker = (): ((
  config: PickerConfiguration,
) => Promise<PickerResponse>) => {
  const showPicker = useRef<boolean>(false)
  const ready = useLoadGoogleApi(!showPicker.current)
  const { setReceptor } = useCurrentReceptor()
  const { openModal } = useModalState()
  const getLinker = useGetLinker()

  const createPicker = useCallback(
    async (config: PickerConfiguration) => {
      const callback = async (tokendata: {
        action: string
        docs: google.picker.DocumentObject[]
      }) => {
        showPicker.current = false
        await config.cbFunc(tokendata)
      }
      const linker = await getLinker(LINKER_MODEL_IDS.GOOGLE_DRIVE)
      if (!linker?.getCredentials().length) {
        const receptor = getLinkerTemplate(LINKER_MODEL_IDS.GOOGLE_DRIVE)
        setReceptor(receptor)
        openModal(ModalFormId.CONNECT_LINKER)
        return {
          isShown: false,
          invalidCredentials: false,
          exceptions: '',
        }
      }
      if (linker?.getExceptions().length > 0) {
        return {
          isShown: false,
          invalidCredentials: false,
          exceptions: linker.getExceptions(),
        }
      }
      if (!linker?.getIscredvalid()) {
        return {
          isShown: false,
          invalidCredentials: true,
          exceptions: '',
        }
      }
      const creds = JSON.parse(linker?.getCredentials())

      const token = creds.token.access_token
      const view = new google.picker.DocsView(config.viewId)
        .setIncludeFolders(true)
        .setMimeTypes(
          'application/vnd.google-apps.folder,image/png,image/jpeg,.rtf,.pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pages,.csv,.xlsx,.xls,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.ppt,.pptx,.key,.numbers,application/vnd.google-apps.document,application/vnd.google-apps.spreadsheet,application/vnd.google-apps.presentation,application/pdf',
        )
        .setSelectFolderEnabled(config.selectFolderEnabled || false)

      const picker = new google.picker.PickerBuilder()
        .setOAuthToken(token)
        .setDeveloperKey(process.env.REACT_APP_GOOGLE_API_KEY || '')
        .setLocale('en')
        .addView(view)
        .setOrigin(window.location.origin)
        .setCallback(callback)
      config.allowMultiselect &&
        picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
      const pickerBuilder = picker.build()
      pickerBuilder.setVisible(true)

      return {
        isShown: true,
        invalidCredentials: false,
        exceptions: '',
      }
    },
    [getLinker, openModal, setReceptor],
  )
  // open the picker
  const openPicker = useCallback(
    async (config: PickerConfiguration) => {
      // if we have token and everything is loaded open the picker
      if (ready && !showPicker.current) {
        showPicker.current = true
        return createPicker(config)
      }
      return {
        isShown: false,
        invalidCredentials: false,
        exceptions: '',
      }
    },
    [ready, createPicker],
  )

  return openPicker
}

export const useDisconnectLinkerModal = (
  modelId: LINKER_MODEL_IDS,
): (() => void) => {
  const client = useAuthorizedGrpcClient(LinkerPromiseClient)
  const mutate = useInvalidateLinkersCache()
  const linkerTemplate = getLinkerTemplate(modelId)

  const onConfirm = async () => {
    try {
      const request = new DisconnectLinkerRequest().setModelId(modelId)
      await client.disconnectLinker(request)
      mutate()
    } catch (e) {
      log.error(`Error disconnecting linker: ${linkerTemplate.name}`, e)
    }
  }

  const confirmationModalProps = {
    title: `Disconnect ${linkerTemplate.name}`,
    body: `Are you sure you want to revoke Trustero's access to ${linkerTemplate.name}?`,
    confirmText: 'Revoke Access',
    redirectTo: '.',
    onConfirmCB: onConfirm,
  }

  return useConfirmationModal(confirmationModalProps)
}

export const useConfirmConnectLinkerModal = (
  modelId: LINKER_MODEL_IDS,
): (() => void) => {
  const { openModal } = useModalState()
  const { setReceptor } = useCurrentReceptor()

  const onConfirm = async () => {
    if (!modelId) return
    setReceptor(getLinkerTemplate(modelId))
    openModal(ModalFormId.CONNECT_LINKER)
  }
  const linkerName = modelId ? getLinkerTemplate(modelId).name : ''

  const confirmationModalProps = {
    title: `Reconnect ${linkerName}`,
    body: `${linkerName} is not connected. Would you like to reconnect?`,
    confirmText: 'Connect',
    redirectTo: '.',
    onConfirmCB: onConfirm,
  }
  const openConfirmConnectModal = useConfirmationModal(confirmationModalProps)

  return modelId ? openConfirmConnectModal : () => null
}

import React, {
  ChangeEventHandler,
  FormEventHandler,
  useContext,
  useState,
} from 'react'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import {
  Dismissed,
  DismissedBatch,
} from '@trustero/trustero-api-web/lib/model/model_pb'
import {
  Identifier,
  MODEL_TYPE,
} from '@trustero/trustero-api-web/lib/common/model_pb'
import { useInvalidateServicesCache } from 'src/pages/Services/Services.hooks'
import styled from 'styled-components/macro'
import {
  useHideModal,
  useIsShowModal,
  useSetActiveModal,
} from 'src/Modal/ModalStateContext'
import AuthContext from '../../../context/authContext'
import ContentContext from '../../../context/ContentContext'
import { Service } from '../../../xgenerated'
import { GridColumn, GridRow } from '../../../components/Reusable/Grid'
import { CheckBoxInput } from '../../../components/Reusable/Inputs/CheckBox'
import { isService, useAuthorizedGrpcClient } from '../../../adapter'
import { callApiAndUpdateContent } from '../../../adapter/dataModelAdapterUtils'
import {
  useGrpcRevalidateByMethod,
  useGrpcRevalidateByMethodRequest,
} from '../../../components'
import { mutateModelRecord } from '../../../components/async/model'
import { excludedServiceIds } from '../../../pages/Services/Services.constants'
import { ModalForm, ModalFormId } from '../../../components/ModalForms'
import { TextButton } from '../../../components/Reusable/Buttons/TextButton'
import { MultiSelectForm } from '../../../components/Reusable/Forms/MultiSelectForm'

const AddServiceFooterTextStyle = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

const AddServiceFooterElement = (): JSX.Element => {
  const openAddCustomServiceModal = useSetActiveModal(
    ModalFormId.ADD_CUSTOM_SERVICE,
  )
  return (
    <AddServiceFooterTextStyle>
      Don&apos;t see your service here?
      <TextButton
        onClick={(e: React.MouseEvent) => {
          e.preventDefault()
          openAddCustomServiceModal()
        }}
      >
        Add a custom service.
      </TextButton>
    </AddServiceFooterTextStyle>
  )
}

export const AddServiceForm = ({
  services,
}: {
  services: Service[]
}): JSX.Element => {
  const { authCtx, authDispatch } = useContext(AuthContext)
  const { content, contentDispatch } = useContext(ContentContext)
  const modelClient = useAuthorizedGrpcClient(ModelPromiseClient)
  const methodMutator = useGrpcRevalidateByMethod()
  const methodRequestMutator = useGrpcRevalidateByMethodRequest()
  const invalidateServices = useInvalidateServicesCache()

  // Set of service ids to link
  const [formData, setFormData] = useState(new Set<string>())

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const serviceId = e.currentTarget.value
    const isChecked = e.currentTarget.checked

    setFormData((prevState) => {
      const newState = new Set(prevState)
      isChecked ? newState.add(serviceId) : newState.delete(serviceId)
      return newState
    })
  }

  const show = useIsShowModal(ModalFormId.ADD_SERVICE)
  const hide = useHideModal({
    modalId: ModalFormId.ADD_SERVICE,
    onHide: () => setFormData(new Set<string>()),
  })

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault()
    const newServices = Array.from(formData)
      .map((model_id) => content.idIndex[model_id])
      .filter(
        (model) =>
          !excludedServiceIds.includes(model.modelid) && isService(model),
      ) as Service[]
    const dismissedList = newServices.map((model) => {
      return new Dismissed()
        .setId(
          new Identifier().setModelid(model.id).setModeltype(model.modeltype),
        )
        .setDismissed(false)
    })

    const apiCall = () =>
      modelClient.setDismissedBatch(
        new DismissedBatch().setDismissedList(dismissedList),
      )
    await callApiAndUpdateContent(
      apiCall,
      content,
      contentDispatch,
      authCtx,
      authDispatch,
      methodMutator,
      methodRequestMutator,
    )

    await Promise.all(
      newServices.map((service) =>
        mutateModelRecord(
          MODEL_TYPE.SERVICE,
          service.modelid,
          methodMutator,
          methodRequestMutator,
        ),
      ),
    )
    await invalidateServices()

    hide()
  }

  const multiSelectFormProps = {
    loading: false,
    formId: ModalFormId.ADD_SERVICE,
    title: 'Select services you use to add to your Services list.',
    onSubmit,
    gridTemplateColumnStyling: 'min-content repeat(2, 1fr)',
    gridItems: (
      <>
        {services.map((service) => (
          <GridRow
            key={service.id}
            as="label"
            style={{ cursor: 'pointer' }}
            htmlFor={`${service.id}-input`}
          >
            <GridColumn>
              <CheckBoxInput
                id={`${service.id}-input`}
                value={service.modelid}
                onChange={onChange}
              />
            </GridColumn>
            <GridColumn>
              <service.logo width="32px" height="32px" />
              <p style={{ marginLeft: '8px' }}>{service.name}</p>
            </GridColumn>
            <GridColumn>{service.component_category}</GridColumn>
          </GridRow>
        ))}
      </>
    ),
  }

  return (
    <ModalForm
      show={show}
      hide={hide}
      title="Add Services"
      submitText="Add Service"
      formId={ModalFormId.ADD_SERVICE}
      footerContent={<AddServiceFooterElement />}
    >
      <MultiSelectForm {...multiSelectFormProps} />
    </ModalForm>
  )
}

import React, { ChangeEventHandler } from 'react'
import { Grid } from '../../../../../components/Reusable/Grid'
import { ControlsIndexItem } from './ControlsIndexItem'

// TODO: Consolidate with ControlsIndexGrid to use a boolean prop to hide headers
export const ControlsIndexGridNoHeaders = ({
  controlIds,
  selected,
  onChange,
}: {
  controlIds: string[]
  selected: string[]
  onChange: ChangeEventHandler
}): JSX.Element => {
  return (
    <Grid
      gridTemplateColumns={`
          auto minmax(min-content, 1fr) repeat(2, auto)
        `}
      pb="xs"
      overflowX={'auto'}
      maxHeight={'70vh'}
    >
      <>
        {controlIds.map((controlId) => (
          <ControlsIndexItem
            key={controlId}
            modelId={controlId}
            selected={selected}
            onChange={onChange}
          />
        ))}
      </>
    </Grid>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import {
  CompanyInfoSVG,
  ControlsSVG,
  FrameworksSVG,
  PoliciesSVG,
  ReceptorsSVG,
  RequestsSVG,
  RisksSVG,
} from 'src/components/Icons/Basic'
import { ReactComponent as ArrowSVG } from 'src/components/Icons/assets/arrow-forward-icon.svg'
import { StyledTextButton } from 'src/components/Reusable/Buttons/TextButton/TextButton'
import palette from 'src/designSystem/variables/palette'
import { IconContainer } from 'src/pages/Dashboard/ChecklistWidget/Card/Card.styles'
import styled from 'styled-components/macro'
import { margin, textStyle } from 'styled-system'

// widget styles

export const RoadmapWidgetContainer = styled.article`
  grid-column: span 12;
  display: flex;
  justify-content: start;
  width: 100%;
  gap: 32px;
`

export const RoadmapWidgetTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`

export const RoadmapWidgetSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  width: 100%;
  min-width: 150px;
  max-width: 255px;
  height: 275px;
  border: 1px solid ${palette.neutral[100]};
  border-radius: 4px;
  gap: 16px;

  &:hover {
    color: ${palette.neutral.white};
    background-color: ${palette.neutral[800]};

    .total-count {
      color: ${palette.neutral.white};
    }

    .widget-info-button > svg {
      fill: ${palette.neutral.white};
    }

    .progress-circle {
      background-color: ${palette.neutral[800]};
    }

    svg:not(.progress-ring):not(.widget-info-button) > svg {
      path {
        fill: ${palette.green[300]};
      }
      filter: none;
      opacity: 1;
    }

    .check-content {
      display: none;
    }

    .link-content {
      display: inline-block;
    }

    .total-link {
      p {
        color: ${palette.neutral.white};
      }
    }

    .arrow-icon {
      display: inline-block;
    }
  }
`

export const RoadmapSectionTitle = styled.h1.attrs({
  textStyle: 'subtitle.1',
})`
  ${textStyle}
`

export const RoadmapIconContainer = styled(IconContainer).attrs<{
  children: JSX.Element
}>((props) => {
  return {
    className: 'icon-container',
    children: <>{props.children}</>,
  }
})``

export const RoadmapWidgetSectionIconRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const RoadmapWidgetSectionRatio = styled.div`
  display: flex;
  align-items: flex-end;
`

export const RoadmapWidgetFinishedCount = styled.div`
  font-size: 40px;
  font-weight: 300;
  line-height: 1;
  margin: 0;
`

export const RoadmapWidgetTotalCount = styled.div.attrs({
  className: 'total-count',
})`
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: ${palette.neutral[400]};
  margin: 0;
`

export const RoadmapWidgetCheckContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const RoadmapWidgetCheckContent = styled.div.attrs({
  className: 'check-content',
})`
  display: flex;
  flex-direction: column;
`

export const RoadmapWidgetLinkContent = styled.div.attrs({
  className: 'link-content',
})`
  display: none;
  color: ${palette.neutral.white};
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`

export const RoadmapWidgetCheckHover = styled.div.attrs({
  className: 'check-container-hover',
})`
  display: flex;
  flex-direction: column;
`

export const RoadMapWidgetCheckRow = styled.div`
  display: flex;
  font-size: 12px;
  line-height: 21px;
`

export const RoadMapWidgetRowText = styled(RoadMapWidgetCheckRow).attrs({
  className: 'row-text',
})`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const RoadmapWidgetCardLink = styled(Link).attrs<{
  text: JSX.Element
  isDisabled?: boolean
}>(({ text }) => ({
  className: 'card-link',
  children: (
    <>
      {text}
      <ArrowIcon />
    </>
  ),
}))<{ text: JSX.Element; isDisabled?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${palette.neutral.white};
  text-decoration: none;
  font-size: 12px;
  p {
    color: ${palette.neutral.white};
  }
  &:hover {
    p {
      color: ${palette.neutral.white};
    }
    text-decoration: underline;
    color: ${palette.neutral.white};
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
      pointer-events: none;
      cursor: not-allowed;
    `}
`

export const RoadmapWidgetCardButton = styled(StyledTextButton).attrs<{
  text: JSX.Element
}>(({ text }) => ({
  className: 'card-link',
  children: (
    <>
      {text}
      <ArrowIcon />
    </>
  ),
}))<{ text: JSX.Element }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${palette.neutral.white};
  text-decoration: none;
  font-size: 12px;
  p {
    color: ${palette.neutral.white};
  }
  &:hover {
    p {
      color: ${palette.neutral.white};
    }
    text-decoration: underline;
    color: ${palette.neutral.white};
  }
`

export const RoadmapWidgetTotalLink = styled(RoadmapWidgetCardLink).attrs({
  className: 'total-link',
})`
  p {
    color: black;
  }
  .arrow - icon {
    color: ${palette.neutral[900]};
  }
`

// roadmap widget icons

export const ArrowIcon = styled(ArrowSVG).attrs({
  width: 16,
  height: 16,
  ml: 'xs',
  className: 'arrow-icon',
})`
  ${margin}
  fill: ${(props) => props.theme.colors?.text.icon.hover};
  display: none;
`

export const ControlCircleIcon = styled(ControlsSVG)`
  path {
    fill: ${palette.neutral[500]};
  }
`

export const PolicyCircleIcon = styled(PoliciesSVG)`
  path {
    fill: ${palette.neutral[500]};
  }
`

export const RisksCircleIcon = styled(RisksSVG)`
  path {
    fill: ${palette.neutral[500]};
  }
`

export const RequestsCircleIcon = styled(RequestsSVG)`
  path {
    fill: ${palette.neutral[500]};
  }
`

export const CompanyInfoCircleIcon = styled(CompanyInfoSVG)`
  path {
    fill: ${palette.neutral[500]};
  }
`

export const ServicesCircleIcon = styled(ReceptorsSVG)`
  path {
    fill: ${palette.neutral[500]};
  }
`

export const FrameworksCircleIcon = styled(FrameworksSVG)`
  path {
    fill: ${palette.neutral[500]};
  }
`

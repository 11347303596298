import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'
import isString from 'lodash/isString'
import dayjs, { Dayjs, ManipulateType } from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { isDev } from './environment'
import { DATE_FORMATS } from './dateConstants'

// @ts-ignore
dayjs.extend(relativeTime)

/**
 * Converts a protobuf Timestamp to a Day.js object.
 *
 * @param {Timestamp} timestamp - The protobuf Timestamp to be converted.
 * @returns {dayjs.Dayjs} A Day.js object representing the same moment in time as the input Timestamp.
 */
export const timestampToDayJS = (timestamp: Timestamp): dayjs.Dayjs =>
  dayjs(timestamp.toDate())

/**
 * Formats a protobuf Timestamp into a human-readable date string using a specified format.
 *
 * @param {Timestamp} timestamp - The protobuf Timestamp to be formatted.
 * @param {string} [format=DATE_FORMATS.SHORT] - The format string to use for formatting the date. Defaults to 'MMM D, YYYY'.
 * @returns {string} A string representing the formatted date.
 */
export const formatTimestamp = (
  timestamp: Timestamp,
  format: DATE_FORMATS = DATE_FORMATS.SHORT,
): string => dayjs(timestamp.toDate()).format(format)

/**
 * Takes two Timestamps and returns true if they represent the same day.
 *
 * @param {Timestamp} timestamp1 - The first protobuf Timestamp to be compared.
 * @param {Timestamp} timestamp2 - The second protobuf Timestamp to be compared.
 * @returns {boolean} A boolean representing whether the two Timestamps represent the same day.
 */
export const isTimestampEqual = (
  timestamp1: Timestamp,
  timestamp2: Timestamp,
): boolean =>
  dayjs(timestamp1.toDate()).isSame(dayjs(timestamp2.toDate()), 'day')
/**
 *
 * @param {Timestamp} timestamp The timestamp to subtract days from
 * @param {number} days The number of days to subtract
 * @returns {Timestamp} A new timestamp with the specified number of days subtracted
 */
export const subtractDaysFromTimestamp = (
  timestamp: Timestamp,
  days: number,
): Timestamp => {
  return Timestamp.fromDate(
    dayjs(timestamp.toDate()).subtract(days, 'days').toDate(),
  )
}

export interface Shift {
  quantity: number
  unit?: ManipulateType
}

export const formatTimestampAllowUndefined = (
  timestamp?: Timestamp,
  format: DATE_FORMATS = DATE_FORMATS.SHORT,
  shift?: Shift,
): string => {
  if (!timestamp) {
    return 'Unknown Date'
  }
  return shift
    ? dayjs(timestamp.toDate()).add(shift.quantity, shift.unit).format(format)
    : dayjs(timestamp.toDate()).format(format)
}

/**
 * Checks if a given protobuf Timestamp represents a date and time in the past.
 *
 * @param {Timestamp} timestamp - The protobuf Timestamp to be checked.
 * @returns {boolean} Returns true if the date has passed, false otherwise.
 */
export const hasDatePassed = (timestamp: Timestamp): boolean => {
  const now = dayjs()
  const dateToCheck = dayjs(timestamp.toDate())
  return dateToCheck.isBefore(now)
}

export const getDaysBetweenWithDate = (date1: Date, date2: Date): number =>
  getDaysBetween(dayjs(date1), dayjs(date2))

export const getDaysBetween = (date1: Dayjs, date2: Dayjs): number =>
  date1.diff(date2, 'day')

/**
 * Checks if a given protobuf Timestamp represents a date and time within a specified number of days from the current date and time.
 *
 * @param {Timestamp} timestamp - The protobuf Timestamp to be checked.
 * @param {number} days - The number of days to check against.
 * @returns {boolean} Returns true if the date is within the specified window, false otherwise.
 */
export const dateWithinWindow = (
  timestamp: Timestamp,
  days: number,
): boolean => {
  const now = dayjs()
  const dateToCheck = dayjs(timestamp.toDate())
  return dateToCheck.isAfter(now.subtract(days, 'days'))
}

export const dateIsInFuture = (timestamp: Timestamp): boolean =>
  dayjs(timestamp.toDate()).isAfter(dayjs())

/**
 * Creates a new protobuf Timestamp with the current time.
 * @returns {Timestamp} The current time as a protobuf Timestamp.
 */
export const getCurrentTimestamp = (): Timestamp =>
  Timestamp.fromDate(new Date())

export const getHumanReadableWall = (seconds: number): string => {
  const absDiffInSeconds = Math.abs(seconds)

  // If the difference is 60 seconds or less
  if (absDiffInSeconds <= 60) {
    return `${absDiffInSeconds} second${absDiffInSeconds === 1 ? '' : 's'}`
  }

  // For differences greater than 60 seconds, calculate minutes and remaining seconds
  const minutes = Math.floor(absDiffInSeconds / 60)
  const remainingSeconds = absDiffInSeconds % 60

  // Build a string with minutes and seconds
  let result = `${minutes} minute${minutes === 1 ? '' : 's'}`
  if (remainingSeconds > 0) {
    result += ` and ${remainingSeconds} second${
      remainingSeconds === 1 ? '' : 's'
    }`
  }

  return result
}

export const getHumanReadableDifference = (
  start: Dayjs,
  end: Dayjs,
): string => {
  const diffInSeconds = end.diff(start, 'second')

  return getHumanReadableWall(diffInSeconds)
}

/**
 * @deprecated We are moving to `dayjs`
 */
const formatDate = (
  timestamp: string | number | Date | undefined,
  fullTime = true,
  useTimezone = true,
): string => {
  return dateFormatter(new Date(timestamp || ''), fullTime, useTimezone)
}

/**
 * @deprecated We are moving to `dayjs`
 */
export const dateFormatter = (
  date: Date | string | undefined | false,
  fullTime = true,
  useTimezone = true,
): string => {
  if (!date) {
    return ''
  }
  if (isString(date)) {
    date = new Date(date)
  }
  const locale = isDev() ? 'en-US' : undefined
  // [AP-5003] TODO: Is this needed? It was making the dates for scanned SOC 2 reports appear to be off by one day in development.
  const timezone = isDev() && useTimezone ? { timeZone: 'UTC' } : {}
  const timeopts = fullTime
    ? {
        hour: 'numeric',
        minute: '2-digit',
      }
    : {}

  // TODO: Clean up this ignore
  // @ts-ignore
  const options: DateTimeFormatOptions | undefined = {
    year: 'numeric',
    day: 'numeric',
    month: 'short',
    ...timeopts,
    ...timezone,
  }
  const dateStr = date.toLocaleDateString(locale, options)

  return `${dateStr}`
}

/**
 * @deprecated We are moving to `dayjs`
 */
export const dateToTimestamp = (date: string | Date | undefined): Timestamp => {
  if (!date) {
    return new Timestamp()
  }
  if (isString(date)) {
    date = new Date(date)
  }
  const seconds = Math.floor(date.getTime() / 1000)
  return new Timestamp().setSeconds(seconds)
}

/**
 * @deprecated We are moving to `dayjs`
 */
export const isWithinLastYear = (timestamp: Timestamp | undefined): boolean => {
  if (!timestamp) {
    return false
  }

  const currentDate = new Date()
  const timestampDate = timestamp.toDate()
  const lastYear = new Date(
    currentDate.getFullYear() - 1,
    currentDate.getMonth(),
    currentDate.getDate(),
  )

  return timestampDate >= lastYear
}

/**
 * @deprecated We are moving to `dayjs`
 */
export const getDateText = (startDate: string, endDate: string): string => {
  const hasBothDates: boolean = !!startDate && !!endDate
  return `${startDate}${hasBothDates ? ' to ' : ''}${endDate}`
}

export const dateToStartOfDate = (date?: Date): Date | undefined => {
  if (!date) return
  return dayjs(date).startOf('day').toDate()
}

export const dateToEndOfDate = (date?: Date): Date | undefined => {
  if (!date) return
  return dayjs(date).endOf('day').toDate()
}

/**
 * @deprecated We are moving to `dayjs`
 */
export const dateStringFromTimestamp = (
  timestamp: Timestamp | undefined,
): string => {
  if (!timestamp) {
    return ''
  }

  const date = timestamp.toDate()
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    timeZone: 'UTC',
  }

  return date.toLocaleString('en-US', options)
}

/**
 * @deprecated We are moving to `dayjs`
 */
export const timestampObjectToTimestamp = (
  timestampObj: Timestamp.AsObject | undefined,
): undefined | Timestamp => {
  if (!timestampObj || (!timestampObj.seconds && !timestampObj.nanos)) {
    return
  }

  return new Timestamp()
    .setSeconds(timestampObj.seconds)
    .setNanos(timestampObj.nanos)
}

export default formatDate

import { useCallback } from 'react'
import { TimeRange } from '@trustero/trustero-api-web/lib/common/time_pb'
import { AttachmentPromiseClient } from '@trustero/trustero-api-web/lib/attachment/attachment_grpc_web_pb'
import {
  GetLatestEvidenceRequest,
  ListEvidenceRequest,
} from '@trustero/trustero-api-web/lib/attachment/attachment_pb'
import { ModelPromiseClient } from '@trustero/trustero-api-web/lib/model/model_grpc_web_pb'
import { GetControlRequest } from '@trustero/trustero-api-web/lib/model/control_pb'
import { ModelStatsServicePromiseClient } from '@trustero/trustero-api-web/lib/model/stats_grpc_web_pb'
import { ListControlComplianceStatsRequest } from '@trustero/trustero-api-web/lib/model/stats_pb'
import {
  useGrpcRevalidateByMethod,
  useGrpcRevalidateByMethodRequest,
} from '../../components'

export type invalidateEvidenceTypes = {
  timeRange?: TimeRange
  controlId?: string
  requestId?: string
}

/**
 * ! Should be used every time Evidence is added or deleted from a Control
 *
 * Returns a function that invalidates all cache keys affiliated with
 * the fetching of evidence list and evidence count for the given control
 *
 * This then triggers a refetch of a Control's evidence list and evidence count
 * @returns Returns a function to invalidate cache keys
 */
export const useInvalidateEvidence = ({
  controlId,
  timeRange,
  requestId,
}: invalidateEvidenceTypes): (() => Promise<unknown>) => {
  const grpcRevalidateByMethodRequest = useGrpcRevalidateByMethodRequest()
  const invalidator = useCallback(() => {
    const latestEvidenceRequest = new GetLatestEvidenceRequest()
    const listEvidenceRequest = new ListEvidenceRequest()

    if (timeRange) {
      latestEvidenceRequest.setTimeRange(timeRange)
      listEvidenceRequest.setTimeRange(timeRange)
    }
    if (controlId) {
      latestEvidenceRequest.setControlId(controlId)
      listEvidenceRequest.setControlId(controlId)
    }
    if (requestId) {
      latestEvidenceRequest.setDocumentRequestId(requestId)
      listEvidenceRequest.setDocumentRequestId(requestId)
    }

    return Promise.all([
      grpcRevalidateByMethodRequest(
        AttachmentPromiseClient.prototype.getLatestEvidence,
        latestEvidenceRequest,
      ),
      grpcRevalidateByMethodRequest(
        AttachmentPromiseClient.prototype.listEvidence,
        listEvidenceRequest,
      ),
      grpcRevalidateByMethodRequest(
        ModelPromiseClient.prototype.getControl,
        new GetControlRequest(),
      ),
      grpcRevalidateByMethodRequest(
        ModelStatsServicePromiseClient.prototype.listControlComplianceStats,
        new ListControlComplianceStatsRequest(),
      ),
    ])
  }, [grpcRevalidateByMethodRequest, controlId, requestId, timeRange])
  return invalidator
}

/**
 * This can be used to invalidate all evidence cache from getLatestEvidence and listEvidence calls.
 * This is not the best long term solution and it would be better to update the cache rather than replace it. However it could be a lot worse and will do for now 12/11/2022.
 *
 * @returns Returns a function to invalidate evidence
 */

export const useHardEvidenceInvalidation = (): (() => Promise<void>) => {
  const mutateFunc = useGrpcRevalidateByMethod()

  return useCallback(async () => {
    await Promise.all([
      mutateFunc(AttachmentPromiseClient.prototype.getLatestEvidence),
      mutateFunc(AttachmentPromiseClient.prototype.listEvidence),
      mutateFunc(AttachmentPromiseClient.prototype.getDocumentByOID),
    ])
  }, [mutateFunc])
}

import React from 'react'
import { GridColumn, GridRow } from '../../../../components/Reusable/Grid'

export const NoEvidence = ({
  isDismissed = false,
}: {
  isDismissed: boolean
}): JSX.Element => {
  const emptyText = isDismissed
    ? 'This Control is Out of Scope. To add evidence, move it In Scope first.'
    : "Looks like you haven't added any evidence yet. Try adding one now!"

  return (
    <GridRow>
      <GridColumn gridColumn="1 / -1" justifyContent="center">
        {emptyText}
      </GridColumn>
    </GridRow>
  )
}

import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import isFunction from 'lodash/isFunction'
import { APP_ANALYTICS_EVENTS } from 'src/analytics/analytics.constants'
import { useConfirmationContext } from '../../Confirmation'
import { Spinner, SpinnerProps, useThrobberContext } from '../../Throbber'

type ConfirmationModalProps<T extends unknown[] = [], D = string> = {
  title: string
  body: string | JSX.Element
  confirmText?: string
  redirectTo?: string
  onConfirmCB?: (...args: T) => Promise<void> | void
  footer?: JSX.Element
  throbber?: SpinnerProps
  onCancel?: () => void
  cancelText?: string
  handleSubmit?: () => void
  hideCancel?: boolean
  analyticsEvent?: keyof typeof APP_ANALYTICS_EVENTS
  analyticsEventData?: Record<string, D>
}

export const useConfirmationModal = <T extends unknown[]>(
  props: ConfirmationModalProps<T>,
): ((...args: T) => void) => {
  const navigate = useNavigate()
  const { setConfirmationState } = useConfirmationContext()
  const { setThrobberState } = useThrobberContext()

  const onConfirmCB = useCallback(
    async (...args: T) => {
      if (props.onConfirmCB) {
        props.throbber &&
          setThrobberState({
            isShown: true,
            Display: <Spinner {...props.throbber} />,
          })
        try {
          await props.onConfirmCB(...args)
        } catch (e) {
          props.throbber &&
            setThrobberState({
              isShown: false,
              Display: <></>,
            })
          throw e
        } finally {
          props.throbber &&
            setThrobberState({
              isShown: false,
              Display: <></>,
            })
        }
      }
      if (props.redirectTo) {
        navigate(props.redirectTo, { replace: true })
      }
      if (isFunction(props.handleSubmit)) {
        props.handleSubmit()
      }
    },
    [navigate, props, setThrobberState],
  )

  return useCallback(
    (...args: T) =>
      setConfirmationState({
        ...props,
        isShown: true,
        size: 'lg',
        onConfirmCB: () => onConfirmCB(...args),
        footer: props.footer,
      }),
    [onConfirmCB, props, setConfirmationState],
  )
}

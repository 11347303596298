import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { margin } from 'styled-system'
import { SubServiceList } from './SubServiceList'
import { SubServiceInstanceList } from './SubServiceInstanceList'

const ReceptorSubServicesContainer = styled.section.attrs({
  mt: 'm',
})`
  ${margin}
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`

export function ReceptorSubServices({
  receptorId,
  serviceIds,
}: {
  receptorId: string
  serviceIds: string[]
}): JSX.Element {
  const [selectedServiceId, setSelectedServiceId] = useState<
    string | undefined
  >()

  useEffect(() => {
    if (selectedServiceId || !serviceIds || serviceIds.length === 0) {
      return
    }
    setSelectedServiceId(serviceIds[0])
  }, [selectedServiceId, serviceIds])

  if (!selectedServiceId) {
    return <></>
  }

  return (
    <ReceptorSubServicesContainer>
      <SubServiceList
        receptorId={receptorId}
        serviceIds={serviceIds}
        selectedServiceId={selectedServiceId}
        setSelectedServiceId={setSelectedServiceId}
      />
      <SubServiceInstanceList
        receptorId={receptorId}
        selectedServiceId={selectedServiceId}
      />
    </ReceptorSubServicesContainer>
  )
}

/**
 * @fileoverview gRPC-Web generated client stub for model
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.1
// 	protoc              v3.20.3
// source: model/service_instance.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.model = require('./service_instance_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.model.ServiceInstanceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.model.ServiceInstancePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.GetServiceInstanceRequest,
 *   !proto.model.Instance>}
 */
const methodDescriptor_ServiceInstance_Get = new grpc.web.MethodDescriptor(
  '/model.ServiceInstance/Get',
  grpc.web.MethodType.UNARY,
  proto.model.GetServiceInstanceRequest,
  proto.model.Instance,
  /**
   * @param {!proto.model.GetServiceInstanceRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.Instance.deserializeBinary
);


/**
 * @param {!proto.model.GetServiceInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.Instance)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.Instance>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ServiceInstanceClient.prototype.get =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.ServiceInstance/Get',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_Get,
      callback);
};


/**
 * @param {!proto.model.GetServiceInstanceRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.Instance>}
 *     Promise that resolves to the response
 */
proto.model.ServiceInstancePromiseClient.prototype.get =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.ServiceInstance/Get',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_Get);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ListInstancesRequest,
 *   !proto.model.ListInstancesResponse>}
 */
const methodDescriptor_ServiceInstance_List = new grpc.web.MethodDescriptor(
  '/model.ServiceInstance/List',
  grpc.web.MethodType.UNARY,
  proto.model.ListInstancesRequest,
  proto.model.ListInstancesResponse,
  /**
   * @param {!proto.model.ListInstancesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ListInstancesResponse.deserializeBinary
);


/**
 * @param {!proto.model.ListInstancesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ListInstancesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ListInstancesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ServiceInstanceClient.prototype.list =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.ServiceInstance/List',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_List,
      callback);
};


/**
 * @param {!proto.model.ListInstancesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ListInstancesResponse>}
 *     Promise that resolves to the response
 */
proto.model.ServiceInstancePromiseClient.prototype.list =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.ServiceInstance/List',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_List);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ListInstancesRequest,
 *   !proto.model.ListInstanceIdsResponse>}
 */
const methodDescriptor_ServiceInstance_ListIds = new grpc.web.MethodDescriptor(
  '/model.ServiceInstance/ListIds',
  grpc.web.MethodType.UNARY,
  proto.model.ListInstancesRequest,
  proto.model.ListInstanceIdsResponse,
  /**
   * @param {!proto.model.ListInstancesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ListInstanceIdsResponse.deserializeBinary
);


/**
 * @param {!proto.model.ListInstancesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ListInstanceIdsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ListInstanceIdsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ServiceInstanceClient.prototype.listIds =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.ServiceInstance/ListIds',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_ListIds,
      callback);
};


/**
 * @param {!proto.model.ListInstancesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ListInstanceIdsResponse>}
 *     Promise that resolves to the response
 */
proto.model.ServiceInstancePromiseClient.prototype.listIds =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.ServiceInstance/ListIds',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_ListIds);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ListInstancesByServiceIdRequest,
 *   !proto.model.ListInstancesResponse>}
 */
const methodDescriptor_ServiceInstance_ListInstancesByServiceId = new grpc.web.MethodDescriptor(
  '/model.ServiceInstance/ListInstancesByServiceId',
  grpc.web.MethodType.UNARY,
  proto.model.ListInstancesByServiceIdRequest,
  proto.model.ListInstancesResponse,
  /**
   * @param {!proto.model.ListInstancesByServiceIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ListInstancesResponse.deserializeBinary
);


/**
 * @param {!proto.model.ListInstancesByServiceIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ListInstancesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ListInstancesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ServiceInstanceClient.prototype.listInstancesByServiceId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.ServiceInstance/ListInstancesByServiceId',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_ListInstancesByServiceId,
      callback);
};


/**
 * @param {!proto.model.ListInstancesByServiceIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ListInstancesResponse>}
 *     Promise that resolves to the response
 */
proto.model.ServiceInstancePromiseClient.prototype.listInstancesByServiceId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.ServiceInstance/ListInstancesByServiceId',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_ListInstancesByServiceId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.ListInstancesRequest,
 *   !proto.model.ListInstancesHierarchyResponse>}
 */
const methodDescriptor_ServiceInstance_ListHierarchy = new grpc.web.MethodDescriptor(
  '/model.ServiceInstance/ListHierarchy',
  grpc.web.MethodType.UNARY,
  proto.model.ListInstancesRequest,
  proto.model.ListInstancesHierarchyResponse,
  /**
   * @param {!proto.model.ListInstancesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.model.ListInstancesHierarchyResponse.deserializeBinary
);


/**
 * @param {!proto.model.ListInstancesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.model.ListInstancesHierarchyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.model.ListInstancesHierarchyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ServiceInstanceClient.prototype.listHierarchy =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.ServiceInstance/ListHierarchy',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_ListHierarchy,
      callback);
};


/**
 * @param {!proto.model.ListInstancesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.model.ListInstancesHierarchyResponse>}
 *     Promise that resolves to the response
 */
proto.model.ServiceInstancePromiseClient.prototype.listHierarchy =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.ServiceInstance/ListHierarchy',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_ListHierarchy);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.SetInstanceDismissRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ServiceInstance_SetDismiss = new grpc.web.MethodDescriptor(
  '/model.ServiceInstance/SetDismiss',
  grpc.web.MethodType.UNARY,
  proto.model.SetInstanceDismissRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.model.SetInstanceDismissRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.model.SetInstanceDismissRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ServiceInstanceClient.prototype.setDismiss =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.ServiceInstance/SetDismiss',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_SetDismiss,
      callback);
};


/**
 * @param {!proto.model.SetInstanceDismissRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.model.ServiceInstancePromiseClient.prototype.setDismiss =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.ServiceInstance/SetDismiss',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_SetDismiss);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.BulkSetInstancesDismissRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ServiceInstance_BulkSetDismiss = new grpc.web.MethodDescriptor(
  '/model.ServiceInstance/BulkSetDismiss',
  grpc.web.MethodType.UNARY,
  proto.model.BulkSetInstancesDismissRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.model.BulkSetInstancesDismissRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.model.BulkSetInstancesDismissRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ServiceInstanceClient.prototype.bulkSetDismiss =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.ServiceInstance/BulkSetDismiss',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_BulkSetDismiss,
      callback);
};


/**
 * @param {!proto.model.BulkSetInstancesDismissRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.model.ServiceInstancePromiseClient.prototype.bulkSetDismiss =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.ServiceInstance/BulkSetDismiss',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_BulkSetDismiss);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.model.SetInstanceContainsCustomerDataRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_ServiceInstance_SetContainsCustomerData = new grpc.web.MethodDescriptor(
  '/model.ServiceInstance/SetContainsCustomerData',
  grpc.web.MethodType.UNARY,
  proto.model.SetInstanceContainsCustomerDataRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.model.SetInstanceContainsCustomerDataRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.model.SetInstanceContainsCustomerDataRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.model.ServiceInstanceClient.prototype.setContainsCustomerData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/model.ServiceInstance/SetContainsCustomerData',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_SetContainsCustomerData,
      callback);
};


/**
 * @param {!proto.model.SetInstanceContainsCustomerDataRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.model.ServiceInstancePromiseClient.prototype.setContainsCustomerData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/model.ServiceInstance/SetContainsCustomerData',
      request,
      metadata || {},
      methodDescriptor_ServiceInstance_SetContainsCustomerData);
};


module.exports = proto.model;

